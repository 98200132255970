import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Input from 'components/Input';
// utils
import { connect } from 'react-redux';
// store
import { closeModal } from 'reducers/modals';
// styles
import styles from './UpdateCategoryNameModal.module.scss';

const UpdateCategoryNameModal = ({ onSubmit, closeModal, name }) => {
  const [categoryName, setCategoryName] = useState(name);

  const onCategoryNameChange = ({ target: { value } }) => setCategoryName(value);

  const onUpdate = () => {
    onSubmit(categoryName);
    closeModal();
  };

  return (
    <Modal title={<FormattedMessage id="component.modal.reports.renameCategory" />}>
      <section className="modal-body">
        <div className={styles['category-name-label']}>
          <FormattedMessage id="general.categoryName" />
        </div>
        <div>
          <FormattedMessage id="general.typeCategoryName">
            {(placeholder) => (
              <Input placeholder={placeholder} value={categoryName} onChange={onCategoryNameChange} />
            )}
          </FormattedMessage>
        </div>
      </section>
      <section className="modal-footer text-right">
        <Button className="margin-right-1" onClick={closeModal}>
          <FormattedMessage id="general.button.cancel" />
        </Button>
        <Button primary disabled={!categoryName || name === categoryName} onClick={onUpdate}>
          <FormattedMessage id="container.checkupIndex.row.update" />
        </Button>
      </section>
    </Modal>
  );
};

UpdateCategoryNameModal.propTypes = {
  closeModal: T.func.isRequired,
  onSubmit: T.func.isRequired,
  name: T.string.isRequired,
};

export default connect(null, { closeModal })(UpdateCategoryNameModal);
