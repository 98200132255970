import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { fetchInventories, updateAdminInventory } from 'reducers/inventoriesList';
import { fetchUserCompanies } from 'reducers/auth';
import { removeAttentionDot } from 'reducers/sidebar';
import { openModal } from 'reducers/modals';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { enableEditMode } from 'reducers/barnsheets/barnsheetsGroupCheckups';
// hoc
import withDropdownActions from 'hoc/withDropdownActions';
// components
import { FormattedMessage } from 'react-intl';
import SearchBox from 'components/SearchBox';
import NothingBox from 'components/NothingBox';
import TableFilter from 'components/TableFilter';
import DataTable from 'components/DataTable/DataTable';
import FarmTitleBox from 'components/FarmTitleBox/FarmTitleBox';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import DownloadLink from 'components/DownloadLink';
import Subnavigation from 'components/Subnavigation';
import BatchDownloadModal from 'components/Inventory/BatchDownloadModal/BatchDownloadModal';
import Button from 'components/Button';
import { InventoryBadge } from 'components/StatusBadge/StatusBadge';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import Preloader from 'components/Preloader';
import OfflineScreen from 'components/OfflineScreen';
import Panel from 'components/Panel';
import ABFBadge from 'components/ABFBadge';
// utils
import sum from 'lodash.sum';
import kebabCase from 'lodash.kebabcase';
import moment from 'moment';
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import download from 'downloadjs';
import { isTablet } from 'react-device-detect';
// styles
import './Invetory.scss';

class Inventory extends Component {

  componentDidMount() {
    const { companies, setBreadcrumbs, fetchUserCompanies, isOnline } = this.props;
    setBreadcrumbs([
      { label: <FormattedMessage id="general.pageTitle.inventory" />, useLabelAsMobileTitle: true },
    ]);

    if (isOnline) {
      this.getInventories();
      if (!companies.length) fetchUserCompanies();
    }
  }

  componentDidUpdate({ isOnline: prevIsOnline }) {
    const { isOnline, companies, fetchUserCompanies } = this.props;
    if (isOnline && isOnline !== prevIsOnline) {
      this.getInventories();
      if (!companies.length) fetchUserCompanies();
    }
  }

  componentWillUnmount() {
    const { removeAttentionDot, setBreadcrumbs } = this.props;
    setBreadcrumbs();
    removeAttentionDot('inventories');
  }

  getInventories = () => {
    const { fetchInventories, isAdminRoute, reqParams } = this.props;

    fetchInventories({ admin: isAdminRoute, ...reqParams });
  };

  onCompanyChange = (company) => {
    const { fetchInventories, reqParams, isOnline } = this.props;
    if (isOnline) fetchInventories({ ...reqParams, page: 1, company_id: company?.id });
  };

  downloadCSV = (inventory) => (e) => {
    e.stopPropagation();
    const { isAdminRoute } = this.props;
    const startAt = moment(inventory.started_on).format('YYYY-MM-DD');
    const endAt = moment(inventory.ended_on).format('YYYY-MM-DD');
    const filename = `inventory-${kebabCase(inventory.pig_group.name)}-${startAt}-${endAt}.csv`;
    return fetchFromAPI(`${isAdminRoute ? '/admin' : ''}/csv/inventories/${inventory.id}`, { blob: true })
      .then((blob) => download(blob, filename, 'text/csv'))
      .catch(toastResponseErrors);
  };

  onBatchDownload = () => {
    const { openModal, fetchInventories, reqParams, isAdminRoute } = this.props;
    openModal(
      <BatchDownloadModal
        isAdmin={isAdminRoute}
        refreshInventories={() => fetchInventories({ ...reqParams })}
      />
    );
  };

  openDropdown = (inventory) => (e) => {
    const { openDropdown, isAdminRoute } = this.props;

    const options = [
      {
        label: <FormattedMessage id="general.button.accept" />,
        onClick: this.acceptInventory(inventory.id),
        hide: !(inventory.status === 'submitted' && isAdminRoute)
      },
      {
        label: <FormattedMessage id="general.button.revoke" />,
        onClick: this.revokeInventory(inventory.id),
        hide: !(inventory.status === 'accepted' && isAdminRoute)
      },
      { label: <FormattedMessage id="general.button.view" />,
        onClick: this.editInventory(inventory),
      },
      { label: <FormattedMessage id="general.button.downloadCSV" />,
        onClick: this.downloadCSV(inventory)
      }
    ];

    openDropdown(e, options);
  };

  acceptInventory = (id) => (e) => {
    e.stopPropagation();
    const { updateAdminInventory } = this.props;
    updateAdminInventory(id, { status: 'accepted' });
  };

  revokeInventory = (id) => (e) => {
    e.stopPropagation();
    const { updateAdminInventory } = this.props;
    updateAdminInventory(id, { status: 'submitted' });
  };

  editInventory = (inventory) => (e) => {
    e.stopPropagation();
    const { enableEditMode } = this.props;
    const { router } = this.context;
    enableEditMode(inventory.pig_group.id, { type: 'inventory', inventory_id: inventory.id })
      .then(() => {
        router.push({ pathname: `/barnsheets/groups/${inventory.pig_group.id}`, query: { editMode: true } });
      });
  };

  renderFarmNameColumn = (inventory) => {
    const { isAdminRoute } = this.props;
    return (
      <CustomColumn>
        <div className="flex justify">
          <FarmTitleBox
            name={inventory?.pig_group?.farm?.name}
            type={inventory?.pig_group?.farm?.farm_type}
          />
          <DownloadLink
            endpoint={`${isAdminRoute ? '/admin' : ''}/csv/inventories/${inventory.id}`}
            title="Download as CSV"
            className="button download-btn hide-for-large"
          >
            <i className="fa fa-download-btn" />
          </DownloadLink>
        </div>
      </CustomColumn>
    );
  };

  renderFarmGroupIdColumn = (inventory) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.groupId" />}>
      {inventory.pig_group.name}
    </CustomColumn>
  );

  renderABFStatusColumn = (inventory) => (
    <CustomColumn label={<FormattedMessage id="general.abfStatus" />}>
      {inventory.pig_group.abf_tracking ? <ABFBadge /> : 'N/A'}
    </CustomColumn>
  )

  renderTimePeriodColumn = (inventory) => (
    <CustomColumn label={<FormattedMessage id="container.inventory.table.timePeriod" />}>
      {moment(inventory.started_on).format('MMM D') + '-' + moment(inventory.ended_on).format('D, YYYY')}
    </CustomColumn>
  );

  renderStartColumn = ({ start_pigs }) => (
    <CustomColumn label={<FormattedMessage id="container.inventory.table.start" />}>
      {start_pigs}
    </CustomColumn>
  );

  renderEndColumn = ({ end_pigs }) => (
    <CustomColumn label={<FormattedMessage id="container.inventory.table.end" />}>
      {end_pigs}
    </CustomColumn>
  );

  renderStatusColumn = (inventory) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <InventoryBadge status={inventory.status} />
    </CustomColumn>
  );

  renderActionsColumn = (inventory) => {
    const { isAdminRoute } = this.props;
    return (
      <div className="collapsible-value button-column light">
        <i
          data-name={`${inventory.pig_group.name}-${inventory.id}`}
          className="fa fa-dots-three-horizontal show-for-large dropdown-icon"
          onClick={this.openDropdown(inventory)}
        />
        <Button small light onClick={this.editInventory(inventory)} className="hide-for-large">
          <i className="fa fa-eye mr-5" />
          <FormattedMessage id="general.button.view" />
        </Button>

        {inventory.status === 'pending' && isAdminRoute && (
          <Button small primary disabled className="hide-for-large">
            <FormattedMessage id="general.button.accept" />
          </Button>
        )}

        {inventory.status === 'submitted' && isAdminRoute && (
          <Button small primary onClick={this.acceptInventory(inventory.id)} className="hide-for-large">
            <FormattedMessage id="general.button.accept" />
          </Button>
        )}

        {inventory.status === 'accepted' && isAdminRoute && (
          <Button small gray onClick={this.revokeInventory(inventory.id)} className="hide-for-large">
            <FormattedMessage id="general.button.revoke" />
          </Button>
        )}
      </div>
    );
  };

  renderExpandable = (inventory) => {
    const { isAdminRoute } = this.props;
    return (
      <div>
        <Button small light onClick={this.downloadCSV(inventory)}>
          <i className="fa fa-download-btn mr-5" />
          <FormattedMessage id="general.button.downloadCSV" />
        </Button>

        <Button small light onClick={this.editInventory(inventory)}>
          <i className="fa fa-eye mr-5" />
          <FormattedMessage id="general.button.view" />
        </Button>

        {inventory.status === 'pending' && isAdminRoute && (
          <Button small primary disabled>
            <FormattedMessage id="general.button.accept" />
          </Button>
        )}

        {inventory.status === 'submitted' && isAdminRoute && (
          <Button small primary onClick={this.acceptInventory(inventory.id)}>
            <FormattedMessage id="general.button.accept" />
          </Button>
        )}

        {inventory.status === 'accepted' && isAdminRoute && (
          <Button small gray onClick={this.revokeInventory(inventory.id)}>
            <FormattedMessage id="general.button.revoke" />
          </Button>
        )}
      </div>
    );
  };

  renderTitle = () => {
    const { companies, reqParams: { company_id } } = this.props;
    const selectedCompany = companies.find(({ id }) => id === company_id) || {};
    return (
      <h2 className="lighter show-for-large">
        {selectedCompany.name || <FormattedMessage id="container.inventory.table.title" />}
      </h2>
    );
  };

  render() {
    const { isLoading, resources, meta: { total, stats = {} }, companies, isAdminRoute, onSortChange,
      reqParams: { company_id, search, status, page, per_page, sort }, onPageChange, onPerPageChange,
      onStatusChange, onSearchChange, isOnline } = this.props;

    const statsSum = sum(Object.values(stats));

    const tableFilters = [
      { label: <FormattedMessage id="container.inventory.tableFilters.all" />, value: '', count: statsSum },
      { label: <FormattedMessage id="general.status.accepted" />, value: 'accepted' },
      { label: <FormattedMessage id="general.status.submitted" />, value: 'submitted', hide: !isAdminRoute },
      { label: <FormattedMessage id="general.status.pending" />, value: 'pending' },
    ];

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.farmName" />, sortKey: 'farm_name', flex: '2 0 100px',
        renderer: this.renderFarmNameColumn },
      { label: <FormattedMessage id="component.dataTable.headers.groupId" />, sortKey: 'pig_group_name',
        flex: '1 0 100px', renderer: this.renderFarmGroupIdColumn },
      { label: <FormattedMessage id="general.abfStatus" />, flex: '1 1 90px', renderer: this.renderABFStatusColumn,
        sortKey: 'abf_tracking' },
      { label: <FormattedMessage id="container.inventory.table.timePeriod" />, sortKey: 'started_on', flex: '1 0 95px',
        renderer: this.renderTimePeriodColumn },
      { label: <FormattedMessage id="container.inventory.table.start" />, sortKey: 'start_pigs', flex: '1 1 60px',
        renderer: this.renderStartColumn },
      { label: <FormattedMessage id="container.inventory.table.end" />, sortKey: 'end_pigs', flex: '1 1 60px',
        renderer: this.renderEndColumn },
      { label: <FormattedMessage id="component.dataTable.headers.status" />,
        flex: '1 1 100px', renderer: this.renderStatusColumn },
      { flex: `0 0 ${isAdminRoute ? '50px' : '120px'}`, className: isTablet ? 'hide-for-large' : '',
        renderer: this.renderActionsColumn, fixed: true },
      { flex: '0 0 40px', renderer: () => <ArrowColumn />, hide: !isTablet, fixed: true, hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <div className="Inventory">
        <Subnavigation
          items={companies}
          currentId={company_id}
          onSelect={this.onCompanyChange}
          defaultItem={<FormattedMessage id="container.inventory.navigation.all" />}
          className="small-12 column"
        />

        {isOnline
          ? (
            <section className="small-12 column">
              <Panel>
                <Panel.Heading renderTitle={this.renderTitle}>
                  <SearchBox initialValue={search} onChange={onSearchChange} />
                </Panel.Heading>
                <Panel.Body noPadding>
                  <Preloader isActive={isLoading} />

                  <TableFilter
                    filters={tableFilters}
                    onFilterChange={onStatusChange}
                    activeFilter={status}
                    stats={stats}
                    className="mh-10"
                  >
                    <Button primary small onClick={this.onBatchDownload}>
                      <FormattedMessage id="general.button.batchDownload" />
                    </Button>
                  </TableFilter>

                  <DataTable
                    data={resources}
                    columns={columns}
                    sort={sort}
                    paginationProps={paginationProps}
                    onSortChange={onSortChange}
                    renderExpandable={this.renderExpandable}
                    isExpandable={isTablet}
                    scrollable
                    tableKey="inventoriesList"
                  />

                  <NothingBox
                    itemsName="inventories"
                    display={!resources.length}
                    isLoading={isLoading}
                    search={search}
                  >
                    <h2 className="lighter"><FormattedMessage id="component.nothingBox.noInventories" /></h2>
                  </NothingBox>
                </Panel.Body>
              </Panel>
            </section>
          )
          : <OfflineScreen />}
      </div>
    );
  }
}

Inventory.propTypes = {
  isLoading: T.bool.isRequired,
  isAdminRoute: T.bool,
  meta: T.object.isRequired,
  reqParams: T.object.isRequired,
  companies: T.array,
  resources: T.array.isRequired,
  fetchInventories: T.func.isRequired,
  fetchUserCompanies: T.func.isRequired,
  updateAdminInventory: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  enableEditMode: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onStatusChange: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  removeAttentionDot: T.func.isRequired,
  openModal: T.func.isRequired,
  isOnline: T.bool.isRequired,
  openDropdown: T.func.isRequired
};

Inventory.contextTypes = {
  router: T.object.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      reqParams: state.inventoriesList.params,
      isLoading: state.inventoriesList.isLoading,
      resources: state.inventoriesList.resources,
      meta: state.inventoriesList.meta,
      companies: state.auth.user_companies,
      isOnline: state.network.isOnline,
    }), {
      fetchInventories,
      fetchUserCompanies,
      updateAdminInventory,
      openModal,
      setBreadcrumbs,
      enableEditMode,
      removeAttentionDot,
    }
  ),
  withDropdownActions,
  withDataTableController('fetchInventories', 'reqParams'),
);

export default enhance(Inventory);
