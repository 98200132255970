import React, { useState, useEffect, Fragment } from 'react';
import T from 'prop-types';
// components
import Link from 'react-router/lib/Link';
import TreatmentProductInfo from './TreatmentProductInfo';
import Translation from 'components/Translation';
import { FormattedMessage } from 'react-intl';
import Waypoint from 'components/Waypoint/Waypoint';
import InfoRow from 'components/InfoRow/InfoRow';
import DotSeparator from 'components/DotSeparator/DotSeparator';
import Collapse from 'react-collapse';
import Button from 'components/Button';
// utils
import cn from 'classnames';
import moment from 'moment';
// styles
import styles from './TreatmentStepCard.module.scss';

const formatDate = (date) => moment(date).format('MM/DD/YYYY');
const limitText = (text, limit) => (text.length > limit ? text.substring(0, limit) + '...' : text);

const TreatmentStepCard = ({
  className, hasNoDetails = false, pigGroup, treatmentStepData, onReport, reportSteps = false
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isDetailsOpened, setIsDetailsOpened] = useState(false);
  const {
    treatment_protocol_product: treatmentProtocolProduct,
    start_date: startDate, // date when treatment starts
    end_date: endDate, // date when treatment ends
    step_type: stepCondition, // possible values: [before, between, after, on]
    used_at: treatmentUsageDate,
    repeat,
  } = treatmentStepData;
  const { treatment_product, treatment_protocol } = treatmentProtocolProduct;
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);
  const pigGroupStartDate = moment(pigGroup.started_on).format('MM/DD/YYYY');
  const runCount = moment(endDate).diff(moment(startDate), 'days') + 1; // taking current day into account
  const isOutdated = moment().diff(moment(endDate), 'days') > 0; // end date > current date
  const isTreatmentExpired = repeat ?
    isOutdated && (!treatmentUsageDate ||
      moment(endDate).startOf('day').isAfter(moment(treatmentUsageDate).startOf('day'))) :
    isOutdated && !treatmentUsageDate;
  const isTreatmentCompleted = repeat ?
    (treatmentUsageDate && moment(treatmentUsageDate).startOf('day').isSameOrAfter(moment(endDate).startOf('day'))) :
    !!treatmentUsageDate;
  const isFutureTreatment = moment(startDate).isAfter(moment());

  useEffect(() => {
    if (reportSteps && onReport && !(isTreatmentCompleted || isFutureTreatment)) {
      onReport(treatmentStepData);
    }
  }, [onReport, isTreatmentCompleted, isFutureTreatment, treatmentStepData, reportSteps]);


  const toggleCollapse = () => setIsOpened(!isOpened);
  const toggleDetailsCollapse = () => setIsDetailsOpened(!isDetailsOpened);

  const getFooterLabelKey = () => {
    return `general.runTreatmentForPigs.${stepCondition}`;
  };

  const getFooterLabelValues = () => {
    return { start_date: formattedStartDate, end_date: formattedEndDate };
  };

  const renderTreatmentTimeLine = () => (
    <div className={styles['treatment-timeline']}>
      <Waypoint type="source">
        <div className="semibold">
          <InfoRow
            label={<FormattedMessage id="container.checkupIndex.row.start" />}
            value={startDate ? formatDate(startDate) : 'N/A'}
          />
        </div>
        <div className={styles['duration-wrapper']}>
          {stepCondition !== 'after' && !repeat && !!startDate && (
            <div className={styles.duration}>
              <FormattedMessage
                id={stepCondition === 'between' ? 'general.runBetweenDaysCount' : 'general.runDaysCount'}
                values={{ days_count: <FormattedMessage id="general.daysCount" values={{ count: runCount }} /> }}
              />
            </div>
          )}
          {stepCondition === 'between' && repeat && (
            <div className={styles.duration}>
              <FormattedMessage
                id="general.runEverydayInDaysCount"
                values={{ days_count: <FormattedMessage id="general.daysCount" values={{ count: runCount }} /> }}
              />
            </div>
          )}
        </div>
      </Waypoint>
      <Waypoint icon={<i className={cn('fa fa-times', styles['end-icon'])} />} isEnd>
        <div className="semibold">
          <InfoRow
            label={<FormattedMessage id="general.treatmentWindowEnds" />}
            value={endDate ? formattedEndDate : <FormattedMessage id="general.notAvail" />}
          />
        </div>
      </Waypoint>
    </div>
  );

  return (
    <div className={cn(styles['treatment-step-card'], className)}>
      <div
        className={cn(styles['status-line'], {
          [styles.completed]: isTreatmentCompleted,
          [styles.expired]: isTreatmentExpired,
        })}
      >
        <InfoRow
          label={<FormattedMessage id="general.treatment" />}
          value={(<FormattedMessage id="general.arrivingPigsOnDate" values={{ date: pigGroupStartDate }} />)}
        />
        {treatmentUsageDate && (
          <InfoRow
            className={styles['usage-row']}
            label={<FormattedMessage id="general.usedOn" />}
            value={moment(treatmentUsageDate).format('MM/DD/YYYY, hh:mm')}
          />
        )}

        {isTreatmentExpired && <div className={styles['expired-label']}><FormattedMessage id="general.expired" /></div>}
      </div>

      {/* HEADER */}
      <div className={styles['card-header']}>
        <i
          className={cn({
            [styles['complete-icon']]: isTreatmentCompleted,
            [styles['active-icon']]: !isTreatmentCompleted,
            'fa fa-check-circle-bts': isTreatmentCompleted,
          })}
        />
        <span className="semibold nowrap-ellipsis pl-20">
          {treatmentProtocolProduct.treatment_product.name}
          <DotSeparator />
          {treatmentProtocolProduct.treatment_protocol.name}
        </span>

        <div className={styles['btn-group']}>

          {isTreatmentCompleted && (
            <span className={styles['complete-label']}>
              <FormattedMessage id="general.completedDate" values={{ date: formatDate(treatmentUsageDate) }} />
            </span>
          )}

          {onReport && !(isTreatmentCompleted || isFutureTreatment) && (
            <Button
              primary
              onClick={() => onReport(treatmentStepData)}
              className={cn(styles['submit-button'], { 'mr-0': hasNoDetails })}
            >
              <FormattedMessage id="general.report" />
            </Button>
          )}

          {!hasNoDetails && (
            <i
              onClick={toggleCollapse}
              className={cn('fa fa-angle-right', styles['collapse-icon'], { [styles.opened]: isOpened })}
            />
          )}
        </div>

      </div>

      {/* CONTENT */}
      {!hasNoDetails && (
        <Collapse isOpened={isOpened}>
          <div className={styles['collapse-content']}>
            <div className={styles['general-info']}>
              {renderTreatmentTimeLine()}
              <div className={styles['product-info']}>
                <div className="semibold">{treatment_product.name}</div>
                <InfoRow
                  label={<span className="semibold"><FormattedMessage id="general.headTreated" /></span>}
                  value={treatmentProtocolProduct.head_treated}
                />
                <InfoRow
                  label={<span className="semibold"><FormattedMessage id="general.sourceFarm" /></span>}
                  value={
                    treatment_protocol?.farm_treatment_protocol?.farm?.name ||
                    <FormattedMessage id="general.notAvail" />
                  }
                />
              </div>
            </div>
            <TreatmentProductInfo treatmentProtocolProduct={treatmentProtocolProduct} />
          </div>
        </Collapse>
      )}

      <div className={styles['mobile-content']}>
        {!hasNoDetails && renderTreatmentTimeLine()}
        <div onClick={toggleDetailsCollapse} className={styles['treatment-details']}>
          <div className="semibold">
            <FormattedMessage id="general.treatmentDetails" />
          </div>
          <i className={cn('fa fa-angle-right', styles['collapse-icon'], { [styles.opened]: isDetailsOpened })} />
        </div>
        <Collapse isOpened={isDetailsOpened}>
          <div className={styles['notes-section']}>
            <div className={styles['notes-item']}>
              <div className="semibold">
                <FormattedMessage id="general.medicationInformation" />
              </div>
              {treatmentProtocolProduct.notes ? (
                <Fragment>
                  <Translation text={limitText(treatmentProtocolProduct.notes, 128)}>
                    <span>{limitText(treatmentProtocolProduct.notes, 128)}</span>
                  </Translation>
                  <div>
                    <Link
                      target="_blank"
                      className="link"
                      to={`/admin/health-variables/medications/${treatmentProtocolProduct.id}/information`}
                    >
                      <FormattedMessage id="general.readMore" />
                    </Link>
                  </div>
                </Fragment>
              ) : <FormattedMessage id="general.notAvail" />}
            </div>

            <div className={styles['notes-item']}>
              <div className="semibold mt-15">
                <FormattedMessage id="general.dosageNotes" />
              </div>

              {treatmentProtocolProduct.dosage_comment ? (
                <Translation text={treatmentProtocolProduct.dosage_comment}>
                  <span>{treatmentProtocolProduct.dosage_comment}</span>
                </Translation>
              ) : <FormattedMessage id="general.notAvail" />}
            </div>

            <div className={styles['notes-item']}>
              <div className="semibold mt-15">
                <FormattedMessage id="general.productNotes" />
              </div>
              {treatmentProtocolProduct.comment ? (
                <Translation text={treatmentProtocolProduct.comment}>
                  <span>{treatmentProtocolProduct.comment}</span>
                </Translation>
              ) : <FormattedMessage id="general.notAvail" />}
            </div>

            <div className={styles['notes-item']}>
              <div className="semibold mt-15">
                <FormattedMessage id="general.treatmentProtocolNotes" />
              </div>
              {treatmentProtocolProduct.treatment_protocol.comment ? (
                <Translation text={treatmentProtocolProduct.treatment_protocol.comment}>
                  <span>{treatmentProtocolProduct.treatment_protocol.comment}</span>
                </Translation>
              ) : <FormattedMessage id="general.notAvail" />}
            </div>
          </div>
        </Collapse>
        {onReport && !(isTreatmentCompleted || isFutureTreatment) && (
          <Button primary onClick={() => onReport(treatmentStepData)} className={cn(styles['submit-button'])}>
            <FormattedMessage id="general.report" />
          </Button>
        )}
      </div>

      {/* FOOTER */}
      <div className={cn(styles['card-footer'], { [styles.hidden]: isOpened })}>
        <InfoRow
          className="nowrap-ellipsis"
          label={<FormattedMessage id="general.treatmentDirections" />}
          value={(
            <FormattedMessage
              id={getFooterLabelKey()}
              values={{ ...getFooterLabelValues(), arrive_date: pigGroupStartDate, b: (msg) => <b>{msg}</b> }}
            />
          )}
        />
      </div>
    </div>
  );
};

TreatmentStepCard.propTypes = {
  className: T.string,
  hasNoDetails: T.bool,
  pigGroup: T.object.isRequired,
  treatmentStepData: T.object.isRequired,
  onReport: T.func,
  reportSteps: T.bool,
};

export default TreatmentStepCard;
