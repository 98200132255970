import React, { memo, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { deleteDirectPostedActivity } from 'reducers/farmfeed';
import { openViewer } from 'reducers/mediaViewer';
import { openModal } from 'reducers/modals';
// lib components
import { FormattedMessage } from 'react-intl';
import Linkify from 'react-linkify';
import ReactPlayer from 'react-player';
import TruncateMarkup from 'react-truncate-markup';
// components
import ActivityActionsBar from './ActivityActionsBar/ActivityActionsBar';
import ActivityAuthorLine from './ActivityAuthorLine/ActivityAuthorLine';
import ActivityAssets from './ActivityAssets/ActivityAssets';
import ActivityBodyCollapse from './ActivityBodyCollapse';
import ActivityBox from './ActivityBox';
import ActivityHeader from './ActivityHeader';
import ActivityMentions from './ActivityMentions';
import AudioPreview from '../AudioPreview/AudioPreview';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import FarmFeedRowComments from 'components/FarmFeed/FarmFeedRowComments';
import FarmFeedRowAddComment from 'components/FarmFeed/FarmFeedRowAddComment';
import FilePreview from 'components/FilePreview/FilePreview';
import LastCommentInfo from './LastCommentInfo/LastCommentInfo';
import ResponsivePlayer from 'components/ResponsivePlayer/ResponsivePlayer';
import Translation from 'components/Translation';
// hooks
import useActivityController from 'hooks/useActivityController';
// utils
import { isAudioAsset, isEqualKeys, isImageAsset, isVideoAsset, isPDFAsset } from 'utils';
import classnames from 'classnames/bind';
import isEmpty from 'lodash.isempty';
import getUrls from 'get-urls';
// styles
import styles from './DirectPostActivity.module.scss';

const cn = classnames.bind(styles);

const affectRerenderKeys = ['id', 'updated_at', 'is_viewed', 'flagged', 'comments', 'assets'];
const linkifyProps = { target: '_blank', style: { color: '#ff5e31' } };

const DirectPostActivity = (props) => {
  const [shouldTruncate, setShouldTruncate] = useState(true);
  const [isMessageExpanded, setIsMessageExpanded] = useState(false);
  const {
    defaultDropdownOptions,
    isMinimized,
    onToggleMinimized,
  } = useActivityController(props);
  const { activity, currentUser, deleteDirectPostedActivity, markAsSeen, openDropdown, openModal,
    openViewer, setActivityFlag } = props;
  const {
    id,
    flagged,
    is_viewed,
    message,
    mentions,
    company,
    farm,
    user,
    assets,
    pig_group,
    pig_group_mentions,
    farm_mentions,
  } = activity;
  const msgClass = message.length > 100 ? 'large-message' : 'small-message';
  const filteredAssets = assets.filter((asset) => asset.media_type);
  const urlsSet = getUrls(message);
  const videoUrls = [];
  urlsSet.forEach((url) => {
    if (ReactPlayer.canPlay(url)) videoUrls.push(url);
  });
  const videoUrl = videoUrls.length ? [videoUrls.shift()] : [];
  const imgVideoPdfAssets = assets.filter((asset) => isImageAsset(asset) || isVideoAsset(asset) || isPDFAsset(asset));

  const handleOpenAssetInViewer = (assets, index, data) => {
    openViewer(assets, index, data, 'direct-post');
  };

  const handleDeletePost = () => {
    openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.deleteDirectPost" />}
        actionBtnLabel={<FormattedMessage id="component.modal.deleteDirectPost.confirm" />}
        actionBtnProps={{ red: true }}
        handleConfirm={() => deleteDirectPostedActivity(activity.id)}
      >
        <FormattedMessage id="component.modal.deleteDirectPost.text" tagName="p" />
      </ConfirmationModal>
    );
  };

  const handleOpenDropdown = (e) => {
    openDropdown(e, [
      ...defaultDropdownOptions,
      { label: <FormattedMessage id="general.button.delete" />, onClick: handleDeletePost, icon: 'trash-o',
        hide: currentUser.id !== user?.id },
    ]);
  };

  const handleTruncate = (isTruncated) => {
    if (!isTruncated) setShouldTruncate(isTruncated);
  };

  return (
    <ActivityBox isFlagged={flagged} isUnseen={!is_viewed} markAsSeen={markAsSeen}>
      {!isMinimized && <LastCommentInfo activity={activity} currentUser={currentUser} />}
      <ActivityHeader activityId={id} onOpenDropdown={handleOpenDropdown} onDoubleClick={onToggleMinimized}>
        <ActivityAuthorLine
          activity={activity}
          currentUser={currentUser}
          minimized={isMinimized}
        >
          <FormattedMessage
            tagName="div"
            id="container.farmfeed.row.reason.direct"
            values={{
              ownPost: currentUser.id === user.id ? 'yes' : 'no',
              name: user.full_name,
              b: (msg) => <b>{msg}</b>,
            }}
          />
        </ActivityAuthorLine>
      </ActivityHeader>

      <ActivityBodyCollapse isClosed={isMinimized}>
        <div className={cn('direct-post-box')}>
          <div className={cn('message', msgClass)}>
            <Linkify properties={linkifyProps}>
              {isMessageExpanded && (
                <Translation text={message}>
                  <div>
                    {message}
                    <span className={cn('see-more')} onClick={() => setIsMessageExpanded((prevState) => !prevState)}>
                      &nbsp;...<FormattedMessage id="general.seeLess" />
                    </span>
                  </div>
                </Translation>
              )}
              {!isMessageExpanded && shouldTruncate && (
                <TruncateMarkup
                  lines={5}
                  onTruncate={handleTruncate}
                  ellipsis={(
                    <span className={cn('see-more')} onClick={() => setIsMessageExpanded((prevState) => !prevState)}>
                      &nbsp;...<FormattedMessage id="general.seeMore" />
                    </span>
                  )}
                >
                  <div>{message}</div>
                </TruncateMarkup>
              )}
              {!isMessageExpanded && !shouldTruncate && (
                <Translation text={message}>
                  <div>{message}</div>
                </Translation>
              )}
            </Linkify>
          </div>

          {!!videoUrl.length && videoUrl.map((url, ind) => (
            <div key={`video-${ind}`} style={{ margin: '20px 0' }}>
              <ResponsivePlayer url={url} />
            </div>
          ))}

          <ActivityMentions
            className="mt-15"
            company={company}
            group={pig_group}
            farm={farm}
            users={mentions}
            groups={pig_group_mentions}
            farms={farm_mentions}
            isAdmin={currentUser.roles_map.admin}
          />

          {!isEmpty(filteredAssets) && (
            <>
              {!!imgVideoPdfAssets.length && (
                <ActivityAssets
                  className="mv-15"
                  activity={activity}
                  assets={imgVideoPdfAssets}
                  onAssetPreview={handleOpenAssetInViewer}
                />
              )}
              {filteredAssets.map((asset, index) => isAudioAsset(asset) && (
                <div key={`${index}-${asset.id}`}>
                  <AudioPreview
                    controlSize="40"
                    asset={asset}
                    hideAction
                  />
                </div>
              ))}
              {filteredAssets.map((asset, index) => (
                !isAudioAsset(asset) && !isVideoAsset(asset) && !isImageAsset(asset) && !isPDFAsset(asset) &&
                (<FilePreview key={index} asset={asset} />)
              ))}
            </>
          )}
        </div>

        <ActivityActionsBar
          activity={activity}
          currentUser={currentUser}
          openDropdown={openDropdown}
          setActivityFlag={setActivityFlag}
        />

        {(isEmpty(company) || currentUser.current_company.comment_company_direct_post) && (
          <>
            <FarmFeedRowComments activity={activity} />
            <FarmFeedRowAddComment activity={activity} />
          </>
        )}

      </ActivityBodyCollapse>
    </ActivityBox>
  );
};

DirectPostActivity.propTypes = {
  activity: T.object.isRequired,
  currentUser: T.object.isRequired,
  markAsSeen: T.func,
  openViewer: T.func.isRequired,
  openModal: T.func.isRequired,
  openDropdown: T.func.isRequired,
  setActivityFlag: T.func.isRequired,
  deleteDirectPostedActivity: T.func.isRequired,
};

export default memo(
  connect(null, { openViewer, openModal, deleteDirectPostedActivity })(DirectPostActivity), (prevProps, nextProps) => {
    return isEqualKeys(affectRerenderKeys, prevProps.activity, nextProps.activity);
  }
);
