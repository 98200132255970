import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import VerticalAligner from 'components/VerticalAligner';
// utils
import cn from 'classnames';
// styles
import styles from './CollapseTitle.module.scss';

function renderBadgeLabel(textKey) {
  return (
    <FormattedMessage id={textKey}>
      {(text) => <span className={styles['notes-badge']}>{text}</span>}
    </FormattedMessage>
  );
}

const CollapseTitle = ({
  hasTriggerIcon,
  hasNotesBadge,
  hasEditedBadge,
  children,
  hasEdgeBadge,
  hasDicamBadge,
  hasBarnToolBadge,
  hasDistynctBadge,
}) => (
  <VerticalAligner>
    {hasTriggerIcon && (
      <i className={cn('fa fa-exclamation-triangle-bts mr-5', styles['trigger-icon'])} />
    )}
    {children}
    {hasNotesBadge && renderBadgeLabel('general.notes')}
    {hasEdgeBadge && renderBadgeLabel('general.edgeControllerTitle')}
    {hasDicamBadge && renderBadgeLabel('general.dicamControllerTitle')}
    {hasBarnToolBadge && renderBadgeLabel('general.barnToolControllerTitle')}
    {hasDistynctBadge && renderBadgeLabel('general.distynctControllerTitle')}
    {hasEditedBadge && (
      <span className={styles['edited-badge']}>
        <FormattedMessage id="general.button.edited" />
      </span>
    )}
  </VerticalAligner>
);

CollapseTitle.propTypes = {
  hasTriggerIcon: T.bool.isRequired,
  hasNotesBadge: T.bool.isRequired,
  hasDicamBadge: T.bool,
  hasEdgeBadge: T.bool,
  hasBarnToolBadge: T.bool,
  hasDistynctBadge: T.bool,
  hasEditedBadge: T.bool.isRequired,
  children: T.oneOfType([T.arrayOf(T.node), T.node]),
};

export default CollapseTitle;
