import uuid from 'uuid';

export const validateReportTemplate = (values) => {
  if (values.frequency_type !== 'never') return !!values?.name && !!values?.frequency && !!values?.frequency_unit;
  return !!values?.name;
};

export const formatReportTemplateCreatePayload = (reportTemplate) => ({
  id: reportTemplate.id,
  name: reportTemplate.name,
  active: reportTemplate.active,
  frequency: reportTemplate.frequency,
  frequency_type: reportTemplate.frequency_type,
  frequency_unit: reportTemplate.frequency_unit,
  farmfeed_event: reportTemplate.farmfeed_event,
  general_notes_section: reportTemplate.general_notes_section,
  action_plan_section: reportTemplate.action_plan_section,
  photo_video_uploads: reportTemplate.photo_video_uploads,
  disabled_farm_types: reportTemplate.disabled_farm_types,
  svr_categories_attributes: reportTemplate.svr_categories.map((category) => ({
    item_key: category.name,
    svr_questions_attributes: category.svr_questions.map((question, position) => ({
      position,
      item_key: question.label,
      is_enabled: question.is_enabled,
    }))
  }))
});

// TODO: simplify logic with deleted questions and categories

export const formatReportTemplateUpdatePayload = (reportTemplate, initialReportTemplate) => {
  /**
   * Comparing updated and original templates to detect which categories where deleted
   */
  const initialCategories = initialReportTemplate.svr_categories;
  const deletedCategories = initialCategories.filter(({ id }) => {
    return !reportTemplate.svr_categories.find((category) => category.id === id);
  });

  return {
    id: reportTemplate.id,
    name: reportTemplate.name,
    active: reportTemplate.active,
    frequency: reportTemplate.frequency,
    frequency_type: reportTemplate.frequency_type,
    frequency_unit: reportTemplate.frequency_unit,
    farmfeed_event: reportTemplate.farmfeed_event,
    general_notes_section: reportTemplate.general_notes_section,
    action_plan_section: reportTemplate.action_plan_section,
    photo_video_uploads: reportTemplate.photo_video_uploads,
    disabled_farm_types: reportTemplate.disabled_farm_types,
    svr_categories_attributes: [
      ...deletedCategories.map((category) => ({ ...category, deleted: true })),
      ...reportTemplate.svr_categories.map(({ id: categoryId, svr_questions, name }) => {
        const initialCategoryQuestions = initialCategories.find(({ id }) => id === categoryId)?.svr_questions || [];
        const deleteQuestions = initialCategoryQuestions.filter(({ id }) => {
          return !svr_questions.find((question) => question.id === id);
        });

        return {
          id: categoryId,
          item_key: name,
          svr_questions_attributes: [
            ...deleteQuestions.map((question) => ({ ...question, deleted: true })),
            ...svr_questions.map(({ id, label: item_key, is_enabled }, index) => ({
              id, position: index, is_enabled, item_key,
            }))
          ]
        };
      }),
    ]
  };
};

/**
 * Adding unique key, we need unique identifier for correct drag and drop work
 */
export const formatReportTemplateResponse = (reportTemplate) => ({
  ...reportTemplate,
  disabled_farm_types: reportTemplate.disabled_farm_types || [],
  svr_categories: reportTemplate.svr_categories.map((category) => ({
    ...category,
    name: category.item_key,
    key: uuid(),
    svr_questions: category.svr_questions.map((question) => ({ ...question, label: question.item_key, key: uuid() }))
  }))
});
