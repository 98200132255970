import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
// components
import { Link } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import CompositeAddressField from 'components/ReduxFormFields/CompositeAddressField/CompositeAddressField';
import FormField from 'components/ReduxFormFields/FormField/FormField';
import SelectField from 'components/ReduxFormFields/SelectField/SelectField';
import Button from 'components/Button';
import TenantCheckboxRow from '../TenantCheckboxRow';
import TenantStatusRow from '../TenantStatusRow';
// constants
import { tenantSettingsCheckboxes } from 'constants.js';
import { langOptions } from 'utils/localizationHelper';

const EditTenantForm = ({ handleSubmit, pristine, submitting, countryCode, status, everypig_basic, change }) => {
  const { formatMessage } = useIntl();
  const namePlaceholder = formatMessage({ id: 'general.companyName' });
  const isSchedulingAccessOnly = status === 'only_logistics';

  useEffect(() => {
    if (everypig_basic) {
      change('ai_mortality_prediction', false);
      change('ai_treatment_prediction', false);
      change('ai_img_prediction', false);
      change('ai_diagnosis_prediction', false);
      change('admin_treatment_protocols', false);
      change('admin_svrs', false);
      change('logistics', false);
      change('virtual_visits', false);
    }
  }, [everypig_basic, change]);

  return (
    <form onSubmit={handleSubmit} className="CreateTenantForm">
      <div className="row">
        <div className="small-12 medium-8 column tenant-form-rows">
          <div className="row">
            <div className="small-12 column">
              <label>
                <Field
                  label={namePlaceholder}
                  name="name"
                  component={FormField}
                  type="text"
                  placeholder={namePlaceholder}
                />
              </label>
            </div>
          </div>

          {countryCode &&
            <CompositeAddressField countryCode={countryCode} />}

          <div className="row">
            <div className="small-12 medium-6 column">
              <label>
                <Field
                  label={<FormattedMessage id="general.pageTitle.language" />}
                  name="language"
                  component={SelectField}
                  options={langOptions}
                  valueKey="value"
                  labelKey="label"
                />
              </label>
            </div>
          </div>

          <TenantStatusRow />

          {tenantSettingsCheckboxes.map((item, index) => (
            <TenantCheckboxRow
              key={item.name}
              fieldData={{ ...item, isDisabled: isSchedulingAccessOnly }}
              isFirstRow={index === 0}
              isLastRow={tenantSettingsCheckboxes.length === (index + 1)}
              everypig_basic={everypig_basic}
            />
          ))}

          {/* Button */}
          <div className="row mt-30">
            <div className="small-12 column">
              <Button type="submit" className="button primary" disabled={pristine || submitting}>
                <FormattedMessage id="general.button.saveChanges" />
              </Button>
              <Link to="/super-admin/tenants" className="button btn-link margin-left-1">
                <FormattedMessage id="general.button.cancel" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

EditTenantForm.propTypes = {
  handleSubmit: T.func.isRequired,
  pristine: T.bool,
  submitting: T.bool,
  countryCode: T.string,
  status: T.string,
  everypig_basic: T.bool,
  change: T.func.isRequired,
};

const selector = formValueSelector('edit-tenant');

const formComponent = reduxForm({
  form: 'edit-tenant',
})(EditTenantForm);

export default connect((state) => ({
  countryCode: selector(state, 'country_code'),
  status: selector(state, 'status'),
  everypig_basic: selector(state, 'everypig_basic'),
}))(formComponent);
