import React, { useMemo } from 'react';
import T from 'prop-types';
// components
import SidebarItem from './SidebarItem';
import SidebarDropdownItem from './SidebarDropdownItem';
import { FormattedMessage } from 'react-intl';
// styles
import cn from 'classnames';
import './SidebarGroup.scss';

const renderItems = (items) => items.map((item, idx) => <SidebarItem key={idx} item={item} />);

const SidebarGroup = ({ items, isCollapsed, isExpired, sidebarCollapsed }) => {
  const isSecondaryItemsVisible = useMemo(() => items.secondary.some(({ hide }) => !hide), [items]);
  const isMoreItemsVisible = useMemo(() => items.more.some(({ hide }) => !hide), [items]);

  return (
    <div className="SidebarGroup">
      {isExpired && (
      <ul className={cn('group-list', { 'is-collapsed': isCollapsed })}>
        {renderItems(items.expired)}
      </ul>
      )}

      {!isExpired && (
      <ul className={cn('group-list', { 'is-collapsed': isCollapsed })}>
        {renderItems(items.primary)}

        {(isSecondaryItemsVisible || isMoreItemsVisible) && <li className="divider" />}

        {renderItems(items.secondary)}
        {!sidebarCollapsed && isMoreItemsVisible && renderItems(items.more)}

        {sidebarCollapsed && isMoreItemsVisible && (
          <SidebarDropdownItem
            label={<FormattedMessage id="component.sidebar.more" />}
            items={items.more}
            isCollapsedSidebar={isCollapsed}
            isOpenAbove
          />
        )}
      </ul>
      )}
    </div>
  );
};

SidebarGroup.propTypes = {
  items: T.object.isRequired,
  isCollapsed: T.bool,
  isExpired: T.bool,
  sidebarCollapsed: T.bool.isRequired,
};

export default SidebarGroup;
