import React, { useEffect } from 'react';
import T from 'prop-types';
// api
import { fetchPigGroupSalesResults } from 'endpoints/pigGroups';
// redux
import { connect } from 'react-redux';
import { setTableParams } from 'reducers/tableParams';
// components
import Preloader from 'components/Preloader';
import { CustomColumn } from 'components/DataTable/Columns';
import DataTable from 'components/DataTable/DataTable';
import { FormattedMessage } from 'react-intl';
import Panel from 'components/Panel';
import NothingBox from 'components/NothingBox';
import FormattedWeight from 'components/FormattedWeight';
// utils
import moment from 'moment';
import { tableNames } from 'utils/constants';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';

const BarnSheetsGroupSalesResults = ({
  tableParams,
  setTableParams,
  params: { id }
}) => {
  const {
    onPageChange,
    onPerPageChange,
    onSortChange,
    fetchData,
    data: { resources, isLoading, meta: { total } },
  } = useDataTable((params) => fetchPigGroupSalesResults(id, params), {
    setTableParams: (params) => setTableParams(tableNames.barnSheetsGroupSalesResults, params),
    tableParams,
  });

  useEffect(() => {
    fetchData(tableParams);
  }, []);

  const paginationProps = {
    onPageChange,
    onPerPageChange,
    totalItems: total,
    currentPage: tableParams.page,
    perPage: tableParams.per_page,
  };

  const renderLoadColumn = (data) => (
    <CustomColumn label={<FormattedMessage id="general.load" />}>
      {data.loading.name || <FormattedMessage id="general.notAvail" />}
    </CustomColumn>
  );

  const renderDateDeliveredColumn = (data) => (
    <CustomColumn label={<FormattedMessage id="general.dateDelivered" />}>
      {data.delivered_at ? moment(data.delivered_at).format('MM/DD/YYYY') : <FormattedMessage id="general.notAvail" />}
    </CustomColumn>
  );

  const renderHeadDeliveredColumn = (data) => (
    <CustomColumn label={<FormattedMessage id="general.headDelivered" />}>
      {data.head_count || <FormattedMessage id="general.notAvail" />}
    </CustomColumn>
  );

  const renderPrelimLoadWeightColumn = (data) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="general.prelimLoadWeight" />}>
      <FormattedWeight value={data.total_weight} />
    </CustomColumn>
  );

  const renderAvgWeightColumn = (data) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.avgWeight" />}>
      <FormattedWeight value={data.avg_pig_weight} />
    </CustomColumn>
  );

  const renderDeadOnArrivalColumn = (data) => (
    <CustomColumn label={<FormattedMessage id="general.deadOnArrival.abbr" />}>
      {data.dead_on_arrival || 0}
    </CustomColumn>
  );

  const renderPackingPlantName = (data) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="general.destinationTypes.packingPlant" />}>
      {data?.destination_loading_commitment?.packing_plant?.name || <FormattedMessage id="general.notAvail" />}
    </CustomColumn>
  );

  const columns = [
    {
      label: <FormattedMessage id="general.load" />,
      flex: '1 1 140px',
      renderer: renderLoadColumn,
      sortKey: 'loading_name',
    },
    {
      label: <FormattedMessage id="general.dateDelivered" />,
      flex: '1 1 140px',
      renderer: renderDateDeliveredColumn,
      sortKey: 'delivered_at'
    },
    {
      label: <FormattedMessage id="general.headDelivered" />,
      flex: '1 1 140px',
      renderer: renderHeadDeliveredColumn,
      sortKey: 'head_count'
    },
    {
      label: <FormattedMessage id="general.prelimLoadWeight" />,
      flex: '1 1 140px',
      renderer: renderPrelimLoadWeightColumn,
      sortKey: 'total_weight',
    },
    {
      label: <FormattedMessage id="component.dataTable.headers.avgWeight" />,
      flex: '1 1 140px',
      renderer: renderAvgWeightColumn,
      sortKey: 'avg_pig_weight',
    },
    {
      label: <FormattedMessage id="general.deadOnArrival.abbr" />,
      flex: '1 1 140px',
      renderer: renderDeadOnArrivalColumn,
      sortKey: 'dead_on_arrival'
    },
    {
      label: <FormattedMessage id="general.destinationTypes.packingPlant" />,
      flex: '1 1 140px',
      renderer: renderPackingPlantName,
      sortKey: 'packing_plant_name',
    }
  ];

  return (
    <Panel>
      <Panel.Heading className="farms-panel-title" title={<FormattedMessage id="general.salesResults" />}>
      </Panel.Heading>
      <Panel.Body noPadding>
        <Preloader isActive={isLoading} />
        <DataTable
          sort={tableParams.sort}
          data={resources}
          columns={columns}
          paginationProps={paginationProps}
          isLoading={isLoading}
          onSortChange={onSortChange}
          scrollable
        />

        <NothingBox display={!resources.length} isLoading={isLoading}>
          <FormattedMessage tagName="h2" id="component.nothingBox.noData" />
        </NothingBox>
      </Panel.Body>
    </Panel>
  );
};

BarnSheetsGroupSalesResults.propTypes = {
  tableParams: T.object.isRequired,
  params: T.object.isRequired,
  setTableParams: T.func.isRequired,
};

export default connect(
  (state) => ({
    tableParams: tableParamsSelector(state, tableNames.barnSheetsGroupSalesResults),
  }), {
    setTableParams,
  }
)(BarnSheetsGroupSalesResults);
