import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { disableEditMode } from 'reducers/barnsheets/barnsheetsGroupCheckups';
import { setPageOptions, setHeaderVisibility, setAdvancedSearchVisibility } from 'reducers/layout';
// components
import ServicesDropdown from 'components/ServicesDropdown';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import UserDropdown from './UserDropdown';
import Breadcrumbs from './Breadcrumbs';
import FarmFeedSearch from 'components/FarmFeedSearch';
import FarmFeedSearchMobile from 'components/FarmFeedSearch/FarmFeedSearchMobile';
import NotificationBadge from './NotificationBadge';
import Intercom from 'react-intercom';
import MediaQuery from 'react-responsive/src/Component';
// utils
import cn from 'classnames';
import throttle from 'lodash.throttle';
import isFunction from 'lodash.isfunction';
import { isTablet, isIOS } from 'react-device-detect';
import { editModeBackUrl } from 'containers/Barnsheets/utils/editMode';
import { getUserRolesList } from 'utils/rolesHelper';
import { isEmptyFarmFeedParamsSearch } from 'utils/farmFeedHelper';
import { isFarmFeedPage } from 'utils/locationHelper';
import { ESC_KEY, INTERCOM_APP_ID } from 'utils/constants';
// styles
import './MainHeader.scss';

class MainHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tabletLandscape: false,
    };
    this.handleScrollEvent = throttle(this.onContentScroll, 500);
    this.prevScroll = 0;
  }

  componentDidMount() {
    window.addEventListener('orientationchange', this.handleChangeOrientation);
    window.addEventListener('keyup', this.handleOnKeyUp);
    this.handleChangeOrientation();
    this.bodybag = document.getElementById('bodybag');
    this.bodybag.addEventListener('scroll', this.handleScrollEvent);
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.handleChangeOrientation);
    window.removeEventListener('keyup', this.handleOnKeyUp);
    this.bodybag.removeEventListener('scroll', this.handleScrollEvent);
  }

  onContentScroll = () => {
    const { isHeaderVisible, setHeaderVisibility } = this.props;
    if (window.innerWidth >= 1024) return;
    const scroll = this.bodybag.scrollTop;
    const isScrolledUp = scroll < this.prevScroll;
    const isScrolledDown = scroll > this.prevScroll;
    if (isScrolledUp && !isHeaderVisible) setHeaderVisibility(true);
    if (isScrolledDown && isHeaderVisible && (scroll > 55)) setHeaderVisibility(false);
    this.prevScroll = scroll;
  };

  getMobileTitle = (breadcrumbs) => {
    const { mobileTitle, renderMobileTitle } = this.props;
    if (isFunction(renderMobileTitle)) {
      return renderMobileTitle();
    }
    if (mobileTitle) {
      return <FormattedMessage id={`general.pageTitle.${mobileTitle}`} />;
    }
    const breadcrumbsLevelsCount = breadcrumbs.length;
    if (breadcrumbsLevelsCount > 0) {
      const lastLevel = breadcrumbs[breadcrumbsLevelsCount - 1];
      if (lastLevel?.useLabelAsMobileTitle) {
        return lastLevel.label;
      }
      if (lastLevel?.mobileTitle) {
        return lastLevel.mobileTitle;
      }
    }
    return '';
  };

  handleOnKeyUp = (e) => {
    const { isEditMode, isModalOpen, isViewerOpen, isSuperAdminMode } = this.props;
    const code = (e.which) ? e.which : e.keyCode;
    if (code === ESC_KEY) {
      if (isModalOpen || isViewerOpen) return;
      if (isEditMode) this.editModeOff();
      if (isSuperAdminMode) this.superAdminModeOff();
    }
  };

  editModeOff = () => {
    const { currentGroup, disableEditMode, user, setPageOptions } = this.props;
    const { router } = this.context;

    disableEditMode(currentGroup.id)
      .then(() => {
        setPageOptions({ inEditMode: false });
        const backUrl = editModeBackUrl(currentGroup, user);
        router.push(backUrl);
      });
  };

  reviewModeOff = () => {
    const { router } = this.context;
    const { id: checkupId } = router.params;
    router.push(`/barnsheets/daily-checkup/${checkupId}`);
  }

  goBack = () => {
    this.context.router.goBack();
  };

  superAdminModeOff = () => {
    this.context.router.push('/');
  };

  handleChangeOrientation = () => {
    const tabletLandscape = window.screen.orientation?.type === 'landscape-primary';
    const isIOSTabletLandscape = isIOS && window.screen.height < window.screen.width;
    if (isTablet) this.setState({ tabletLandscape: tabletLandscape || isIOSTabletLandscape });
  };

  renderBackLink = (backLink) => {
    const { tabletLandscape } = this.state;
    const classes = cn('back-link', { 'hide-for-large': !tabletLandscape });
    if (typeof backLink === 'string') return (
      <Link to={backLink} className={classes}>
        <i className="fa fa-angle-left-btb" />
      </Link>
    );
    if (typeof backLink === 'object') return (
      <Link to={backLink.link} onClick={backLink.onClick} className={classes}>
        <i className={backLink.icon || 'fa fa-angle-left-btb'} />
      </Link>
    );
    return (
      <a onClick={this.goBack} className={classes}>
        <i className="fa fa-angle-left-btb" />
      </a>
    );
  };

  render() {
    const { user, backLink, rightButton, mobileTitle, headerLinks, showAdvancedSearch, setAdvancedSearchVisibility,
      isStickyHidden, isEditMode, isSuperAdminMode, breadcrumbs, isSearchActive, isEmptyFFParamsSearch,
      location: { pathname } } = this.props;
    const { tabletLandscape } = this.state;
    const isReviewMode = !!this.props.location.query.reviewMode;

    const showEveryPigLogo = !breadcrumbs.length && !mobileTitle;

    const intercomUser = user
      ? {
        user_id: user.id,
        email: user.email,
        name: `${user.first_name} ${user.last_name}`,
        phone: user.phone || '',
        country_code: user.country_code,
        company: {
          id: user.current_company.id,
          name: user.current_company.name
        },
        user_roles: getUserRolesList(user)
      }
      : {};

    return (
      <header
        className={cn('main-header', {
          'sticky-hidden': isStickyHidden,
          'is-super-admin-mode animated fadeInDown': isSuperAdminMode,
        })}
      >

        {!!breadcrumbs.length && (
          <Breadcrumbs
            breadcrumbs={breadcrumbs}
            className={cn('show-for-large', { 'pushed-by-search': isSearchActive })}
          />
        )}

        {isSearchActive && (
          <MediaQuery minWidth={1024}>
            {(matches) => (matches ? <FarmFeedSearch /> : <FarmFeedSearchMobile />)}
          </MediaQuery>
        )}

        <div className="with-scroll" />

        {headerLinks && (
          <div className="header-navigation show-for-large">
            {headerLinks.map(({ link, label, badge, isHidden, activePaths = [] }, index) => !isHidden && (
              <Link
                key={index}
                to={link}
                activeClassName="active"
                className={cn('navigation-link', { 'active': activePaths.some((p) => pathname.includes(p)) })}
              >
                <span>{label}</span>
                {badge && <span className="badge">{badge}</span>}
              </Link>
            ))}
          </div>
        )}

        {backLink && !tabletLandscape && (this.renderBackLink(backLink))}

        <h1 className="title color-white hide-for-large">
          {this.getMobileTitle(breadcrumbs)}
        </h1>

        {showEveryPigLogo &&
          <Link to="/" className="fa fa-ep-pig mobile-logo hide-for-large" />
        }

        {rightButton && (
          <div className="mobile-btn">
            {rightButton.icon && (
              <i
                className={cn(rightButton.icon, 'right-icon hide-for-large')}
                onClick={() => rightButton.onClick?.()}
              />
            )}
            {!rightButton.icon && (
              <Button {...rightButton} primary small>
                <FormattedMessage id={`general.button.${rightButton.label}`} />
              </Button>
            )}
          </div>
        )}

        {!isSuperAdminMode && !rightButton && (
          <div className="user-brief show-for-large">
            {isFarmFeedPage(pathname) && isEmptyFFParamsSearch && (
              <i
                onClick={() => setAdvancedSearchVisibility(!showAdvancedSearch)}
                className={cn('fa fa-filter open-filter-icon right-actions-item show-for-xlarge', {
                  'active': showAdvancedSearch,
                })}
              />
            )}
            <MediaQuery minDeviceWidth={1024}>
              <ServicesDropdown user={user} className="right-actions-item" />
            </MediaQuery>
            {user && <NotificationBadge />}
            {user && <UserDropdown user={user} />}
          </div>
        )}

        {!!INTERCOM_APP_ID && (
          <Intercom
            appID={INTERCOM_APP_ID}
            {...intercomUser}
          />
        )}

        {isEditMode && (
          <div className="edit-mode-overlay animated fadeInDown">
            <FormattedMessage id="general.editMode" />
            <div className="edit-mode-off" onClick={this.editModeOff}>
              <i className="fa fa-times" />
              <FormattedMessage id="general.escapeShort" />
            </div>
          </div>
        )}

        {isReviewMode && (
          <div className="edit-mode-overlay animated fadeInDown">
            <FormattedMessage id="general.reviewMode" />
            <div className="edit-mode-off" onClick={this.reviewModeOff}>
              <i className="fa fa-times" />
              <FormattedMessage id="general.escapeShort" />
            </div>
          </div>
        )}

        {isSuperAdminMode && (
          <div className="super-admin-mode-off" onClick={this.superAdminModeOff}>
            <i className="fa fa-times" />
            <FormattedMessage id="general.escapeShort" />
          </div>
        )}
      </header>
    );
  }
}

MainHeader.contextTypes = {
  router: T.object.isRequired,
};

MainHeader.propTypes = {
  user: T.object.isRequired,
  setHeaderVisibility: T.func.isRequired,
  setAdvancedSearchVisibility: T.func.isRequired,
  showAdvancedSearch: T.bool.isRequired,
  location: T.object.isRequired,
  headerLinks: T.array,
  disableEditMode: T.func.isRequired,
  currentGroup: T.object,
  breadcrumbs: T.array.isRequired,
  setPageOptions: T.func.isRequired,
  mobileTitle: T.string,
  renderMobileTitle: T.func,
  rightButton: T.oneOfType([T.string, T.object]),
  backLink: T.oneOfType([T.string, T.object]),
  isHeaderVisible: T.bool.isRequired,
  isEditMode: T.bool,
  isModalOpen: T.bool,
  isViewerOpen: T.bool,
  isSuperAdminMode: T.bool,
  isSearchActive: T.bool.isRequired,
  isStickyHidden: T.bool.isRequired,
  isEmptyFFParamsSearch: T.bool.isRequired,
};

export default connect(
  (state) => {
    const { isHeaderHidden, inEditMode, pinHeader, showHeader } = state.layout;
    const isEmptyFFParamsSearch = isEmptyFarmFeedParamsSearch(state.farmfeed.params);
    const isStickyHidden = !(showHeader || pinHeader || inEditMode);
    return {
      isEmptyFFParamsSearch,
      isEditMode: inEditMode,
      isStickyHidden: isHeaderHidden || isStickyHidden,
      mobileTitle: state.layout.mobileTitle,
      renderMobileTitle: state.layout.renderMobileTitle,
      isHeaderVisible: state.layout.showHeader,
      showAdvancedSearch: state.layout.showAdvancedSearch,
      backLink: state.layout.backLink,
      rightButton: state.layout.rightButton,
      headerLinks: state.layout.links,
      isSearchActive: state.farmFeedSearch.show,
      isSuperAdminMode: state.layout.inSuperAdminMode,
      currentGroup: state.barnsheets.groupCheckups.pigGroup, // we need 'pigGroup' to disable editMode
      isModalOpen: state.modals.isOpen,
      isViewerOpen: state.mediaViewer.isOpen,
      breadcrumbs: state.breadcrumbs,
    };
  },
  {
    setHeaderVisibility,
    setAdvancedSearchVisibility,
    disableEditMode,
    setPageOptions,
  }
)(MainHeader);
