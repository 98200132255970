import React, { useState } from 'react';
import T from 'prop-types';
// components
import { formValueSelector, reduxForm, change } from 'redux-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router';
import CreateUserFormFields from 'components/ReduxFormFields/UserFormFields/UserFormFields';
import CircleCheckbox from 'components/CircleCheckbox';
import SelectField from 'components/ReduxFormFields/SelectField/SelectField';
import Button from 'components/Button';
import AddressSelect from './AddressSelect';
import FarmFormTooltip from './FarmFormTooltip';
import FarmMarker from 'components/GoogleMap/Markers/FarmMarker/FarmMarker';
import GoogleMapSelect from 'components/GoogleMap/GoogleMapSelect';
import Field from 'components/Field';
import ButtonRadioGroup from 'components/ButtonRadioGroup/ButtonRadioGroup';
import CoordsSelect from 'containers/Admin/Farms/components/CoordsSelect.js';
import FederalPremiseIDField from 'components/Admin/Farms/Fields/FederalPremiseIDField';
import FarmTypeField from 'components/Admin/Farms/Fields/FarmTypeField';
import FarmManagerField from 'components/Admin/Farms/Fields/FarmManagerField';
import CircleCheckboxField from 'components/Admin/Farms/Fields/CircleCheckboxField';
import Popover from 'components/Popover';
// redux
import { connect } from 'react-redux';
// constants
import { utcOffsets } from 'constants.js';
// utils, hooks
import useCountries from 'utils/hooks/useCountries';
import cn from 'classnames';
import isEmpty from 'lodash.isempty';
import { getFullAddress } from 'utils';
// constants
import { addressRadioOptions, farmsUserRoles } from 'constants/farms';
// styles
import './CreateFarmForm.scss';

const CreateFarmForm = ({
  addressObj,
  change,
  currentCompany,
  email,
  farm_name,
  farm_type,
  form,
  handleSubmit,
  hasUser,
  hasLocationDefining,
  pristine,
  submitting,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedAddressType, setAddressType] = useState('address');
  const { countries } = useCountries();
  const { formatMessage } = useIntl();

  const selected = !isEmpty(addressObj) ? getFullAddress(addressObj) : '';
  const allowSubmit = !(pristine || submitting) &&
    (!!farm_name && (hasUser === !!email) && !!addressObj && !!addressObj.city);

  const changeAddressType = (event) => {
    const { target: { value } } = event;
    setAddressType(value);
  };

  return (
    <div className="CreateFarmForm">
      <div className="form-content-block">
        <form className="farm-form-create">
          {/* FARM NAME */}
          <div className="row">
            <div className="small-12 column">
              <label>
                <Field
                  name="name"
                  label={<FormattedMessage id="general.farmName" />}
                  placeholderKey="general.farmName"
                />
              </label>
            </div>
          </div>

          <div className="row">
            <div className="small-12 column">
              <ButtonRadioGroup
                options={addressRadioOptions}
                value={selectedAddressType}
                onChange={changeAddressType}
              />
            </div>
          </div>

          {/* FARM ADDRESS */}
          <div className="row margin-top-1">
            {selectedAddressType === 'address' ? (
              <div className="small-12 column">
                <label>
                  <AddressSelect
                    label={<FormattedMessage id="general.farmAddress" />}
                    hasPoweredByLabel
                    selected={selected}
                    countriesData={countries}
                    changeValue={(name, val) => change(name, val)}
                    placeholder={<FormattedMessage id="general.typeAddress" />}
                  />
                </label>
              </div>
            ) : (
              <div className="column coords-select-form">
                <CoordsSelect
                  changeValue={(name, val) => change(name, val)}
                  countriesData={countries}
                  lat={addressObj?.lat}
                  lng={addressObj?.lng}
                />
              </div>
            )}
          </div>

          {/* FEDERAL PREMISE ID */}
          <FederalPremiseIDField name="federal_premise_id" className="row mt-15" />

          {/* FARM ID OR TATTOO NUMBER */}
          <div className="row margin-top-1">
            <div className="small-11 medium-11 column">
              <label>
                <Field
                  label={<FormattedMessage id="general.farmIdOptional" />}
                  placeholderKey="general.farmId"
                  name="external_link_id"
                />
              </label>
            </div>
            <FarmFormTooltip
              className="mt-35 pl-0 small-1 medium-1 column"
              overlay={formatMessage({ id: 'container.admin.farm.externalLinkId.hint' })}
            />
          </div>

          {/* FARM TYPE */}
          <FarmTypeField name="farm_type" />

          {/* FARM MANAGEMENT PROVIDER */}
          <FarmManagerField name="manager_id" currentCompany={currentCompany} />

          {/* UTC OFFSET  */}
          <div className="row">
            <div className="small-12 column">
              <label>
                <Field
                  label={<FormattedMessage id="general.timeZone" />}
                  placeholderKey="general.chooseTimeZone"
                  name="utc_offset"
                  component={SelectField}
                  options={utcOffsets}
                  valueKey="value"
                  labelKey="label"
                />
              </label>
            </div>
          </div>

          <CircleCheckboxField
            className="mb-15"
            name="auto_refresh_utc_offset"
            label={<FormattedMessage tagName="b" id="general.autoRefreshUTC" />}
          />

          <CircleCheckboxField
            className="mb-15"
            name="external"
            label={<FormattedMessage tagName="b" id="general.externalFarm" />}
          />

          <CircleCheckboxField
            className="mb-15"
            name="assign_external_source"
            label={(
              <Popover placement="right" content={<FormattedMessage id="general.sourcingTracking.description" />}>
                <div>
                  <FormattedMessage tagName="b" id="general.sourcingTracking" />
                </div>
              </Popover>
            )}
          />

          {farm_type === 'su' && (
            <CircleCheckboxField
              className="mb-15"
              name="loose_sow_housed"
              label={<FormattedMessage tagName="b" id="general.looseSowHoused" />}
            />
          )}

          <div className="checkbox-container">

            <div className={cn('checkbox-row', { 'selected': hasUser })}>
              <div className="row">
                <div className="small-12 column">
                  <CircleCheckbox
                    value="false"
                    onClick={() => change('has_user', !hasUser)}
                    checked={hasUser}
                    label={<FormattedMessage id="component.createFarmForm.createUserWithFarm" />}
                  />
                </div>
              </div>

              {/* USER FORM */}
              {hasUser && (
                <div className="row">
                  <div className="small-12 medium-12 column mt-20">
                    <CreateUserFormFields
                      wrap="user"
                      roles={farmsUserRoles}
                      rolesTitle={<FormattedMessage id="component.userFieldsMethods.userRolesAtFarm" />}
                      setFormValidity={setIsFormValid}
                      change={change}
                      email={email}
                      form={form}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        <div className="map-block">
          <GoogleMapSelect
            onChangeAdress={(data) => change('addressObj', data)}
            hasLocationDefining={hasLocationDefining}
            addressData={addressObj}
          >
            {(position) => <FarmMarker {...position} farm={{ farm_type }} />}
          </GoogleMapSelect>
        </div>
      </div>
      {/* BUTTON */}
      <div className="button-submit">
        <Button
          onClick={handleSubmit}
          primary
          disabled={!allowSubmit || (hasUser && !isFormValid)}
        >
          <FormattedMessage id={`general.button.${hasUser ? 'createFarmAndUser' : 'createFarm'}`} />
        </Button>
        <Link to="/admin/farms" className="button btn-link margin-left-1">
          <FormattedMessage id="general.button.cancel" />
        </Link>
      </div>
    </div>
  );
};

CreateFarmForm.propTypes = {
  handleSubmit: T.func.isRequired,
  change: T.func.isRequired,
  currentCompany: T.object.isRequired,
  hasLocationDefining: T.bool,
  addressObj: T.object,
  hasUser: T.bool,
  pristine: T.bool,
  submitting: T.bool,
  farm_name: T.string,
  email: T.string,
  utc_offset: T.number,
  address: T.string,
  state: T.string,
  zipcode: T.any,
  lat: T.number,
  lng: T.number,
  farm_type: T.string,
  form: T.string,
};

const selector = formValueSelector('create-farm');
const formComponent = reduxForm({
  form: 'create-farm',
})(CreateFarmForm);

export default connect(
  (state) => ({
    farm_name: selector(state, 'name'),
    farm_type: selector(state, 'farm_type'),
    hasUser: selector(state, 'has_user'),
    utc_offset: selector(state, 'utc_offset'),
    email: selector(state, 'user[email]'),
    first_name: selector(state, 'user[first_name]'),
    last_name: selector(state, 'user[last_name]'),
    addressObj: selector(state, 'addressObj'),
    currentCompany: state.auth.user.current_company,
  }), {
    change,
  }
)(formComponent);
