import React from 'react';
import T from 'prop-types';
// components
import Field from 'components/Field';
import SelectField from 'components/ReduxFormFields/SelectField/SelectField';
import { useIntl, FormattedMessage } from 'react-intl';
import TrashButton from 'components/TrashButton';
import Divider from 'components/Divider';
import Collapse from 'components/Collapse/Collapse';
import TreatmentSteps from './TreatmentSteps';
import CircleCheckbox from 'components/CircleCheckbox';
import CommentBox from 'components/CommentBox';
import ToggleSwitcher from 'components/ToggleSwitcher';
import Translation from 'components/Translation';
import MessageBox from 'components/MessageBox';
import Input from 'components/Input/Input';
// redux
import { change } from 'redux-form';
import { connect } from 'react-redux';
// utils
import cn from 'classnames';
import { getMedType } from 'utils';
// constants
import { treatmentProtocolFormName, treatmentProtocolFormSelector } from './helper';
// styles
import styles from './Treatment.module.scss';

const isProductWaterMed = (product) => product?.route?.includes('oral');

const treatmentDurationTypes = [
  {
    label: <FormattedMessage id="general.oneStepTreatment" />,
    isChecked: (steps) => steps.length === 1,
    type: 'one_step',
  },
  {
    label: <FormattedMessage id="general.multiStepTreatment" />,
    isChecked: (steps) => steps.length > 1,
    type: 'multi_step',
  },
];

const optionRenderer = (option, ABFEnabled) => {
  const className = getMedType(option);
  return (
    <span className={cn(styles.medication, { [styles.divider]: option.divider })}>
      <span>
        <i className={`fa fa-${className}`} />
        {option.name}
      </span>
      <span className={styles['right-text']}>
        {option.common && <FormattedMessage id="general.meds.common" />}
        {option.vaccine && <>(<FormattedMessage id="general.vaccine" />)</>}
        {ABFEnabled && (
          <>
            {option.safe && <>&nbsp;(<FormattedMessage id="general.safe" />)</>}
            {!option.safe && <>&nbsp;(<FormattedMessage id="general.unsafe" />)</>}
          </>
        )}
      </span>
    </span>
  );
};

const Treatment = ({ fieldName, options, onRemove, product, isProductsChangeable, change, values, ABFEnabled }) => {
  const { formatMessage } = useIntl();
  const onDurationChange = (treatmentDurationType) => () => {
    // remove all steps except first one
    if (treatmentDurationType === 'one_step') {
      change(treatmentProtocolFormName, `${fieldName}.steps`, [values.steps[0]]);
    }
    // add one step so that the length of steps equals two
    if (treatmentDurationType === 'multi_step') {
      change(treatmentProtocolFormName, `${fieldName}.steps`, [...values.steps, {}]);
    }
  };

  const onRepeatChange = ({ target: { checked } }) => {
    change(treatmentProtocolFormName, `${fieldName}.repeat`, checked);

    if (checked) {
      values.steps.forEach((step, stepIndex) => {
        change(treatmentProtocolFormName, `${fieldName}.steps[${stepIndex}].step_type`, 'between');
      });
    }
  };
  const onCommentChange = (comment) => change(treatmentProtocolFormName, `${fieldName}.comment`, comment);
  const onDosageCommentChange = (comment) => change(treatmentProtocolFormName, `${fieldName}.dosage_comment`, comment);

  return (
    <div>
      <div className={cn(styles['product-select'], 'medium-4')}>
        <Field
          disabled={!isProductsChangeable}
          options={options}
          name={`${fieldName}.product`}
          label={<FormattedMessage id="general.product" />}
          optionRenderer={(option) => optionRenderer(option, ABFEnabled)}
          labelKey="name"
          valueKey="id"
          component={({ input, ...rest }) => <SelectField input={input} {...rest} onChange={input.onChange} />}
        />
        {onRemove && isProductsChangeable && <TrashButton className={styles['trash-btn']} onClick={onRemove} />}
      </div>
      {product && (
        <div className={styles['product-section']}>
          {ABFEnabled && !product.safe && (
            <div className="mt-5">
              <MessageBox type="warning" className="message-box">
                <FormattedMessage id="general.protocolTreatmentUnsafe.warning" />
              </MessageBox>
            </div>
          )}
          {/* PRODUCT INFO */}
          {product.notes && (
            <Collapse
              className={cn(styles['section-collapse'], 'mb-10')}
              title={<FormattedMessage id="general.information" />}
            >

              <div className={styles['collapse-content']}>
                <Translation text={product.notes}>
                  {product.notes}
                </Translation>
              </div>
            </Collapse>
          )}

          {isProductWaterMed(product) && (
            <div className={cn('mb-20')}>
              <ToggleSwitcher
                text={<FormattedMessage id="general.dailyTreatment" />}
                checked={values.repeat}
                onChange={onRepeatChange}
              >
                <FormattedMessage id="general.selectedTreatmentEveryDay" />
              </ToggleSwitcher>
            </div>
          )}

          <Collapse
            isOpenOnMount
            className={cn(styles['section-collapse'], 'mb-15')}
            title={<FormattedMessage id="general.treatmentDetails" />}
          >
            <div className={styles['collapse-content']}>
              <div className="semibold mb-10">
                <FormattedMessage id="general.treatmentInformation" />
              </div>
              <div className="row">
                <div className="small-12 medium-4 large-4 column">
                  <label>
                    <FormattedMessage id="general.unitOfMeasure">
                      {(text) => (
                        <>
                          <span className="label-tag">{text}</span>
                          <Input
                            value={product.unit_type ? formatMessage({ id: `general.${product.unit_type}` }) : ''}
                            name="unit_type"
                            type="text"
                            placeholder={text}
                            readOnly
                          />
                        </>
                      )}
                    </FormattedMessage>
                  </label>
                </div>
                <div
                  className={
                    `small-12 ${product.dosage_type === 'doses' ? 'medium-4 large-4' : 'medium-3 large-3'} column`
                  }
                >
                  <label>
                    <FormattedMessage id="general.unitSize">
                      {(text) => (
                        <>
                          <span className="label-tag">{text}</span>
                          <Input
                            value={product.unit_size || ''}
                            name="unit_size"
                            type="text"
                            placeholder={text}
                            readOnly
                          />
                        </>
                      )}
                    </FormattedMessage>
                  </label>
                </div>
                {product.dosage_type === 'doses'
                  ? (
                    <div className="small-12 medium-4 large-4 column">
                      <label>
                        <FormattedMessage id="general.dosage">
                          {(text) => (
                            <>
                              <span className="label-tag">{text}</span>
                              <Input
                                value={
                                  product.dosage_type ? formatMessage({ id: `general.${product.dosage_type}` }) : ''
                                }
                                name="dosage"
                                type="text"
                                readOnly
                              />
                            </>
                          )}
                        </FormattedMessage>
                      </label>
                    </div>
                  )
                  : (
                    <div className="small-12 medium-5 large-5 column">
                      <div className="row">
                        <div className="small-5 medium-5 large-5 column">
                          <label>
                            <FormattedMessage id="general.dosage">
                              {(text) => (
                                <>
                                  <span className="label-tag">{text}</span>
                                  <Input
                                    value={
                                      product.dosage_type ? formatMessage({ id: `general.${product.dosage_type}` }) : ''
                                    }
                                    name="dosage"
                                    type="text"
                                    readOnly
                                  />
                                </>
                              )}
                            </FormattedMessage>
                          </label>
                        </div>
                        <div className="mt-30 small-2 medium-2 large-2 column">
                          <div className="divider">/</div>
                        </div>
                        <div className="mt-20 small-5 medium-5 large-5 column">
                          <label>
                            <span className="label-tag" />
                            <Input
                              value={
                                product.dosage_measure ? formatMessage({ id: `general.${product.dosage_measure}` }) : ''
                              }
                              name="dosage_measure"
                              type="text"
                              readOnly
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              <div className="semibold mb-10">
                <FormattedMessage id="general.treatmentDuration" />
              </div>
              {treatmentDurationTypes.map(({ type, isChecked, label, isDisabled }) => (
                <div className="mb-10" key={type}>
                  <CircleCheckbox
                    type="radio"
                    label={label}
                    checked={isChecked(values.steps)}
                    onChange={onDurationChange(type)}
                    disabled={isDisabled?.(values)}
                  />
                </div>
              ))}
              <div className="mt-20">
                <TreatmentSteps isDailyTreatment={values.repeat} fieldName={`${fieldName}.steps`} product={product} />
              </div>
            </div>
          </Collapse>
        </div>
      )}
      <div className={styles['product-comment']}>
        <CommentBox
          label={<FormattedMessage id="general.addProductNote" />}
          className="no-margin"
          onValueChange={onCommentChange}
          value={values.comment}
        />
      </div>
      <div className={cn(styles['product-comment'], 'mt-15')}>
        <CommentBox
          label={<FormattedMessage id="general.addDosageNote" />}
          className="no-margin"
          onValueChange={onDosageCommentChange}
          value={values.dosage_comment}
        />
      </div>
      <Divider className="mt-15 mb-15 mh-15" />
    </div>
  );
};

Treatment.propTypes = {
  fieldName: T.string.isRequired,
  product: T.oneOfType([T.string, T.object]),
  options: T.array.isRequired,
  onRemove: T.func,
  isProductsChangeable: T.bool.isRequired,
  ABFEnabled: T.bool.isRequired,
};

export default connect((state, { fieldName }) => ({
  values: treatmentProtocolFormSelector(state, fieldName),
}), { change })(Treatment);
