import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// translations
import { FormattedMessage } from 'react-intl';
// assets
import './Insights.scss';

const Insights = ({
  children,
  isCurrentUserAdmin,
  isAnalyticsVisible,
  setBreadcrumbs,
  setPageOptions,
}) => {
  const links = [
    {
      link: '/analytics',
      label: <FormattedMessage id="general.pageTitle.analytics" />,
      hide: !(isCurrentUserAdmin || isAnalyticsVisible),
    },
    {
      link: '/auto-report',
      label: <FormattedMessage id="general.pageTitle.autoReport" />,
    },
    {
      link: '/asset-map',
      label: <FormattedMessage id="general.pageTitle.assetMap" />,
    },
    {
      link: '/treatments',
      label: <FormattedMessage id="general.pageTitle.treatments" />,
      hide: !isCurrentUserAdmin,
    },
    {
      link: '/checkins',
      label: <FormattedMessage id="component.sidebar.checkInHistory" />,
      hide: !isCurrentUserAdmin,
    },
  ];

  useEffect(() => {
    setPageOptions({ links: isCurrentUserAdmin ? links : links.filter(({ hide }) => !hide) });
    setBreadcrumbs();
    return () => {
      setPageOptions({ links: null });
    };
  }, []);

  return (
    <div className="Insights">
      {children}
    </div>
  );
};

Insights.propTypes = {
  children: T.object.isRequired,
  setPageOptions: T.func.isRequired,
  isCurrentUserAdmin: T.bool.isRequired,
  setBreadcrumbs: T.func.isRequired,
  isAnalyticsVisible: T.bool.isRequired,
};

export default connect(
  (state) => ({
    isCurrentUserAdmin: state.auth.user.roles_map.admin,
    isAnalyticsVisible: state.auth.user.analytics_extended_access,
  }), {
    setPageOptions,
    setBreadcrumbs,
  }
)(Insights);
