import React, { Fragment, useCallback } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ButtonRadioGroup from 'components/ButtonRadioGroup/ButtonRadioGroup';
import AsyncSelect from 'components/AsyncSelect/AsyncSelect';
import AsyncSelectLoadMore from 'components/AsyncSelect/AsyncSelectLoadMore';
import GroupSourceBlock from './GroupSourceBlock';
// api
import { getExternalSources } from 'endpoints/admin/externalSources';
import { getAdminPigGroupSource } from 'endpoints/admin/pigGroups';
import { searchFarms } from 'endpoints/farms';
// utils
import classnames from 'classnames/bind';
import update from 'react-addons-update';
import isEmpty from 'lodash.isempty';
import { getDefaultPigGroupSources } from 'utils/pigGroupsHelper';
// styles
import styles from './SourcesSelector.module.scss';

const cn = classnames.bind(styles);

const sourceTypeOptions = [
  { value: 'external', label: <FormattedMessage id="general.externalSource" /> },
  { value: 'internal', label: <FormattedMessage id="general.internalTransfer" /> },
];

const requestParams = { page: 1, per_page: 50 };

const SourcesSelector = ({
  className = '',
  children,
  sources = getDefaultPigGroupSources(),
  onChange,
}) => {
  const onSourceTypeChange = (index) => ({ target: { value: entity_type } }) => {
    onChange(update(sources, { [index]: { $merge: { entity_type } } }));
  };

  const onSourceChange = (index) => (data) => {
    onChange(update(sources, { [index]: { $merge: { external_source: data } } }));
  };

  const onFarmChange = (index) => (data) => {
    onChange(update(sources, { [index]: { $merge: { farm: data, source_pig_group: {} } } }));
  };

  const fetchSource = (source_pig_group, index) => {
    getAdminPigGroupSource(source_pig_group.value)
      .then((data) => {
        if (data) {
          onChange(update(
            sources,
            { [index]: { $merge: { source_pig_group: { ...source_pig_group, source: data.resource } } } }
          ));
        }
      });
  };

  const onPigGroupChange = (index) => (sourcePigGroup) => {
    if (sourcePigGroup) fetchSource(sourcePigGroup, index);
    onChange(update(sources, { [index]: { $merge: { source_pig_group: sourcePigGroup || {} } } }));
  };

  const addSource = () => {
    onChange(update(sources, { $push: getDefaultPigGroupSources() }));
  };

  const removeSource = (index) => () => {
    const [defaultSource] = getDefaultPigGroupSources();
    if (sources[index].id) onChange(update(sources, { [index]: { $merge: { ...defaultSource, _destroy: true } } }));
    else onChange(update(sources, { $splice: [[index, 1]] }));
  };

  const getActiveSources = useCallback((params) => {
    return getExternalSources({ ...params, active: true });
  }, []);

  const getFarmsWithGroups = useCallback((params) => {
    return searchFarms({ ...params, active: true, having_pig_groups: true });
  }, []);

  const activeSourcesCount = sources.filter(({ _destroy }) => !_destroy).length;
  let sourceCounter = 1;

  return (
    <div className={className}>
      {sources.map(
        ({ farm, source_pig_group = {}, external_source, entity_type, _destroy }, index) => (!_destroy ? (
          <div key={index} className="pt-10">
            <div className={cn('select-type-line')}>
              {(activeSourcesCount > 1) && (
                <div className={cn('index-cell')}>
                  <div className={cn('row-index')}>{sourceCounter++}</div>
                </div>
              )}
              <div className={cn('select-type-cell')}>
                <ButtonRadioGroup
                  options={sourceTypeOptions}
                  value={entity_type}
                  onChange={onSourceTypeChange(index)}
                />
                {entity_type === 'external' && (
                  <Fragment>
                    <div className={cn('source-type-label')}>
                      <FormattedMessage id="component.groupCreate.selectExternalSource" />
                    </div>
                    <AsyncSelectLoadMore
                      isClearable
                      selected={external_source}
                      onChange={onSourceChange(index)}
                      optionsGetter={getActiveSources}
                      placeholder={<FormattedMessage id="general.searchBy.sourceNameOrId" />}
                    />
                  </Fragment>
                )}
                {entity_type === 'internal' && (
                  <Fragment>
                    <div className={cn('source-type-label')}>
                      <FormattedMessage id="component.groupCreate.selectFarm" />
                    </div>
                    <AsyncSelectLoadMore
                      isClearable
                      selected={farm}
                      onChange={onFarmChange(index)}
                      optionsGetter={getFarmsWithGroups}
                      placeholder={<FormattedMessage id="general.searchBy.farmNameOrId" />}
                    />
                  </Fragment>
                )}
                {entity_type === 'internal' && farm?.value && (
                  <div className={cn('source-group-label', 'ml-30')}>
                    <FormattedMessage id="component.groupCreate.selectSourcedGroup" />
                  </div>
                )}
                {entity_type === 'internal' && farm?.value && (
                  <AsyncSelect
                    className="ml-30"
                    selected={source_pig_group}
                    onChange={onPigGroupChange(index)}
                    optionsPath={`/pig_groups/search?exclude_status=closed&farm_id=${farm.value}`}
                    requestParams={requestParams}
                    optionsKeys={{ value: 'id', label: 'name' }}
                    placeholder={<FormattedMessage id="general.searchBy.groupId" />}
                  />
                )}
                {entity_type === 'internal' && !isEmpty(source_pig_group.source) &&
                  <GroupSourceBlock className={cn('source-block')} source={source_pig_group.source} />
                }
              </div>
              {(activeSourcesCount > 1) && (
                <div className={cn('trash-cell')}>
                  <i className={cn('fa fa-trash-o', 'trash-icon')} onClick={removeSource(index)} />
                </div>
              )}
            </div>
          </div>
        ) : null)
      )}
      <span className={cn('add-source')} onClick={addSource}>
        <FormattedMessage id="general.addSource" />
      </span>
      {children}
    </div>
  );
};

SourcesSelector.propTypes = {
  children: T.node,
  onChange: T.func.isRequired,
  className: T.string,
  sources: T.array,
};

export default SourcesSelector;
