import React from 'react';
import T from 'prop-types';
// styles
import cn from 'classnames';
import './ExtendedHeader.scss';

const ExtendedHeader = ({ headerData: { isError, isInfo, text } }) => (
  <p className={cn('header', { info: isInfo, error: isError })}>
    <b>{text}</b>
  </p>
);

ExtendedHeader.propTypes = {
  headerData: T.shape({
    isError: T.bool,
    isInfo: T.bool,
    text: T.oneOfType([T.string, T.object]),
  }),
};

export default ExtendedHeader;
