import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Modal from 'components/Modal';
import Input from 'components/Input';
import Button from 'components/Button';
// utils
import { connect } from 'react-redux';
import uuid from 'uuid';
// store
import { closeModal } from 'reducers/modals';
// styles
import styles from './CreateCategoryModal.module.scss';

const CreateCategoryModal = ({ closeModal, onSubmit }) => {
  const [categoryName, setCategoryName] = useState('');

  const createCategory = () => {
    onSubmit({ name: categoryName, svr_questions: [], key: uuid() });
    closeModal();
  };

  const onCategoryNameChange = ({ target: { value } }) => setCategoryName(value);

  return (
    <Modal title={<FormattedMessage id="component.modal.reports.createCategory" />}>
      <section className="modal-body">
        <div className={styles['category-name-label']}>
          <FormattedMessage id="general.categoryName" />
        </div>
        <div>
          <FormattedMessage id="general.typeCategoryName">
            {(placeholder) => (
              <Input placeholder={placeholder} value={categoryName} onChange={onCategoryNameChange} />
            )}
          </FormattedMessage>
        </div>
      </section>
      <section className="modal-footer text-right">
        <Button className="margin-right-1" onClick={closeModal}>
          <FormattedMessage id="general.button.cancel" />
        </Button>
        <Button primary disabled={!categoryName} onClick={createCategory}>
          <FormattedMessage id="general.button.create" />
        </Button>
      </section>
    </Modal>
  );
};

CreateCategoryModal.propTypes = {
  closeModal: T.func.isRequired,
  onSubmit: T.func.isRequired,
};

export default connect(null, { closeModal })(CreateCategoryModal);
