import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setTableParams } from 'reducers/tableParams';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { openPortalRight } from 'reducers/rightToLeftPortal';
import { setPageOptions } from 'reducers/layout';
import { createUserFilterAction, updateUserFilterAction } from 'reducers/userFilters';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
import { useDropdownActions } from 'hooks/useDropdownContext';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import Link from 'react-router/lib/Link';
import BarnSheetsContainer from '../BarnSheetsContainer';
import SearchBox from 'components/SearchBox';
import NothingBox from 'components/NothingBox';
import TableFilter from 'components/TableFilter';
import FarmTypeLabel from 'components/FarmTypeLabel/FarmTypeLabel';
import DataTable from 'components/DataTable/DataTable';
import CustomColumn from 'components/DataTable/Columns/CustomColumn';
import ProgressPercent from 'components/ProgressBar/ProgressPercent';
import ArrowColumn from 'components/DataTable/Columns/ArrowColumn';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import UserFilter from 'components/UserFilter';
import UserFilterMobile from 'components/UserFilterMobile';
import UserFilterMobileHeader from 'components/UserFilterMobile/UserFilterMobileHeader';
// requests
import { fetchBarnSheetsFarms, applyBarnSheetsFarmsFilter } from 'endpoints/barnsheets/farms';
// utils
import cn from 'classnames';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { useMediaQuery } from 'react-responsive';
import { isTablet } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatMortality, formatNumber, formatTableValue } from 'utils';
import { tableNames } from 'utils/constants';
import { allOrAnyFarmsOptions, userFilterGroupNames, userFilterSectionTypes } from 'utils/constants/userFilters';

const tableFilters = [
  { label: <FormattedMessage id="general.allFarms" />, value: '' },
  { label: <FormattedMessage id="general.status.active" />, value: 'active' },
  { label: <FormattedMessage id="general.status.disabled" />, value: 'disabled' },
];

const BarnSheetsFarms = ({
  isAdmin,
  openPortalRight,
  setTableParams,
  setPageOptions,
  setBreadcrumbs,
  tableParams,
  createUserFilterAction,
  updateUserFilterAction,
}) => {
  const {
    data: { resources, isLoading, meta },
    fetchData,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onStatusChange,
    onSearchChange,
    onUserFilterSectionsChange,
    onUserFilterSegmentSave,
  } = useDataTable(fetchBarnSheetsFarms, {
    setTableParams: (params) => setTableParams(tableNames.barnSheetsFarms, params),
    tableParams,
    filterFetcher: applyBarnSheetsFarmsFilter,
    userFilterGroupName: userFilterGroupNames.barnSheetsFarms,
    createUserFilter: createUserFilterAction,
    updateUserFilter: updateUserFilterAction,
  });
  const { openDropdown } = useDropdownActions();
  const { formatMessage } = useIntl();
  const { page, per_page, search, sort, status, filters } = tableParams;
  const { total, stats } = meta;
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const userFilter = filters?.[0];
  const filterSectionsCount = userFilter?.sections?.length;
  const hasMobileFilterHeader = !isDesktop && filterSectionsCount > 0;

  const sections = [
    { value: userFilterSectionTypes.farm, label: formatMessage({ id: 'general.farmName' }) },
    { value: userFilterSectionTypes.farmType, label: formatMessage({ id: 'general.farmType' }) },
    { value: userFilterSectionTypes.pigsCount, label: formatMessage({ id: 'general.pageTitle.inventory' }) },
    { value: userFilterSectionTypes.openPigGroupsCount, label: formatMessage({ id: 'general.openGroups' }) },
    { value: userFilterSectionTypes.totalDeaths,
      label: formatMessage({ id: 'component.dataTable.headers.totalMortality' }) },
    { value: userFilterSectionTypes.avgMortalityRate, label: formatMessage({ id: 'general.mortalityRate' }) },
    { value: userFilterSectionTypes.avgTreated, label: formatMessage({ id: 'general.treated24Hours' }) },
    { value: userFilterSectionTypes.compliance, label: formatMessage({ id: 'general.compliance' }) },
    { value: userFilterSectionTypes.currentWaterUsage,
      label: formatMessage({ id: 'component.dataTable.headers.currentGallonsConsumed' }) },
  ];

  const handleOpenMobileFilters = () => {
    openPortalRight(
      <UserFilterMobile
        allOrAnyConditionLabel={<FormattedMessage id="component.advancedSearchItem.allFarmsFilters" />}
        appliedFilter={userFilter || {}}
        filterSections={sections}
        filterGroupName={userFilterGroupNames.barnSheetsFarms}
        setFilter={(filter) => fetchData({ ...tableParams, filters: filter ? [filter] : undefined })}
      />,
    );
  };

  const handleSetPageOptions = () => {
    setPageOptions({
      rightButton: !isDesktop ? {
        icon: 'fa fa-filter',
        onClick: handleOpenMobileFilters,
      } : '',
    });
  };

  useDidMountEffect(handleSetPageOptions, [tableParams, isDesktop]);

  useEffect(() => {
    fetchData({ ...tableParams, sort: sort || 'active desc' }).catch(toastResponseErrors);
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.pageTitle.barnsheets" />,
        path: '/barnsheets/farms',
        useLabelAsMobileTitle: true,
      },
    ]);
    handleSetPageOptions();
    return () => {
      setPageOptions({ rightButton: '' });
    };
  }, []);

  const handleOpenDropdown = (e, id) => {
    const options = [
      { label: <FormattedMessage id="general.viewBarnSheet" />, url: `/barnsheets/farms/${id}/groups` },
      { label: <FormattedMessage id="general.manageFarm" />, url: `/admin/farms/${id}`, hide: !isAdmin },
    ];
    openDropdown(e, options);
  };

  const renderFarmNameColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.farmName" />}>
      <Link to={`/barnsheets/farms/${rowData.id}/groups`} className="color-primary">{rowData.name}</Link>
    </CustomColumn>
  );

  const renderFarmTypeColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.type" />}>
      <FarmTypeLabel farmType={rowData.farm_type} />
    </CustomColumn>
  );

  const renderStartDateColumn = (rowData) => (
    <CustomColumn textRight label={<FormattedMessage id="general.openGroups" />}>
      {formatTableValue(rowData.open_pig_groups_count)}
    </CustomColumn>
  );

  const renderInventoryColumn = (rowData) => (
    <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.inventory" />}>
      {formatTableValue(rowData.pigs_count, formatNumber(rowData.pigs_count || 0))}
    </CustomColumn>
  );

  const renderMortalityColumn = (rowData) => (
    <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.mortRate" />}>
      {formatMortality(rowData.avg_mortality_rate)}
    </CustomColumn>
  );

  const renderTotalMortalityColumn = (rowData) => (
    <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.totalMortality" />}>
      {rowData.total_deaths !== null ? rowData.total_deaths : '-'}
    </CustomColumn>
  );

  const renderProgressColumn = (rowData) => (
    <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.compliance" />} noBottomBorder>
      {formatTableValue(rowData.progress, <ProgressPercent withoutBar progress={rowData.progress} />)}
    </CustomColumn>
  );

  const renderTreatedColumn = (rowData) => {
    const { avg_treated_24h } = rowData;
    return (
      <CustomColumn textRight label={<FormattedMessage id="general.treatedPeriod" values={{ period: '24HR' }} />}>
        {formatTableValue(avg_treated_24h, Number(avg_treated_24h).toFixed(2) + '%', '-')}
      </CustomColumn>
    );
  };

  const renderWaterUsageColumn = (rowData) => (
    <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.currentGallonsConsumed" />}>
      {rowData.current_water_usage ? +rowData.current_water_usage : '-'}
    </CustomColumn>
  );

  const renderButtonsColumn = (rowData, { rowIndex }) => {
    const { id } = rowData;
    return (
      <div className="collapsible-value button-column">
        <i
          data-name={`${id}-${rowIndex}`}
          className="fa fa-dots-three-horizontal show-for-large"
          onClick={(e) => handleOpenDropdown(e, id)}
        />
        <Link to={`/barnsheets/farms/${id}/groups`} className="button light hide-for-large">
          <FormattedMessage id="general.button.view" />
        </Link>
        {isAdmin && (
          <Link to={`/admin/farms/${id}`} className="button dark-grey hide-for-large">
            <FormattedMessage id="general.manageFarm" />
          </Link>
        )}
      </div>
    );
  };

  const renderExpandable = (rowData) => {
    const { id } = rowData;
    return (
      <div>
        {isAdmin && (
          <Link to={`/admin/farms/${id}`} className="button small light">
            <i className="fa fa-gear mr-5" />
            <FormattedMessage id="general.manage" />
          </Link>
        )}
        <Link to={`/barnsheets/farms/${id}/groups`} className="button small light">
          <i className="fa fa-eye mr-5" />
          <FormattedMessage id="general.viewBarnSheet" />
        </Link>
      </div>
    );
  };

  const columns = [
    { label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '1 1 140px',
      renderer: renderFarmNameColumn, sortKey: 'name' },
    { label: <FormattedMessage id="general.type" />, flex: '1 1 50px', renderer: renderFarmTypeColumn,
      sortKey: 'farm_type' },
    { label: <FormattedMessage id="general.openGroups" />, flex: '1 1 95px', renderer: renderStartDateColumn,
      sortKey: 'open_pig_groups_count', textRight: true },
    { label: <FormattedMessage id="component.dataTable.headers.inventory" />, flex: '1 1 70px',
      renderer: renderInventoryColumn, sortKey: 'pigs_count', textRight: true },
    { label: <FormattedMessage id="component.dataTable.headers.mortRate" />, flex: '1 1 100px',
      renderer: renderMortalityColumn, sortKey: 'avg_mortality_rate', textRight: true },
    { label: <FormattedMessage id="component.dataTable.headers.totalMortality" />, flex: '1 1 120px',
      renderer: renderTotalMortalityColumn, sortKey: 'total_deaths', textRight: true },
    { label: <FormattedMessage id="general.treatedPeriod" values={{ period: '24HR' }} />, flex: '1 1 120px',
      renderer: renderTreatedColumn, textRight: true },
    { label: <FormattedMessage id="component.dataTable.headers.currentGallonsConsumed" />, flex: '1 1 120px',
      renderer: renderWaterUsageColumn, sortKey: 'current_water_usage', textRight: true },
    { label: <FormattedMessage id="component.dataTable.headers.compliance" />, flex: '1 1 90px',
      renderer: renderProgressColumn, sortKey: 'progress', textRight: true },
    { label: '', flex: '0 0 45px', renderer: renderButtonsColumn, fixed: true,
      className: isTablet ? 'hide-for-large' : '' },
    { label: '', flex: '0 0 45px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet, hasPinnedIcon: true },
  ];

  const paginationProps = {
    onPageChange,
    onPerPageChange,
    totalItems: total,
    currentPage: page,
    perPage: per_page,
  };

  return (
    <BarnSheetsContainer
      className={cn('barn-sheets', { 'is-desktop': isDesktop })}
      headerContent={hasMobileFilterHeader && (
        <UserFilterMobileHeader
          onClick={handleOpenMobileFilters}
          onClose={() => fetchData({ ...tableParams, filters: undefined })}
          sectionsCount={filterSectionsCount}
          segmentName={userFilter?.name}
        />
      )}
    >
      {isDesktop && (
        <UserFilter
          isVisible
          filterGroupName={userFilterGroupNames.barnSheetsFarms}
          availFilterSections={sections}
          className="bs-filters"
          listOptions={allOrAnyFarmsOptions}
          onSectionsChange={onUserFilterSectionsChange}
          initialFilter={tableParams.filters?.[0]}
          onSave={onUserFilterSegmentSave}
        />
      )}
      <Panel className="bs-table-panel panel-padding">
        <Panel.Heading
          renderTitle={() => <h2 className="lighter show-for-large"><FormattedMessage id="general.farms" /></h2>}
        >
          <SearchBox initialValue={search} onChange={onSearchChange} />
        </Panel.Heading>
        <Panel.Body noPadding>
          <Preloader isActive={isLoading} />

          <TableFilter
            filters={tableFilters}
            onFilterChange={onStatusChange}
            activeFilter={status}
            stats={stats}
            className="mh-10"
          />

          <DataTable
            data={resources}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            isLoading={isLoading}
            renderExpandable={renderExpandable}
            scrollable
          />

          <NothingBox
            itemsName="barnsheets"
            display={!resources.length}
            isLoading={isLoading}
            search={search}
          >
            <h2 className="lighter"><FormattedMessage id="component.nothingBox.noBarnSheets" /></h2>
          </NothingBox>
        </Panel.Body>
      </Panel>
    </BarnSheetsContainer>
  );
};

BarnSheetsFarms.propTypes = {
  isAdmin: T.bool,
  openPortalRight: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  setTableParams: T.func.isRequired,
  tableParams: T.object.isRequired,
  createUserFilterAction: T.func.isRequired,
  updateUserFilterAction: T.func.isRequired,
};

export default connect(
  (state) => ({
    tableParams: tableParamsSelector(state, tableNames.barnSheetsFarms),
    isAdmin: state.auth.user.roles_map.admin,
  }), {
    openPortalRight,
    setBreadcrumbs,
    setPageOptions,
    setTableParams,
    createUserFilterAction,
    updateUserFilterAction,
  }
)(BarnSheetsFarms);
