import React, { Fragment, useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import TreatmentProtocolForm from './components/TreatmentProtocolForm';
// endpoints
import { getTreatmentProtocol, updateTreatmentProtocol } from 'endpoints/treatmentProtocols';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import {
  formatTreatmentProtocolPayload,
  formatTreatmentProtocolResponse,
  markDestroyedItems,
  validateUpdatedTreatmentProtocol,
} from './helper';

const EditTreatmentProtocol = ({
  setBreadcrumbs,
  setPageOptions,
  router: {
    params: { protocolId },
    push,
  },
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [treatmentProtocol, setTreatmentProtocol] = useState(undefined);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const fetchedTreatmentProtocol = await getTreatmentProtocol(protocolId);
      const formattedTreatmentProtocol = formatTreatmentProtocolResponse(fetchedTreatmentProtocol);
      setTreatmentProtocol(formattedTreatmentProtocol);
      setIsLoading(false);
    } catch (error) {
      toastResponseErrors(error);
      push('/treatment-protocols');
    }
  };

  useEffect(() => {
    fetchData();
    setPageOptions({ backLink: '/treatment-protocols' });
  }, []);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.globalProtocols" />,
        path: '/treatment-protocols',
      },
      {
        label: treatmentProtocol?.name || '',
        useLabelAsMobileTitle: true,
      },
    ]);
  }, [treatmentProtocol?.name]);

  const onSubmit = async (updatedTreatmentProtocol) => {
    try {
      setIsLoading(true);
      const farmIds = updatedTreatmentProtocol.applied_farms?.map(({ id }) => id);
      const markedTreatmentProtocolPayload = markDestroyedItems(updatedTreatmentProtocol, treatmentProtocol);
      const treatmentProtocolPayload = formatTreatmentProtocolPayload(markedTreatmentProtocolPayload);
      await updateTreatmentProtocol(protocolId, treatmentProtocolPayload, farmIds);
      push('/treatment-protocols');
    } catch (error) {
      toastResponseErrors(error);
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Preloader isActive={isLoading} />
      <TreatmentProtocolForm
        isGlobal
        onSubmit={onSubmit}
        validate={validateUpdatedTreatmentProtocol}
        initialValues={treatmentProtocol}
        title={<FormattedMessage id="general.editTreatmentProtocol" />}
        submitLabel={<FormattedMessage id="general.updateTreatmentProtocol" />}
      />
    </Fragment>
  );
};

EditTreatmentProtocol.propTypes = {
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  router: T.object.isRequired,
};

export default connect(null, { setPageOptions, setBreadcrumbs })(EditTreatmentProtocol);
