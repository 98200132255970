import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import SelectionSection from '../Sections/SelectionSection';
import EntityNameSection from '../Sections/EntityNameSection';
import CommonSection from '../Sections/CommonSection';
import EntityTypeSection from '../Sections/EntityTypeSection';
import DatesSection from '../Sections/DatesSection';
import EntityValueSection from '../Sections/EntityValueSection';
import MortalityRateUserFilterSection from '../Sections/MortalityRateUserFilterSection';
import InventoryUserFilterSection from '../Sections/InventoryUserFilterSection';
import MultiSelectSection from '../Sections/MultiSelectSection';
// filters constants
import { getSectionOptionValueKey } from 'utils/userFiltersHelper';
import {
  conditionsBySectionType,
  emptySectionPlaceholders,
  sectionEntityKeys,
  sectionIcons,
  sectionSpecificProps,
  userFilterSectionTypes,
} from 'utils/constants/userFilters';

const FilterSectionSwitcher = ({ type, uniqueId, ...props }) => {
  const additionalProps = {
    listOptions: conditionsBySectionType[type],
    icon: sectionIcons[type],
    placeholder: emptySectionPlaceholders[type],
  };
  switch (type) {
    case userFilterSectionTypes.aiImagePrediction:
    case userFilterSectionTypes.aiTreatmentPrediction:
    case userFilterSectionTypes.aiMortalityPrediction:
    case userFilterSectionTypes.dcAsset:
    case userFilterSectionTypes.svr:
    case userFilterSectionTypes.pigGroupStatus:
    case userFilterSectionTypes.flagged:
    case userFilterSectionTypes.pigMovements:
    case userFilterSectionTypes.withdrawalWarning:
    case userFilterSectionTypes.waterUsageWarning:
      return (
        <SelectionSection
          key={uniqueId}
          {...additionalProps}
          {...props}
        />
      );
    case userFilterSectionTypes.farmExternalLinkId:
    case userFilterSectionTypes.farmFederalPremiseId:
    case userFilterSectionTypes.farmLocation:
      return (
        <CommonSection
          key={uniqueId}
          type={type}
          {...additionalProps}
          {...props}
        />
      );
    case userFilterSectionTypes.farm:
    case userFilterSectionTypes.pigGroup:
    case userFilterSectionTypes.user:
    case userFilterSectionTypes.company:
    case userFilterSectionTypes.load:
    case userFilterSectionTypes.managementCompany:
    case userFilterSectionTypes.latestSvrCreatedBy:
      return (
        <EntityNameSection
          key={uniqueId}
          type={type}
          {...additionalProps}
          {...props}
          entity={props[sectionEntityKeys[type]]}
        />
      );
    case userFilterSectionTypes.avgMortalityRate:
    case userFilterSectionTypes.dcMortalityRate:
    case userFilterSectionTypes.mortalityRate:
      return (
        <MortalityRateUserFilterSection key={uniqueId} type={type} {...props} />
      );
    case userFilterSectionTypes.dcInventory:
    case userFilterSectionTypes.pigsCount:
      return (
        <InventoryUserFilterSection key={uniqueId} type={type} {...props} />
      );
    case userFilterSectionTypes.dcEstimatedWeight:
    case userFilterSectionTypes.weight:
    case userFilterSectionTypes.compliance:
    case userFilterSectionTypes.currentWaterUsage:
    case userFilterSectionTypes.avgTreated:
    case userFilterSectionTypes.totalDeaths:
    case userFilterSectionTypes.openPigGroupsCount:
      return (
        <EntityValueSection
          key={uniqueId}
          type={type}
          {...sectionSpecificProps[type]}
          {...additionalProps}
          {...props}
        />
      );
    case userFilterSectionTypes.date:
    case userFilterSectionTypes.pigGroupCloseDate:
    case userFilterSectionTypes.closeDate:
    case userFilterSectionTypes.pigGroupStartDate:
    case userFilterSectionTypes.startDate:
    case userFilterSectionTypes.arriveAt:
    case userFilterSectionTypes.latestSvrCreatedAt:
      return (
        <DatesSection
          key={uniqueId}
          type={type}
          inputLabel={<FormattedMessage id="general.daysAgo" />}
          {...additionalProps}
          {...props}
        />
      );
    case userFilterSectionTypes.farmType:
    case userFilterSectionTypes.loadType:
    case userFilterSectionTypes.commitmentStatus:
    case userFilterSectionTypes.loadingStatus:
      return (
        <EntityTypeSection
          key={uniqueId}
          type={type}
          nameKey={getSectionOptionValueKey(type)}
          {...additionalProps}
          {...props}
        />
      );
    case userFilterSectionTypes.dcDiagnoses:
      return (
        <MultiSelectSection
          key={uniqueId}
          type={type}
          entities={props[sectionEntityKeys[type]]}
          {...additionalProps}
          {...props}
        />
      );
    default:
      return null;
  }
};

FilterSectionSwitcher.propTypes = {
  type: T.string.isRequired,
  uniqueId: T.number.isRequired,
};

export default FilterSectionSwitcher;
