import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// redux, redux-form, selectors
import { connect } from 'react-redux';
import { closeModal } from 'reducers/modals';
import { fetchCheckupDiagnoses } from 'reducers/dailyCheckup/checkupDiagnoses';
import { fetchDiagnosesList } from 'reducers/staticData';
import { reset } from 'redux-form';
import {
  diagnoseFormInitialValuesSelector,
  checkupDiagnosesCountSelector,
} from 'store/selectors/diagnosisSidebarSelectors';
// components
import { FormattedMessage } from 'react-intl';
import SubNavigation from 'components/Subnavigation/Subnavigation';
import DiagnoseForm from 'components/DiagnoseForm/DiagnoseForm';
import CollapsesBox from 'components/CollapsesBox/CollapsesBox';
import GroupInfoCollapse from 'components/Groups/GroupInfoCollapse';
import DiagnosesCollapse from 'components/FarmFeed/GroupAnalyticsBlock/DiagnosesCollapse';
import Preloader from 'components/Preloader';
// hooks
import useDidMountEffect from 'hooks/useDidMountEffect';
// utils
import cn from 'classnames';
import withOutsideClick from 'react-onclickoutside';
// styles
import './DiagnosisSidebar.scss';

const DIAGNOSE = 'diagnose';
const GROUP_INFO = 'group-info';

const subNavItems = [
  { tab: DIAGNOSE, name: <FormattedMessage id="general.diagnose" /> },
  { tab: GROUP_INFO, name: <FormattedMessage id="general.groupInfo" /> },
];

const DiagnosisSidebar = ({
  authUser,
  checkupId,
  closeModal,
  closePortal,
  closeOnClickOutside,
  currentIndex,
  diagnosisCount,
  diagnosesList,
  diagnosisId,
  fetchCheckupDiagnoses,
  fetchDiagnosesList,
  hasTags,
  initialValues,
  isDiagnosesLoading,
  onSubmitData,
  pigGroup,
  reset,
  setIsDiagnoseFormPristine,
}) => {
  const [activeTab, setActiveTab] = useState(DIAGNOSE);

  useEffect(() => {
    fetchDiagnosesList();
  }, []);

  useEffect(() => {
    fetchCheckupDiagnoses(checkupId);
  }, [checkupId]);

  useDidMountEffect(() => {
    reset('diagnose-form');
  }, [currentIndex]);

  const handleClose = () => {
    reset('diagnose-form');
    return closePortal?.() || closeModal();
  };

  DiagnosisSidebar.handleClickOutside = () => {
    if (closeOnClickOutside) handleClose();
  };

  return (
    <div className="DiagnosisSidebar">
      <div className="sidebar-title">
        <span className="panel-title ml-20">
          <h4><FormattedMessage id="general.diagnose" /> • {pigGroup.name}</h4>
        </span>
        <div className="cancel-button mr-20" onClick={handleClose}><i className="fa fa-times" /></div>
      </div>

      <SubNavigation
        className="sub-navigation"
        isActive={(item) => item.tab === activeTab}
        items={subNavItems}
        onSelect={(selectedItem) => setActiveTab(selectedItem.tab)}
      />

      <Preloader isActive={isDiagnosesLoading} />

      <div className="sidebar-tabs">
        <div className={cn('diagnose-tab', { 'active': activeTab === DIAGNOSE })}>
          <DiagnoseForm
            hasTags={hasTags}
            checkupId={checkupId}
            diagnosesList={diagnosesList}
            setIsDiagnoseFormPristine={setIsDiagnoseFormPristine}
            closeModalFunction={handleClose}
            initialValues={initialValues}
            onSubmit={onSubmitData}
            editMode={Boolean(diagnosisId)}
            pigGroupId={pigGroup?.id}
          />
        </div>

        <div className={cn('group-info-content', { 'active': activeTab === GROUP_INFO })}>
          <CollapsesBox className="group-info-tab">
            <GroupInfoCollapse className="group-info-collapse" group={pigGroup} checkupId={checkupId} />
            <DiagnosesCollapse
              className="diagnose-collapse"
              checkupId={checkupId}
              currentUser={authUser}
              diagnosisCount={diagnosisCount}
            />
          </CollapsesBox>
        </div>
      </div>
    </div>
  );
};

DiagnosisSidebar.propTypes = {
  currentIndex: T.number.isRequired,
  diagnosesList: T.array.isRequired,
  initialValues: T.object,
  diagnosisCount: T.number,
  diagnosisId: T.number,
  checkupId: T.number,
  pigGroup: T.object,
  authUser: T.object,
  closeModal: T.func,
  closePortal: T.func,
  onSubmitData: T.func,
  fetchCheckupDiagnoses: T.func.isRequired,
  fetchDiagnosesList: T.func.isRequired,
  setIsDiagnoseFormPristine: T.func,
  reset: T.func,
  isDiagnosesLoading: T.bool,
  closeOnClickOutside: T.bool,
  hasTags: T.bool,
};

DiagnosisSidebar.defaultProps = {
  closeOnClickOutside: true,
};

const clickOutsideConfig = {
  handleClickOutside: () => DiagnosisSidebar.handleClickOutside
};

export default connect(
  (state, props) => ({
    initialValues: diagnoseFormInitialValuesSelector(state, props),
    diagnosisCount: checkupDiagnosesCountSelector(state, props),
    isDiagnosesLoading: state.dailyCheckup.checkupDiagnoses.isLoading,
    diagnosesList: state.staticData.diagnosesList.resources,
    authUser: state.auth.user,
  }),
  { reset, closeModal, fetchCheckupDiagnoses, fetchDiagnosesList }
)(withOutsideClick(DiagnosisSidebar, clickOutsideConfig));
