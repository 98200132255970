import React, { Fragment, useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import TreatmentProtocolForm from '../../components/TreatmentProtocolForm';
// endpoints
import { createFarmTreatmentProtocol } from 'endpoints/treatmentProtocols';
// utils
import { connect } from 'react-redux';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatTreatmentProtocolPayload } from '../../helper';

const initialTreatment = { product: '', steps: [{}], };
const defaultInitialValues = { treatments: [initialTreatment] };

const CreateFarmTreatmentProtocol = ({ farm, setBreadcrumbs, setPageOptions, router, isABFEnabled }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { params: { farmId }, push } = router;

  useEffect(() => {
    setPageOptions({ backLink: `/treatment-protocols-farms/${farmId}/treatment-protocols` });
  }, []);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.treatmentProtocolFarms" />,
        path: '/treatment-protocols-farms',
      },
      {
        label: farm?.name || '',
        path: `/treatment-protocols-farms/${farmId}/treatment-protocols`,
      },
      {
        label: <FormattedMessage id="general.createTreatmentProtocol" />,
        useLabelAsMobileTitle: true,
      },
    ]);
  }, [farm?.name, farmId]);

  const onSubmit = async (newProtocolData) => {
    try {
      setIsLoading(true);
      const treatmentProtocolPayload = formatTreatmentProtocolPayload(newProtocolData);
      await createFarmTreatmentProtocol(farmId, treatmentProtocolPayload);
      push(`/treatment-protocols-farms/${farmId}/treatment-protocols`);
    } catch (error) {
      toastResponseErrors(error);
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Preloader isActive={isLoading} />
      <TreatmentProtocolForm
        isCreate
        onSubmit={onSubmit}
        initialValues={defaultInitialValues}
        title={<FormattedMessage id="general.createTreatmentProtocol" />}
        submitLabel={<FormattedMessage id="general.createTreatmentProtocol" />}
        ABFEnabled={isABFEnabled}
      />
    </Fragment>
  );
};

CreateFarmTreatmentProtocol.propTypes = {
  farm: T.object.isRequired,
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  router: T.object.isRequired,
  isABFEnabled: T.bool.isRequired,
};

export default connect(
  (state) => ({
    farm: state.profiles.farm.data,
    isABFEnabled: state.auth.user.current_company.abf_tracking,
  }), {
    setPageOptions,
    setBreadcrumbs,
  }
)(CreateFarmTreatmentProtocol);
