import { useEffect } from 'react';

// endpoints
import { authValidateToken } from 'endpoints/auth';

function CurrentUserCompanyManager({ user }) {
  useEffect(() => {
    if (user.root_companies.length > 1) {
      const interval = setInterval(async () => {
        const { data: fetchedUser } = await authValidateToken();

        if (fetchedUser.current_company.id !== user.current_company.id) {
          window.location.reload();
        }
      }, 60000); // 1 minute in milliseconds

      return () => clearInterval(interval);
    }
    return () => {};
  }, [user]);

  return null;
}

export default CurrentUserCompanyManager;
