import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage } from 'react-intl';
import TreatmentProtocolsGroupRow from '../../components/TreatmentProtocolsGroupRow';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import NothingBox from 'components/NothingBox';
// endpoints
import { getTreatmentProtocolsFarmPigGroups } from 'endpoints/treatmentProtocols';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const TreatmentProtocolsFarmGroups = ({ farm, params: { farmId }, router, setBreadcrumbs, setPageOptions }) => {
  const [{ pigGroups, isGroupsLoading }, setState] = useState({
    pigGroups: [],
    isGroupsLoading: false,
  });

  const fetchData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isGroupsLoading: true }));
      const pigGroups = await getTreatmentProtocolsFarmPigGroups(farmId);
      setState({ pigGroups, isGroupsLoading: false });
    } catch (error) {
      toastResponseErrors(error);
      router.push('/treatment-protocols-farms');
    }
  };

  useEffect(() => {
    fetchData();
    setPageOptions({ backLink: '/treatment-protocols-farms' });
  }, []);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.treatmentProtocolFarms" />,
        path: '/treatment-protocols-farms',
      },
      {
        label: farm?.name || '',
        useLabelAsMobileTitle: true },
    ]);
  }, [farm?.name]);

  const onGroupSelect = ({ farm_id, id }) => {
    router.push(`/treatment-protocols-farms/${farm_id}/groups/${id}/treatment-protocols`);
  };

  return (
    <section className="small-12 column">
      <Panel className="mb-20">
        <Panel.Heading title={<FormattedMessage id="general.pageTitle.groups" />} />
        <Panel.Body className="pt-20 pb-0 ph-20">
          <Preloader isActive={isGroupsLoading} />
          {pigGroups.map((group) => (
            <TreatmentProtocolsGroupRow key={group.id} group={group} onSelect={() => onGroupSelect(group)} />
          ))}
          <NothingBox itemsName="groups" display={!pigGroups.length} isLoading={isGroupsLoading}>
            <FormattedMessage tagName="h1" id="component.nothingBox.noGroupsToDisplay" />
          </NothingBox>
        </Panel.Body>
      </Panel>
    </section>
  );
};

TreatmentProtocolsFarmGroups.propTypes = {
  farm: T.object.isRequired,
  params: T.object.isRequired,
  router: T.object.isRequired,
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
};

export default connect(
  (state) => ({
    farm: state.profiles.farm.data,
  }), {
    setBreadcrumbs,
    setPageOptions,
  }
)(TreatmentProtocolsFarmGroups);
