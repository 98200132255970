import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { ReactComponent as BasicPlanIcon } from './basic-plan-icon.svg';
// redux
import { connect } from 'react-redux';
import { closeModal } from 'reducers/modals';

const BasicPlanModal = ({ closeModal }) => (
  <Modal title={<FormattedMessage id="general.upgradeYourAccount" />}>
    <section className="modal-body text-center">
      <BasicPlanIcon />
      <h2 className="mt-20 mb-10">
        <FormattedMessage id="general.youDiscoveredLockedFeature" />
      </h2>
      <FormattedMessage
        id="general.contactEverypig"
        values={{
          helloLink: <a href="mailto:hello@everypig.com">hello@everypig.com</a>,
          b: (text) => <b>{text}</b>,
        }}
      />
    </section>
    <section className="modal-footer text-right sticky-on-mobile">
      <Button onClick={closeModal}>
        <FormattedMessage id="component.home.buttonText" />
      </Button>
    </section>
  </Modal>
);

BasicPlanModal.propTypes = {
  closeModal: T.func.isRequired,
};

export default connect(null, { closeModal })(BasicPlanModal);
