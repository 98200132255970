import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ActivityActionsBar from './ActivityActionsBar/ActivityActionsBar';
import Collapse from 'components/Collapse/Collapse';
import ActivityBox from './ActivityBox';
import ActivityBodyCollapse from './ActivityBodyCollapse';
import ActivityHeader from './ActivityHeader';
import ActivityHeaderBadge from './ActivityHeader/ActivityHeaderBadge';
import ActivityMentions from './ActivityMentions';
import FarmTypeLabel from 'components/FarmTypeLabel';
import FarmFeedRowComments from 'components/FarmFeed/FarmFeedRowComments';
import FarmFeedRowAddComment from 'components/FarmFeed/FarmFeedRowAddComment';
import FormattedWeight from 'components/FormattedWeight';
import LastCommentInfo from './LastCommentInfo/LastCommentInfo';
import TreatmentPopoverList from './TreatmentPopoverList';
import Divider from 'components/Divider';
import Link from 'react-router/lib/Link';
// utils
import classnames from 'classnames/bind';
import moment from 'moment';
// hoc
import withActivityController from './hoc/withActivityController';
// styles
import styles from './GeneralWithdrawalWarning.module.scss';

const cn = classnames.bind(styles);

const affectRerenderKeys = ['id', 'updated_at', 'is_viewed', 'flagged', 'comments', 'additional_data'];

const GeneralWithdrawalWarning = ({
  className,
  activity,
  currentUser,
  isChildActivity,
  markAsSeen,
  openDropdown,
  setActivityFlag,
  isMinimized,
  isTruncated,
  toggleMinimized,
  onViewFullActivity,
  defaultDropdownOptions,
}) => {
  const { id, events, created_at, flagged, is_viewed, mentions } = activity;
  const isAdmin = currentUser.roles_map.admin;

  const isUserHasRoles = (user, farmId) => {
    if (!user.roles_map) return false;
    const { caretaker, manager, owner, vet, admin, super_admin, tenant_owner } = user.roles_map;
    if (admin || super_admin || tenant_owner) return true;
    const companyId = user.current_company.id;
    if (farmId) {
      const isManager = manager.farm_ids.includes(farmId) || manager.company_ids.includes(companyId);
      const isOwner = owner.farm_ids.includes(farmId) || owner.company_ids.includes(companyId);
      const isVet = vet.farm_ids.includes(farmId) || vet.company_ids.includes(companyId);
      const isCaregiver = caretaker.farm_ids.includes(farmId) || caretaker.company_ids.includes(companyId);

      return isCaregiver || isManager || isOwner || isVet;
    }

    const isManager = !!manager.farm_ids.length || !!manager.company_ids.length;
    const isOwner = !!owner.farm_ids.length || !!owner.company_ids.length;
    const isVet = !!vet.farm_ids.length || !!vet.company_ids.length;
    const isCaregiver = !!caretaker.farm_ids.length || !!caretaker.company_ids.length;

    return isCaregiver || isManager || isOwner || isVet;
  };

  const renderWeightLabel = (additional_data) => {
    const { pg_estimated_weight } = additional_data;
    return <FormattedWeight value={pg_estimated_weight} />;
  };

  const renderTreatments = (additional_data) => {
    const { withdrawal_data } = additional_data;
    return <TreatmentPopoverList treatments={withdrawal_data} isCurrentUserAdmin={isAdmin} />;
  };

  const renderPigGroupName = (pig_group) => {
    if (isAdmin) {
      return (
        <Link to={`/barnsheets/groups/${pig_group.id}`} className="color-primary">
          {pig_group.name}
        </Link>
      );
    }
    return pig_group.name;
  };

  const renderFarmTitle = (farm) => {
    return <div>{farm.name}<FarmTypeLabel className="ml-5" farmType={farm.farm_type} /></div>;
  };

  const farmIds = new Set(events.map(({ farm_id }) => farm_id));
  const farmsData = [...farmIds].map((farmId) => {
    const findFarm = ({ farm_id }) => farm_id === farmId;
    const { farm } = events.find(findFarm);
    const farmEvents = events.filter(findFarm);
    return {
      farm,
      data: farmEvents.map(({ additional_data, pig_group }) => ({ additional_data, pig_group })),
    };
  }).filter(({ farm }) => isUserHasRoles(currentUser, farm.id));

  return (
    <ActivityBox
      className={className}
      isFlagged={flagged}
      isTruncated={isTruncated}
      hasMarker={!isChildActivity}
      isUnseen={!is_viewed}
      markAsSeen={markAsSeen}
      onViewFullActivity={onViewFullActivity}
    >
      {!isMinimized &&
        <LastCommentInfo activity={activity} currentUser={currentUser} />
      }
      <ActivityHeader
        activityId={id}
        onOpenDropdown={(e) => openDropdown(e, defaultDropdownOptions)}
        onDoubleClick={toggleMinimized}
      >
        <ActivityHeaderBadge isCentered>
          <FormattedMessage id="general.withdrawalWarning" tagName="b" />
        </ActivityHeaderBadge>
      </ActivityHeader>
      <ActivityBodyCollapse isClosed={isMinimized}>
        <div className={cn('warning-text')}>
          <FormattedMessage
            tagName="div"
            id="component.generalWithdrawalWarning.text"
            values={{
              b: (weight) => <b>{weight}</b>,
              orange: (msg) => <span className={cn('brand-orange')}>{msg}</span>,
              date: moment(activity.created_at).subtract(1, 'day').format('MM/DD/YYYY'),
            }}
          />
          <div className={cn('timestamp')}>
            {moment(created_at).format('MMM DD, YYYY, hh:mm a')}
          </div>
          <ActivityMentions isAdmin={isAdmin} users={mentions} />
        </div>
        {!!farmsData.length && (
          <div className="ph-30 mb-25">
            <Collapse
              className={cn('collapse')}
              title={<FormattedMessage id="general.button.showDetails" />}
            >
              <Divider />
              {farmsData.map(({ farm, data }, idx) => (
                <Collapse
                  key={idx}
                  className={cn('collapse', 'child')}
                  title={renderFarmTitle(farm)}
                >
                  <Divider />
                  <div className={cn('collapse-body')}>
                    {data.map(({ pig_group, additional_data }, idx) => (
                      <div className={cn('row')} key={idx}>
                        <div className={cn('group-name')}>
                          {renderPigGroupName(pig_group)}
                        </div>
                        <div className={cn('value')}>
                          {renderWeightLabel(additional_data)}
                        </div>
                        <div className={cn('value')}>
                          {renderTreatments(additional_data)}
                        </div>
                      </div>
                    ))}
                  </div>
                </Collapse>
              ))}
            </Collapse>
          </div>
        )}
        {!isChildActivity && (
          <>
            <ActivityActionsBar
              activity={activity}
              currentUser={currentUser}
              openDropdown={openDropdown}
              setActivityFlag={setActivityFlag}
            />
            <FarmFeedRowComments activity={activity} />
            <FarmFeedRowAddComment activity={activity} />
          </>
        )}
      </ActivityBodyCollapse>
    </ActivityBox>
  );
};

GeneralWithdrawalWarning.contextTypes = {
  router: T.object.isRequired
};

GeneralWithdrawalWarning.propTypes = {
  className: T.string,
  activity: T.object.isRequired,
  currentUser: T.object.isRequired,
  markAsSeen: T.func,
  isChildActivity: T.bool,
  openDropdown: T.func.isRequired,
  setActivityFlag: T.func.isRequired,
  // from hoc
  isTruncated: T.bool.isRequired,
  isMinimized: T.bool.isRequired,
  toggleMinimized: T.func.isRequired,
  onViewFullActivity: T.func.isRequired,
  defaultDropdownOptions: T.array.isRequired,
};

GeneralWithdrawalWarning.defaultProps = {
  isChildActivity: false,
};

export default withActivityController(GeneralWithdrawalWarning, { affectRerenderKeys });
