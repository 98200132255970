import React from 'react';
import T from 'prop-types';
// components
import VideoPreviewOverlay from 'components/VideoPreviewOverlay';
import PdfAssetPreview from 'components/PdfAssetPreview';
// utils
import { bytesToSize, isVideoAsset, isPDFAsset, isImageAsset } from 'utils';
// styles
import styles from './MediaFilesUploader.module.scss';

const UploadedFilePreview = ({ file, onFileRemove, onPreviewClick }) => (
  <div className={styles['file-preview']}>
    <div onClick={onPreviewClick} className={styles['preview-image-wrapper']}>
      {isPDFAsset(file) && <PdfAssetPreview asset={file} />}
      {(isVideoAsset(file) || isImageAsset(file)) && (
        <div
          className={styles['preview-image']}
          style={{ backgroundImage: `url(${file?.url?.thumb || URL.createObjectURL(file.asset)})` }}
        />
      )}
      {isVideoAsset(file) && <VideoPreviewOverlay size="25" />}
    </div>
    <div className={styles['file-data']}>
      <div className={styles['file-details']}>
        <div className={styles['file-name']}>{file.name || file.asset.name}</div>
        <div className={styles['file-type']}>
          {file.media_type || file.asset.type} | {bytesToSize(file.media_size)}
        </div>
      </div>
    </div>
    <div className={styles['remove-file-btn']} onClick={() => onFileRemove(file)}>
      <i className="fa fa-trash-o" />
    </div>
  </div>
);

UploadedFilePreview.propTypes = {
  file: T.object.isRequired,
  onFileRemove: T.func.isRequired,
  onPreviewClick: T.func.isRequired,
};

export default UploadedFilePreview;
