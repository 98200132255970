import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { setTableParams } from 'reducers/tableParams';
import { openModal } from 'reducers/modals';
// components
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { FormattedMessage } from 'react-intl';
import DraftReportsCarousel from './components/DraftReportsCarousel';
import Preloader from 'components/Preloader';
import NothingBox from 'components/NothingBox';
import Panel from 'components/Panel';
import PaginationWrapper from 'components/PaginationWrapper';
import TableView from './components/TableView';
import GridView from './components/GridView';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
// endpoints
import { fetchFarm } from 'endpoints/farms';
import { getReportTemplate } from 'endpoints/reportTemplates';
import { getFarmReports } from 'endpoints/reports';
import { deleteFarmSVR, getSiteVisitReportPDF } from 'endpoints/svr';
// icons
import { ReactComponent as TableIcon } from './table.svg';
import { ReactComponent as GridIcon } from './grid.svg';
// utils
import { getLSReportsTableView, setLSReportsTableView } from 'utils/localStorageHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { tableNames } from 'utils/constants';
import { getUserAccessToFarmSVR, getSVRAssetName, getReportEditPath, getReportReviewPath } from 'utils/SVRHelper';
import download from 'downloadjs';
import cn from 'classnames';
import moment from 'moment';
// styles
import styles from './FarmReports.module.scss';

const TABLE_VIEW_MODE = 'TABLE_VIEW_MODE';
const GRID_VIEW_MODE = 'GRID_VIEW_MODE';

const FarmReports = ({
  params: { farmId, templateId },
  router,
  tableParams,
  setBreadcrumbs,
  currentUser,
  openModal,
}) => {
  const [isPageDataLoading, setPageDataLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [viewMode, setViewMode] = useState(getLSReportsTableView() || GRID_VIEW_MODE);

  const {
    fetchData,
    data: { resources, isLoading, meta: { total } },
    onPageChange,
    onPerPageChange,
  } = useDataTable((params) => getFarmReports(farmId, templateId, { ...params, draft: false }), {
    tableParams,
    setTableParams: (params) => setTableParams(tableNames.farmReports, params)
  });

  const toggleMode = () => {
    setViewMode((prevViewMode) => {
      const viewMode = prevViewMode === TABLE_VIEW_MODE ? GRID_VIEW_MODE : TABLE_VIEW_MODE;
      setLSReportsTableView(viewMode);
      return viewMode;
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchPageData = async () => {
    try {
      setPageDataLoading(true);
      const reportTemplate = await getReportTemplate(templateId);
      const farm = await fetchFarm(farmId);
      const { canEdit } = getUserAccessToFarmSVR(currentUser, farmId, farm.manager_id);
      if (canEdit) setIsEditable(canEdit);
      setBreadcrumbs([
        { label: <FormattedMessage id="general.reports" />, path: '/report-farms' },
        { label: farm.name, path: `/report-farms/${farm.id}/report-templates` },
        { label: reportTemplate.name, useLabelAsMobileTitle: true },
      ]);

    } catch (error) {
      toastResponseErrors(error);
    } finally {
      setPageDataLoading(false);
    }
  };

  useEffect(() => {
    fetchPageData();
  }, [farmId, templateId]);

  const onEdit = (report) => () => router.push(getReportEditPath(report));
  const onReview = (report) => () => router.push(getReportReviewPath(report));
  const onDelete = (report) => deleteFarmSVR(report.farm_id, report.id).then(fetchData).catch(toastResponseErrors);
  const onDownload = (report) => () => {
    getSiteVisitReportPDF(report.farm_id, report.id)
      .then((blob) => download(blob, getSVRAssetName(report), 'application/pdf'))
      .catch(toastResponseErrors);
  };

  const handleReportDelete = (report) => () => {
    openModal(
      <ConfirmationModal
        title={<FormattedMessage id="component.modal.deleteReport.title" />}
        actionBtnLabel={<FormattedMessage id="component.modal.deleteReport.confirm" />}
        actionBtnProps={{ red: true }}
        handleConfirm={() => onDelete(report)}
      >
        <FormattedMessage
          id="component.modal.deleteReport.text"
          tagName="p"
          values={{ date: moment(report.created_at).format('MM/DD/YYYY') }}
        />
      </ConfirmationModal>
    );
  };

  const paginationProps = {
    onPageChange,
    onPerPageChange,
    totalItems: total,
    currentPage: tableParams.page,
    perPage: tableParams.per_page,
  };

  const ViewModeIcon = viewMode !== TABLE_VIEW_MODE ? TableIcon : GridIcon;

  return (
    <div className="small-12 column pb-25">
      <Preloader isActive={isPageDataLoading} />
      {/* ------------------------------ DRAFT REPORTS ----------------------------- */}
      {isEditable && <DraftReportsCarousel templateId={templateId} farmId={farmId} />}
      {/* ----------------------------- ACTIVE REPORTS ----------------------------- */}
      <Panel className={cn('mt-30')}>
        <Panel.Heading className="farms-panel-title" title={<FormattedMessage id="general.reports" />}>
          <div className="cursor-pointer" onClick={toggleMode}>
            <ViewModeIcon height={20} width={20} />
          </div>
        </Panel.Heading>
        <Panel.Body noPadding>
          <Preloader isActive={isLoading} />
          <NothingBox itemsName="reports" display={!resources.length} isLoading={isLoading}>
            <FormattedMessage tagName="h1" id="component.nothingBox.noReports" />
          </NothingBox>
          {/* ----------------------------- TABLE VIEW MODE ---------------------------- */}
          {viewMode === TABLE_VIEW_MODE && !!resources.length && (
            <TableView
              onDelete={handleReportDelete}
              onEdit={onEdit}
              onReview={onReview}
              onDownload={onDownload}
              isReportEditable={isEditable}
              resources={resources}
              isLoading={isLoading}
            />
          )}
          {/* ----------------------------- GRID VIEW MODE ----------------------------- */}
          {viewMode === GRID_VIEW_MODE && !!resources.length && (
            <GridView
              onDelete={handleReportDelete}
              onEdit={onEdit}
              onReview={onReview}
              onDownload={onDownload}
              isReportEditable={isEditable}
              resources={resources}
            />
          )}
          {!!resources && (
            <div className={cn(styles['pagination-wrapper'])}>
              <PaginationWrapper {...paginationProps} onlyBottom className="mb-0" />
            </div>
          )}
        </Panel.Body>
      </Panel>
    </div>
  );
};

FarmReports.propTypes = {
  setBreadcrumbs: T.func.isRequired,
  params: T.object.isRequired,
  currentUser: T.object.isRequired,
  tableParams: T.object.isRequired,
  router: T.object.isRequired,
  openModal: T.func.isRequired,
};

export default connect((state) => ({
  currentUser: state.auth.user,
  tableParams: tableParamsSelector(state, tableNames.farmReports),
}), { setBreadcrumbs, setTableParams, openModal })(FarmReports);
