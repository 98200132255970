import Resumable from 'resumablejs';
import { getAuthData } from 'utils/authData';
import { API_URL } from '../constants';
import moment from 'moment';
import { getSVRChunkAsset } from '../endpoints/svr';

export const ADDITIONAL_CATEGORY_KEY = 'miscellaneous';

export const isSomeReportQuestionFlagged = (svrCategories) => {
  return svrCategories?.some(({ svr_questions }) => svr_questions.some(({ answer }) => answer?.is_flagged));
};

export const getMiscellaneousCategory = (categories) => categories.find((category) => category.is_static);

export function buildCustomSVRCategory(SVRCustomQuestions) {
  return {
    item_key: 'custom',
    name: 'Custom Questions',
    name_key: 'general.customQuestions',
    svr_questions: SVRCustomQuestions.map((question) => ({
      ...question,
      item_key: question.label,
      question_type: 'custom'
    })),
  };
}

export const getAnsweredSVRQuestions = (categories) => {
  return categories.map(({ svr_questions }) => svr_questions
    .filter(({ answer, question_type }) => (question_type === 'common' && answer?.value) ||
    question_type === 'additional_section')
    .map(({ id, answer }) => ({
      id,
      answer: {
        ...answer,
        is_flagged: !!answer?.is_flagged,
        value: answer?.value || null,
      }
    })))
    .flat(1);
};

export const getSVRNotesComment = (answer, user) => ({
  comment: answer.value,
  user,
  created_at: answer.created_at,
  updated_at: answer.updated_at,
});

export const mergeCategoriesAnswers = (oldCategories, newCategories) => {
  return newCategories.map((category) => ({
    ...category,
    svr_questions: category.svr_questions.map((question) => ({
      ...question,
      answer: {
        ...(oldCategories
          .find(({ item_key }) => item_key === category.item_key)?.svr_questions
        ?.find(({ item_key }) => item_key === question.item_key)?.answer || {}),
        id: question.answer.id,
      }
    }))
  }));
};

export const mergeCategoryAnswers = (oldCategory, newCategory) => {
  return {
    ...newCategory,
    svr_questions: newCategory.svr_questions.map((question) => ({
      ...question,
      answer: {
        ...(oldCategory.svr_questions.find(({ item_key }) => item_key === question.item_key)?.answer || {}),
        id: question.answer.id,
      }
    }))
  };
};

export const uploadAssetToSVR = (valueId, asset) => {
  return new Promise((resolve, reject) => {
    const uploadPath = `${API_URL}/svr_question_values/${valueId}/chunk`;
    const R = new Resumable({
      target: uploadPath,
      testTarget: uploadPath,
      headers: { 'Accept': '*/*', ...getAuthData() },
      simultaneousUploads: 1,
      testChunks: false,
      chunkRetryInterval: 500,
      maxFileSize: 314572800,
      method: 'post'
    });

    R.addFile(asset);

    R.on('fileSuccess', (file) => getSVRChunkAsset(valueId, { chunk_identifier: file.uniqueIdentifier })
      .then(resolve).catch(reject));

    if (R.support) {
      R.on('fileAdded', (file) => {
        file.resumableObj.upload();
      });
    }
  });
};

export const getSVRAssetName = (svr) => {
  return `site_visit_report_${moment(svr.created_at || svr.updated_at).format('MM/DD/YY')}`;
};

export function getUserAccessToFarmSVR(user, farmId, farmManagerId) {
  const { admin, caretaker, manager, owner, vet, super_admin, tenant_owner } = user.roles_map;
  const isUserHasAdminRole = admin || super_admin || tenant_owner;

  if (isUserHasAdminRole) {
    return { canEdit: true, canView: true };
  }
  if (!farmId && !farmManagerId) {
    return { canEdit: false, canView: false };
  }
  let isUserHasEditRoleOnFarm = false;
  let isUserHasViewRoleOnFarm = false;
  let isUserHasEditRoleOnCompany = false;
  let isUserHasViewRoleOnCompany = false;

  if (farmId) {
    isUserHasEditRoleOnFarm = manager.farm_ids?.includes(+farmId) || owner.farm_ids?.includes(+farmId)
      || vet.farm_ids?.includes(+farmId);
    isUserHasViewRoleOnFarm = caretaker.farm_ids?.includes(+farmId);
  }
  if (farmManagerId) {
    isUserHasEditRoleOnCompany = manager.company_ids?.includes(+farmManagerId)
      || owner.company_ids?.includes(+farmManagerId) || vet.company_ids?.includes(+farmManagerId);
    isUserHasViewRoleOnCompany = caretaker.company_ids?.includes(+farmManagerId);
  }
  const canEdit = isUserHasEditRoleOnFarm || isUserHasEditRoleOnCompany;
  const canView = canEdit || isUserHasViewRoleOnFarm || isUserHasViewRoleOnCompany;

  return {
    canEdit,
    canView,
  };
}

export function getSVRBackLink(farmId, templateId, query) {
  switch (query?.from) {
    case 'farmfeed':
      return '/farmfeed';
    case 'barnsheets':
      return `/barnsheets/farms/${farmId}/reports`;
    default:
      return `/report-farms/${farmId}/report-templates/${templateId}/reports`;
  }
}

export function isSVRCategoriesEmpty(categories) {
  return !categories.some((category) => {
    return category?.svr_questions?.some(({ answer }) => answer?.value);
  });
}

export function isAdditionalCategoryEmpty(additionalCategory) {
  return !additionalCategory?.svr_questions?.some(({ answer, question_type }) => {
    if (question_type === 'media') {
      return answer?.value?.length;
    }

    return answer?.value;
  });
}

export function checkSVRConfirmable(categories) {
  return categories.every((category) => {
    return category?.svr_questions?.every((question) => question?.answer?.value);
  });
}

export function getAnswersCountByAnswerType(svr, type) {
  let count = 0;

  svr.svr_categories.forEach(({ svr_questions }) => {
    svr_questions.forEach(({ answer }) => {
      if (answer?.value === type && answer?.active) count++;
    });
  });

  return count;
}

const isAnswerActive = ({ answer }) => answer?.active;
const sortQuestions = (questionOne, questionTwo) => {
  return questionOne?.answer?.position > questionTwo?.answer?.position ? 1 : -1;
};

function isQuestionsPresent({ svr_questions }) {
  return svr_questions.length;
}

const parseQuestions = (question) => ({
  ...question,
  label: question.item_key,
});

const parseReportCategories = (categories = []) => categories
  .map((category) => ({
    ...category,
    name: category.item_key,
    svr_questions: category.svr_questions.filter(isAnswerActive).sort(sortQuestions).map(parseQuestions),
  }))
  .map(parseQuestions)
  .filter(isQuestionsPresent);

export const parseReportResponse = (data) => ({
  id: data.id,
  user: data.user,
  isDraft: data.is_draft,
  createdAt: data.created_at,
  categories: parseReportCategories(data.svr_categories),
  svr_template_id: data.svr_template_id,
  general_notes: data.general_notes,
  general_notes_section: data.general_notes_section,
  action_plan: data.action_plan,
  action_plan_section: data.action_plan_section,
  photo_video_uploads: data.photo_video_uploads,
  svr_assets: data.svr_assets,
});

export const getReportReviewPath = (report) => {
  const { farm_id, id, svr_template_id } = report;
  return `/report-farms/${farm_id}/report-templates/${svr_template_id}/reports/${id}/review`;
};

export const getReportEditPath = (report) => {
  const { farm_id, id, svr_template_id } = report;
  return `/report-farms/${farm_id}/report-templates/${svr_template_id}/reports/${id}`;
};
