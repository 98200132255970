import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Translation from 'components/Translation';
import TimeAgo from 'components/TimeAgo/TimeAgo';
// utils
import { renderMentionsText } from 'utils';
// styles
import styles from './SVRComment.module.scss';

const CommentText = ({ commentData, isActionable = false, onDelete, onEdit }) => (
  <Fragment>
    <div className={styles.comment}>
      <span className="semibold">{commentData.user.full_name}</span>
      &nbsp;
      <span className={styles['comment-text']}>
        <Translation text={commentData.comment}>
          {renderMentionsText(commentData.comment)}
        </Translation>
      </span>
    </div>
    <div className={styles['bottom-line']}>
      {!!(commentData.updated_at || commentData.created_at) && (
        <div className={styles.time}>
          <TimeAgo date={commentData.updated_at || commentData.created_at} />
        </div>
      )}
      {isActionable && (
        <Fragment>
          <div onClick={onDelete} className={styles['action-btn']}>
            <FormattedMessage id="general.button.delete" />
          </div>
          <div onClick={onEdit} className={styles['action-btn']}>
            <FormattedMessage id="general.button.edit" />
          </div>
        </Fragment>
      )}
    </div>
  </Fragment>
);

CommentText.propTypes = {
  commentData: T.object.isRequired,
  isActionable: T.bool,
  onEdit: T.func,
  onDelete: T.func,
};

export default CommentText;
