import React from 'react';
import T from 'prop-types';
// components
import { Droppable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import UpdateCategoryNameModal from '../UpdateCategoryNameModal';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import DraggableQuestion from '../DraggableQuestion';
// redux
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
// utils
import classnames from 'classnames/bind';
import uuid from 'uuid';
// constants
import {
  reportTemplateFormName,
  reportTemplateFormSelector,
  newQuestionTemplate,
} from '../ReportTemplateForm/constants';
// styles
import styles from './DroppableCategory.module.scss';

const cn = classnames.bind(styles);

const DroppableCategory = ({ categoryIndex, categoryData, search, change, openModal, allCategories = [] }) => {
  const { svr_questions: questions, name: categoryName, key: categoryKey } = categoryData;
  const hasDraftQuestions = questions.some(({ draft }) => draft);
  const visibleQuestions = search ?
    questions.filter(({ label }) => label.includes(search.toLowerCase())) :
    questions;
  const isSearchResultsEmpty = !visibleQuestions.length && search;
  const isCategoryEmpty = !questions.length;
  const droppableType = categoryName;
  const allQuestionsCount = questions.length;
  const enabledQuestionsCount = questions.filter(({ is_enabled }) => is_enabled).length;

  const onUpdate = (updatedCategoryName) => {
    change(reportTemplateFormName, 'svr_categories', [
      ...allCategories.slice(0, categoryIndex),
      { ...allCategories[categoryIndex], name: updatedCategoryName },
      ...allCategories.slice(categoryIndex + 1),
    ]);
  };

  const onCategoryDelete = () => {
    change(reportTemplateFormName, 'svr_categories', [
      ...allCategories.slice(0, categoryIndex),
      ...allCategories.slice(categoryIndex + 1),
    ]);
  };

  const onQuestionAdd = () => {
    const updatedQuestions = [...questions, { ...newQuestionTemplate, key: uuid() }];
    change(reportTemplateFormName, `svr_categories[${categoryIndex}].svr_questions`, updatedQuestions);
  };

  const handleCategoryUpdate = () => {
    openModal(<UpdateCategoryNameModal onSubmit={onUpdate} name={categoryName} />);
  };

  const handleCategoryDelete = () => {
    openModal(
      <ConfirmationModal
        title={<FormattedMessage id="component.modal.reports.deleteCategory" />}
        actionBtnLabel={<FormattedMessage id="component.modal.reports.deleteCategory.confirm" />}
        actionBtnProps={{ primary: true }}
        warningMessage={<FormattedMessage id="component.modal.reports.deleteCategory.warning" />}
        handleConfirm={onCategoryDelete}
      >
        <FormattedMessage
          id="component.modal.reports.deleteCategory.text"
          values={{ name: <strong>{categoryName}</strong> }}
          tagName="p"
        />
      </ConfirmationModal>
    );
  };

  const questionRenderer = (questionData, questionIndex) => (
    <DraggableQuestion
      questionIndex={questionIndex}
      categoryIndex={categoryIndex}
      categoryQuestions={questions}
      questionData={questionData}
      key={questionIndex}
      isDragDisabled={!!search || !questionData.is_enabled || hasDraftQuestions}
    />
  );

  return (
    <Droppable key={categoryName} type={droppableType} droppableId={`${categoryKey}`}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef} className={cn('category')}>
          <div className={cn('category-header', 'pb-15')}>
            <h4 className="semibold">{categoryName}</h4>
            &nbsp;
            {enabledQuestionsCount}/{allQuestionsCount}
            <div className={cn('btn-group')}>
              <div onClick={handleCategoryUpdate} className={cn('btn')}>
                <FormattedMessage id="general.button.rename" />
              </div>
              <div onClick={handleCategoryDelete} className={cn('btn')}>
                <FormattedMessage id="general.button.delete" />
              </div>
            </div>
          </div>

          {/* EMPTY PLACEHOLDER */}
          {(isSearchResultsEmpty || isCategoryEmpty) && (
            <div className={cn('empty-container')}>
              <FormattedMessage
                id={search ? 'component.emptySearchResult' : 'component.emptyCategory'}
                values={{ category_name: categoryName }}
              />
            </div>
          )}

          {/* QUESTIONS LIST */}
          <div className={cn('questions')}>
            {visibleQuestions.map(questionRenderer)}
            {provided.placeholder}
            {!hasDraftQuestions && !search && (
              <div className={cn(styles['add-question-btn'], 'mt-10')}>
                <span onClick={onQuestionAdd} className={styles['btn-label']}>
                  <FormattedMessage id="general.addQuestion" />
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </Droppable>
  );
};

DroppableCategory.propTypes = {
  categoryData: T.object.isRequired,
  search: T.string,
  categoryIndex: T.number.isRequired,
  allCategories: T.array.isRequired,
  change: T.func.isRequired,
};

export default connect((state) => ({
  allCategories: reportTemplateFormSelector(state, 'svr_categories')
}), { change, openModal })(DroppableCategory);
