import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import DateLine from 'components/DateLine/DateLine';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import PaginationWrapper from 'components/PaginationWrapper';
import TreatmentStepCard from './TreatmentStepCard';
import NothingBox from 'components/NothingBox';
// hooks
import useDataTable from 'hooks/useDataTable';
// utils, constants
import cn from 'classnames';
import moment from 'moment';

const formatDate = (date) => moment(date).format('MM/DD/YYYY');

const TreatmentProtocolsStepsPanel = ({
  className,
  fetchTreatmentProtocolsSteps,
  isOnline,
  onStepReport,
  pigGroup,
  placeholder = <FormattedMessage id="component.nothingBox.noTreatmentProtocols" />,
  setTableParams,
  tableParams,
  title,
}) => {
  const {
    data: { resources, isLoading, meta: { total } },
    fetchData,
    onPageChange,
    onPerPageChange,
  } = useDataTable(fetchTreatmentProtocolsSteps, {
    setTableParams,
    tableParams,
  });

  useEffect(() => {
    if (isOnline) fetchData(tableParams);
  }, [isOnline]);

  return (
    <Panel className={cn({ [className]: !!className })}>
      <Panel.Heading title={title} />
      <Panel.Body className="ph-20">
        <Preloader isActive={isLoading} />
        {resources.map((treatmentStepData, idx) => {
          const startDate = formatDate(treatmentStepData.start_date || pigGroup.started_on);
          const previousStartDate = formatDate(resources[idx - 1]?.start_date || pigGroup.started_on);
          const hasDateLine = (idx === 0 || startDate !== previousStartDate);

          return (
            <Fragment key={treatmentStepData.id}>
              {hasDateLine && <DateLine date={startDate} />}
              <TreatmentStepCard
                className={cn('mb-10', { 'mt-10': hasDateLine, 'mt-20': !hasDateLine })}
                onReport={onStepReport}
                pigGroup={pigGroup}
                treatmentStepData={treatmentStepData}
              />
            </Fragment>
          );
        })}

        <NothingBox display={!resources.length}>
          <h2 className="lighter semibold">
            {placeholder}
          </h2>
        </NothingBox>

        {!!resources.length && (
          <PaginationWrapper
            onlyBottom
            totalItems={total}
            currentPage={tableParams.page}
            onPageChange={onPageChange}
            perPage={tableParams.per_page}
            onPerPageChange={onPerPageChange}
          />
        )}
      </Panel.Body>
    </Panel>
  );
};

TreatmentProtocolsStepsPanel.propTypes = {
  className: T.string,
  placeholder: T.oneOf([T.string, T.node]),
  fetchTreatmentProtocolsSteps: T.func.isRequired,
  isOnline: T.bool.isRequired,
  onStepReport: T.func,
  pigGroup: T.object,
  setTableParams: T.func.isRequired,
  tableParams: T.object.isRequired,
  title: T.oneOfType([T.string, T.node]).isRequired,
};

export default TreatmentProtocolsStepsPanel;
