import React from 'react';
import T from 'prop-types';
// components
import ReportCard from '../ReportCard';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './GridView.module.scss';

const cn = classnames.bind(styles);

const GridView = ({ resources, onReview, onDownload, onEdit, onDelete, isReportEditable }) => (
  <div className={cn('grid-view')}>
    {resources.map((report) => (
      <ReportCard
        key={report.id}
        report={report}
        onClick={onReview}
        onDownload={onDownload}
        onEdit={onEdit}
        onDelete={onDelete}
        isReportEditable={isReportEditable}
      />
    ))}
  </div>
);

GridView.contextTypes = {
  router: T.object.isRequired,
};

GridView.propTypes = {
  onDelete: T.func.isRequired,
  onEdit: T.func.isRequired,
  onReview: T.func.isRequired,
  onDownload: T.func.isRequired,
  resources: T.array.isRequired,
  isReportEditable: T.bool.isRequired,
};

export default GridView;
