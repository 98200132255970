import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { reportDeath } from 'reducers/dailyCheckup/checkupEdit';
// components
import CheckupSegment from './CheckupSegment/CheckupSegment';
import DeadPigsBlock from './DeadPigsBlock';
// utils
import classnames from 'classnames/bind';
import { getCommentByRole } from 'helpers';
import { isSomeSectionReporting } from 'utils/checkupHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import styles from './ReportDeathSegment.module.scss';
// constants
import { comment_roles } from 'constants.js';

const cn = classnames.bind(styles);

const ReportDeathSegment = ({
  checkup,
  paramsCheckupId,
  isSubmitting,
  isDisabled,
  reportDeath,
  validateCheckup,
}) => {
  const [isAllGoodReported, setIsAllGoodReported] = useState(false);
  const {
    acute_death_count,
    chronic_death_count,
    euthanasia_death_count,
    treated_death_count,
    deaths_sum,
    comments = [],
  } = checkup;
  const { comment } = getCommentByRole(comments, comment_roles.DEATHS_ROLE);
  const isReported =
    acute_death_count !== null ||
    euthanasia_death_count !== null ||
    chronic_death_count !== null ||
    treated_death_count !== null ||
    !!deaths_sum ||
    !!comment;
  const data = checkup.mortality_reasons || [
    { type: 'chronic', value: chronic_death_count },
    { type: 'acute', value: acute_death_count },
    { type: 'euthanasia', value: euthanasia_death_count },
    { type: 'treated', value: treated_death_count },
  ];

  const handleReportNoDeaths = () => {
    if (!validateCheckup(checkup)) return;
    setIsAllGoodReported(true);
    reportDeath(paramsCheckupId, { all_good: true })
      .catch(toastResponseErrors);
  };

  return (
    <CheckupSegment
      isReported={isReported}
      isSubmitting={isSubmitting}
      isDisabled={isDisabled}
      onEmptyDataSubmit={handleReportNoDeaths}
      itemsCount={deaths_sum}
      titleKey="component.checkupSegment.doYouHaveDeaths"
      titleKeyWhenReported="general.pageTitle.mortalities"
      reportPageUrl={`/daily-checkup/${paramsCheckupId}/report-deaths`}
      withoutBottomBorder={!!deaths_sum}
      noData={isReported && !deaths_sum}
      isSegmentConditionChanged={isAllGoodReported}
    >
      {(!!deaths_sum || !!comment) && (
        <div className={cn('reported-deaths')}>
          <DeadPigsBlock
            data={data}
            dailyCheckup={checkup}
            hasDeathReasons={Boolean(checkup.mortality_reasons)}
          />
        </div>
      )}
    </CheckupSegment>
  );
};

ReportDeathSegment.propTypes = {
  checkup: T.object.isRequired,
  paramsCheckupId: T.oneOfType([T.string, T.number]).isRequired,
  validateCheckup: T.func.isRequired,
  reportDeath: T.func.isRequired,
  isDisabled: T.bool.isRequired,
  isSubmitting: T.bool.isRequired,
};

export default connect(
  ({ dailyCheckup }) => ({
    isSubmitting: dailyCheckup.checkupEdit.isReportingDeaths,
    isDisabled: isSomeSectionReporting(dailyCheckup.checkupEdit) && !dailyCheckup.checkupEdit.isReportingDeaths,
  }), {
    reportDeath,
  }
)(ReportDeathSegment);
