import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { setTableParams } from 'reducers/tableParams';
// components
import { FormattedMessage } from 'react-intl';
import TreatmentProtocolsGroupRow from './components/TreatmentProtocolsGroupRow';
import PaginationWrapper from 'components/PaginationWrapper';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import SearchBox from 'components/SearchBox';
import NothingBox from 'components/NothingBox';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
// endpoints
import { getTreatmentProtocolsPigGroups } from 'endpoints/treatmentProtocols';
// utils, constants
import { tableNames } from 'utils/constants';
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const OutOfComplianceGroups = ({ reqParams, router, setPageOptions, setTableParams, setBreadcrumbs }) => {
  const {
    data: {
      resources,
      isLoading,
      meta: { total },
    },
    fetchData,
    onPageChange,
    onPerPageChange,
    onSearchChange,
  } = useDataTable(getTreatmentProtocolsPigGroups, {
    tableParams: reqParams,
    setTableParams: (params) => setTableParams(tableNames.treatmentProtocolPigGroups, params),
  });
  const { page, per_page, search } = reqParams;

  useEffect(() => {
    fetchData({ ...reqParams, page: 1 }).catch(toastResponseErrors);
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.outOfComplianceGroups" />,
        path: '/treatment-protocols-groups',
        useLabelAsMobileTitle: true
      }
    ]);
    setPageOptions({ backLink: '' });
  }, []);

  const onGroupSelect = ({ id }) => {
    router.push(`/treatment-protocols-groups/${id}`);
  };

  return (
    <section className="small-12 column row centered">
      <Panel className="mb-20">
        <Panel.Heading title={<FormattedMessage id="general.outOfComplianceGroups" />}>
          <SearchBox onChange={onSearchChange} initialValue={search} disableFocus />
        </Panel.Heading>
        <Panel.Body className="pt-20 pb-0 ph-20">
          <Preloader isActive={isLoading} />
          {resources.map((group) => (
            <TreatmentProtocolsGroupRow key={group.id} group={group} onSelect={() => onGroupSelect(group)} />
          ))}
          <NothingBox itemsName="groups" display={!resources.length} isLoading={isLoading}>
            <FormattedMessage tagName="h1" id="component.nothingBox.noGroupsToDisplay" />
          </NothingBox>

          <PaginationWrapper
            currentPage={page}
            perPage={per_page}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            totalItems={total}
            onlyBottom
          />
        </Panel.Body>
      </Panel>
    </section>
  );
};

OutOfComplianceGroups.propTypes = {
  reqParams: T.object.isRequired,
  router: T.object.isRequired,
  setTableParams: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
};

export default connect(
  (state) => ({
    reqParams: tableParamsSelector(state, tableNames.treatmentProtocolPigGroups),
  }), {
    setPageOptions,
    setTableParams,
    setBreadcrumbs,
  }
)(OutOfComplianceGroups);
