import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { setPageOptions } from 'reducers/layout';
import { setTableParams } from 'reducers/tableParams';
// components
import { FormattedMessage } from 'react-intl';
import FormattedWeight from 'components/FormattedWeight';
import GroupProfileHeader from 'components/ProfileHeader/GroupProfileHeader/GroupProfileHeader';
import ProfileHeaderStats from 'components/ProfileHeader/ProfileHeaderStats/ProfileHeaderStats';
import TreatmentProtocolsStepsPanel from 'components/TreatmentProtocols/TreatmentProtocolsStepsPanel';
// hooks
import { tableParamsSelector } from 'hooks/useDataTable';
// endpoints
import { getGroupExpiredProtocolTreatmentSteps, getTreatmentProtocolsPigGroup } from 'endpoints/treatmentProtocols';
// utils, constants
import { formatMortality } from 'utils';
import { tableNames } from 'utils/constants';
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const GroupTreatmentProtocolsSteps = ({
  isOnline,
  params: { groupId },
  tableParams,
  setBreadcrumbs,
  setPageOptions,
  setTableParams,
}) => {
  const [pigGroup, setPigGroup] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  // const [{ pigGroup, isGroupLoading }, setState] = useState({ pigGroup: undefined, isGroupLoading: false });

  const fetchGroup = async () => {
    try {
      setIsLoading(true);
      const pigGroup = await getTreatmentProtocolsPigGroup(groupId);
      setPigGroup(pigGroup);
    } catch (error) {
      toastResponseErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchGroup();
    setPageOptions({ backLink: '/treatment-protocols-groups' });
  }, []);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.outOfComplianceGroups" />,
        path: '/treatment-protocols-groups',
      },
      {
        label: pigGroup?.name || '',
        useLabelAsMobileTitle: true,
      },
    ]);
  }, [pigGroup?.name]);

  const groupStats = pigGroup ? [
    { label: <FormattedMessage id="general.puc" />, value: pigGroup.pigs },
    { label: <FormattedMessage id="general.estAvgWeight" />,
      value: <FormattedWeight value={pigGroup.estimated_weight} unitFontSize="13px" /> },
    { label: <FormattedMessage id="general.mortalityRate" />, value: formatMortality(pigGroup.mortality_rate) },
  ] : [];

  return (
    <div>
      <GroupProfileHeader group={pigGroup} isLoading={isLoading}>
        <ProfileHeaderStats stats={groupStats} />
      </GroupProfileHeader>
      <section className="small-12 column">
        {pigGroup && (
          <TreatmentProtocolsStepsPanel
            className="mb-20"
            isOnline={isOnline}
            title={<FormattedMessage id="general.treatmentProtocols" />}
            fetchTreatmentProtocolsSteps={(params) => getGroupExpiredProtocolTreatmentSteps(groupId, params)}
            pigGroup={pigGroup}
            setTableParams={(params) => setTableParams(tableNames.pigGroupTreatmentProtocolsProductSteps, params)}
            tableParams={tableParams}
          />
        )}
      </section>
    </div>
  );
};

GroupTreatmentProtocolsSteps.propTypes = {
  isOnline: T.bool.isRequired,
  params: T.object.isRequired,
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  setTableParams: T.func.isRequired,
  tableParams: T.object.isRequired,
};

export default connect(
  (state) => ({
    isOnline: state.network.isOnline,
    tableParams: tableParamsSelector(state, tableNames.pigGroupTreatmentProtocolsProductSteps),
  }), {
    setBreadcrumbs,
    setPageOptions,
    setTableParams,
  }
)(GroupTreatmentProtocolsSteps);
