import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { enableEditMode } from 'reducers/barnsheets/barnsheetsGroupCheckups';
import { setTableParams } from 'reducers/tableParams';
import { openModal } from 'reducers/modals';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
import useGroupsColumnRenderers from 'hooks/barnSheets/useGroupsColumnRenderers';
// components
import { FormattedMessage } from 'react-intl';
import SearchBox from 'components/SearchBox';
import NothingBox from 'components/NothingBox';
import TableFilter from 'components/TableFilter';
import DataTable from 'components/DataTable/DataTable';
import ArrowColumn from 'components/DataTable/Columns/ArrowColumn';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
// api
import { fetchBarnSheetsGroups } from 'endpoints/barnsheets/groups';
// utils
import { isTablet } from 'react-device-detect';
import { tableNames } from 'utils/constants';
import { isSchedulingTariffUser } from 'utils/userHelper';
// styles
import './BarnSheetsFarmGroups.scss';

const tableFilters = [
  { label: <FormattedMessage id="general.allGroups" />, value: '' },
  { label: <FormattedMessage id="general.status.opened" />, value: 'opened' },
  { label: <FormattedMessage id="general.status.closed" values={{ date: null }} />, value: 'closed' },
];

const BarnSheetsFarmGroups = ({
  enableEditMode,
  isAdmin,
  params: { farmId },
  setTableParams,
  tableParams,
  isSchedulingAccessOnly,
  openModal,
}, {
  router,
}) => {
  const {
    data: { resources, isLoading, meta },
    fetchData,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onStatusChange,
    onSearchChange,
  } = useDataTable(fetchBarnSheetsGroups, {
    setTableParams: (params) => setTableParams(tableNames.barnSheetsFarmGroups, params),
    tableParams,
  });
  const { page, per_page, search, sort, status } = tableParams;
  const { total, stats } = meta;

  const fetchFarmGroups = () => {
    fetchData({ ...tableParams, farm_id: farmId, sort: sort || 'status asc' });
  };

  useEffect(() => {
    fetchFarmGroups();
  }, []);

  const handleTurnOnEditMode = (id) => {
    enableEditMode(id).then(() => router.push(`/barnsheets/groups/${id}?editMode=true`));
  };

  const {
    renderDaysOnColumn,
    renderGroupNameColumn,
    renderStartDateColumn,
    renderPigsInColumn,
    renderInventoryColumn,
    renderMortalityRateColumn,
    renderTotalMortalityColumn,
    renderEstimatedWeightColumn,
    renderTreatedColumn,
    renderProgressColumn,
    renderStatusColumn,
    renderButtonsColumn,
    renderExpandable,
  } = useGroupsColumnRenderers({
    isAdmin,
    handleTurnOnEditMode,
    isSchedulingAccessOnly,
    openModal,
    fetchData: fetchFarmGroups,
  });

  const columns = [
    { label: <FormattedMessage id="component.dataTable.headers.groupId" />, flex: '1 1 130px', sortKey: 'name',
      renderer: renderGroupNameColumn },
    { label: <FormattedMessage id="component.dataTable.headers.mortRate" />, flex: '1 1 100px',
      sortKey: 'mortality_rate', renderer: renderMortalityRateColumn, textRight: true },
    { label: <FormattedMessage id="component.dataTable.headers.totalMortality" />, flex: '1 1 110px',
      renderer: renderTotalMortalityColumn, sortKey: 'total_deaths', textRight: true },
    { label: <FormattedMessage id="general.pigsIn" />, flex: '1 1 80px', renderer: renderPigsInColumn,
      sortKey: 'total_pigs_in', textRight: true },
    { label: <FormattedMessage id="component.dataTable.headers.inventory" />, flex: '1 1 80px',
      renderer: renderInventoryColumn, sortKey: 'pigs', textRight: true },
    { label: <FormattedMessage id="component.dataTable.headers.startDate" />, flex: '1 1 100px',
      renderer: renderStartDateColumn, sortKey: 'started_on', textRight: true },
    { label: <FormattedMessage id="general.daysOnFeed" />, flex: '1 1 105px', renderer: renderDaysOnColumn,
      sortKey: 'days_on_feed', textRight: true },
    { label: <FormattedMessage id="general.estAvgWt" />, flex: '1 1 100px', sortKey: 'estimated_weight',
      renderer: renderEstimatedWeightColumn, textRight: true },
    { label: <FormattedMessage id="general.treatedPeriod" values={{ period: '24h' }} />, flex: '1 1 105px',
      renderer: renderTreatedColumn, sortKey: 'avg_treated_24h', textRight: true },
    { label: <FormattedMessage id="component.dataTable.headers.compliance" />, flex: '1 1 100px',
      renderer: renderProgressColumn, sortKey: 'progress', textRight: true },
    { label: <FormattedMessage id="component.dataTable.headers.status" />, flex: '1 1 120px',
      renderer: renderStatusColumn, sortKey: 'status' },
    { label: '', flex: '0 0 45px', renderer: renderButtonsColumn, fixed: true,
      className: isTablet ? 'hide-for-large' : '' },
    { label: '', flex: '0 0 45px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
      hasPinnedIcon: true },
  ];

  const paginationProps = {
    onPageChange,
    onPerPageChange,
    totalItems: total,
    currentPage: page,
    perPage: per_page,
  };

  return (
    <Panel className="BarnSheetsFarmGroups">
      <Panel.Heading
        renderTitle={
          () => <h2 className="lighter show-for-large"><FormattedMessage id="general.pageTitle.groups" /></h2>
        }
      >
        <SearchBox initialValue={search} onChange={onSearchChange} />
      </Panel.Heading>
      <Panel.Body noPadding>
        <Preloader isActive={isLoading} />

        <TableFilter
          filters={tableFilters}
          onFilterChange={onStatusChange}
          activeFilter={status}
          stats={stats}
          className="mh-10"
        />

        <DataTable
          data={resources}
          columns={columns}
          sort={sort}
          isLoading={isLoading}
          onSortChange={onSortChange}
          paginationProps={paginationProps}
          isExpandable={isTablet}
          renderExpandable={renderExpandable}
          scrollable
        />

        <NothingBox
          itemsName="barnsheets"
          display={!resources.length}
          isLoading={isLoading}
          search={search}
        >
          <h2 className="lighter"><FormattedMessage id="component.nothingBox.noBarnSheets" /></h2>
        </NothingBox>
      </Panel.Body>
    </Panel>
  );
};

BarnSheetsFarmGroups.contextTypes = {
  router: T.object.isRequired,
};

BarnSheetsFarmGroups.propTypes = {
  tableParams: T.object.isRequired,
  isAdmin: T.bool.isRequired,
  enableEditMode: T.func.isRequired,
  setTableParams: T.func.isRequired,
  params: T.object.isRequired,
  isSchedulingAccessOnly: T.bool.isRequired,
  openModal: T.func.isRequired,
};

export default connect(
  (state) => ({
    tableParams: tableParamsSelector(state, tableNames.barnSheetsFarmGroups),
    isAdmin: state.auth.user.roles_map.admin,
    isSchedulingAccessOnly: isSchedulingTariffUser(state.auth.user),
  }), {
    setTableParams,
    enableEditMode,
    openModal,
  }
)(BarnSheetsFarmGroups);
