import React, { useState } from 'react';
import T from 'prop-types';
// components
import Field from 'components/Field';
import { FormattedMessage } from 'react-intl';
import Toggle from 'components/Toggle/Toggle';
import { ReactComponent as DNDIcon } from './dnd-icon.svg';
import { Draggable } from 'react-beautiful-dnd';
// redux
import { change } from 'redux-form';
import { connect } from 'react-redux';
// utils
import classnames from 'classnames/bind';
// constants
import { reportTemplateFormName } from '../ReportTemplateForm/constants';
// styles
import styles from './DraggableQuestion.module.scss';

const cn = classnames.bind(styles);

const DraggableQuestion = ({
  questionIndex,
  questionData,
  isDragDisabled,
  categoryIndex,
  categoryQuestions,
  change,
}) => {
  const [isEditing, setEditing] = useState(questionData.draft);

  const deleteQuestion = () => {
    const questionIndex = categoryQuestions.findIndex(({ key: questionKey }) => questionData.key === questionKey);
    change(reportTemplateFormName, `svr_categories[${categoryIndex}].svr_questions`, [
      ...categoryQuestions.slice(0, questionIndex),
      ...categoryQuestions.slice(questionIndex + 1),
    ]);
  };

  const stopEditing = () => {
    if (!questionData.label) {
      deleteQuestion();
    } else {
      change(reportTemplateFormName, `svr_categories[${categoryIndex}].svr_questions`, [
        ...categoryQuestions.slice(0, questionIndex),
        { ...questionData, draft: false },
        ...categoryQuestions.slice(questionIndex + 1),
      ]);
    }
    setEditing(false);
  };

  const startEditing = () => setEditing(true);
  const handleEnterPress = ({ key }) => key === 'Enter' && stopEditing();

  const onToggle = () => {
    const questionIndex = categoryQuestions.findIndex(({ key: questionKey }) => questionData.key === questionKey);
    change(reportTemplateFormName, `svr_categories[${categoryIndex}].svr_questions`, [
      ...categoryQuestions.slice(0, questionIndex),
      { ...questionData, is_enabled: !questionData.is_enabled },
      ...categoryQuestions.slice(questionIndex + 1),
    ]);
  };

  return (
    <Draggable
      isDragDisabled={isDragDisabled}
      index={questionIndex}
      key={questionData.key}
      draggableId={questionData.key}
    >
      {(provided) => (
        <div
          className="pb-10"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className={cn('question', { disabled: !questionData.is_enabled, editing: isEditing })}>
            <DNDIcon className={cn('dnd-icon')} />
            {isEditing ? (
              <FormattedMessage id="general.enterCustomQuestion">
                {(placeholder) => (
                  <Field
                    onKeyDown={handleEnterPress}
                    onBlur={stopEditing}
                    name={`svr_categories[${categoryIndex}].svr_questions[${questionIndex}].label`}
                    placeholder={placeholder}
                    autoFocus
                    component={(props) => <input {...props} {...props.input} className={cn('question-input')} />}
                  />
                )}
              </FormattedMessage>
            ) : questionData.label }
            <div className={cn('control-btn-group')}>
              <i onClick={startEditing} className={cn('fa fa-pencil-mdc', 'icon-btn', 'mr-15')} />
              <i onClick={deleteQuestion} className={cn('fa fa-trash-o', 'icon-btn')} />
              <Toggle handleChange={onToggle} className={cn('toggle')} isChecked={questionData.is_enabled} />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

DraggableQuestion.propTypes = {
  categoryQuestions: T.array.isRequired,
  questionIndex: T.number.isRequired,
  questionData: T.object.isRequired,
  isDragDisabled: T.bool.isRequired,
  change: T.func.isRequired,
};

export default connect(null, { change })(DraggableQuestion);
