import React, { Fragment, useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import TreatmentProtocolForm from '../../components/TreatmentProtocolForm';
// endpoints
import { getPigGroupTreatmentProtocol, updatePigGroupTreatmentProtocol } from 'endpoints/treatmentProtocols';
// utils
import { connect } from 'react-redux';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import {
  formatTreatmentProtocolPayload,
  formatTreatmentProtocolResponse,
  markDestroyedItems,
  validateUpdatedTreatmentProtocol
} from '../../helper';

const EditGroupTreatmentProtocol = ({
  pigGroup,
  setBreadcrumbs,
  setPageOptions,
  router: {
    params: {
      farmId,
      groupId,
      protocolId,
    },
    push,
  }
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [treatmentProtocol, setTreatmentProtocol] = useState(undefined);

  const fetchData = async () => {
    try {
      const fetchedTreatmentProtocol = await getPigGroupTreatmentProtocol(groupId, protocolId);
      const formattedTreatmentProtocol = formatTreatmentProtocolResponse(fetchedTreatmentProtocol);
      setTreatmentProtocol(formattedTreatmentProtocol);
    } catch (error) {
      toastResponseErrors(error);
      push(`/treatment-protocols-farms/${farmId}/groups/${groupId}/treatment-protocols`);
    }
  };

  useEffect(() => {
    fetchData();
    setPageOptions({ backLink: `/treatment-protocols-farms/${farmId}/groups/${groupId}/treatment-protocols` });
  }, []);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.treatmentProtocolFarms" />,
        path: '/treatment-protocols-farms',
      },
      {
        label: pigGroup?.farm?.name || '',
        path: `/treatment-protocols-farms/${farmId}/groups`,
      },
      {
        label: pigGroup?.name || '',
        path: `/treatment-protocols-farms/${farmId}/groups/${groupId}/treatment-protocols`,
      },
      {
        label: treatmentProtocol?.name || '',
        useLabelAsMobileTitle: true,
      },
    ]);
  }, [pigGroup?.name, treatmentProtocol?.name]);

  const onSubmit = async (updatedTreatmentProtocol) => {
    try {
      setIsLoading(true);
      const markedTreatmentProtocolPayload = markDestroyedItems(updatedTreatmentProtocol, treatmentProtocol);
      const treatmentProtocolPayload = formatTreatmentProtocolPayload(markedTreatmentProtocolPayload);
      await updatePigGroupTreatmentProtocol(groupId, protocolId, treatmentProtocolPayload);
      push(`/treatment-protocols-farms/${farmId}/groups/${groupId}/treatment-protocols`);
    } catch (error) {
      toastResponseErrors(error);
      setIsLoading(false);
    }
  };

  if (!treatmentProtocol) return <Preloader isActive />;

  const isProtocolInheritedFromFarm = !!treatmentProtocol.farm_treatment_protocol;

  return (
    <Fragment>
      <Preloader isActive={isLoading} />
      <TreatmentProtocolForm
        isBaseDateDisabled
        isNameChangeable={!isProtocolInheritedFromFarm}
        isProductsChangeable={!isProtocolInheritedFromFarm}
        validate={validateUpdatedTreatmentProtocol}
        onSubmit={onSubmit}
        initialValues={treatmentProtocol}
        title={<FormattedMessage id="general.editTreatmentProtocol" />}
        submitLabel={<FormattedMessage id="general.updateTreatmentProtocol" />}
        ABFEnabled={pigGroup.abf_tracking}
      />
    </Fragment>
  );
};

EditGroupTreatmentProtocol.propTypes = {
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  pigGroup: T.object.isRequired,
  router: T.object.isRequired,
};

export default connect(
  (state) => ({
    pigGroup: state.profiles.pigGroup.data,
  }), {
    setPageOptions,
    setBreadcrumbs,
  }
)(EditGroupTreatmentProtocol);
