import React, { Component } from 'react';
import T from 'prop-types';
// recompose
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// redux
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
// components
import SearchBox from 'components/SearchBox';
import TableFilter from 'components/TableFilter';
import DataTable from 'components/DataTable/DataTable';
import CustomColumn from 'components/DataTable/Columns/CustomColumn';
import Preloader from 'components/Preloader';
import { GroupStatusMetaFarms } from 'components/StatusBadge/StatusBadge';
import Panel from 'components/Panel';
import TruncateHint from 'components/TruncateHint';
import NothingBox from 'components/NothingBox';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import FormattedWeight from 'components/FormattedWeight';
import ClosedIntegration from '../../ClosedIntegration/ClosedIntegration';
// utils
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import download from 'downloadjs';
import { isTablet } from 'react-device-detect';
// assets
import repeat from '../../../../../public/images/repeat.svg';
// styles
import './PushResults.scss';

const tableFilters = [
  { label: <FormattedMessage id="general.allGroups" />, value: '' },
  { label: <FormattedMessage id="general.errors" />, value: 'errors' },
];

const urlDownload = '/push_results/download?direction=import&api=faslink';

class PushResults extends Component {

  state = {
    isLoading: false,
  };

  componentDidMount() {
    this.renderRerunArray();
  }

  downloadCSV = (path, fileName) => (e) => {
    e.stopPropagation();
    return fetchFromAPI(path, { blob: true })
      .then((blob) => download(blob, fileName, 'text/csv'))
      .catch(toastResponseErrors);
  };


  renderFarmIdColumn = (farm) => {
    const { data: { pig_group_name } } = farm;
    return (
      <CustomColumn label={<FormattedMessage id="component.dataTable.headers.farmName" />}>
        <TruncateHint name={pig_group_name || 'N/A'} className="groupIdName" />
      </CustomColumn>
    );
  };

  renderFarmNameColumn = ({ data: { farm_name } }) => {
    return (
      <CustomColumn label={<FormattedMessage id="component.dataTable.headers.farmName" />}>
        <TruncateHint name={farm_name || 'N/A'} className="groupIdName" />
      </CustomColumn>
    );
  };

  showSpaces = (isField) => {
    return isField ? 0 : 'N/A';
  };

  renderPlacementsColumn = ({ data: { report_data } }) => {
    const { meta: { fields: { show_placements } } } = this.props;
    return (
      <CustomColumn label={<FormattedMessage id="component.dataTable.headers.placements" />} textRight>
        {report_data.placements ? report_data.placements : this.showSpaces(show_placements)}
      </CustomColumn>
    );
  };

  renderTransfersColumn = ({ data: { report_data } }) => {
    const { meta: { fields: { show_transfers } } } = this.props;
    return (
      <CustomColumn label={<FormattedMessage id="component.dataTable.headers.transfers" />} textRight>
        {report_data.transfers ? report_data.transfers :  this.showSpaces(show_transfers)}
      </CustomColumn>
    );
  };

  renderSalesColumn =({ data: { report_data } }) => {
    const { meta: { fields: { show_sales } } } = this.props;
    return (
      <CustomColumn label={<FormattedMessage id="component.dataTable.headers.sales" />} textRight>
        {report_data.sales ?   report_data.sales : this.showSpaces(show_sales)}
      </CustomColumn>
    );
  };

  renderMortalitiesColumn = ({ data: { report_data } }) => {
    const { meta: { fields: { show_mortalities } } } = this.props;
    return (
      <CustomColumn label={<FormattedMessage id="general.pageTitle.mortalities" />} textRight>
        {report_data.mortalities ? report_data.mortalities : this.showSpaces(show_mortalities) }
      </CustomColumn>
    );
  };

  renderEstimatedWeightColumn = ({ data: { report_data } }) => {
    return (
      <CustomColumn label={<FormattedMessage id="general.estAvgWt" />} textRight>
        <FormattedWeight value={report_data.est_avg_weight} />
      </CustomColumn>
    );
  };

  renderRerunArray = () => {
    this.setState({ isLoading: true });
    const { reqParams, fetchData } = this.props;
    fetchData({ ...reqParams, sort: 'status asc' }).catch(toastResponseErrors)
      .finally(() => { this.setState({ isLoading: false }); });
  };

  onFilterChange = (filter) => {
    this.setState({ isLoading: true });
    const { reqParams, fetchData } = this.props;
    fetchData({ ...reqParams, status: filter, sort: 'status asc' })
      .catch(toastResponseErrors)
      .finally(() => { this.setState({ isLoading: false }); });
  };

  onSearchChangeArray = (searchName) => {
    this.setState({ isLoading: true });
    const { reqParams, fetchData } = this.props;
    fetchData({ ...reqParams, search: searchName,  sort: 'status asc' })
      .catch(toastResponseErrors)
      .finally(() => { this.setState({ isLoading: false }); });
  };


  renderPushResultColumn = ({ success }) => {
    return (
      <CustomColumn
        label={<FormattedMessage id="component.dataTable.headers.status" />}
        noBottomBorder
      >
        <div className="containerResult">
          <div className="itemResultPushFasLink">
            <GroupStatusMetaFarms
              status={success ? 'success' : 'error'}
            />
          </div>
          {!success && (
            <div>
              <button className="resultBtn" onClick={this.renderRerunArray}>
                <img className="resultImg" src={repeat} alt="repeat" />
              </button>
            </div>
          )}
        </div>
      </CustomColumn>
    );
  };

  downloadAll = () => {
    fetchFromAPI(urlDownload, { blob: true })
      .then((blob) => download(blob, 'Download', 'text/csv'))
      .catch(toastResponseErrors);
  };

  getUrlDownload = (success) => {
    return `${urlDownload}&success=${success ? 'true' : 'false'}`;
  };

  getNameDownload = (success) => {
    return success ? 'Successes' : 'Errors';
  };

  render() {
    const {
      reqParams: { page, per_page, search, sort, status },
      meta: { total, stats },
      fasLink,
      active,
      onSortChange,
      onPageChange,
      onPerPageChange,
    } = this.props;
    const { isLoading } = this.state;
    const title = <FormattedMessage id="general.pageTitle.results" />;
    const datePush = moment(Date.now()).format('MM/DD/YY HH:MM:ss GTM ZZ');
    const columns = [
      {
        label: <FormattedMessage id="component.dataTable.headers.groupId" />, flex: '1 1 140px',
        renderer: this.renderFarmIdColumn, sortKey: 'pig_group_name'
      },
      {
        label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '1 1 140px',
        renderer: this.renderFarmNameColumn, sortKey: 'farm_name'
      },
      {
        label: <FormattedMessage id="component.dataTable.headers.placements" />, flex: '1 1 130px',
        renderer: this.renderPlacementsColumn, sortKey: 'placements', textRight: true
      },
      {
        label: <FormattedMessage id="component.dataTable.headers.transfers" />, flex: '1 1 130px',
        renderer: this.renderTransfersColumn, sortKey: 'transfers', textRight: true
      },
      {
        label: <FormattedMessage id="component.dataTable.headers.sales" />, flex: '1 1 130px',
        renderer: this.renderSalesColumn, sortKey: 'sales', textRight: true
      },
      {
        label: <FormattedMessage id="general.pageTitle.mortalities" />, flex: '1 1 130px',
        renderer: this.renderMortalitiesColumn, sortKey: 'mortalities', textRight: true
      },
      { label: <FormattedMessage id="general.estAvgWt" />, flex: '1 1 150px',
        renderer: this.renderEstimatedWeightColumn, sortKey: 'est_avg_weight', textRight: true },
      {
        label: <FormattedMessage id="component.dataTable.headers.pushResult" />, flex: '1 1 219px',
        padding: '0px 0px 0px 30px',
        renderer: this.renderPushResultColumn, sortKey: 'status'
      },

    ];
    const labelButton = <FormattedMessage id="general.button.download" />;

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    if (isLoading) {
      return <Preloader isActive={isLoading} />;
    }

    if (!active) {
      return <ClosedIntegration link="prairie-systems" />;
    }
    return (
      <>
        <Preloader isActive={isLoading} />
        {!isLoading && (
        <div className="small-12 column">
          {active ? (
            <div className="BarnsheetsGroups">
              <Panel className="mv-20">
                <Panel.Heading renderTitle={() => (
                  <h2 className="lighter show-for-large">
                    {title} {datePush}
                  </h2>
                )}
                >
                  <SearchBox initialValue={search} onChange={this.onSearchChangeArray} />
                </Panel.Heading>
                <Panel.Body noPadding>
                  <Preloader isActive={isLoading} />
                  <TableFilter
                    filters={tableFilters}
                    onFilterChange={this.onFilterChange}
                    activeFilter={status}
                    stats={stats}
                    className="mh-10"
                  >
                    <div className="containerButtons">
                      <DropdownButton
                        idKey="groups-dropdown"
                        ignoreScroll
                        label={labelButton}
                        buttonType="compact"
                        onClick={this.downloadAll}
                        dropDownData={[
                          { label: <FormattedMessage id="general.download.successes" />,
                            onClick: this.downloadCSV(this.getUrlDownload(true), this.getNameDownload(true)) },
                          { label: <FormattedMessage id="general.download.errors" />,
                            onClick: this.downloadCSV(this.getUrlDownload(false), this.getNameDownload(false)) },
                        ]}
                        customClass="show-for-large"
                        wide
                      />
                    </div>
                  </TableFilter>
                  {(!fasLink.length) ? (
                    <NothingBox
                      display={!fasLink.length}
                      itemsName="fasLink"
                      isLoading={isLoading}
                      filter="pushResults"
                    />
                  ) : (
                    <DataTable
                      data={fasLink}
                      getRowClass={({ success }) => ({ 'pink': !success })}
                      columns={columns}
                      sort={sort}
                      onSortChange={onSortChange}
                      paginationProps={paginationProps}
                      isExpandable={isTablet}
                      scrollable
                      tableKey="fasLink"
                    />
                  )}
                </Panel.Body>
              </Panel>
            </div>
          ) : <ClosedIntegration link="prairie-systems" /> }
        </div>
        )}
      </>
    );
  }
}

PushResults.contextTypes = {
  router: T.object.isRequired,
};

PushResults.propTypes = {
  reqParams: T.object.isRequired,
  meta: T.object.isRequired,
  fetchData: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  fasLink: T.array,
  active: T.bool,
};

const enhance = compose(
  connect(
    (state) => ({
      meta: state.dataTable.fasLink.meta,
      reqParams: state.dataTable.fasLink.params,
      isAdmin: state.auth.user.roles_map.admin,
      fasLink: state.dataTable.fasLink.resources,
      active: state.dataTable.fasLink.meta.active,
    }), (dispatch) => ({
      fetchData: (query) => dispatch(fetchData('/push_results?api=faslink', 'fasLink', query)),
    })
  ),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(PushResults);
