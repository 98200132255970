import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ActivityActionsBar from './ActivityActionsBar/ActivityActionsBar';
import ActivityCheckupBox from './ActivityCheckupBox/ActivityCheckupBox';
import ActivityBox from './ActivityBox';
import ActivityBodyCollapse from './ActivityBodyCollapse';
import ActivityHeader from './ActivityHeader';
import ActivityHeaderBadge from './ActivityHeader/ActivityHeaderBadge';
import ActivityMentions from './ActivityMentions';
import CollapsesBox from 'components/CollapsesBox/CollapsesBox';
import FarmFeedRowComments from 'components/FarmFeed/FarmFeedRowComments';
import FarmFeedRowAddComment from 'components/FarmFeed/FarmFeedRowAddComment';
import GroupInfoCollapse from 'components/Groups/GroupInfoCollapse';
import LastCommentInfo from './LastCommentInfo/LastCommentInfo';
// utils
import classnames from 'classnames/bind';
import moment from 'moment';
// hoc
import withActivityController from './hoc/withActivityController';
// styles
import styles from './WithdrawalWarningActivity.module.scss';

const cn = classnames.bind(styles);

const affectRerenderKeys = ['id', 'updated_at', 'is_viewed', 'flagged', 'comments', 'additional_data'];

const WaterUsageWarning = ({
  className,
  activity,
  currentUser,
  isChildActivity,
  markAsSeen,
  openDropdown,
  setActivityFlag,
  isMinimized,
  isTruncated,
  toggleMinimized,
  onViewFullActivity,
  defaultDropdownOptions,
}) => {
  const { id, pig_group, additional_data: { water_usage_diff_percentage, integration }, created_at, flagged,
    is_viewed, mentions } = activity;
  const date = moment(created_at).format('YYYY-MM-DD');
  const isAdmin = currentUser.roles_map.admin;

  return (
    <ActivityBox
      className={className}
      isFlagged={flagged}
      isTruncated={isTruncated}
      hasMarker={!isChildActivity}
      isUnseen={!is_viewed}
      markAsSeen={markAsSeen}
      onViewFullActivity={onViewFullActivity}
    >
      {!isMinimized &&
        <LastCommentInfo activity={activity} currentUser={currentUser} />
      }
      <ActivityHeader
        activityId={id}
        onOpenDropdown={(e) => openDropdown(e, defaultDropdownOptions)}
        onDoubleClick={toggleMinimized}
      >
        <ActivityHeaderBadge isCentered>
          <FormattedMessage id="general.waterUsageWarning" tagName="b" />
        </ActivityHeaderBadge>
      </ActivityHeader>
      <ActivityBodyCollapse isClosed={isMinimized}>
        <div className={cn('warning-text')}>
          <FormattedMessage
            tagName="div"
            id="component.waterUsageWarning.text"
            values={{
              b: (weight) => <b>{weight}</b>,
              orange: (msg) => <span className={cn('brand-orange')}>{msg}</span>,
              value: water_usage_diff_percentage > 0 ? `+${water_usage_diff_percentage}` : water_usage_diff_percentage,
            }}
          />
          <div className={cn('timestamp')}>
            {moment(created_at).format('MMM DD, YYYY, hh:mm a')}
          </div>
          <ActivityMentions isAdmin={isAdmin} users={mentions} />
        </div>

        <ActivityCheckupBox isAdmin={isAdmin} activity={activity} className={cn({ 'mb-15': isChildActivity })}>
          {pig_group && (
            <CollapsesBox className={cn('warning-collapse-box')}>
              <GroupInfoCollapse
                isAdmin={isAdmin}
                titleClassName={cn('warning-group-info')}
                group={pig_group}
                customEndpoint={`/pig_groups/${pig_group.id}/history_dc_info?history_date=${date}`}
                integration={integration}
              />
            </CollapsesBox>
          )}
        </ActivityCheckupBox>
        {!isChildActivity && (
          <>
            <ActivityActionsBar
              activity={activity}
              currentUser={currentUser}
              openDropdown={openDropdown}
              setActivityFlag={setActivityFlag}
            />
            <FarmFeedRowComments activity={activity} />
            <FarmFeedRowAddComment activity={activity} />
          </>
        )}
      </ActivityBodyCollapse>
    </ActivityBox>
  );
};

WaterUsageWarning.contextTypes = {
  router: T.object.isRequired
};

WaterUsageWarning.propTypes = {
  className: T.string,
  activity: T.object.isRequired,
  currentUser: T.object.isRequired,
  markAsSeen: T.func,
  isChildActivity: T.bool,
  openDropdown: T.func.isRequired,
  setActivityFlag: T.func.isRequired,
  // from hoc
  isTruncated: T.bool.isRequired,
  isMinimized: T.bool.isRequired,
  toggleMinimized: T.func.isRequired,
  onViewFullActivity: T.func.isRequired,
  defaultDropdownOptions: T.array.isRequired,
};

WaterUsageWarning.defaultProps = {
  isChildActivity: false,
};

export default withActivityController(WaterUsageWarning, { affectRerenderKeys });
