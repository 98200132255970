import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import AppVersionLine from 'components/AppVersionLine';
import UserAvatar from 'components/UserAvatar';
import MobilePortalOverlay from 'components/MobilePortalOverlay';
import MobileMenuSection from './MobileMenuSection';
import TenantAvatar from 'components/TenantAvatar/TenantAvatar';
import TenantSwitcher from './TenantSwitcher';
import SystemSwitcher from './SystemSwitcher';
import InsightsMenu from './InsightsMenu';
import { ReactComponent as BeamerIcon } from 'assets/svg/beamer-icon.svg';
// utils
import useBeamerUserUpdate from 'utils/hooks/useBeamerUserUpdate';
import classnames from 'classnames/bind';
import { IntercomAPI } from 'react-intercom';
import { BEAMER_APP_ID, INTERCOM_APP_ID } from 'utils/constants';
// styles
import styles from './MobileMenuPortal.module.scss';

const cn = classnames.bind(styles);

function getLeftIcon(iconClass = '') {
  return <i className={cn(iconClass, 'left-icon')} />;
}

const MobileMenuPortal = ({
  counters,
  currentUser,
}, { router }) => {
  const [{ isInsightsSelectMode, isTenantSelectMode, isSystemSelectMode }, setState] = useState({
    isTenantSelectMode: false,
    isInsightsSelectMode: false,
    isSystemSelectMode: false,
  });
  const { current_company, email, full_name, roles_map, root_companies, has_trucking_roles } = currentUser;
  const { unViewedAssetsCount } = counters;
  const { push } = router;

  useBeamerUserUpdate(currentUser);

  const goBack = () => {
    setState((prevState) => ({
      ...prevState,
      isTenantSelectMode: false,
      isInsightsSelectMode: false,
      isSystemSelectMode: false,
    }));
  };

  const handleClickOnBeamer = (e) => {
    e.stopPropagation();
    e.currentTarget.querySelector('#Beamer')?.click();
  };

  const beamerTitle = (
    <FormattedMessage id="general.whatIsNew">
      {(text) => <div id="Beamer">{text}</div>}
    </FormattedMessage>
  );

  const userInfoSection = [
    { icon: <UserAvatar size={36} user={currentUser} />, title: <b>{full_name}</b>, subtitle: email },
    { icon: <TenantAvatar name={current_company.name} highlighted />, title: current_company.name,
      onClick: () => setState((prevState) => ({ ...prevState, isTenantSelectMode: true })) },
    { icon: getLeftIcon('fa fa-industry'), title: <FormattedMessage id="general.production" />,
      onClick: () => setState((prevState) => ({ ...prevState, isSystemSelectMode: true })),
      hide: !current_company.logistics || !has_trucking_roles, },
  ];

  const insightsSection = [
    { title: <FormattedMessage id="general.pageTitle.insights" />, icon: getLeftIcon('fa fa-bar-chart'),
      onClick: () => setState((prevState) => ({ ...prevState, isInsightsSelectMode: true })) },
    { icon: getLeftIcon('fa fa-photo'), onClick: () => push('/media-gallery'),
      title: <FormattedMessage id="general.pageTitle.mediaGallery" /> },
    { icon: getLeftIcon('fa fa-ulist'), onClick: () => push('/barnsheets'),
      title: <FormattedMessage id="general.pageTitle.barnsheets" /> },
    { icon: getLeftIcon('fa fa-folder'), onClick: () => push('/tenant-assets'),
      title: <FormattedMessage id="general.pageTitle.resources" />, count: unViewedAssetsCount },
    { icon: getLeftIcon('fa fa-ep-groups'), onClick: () => push('/groups'),
      title: <FormattedMessage id="general.pageTitle.groups" />, hide: !roles_map.admin },
  ];

  const insightsChildrenSection = [
    { icon: getLeftIcon('fa fa-bar-chart'), onClick: () => push('/analytics'),
      title: <FormattedMessage id="general.pageTitle.analytics" />, hide: !roles_map.admin },
    { icon: getLeftIcon('fa fa-text-document'), onClick: () => push('/auto-report'),
      title: <FormattedMessage id="general.pageTitle.autoReport" /> },
    { icon: getLeftIcon('fa fa-map'), onClick: () => push('/asset-map'),
      title: <FormattedMessage id="general.pageTitle.assetMap" /> },
    { icon: getLeftIcon('fa fa-map-marker'), onClick: () => push('/checkins'),
      title: <FormattedMessage id="general.pageTitle.checkinsHistory" />, hide: !roles_map.admin },
    { icon: getLeftIcon('fa fa-water-soluble'), onClick: () => push('/treatments'),
      title: <FormattedMessage id="general.pageTitle.treatments" />, hide: !roles_map.admin },
  ];

  const profileSection = [
    { icon: getLeftIcon('fa fa-ep-avatar-male'), onClick: () => push('/profile'),
      title: <FormattedMessage id="general.pageTitle.myProfile" /> },
    { icon: getLeftIcon('fa fa-ep-question-circle'), onClick: () => IntercomAPI('show'),
      title: <FormattedMessage id="general.support" />, hide: !INTERCOM_APP_ID },
    { icon: <BeamerIcon height={24} />, title: beamerTitle, onClick: handleClickOnBeamer, hide: !BEAMER_APP_ID },
    { icon: getLeftIcon('fa fa-shield'), onClick: () => window.open('https://www.everypig.com/privacy-policy'),
      title: <FormattedMessage id="general.privacyPolicy" /> },
  ];

  return (
    <div className={cn('mobile-menu-box')}>
      <div className={cn('sections')}>
        <MobileMenuSection data={userInfoSection} className={cn('menu-section')} />
        <MobileMenuSection data={insightsSection} className={cn('menu-section')} />
        <MobileMenuSection data={profileSection} className={cn('menu-section')} />

        <div className={cn('logout-section')} onClick={() => router.push('/logout')}>
          <FormattedMessage id="general.logoutEverypig" />
        </div>
        <AppVersionLine className={cn('version-line')} />
      </div>

      <MobilePortalOverlay isHidden={!isTenantSelectMode && !isInsightsSelectMode && !isSystemSelectMode}>
        {isSystemSelectMode && (
          <SystemSwitcher goBack={goBack} />
        )}
        {isTenantSelectMode &&
          <TenantSwitcher currentTenantId={current_company.id} goBack={goBack} companies={root_companies} />
        }
        {isInsightsSelectMode &&
          <InsightsMenu sections={insightsChildrenSection} goBack={goBack} />
        }
      </MobilePortalOverlay>
    </div>
  );
};

MobileMenuPortal.contextTypes = {
  router: T.object.isRequired,
};

MobileMenuPortal.propTypes = {
  currentUser: T.object.isRequired,
  counters: T.object.isRequired,
};

export default MobileMenuPortal;
