import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { closeViewer, showPrev, showNext, showByIndex, updateCurrentAsset, fetchUserById } from 'reducers/mediaViewer';
import { updateTagsCount } from 'reducers/mediaGallery';
import { updateActivity } from 'reducers/farmfeed';
import { openPortal } from 'reducers/portal';
import { openModal } from 'reducers/modals';
import { updateCheckupDiagnoses, setPredictedTags } from 'reducers/dailyCheckup/checkupDiagnoses';
// components
import { FormattedMessage } from 'react-intl';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import DiagnoseButton from 'components/DiagnoseForm/DiagnoseButton';
import MediaViewerHeader from 'components/MediaViewer/MediaViewerHeader';
import MediaViewerViewBox from './MediaViewerViewBox';
import MediaViewerAttachedItems from './MediaViewerAttachedItems';
import ResponsiveDropdown from 'components/ResponsiveDropdown';
import DiagnosisSidebar from 'components/DiagnosisSidebar/DiagnosisSidebar';
import MobileDiseasesSelector from 'components/MobileDiseasesSelector/MobileDiseasesSelector';
// utils
import isEmpty from 'lodash.isempty';
import cn from 'classnames';
import { isTablet, isMobile } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { isPDFAsset, isUserCanDiagnose, showToastrMessage } from 'utils';
import { ESC_KEY, LEFT_KEY, RIGHT_KEY } from 'utils/constants';
import update from 'react-addons-update';
import MediaQuery from 'react-responsive';
// styles
import './MediaViewer.scss';

class MediaViewer extends Component {

  state = {
    isDiagnosePanelOpen: false,
    isDiagnoseFormPristine: true,
    isAttachmentsOpened: false,
  };

  componentDidMount() {
    window.addEventListener('keyup', this.handleOnKeyUp);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { assets, currentIndex, mediaUsers, currentUser } = nextProps;
    const currentAsset = assets[currentIndex];
    this.fetchAssetCreator({ currentAsset, mediaUsers, currentUser });
  }

  componentDidUpdate(prevProps) {
    const { assets, type } = this.props;
    const isAssetsChanged = assets !== prevProps.assets;
    if (isAssetsChanged && assets.length > 1) {
      if (!isMobile) {
        const { dailyCheckup } = this.getData();
        const isDCMedia = (type !== 'barnsheetsGallery') && !!dailyCheckup?.id;
        if (isDCMedia) this.setState({ isAttachmentsOpened: true });
      }
    }
    if (isAssetsChanged && !assets.length && this.state.isAttachmentsOpened) {
      this.setState({ isAttachmentsOpened: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleOnKeyUp);
  }

  fetchAssetCreator = ({ currentAsset, mediaUsers, currentUser }) => {
    const { fetchUserById } = this.props;
    const creatorId = currentAsset?.creator_id;
    if (creatorId === currentUser.id) return;
    if (!!creatorId && !(creatorId in mediaUsers)) {
      fetchUserById(creatorId);
    }
  };

  getData = () => {
    const { type, data, assets, currentIndex } = this.props;
    if (type === 'activity') {
      return {
        activity: data,
        farm: data?.farm || {},
        managerId: data?.farm?.manager?.id || null,
        dailyCheckup: data?.daily_checkup || {},
        pigGroup: data?.pig_group || {},
      };
    }
    if (['barnsheetsGallery', 'gallery'].includes(type)) {
      return {
        activity: {},
        farm: data.farm,
        managerId: data?.farm?.manager_id || null,
        dailyCheckup: { id: assets[currentIndex].daily_checkup_id },
        pigGroup: data?.pig_group || {},
      };
    }
    if (type === 'direct-post') {
      return {
        activity: {},
        farm: data?.pig_group?.farm || {},
        managerId: data?.pig_group?.farm?.manager_id || null,
        dailyCheckup: {},
        pigGroup: data?.pig_group || {},
      };
    }
    if (type === 'svr-issue') {
      return {
        activity: {},
        farm: data?.farm || {},
        managerId: null,
        dailyCheckup: {},
        pigGroup: {},
      };
    }

    return {
      activity: {},
      farm: data?.pig_group?.farm || {},
      managerId: data?.pig_group?.farm?.manager_id || null,
      dailyCheckup: data,
      pigGroup: data?.pig_group || {},
    };
  };

  handleOnKeyUp = (e) => {
    const { assets, isOpen, showPrev, showNext, closeViewer } = this.props;
    const code = e.which ? e.which : e.keyCode;
    if (isOpen) {
      if (code === ESC_KEY) {
        closeViewer();
      }
      if (code === LEFT_KEY && assets.length > 1) {
        this.handleConfirmAssetSwitch(showPrev);
      }
      if (code === RIGHT_KEY && assets.length > 1) {
        this.handleConfirmAssetSwitch(showNext);
      }
    }
  };

  downloadCurrentMedia = () => {
    const { assets, currentIndex } = this.props;
    const currentAsset = assets?.[currentIndex];
    const targetUrl = currentAsset?.url?.large || currentAsset?.url?.original || currentAsset?.url?.origin;
    if (currentAsset && targetUrl) {
      window.open(targetUrl, '_blank');
    }
  };

  toggleDiagnosePanel = () => {
    this.setState((prevState) => ({ ...prevState, isDiagnosePanelOpen: !prevState.isDiagnosePanelOpen }));
  };

  onSidebarDataSubmit = (data, declinedTagIds = []) => {
    const { dailyCheckup } = this.getData();
    const { updateCheckupDiagnoses, assets, currentIndex, predictedTags } = this.props;

    return updateCheckupDiagnoses(dailyCheckup.id, data, declinedTagIds)
      .then(({ value: { resources, meta } }) => {

        showToastrMessage('component.toastr.diagnoses.successUpdated');

        const diagnosesTags = resources.reduce((prev, next) => prev.concat(next.image_tags), []);
        const predictedTagsToSave = data.reduce((prev, next) => {
          return prev + (next.image_tags_attributes || []).filter((tag) => !!tag.prediction_image_tag_id).length;
        }, 0);

        const assetId = assets[currentIndex].id;
        const predictedTagsCount = predictedTags.filter(({ id }) => !declinedTagIds.includes(id)).length;
        const currentAssetTagsCount = diagnosesTags.filter((tag) => (tag.daily_checkup_asset_id === assetId)).length;

        this.onUpdateCounters(assetId, {
          image_tags_count: currentAssetTagsCount,
          prediction_image_tags_count: predictedTagsCount - predictedTagsToSave,
        }, { diagnosis_count: meta.total });

        return diagnosesTags;
      })
      .catch(toastResponseErrors);
  };

  onUpdateCounters = (assetId, tagsCountsData, otherCounts = {}) => {
    const { updateActivity, updateCurrentAsset, updateTagsCount, type } = this.props;
    const { activity } = this.getData();
    updateCurrentAsset(tagsCountsData);

    if (!isEmpty(activity)) {
      const assetIndex = activity.daily_checkup.dc_assets.findIndex((asset) => asset.id === assetId);
      updateActivity({
        ...activity,
        daily_checkup: {
          ...activity.daily_checkup,
          dc_assets: update(activity.daily_checkup.dc_assets, {
            [assetIndex]: { $merge: tagsCountsData },
          }),
          ...otherCounts,
        },
      });
    }

    if (type === 'gallery') {
      updateTagsCount(assetId, tagsCountsData);
    }
  };

  onOpenMobileDiseaseSelector = () => {
    const { dailyCheckup, pigGroup } = this.getData();
    this.props.openPortal(
      <MobileDiseasesSelector
        checkupId={dailyCheckup.id}
        pigGroup={pigGroup}
        onSubmitData={this.onSidebarDataSubmit}
      />
    );
  };

  onCloseViewer = () => {
    this.setState({ isDiagnosePanelOpen: false });
    this.props.closeViewer();
  };

  handleConfirmAssetSwitch = (action) => {
    const { openModal } = this.props;
    const { isDiagnosePanelOpen, isDiagnoseFormPristine } = this.state;

    if (!isDiagnosePanelOpen || isDiagnoseFormPristine) {
      return action();
    }

    return openModal(
      <ConfirmationModal
        className="media-viewer-confirm-modal"
        actionBtnLabel={<FormattedMessage id="general.button.ok" />}
        actionBtnProps={{ primary: true }}
        handleConfirm={action}
      >
        <FormattedMessage id="component.modal.diagnosesChanges.text" />
      </ConfirmationModal>
    );
  }

  render() {
    const {
      assets, currentIndex, isOpen, isSidebarCollapsed, showNext, showPrev, showByIndex, mediaUsers,
      currentUser, type, updateCheckupDiagnoses, setPredictedTags, isFullScreenMode
    } = this.props;
    const { isDiagnosePanelOpen, isAttachmentsOpened } = this.state;
    const { farm, managerId, dailyCheckup, pigGroup } = this.getData();
    const checkupId = dailyCheckup?.id;
    const pigGroupId = pigGroup?.id;
    const farmId = farm?.id;
    const isPdf = isPDFAsset(assets[currentIndex] || {});

    const dropDownItems = [
      {
        label: <FormattedMessage id={`general.download${isPdf ? 'Pdf' : 'File'}`} />,
        onClick: this.downloadCurrentMedia
      },
      {
        label: <FormattedMessage id="general.viewDailyCheckup" />, url: `/barnsheets/daily-checkup/${checkupId}`,
        hide: !checkupId
      },
      {
        label: <FormattedMessage id="general.viewBarnSheet" />, url: `/barnsheets/groups/${pigGroupId}`,
        hide: !pigGroupId
      },
      {
        label: <FormattedMessage id="general.manageFarm" />, url: `/admin/farms/${farmId}`,
        hide: !farmId || !currentUser.roles_map.admin
      },
    ];

    const currentAsset = assets && assets[currentIndex];
    const creatorId = currentAsset?.creator_id;
    const creator = creatorId === currentUser.id ? currentUser : (mediaUsers?.[creatorId] || {});

    const showBadge = type && !['direct-post', 'svr-issue', 'pdf-preview'].includes(type);
    const isUserVetOrAdmin = isUserCanDiagnose(currentUser, farm.id, managerId);
    const isAttachmentsAvailable = !isMobile;
    return (
      <div
        className={cn('mediaViewer', {
          'is-mobile': true,
          'is-fullscreen': isFullScreenMode,
          'is-tablet': isTablet,
          'is-open': isOpen,
          'is-diagnose-panel-open': isDiagnosePanelOpen,
          'is-sidebar-collapsed': isSidebarCollapsed,
        })}
      >
        {currentAsset && (
          <div className="viewer-layout">

            <MediaQuery minDeviceWidth={813}>
              <ResponsiveDropdown
                className="header-btn__dropdown"
                trigger={<i className="fa fa-dots-three-horizontal icon-dropdown" />}
                options={dropDownItems}
              />
            </MediaQuery>

            <MediaViewerHeader
              currentAsset={currentAsset}
              dailyCheckup={dailyCheckup}
              creator={creator}
              farm={farm}
              closeViewer={this.onCloseViewer}
              openDiagnosePanel={this.toggleDiagnosePanel}
              isUserCanDiagnose={isUserVetOrAdmin}
              isCollapsed={isSidebarCollapsed}
              isFullScreenMode={isFullScreenMode}
            />

            <div
              className={cn('media-viewer-body', {
                'is-attachments-opened': isAttachmentsAvailable && isAttachmentsOpened,
                'is-attachments-collapsed': isAttachmentsAvailable && !isAttachmentsOpened,
              })}
            >
              <MediaViewerViewBox
                checkupId={dailyCheckup.id}
                currentIndex={currentIndex}
                assets={assets}
                showNext={() => this.handleConfirmAssetSwitch(showNext)}
                showPrev={() => this.handleConfirmAssetSwitch(showPrev)}
                isDiagnosePanelOpen={isDiagnosePanelOpen}
                onTagUpdate={this.onSidebarDataSubmit}
                onDiagnosesPanelOpen={this.toggleDiagnosePanel}
                showBadge={showBadge}
                onUpdateCounters={this.onUpdateCounters}
                onCheckupDiagnosesUpdate={updateCheckupDiagnoses}
                onSetPredictedTags={setPredictedTags}
                isSidebarCollapsed={isSidebarCollapsed}
                isFullScreenMode={isFullScreenMode}
              />
              <div className={cn('diagnose-sidebar-wrapper', { 'opened': isDiagnosePanelOpen })}>
                {isDiagnosePanelOpen && (
                  <DiagnosisSidebar
                    currentIndex={currentIndex}
                    hasTags
                    closeOnClickOutside={false}
                    checkupId={checkupId}
                    pigGroup={pigGroup}
                    closePortal={this.toggleDiagnosePanel}
                    onSubmitData={this.onSidebarDataSubmit}
                    setIsDiagnoseFormPristine={(isDiagnoseFormPristine) => this.setState({ isDiagnoseFormPristine })}
                  />
                )}
              </div>
            </div>

            {isAttachmentsAvailable && (
              <MediaViewerAttachedItems
                isOpen={isAttachmentsOpened}
                setIsOpen={() => {
                  this.setState((prevState) => ({
                    ...prevState,
                    isAttachmentsOpened: !prevState.isAttachmentsOpened,
                  }));
                }}
                hasPredictionIcon={isUserVetOrAdmin}
                assets={assets}
                currentIndex={currentIndex}
                selectAsset={(index) => this.handleConfirmAssetSwitch(() => showByIndex(index))}
                isCollapsed={isSidebarCollapsed}
                isFullScreenMode={isFullScreenMode}
              />
            )}

            <MediaQuery maxDeviceWidth={812}>
              <div className="media-viewer-footer">
                <div className="footer-buttons">
                  <div>
                    {isUserCanDiagnose(currentUser, farm.id, managerId) &&
                      <DiagnoseButton onClick={this.onOpenMobileDiseaseSelector} />
                    }
                  </div>
                  <ResponsiveDropdown
                    className="footer-btn"
                    trigger={<i className="fa fa-dots-three-horizontal" />}
                    options={dropDownItems}
                  />
                </div>
              </div>
            </MediaQuery>
          </div>
        )}
      </div>
    );
  }
}

MediaViewer.propTypes = {
  assets: T.array.isRequired,
  currentIndex: T.number.isRequired,
  predictedTags: T.array.isRequired,
  data: T.object,
  type: T.string,
  currentUser: T.object.isRequired,
  mediaUsers: T.object,
  isOpen: T.bool,
  isSidebarCollapsed: T.bool,
  isFullScreenMode: T.bool.isRequired,
  closeViewer: T.func.isRequired,
  showPrev: T.func.isRequired,
  showNext: T.func.isRequired,
  showByIndex: T.func.isRequired,
  setPredictedTags: T.func.isRequired,
  fetchUserById: T.func.isRequired,
  updateCheckupDiagnoses: T.func.isRequired,
  openPortal: T.func.isRequired,
  openModal: T.func.isRequired,
  updateCurrentAsset: T.func.isRequired,
  updateActivity: T.func.isRequired,
  updateTagsCount: T.func.isRequired,
};

export default connect(
  (state) => ({
    assets: state.mediaViewer.assets,
    currentIndex: state.mediaViewer.currentIndex,
    data: state.mediaViewer.data,
    type: state.mediaViewer.type,
    mediaUsers: state.mediaViewer.mediaUsers,
    isOpen: state.mediaViewer.isOpen,
    isSidebarCollapsed: state.sidebar.isSidebarCollapsed,
    isFullScreenMode: state.layout.isFullScreenMode,
    currentUser: state.auth.user,
    predictedTags: state.dailyCheckup.checkupDiagnoses.predictedTags,
  }), {
    closeViewer,
    openModal,
    openPortal,
    showPrev,
    showNext,
    showByIndex,
    setPredictedTags,
    fetchUserById,
    updateCurrentAsset,
    updateTagsCount,
    updateCheckupDiagnoses,
    updateActivity,
  }
)(MediaViewer);
