import React, { Component } from 'react';
import T from 'prop-types';
// redux, actions
import { connect } from 'react-redux';
import { updateUserProfile } from 'reducers/profiles';
// components
import UserProfileEditForm from './components/UserProfileEditForm';
import FormErrorsBox from 'components/FormErrorsBox';
import Panel from 'components/Panel';
import { FormattedMessage } from 'react-intl';
// utils
import handleFormErrors from 'utils/handleFormErrors';
import { showToastrMessage } from 'utils';
import pick from 'lodash.pick';
import isEmpty from 'lodash.isempty';
import getValue from 'lodash.get';
import moment from 'moment';
// constants
import { customUserFieldsErrors } from 'constants.js';

const alwaysEditableFields = ['email', 'telephone', 'pqa_number', 'pqa_date'];

class UserProfileEdit extends Component {

  updateUserInfo = (data) => {
    const { updateUserProfile, params: { id }, user } = this.props;
    const entity = pick(data, ['email', 'first_name', 'last_name', 'nickname', 'pqa_number']);
    const day = getValue(data, 'pqa_date.day');
    const month = getValue(data, 'pqa_date.month');
    const year = getValue(data, 'pqa_date.year');
    const full_date = data.pqa_date ? `${month}/${day}/${year}` : '';
    entity.email = getValue(data, 'email', '').toLowerCase();
    entity.phone = getValue(data.telephone, 'phone');
    entity.country_code = getValue(data.telephone, 'countryCode');
    entity.birthday_date = data.birthday_date ? moment(data.birthday_date, 'MM/DD/YYYY').format('YYYY-MM-DD') : null;
    entity.pqa_expire = full_date.length ? moment(full_date, 'MM/DD/YYYY').format('YYYY-MM-DD') : undefined;

    const resource = user.registration_state === 'complete'
      ? pick(entity, ['email', 'phone', 'country_code', 'pqa_number', 'pqa_expire'])
      : entity;

    return updateUserProfile(`/admin/users/${id}`, { method: 'put', body: JSON.stringify({ resource }) })
      .then(() => {
        showToastrMessage('component.toastr.user.profileUpdated');
      })
      .catch(handleFormErrors);
  };

  render() {
    const { user, router: { location: { hash } } } = this.props;
    let initialValues;
    if (!isEmpty(user)) {
      initialValues = {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        nickname: user.nickname,
        birthday_date: user.birthday_date ? moment(user.birthday_date).format('MM/DD/YYYY') : '',
        pqa_number: user.pqa_number,
        telephone: { phone: user.phone, countryCode: user.country_code },
      };

      if (user.pqa_expire) {
        initialValues.pqa_date = {
          year: moment(user.pqa_expire).format('YYYY'),
          month: moment(user.pqa_expire).format('MM'),
          day: moment(user.pqa_expire).format('DD'),
        };
      }
    }
    return (
      <Panel className="panel">
        <Panel.Heading title={<FormattedMessage id="general.userInformation" />} />
        <Panel.Body noPadding>
          <div className="row">
            <section className="small-12 medium-10 large-8 column">
              <FormErrorsBox
                formName="user-profile-edit-form"
                customErrors={customUserFieldsErrors}
              />
              <UserProfileEditForm
                onSubmit={this.updateUserInfo}
                initialValues={initialValues}
                hash={hash}
                readOnly={user.registration_state === 'complete'}
                alwaysEditableFields={alwaysEditableFields}
              />
            </section>
          </div>
        </Panel.Body>
      </Panel>
    );
  }
}

UserProfileEdit.propTypes = {
  updateUserProfile: T.func,
  params: T.object,
  user: T.object,
  router: T.object,
};

export default connect((state) => ({
  user: state.profiles.user.data,
}), { updateUserProfile })(UserProfileEdit);
