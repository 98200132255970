import React, { useEffect } from 'react';
import T from 'prop-types';
// store
import { fetchSVRFarm, fetchSVR, resetData, deleteDraftSVR, postComment } from 'reducers/siteVisitReport';
import { openModal, closeModal } from 'reducers/modals';
import { setPageOptions } from 'reducers/layout';
// components
import Preloader from 'components/Preloader';
import ReportEdit from './ReportEdit';
import ReportMobileEdit from './ReportMobileEdit';
import ReportConfirmation from './ReportConfirmation';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
// utils
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getSVRBackLink } from 'utils/SVRHelper';
import { FormattedMessage, useIntl } from 'react-intl';

const Report = ({
  location,
  params: { farmId, svrId, templateId },
  router,
  setPageOptions,
  isDataLoaded,
  fetchSVRFarm,
  fetchSVR,
  resetData,
  deleteDraftSVR,
  unsavedComments,
  closeModal,
  postComment,
  openModal,
  report,
}) => {
  const { query, pathname } = location;
  const { push } = router;
  const { formatMessage } = useIntl();
  const backLink = getSVRBackLink(farmId, templateId,  query);

  const fetchData = async () => {
    try {
      await fetchSVRFarm(farmId);
      await fetchSVR(farmId, svrId);
    } catch (error) {
      toastResponseErrors(error);
    }
  };

  useEffect(() => {
    if (report?.categories?.every((category) => !category.svr_questions.length)) {
      toastResponseErrors({ error: formatMessage({ id: 'general.reportDoesntHaveQuestions' }) });
      push(backLink);
    }
  }, [report?.id]);

  useEffect(() => {
    fetchData();
    setPageOptions({ isFullScreenMode: true });

    return () => {
      setPageOptions({ isFullScreenMode: false });
      resetData();
    };
  }, []);

  if (!isDataLoaded) {
    return <Preloader isCentered isActive />;
  }

  const isConfirmPage = query.mode === 'confirmation';

  if (isConfirmPage) {
    return <ReportConfirmation />;
  }

  const onClose = async () => {
    try {
      await deleteDraftSVR();
      push(backLink);
    } catch (error) {
      toastResponseErrors(error);
    }
  };

  const saveUnsavedComments = async (unsavedComments) => {
    try {
      const saveComments = Promise.all(Object.keys(unsavedComments).map((key) => {
        const comment = unsavedComments[key];
        const { categoryIndex, questionIndex } = comment;
        return postComment(categoryIndex, questionIndex, comment);
      }));
      await saveComments;
      push(backLink);
    } catch (e) {
      toastResponseErrors(e);
    }

  };

  const onSave = () => {
    const notEmptyUnsavedComments = Object.keys(unsavedComments)
      .filter((key) => !!unsavedComments[key]?.comment)
      .map((key) => unsavedComments[key]);

    if (!notEmptyUnsavedComments.length) return push(backLink);

    return openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.saveReport" />}
        actionBtnLabel={<FormattedMessage id="general.yesSaveAndClose" />}
        actionBtnProps={{ blue: true }}
        cancelBtnLabel={<FormattedMessage id="general.close" />}
        cancelBtnProps={{ onClick: () => {
          closeModal();
          push(backLink);
        } }}
        handleConfirm={() => saveUnsavedComments(notEmptyUnsavedComments)}
      >
        <FormattedMessage id="general.youHaveUnsavedReportComments" tagName="p" />
      </ConfirmationModal>
    );
  };

  const onPreview = () => push({ pathname, query: { ...query, mode: 'confirmation' } });
  const svrEditProps = { onClose, onSave, onPreview };

  if (isMobile) {
    return <ReportMobileEdit {...svrEditProps} router={router} location={location} />;
  }

  return (
    <ReportEdit {...svrEditProps} />
  );
};

Report.propTypes = {
  params: T.object.isRequired,
  router: T.object.isRequired,
  unsavedComments: T.array.isRequired,
  fetchSVRFarm: T.func.isRequired,
  fetchSVR: T.func.isRequired,
  resetData: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  deleteDraftSVR: T.func.isRequired,
  isDataLoaded: T.bool.isRequired,
  location: T.object.isRequired,
  closeModal: T.func.isRequired,
  openModal: T.func.isRequired,
  postComment: T.func.isRequired,
  report: T.object,
};

export default connect(({ siteVisitReport }) => ({
  farm: siteVisitReport.farm,
  report: siteVisitReport.report,
  unsavedComments: siteVisitReport.unsavedComments,
  isDataLoaded: !!siteVisitReport.report && !!siteVisitReport.farm,
}), {
  openModal,
  closeModal,
  deleteDraftSVR,
  postComment,
  fetchSVRFarm,
  fetchSVR,
  setPageOptions,
  resetData,
})(Report);
