import React, { Component } from 'react';
import T from 'prop-types';
// components
import ReactCollapse from 'react-collapse';
import Preloader from 'components/Preloader';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './Collapse.module.scss';

const cn = classnames.bind(styles);

class Collapse extends Component {

  state = {
    isOpened: false,
  };

  componentDidMount() {
    if (this.props.isOpenOnMount) this.toggleCollapse();
  }

  toggleCollapse = () => {
    const { onToggle } = this.props;
    this.setState((prevState) => {
      if (onToggle) onToggle(!prevState.isOpened);
      return { isOpened: !prevState.isOpened };
    });
  };

  resetCollapse = () => this.setState({ isOpened: false });

  render() {
    const { isOpened } = this.state;
    const { title, titleClassName, className, isLoading, icon, children } = this.props;
    return (
      <div className={cn('collapse', { isOpened, [className]: !!className })}>
        <div
          className={cn('collapse-title', { isOpened, [titleClassName]: !!titleClassName })}
          onClick={this.toggleCollapse}
        >
          <div className="flex align-center mr-10 nowrap-ellipsis">
            {title}
          </div>
          <i className={cn(icon, 'icon', { isOpened })} />
        </div>

        <ReactCollapse isOpened={isOpened}>
          <Preloader isActive={isLoading} />
          {children}
        </ReactCollapse>

      </div>
    );
  }
}

Collapse.defaultProps = {
  icon: 'fa fa-angle-right',
  isOpenOnMount: false,
};

Collapse.propTypes = {
  className: T.string,
  titleClassName: T.string,
  isOpenOnMount: T.bool,
  title: T.oneOfType([T.string, T.node]),
  onToggle: T.func,
  icon: T.string,
  isLoading: T.bool,
  children: T.node,
};

export default Collapse;
