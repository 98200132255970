import Resumable from 'resumablejs';
import { getAuthData } from './authData';
import { API_URL } from '../constants';
import { getCheckupChunkAsset } from 'endpoints/checkup/checkupEdit';

export const uploadAssetToCheckup = (checkupId, asset) => {
  return new Promise((resolve, reject) => {
    const uploadPath = `${API_URL}/daily_checkups/${checkupId}/chunk`;
    const R = new Resumable({
      target: uploadPath,
      testTarget: uploadPath,
      headers: { 'Accept': '*/*', ...getAuthData() },
      simultaneousUploads: 1,
      testChunks: true,
      chunkRetryInterval: 500,
      maxFileSize: 314572800,
    });
    R.addFile(asset);

    if (R.support) {
      R.on('fileAdded', (file) => file.resumableObj.upload());
      R.on('fileSuccess', (file) => getCheckupChunkAsset(checkupId, { chunk_identifier: file.uniqueIdentifier })
        .then(resolve).catch(reject));
    }
  });
};
