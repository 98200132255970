import React, { Component } from 'react';
import T from 'prop-types';
import { API_URL } from '../../constants';
// redux
import { connect } from 'react-redux';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { setPageOptions } from 'reducers/layout';
import { openModal, closeModal } from 'reducers/modals';
import { openViewer } from 'reducers/mediaViewer';
import { increaseUnviewedAssetsCount } from 'reducers/sidebar';
import { fetchData, setDataItem, setDataItemByIndex, resetResources, setViewedResources, removeDataItemByIndex,
  mergeDataItemToTop } from 'reducers/dataTable';
import withDropdownActions from 'hoc/withDropdownActions';
// components
import { FormattedMessage } from 'react-intl';
import ResourcesSubNav from './components/ResourcesSubNav';
import ResourcesDropzone from './components/ResourcesDropzone';
import AddTenantAssetModal from './components/AddTenantAssetModal';
import TenantAssetsTable from './components/TenantAssetsTable';
import TenantAssetsList from './components/TenantAssetsList';
import TenantAssetsBoxesContainer from './components/TenantAssetsBoxesContainer';
import UploadsList from './components/UploadsList';
import Preloader from 'components/Preloader';
import OfflineScreen from 'components/OfflineScreen';
import Button from 'components/Button';
import StickyFooter from 'components/StickyFooter';
// utils
import Resumable from 'resumablejs';
import { getAuthData } from 'utils/authData';
import { toastr } from 'react-redux-toastr';
import { isMobile } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getFileTypeData } from 'utils/tenantAssetsHelper';
import cn from 'classnames';
import findIndex from 'lodash.findindex';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import download from 'downloadjs';
// api
import { removeTenantAsset, markViewedAssets } from 'endpoints/tenantResources';
// styles
import './TenantResources.scss';

const types = ['csv', 'doc', 'docx', 'xls', 'xlsx', 'jpg', 'jpeg', 'gif', 'png', 'pdf', 'mp4', 'mov', 'avi'];
const fileTypes = types.concat(types.map((ext) => ext.toUpperCase()));

const defaultParams = { sort: 'updated_at desc', page: 1, per_page: 50, padding: 0 };

class TenantResources extends Component {

  constructor() {
    super();
    this.state = {
      files: [],
      showUploads: false,
      isLoaded: false,
      isLargeScreen: window.innerWidth >= 1024,
    };
    this.viewedIds = new Set();
    this.throttleCheck = throttle(this.sendViewedIds, 2000, { leading: false });
    this.handleScreenResize = debounce(this.setScreenView, 300);
  }

  componentDidMount() {
    const { setBreadcrumbs, isAdmin, isOnline, fetchData, reqParams, location: { query } } = this.props;
    const { isLargeScreen } = this.state;
    setBreadcrumbs([
      { label: <FormattedMessage id="general.pageTitle.resources" />, useLabelAsMobileTitle: true }
    ]);
    this.setPageOptions(isLargeScreen, query.view_type === 'block');
    window.addEventListener('resize', this.handleScreenResize);
    if (isOnline) {
      fetchData({ ...reqParams, ...defaultParams })
        .then(() => {
          this.setState({ isLoaded: true });
          if (isAdmin && isOnline) {
            this.dropzoneElement = document.getElementById('resource-dropzone');
            this.initializeFileUploader();
          }
        })
        .catch(toastResponseErrors);
    }
  }

  UNSAFE_componentWillReceiveProps({ isOnline, resources, reqParams, fetchData }) {
    const goOnline = isOnline && (isOnline !== this.props.isOnline);
    const goOffline = !isOnline && (isOnline !== this.props.isOnline);
    const hasResources = !!resources.length;
    if (goOffline) {
      this.setState({ isLoaded: false });
    }
    if (goOnline && hasResources) {
      this.setState({ isLoaded: true });
    }
    if (goOnline && !hasResources) {
      fetchData({ ...reqParams, ...defaultParams }).then(() => {
        this.setState({ isLoaded: true });
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { isOnline, isAdmin } = this.props;
    const { isOnline: isOnlinePrev } = prevProps;
    // needed when network appears on offline screen
    if (!this.dropzoneElement && isAdmin && isOnline && (isOnline !== isOnlinePrev)) {
      this.dropzoneElement = document.getElementById('resource-dropzone');
      this.initializeFileUploader();
    }
  }

  componentWillUnmount() {
    const { resetResources, setPageOptions } = this.props;
    resetResources();
    setPageOptions({ rightButton: '' });
    window.removeEventListener('resize', this.handleScreenResize);
  }

  onSearchChange = (search) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, ...defaultParams, search }).catch(toastResponseErrors);
  };

  onSortChange = (sort) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, ...defaultParams, sort }).catch(toastResponseErrors);
  };

  setPageOptions = (isLargeScreen, isBlockView) => {
    const { setPageOptions } = this.props;
    const rightButton = !isLargeScreen
      ? { icon: `fa fa-${isBlockView ? 'ulist' : 'grid-two-up'}`, onClick: this.onViewTypeToggle }
      : '';
    setPageOptions({ rightButton });
  };

  setScreenView = () => {
    const isLargeScreen = window.innerWidth >= 1024;
    this.setState((prevState) => {
      if (prevState.isLargeScreen === isLargeScreen) return null;
      const { location: { query } } = this.props;
      this.setPageOptions(isLargeScreen, query.view_type === 'block');
      return { ...prevState, isLargeScreen };
    });
  };

  onAddFileClick = () => {
    const { openModal, closeModal, mergeDataItemToTop } = this.props;
    openModal(
      <AddTenantAssetModal
        onClose={closeModal}
        onLinkCreate={mergeDataItemToTop}
        onBrowseLinkAssign={this.assignBrowseLink}
      />,
      { position: isMobile ? 'bottom' : '' }
    );
  };

  openEditLinkModal = (asset) => {
    const { openModal, closeModal, setDataItem } = this.props;
    const { id, link, name } = asset;
    openModal(
      <AddTenantAssetModal
        assetId={id}
        onClose={closeModal}
        onLinkUpdate={setDataItem}
        initialMode="edit_link"
        initialLink={link}
        initialName={name}
      />,
      { position: isMobile ? 'bottom' : '' }
    );
  };

  openRenameModal = (asset) => {
    const { openModal, closeModal, setDataItem } = this.props;
    const { id, name } = asset;
    openModal(
      <AddTenantAssetModal
        assetId={id}
        onClose={closeModal}
        onFileNameUpdate={setDataItem}
        initialMode="rename"
        initialName={name}
      />,
      { position: isMobile ? 'bottom' : '' }
    );
  };

  onAssetRemove = (id) => {
    const { fetchData, reqParams } = this.props;
    removeTenantAsset(id)
      .then(() => {
        toastr.success('', '', {
          icon: <i className="fa fa-thumbs-up" />,
          component: (
            <FormattedMessage id="component.toastr.asset.hasBeenDeleted">
              {(text) => (<div className="rrt-text">{text}</div>)}
            </FormattedMessage>
          ),
        });
        fetchData({ ...reqParams, ...defaultParams });
      })
      .catch(toastResponseErrors);
  };

  assignBrowseLink = (browseLink) => {
    if (this.resumable && this.resumable.support) {
      this.resumable.assignBrowse(browseLink);
    }
  };

  // File uploader

  initializeFileUploader = () => {
    const uploadPath = `${API_URL}/admin/company_chunk`;
    this.resumable = new Resumable({
      target: uploadPath,
      testTarget: uploadPath,
      headers: { 'Accept': '*/*', ...getAuthData() },
      simultaneousUploads: 1,
      testChunks: true,
      chunkRetryInterval: 500,
      maxFileSize: 314572800,
      fileType: fileTypes,
      fileTypeErrorCallback: (file) => {
        toastr.error('', '', {
          icon: <i className="fa fa-ban" />,
          className: 'file-type-error',
          component: (
            <FormattedMessage
              id="component.toastr.availFileTypes.text"
              values={{ filename: file.name, types: types.join(', ') }}
            >
              {(text) => <div className="rrt-text">{text}</div>}
            </FormattedMessage>
          ),
        });
      },
      maxFileSizeErrorCallback: (file) => {
        toastr.error('', '', {
          icon: <i className="fa fa-ban" />,
          component: (
            <FormattedMessage id="general.fileSizeExceeded" values={{ size: '300mb', filename: file.name }}>
              {(text) => <div className="rrt-text">{text}</div>}
            </FormattedMessage>
          ),
        });
      },
    });

    if (this.resumable.support) {
      this.resumable.assignDrop(this.dropzoneElement);
      this.resumable.on('fileAdded', this.onFileAdded);
      this.resumable.on('fileSuccess', this.onFileSuccess);
      this.resumable.on('fileError', this.onFileError);
      this.resumable.on('fileProgress', this.onFileProgress);
    } else {
      // eslint-disable-next-line no-console
      console.warn('ResumableJS not supported!');
    }
  };

  onFileAdded = (resumableFile) => {
    const R = resumableFile.resumableObj;
    const { isOpenModal, closeModal, mergeDataItemToTop } = this.props;
    if (isMobile) {
      const newAsset = {
        uniqueIdentifier: resumableFile.uniqueIdentifier,
        filename: resumableFile.fileName,
        content_type: getFileTypeData(resumableFile.fileName).contentType,
        uploadProgress: 0,
        cancel: resumableFile.cancel,
      };
      mergeDataItemToTop(newAsset);
    } else {
      const { files } = this.state;
      this.setState({ showUploads: true, files: [...files, resumableFile] });
    }
    if (resumableFile.container === this.dropzoneElement) this.dropzoneComponent.resetDragCounter();
    R.upload();
    if (isOpenModal) closeModal();
  };

  onFileSuccess = (resumableFile) => {
    const { resources, setDataItemByIndex, fetchData, reqParams } = this.props;
    if (!isMobile) {
      const { files } = this.state;
      const index = findIndex(files, { uniqueIdentifier: resumableFile.uniqueIdentifier });
      const updatedFile = { ...files[index], uploadProgress: resumableFile.progress() };
      this.setState({
        files: [
          ...files.slice(0, index),
          updatedFile,
          ...files.slice(index + 1),
        ],
      });
    } else {
      const index = findIndex(resources, { uniqueIdentifier: resumableFile.uniqueIdentifier });
      const updatedFile = { ...resources[index], uploadProgress: resumableFile.progress() };
      setDataItemByIndex(updatedFile, index);
    }
    if (this.resumable.progress() === 1) {
      fetchData({ ...reqParams, ...defaultParams }).catch(toastResponseErrors);
      setTimeout(() => this.setState({ files: [] }), 4000);
    }
    if (resumableFile.progress() === 1) {
      resumableFile.resumableObj.removeFile(resumableFile);
    }
  };

  onFileError = (file, message) => {
    // eslint-disable-next-line no-console
    console.log('ResumableJS: onFileError', file.fileName);
    toastResponseErrors(message);
  };

  onFileProgress = (file) => {
    if (!isMobile) {
      const { files } = this.state;
      const index = findIndex(files, { uniqueIdentifier: file.uniqueIdentifier });
      const updatedFile = { ...files[index], uploadProgress: file.progress() };
      this.setState({
        files: [
          ...files.slice(0, index),
          updatedFile,
          ...files.slice(index + 1),
        ],
      });
      return;
    }

    const { resources, setDataItemByIndex } = this.props;
    const index = findIndex(resources, { uniqueIdentifier: file.uniqueIdentifier });
    const updatedFile = { ...resources[index], uploadProgress: file.progress() };
    setDataItemByIndex(updatedFile, index);
  };

  removeFileFromQueue = (file) => {
    const { files } = this.state;
    const fileIndex = findIndex(files, { uniqueIdentifier: file.uniqueIdentifier });
    this.setState({
      files: [
        ...files.slice(0, fileIndex),
        ...files.slice(fileIndex + 1),
      ],
    });
  };

  removeFileFromTable = (file) => {
    const { resources, removeDataItemByIndex } = this.props;
    const index = findIndex(resources, { uniqueIdentifier: file.uniqueIdentifier });
    removeDataItemByIndex(index);
  };

  cancelUpload = (file) => {
    file.cancel();
    if (isMobile) {
      this.removeFileFromTable(file);
      return;
    }
    this.removeFileFromQueue(file);
  };

  onUploadsListClose = () => (this.setState({ showUploads: false }));

  openDropdown = (asset) => (e, customTarget, centered) => {
    const { openDropdown, isAdmin } = this.props;
    const { id, link, content_type } = asset;
    const canPreview = !link && ['CompanyImage', 'CompanyPdf'].includes(content_type);

    const options = [
      { label: <FormattedMessage id="general.preview" />, icon: 'remove-red-eye', desktopIcon: true,
        onClick: () => this.onPreviewAsset(asset), hide: !canPreview },
      { label: <FormattedMessage id="general.button.download" />, icon: 'download-btn', desktopIcon: true,
        onClick: () => this.downloadAsset(asset), hide: !!link },
      { label: <FormattedMessage id="general.openUrl" />, icon: 'open-in-new', desktopIcon: true,
        onClick: () => window.open(link, '_blank'), hide: !link },
      { label: <FormattedMessage id="general.editUrl" />, icon: 'pencil-mdc', desktopIcon: true,
        onClick: () => this.openEditLinkModal(asset), hide: !isAdmin || !link },
      { label: <FormattedMessage id="general.editName" />, icon: 'pencil-mdc', desktopIcon: true,
        onClick: () => this.openRenameModal(asset), hide: !isAdmin || !!link },
      { label: <FormattedMessage id="general.button.remove" />, icon: 'trash-o', desktopIcon: true,
        onClick: () => this.onAssetRemove(id), hide: !isAdmin },
    ];

    const target = customTarget || e.target;
    const targetCoords = target.getBoundingClientRect();
    const customCoords = centered ? {
      height: targetCoords.height,
      left: targetCoords.left,
      top: targetCoords.top,
      width: targetCoords.width,
      x: targetCoords.x,
      y: targetCoords.y,
      bottom: targetCoords.bottom - targetCoords.height + 20,
      right: targetCoords.right + targetCoords.width - 40,
    } : undefined;

    openDropdown(e, options, { customTarget: target, customCoords });
  };

  onPreviewAsset = (asset) => {
    const { openViewer } = this.props;
    if (['CompanyImage', 'CompanyVideo'].includes(asset.content_type)) {
      openViewer([asset]);
      return;
    }
    if (asset.content_type === 'CompanyLink') {
      window.open(asset.link, '_blank');
      return;
    }
    if (asset.content_type === 'CompanyPdf') {
      openViewer([asset], 0, {}, 'pdf-preview');
      return;
    }
    this.downloadAsset(asset);
  };

  downloadAsset = (asset) => {
    if (asset.url.original) download(asset.url.original);
  };

  onViewTypeToggle = () => {
    const { router } = this.context;
    const { location: { query } } = this.props;
    const { isLargeScreen } = this.state;
    this.setPageOptions(isLargeScreen, !query.view_type);
    if (query.view_type) {
      router.push('/tenant-assets');
      return;
    }
    router.push({ pathname: '/tenant-assets', query: { view_type: 'block' } });
  };

  markAsSeen = (asset) => () => {
    this.viewedIds.add(asset.id);
    this.throttleCheck();
  };

  sendViewedIds = () => {
    const { setViewedResources, increaseUnviewedAssetsCount } = this.props;
    const viewedIdsCount = this.viewedIds.size;
    if (viewedIdsCount) {
      markViewedAssets(this.viewedIds)
        .then(() => {
          setViewedResources(this.viewedIds);
          increaseUnviewedAssetsCount(-viewedIdsCount);
          this.viewedIds.clear();
        })
        .catch(toastResponseErrors);
    }
  };

  onLoadMoreResources = () => {
    const { reqParams, fetchData } = this.props;
    fetchData({ ...reqParams, page: reqParams.page + 1 }, true).catch(toastResponseErrors);
  };

  render() {
    const { files, showUploads, isLoaded, isLargeScreen } = this.state;
    const { resources, reqParams, reqParams: { search, page }, meta, isLoading, isOnline, isAdmin,
      location: { query } } = this.props;
    const isEmpty = isLoaded && !resources.length && !search;
    const showForAdmin = isAdmin && isLoaded && !isEmpty;
    const isBlockView = query.view_type === 'block';
    const totalAssetsCount = meta.total || 0;
    const currentAssetsCount = resources.length;
    const hasMoreResources = !!currentAssetsCount && (totalAssetsCount > currentAssetsCount);
    const isFirstPageLoading = (page === 1) && isLoading;

    if (!isOnline) return <OfflineScreen />;

    return (
      <div className="TenantResources">
        <ResourcesSubNav
          viewType={query.view_type}
          search={search}
          onSearchChange={this.onSearchChange}
          onAddClick={this.onAddFileClick}
          hasActionBtn={isAdmin}
          isEmpty={isEmpty}
        />
        <Preloader className="tenant-resources-preloader" isActive={isFirstPageLoading} />
        {isAdmin && (
          <ResourcesDropzone
            ref={(ref) => { this.dropzoneComponent = ref; }}
            isEmpty={isEmpty}
          />
        )}
        {(!isAdmin || showForAdmin) && isLargeScreen && !isBlockView && (
          <TenantAssetsTable
            resources={resources}
            reqParams={reqParams}
            isLoading={isLoading}
            hasMoreResources={hasMoreResources}
            onSortChange={this.onSortChange}
            onLoadMore={this.onLoadMoreResources}
            onOpenDropdown={this.openDropdown}
            onMarkItemAsSeen={this.markAsSeen}
            onPreviewAsset={this.onPreviewAsset}
          />
        )}
        {(!isAdmin || showForAdmin) && !isLargeScreen && !isBlockView && (
          <div className="list-wrapper">
            <TenantAssetsList
              search={search}
              resources={resources}
              isLoading={isLoading}
              hasMoreResources={hasMoreResources}
              onOpenDropdown={this.openDropdown}
              onMarkItemAsSeen={this.markAsSeen}
              onLoadMore={this.onLoadMoreResources}
              onCancelItemUpload={this.cancelUpload}
              onPreviewAsset={this.onPreviewAsset}
            />
          </div>
        )}
        {(!isAdmin || showForAdmin) && isBlockView && (
          <div className="list-wrapper">
            <TenantAssetsBoxesContainer
              search={search}
              resources={resources}
              hasMoreResources={hasMoreResources}
              isLoading={isLoading}
              onLoadMore={this.onLoadMoreResources}
              onOpenDropdown={this.openDropdown}
              onMarkItemAsSeen={this.markAsSeen}
              onPreviewAsset={this.onPreviewAsset}
            />
          </div>
        )}
        {!!files.length && showUploads && (
          <UploadsList
            files={files}
            onClose={this.onUploadsListClose}
            onFileUploadCancel={this.cancelUpload}
          />
        )}
        {isAdmin && !isLargeScreen && isLoaded && (
          <StickyFooter className={cn({ 'assets-footer': !resources.length })}>
            <Button className="add-btn hide-for-large" primary onClick={this.onAddFileClick}>
              <i className="fa fa-plus mr-10" />
              <FormattedMessage id="general.button.add" />
            </Button>
          </StickyFooter>
        )}
      </div>
    );
  }
}

TenantResources.propTypes = {
  location: T.object.isRequired,
  setPageOptions: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  openModal: T.func.isRequired,
  closeModal: T.func.isRequired,
  openDropdown: T.func.isRequired,
  isAdmin: T.bool.isRequired,
  isOpenModal: T.bool.isRequired,
  isOnline: T.bool.isRequired,
  fetchData: T.func.isRequired,
  setDataItem: T.func.isRequired,
  setDataItemByIndex: T.func.isRequired,
  removeDataItemByIndex: T.func.isRequired,
  mergeDataItemToTop: T.func.isRequired,
  resetResources: T.func.isRequired,
  setViewedResources: T.func.isRequired,
  increaseUnviewedAssetsCount: T.func.isRequired,
  openViewer: T.func.isRequired,
  resources: T.array.isRequired,
  reqParams: T.object.isRequired,
  meta: T.object.isRequired,
  isLoading: T.bool.isRequired,
};

TenantResources.contextTypes = {
  router: T.object.isRequired,
};

export default connect(
  (state) => ({
    isAdmin: state.auth.user.roles_map.admin,
    isOpenModal: state.modals.isOpen,
    isOnline: state.network.isOnline,
    resources: state.dataTable.tenantAssets.resources,
    reqParams: state.dataTable.tenantAssets.params,
    meta: state.dataTable.tenantAssets.meta,
    isLoading: state.dataTable.tenantAssets.isLoading,
  }), {
    fetchData: (query, isLoadMore) => fetchData('/company_assets', 'tenantAssets', query, isLoadMore),
    setDataItem: (data) => setDataItem(data, 'tenantAssets'),
    setDataItemByIndex: (data, index) => setDataItemByIndex(data, index, 'tenantAssets'),
    removeDataItemByIndex: (index) => removeDataItemByIndex(index, 'tenantAssets'),
    mergeDataItemToTop: (data) => mergeDataItemToTop(data, 'tenantAssets'),
    resetResources: () => resetResources('tenantAssets'),
    setViewedResources: (ids) => setViewedResources(ids, 'tenantAssets'),
    setPageOptions,
    setBreadcrumbs,
    increaseUnviewedAssetsCount,
    openModal,
    closeModal,
    openViewer,
  },
)(withDropdownActions(TenantResources));
