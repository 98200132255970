import { fetchFromAPI } from 'utils/api';

export const createReportTemplate = (resource) => {
  return fetchFromAPI(
    '/admin/svr_templates',
    { method: 'post', body: JSON.stringify({ resource }) }
  ).then((response) => response.resource);
};

export const updateReportTemplate = (resourceId, resource) => {
  return fetchFromAPI(
    `/admin/svr_templates/${resourceId}`,
    { method: 'PUT', body: JSON.stringify({ resource }) }
  ).then((response) => response.resource);
};

export const getReportTemplate = (id) => {
  return fetchFromAPI(`/admin/svr_templates/${id}`, { method: 'get' }).then((response) => response.resource);
};

export const getReportTemplates = (params) => {
  return fetchFromAPI('/admin/svr_templates', { method: 'get', params });
};
