import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { extendParamsOnChange } from 'components/DataTable/DataTableController/DataTableController';
import {
  fetchGroupCheckups,
  enableEditMode,
  setSelectedCheckupId,
  resetSelectedCheckupId,
  clearGroupCheckups,
} from 'reducers/barnsheets/barnsheetsGroupCheckups';
import { openModal, TYPE_PANEL } from 'reducers/modals';
import { openPortal } from 'reducers/portal';
import { updateCheckupDiagnoses } from 'reducers/dailyCheckup/checkupDiagnoses';
// hoc
import withDropdownActions from 'hoc/withDropdownActions';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import Button from 'components/Button';
import DownloadLink from 'components/DownloadLink';
import DataTable from 'components/DataTable/DataTable';
import CustomColumn from 'components/DataTable/Columns/CustomColumn';
import ArrowColumn from 'components/DataTable/Columns/ArrowColumn';
import NothingBox from 'components/NothingBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import MobileDiseasesSelector from 'components/MobileDiseasesSelector/MobileDiseasesSelector';
import ModalSidebar from 'components/Modal/ModalSidebar';
import DiagnosisSidebar from 'components/DiagnosisSidebar/DiagnosisSidebar';
import FormattedWeight from 'components/FormattedWeight';
// endpoints
import { downloadDailyCheckupCSV } from 'endpoints/dailyCheckups';
// utils
import cn from 'classnames';
import download from 'downloadjs';
import moment from 'moment';
import identity from 'lodash.identity';
import isEmpty from 'lodash.isempty';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { extendCorrection, dailyCheckupVariety } from '../utils/editMode';
import { formatMortality, isUserCanDiagnose, showToastrMessage } from 'utils';
import { isTablet, isMobile } from 'react-device-detect';
import { animateScroll } from 'react-scroll';
import debounce from 'lodash.debounce';
import { isUserExactlyCaregiver } from 'utils/rolesHelper';
import { isSchedulingTariffUser } from 'utils/userHelper';
// styles
import './BarnsheetsGroupCheckups.scss';

class BarnsheetsGroupCheckups extends Component {

  resourcesCorrections = [];

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    const { pigGroup, reqParams } = this.props;
    this.fetchCheckupsWithParams(reqParams, pigGroup).then(this.scrollToRecord);
  }

  componentWillUnmount() {
    const { clearGroupCheckups } = this.props;
    clearGroupCheckups(); // we need it for auto scroll, after going back to this component scroll is 'twitching' because of resources refresh
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pigGroup: { id, in_edit_mode } } = this.props;
    const { id: next_id, in_edit_mode: next_in_edit_mode } = nextProps.pigGroup;
    // edit mode toggle
    // refetch checkups on edit mode change
    if (id === next_id && in_edit_mode !== next_in_edit_mode) {
      this.fetchCheckupsWithParams(nextProps.reqParams, nextProps.pigGroup);
    }

    // in edit mode before props.resources receiving - recalculate corrections
    if (in_edit_mode && nextProps.resources) {
      this.resourcesCorrections = nextProps.resources.map(extendCorrection);
    }
  }

  scrollToRecord = () => {
    // TODO: find out the way to scroll without ref and with 'scroller' from 'react-scroll'
    if (this.ref?.current) {
      const { resetSelectedCheckupId } = this.props;
      const { y, height } = this.ref.current.getBoundingClientRect();
      const headerHeight = 55;
      const yPosition = y - height - headerHeight;
      animateScroll.scrollTo(yPosition, { containerId: 'bodybag', smooth: true });
      debounce(resetSelectedCheckupId, 2000)();
      this.ref = undefined;
    }
  }

  // DataTableController methods
  onSortChange = (sort) => {
    const { pigGroup, reqParams } = this.props;
    const newParams = extendParamsOnChange('sort', reqParams, sort);
    this.fetchCheckupsWithParams(newParams, pigGroup);
  };

  onPageChange = (page) => {
    const { pigGroup, reqParams } = this.props;
    const newParams = extendParamsOnChange('page', reqParams, page);
    this.fetchCheckupsWithParams(newParams, pigGroup);
  };

  onPerPageChange = (perPage) => {
    const { pigGroup, reqParams } = this.props;
    const newParams = extendParamsOnChange('perPage', reqParams, perPage);
    this.fetchCheckupsWithParams(newParams, pigGroup);
  };

  fetchCheckupsWithParams = (params, pigGroup) => {
    const { fetchGroupCheckups, validateCorrections } = this.props;
    const { location } = this.props;
    const isUrlEditMode = location.query?.editMode || false;
    const isEditMode = pigGroup?.in_edit_mode || isUrlEditMode;
    const inventoryStartAt = pigGroup?.edit_inventory_started_on;
    return fetchGroupCheckups(params, isEditMode, inventoryStartAt).then(validateCorrections);
  };

  downloadCSV = (checkupId) => (e) => {
    e.stopPropagation();
    return downloadDailyCheckupCSV(checkupId)
      .then((blob) => download(blob, `daily-checkup-${checkupId}.csv`, 'text/csv'))
      .catch(toastResponseErrors);
  };

  editDailyCheckup = (checkup) => (e) => {
    e.stopPropagation();
    const { enableEditMode } = this.props;
    const { router } = this.context;
    enableEditMode()
      .then(() => router.push(`/barnsheets/daily-checkup/${checkup.id}?editMode=true`));
  };

  handleRedirectToDailyCheckup = (checkup) => {
    const { setSelectedCheckupId } = this.props;
    setSelectedCheckupId(checkup.id);
    this.props.router.push(`/barnsheets/daily-checkup/${checkup.id}`);
  }

  renderCheckupDateColumn = (checkup) => {
    const { pigGroup: { in_edit_mode }, selectedCheckupId } = this.props;
    const isEditMode = Boolean(in_edit_mode);
    const variety = dailyCheckupVariety(checkup);
    const isPreviouslySelectedCheckup = selectedCheckupId === checkup.id;
    return (
      <CustomColumn flexRow>
        <div ref={isPreviouslySelectedCheckup ? this.ref : undefined}>
          <span className="name-icon">
            {(variety === 'generated') && <span className={`icon fa fa-ban ${checkup.confirmed ? '' : 'alert'}`} />}
            {(variety === 'empty_farm') && <span className="icon fa fa-no-pigs" />}
            {(variety === 'default') && <span className="icon fa fa-check-circle" />}
            <span className="color-primary">
              <Link onClick={() => this.handleRedirectToDailyCheckup(checkup)}>
                {moment(checkup.created_on).format('L')}
              </Link>
            </span>
            {!!checkup.dc_assets.length && (
              <FormattedMessage id="general.media">
                {(text) => <span className="media-label">{text}</span>}
              </FormattedMessage>
            )}
          </span>
          {!isEditMode && (
            <DownloadLink
              className="no-margin hide-for-large button"
              endpoint={`/csv/daily_checkups/${checkup.id}.csv`}
            >
              <span className="fs-16 fa fa-download-btn" />
            </DownloadLink>
          )}
        </div>
      </CustomColumn>
    );
  };


  renderDeathsColumn = (checkup, { rowIndex }) => (
    <CustomColumn label={<FormattedMessage id="general.deaths" />} textRight>
      {this.renderSingleValue(checkup, rowIndex, 'deaths_sum')}
    </CustomColumn>
  );

  renderHeadTreatedColumn = (checkup, { rowIndex }) => (
    <CustomColumn textRight label={<FormattedMessage id="general.headTreated" />}>
      {this.renderSingleValue(checkup, rowIndex, 'total_pigs_treated')}
    </CustomColumn>
  );

  renderSymptomsColumn = (checkup, { rowIndex }) => (
    <CustomColumn textRight label={<FormattedMessage id="general.symptoms" />}>
      {this.renderSingleValue(checkup, rowIndex, 'symptom_count')}
    </CustomColumn>
  );

  renderPlacedColumn = (checkup, { rowIndex }) => (
    <CustomColumn textRight label={<FormattedMessage id="general.pigsIn" />}>
      {this.renderSingleValue(checkup, rowIndex, 'pigs_added')}
    </CustomColumn>
  );

  renderPigsOutColumn = (checkup, { rowIndex }) => (
    <CustomColumn textRight label={<FormattedMessage id="general.pigsOut" />}>
      {this.renderPigsOutValue(checkup, rowIndex)}
    </CustomColumn>
  );

  renderInventoryColumn = (checkup, { rowIndex }) => (
    <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.inventory" />}>
      {this.renderSingleValue(checkup, rowIndex, 'pigs')}
    </CustomColumn>
  );

  renderCorrectionsColumn = (checkup, { rowIndex }) => (
    <CustomColumn textRight label={<FormattedMessage id="general.corrections" />}>
      {this.renderSingleValue(checkup, rowIndex, 'pigs_corrected')}
    </CustomColumn>
  );

  renderMRColumn = (checkup, { rowIndex }) => (
    <CustomColumn textRight label={<FormattedMessage id="general.mrShort" />}>
      {this.renderSingleValue(checkup, rowIndex, 'mortality_rate', formatMortality)}
    </CustomColumn>
  );

  renderEstimatedWeightColumn = (checkup, { rowIndex }) => (
    <CustomColumn textRight label={<FormattedMessage id="general.estAvgWeight" />} noBottomBorder>
      {this.renderSingleValue(
        checkup,
        rowIndex,
        'estimated_weight',
        (val) => <FormattedWeight value={val} />
      )}
    </CustomColumn>
  );

  renderSingleValue = (checkup, rowIndex, valueKey, formatter = identity) => {
    const correctionData = this.getCheckupCorrection(rowIndex);
    const correctedCheckup = { ...checkup, ...correctionData };
    const value = correctedCheckup?.[valueKey] || 0;
    const currentValue = checkup?.[valueKey] || 0;
    const isValueCorrected = !isEmpty(correctionData) && formatter(value) !== formatter(currentValue);
    return (
      <span className={cn({ 'corrected': isValueCorrected })}>
        {(formatter(value) && formatter(value) !== '0' ? formatter(value) : '-')}
      </span>
    );
  };

  renderPigsOutValue = (checkup, rowIndex) => {
    const correctionData = this.getCheckupCorrection(rowIndex);
    const checkupData = { ...checkup, ...correctionData };
    const value = checkupData.pigs_out || (checkupData.pigs_sale + checkupData.pigs_transfer);
    const currentValue = checkup.pigs_sale + checkup.pigs_transfer;
    const isValueCorrected = !isEmpty(correctionData) && (value !== currentValue);
    return (
      <span className={cn({ 'corrected': isValueCorrected })}>
        {value && (value !== '0') ? value : '-'}
      </span>
    );
  };

  getCheckupCorrection = (rowIndex) => {
    const { pigGroup: { in_edit_mode } } = this.props;
    return in_edit_mode ? this.resourcesCorrections[rowIndex] : {};
  };

  renderTabletButtons = (checkup) => {
    const { pigGroup: { in_edit_mode } } = this.props;
    const isEditMode = Boolean(in_edit_mode);
    return (
      <div>
        {!isEditMode && (
          <Button small light onClick={this.downloadCSV(checkup.id)}>
            <i className="fa fa-download-btn mr-5" />
            <FormattedMessage id="general.button.downloadData" />
          </Button>
        )}
        <Link
          to={`/barnsheets/daily-checkup/${checkup.id}${isEditMode ? '?editMode=true' : ''}`}
          className="button small"
        >
          <i className={`fa fa-${isEditMode ? 'edit' : 'eye'} mr-5`} />
          {isEditMode
            ? <FormattedMessage id="container.dailyCheckup.editCheckup" />
            : <FormattedMessage id="general.viewCheckup" />}
        </Link>

        <Button onClick={this.openSidebar(checkup)} light>
          <FormattedMessage id="general.editDiagnosis" />
        </Button>
      </div>
    );
  };

  onSidebarDataSubmit = (checkupId, data) => {
    const { updateCheckupDiagnoses } = this.props;
    return updateCheckupDiagnoses(checkupId, data)
      .then(() => {
        showToastrMessage('component.toastr.diagnoses.successUpdated');
      })
      .catch(toastResponseErrors);
  };

  openSidebar = ({ id }) => () => {
    const { openModal, openPortal, pigGroup } = this.props;

    if (isMobile) {
      openPortal(
        <MobileDiseasesSelector
          checkupId={id}
          pigGroup={pigGroup}
          onSubmitData={(data) => this.onSidebarDataSubmit(id, data)}
        />
      );
    } else {
      openModal(
        <ModalSidebar>
          <DiagnosisSidebar
            checkupId={id}
            pigGroup={pigGroup}
            onSubmitData={(data) => this.onSidebarDataSubmit(id, data)}
          />
        </ModalSidebar>,
        {},
        TYPE_PANEL
      );
    }
  };

  openDropdown = (e, dc) => {
    const { openDropdown, pigGroup, authUser, isEditingDisabled, isSchedulingAccessOnly } = this.props;
    const options = [
      { label: <FormattedMessage id="general.button.view" />, url: `/barnsheets/daily-checkup/${dc.id}` },
      { label: <FormattedMessage id="container.dailyCheckup.editCheckup" />, onClick: this.editDailyCheckup(dc),
        hide: isEditingDisabled },
      { label: <FormattedMessage id="general.button.downloadData" />, onClick: this.downloadCSV(dc.id) },
      { label: <FormattedMessage id="general.editDiagnosis" />, onClick: this.openSidebar(dc),
        hide: isSchedulingAccessOnly || !isUserCanDiagnose(authUser, pigGroup.farm, pigGroup.farm.manager_id) },
    ];
    openDropdown(e, options);
  };

  renderButtons = (checkup, { rowIndex }) => {
    const { pigGroup: { in_edit_mode }, isEditingDisabled, isSchedulingAccessOnly } = this.props;
    const isEditMode = Boolean(in_edit_mode);
    return (
      <div className="collapsible-value button-column actions">
        {isEditMode
          ? (
            <Link
              to={`/barnsheets/daily-checkup/${checkup.id}?editMode=true`}
              className="button small light-gray wide show-for-large"
            >
              <FormattedMessage id="general.button.edit" />
            </Link>
          )
          : (
            <i
              data-name={`${checkup.id}-${rowIndex}`}
              className="fa fa-dots-three-horizontal show-for-large"
              onClick={(e) => this.openDropdown(e, checkup)}
            />
          )}
        {isEditMode && (
          <Link to={`/barnsheets/daily-checkup/${checkup.id}?editMode=true`} className="button light hide-for-large">
            <FormattedMessage id="general.button.edit" />
          </Link>
        )}
        {!isEditMode && !isEditingDisabled && (
          <Button className="hide-for-large" light onClick={this.editDailyCheckup(checkup)}>
            <FormattedMessage id="container.dailyCheckup.editCheckup" />
          </Button>
        )}
        {!isEditMode && (
          <Link to={`/barnsheets/daily-checkup/${checkup.id}`} className="button hide-for-large light-gray">
            <FormattedMessage id="general.viewCheckup" />
          </Link>
        )}
        {!isEditMode && !isSchedulingAccessOnly && (
          <Button darkGrey onClick={this.openSidebar(checkup)} className="button hide-for-large">
            <FormattedMessage id="general.editDiagnosis" />
          </Button>
        )}
      </div>
    );
  };

  render() {
    const { isTouchable } = this.context;
    const { meta: { total }, reqParams: { page, per_page }, resources, isLoading,
      pigGroup: { in_edit_mode }, selectedCheckupId } = this.props;
    const isEditMode = Boolean(in_edit_mode);

    const columns = [
      { label: <FormattedMessage id="general.date" />, flex: '1 0 150px', renderer: this.renderCheckupDateColumn },
      { label: <FormattedMessage id="general.allDeaths" />, flex: '1 0 80px', renderer: this.renderDeathsColumn,
        textRight: true },
      { label: <FormattedMessage id="general.headTreated" />, flex: '1 0 80px',
        renderer: this.renderHeadTreatedColumn, textRight: true },
      { label: <FormattedMessage id="general.symptoms" />, flex: '1 0 80px',
        renderer: this.renderSymptomsColumn, textRight: true },
      { label: <FormattedMessage id="general.pigsIn" />, flex: '1 0 80px', renderer: this.renderPlacedColumn,
        textRight: true },
      { label: <FormattedMessage id="general.pigsOut" />, flex: '1 0 80px', renderer: this.renderPigsOutColumn,
        textRight: true },
      { label: <FormattedMessage id="general.corrections" />, flex: '1 0 60px', renderer: this.renderCorrectionsColumn,
        textRight: true },
      { label: <FormattedMessage id="component.dataTable.headers.inventory" />, flex: '1 0 80px',
        renderer: this.renderInventoryColumn, textRight: true },
      { label: <FormattedMessage id="general.mrShort" />, flex: '1 0 60px', renderer: this.renderMRColumn,
        textRight: true },
      { label: <FormattedMessage id="general.estAvgWeight" />, flex: '1 0 100px',
        renderer: this.renderEstimatedWeightColumn, textRight: true },
      { label: '', flex: isEditMode ? '0 0 70px' : '0 0 45px', renderer: this.renderButtons, fixed: true,
        className: isTablet ? 'hide-for-large' : '' },
      { label: '', flex: isEditMode ? '0 0 70px' : '0 0 45px', renderer: () => <ArrowColumn />, fixed: true,
        hide: !isTablet, hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange: this.onPageChange,
      onPerPageChange: this.onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
      perPageOptions: [10, 25, 50, 100, 150, 200],
    };

    return (
      <Panel className={cn('BarnsheetsGroupCheckups', { isTouchable })}>
        <Panel.Heading title={<FormattedMessage id="general.dailyCheckups" />} />
        <Panel.Body noPadding>
          <Preloader isActive={isLoading} />

          <DataTable
            data={resources}
            columns={columns}
            onSortChange={this.onSortChange}
            paginationProps={paginationProps}
            getRowClass={({ variety, confirmed, id }) => ({
              'color-grey pink': variety === 'generated' && !confirmed,
              'color-grey grey': variety === 'empty_farm',
              'previously-selected': id === selectedCheckupId
            })}
            isExpandable={isTablet}
            renderExpandable={this.renderTabletButtons}
            scrollable
          />

          <NothingBox
            itemsName="barnsGroupCheckups"
            display={!resources.length}
            isLoading={isLoading}
          >
            <h2 className="lighter">
              <FormattedMessage id="component.nothingBox.noData" />
            </h2>
          </NothingBox>
        </Panel.Body>
      </Panel>
    );
  }
}

BarnsheetsGroupCheckups.contextTypes = {
  router: T.object.isRequired,
  isTouchable: T.bool,
};

BarnsheetsGroupCheckups.defaultProps = {
  resources: [],
};

BarnsheetsGroupCheckups.propTypes = {
  resources: T.array,
  isLoading: T.bool.isRequired,
  reqParams: T.object.isRequired,
  meta: T.object.isRequired,
  pigGroup: T.object,
  location: T.object,
  authUser: T.object,
  fetchGroupCheckups: T.func.isRequired,
  enableEditMode: T.func.isRequired,
  openDropdown: T.func.isRequired,
  router: T.object.isRequired,
  setSelectedCheckupId: T.func.isRequired,
  resetSelectedCheckupId: T.func.isRequired,
  selectedCheckupId: T.number,
  clearGroupCheckups: T.func.isRequired,
  isEditingDisabled: T.bool.isRequired,
  isSchedulingAccessOnly: T.bool.isRequired,
  // cloned from parent
  validateCorrections: T.func.isRequired,
  updateCheckupDiagnoses: T.func.isRequired,
  openModal: T.func.isRequired,
  openPortal: T.func.isRequired,
};

export default connect(
  (state, { pigGroup }) => {
    const isExactlyCaregiver = isUserExactlyCaregiver(state.auth.user, pigGroup.farm_id);
    return {
      isEditingDisabled: state.auth.user.current_company.barnsheets_limited_access && isExactlyCaregiver,
      resources: state.barnsheets.groupCheckups.resources,
      isLoading: state.barnsheets.groupCheckups.isLoading,
      reqParams: state.barnsheets.groupCheckups.params,
      meta: state.barnsheets.groupCheckups.meta,
      pigGroup: state.barnsheets.groupCheckups.pigGroup,
      selectedCheckupId: state.barnsheets.groupCheckups.selectedCheckupId,
      authUser: state.auth.user,
      isSchedulingAccessOnly: isSchedulingTariffUser(state.auth.user),
    };
  }, (dispatch, { params: { id } }) => ({
    fetchGroupCheckups: (params, isEditMode, date) => dispatch(fetchGroupCheckups(id, params, isEditMode, date)),
    enableEditMode: () => dispatch(enableEditMode(id)),
    openModal: (component, options, type) => dispatch(openModal(component, options, type)),
    openPortal: (component, options) => dispatch(openPortal(component, options)),
    updateCheckupDiagnoses: (checkupId, data) => dispatch(updateCheckupDiagnoses(checkupId, data)),
    setSelectedCheckupId: (checkupId) => dispatch(setSelectedCheckupId(checkupId)),
    resetSelectedCheckupId: () => dispatch(resetSelectedCheckupId()),
    clearGroupCheckups: () => dispatch(clearGroupCheckups()),
  })
)(withDropdownActions(BarnsheetsGroupCheckups));
