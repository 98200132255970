import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import UserPanel from 'components/UserPanel/UserPanel';
import Link from 'react-router/lib/Link';
import Button from 'components/Button';
// utils
import moment from 'moment';
import { isUserCanDiagnose } from 'utils';
import { diagnosesTypes } from 'utils/diagnosesHelper';
// styles
import './DiagnosisPanel.scss';

const DiagnosisPanel = ({ diagnosis, pigGroup, currentUser, dropdownOptions, onEdit, farm, pgDiagnosis }) => {
  const group = diagnosis.pig_group || pigGroup;
  const createdBy = diagnosis.created_by || { full_name: currentUser.current_company.name };
  const customUserName = (createdBy.id === currentUser.id)
    ? <FormattedMessage id="general.you" />
    : createdBy.name;
  let modifyUserName = '';
  if (diagnosis.modified_by) {
    modifyUserName = (diagnosis.modified_by.id === currentUser.id)
      ? <FormattedMessage id="general.you" />
      : diagnosis.modified_by.name;
  }
  return (
    <div className="diagnosis-block">
      <UserPanel
        headerLine={diagnosis.modified_by && (
          <div className="user-modify">
            <FormattedMessage
              id="component.diagnosisHistory.editOn"
              values={{
                user: <Link to={`/admin/users/${diagnosis.modified_by.id}`}>{modifyUserName}</Link>,
                date: moment(diagnosis.updated_at).format('MMM DD, YYYY'),
              }}
            />
          </div>
        )}
        dropdownOptions={dropdownOptions}
        customUserName={customUserName}
        user={createdBy}
        label={(
          <FormattedMessage
            id={pgDiagnosis ?
              'component.diagnosisHistory.healthStatusApplied' :
              'component.diagnosisHistory.diagnosedPigs'}
            values={{
              groupName: <b>{group.name}</b>,
            }}
          />
        )}
        timestamp={diagnosis.created_at}
        className="user-details"
      >
        <div className="panel inner-panel">
          <div className="add-diagnose">
            <i className="diagnose-plus fa fa-diagnosis" />
            <FormattedMessage id={pgDiagnosis ? 'general.healthStatus' : 'general.diagnosis'}>
              {(text) => <span className="diagnosis">{text}</span>}
            </FormattedMessage>
            {isUserCanDiagnose(currentUser, farm?.id, farm?.manager?.id) && !pgDiagnosis && (
              <Button small light onClick={onEdit}>
                <FormattedMessage id="general.button.edit" />
              </Button>
            )}
          </div>
          <div className="diagnose-name">
            {pgDiagnosis ? (
              <span className="name">{diagnosis.diagnosis.short_name || diagnosis.diagnosis.name}</span>
            ) : (
              <>
                <span className="name">{diagnosis.diagnosis.name}</span>
                <span>{diagnosesTypes[diagnosis.diagnosis_type]}</span>
              </>
            )}
          </div>
          {diagnosis.note && (
            <div className="diagnose-note">
              <span className="note"><FormattedMessage id="general.notes" />: </span>
              <span className="italic">{diagnosis.note}</span>
            </div>
          )}
        </div>
      </UserPanel>
    </div>
  );
};

DiagnosisPanel.defaultProps = {
  pgDiagnosis: false,
};

DiagnosisPanel.propTypes = {
  diagnosis: T.object.isRequired,
  onEdit: T.func.isRequired,
  pigGroup: T.object,
  currentUser: T.object.isRequired,
  dropdownOptions: T.array.isRequired,
  farm: T.object,
  pgDiagnosis: T.bool,
};

export default DiagnosisPanel;
