import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { updateUserSetting, fetchUserSetting } from 'reducers/auth';
import { setForceSync } from 'reducers/network';
// components
import { FormattedMessage } from 'react-intl';
import ToggleSwitcher from 'components/ToggleSwitcher';
import Button from 'components/Button';
import ReactSelect from 'components/Select/ReactSelect';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import PreferenceItem from './components/PreferenceItem';
import Link from 'react-router/lib/Link';
import { BasicPlanCustomizationBlocker } from 'components/BasicPlan';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { langOptions } from 'utils/localizationHelper';
import { setLSLanguage } from 'utils/localStorageHelper';
import { isSchedulingTariffUser } from 'utils/userHelper';
import { clearOfflineDB } from 'db/offline-db';
// styles
import './MyProfilePreferences.scss';

const MyProfilePreferences = ({
  fetchUserSetting,
  isLoaded,
  isLoading,
  user,
  userSetting,
  updateUserSetting,
  isSchedulingAccessOnly,
  setForceSync,
  isForceSync,
}) => {
  const { id: currentUserId, language: currentUserLanguage, birthday_date } = user;
  const { enable_ff_check_ins = false, language: settingLanguage, birthday_congrats = false } = userSetting;

  useEffect(() => {
    if (!isLoaded) {
      fetchUserSetting(user.id).catch(toastResponseErrors);
    }
  }, []);

  const handleChangeLanguage = ({ value }) => {
    if (currentUserLanguage === value) return;
    updateUserSetting({ language: value }, currentUserId)
      .then((response) => {
        setLSLanguage(response.value.language);
      })
      .catch(toastResponseErrors);
  };

  const handleEnableSetting = (e, settingName) => {
    const { checked } = e.target;
    if (userSetting[settingName] === checked) return;
    updateUserSetting({ [settingName]: checked }, currentUserId).catch(toastResponseErrors);
  };

  const synchronizeOfflineData = async () => {
    try {
      await clearOfflineDB();
      setForceSync(true);
    } catch (err) {
      toastResponseErrors(err);
    }
  };

  return (
    <Panel>
      <Panel.Heading title={<FormattedMessage id="general.preferences" />} />
      <Panel.Body noPadding>
        <div className="preferences-body">
          <Preloader isActive={isLoading || isForceSync} />
          <PreferenceItem title={<FormattedMessage id="container.myProfile.preferences.lang" tagName="strong" />}>
            <ReactSelect
              className="lang-select"
              options={langOptions}
              value={settingLanguage}
              labelKey="label"
              onChange={handleChangeLanguage}
              matchProp="value"
              searchable={false}
            />
          </PreferenceItem>
          {!isSchedulingAccessOnly && (
            <>
              <PreferenceItem title={<FormattedMessage id="general.offlineMode" tagName="strong" />}>
                <div className="description mb-15"><FormattedMessage id="general.syncData.desc" /></div>
                <Button disabled={isForceSync} onClick={synchronizeOfflineData} primary>
                  <FormattedMessage id="general.syncData" />
                </Button>
              </PreferenceItem>
              <PreferenceItem title={<FormattedMessage id="container.myProfile.personal" tagName="strong" />}>
                {birthday_date
                  ? (
                    <BasicPlanCustomizationBlocker>
                      <ToggleSwitcher
                        checked={birthday_congrats}
                        onChange={(e) => handleEnableSetting(e, 'birthday_congrats')}
                        text={<FormattedMessage id="container.myProfile.birthdayReminders" />}
                        description={<FormattedMessage id="container.myProfile.birthdayRemindersDesc" />}
                      />
                    </BasicPlanCustomizationBlocker>
                  )
                  : (
                    <div className="unavailable-reminder">
                      <div>
                        <FormattedMessage tagName="b" id="container.myProfile.birthdayReminders" />:&nbsp;
                        <span className="unavailable-caption"><FormattedMessage id="general.unavailable" /></span>
                      </div>
                      <div className="info-link">
                        <FormattedMessage id="container.myProfile.mustHaveBirthday" />&nbsp;
                        <Link to="/profile/edit">
                          <FormattedMessage id="component.userProfile.manageBirthdate" />
                        </Link>
                      </div>
                    </div>
                  )}
              </PreferenceItem>
              <PreferenceItem title={<FormattedMessage id="general.pageTitle.farmfeed" tagName="strong" />}>
                <BasicPlanCustomizationBlocker>
                  <ToggleSwitcher
                    checked={enable_ff_check_ins}
                    onChange={(e) => handleEnableSetting(e, 'enable_ff_check_ins')}
                    text={<FormattedMessage id="container.myProfile.farmfeed.checkins" />}
                    description={<FormattedMessage id="container.myProfile.farmfeed.checkins.description" />}
                  />
                </BasicPlanCustomizationBlocker>
              </PreferenceItem>
            </>
          )}
        </div>
      </Panel.Body>
    </Panel>
  );
};

MyProfilePreferences.propTypes = {
  updateUserSetting: T.func.isRequired,
  fetchUserSetting: T.func.isRequired,
  user: T.object.isRequired,
  userSetting: T.object.isRequired,
  isLoading: T.bool.isRequired,
  isLoaded: T.bool.isRequired,
  isSchedulingAccessOnly: T.bool,
  isForceSync: T.bool.isRequired,
  setForceSync: T.func.isRequired,
};

export default connect(
  (state) => ({
    user: state.auth.user,
    userSetting: state.auth.user_setting,
    isLoading: state.auth.isSettingLoading,
    isLoaded: state.auth.isSettingLoaded,
    isSchedulingAccessOnly: isSchedulingTariffUser(state.auth.user),
    isForceSync: state.network.isForceSync,
  }),
  { updateUserSetting, fetchUserSetting, setForceSync }
)(MyProfilePreferences);
