import React from 'react';
import T from 'prop-types';
// components
import FarmInformationBar from './components/FarmInformationBar';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import CategoryViewRow from './components/CategoryViewRow';
import SubmitStepper from './components/SubmitStepper';
import SVRStatBlocks from './components/SVRStatBlocks';
import SVRHeader from './components/SVRHeader';
import Preloader from 'components/Preloader';
import SVRComment from 'components/SVRComment';
import SVRMediaRow from './components/SVRMediaRow';
// store
import { publishSVR } from 'reducers/siteVisitReport';
// utils
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import { getSVRBackLink, getSVRNotesComment } from 'utils/SVRHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { isMobile } from 'react-device-detect';
// styles
import styles from './Report.module.scss';

const cn = classnames.bind(styles);

const ReportConfirmation = ({ isLoading, farm, report, currentUser, publishSVR }, { router }) => {
  const { location: {  query, pathname } } = router;
  const { createdAt, categories, general_notes, action_plan, svr_assets, user } = report;
  const backLink = getSVRBackLink(farm.id, report.svr_template_id, query);

  const onSave = () => router.push(backLink);

  const onBack = () => {
    const query = isMobile ? { currentStep: categories.length - 1 } : {};
    router.push({ pathname, query });
  };

  const onSubmit = async () => {
    try {
      await publishSVR();
      router.push(backLink);
    } catch (error) {
      toastResponseErrors(error);
    }
  };

  const categoryViewRenderer = (categoryData, idx) => (
    <CategoryViewRow categoryIndex={idx} category={categoryData} key={idx} />
  );

  return (
    <div className={cn('wrapper', { loading: isLoading })}>
      <Preloader isCentered isActive={isLoading} />
      <SVRHeader
        templateId={report.svr_template_id}
        farmId={farm.id}
        farmName={farm.name}
        onClose={onSave}
        createdAt={createdAt}
      >
        <Button onClick={onSave} className={cn('save-close-btn')}>
          <FormattedMessage id="general.button.saveAndClose" />
        </Button>
      </SVRHeader>
      <SubmitStepper className={cn('stepper')} />
      <div className={cn('svr-content')}>
        <div className={cn('farm-bar')}>
          <FarmInformationBar farm={farm} currentCompany={currentUser.current_company} />
        </div>
        <div className={cn('content-wrapper')}>
          <div className={cn('content')}>
            <SVRStatBlocks categories={categories} />
            <div className="mt-30">
              {categories.map(categoryViewRenderer)}
            </div>

            {general_notes && (
              <div className={cn('general-section', 'mt-15')}>
                <div className={cn('section-title')}>
                  <FormattedMessage id="general.generalNotes" />
                </div>
                <div>
                  <SVRComment comment={getSVRNotesComment({ value: general_notes }, user)} />
                </div>
              </div>
            )}

            {action_plan && (
              <div className={cn('general-section', 'mt-15')}>
                <div className={cn('section-title')}>
                  <FormattedMessage id="general.actionPlan" />
                </div>
                <div>
                  <SVRComment comment={getSVRNotesComment({ value: action_plan }, user)} />
                </div>
              </div>
            )}

            {!!svr_assets.length && (
              <div className={cn('general-section', 'mt-15', 'mb-15')}>
                <div className={cn('section-title')}>
                  <FormattedMessage id="general.media" />
                </div>
                <SVRMediaRow media={svr_assets} />
              </div>
            )}

          </div>
        </div>
      </div>
      <div className={cn('svr-footer')}>
        <div className={cn('category-info', 'semibold')}>
          <FormattedMessage id="general.button.review" />
        </div>
        <div className={cn('actions')}>
          <Button onClick={onBack} className={cn('btn', 'back')}>
            <i className="mr-10 fa fa-angle-left-btb" />
            <FormattedMessage id="general.button.back" />
          </Button>
          <SubmitStepper className={cn('stepper')} />
          <Button onClick={onSubmit} className={cn('btn')} primary>
            <FormattedMessage id="general.button.submit" />
            <i className="ml-10 fa fa-angle-right-btb" />
          </Button>
        </div>
      </div>
    </div>
  );
};

ReportConfirmation.contextTypes = {
  router: T.object.isRequired,
};

ReportConfirmation.propTypes = {
  farm: T.object,
  report: T.object.isRequired,
  currentUser: T.object.isRequired,
  isLoading: T.bool.isRequired,
  publishSVR: T.func.isRequired,
};

export default connect((state) => ({
  currentUser: state.auth.user,
  isLoading: state.siteVisitReport.isLoading,
  farm: state.siteVisitReport.farm,
  report: state.siteVisitReport.report,
}), { publishSVR })(ReportConfirmation);
