import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// utils
import { isUserExactlyCaregiver } from 'utils/rolesHelper';
// styles
import styles from './DailyCheckupEditProtector.module.scss';

const DailyCheckupEditProtector = ({ children, isEditingDisabled }, { router: { location: { query } } }) => {
  const isReviewMode = !!query.reviewMode;

  if (isEditingDisabled && isReviewMode) {
    return (
      <div className={styles['protect-container']}>
        <div className={styles.protector} />
        {children}
      </div>
    );
  }

  return children;
};

DailyCheckupEditProtector.contextTypes = {
  router: T.object.isRequired,
};

DailyCheckupEditProtector.propTypes = {
  isEditingDisabled: T.bool.isRequired,
  children: T.node.isRequired,
};

export default connect((state, { pigGroup }) => { // we need pigGroup to verify role by farm_id
  const currentUser = state?.auth?.user;
  const farmId = pigGroup?.farm_id;
  const isExactlyCaregiver = isUserExactlyCaregiver(currentUser, farmId);
  return {
    isEditingDisabled: currentUser.current_company.barnsheets_limited_access && isExactlyCaregiver,
  };
})(DailyCheckupEditProtector);
