import React, { Component } from 'react';
import T from 'prop-types';
// redux, actions, recompose
import { connect } from 'react-redux';
import { fetchData, setDataItem } from 'reducers/dataTable';
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn, CheckboxColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import MessageBox from 'components/MessageBox';
import DropdownButton from 'components/DropdownButton';
import Button from 'components/Button';
import TruncateHint from 'components/TruncateHint';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { openModal } from 'reducers/modals';
// utils
import classnames from 'classnames/bind';
import { capitalize, getMedType, showToastrMessage } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { fetchFromAPI } from 'utils/api';
// styles
import styles from './Medication.scss';

const cn = classnames.bind(styles);

const tableFilters = [
  { label: <FormattedMessage id="general.allProducts" />, value: '' },
  { label: <FormattedMessage id="general.meds.oral" />, value: 'oral' },
  { label: <FormattedMessage id="general.meds.subcutaneous" />, value: 'subcutaneous' },
  { label: <FormattedMessage id="general.meds.intramuscular" />, value: 'intramuscular' },
  { label: <FormattedMessage id="general.meds.intravenous" />, value: 'intravenous' },
  { label: <FormattedMessage id="general.meds.topical" />, value: 'topical' },
];

class Medications extends Component {

  componentDidMount() {
    const { reqParams, fetchData } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  onFilterChange = (filter) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, page: 1, route: filter });
  };

  updateMedication = (id) => (e) => {
    const { setDataItem } = this.props;
    const { name } = e.target;

    fetchFromAPI(`/admin/treatment_products/${id}`, {
      method: 'PUT',
      body: JSON.stringify({ resource: { [name]: e.target.checked } }),
    })
      .then(({ resource }) => {
        setDataItem(resource);
        showToastrMessage('component.toastr.medication.updated');
      })
      .catch(toastResponseErrors);
  };

  openConfirmModal = ({ id, active }) => {
    if (!active) {
      this.changeMedicationStatus(id, active);
      return;
    }

    this.props.openModal(
      <ConfirmationModal
        title={<FormattedMessage id="component.modal.disableMedication.title" />}
        actionBtnLabel={<FormattedMessage id="component.modal.disableMedication.confirm" />}
        actionBtnProps={{ red: true }}
        warningMessage={<FormattedMessage id="component.modal.disableMedication.warning" />}
        handleConfirm={() => this.changeMedicationStatus(id, active)}
      >
        <FormattedMessage id="component.modal.disableMedication.text" tagName="p" />
      </ConfirmationModal>
    );
  };

  renderProductNameColumn = ({ id, name, route = [] }) => {
    const { isAdmin } = this.props;
    return (
      <CustomColumn
        className={cn('treatment-name')}
        label={<FormattedMessage id="component.dataTable.headers.productName" />}
      >
        <div className={cn('product-name-column')}>
          <i className={`fa fa-${getMedType({ route })} icon show-for-large`} />
          {isAdmin ? (
            <Link to={`/admin/health-variables/medications/${id}`} className="color-primary">
              <span>{name}</span>
            </Link>
          )
            : <span className="color-primary">{name}</span>}
        </div>
      </CustomColumn>
    );
  };

  showRouteForHint = (route) => (
    <TruncateHint
      className="routes-hint"
      name={route.map((item) => capitalize(item)).join(', ')}
      showName={<span>{capitalize(route[0])} <span className="plus-route">{` +${route.length - 1}`}</span></span>}
    />
  );

  renderRouteColumn = (medication) => {
    const route = medication.route || [];
    return (
      <CustomColumn label={<FormattedMessage id="general.route" />}>
        <span className="route-column">
          {route.length > 1 ? this.showRouteForHint(route) : capitalize(route[0] || '')}
        </span>
      </CustomColumn>
    );
  };

  renderUnitTypeColumn = ({ unit_type }) => (
    <CustomColumn label={<FormattedMessage id="general.unit" />}>
      {unit_type ? <FormattedMessage id={`general.${unit_type}`} /> : ''}
    </CustomColumn>
  );

  renderVaccineColumn = ({ vaccine }) => (
    <CustomColumn label={<FormattedMessage id="general.vaccine" />}>
      <FormattedMessage id={`general.button.${vaccine ? 'yes' : 'no'}`} />
    </CustomColumn>
  );

  renderCommonColumn = ({ common, id }) => (
    <CheckboxColumn
      label={<FormattedMessage id="general.common" />}
      name="common"
      onChange={this.updateMedication(id)}
      checked={Boolean(common)}
    />
  );

  renderSafeColumn = ({ safe, id }) => (
    <CheckboxColumn
      label={<FormattedMessage id="general.safe" />}
      name="safe"
      onChange={this.updateMedication(id)}
      checked={Boolean(safe)}
    />
  );

  renderVetBotColumn = ({ efficacy_survey, id }) => (
    <CheckboxColumn
      label={<FormattedMessage id="general.survey" />}
      name="efficacy_survey"
      onChange={this.updateMedication(id)}
      checked={Boolean(efficacy_survey)}
    />
  );

  renderStatusColumn = ({ active }) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <StatusBadge status={active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  changeMedicationStatus = (id, active) => {
    const { setDataItem } = this.props;
    fetchFromAPI(`/admin/treatment_products/${id}`, {
      method: 'PUT',
      body: JSON.stringify({ resource: { active: !active } }),
    })
      .then(({ resource }) => {
        setDataItem(resource);
        showToastrMessage(`component.toastr.medication.${active ? 'deactivated' : 'activated'}`);
      })
      .catch(toastResponseErrors);
  };

  renderActionsColumn = (item, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${rowIndex}`}
        customClass="show-for-large"
        wide
        buttonType="small light-gray"
        label={<FormattedMessage id={`general.button.${item.active ? 'disable' : 'enable'}`} />}
        onClick={() => this.openConfirmModal(item)}
        dropDownData={[
          {
            label: <FormattedMessage id="general.manageMedication" />,
            url: `/admin/health-variables/medications/${item.id}`,
          },
        ]}
      />
      <Link to={`/admin/health-variables/medications/${item.id}`} className="button light hide-for-large">
        <FormattedMessage id="general.manage" />
      </Link>
      <Button
        default
        onClick={() => this.openConfirmModal(item)}
        className="hide-for-large"
      >
        <FormattedMessage id={`general.button.${item.active ? 'disable' : 'enable'}`} />
      </Button>
    </div>
  );

  renderExpandable = (item) => (
    <div>
      <Link className="button light small" to={`/admin/health-variables/medications/${item.id}`}>
        <i className="fa fa-pencil mr-5" /><FormattedMessage id="general.manage" />
      </Link>
      <Button small light onClick={() => this.openConfirmModal(item)}>
        <i className={`fa fa-${item.active ? 'times' : 'check'}-circle mr-5`} />
        <FormattedMessage id={`general.button.${item.active ? 'disable' : 'enable'}`} />
      </Button>
    </div>
  );

  render() {
    const { isLoading, meta: { stats = {}, total }, data, reqParams: { page, per_page, route: type, search, sort },
      onSearchChange, onPageChange, onPerPageChange, onSortChange, isABFEnabled } = this.props;
    const { isTablet } = this.context;

    const isEmptyTable = !data.length && !type && !search;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.productName" />, flex: '2 0 170px',
        renderer: this.renderProductNameColumn, sortKey: 'name' },
      { label: <FormattedMessage id="general.route" />, flex: '2 0 130px', renderer: this.renderRouteColumn },
      { label: <FormattedMessage id="general.unit" />, flex: '1 0 70px', renderer: this.renderUnitTypeColumn },
      { label: <FormattedMessage id="general.vaccine" />, flex: '1 0 70px', renderer: this.renderVaccineColumn,
        sortKey: 'vaccine' },
      { label: <FormattedMessage id="general.common" />, flex: '1 0 90px', renderer: this.renderCommonColumn,
        sortKey: 'common' },
      { label: <FormattedMessage id="general.safe" />, flex: '1 0 90px', renderer: this.renderSafeColumn,
        sortKey: 'safe', hide: !isABFEnabled },
      { label: <FormattedMessage id="general.survey" />, flex: '1 0 90px', renderer: this.renderVetBotColumn,
        sortKey: 'efficacy_survey' },
      { label: <FormattedMessage id="component.dataTable.headers.status" />, flex: '1 1 120px',
        renderer: this.renderStatusColumn, sortKey: 'status' },
      { label: '', flex: '0 0 155px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };
    const supportLink = <a className={cn('support-link')} href="mailto:support@everypig.com">support@everypig.com</a>;

    return (
      <div className={cn('Medications')}>
        <MessageBox type="warning">
          <div className={cn('warning-box')}>
            <strong><FormattedMessage id="general.medicationWarningQuestion" />&nbsp;</strong>
            <FormattedMessage id="general.medicationWarningText" values={{ link: supportLink }} />
          </div>
        </MessageBox>

        <Panel>
          <Panel.Heading title={<FormattedMessage id="general.products" />}>
            {!isEmptyTable &&
              <SearchBox initialValue={search} onChange={onSearchChange} />}
          </Panel.Heading>
          <Panel.Body noPadding>
            {!isEmptyTable && (
              <TableFilter
                filters={tableFilters}
                activeFilter={type}
                onFilterChange={this.onFilterChange}
                stats={stats}
                className="ph-10"
              />
            )}

            <Preloader isActive={isLoading} />

            <DataTable
              data={data}
              columns={columns}
              sort={sort}
              onSortChange={onSortChange}
              paginationProps={paginationProps}
              isExpandable={isTablet}
              renderExpandable={this.renderExpandable}
              scrollable
              tableKey="adminMedications"
            />

            <NothingBox
              itemsName="medications"
              display={!data.length}
              isLoading={isLoading}
              search={search}
              filter={type}
            >
              <h2 className="lighter"><FormattedMessage id="component.nothingBox.noData" /></h2>
            </NothingBox>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}

Medications.contextTypes = {
  isTablet: T.bool.isRequired,
};

Medications.propTypes = {
  data: T.array.isRequired,
  isLoading: T.bool.isRequired,
  reqParams: T.object.isRequired,
  meta: T.object.isRequired,
  fetchData: T.func.isRequired,
  setDataItem: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  isAdmin: T.bool,
  openModal: T.func,
  isABFEnabled: T.bool.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      data: state.dataTable.adminMedications.resources,
      isLoading: state.dataTable.adminMedications.isLoading,
      reqParams: state.dataTable.adminMedications.params,
      meta: state.dataTable.adminMedications.meta,
      isAdmin: state.auth.user.roles_map.admin,
      isABFEnabled: state.auth.user.current_company.abf_tracking,
    }), {
      fetchData: (query) => fetchData('/admin/treatment_products', 'adminMedications', query),
      setDataItem: (data) => setDataItem(data, 'adminMedications'),
      openModal,
    }
  ),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(Medications);
