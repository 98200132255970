import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import MentionsInput from 'components/MentionsInput';
import SpeechToText from 'components/SpeechToText';
// hooks
import useDidMountEffect from 'hooks/useDidMountEffect';
// utils
import cn from 'classnames';
// styles
import './CommentBox.scss';

const CommentBox = ({
  className = '',
  disabledIds,
  entityId,
  entityType,
  hasMentions,
  isOpen,
  label = <FormattedMessage id="general.notes.label" />,
  onOpen,
  onClose,
  onValueChange,
  onMentionsChange,
  placeholder,
  value,
  useSpeechToText = false,
}) => {
  const [isOpened, setIsOpened] = useState(isOpen);
  const [localValue, setLocalValue] = useState(value || '');
  const [valueInterim, setValueInterim] = useState('');

  useDidMountEffect(() => {
    if (isOpened !== isOpen) setIsOpened(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  const setIsOpenedCallback = (nextIsOpened) => {
    if (nextIsOpened) onOpen?.();
    else onClose?.();
    return nextIsOpened;
  };

  const handleToggleCommentBox = () => {
    setIsOpened((prevIsOpened) => setIsOpenedCallback(!prevIsOpened));
  };

  const handleValueChange = (newValueOrUpdater) => {
    setLocalValue((prevValue) => {
      const updatedValue =
        typeof newValueOrUpdater === 'function'
          ? newValueOrUpdater(prevValue)
          : newValueOrUpdater;
      onValueChange(updatedValue);
      return updatedValue;
    });
  };


  const handleClearComment = () => {
    setIsOpened(setIsOpenedCallback(false));
    handleValueChange('');
    onMentionsChange?.([]);
  };

  return (
    <div className={cn('CommentBoxComponent', { [className]: !!className })}>
      <div className="comment-box-header">
        <a onClick={handleToggleCommentBox} className="link">{label}</a>
        <a onClick={handleClearComment} className={cn('remove-comment', { 'visible': isOpened })}>
          <FormattedMessage id="general.button.remove" />
        </a>
      </div>
      <div className="CommentBoxComponent__wrapper">
        <div className={cn('comment', { 'opened': isOpened, 'closed': !isOpened })}>
          {hasMentions && (
            <>
              <MentionsInput
                inputType="square"
                comment={`${localValue || ''}${valueInterim || ''}`}
                disabledIds={disabledIds}
                entityId={entityId}
                entityType={entityType}
                onCommentChange={handleValueChange}
                onMentionsChange={onMentionsChange}
                placeholder={placeholder}
              />
            </>
          )}
          {!hasMentions && (
            <>
              <textarea
                className="CommentBox__textArea"
                placeholder={placeholder}
                onChange={(e) => handleValueChange(e.target.value)}
                value={`${localValue || ''}${valueInterim || ''}`}
              />
            </>
          )}
        </div>
        {useSpeechToText && (
          <div className={cn('control-buttons-block', { 'visible': isOpened })}>
            <SpeechToText
              setTextMain={handleValueChange}
              setTextInterim={setValueInterim}
              className="speech-to-text"
              buttonClassName="control-button main-control primary"
              iconClassName="speech-icon"
            />
          </div>
        )}
      </div>
    </div>
  );
};

CommentBox.propTypes = {
  isOpen: T.bool,
  hasMentions: T.bool,
  onValueChange: T.func.isRequired,
  onMentionsChange: T.func,
  onOpen: T.func,
  onClose: T.func,
  value: T.string,
  label: T.oneOfType([T.string, T.node]),
  className: T.string,
  placeholder: T.string,
  disabledIds: T.array,
  entityId: T.oneOfType([T.number, T.string]),
  entityType: T.string,
  useSpeechToText: T.bool,
};

export default CommentBox;
