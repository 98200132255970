import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import TreatmentProtocolsStepsPanel from 'components/TreatmentProtocols/TreatmentProtocolsStepsPanel';
// redux
import { connect } from 'react-redux';
import { setTableParams } from 'reducers/tableParams';
// hooks
import { tableParamsSelector } from 'hooks/useDataTable';
// endpoints
import { getPigGroupTreatmentProtocolProductSteps } from 'endpoints/treatmentProtocols';
// constants
import { tableNames } from 'utils/constants';

const BarnSheetsTreatmentProtocols = ({ isOnline, setTableParams, tableParams, pigGroup, params: { id } }) => {
  return (
    <TreatmentProtocolsStepsPanel
      isOnline={isOnline}
      fetchTreatmentProtocolsSteps={(params) => getPigGroupTreatmentProtocolProductSteps(id, params)}
      setTableParams={(params) => setTableParams(tableNames.barnSheetsGroupsTPProductSteps, params)}
      pigGroup={pigGroup}
      tableParams={tableParams}
      title={<FormattedMessage id="general.treatmentProtocols" />}
    />
  );
};

BarnSheetsTreatmentProtocols.propTypes = {
  isOnline: T.bool.isRequired,
  setTableParams: T.func.isRequired,
  tableParams: T.object.isRequired,
  pigGroup: T.object.isRequired,
  params: T.object.isRequired,
};


export default connect(
  (state) => ({
    isOnline: state.network.isOnline,
    tableParams: tableParamsSelector(state, tableNames.barnSheetsGroupsTPProductSteps),
  }), { setTableParams }
)(BarnSheetsTreatmentProtocols);
