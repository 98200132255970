import React, { useEffect, useRef } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { FieldArray, reduxForm, getFormValues, change } from 'redux-form';
import { setHoverTag } from 'reducers/dailyCheckup/checkupDiagnoses';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import DiagnosisFields from './DiagnosisFields';
// utils
import differenceWith from 'lodash.differencewith';
import isEqual from 'lodash.isequal';
import compact from 'lodash.compact';
import isEmpty from 'lodash.isempty';
import { isImageAsset } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import './DiagnoseForm.scss';

const DiagnoseForm = ({
  assets,
  change,
  closeModalFunction,
  currentIndex,
  diagnosesList,
  editMode,
  formValues,
  hasTags,
  handleSubmit,
  initialValues,
  onSubmit,
  pristine,
  setHoverTag,
  setIsDiagnoseFormPristine,
  submitting,
  pigGroupId,
}) => {
  const { current: currentRef } = useRef({ deleted: [], declinedTagIds: [] });
  const currentAsset = assets[currentIndex];
  const isAssetAnImage = currentAsset ? isImageAsset(currentAsset) : false;

  useEffect(() => {
    setIsDiagnoseFormPristine?.(pristine);
  }, [pristine]);

  const prepareData = (diagnoses) => {
    const data = compact(diagnoses.map(({ id, diagnose_type, diagnose, note = null, tags, notify_all, suggested }) => (
      diagnose && !suggested
        ? {
          notify_all,
          note,
          id: id || diagnose.dcDiagnosesItemId || null, // dcDiagnosesItemId - if reverted from deleted array
          diagnosis_id: diagnose.id,
          diagnosis_type: diagnose_type,
          image_tags_attributes: tags,
        }
        : null
    )));

    return data.concat(currentRef.deleted);
  };

  const submitForm = (data) => {
    const values = differenceWith(data.diagnoses, initialValues.diagnoses, isEqual);
    const resources = prepareData(values);

    return onSubmit(resources, currentRef.declinedTagIds)
      .then(() => {
        currentRef.deleted = [];
        currentRef.declinedTagIds = [];
        closeModalFunction();
      })
      .catch(toastResponseErrors);
  };

  const removeDiagnosis = (fields, index) => {
    const diagnosis_id = fields.get(index)?.diagnose?.id;
    if (diagnosis_id) {
      formValues.diagnoses.forEach((item) => {
        if (item.id && item.diagnose?.id === diagnosis_id) {
          currentRef.deleted.push({ id: item.id, diagnosis_id, _destroy: true });
        }
        return false;
      });
    }
    fields.remove(index);
    if (fields.length === 1) {
      fields.push({ diagnose_type: 'clinical', note: null, tags: [] });
    }
  };

  const declinePredictedTag = (id) => {
    currentRef.declinedTagIds.push(id);
  };

  const addTag = (fields, currentDiagnosis) => {
    fields.push({
      asset_name: currentAsset.filename,
      daily_checkup_asset_id: currentAsset.id,
      diagnosis: currentDiagnosis ? { id: currentDiagnosis.value, name: currentDiagnosis.label } : {},
      hash: Date.now(),
      left: 45,
      top: 45,
      width: 10,
      height: 10,
    });
  };

  const saveDiagnosisData = (diagnosisId) => {
    let existedDiagnosisIndex = null;
    let existedDiagnosis = null;

    formValues?.diagnoses?.forEach((item, index) => {
      if (item.diagnose && item.diagnose.id === diagnosisId) {
        existedDiagnosis = item;
        existedDiagnosisIndex = index;
      }
    });

    return { existedDiagnosisIndex, existedDiagnosis };
  };

  const changeDiagnosis = (input, fields, currIndex) => (option) => {
    if (!option) return;

    let newValueObject = { ...option };
    currentRef.deleted.forEach((item, index) => {
      if (item.diagnosis_id === option.id) {
        newValueObject = { ...newValueObject, disabled: false, dcDiagnosesItemId: item.id };
        currentRef.deleted.splice(index, 1);
      }
    });
    if (newValueObject.suggested) {
      const { existedDiagnosis, existedDiagnosisIndex } = saveDiagnosisData(option.id);

      if (Number(existedDiagnosisIndex)) {
        const currentDiagnosis = fields.get(currIndex);
        change(`diagnoses[${currIndex}]`, {
          ...existedDiagnosis,
          tags: [...existedDiagnosis.tags, ...currentDiagnosis.tags]
        });
        fields.remove(existedDiagnosisIndex);
        return;
      }
    }
    input.onChange(newValueObject);
  };

  const saveSuggestedDiagnosis = (e, fields, currIndex, diagnosis_id) => {
    e.preventDefault();
    const currentDiagnosis = fields.get(currIndex);
    const diagnosisId = diagnosis_id || currentDiagnosis.suggested_diagnosis_ids[0];

    const { existedDiagnosis, existedDiagnosisIndex } = saveDiagnosisData(diagnosisId);

    if (Number(existedDiagnosisIndex)) {
      change(`diagnoses[${currIndex}]`, {
        ...existedDiagnosis,
        tags: [...existedDiagnosis.tags, ...currentDiagnosis.tags]
      });
      fields.remove(existedDiagnosisIndex);
      return;
    }

    const diagnosis = diagnosesList.find(({ id }) => id === diagnosisId);
    change(`diagnoses[${currIndex}]`, {
      ...currentDiagnosis,
      diagnose: {
        ...diagnosis,
        label: diagnosis.common_name || diagnosis.name,
      },
      suggested: false,
      tags: [{ ...currentDiagnosis.tags[0], diagnosis }]
    });
  };

  const editSuggestedDiagnosis = (e, currentDiagnosis, index) => {
    e.preventDefault();
    change(`diagnoses[${index}]`, { ...currentDiagnosis, suggested: false });
  };

  const checkRequiredFieldsFilled = (values = {}) => {
    const diagnoses = values?.diagnoses || [];
    return (diagnoses.length === 1) || (diagnoses
      .filter((item) => !item.suggested)
      .every((diagnose) => (!!diagnose?.diagnose?.id)));
  };

  const hasEmptyDiagnosisWithTag = !!formValues?.diagnoses?.some(
    (item) => (isEmpty(item.diagnose) && !item.suggested && !isEmpty(item.tags))
  );
  return (
    <form onSubmit={handleSubmit(submitForm)} className="diagnose-form">
      <div className="form-content">
        <FieldArray
          name="diagnoses"
          component={DiagnosisFields}
          maxFieldsCount={10}
          changeDiagnosis={changeDiagnosis}
          removeDiagnosis={removeDiagnosis}
          saveSuggestedDiagnosis={saveSuggestedDiagnosis}
          editSuggestedDiagnosis={editSuggestedDiagnosis}
          declinePredictedTag={declinePredictedTag}
          hasTags={hasTags}
          setHoverTag={setHoverTag}
          addTag={addTag}
          hasEmptyDiagnosisWithTag={hasEmptyDiagnosisWithTag}
          editMode={editMode}
          diagnosesList={diagnosesList}
          isAssetAnImage={isAssetAnImage}
          pigGroupId={pigGroupId}
        />
      </div>
      <div className="button-block">
        <Button onClick={closeModalFunction} type="button" className="button">
          <FormattedMessage id="general.button.cancel" />
        </Button>
        <Button
          primary
          type="submit"
          disabled={pristine || submitting || !checkRequiredFieldsFilled(formValues)}
        >
          <FormattedMessage id="general.button.saveDiagnoses" />
        </Button>
      </div>
    </form>
  );
};

DiagnoseForm.propTypes = {
  handleSubmit: T.func.isRequired,
  onSubmit: T.func.isRequired,
  diagnosesList: T.array.isRequired,
  editMode: T.bool,
  pristine: T.bool,
  submitting: T.bool,
  formValues: T.object,
  closeModalFunction: T.func,
  setHoverTag: T.func,
  change: T.func.isRequired,
  setIsDiagnoseFormPristine: T.func,
  initialValues: T.object,
  hasTags: T.bool,
  assets: T.array,
  currentIndex: T.number,
  pigGroupId: T.number,
};

const formComponent = reduxForm({
  form: 'diagnose-form',
  enableReinitialize: true,
})(DiagnoseForm);

export default connect(
  (state) => ({
    formValues: getFormValues('diagnose-form')(state),
    isOpen: state.modals.isOpen,
    assets: state.mediaViewer.assets,
    currentIndex: state.mediaViewer.currentIndex,
  }), { setHoverTag, change }
)(formComponent);
