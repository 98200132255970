import React, { useMemo, useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import {
  fetchPigGroup,
  updatePigGroup,
  reopenPigGroup,
  closePigGroup,
  reportPigGroupMarketing,
  forceRequestClosePigGroup,
} from 'reducers/pigGroups/pigGroupShow';
import { resetCheckup } from 'reducers/dailyCheckup/checkupEdit';
import { openModal, closeModal } from 'reducers/modals';
// components
import { FormattedMessage } from 'react-intl';
import GroupProfileHeader from 'components/ProfileHeader/GroupProfileHeader/GroupProfileHeader';
import ProfileHeaderStats from 'components/ProfileHeader/ProfileHeaderStats/ProfileHeaderStats';
import SubNavigation from 'components/Subnavigation';
import CustomGroupNameModal from '../components/CustomGroupNameModal';
import FormattedWeight from 'components/FormattedWeight';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
// utils
import moment from 'moment';
import kebabCase from 'lodash.kebabcase';
import classnames from 'classnames/bind';
import { isPigGroupDeletable } from 'utils/pigGroupsHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import download from 'downloadjs';
import { formatMortality, formatGroupDeliveryDate, showToastrMessage } from 'utils';
// groups API
import { deleteAdminPigGroup } from 'endpoints/admin/pigGroups';
import { downloadPigGroupCSV } from 'endpoints/pigGroups';
// styles
import styles from './GroupProfileContainer.module.scss';

const cn = classnames.bind(styles);

const GroupProfileContainer = ({
  children,
  closeModal,
  closePigGroup,
  forceRequestClosePigGroup,
  fetchPigGroup,
  isLoading,
  isLogisticsEnabled,
  openModal,
  params: { id: groupId },
  pigGroup,
  reopenPigGroup,
  reportPigGroupMarketing,
  resetCheckup,
  router,
  updatePigGroup,
}) => {
  const { pigs, status, estimated_weight, mortality_rate, delivery_start, delivery_end,
    marketing_reported_at } = pigGroup;
  const canDeleteGroup = isPigGroupDeletable(pigGroup);
  const showDeliveryDateRange = (['awaiting_delivery', 'scheduled_delivery'].includes(status)) &&
    !pigs && delivery_start && delivery_end;

  useEffect(() => {
    fetchPigGroup(groupId);
    // if checkup for group already created, shipment/transfer update this checkup in background mode (back-end)
    resetCheckup();
  }, []);

  const navLinks = useMemo(() => {
    const links = [
      { link: `/groups/${groupId}/groupfeed`, label: <FormattedMessage id="general.groupFeed" /> },
      { link: `/groups/${groupId}/shipments`, label: <FormattedMessage id="general.shipments" /> },
      { link: `/groups/${groupId}/transfers`, label: <FormattedMessage id="general.salesTransfers" /> },
      { link: `/groups/${groupId}/edit`, label: <FormattedMessage id="general.groupInformation" /> },
      { link: `/groups/${groupId}/flow`, label: <FormattedMessage id="general.groupFlow" /> },
    ];
    if (isLogisticsEnabled) {
      links.splice(links.length - 1, 0, {
        link: `/groups/${groupId}/link-loads`,
        label: <FormattedMessage id="general.linkLoads" />,
      });
      links.splice(links.length - 1, 0, {
        link: `/groups/${groupId}/scheduled-loads`,
        label: <FormattedMessage id="general.scheduledLoads" />,
      });
    }
    return links;
  }, [groupId, isLogisticsEnabled]);

  const handleOpenCustomNameModal = () => {
    const onSubmit = (data) => {
      return updatePigGroup(data, groupId)
        .then(() => {
          showToastrMessage('component.toastr.groupName.wasUpdated');
        })
        .catch(toastResponseErrors);
    };
    openModal(
      <CustomGroupNameModal
        initialName={pigGroup.name}
        onClose={closeModal}
        onSubmit={onSubmit}
      />
    );
  };

  const handleReopenPigGroup = () => {
    return reopenPigGroup(groupId)
      .then(() => {
        showToastrMessage('component.toastr.group.wasReopened');
      })
      .catch(toastResponseErrors);
  };

  const handleClosePigGroup = () => {
    return closePigGroup(groupId)
      .then(() => {
        showToastrMessage('component.toastr.group.wasClosed');
      })
      .catch(toastResponseErrors);
  };


  const handleForceClosePigGroup = () => {
    const handleConfirm = () => {
      forceRequestClosePigGroup(groupId)
        .then(() => {
          showToastrMessage('component.toastr.group.wasForceCloseRequested');
        })
        .catch(toastResponseErrors);
    };

    openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.forceCloseGroup" />}
        actionBtnLabel={<FormattedMessage id="component.modal.forceCloseGroup.confirm" />}
        actionBtnProps={{ blue: true }}
        warningMessage={<FormattedMessage id="component.modal.forceCloseGroup.text" />}
        handleConfirm={handleConfirm}
      />
    );
  };

  const handleDownloadPigGroupCSV = () => {
    const filename = `pig-group-${kebabCase(pigGroup.name)}-${moment().format('MM-DD-YYYY')}.csv`;
    return downloadPigGroupCSV(groupId)
      .then((blob) => download(blob, filename, 'text/csv'))
      .catch(toastResponseErrors);
  };

  const handleDeleteGroup = () => {
    return deleteAdminPigGroup(groupId)
      .then(() => {
        showToastrMessage('component.toastr.group.wasDeleted');
        router.push('/groups');
      })
      .catch(toastResponseErrors);
  };

  const handleMarkAsMarketing = () => {
    return reportPigGroupMarketing(groupId)
      .then(() => {
        showToastrMessage('component.toastr.group.markAsMarketing');
      })
      .catch(toastResponseErrors);
  };

  const handleHideGroup = () => {
    return updatePigGroup({ hidden: true }, groupId)
      .then(() => {
        showToastrMessage('component.toastr.group.hidden');
      })
      .catch(toastResponseErrors);
  };


  const handleUnhideGroup = () => {
    return updatePigGroup({ hidden: false }, groupId)
      .then(() => {
        showToastrMessage('component.toastr.group.unhidden');
      })
      .catch(toastResponseErrors);
  };

  const groupStats = [
    { label: <FormattedMessage id="general.puc" />, value: pigs || 0 },
    { label: <FormattedMessage id="general.estAvgWeight" />,
      value: <FormattedWeight value={estimated_weight} unitFontSize="13px" /> },
    { label: <FormattedMessage id="general.mortalityRate" />, value: formatMortality(mortality_rate) },
  ];

  const dropdownOptions = [
    {
      label: <FormattedMessage id="general.downloadGroupData" />,
      onClick: handleDownloadPigGroupCSV
    },
    {
      label: <FormattedMessage id="general.markGroupAsMarketing" />,
      onClick: handleMarkAsMarketing,
      hide: marketing_reported_at,
    },
    {
      label: <FormattedMessage id="general.editGroupID" />,
      onClick: handleOpenCustomNameModal,
    },
    {
      label: <FormattedMessage id="general.button.closeGroup" />,
      onClick: handleClosePigGroup,
      hide: !!pigs || status !== 'opened',
    },
    {
      label: <FormattedMessage id="general.forceCloseGroup" />,
      onClick: handleForceClosePigGroup,
      hide: !pigs || status !== 'opened',
    },
    {
      label: <FormattedMessage id="general.reopenGroup" />,
      onClick: handleReopenPigGroup,
      hide: status !== 'closed',
    },
    {
      label: <FormattedMessage id="general.button.hideGroup" />,
      onClick: handleHideGroup,
      hide: status !== 'closed' || pigGroup.hidden,
    },
    {
      label: <FormattedMessage id="general.button.unhideGroup" />,
      onClick: handleUnhideGroup,
      hide: !pigGroup.hidden,
    },
    {
      label: <FormattedMessage id="general.deleteGroup" />,
      onClick: handleDeleteGroup,
      hide: !!pigs || !canDeleteGroup,
    },
  ];

  return (
    <div>
      {showDeliveryDateRange && (
        <div className={cn('delivery-date')}>
          <FormattedMessage
            id="general.estimateDeliveryDate"
            values={{ date: formatGroupDeliveryDate(delivery_start, delivery_end, { month: 'MMMM' }) }}
          />
        </div>
      )}
      <GroupProfileHeader
        group={pigGroup}
        onEditName={handleOpenCustomNameModal}
        isLoading={isLoading}
        dropdownOptions={dropdownOptions}
      >
        <ProfileHeaderStats stats={groupStats} />
      </GroupProfileHeader>
      <SubNavigation links={navLinks} className="small-12 column" />
      {children}
    </div>
  );
};

GroupProfileContainer.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
  params: T.object.isRequired,
  pigGroup: T.object,
  isLoading: T.bool.isRequired,
  isLogisticsEnabled: T.bool.isRequired,
  router: T.object.isRequired,
  fetchPigGroup: T.func.isRequired,
  updatePigGroup: T.func.isRequired,
  reopenPigGroup: T.func.isRequired,
  closePigGroup: T.func.isRequired,
  reportPigGroupMarketing: T.func.isRequired,
  resetCheckup: T.func.isRequired,
  closeModal: T.func.isRequired,
  openModal: T.func.isRequired,
  forceRequestClosePigGroup: T.func.isRequired,
};

export default connect(
  (state, { params }) => ({
    pigGroup: +params.id === state.pigGroups.groupShow.data.id ? state.pigGroups.groupShow.data : {},
    isLoading: state.pigGroups.groupShow.isLoading,
    isLogisticsEnabled: state.auth.user.current_company.logistics,
  }), {
    fetchPigGroup,
    updatePigGroup,
    reopenPigGroup,
    closePigGroup,
    forceRequestClosePigGroup,
    resetCheckup,
    reportPigGroupMarketing,
    closeModal,
    openModal,
  }
)(GroupProfileContainer);
