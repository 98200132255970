import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
// utils
export const frequencyPeriodUnits = [
  { label: <FormattedMessage id="general.day" />, value: 'day' },
  { label: <FormattedMessage id="general.week" />, value: 'week' }
];
export const frequencyTypes = [
  { label: <FormattedMessage id="general.never" />, value: 'never' },
  { label: <FormattedMessage id="general.byFarm" />, value: 'by_farm' },
  { label: <FormattedMessage id="general.byGroup" />, value: 'by_group' },
];
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
