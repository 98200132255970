import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// styles
import styles from './TrashButton.module.scss';

const TrashButton = ({ onClick, className }) => (
  <div onClick={onClick} className={cn(styles['trash-button'], className)}>
    <i className={`fa fa-trash-o ${styles.icon}`} />
  </div>
);

TrashButton.propTypes = {
  onClick: T.func.isRequired,
  className: T.string,
};

export default TrashButton;
