import React, { Fragment } from 'react';
// components
import Field from 'components/Field';
import Input from 'components/Input';
import ReactSelect from 'components/Select/ReactSelect';
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
import getValue from 'lodash.get';
import { frequencyPeriodUnits } from './helper';
// styles
import styles from './ReportFrequency.module.scss';

const cn = classnames.bind(styles);

const ValueInput = (props) => (
  <Input
    {...props}
    {...props.input}
    placeholder="0"
    className={cn('days-input', 'mr-10')}
    hasNoMargin
    type="number"
    min={1}
    max={999}
    onChange={({ target: { value, validity } }) => validity.valid && props.input.onChange(value ? +value : '')
    }
  />
);

const UnitSelectField = (props) => (
  <Field
    {...props}
    component={({ input }) => (
      <ReactSelect
        className={cn('unit-select')}
        noInputMargin
        options={frequencyPeriodUnits}
        labelKey="label"
        valueKey="value"
        arrowRenderer={() => <i className="fa fa-angle-down" />}
        value={input.value || ''}
        onBlur={() => input.onBlur(getValue(input, 'value'))}
        onChange={(option) => input.onChange(getValue(option, 'value'))}
        clearable={false}
        {...props}
      />
    )}
  />
);

const ReportFrequency = () =>  (
  <div className={cn('radio-item')}>
    <div className={cn('radio-label', 'semibold')}>
      <Field min={1} component={ValueInput} name="frequency" />
      <UnitSelectField name="frequency_unit" />
    </div>
  </div>
);

export const GroupFrequencyForm = () => (
  <Fragment>
    <div className={cn('mt-15')}>
      <div className="medium-4">
        <div className={cn('frequency-field')}>
          <div className={cn('label-text')}>
            <FormattedMessage id="general.on" />
          </div>
          <Field name="frequency" component={ReportFrequency} />
          <div className={cn('ml-5')}>
            <FormattedMessage id="general.ofGroupOnFeed" />
          </div>
        </div>
      </div>
      <div className="medium-6">
        <div className={cn('info-row', 'mt-25')}>
          <i className={cn('fa fa-info-circle', 'icon')} />
          <FormattedMessage id="general.everypigWillUseForGroup" />
        </div>
      </div>
    </div>
  </Fragment>
);

export const FarmFrequencyForm = () => (
  <Fragment>
    <div className={cn('mt-15')}>
      <div className="medium-4">
        <div className={cn('frequency-field')}>
          <div className={cn('label-text')}>
            <FormattedMessage id="general.every" />
          </div>
          <Field name="frequency" component={ReportFrequency} />
        </div>
      </div>
      <div className="medium-6">
        <div className={cn('info-row', 'mt-25')}>
          <i className={cn('fa fa-info-circle', 'icon')} />
          <FormattedMessage id="general.everypigWillUseForFarm" />
        </div>
      </div>
    </div>
  </Fragment>
);

export const DisabledFrequencyForm = () => (
  <Fragment>
    <div className="medium-6">
      <div className={cn('info-row', 'mt-15')}>
        <i className={cn('fa fa-info-circle', 'icon')} />
        <FormattedMessage id="general.everypigWillNotSendAlert" />
      </div>
    </div>
  </Fragment>
);
