import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import AsyncMultiSelect from 'components/AsyncMultiSelect';
import InfoTooltip from 'components/InfoTooltip';
// endpoints
import { fetchPigGroupDiagnosis, createPigGroupDiagnosis, deletePigGroupDiagnosis } from 'endpoints/pigGroupDiagnoses';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import classnames from 'classnames/bind';
// styles
import styles from './PigGroupDiagnosesManager.module.scss';

const cn = classnames.bind(styles);
const filterOption = (selectedEntities) => (option) => {
  const isAlreadySelected = !!selectedEntities.find((entity) => entity.pig_group.id === option.value);
  if (isAlreadySelected) return null;
  return option;
};

const PigGroupDiagnosesManager = ({ currentDiagnosis, pigGroupId }) => {
  const [pigGroupDiagnoses, setPigGroupDiagnoses] = useState([]);
  const [farmId, setFarmId] = useState(null);
  const isDiagnosisValid =
    !currentDiagnosis.suggested && currentDiagnosis.diagnose && currentDiagnosis.diagnose.short_name;

  useEffect(() => {
    if (isDiagnosisValid) {
      fetchPigGroupDiagnosis(currentDiagnosis.diagnose.id, { pig_group_id: pigGroupId })
        .then((response) => {
          setFarmId(response.meta.farm_id);
          setPigGroupDiagnoses(response.resources);
        });
    }
  }, [isDiagnosisValid]);

  const addPigGroupDiagnosis = (group) => {
    createPigGroupDiagnosis(currentDiagnosis.diagnose.id, { resource: { pig_group_id: group.value } })
      .then((newDiagnosis) => {
        setPigGroupDiagnoses((prevDiagnoses) => [...prevDiagnoses, newDiagnosis.resource]);
      })
      .catch(toastResponseErrors);
  };

  const removePigGroupDiagnosis = (pigGroupDiagnosis) => {
    deletePigGroupDiagnosis(currentDiagnosis.diagnose.id, pigGroupDiagnosis.id)
      .then(() => {
        setPigGroupDiagnoses((prevDiagnoses) => prevDiagnoses
          .filter((diagnosis) => diagnosis.id !== pigGroupDiagnosis.id));
      })
      .catch(toastResponseErrors);
  };

  const optionsPath = farmId ?
    `/pig_groups/search?status=opened&farm_id=${farmId}` :
    '/pig_groups/search?status=opened';

  if (!isDiagnosisValid) {
    return null;
  }

  return (
    <>
      <div className={cn('title')}>
        <FormattedMessage id="general.healthStatusManager.title" />
        <InfoTooltip
          className="mt-35 pl-0 small-1 medium-1 column"
          overlay={<FormattedMessage id="component.pigGroupDiagnosesManager.hint" />}
        />
      </div>
      <AsyncMultiSelect
        values={pigGroupDiagnoses}
        scrollable
        filterOption={filterOption}
        selectClassName={cn('mt-10')}
        onSelect={addPigGroupDiagnosis}
        onRemove={removePigGroupDiagnosis}
        optionsPath={optionsPath}
        optionsKeys={{ value: 'id', label: 'name' }}
        placeholderKey="general.searchBy.groups"
        extractLabel={(value) => value.pig_group.name}
      />
    </>
  );
};

PigGroupDiagnosesManager.propTypes = {
  pigGroupId: PropTypes.number,
  currentDiagnosis: PropTypes.shape({
    suggested: PropTypes.bool,
    diagnosis: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
};

export default PigGroupDiagnosesManager;
