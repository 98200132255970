import React, { useState } from 'react';
import T from 'prop-types';
// components
import ReportTemplateForm from './components/ReportTemplateForm';
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
// api
import { createReportTemplate } from 'endpoints/admin/reportTemplates';
// redux
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
// helper
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatReportTemplateCreatePayload, validateReportTemplate } from './helper';

const ReportTemplateCreate = ({ router, openModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const onCancel = () => router.goBack();

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const payload = formatReportTemplateCreatePayload(values);
      await createReportTemplate(payload);
      router.push('/admin/report-builder');
    } catch (err) {
      toastResponseErrors(err);
      setIsLoading(false);
    }
  };

  const showConfirmationModal = (values) => {
    openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.createReport" />}
        actionBtnLabel={<FormattedMessage id="general.confirm" />}
        actionBtnProps={{ primary: true }}
        handleConfirm={() => handleSubmit(values)}
      >
        <FormattedMessage
          id="component.modal.createReport"
          values={{ name: values.name, b: (msg) => <b>{msg}</b> }}
        />
      </ConfirmationModal>
    );
  };

  return (
    <>
      <Preloader isActive={isLoading} />
      <ReportTemplateForm
        submitButtonLabel={<FormattedMessage id="general.create" />}
        validate={validateReportTemplate}
        onSubmit={showConfirmationModal}
        onCancel={onCancel}
        title={<FormattedMessage id="general.createReport" />}
      />
    </>
  );
};

ReportTemplateCreate.propTypes = {
  router: T.object.isRequired,
  openModal: T.func.isRequired,
};

export default connect(null, { openModal })(ReportTemplateCreate);
