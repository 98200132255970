import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { reset } from 'redux-form';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel/index';
import ToggleSwitcher from 'components/ToggleSwitcher/index';
import FormErrorsBox from 'components/FormErrorsBox';
// utils
import { fetchFromAPI } from 'utils/api';
import handleFormErrors from 'utils/handleFormErrors';
import { showToastrMessage } from 'utils';
import DistynctForm from './components/DistynctForm';

const initialState = {
  formValues: {
    client_secret: '',
    client_id: '',
    export_mortalities: false,
    import_temperature: false,
    import_water_usage: false,
  },
  active: false,
  isLoading: false,
  connected: false,
};

class DistynctIntegration extends Component {

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    this.getSettings();
  }

  getSettings = () => {
    const { companyId } = this.props;
    this.setState({ isLoading: true });

    fetchFromAPI(`/admin/companies/${companyId}/distynct_credential`)
      .then(({ resource }) => {
        const {
          client_secret,
          client_id,
          active,
          export_mortalities,
          import_temperature,
          import_water_usage,
        } = resource;
        this.setState({
          active,
          isLoading: false,
          connected: client_secret,
          formValues: {
            export_mortalities,
            import_temperature,
            import_water_usage,
            client_secret,
            client_id,
          },
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false, connected: false });
        return handleFormErrors(error);
      });
  };

  handleSubmit = (resource) => {
    const { companyId } = this.props;
    const reqParams = { method: 'PUT', body: JSON.stringify({
      resource: { client_id: resource.client_id,
        client_secret: resource.client_secret,
        active: true } }) };
    this.setState({ isLoading: true });

    return fetchFromAPI(`/admin/companies/${companyId}/distynct_credential`, reqParams)
      .then(({ resource }) => {
        showToastrMessage('component.toastr.credentials.updated');
        const {
          active,
          client_secret,
          client_id,
          import_temperature,
          import_water_usage,
        } = resource;
        this.setState({
          active,
          connected: !!client_secret,
          isLoading: false,
          formValues: {
            client_secret,
            client_id,
            import_temperature,
            import_water_usage,
          },
        });
      },
      (error) => {
        this.setState({ isLoading: false, connected: false });
        return handleFormErrors(error);
      });
  };

  toggleActive = (resource) => {
    const { companyId } = this.props;
    const reqParams = { method: 'PUT', body: JSON.stringify({ resource }) };
    this.setState({ isLoading: true });
    fetchFromAPI(`/admin/companies/${companyId}/distynct_credential`, reqParams)
      .then(({ resource: { active } }) => {
        this.setState({ active, isLoading: false });
      },
      (error) => {
        this.setState({ isLoading: false });
        return handleFormErrors(error);
      });
  };

  resetForm = () => {
    const { companyId, resetForm } = this.props;
    const resource = { client_secret: '', client_id: '' };
    const reqParams = { method: 'delete', body: JSON.stringify({ resource }) };
    this.setState({ isLoading: true });

    return fetchFromAPI(`/admin/companies/${companyId}/distynct_credential`, reqParams)
      .then(() => {
        resetForm('distynct-form');
        showToastrMessage('component.toastr.credentials.reset');
        this.setState(initialState);
      },
      (error) => {
        this.setState({ isLoading: false });
        return handleFormErrors(error);
      });
  };

  switchToggle = (name, value) => {
    const { companyId } = this.props;
    const { formValues } = this.state;
    const resource = { [name]: value };
    const reqParams = { method: 'PUT', body: JSON.stringify({ resource }) };
    this.setState({ isLoading: true });
    return fetchFromAPI(`/admin/companies/${companyId}/distynct_credential`, reqParams)
      .then(({ resource }) => {
        showToastrMessage('component.toastr.settings.updated');
        this.setState({
          isLoading: false,
          formValues: {
            ...formValues,
            [name]: resource[name],
          },
        });
      },
      (error) => {

        this.setState({ isLoading: false });
        return handleFormErrors(error);
      });
  }

  render() {
    const { isLoading, formValues, active, connected } = this.state;
    const isDisabled = !formValues.client_id || !formValues.client_secret;
    return (
      <Panel>
        <Panel.Heading title="Distynct" />
        <Panel.Body className="ph-15">
          <Preloader isActive={isLoading} />

          <div className="row clearfix">
            <section className="small-12 medium-10 large-8 column">

              <ToggleSwitcher
                isDisabled={isDisabled}
                checked={active}
                text={<FormattedMessage id="component.distynct.title" />}
                description={<FormattedMessage id="component.distynct.desc" />}
                onChange={() => this.toggleActive({ active: !active })}
              />

              <FormErrorsBox formName="distynct-form" className="animated fadeIn" />

              <DistynctForm
                handleForm={this.handleSubmit}
                resetForm={this.resetForm}
                initialValues={formValues}
                active={active}
                connected={connected}
                switchToggle={this.switchToggle}
              />
            </section>
          </div>
        </Panel.Body>
      </Panel>
    );
  }
}

DistynctIntegration.propTypes = {
  companyId: T.number.isRequired,
  resetForm: T.func.isRequired,
};

export default connect(
  (state) => ({
    companyId: state.auth.user.current_company.id,
  }), {
    resetForm: reset,
  }
)(DistynctIntegration);
