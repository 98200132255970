import React, { Component, Fragment } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { change } from 'redux-form';
// components
import { FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player';
import ImageTagEdit from './ImageTag/ImageTagEdit';
import ImageTagView from './ImageTag/ImageTagView';
import PredictedImageTagEdit from './ImageTag/PredictedImageTagEdit';
import PredictedImageTagView from './ImageTag/PredictedImageTagView';
import Preloader from 'components/Preloader';
import MediaViewerNavButtons from './MediaViewerNavButtons';
import MediaViewerZoomPanel from './MediaViewerZoomPanel';
import SuggestionBadge from 'components/SuggestionBadge';
import DiagnosisBadge from '../DiagnosisBadge';
import PdfPreview from 'components/PdfPreview/PdfPreview';
import MediaQuery from 'react-responsive/src/Component';
// endpoints
import { getDCAssetImageTags } from 'endpoints/dailyCheckupAssets';
// utils
import cn from 'classnames';
import clamp from 'lodash.clamp';
import isEmpty from 'lodash.isempty';
import { isMobile, isTablet } from 'react-device-detect';
import { isVideoAsset, isPDFAsset } from 'utils';
import { minWidth, getMinHeight } from 'utils/imageTagHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { createSelector } from 'reselect';
import update from 'react-addons-update';
// constants
import {
  DIAGNOSE_PANEL_WIDTH,
  MAX_PDF_SCALE,
  MIN_PDF_SCALE
} from 'constants.js';
// styles
import './MediaViewerViewBox.scss';

class MediaViewerViewBox extends Component {
  constructor() {
    super();
    this.state = {
      active: '',
      // current image size ({ w: number , h: number })
      currentSize: {},
      tags: [],
      predictedTags: [],
      isTagsLoading: false,
      measure: 1
    };

    // for swiping on mobile
    this.touch = {
      // startX, startY - coordinates of the touch point relative to the screen, not including any scroll offset.
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0
    };

    // for pinching
    this.distance = null;

    // for image dragging
    this.isDragging = false;
    this.isDraggable = false;
    // mouse coords relative to the entire page when event triggered
    this.mouseX = 0;
    this.mouseY = 0;
    // touch  coords relative to the entire page when event triggered
    this.touchX = 0;
    this.touchY = 0;
    // image oversize by height and width
    this.oversizeX = 0;
    this.oversizeY = 0;
    this.panPositionTop = 50;
    this.panPositionLeft = 50;
    this.panPositionX = 0;
    this.panPositionY = 0;
  }

  componentDidMount() {
    const { currentIndex } = this.props;
    this.fetchTags(currentIndex);

    if (this.tagZone) {
      this.tagZone.addEventListener('mousedown', this.onMouseDown);
      this.tagZone.addEventListener('mouseup', this.onMouseUp);
      this.tagZone.addEventListener('mousemove', this.onMouseMove);
      this.tagZone.addEventListener('dblclick', this.onTagZoneClick);
      this.tagZone.addEventListener('dragstart', () => {
        return false;
      });
    }
    if (isMobile) {
      this.viewBox.addEventListener('touchstart', this.onTouchStart, false);
      this.viewBox.addEventListener('touchmove', this.onTouchMove, false);
      this.viewBox.addEventListener('touchend', this.onTouchEnd, false);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentIndex, isDiagnosePanelOpen } = this.props;
    if (nextProps.currentIndex !== currentIndex) {
      this.setState({ currentSize: {} });
      this.resetPanImage();
      this.fetchTags(nextProps.currentIndex);
    }

    if (!nextProps.isDiagnosePanelOpen && isDiagnosePanelOpen) {
      this.fetchTags(nextProps.currentIndex);
    }

    if (isDiagnosePanelOpen !== nextProps.isDiagnosePanelOpen && this.isDraggable) {
      if (this.oversizeX > 0) {
        const backgroundPositionX = parseFloat(
          this.bgImage.style.backgroundPositionX
        );

        if (nextProps.isDiagnosePanelOpen) {
          this.tagZone.style.left = `${parseFloat(this.tagZone.style.left) -
            (DIAGNOSE_PANEL_WIDTH * backgroundPositionX) / 100}px`;
        } else {
          this.tagZone.style.left = `${parseFloat(this.tagZone.style.left) +
            (DIAGNOSE_PANEL_WIDTH * backgroundPositionX) / 100}px`;
        }
      }
    }
  }

  shouldComponentUpdate() {
    // avoid react render while dragging image
    // because we manage image styles position manually
    return !this.isDragging;
  }

  componentWillUnmount() {
    if (this.tagZone) {
      this.tagZone.removeEventListener('mousedown', this.onMouseDown);
      this.tagZone.removeEventListener('mouseup', this.onMouseUp);
      this.tagZone.removeEventListener('mousemove', this.onMouseMove);
      this.tagZone.removeEventListener('dblclick', this.onTagZoneClick);
      this.tagZone.removeEventListener('dragstart', () => {
        return false;
      });
    }
    if (isMobile) {
      this.viewBox.removeEventListener('touchstart', this.onTouchStart);
      this.viewBox.removeEventListener('touchmove', this.onTouchMove);
      this.viewBox.removeEventListener('touchend', this.onTouchEnd);
    }
  }

  fetchTags = (index) => {
    const { assets, onSetPredictedTags } = this.props;
    const currentAsset = assets[index];
    const {
      image_tags_count = 0,
      prediction_image_tags_count = 0
    } = currentAsset;

    if (!(image_tags_count + prediction_image_tags_count)) {
      onSetPredictedTags([]);
      return this.setState({ tags: [], predictedTags: [] });
    }

    this.setState({ isTagsLoading: true });
    return getDCAssetImageTags(currentAsset.id)
      .then((response) => {
        const tags = response.resources.map((tag) => ({
          id: tag.id,
          left: tag.left,
          top: tag.top,
          width: tag.width,
          height: tag.height,
          ai_suggestion: tag.ai_suggestion,
          diagnosis: tag.daily_checkup_diagnosis.diagnosis,
          created_by:
            tag.daily_checkup_diagnosis.modified_by ||
            tag.daily_checkup_diagnosis.created_by,
          dc_diagnosis_id: tag.daily_checkup_diagnosis.id,
          created_at: tag.created_at,
          asset_name: currentAsset.name || currentAsset.filename,
          daily_checkup_asset_id: currentAsset.id,
          hash: Date.now()
        }));

        const predictedTags = (response.meta.prediction_image_tags || []).map(
          (tag) => ({
            ...tag,
            ai_suggestion_percent: tag.score,
            asset_name: currentAsset.name || currentAsset.filename,
            daily_checkup_asset_id: currentAsset.id,
            hash: Date.now(),
            isSuggestionTag: true
          })
        );

        onSetPredictedTags(predictedTags);

        this.setState({ isTagsLoading: false, tags, predictedTags });
      })
      .catch((errors) => {
        this.setState({ isTagsLoading: false });
        toastResponseErrors(errors);
      });
  };

  getDiagnosisInForm = (formDiagnoses, currentTag) => {
    let diagnosisInForm = null;
    formDiagnoses.forEach((item) => {
      item.tags?.forEach((tag) => {
        if (
          (tag.id && tag.id === currentTag.id) ||
          tag.hash === currentTag.hash
        ) {
          diagnosisInForm = item;
        }
      });
    });

    return diagnosisInForm;
  };

  updateTagPosition = (data, index, existingTag) => {
    const {
      diagnoseFormTags,
      isDiagnosePanelOpen,
      formDiagnoses,
      change
    } = this.props;
    const currentTag = existingTag || diagnoseFormTags[index] || {};
    if (!isDiagnosePanelOpen) return;

    const diagnosisInForm =
      formDiagnoses.find(
        (item) => (currentTag?.diagnosis?.id || -1) === item?.diagnose?.id
      ) || this.getDiagnosisInForm(formDiagnoses, currentTag);
    const diagnosisInFormIndex = formDiagnoses.indexOf(diagnosisInForm);
    const tagInForm = diagnosisInForm.tags?.find((tag) => {
      return tag.id ? currentTag.id === tag.id : currentTag.hash === tag.hash;
    });
    if (!tagInForm) return;
    const tagIndex = diagnosisInForm.tags.indexOf(tagInForm);
    const tags = update(diagnosisInForm.tags, { [tagIndex]: { $merge: data } });

    change('diagnose-form', `diagnoses[${diagnosisInFormIndex}].tags`, tags);
  };

  resetPanImage = () => {
    this.isDragging = false;
    this.isDraggable = false;
    this.oversizeX = 0;
    this.oversizeY = 0;
    this.panPositionTop = 50;
    this.panPositionLeft = 50;
    this.panPositionX = 0;
    this.panPositionY = 0;
    if (this.tagZone) {
      this.tagZone.style.left = 'auto';
      this.tagZone.style.top = 'auto';
    }
  };

  //
  // HANDLE SWIPE ON MOBILE
  //

  onTouchStart = (e) => {
    // screenX, screenY - coordinates of the touch point relative to the screen, not including any scroll offset
    const { screenX, screenY } = e.changedTouches[0];
    this.touch.startX = screenX;
    this.touch.startY = screenY;

    // detecting single touch, which is used for image dragging on mobile
    this.singleTouch = e.touches.length === 1;

    // detecting double touch, which is used for pinch zoom
    if (e.touches.length === 2) {
      e.preventDefault();
    }

    // TODO: it runs when it doesnt need to (after 1 pinch it always runs)
    if (this.singleTouch && this.isDraggable) {
      e.preventDefault();
      this.isDragging = true;
      this.touchX = e.touches[0].clientX;
      this.touchY = e.touches[0].clientY;
      // convert pan position to pixels
      this.panPositionX = (this.oversizeX * this.panPositionLeft) / 100;
      this.panPositionY = (this.oversizeY * this.panPositionTop) / 100;
    }
  };

  onTouchMove = (e) => {
    const { currentIndex, assets } = this.props;

    const currentAsset = assets[currentIndex];
    if (isPDFAsset(currentAsset)) return;

    if (this.singleTouch && this.isDragging) {
      e.preventDefault();
      if (this.oversizeX > 0) {
        this.panPositionX = clamp(
          this.panPositionX - e.touches[0].clientX + this.touchX,
          0,
          Math.floor(this.oversizeX)
        );
      }

      if (this.oversizeY > 0) {
        this.panPositionY = clamp(
          this.panPositionY - e.touches[0].clientY + this.touchY,
          0,
          Math.floor(this.oversizeY)
        );
      }

      this.bgImage.style['background-position-x'] = `-${this.panPositionX}px`;
      this.bgImage.style['background-position-y'] = `-${this.panPositionY}px`;
      this.touchX = e.touches[0].clientX;
      this.touchY = e.touches[0].clientY;

      return;
    }

    // we need it because dragging can be set to true at first touch, and component wont update
    this.isDragging = false;

    // double touch, pinch zoom
    if (e.touches.length === 2) {
      e.preventDefault();

      // calc distance between touches
      const prevDistance = this.distance;
      const x = (e.touches[0].screenX - e.touches[1].screenX) ** 2;
      const y = (e.touches[0].screenY - e.touches[1].screenY) ** 2;
      this.distance = Math.sqrt(x + y);

      if (prevDistance) {
        const currentScale = this.distance / prevDistance;
        const scale = (this.state.scale || 1) * currentScale;
        if (scale < 0.5 || scale > 5) {
          this.distance = prevDistance;
          return;
        }

        // update oversize according to the latest scale, we need it for dragging
        this.updateOversize(currentScale);
        this.setState(({ currentSize }) => ({
          scale,
          currentSize: {
            w: currentSize.w * currentScale,
            h: currentSize.h * currentScale
          }
        }));
      }
    }
  };

  onTouchEnd = (e) => {
    const { screenX, screenY } = e.changedTouches[0];
    this.touch.endX = screenX;
    this.touch.endY = screenY;

    if (this.isDragging && this.singleTouch) {
      this.isDragging = false;
      this.panPositionLeft = clamp(
        (this.panPositionX / this.oversizeX) * 100,
        0,
        100
      );

      this.panPositionTop = clamp(
        (this.panPositionY / this.oversizeY) * 100,
        0,
        100
      );

      // force update image position
      this.bgImage.style['background-position-x'] = `${this.panPositionLeft}%`;
      this.bgImage.style['background-position-y'] = `${this.panPositionTop}%`;
      this.forceUpdate();
    }

    // for pinch zoom
    this.distance = null;

    if (this.singleTouch && !this.isDraggable) this.handleSwipe();
  };

  handleSwipe = () => {
    const { showPrev, showNext, currentIndex, assets } = this.props;
    if (this.touch.startX - this.touch.endX > 30 && currentIndex !== assets.length - 1) {
      showNext();
    }
    if (this.touch.endX - this.touch.startX > 30 && currentIndex !== 0) {
      showPrev();
    }
  };

  //
  // HANDLE IMAGE DRAG
  //

  onMouseDown = (e) => {
    const { isDiagnosePanelOpen } = this.props;

    if (e.target !== this.tagZone && isDiagnosePanelOpen) return;
    if (!this.isDraggable) return;
    this.isDragging = true;
    this.mouseX = e.clientX;
    this.mouseY = e.clientY;
    this.updateOversize();
    // convert pan position to pixels
    this.panPositionX = (this.oversizeX * this.panPositionLeft) / 100;
    this.panPositionY = (this.oversizeY * this.panPositionTop) / 100;
  };

  onMouseUp = (e) => {
    const { isDiagnosePanelOpen } = this.props;

    if (e.target !== this.tagZone && isDiagnosePanelOpen) return;
    if (!this.isDraggable) return;
    this.isDragging = false;
    // convert pan position to percent
    this.panPositionLeft = clamp(
      (this.panPositionX / this.oversizeX) * 100,
      0,
      100
    );
    this.panPositionTop = clamp(
      (this.panPositionY / this.oversizeY) * 100,
      0,
      100
    );
    // force update image position
    this.bgImage.style['background-position-x'] = `${this.panPositionLeft}%`;
    this.bgImage.style['background-position-y'] = `${this.panPositionTop}%`;
    this.forceUpdate();
  };

  onMouseMove = (e) => {
    if (!this.isDragging) return;
    if (this.oversizeX > 0) {
      this.panPositionX = clamp(
        this.panPositionX - e.clientX + this.mouseX,
        0,
        Math.floor(this.oversizeX)
      );
    }
    if (this.oversizeY > 0) {
      this.panPositionY = clamp(
        this.panPositionY - e.clientY + this.mouseY,
        0,
        Math.floor(this.oversizeY)
      );
    }
    this.bgImage.style['background-position-x'] = `-${this.panPositionX}px`;
    this.tagZone.style.left = `-${this.panPositionX}px`;
    this.bgImage.style['background-position-y'] = `-${this.panPositionY}px`;
    this.tagZone.style.top = `-${this.panPositionY}px`;
    this.mouseX = e.clientX;
    this.mouseY = e.clientY;
  };

  //
  // HANDLE SCALING
  //

  resetScaleTimeout = () => {
    clearTimeout(this.scaleTimeoutId);
    this.scaleTimeoutId = setTimeout(() => this.setState({ active: '' }), 1500);
  };

  onZoomInClick = () => {
    const { currentIndex, assets } = this.props;

    const currentAsset = assets[currentIndex];
    const isPDF = isPDFAsset(currentAsset);

    if (isPDF) {
      return this.setState(({ measure }) => {
        const scale = measure * 1.2;
        return { measure: scale > 2.4 ? MAX_PDF_SCALE : scale };
      });
    }
    this.resetScaleTimeout();
    this.updateOversize(1.2);
    return this.setState(({ currentSize, measure }) => ({
      active: 'zoomIn',
      currentSize: {
        w: currentSize.w * 1.2,
        h: currentSize.h * 1.2
      },
      measure: measure * 1.2
    }));
  };

  onZoomOutClick = () => {
    const { currentIndex, assets } = this.props;

    const currentAsset = assets[currentIndex];
    const isPDF = isPDFAsset(currentAsset);

    if (isPDF) {
      return this.setState(({ measure }) => {
        const scale = measure * 0.8;
        return { measure: scale < 0.6 ? MIN_PDF_SCALE : scale };
      });
    }
    this.resetScaleTimeout();
    this.updateOversize(0.8);
    return this.setState(({ currentSize, measure }) => ({
      active: 'zoomOut',
      currentSize: {
        w: currentSize.w * 0.8,
        h: currentSize.h * 0.8
      },
      measure: measure * 0.8
    }));
  };

  onFitToPageClick = () => {
    const { currentIndex, assets } = this.props;

    const currentAsset = assets[currentIndex];
    const isPDF = isPDFAsset(currentAsset);

    if (isPDF) {
      return this.setState({ measure: 1 });
    }
    this.resetScaleTimeout();
    const { width, height } = this.hiddenImage.getBoundingClientRect();
    this.setState({
      active: 'fitToPage',
      currentSize: {
        w: width,
        h: height
      },
      measure: 1
    });
    return this.resetPanImage();
  };

  updateOversize = (scaler = 1) => {
    const { currentSize } = this.state;
    const { width, height } = this.bgImage.getBoundingClientRect();
    this.oversizeX = currentSize.w * scaler - width;
    this.oversizeY = currentSize.h * scaler - height;
    this.isDraggable = this.oversizeX > 0 || this.oversizeY > 0;

    if (this.oversizeX < 0) {
      this.panPositionLeft = 50;
      this.tagZone.style.left = 'auto';
    } else {
      this.tagZone.style.left = `-${this.oversizeX *
        (this.panPositionLeft / 100)}px`;
    }
    if (this.oversizeY < 0) {
      this.panPositionTop = 50;
      this.tagZone.style.top = 'auto';
    } else {
      this.tagZone.style.top = `-${this.oversizeY *
        (this.panPositionTop / 100)}px`;
    }
  };

  onImageLoad = ({
    target: { naturalWidth, naturalHeight, width, height }
  }) => {
    this.imageDimensions = { naturalWidth, naturalHeight, width, height };
    this.setState({
      currentSize: {
        w: width - width / 100 * 10,
        h: height
      }
    });
  };

  checkEmptyTag = (formDiagnoses) => (
    formDiagnoses.some((item) => !item.suggested && isEmpty(item.diagnose) && !isEmpty(item.tags))
  );

  getDiagnoseWithoutTags = (formDiagnoses) => {
    let empty = null;
    formDiagnoses.forEach((item) => {
      if (isEmpty(item.diagnose) && !(item.tags && item.tags.length)) {
        empty = item;
      }
    });

    return empty;
  };

  onTagZoneClick = (e) => {
    const {
      isDiagnosePanelOpen,
      assets,
      currentIndex,
      formDiagnoses,
      change,
      checkupId
    } = this.props;
    const { currentSize } = this.state;
    const currentAsset = assets[currentIndex];
    const { width, height } = this.tagZone.getBoundingClientRect();

    if (e.target === this.tagZone && isDiagnosePanelOpen) {
      const left = (e.offsetX / width) * 100;
      const top = (e.offsetY / height) * 100;

      const newTag = {
        left,
        top,
        currentSize,
        width: minWidth,
        height: getMinHeight(currentSize.w, currentSize.h),
        asset_name: currentAsset.name || currentAsset.filename,
        daily_checkup_asset_id: currentAsset.id,
        hash: Date.now()
      };

      if (!this.checkEmptyTag(formDiagnoses)) {
        const emptyDiagnose = this.getDiagnoseWithoutTags(formDiagnoses);
        const newDiagnosis = {
          daily_checkup_id: checkupId,
          diagnose_type: 'clinical',
          note: null,
          tags: [newTag]
        };
        if (!isEmpty(emptyDiagnose)) {
          const diagnosisIndex = formDiagnoses.indexOf(emptyDiagnose);
          change('diagnose-form', `diagnoses[${diagnosisIndex}]`, newDiagnosis);
        } else {
          change('diagnose-form', 'diagnoses', [
            ...formDiagnoses,
            newDiagnosis
          ]);
        }
      } else {
        let existingTag = null;
        formDiagnoses.forEach((item) => {
          if (isEmpty(item.diagnose) && item.tags && item.tags.length) {
            item.tags.forEach((tag) => {
              if (!tag.id) existingTag = tag;
            });
          }
        });
        this.updateTagPosition({ left, top }, null, existingTag);
      }
    }
  };

  getImageStyle = (index, currentIndex) => {
    const { assets } = this.props;
    const { currentSize } = this.state;
    const targetUrl = assets[index]?.url?.large || assets[index].url.original || assets[index].url.origin;
    const backgroundSize =
      !isEmpty(currentSize) && index === currentIndex
        ? `${currentSize.w}px ${currentSize.h}px`
        : '';
    return {
      backgroundSize,
      backgroundImage: `url(${targetUrl})`,
      backgroundPositionX: `${this.panPositionLeft}%`,
      backgroundPositionY: `${this.panPositionTop}%`
    };
  };

  getOriginalScale = () => {
    const { currentSize } = this.state;
    return !isEmpty(currentSize)
      ? Math.floor((currentSize.w / this.imageDimensions.naturalWidth) * 100)
      : 100;
  };

  onDeletePredictedTag = ({ id }) => {
    const {
      onCheckupDiagnosesUpdate,
      onUpdateCounters,
      checkupId,
      assets,
      currentIndex
    } = this.props;
    onCheckupDiagnosesUpdate(checkupId, [], [id]).then(() => {
      this.setState((prevState) => {
        const predictedTags = prevState.predictedTags.filter(
          (tag) => tag.id !== id
        );
        onUpdateCounters(assets[currentIndex].id, {
          prediction_image_tags_count: predictedTags.length
        });
        return { predictedTags };
      });
    });
  };

  addTag = (tag, diagnosis) => {
    const { checkupId, change, formDiagnoses } = this.props;
    const newTag = { ...tag, diagnosis };

    // if diagnoses form is empty (1 empty item)
    if (formDiagnoses.length === 1 && !formDiagnoses[0].diagnose) {
      change('diagnose-form', 'diagnoses', [
        {
          ...formDiagnoses[0],
          diagnose: {
            name: diagnosis.name,
            id: diagnosis.id,
            label: diagnosis.common_name || diagnosis.name
          },
          diagnosis_id: diagnosis.id,
          tags: [newTag]
        }
      ]);
    }

    const diagnosisItem =
      formDiagnoses.find((item) => diagnosis.id === item?.diagnose?.id) ||
      this.getDiagnosisInForm(formDiagnoses, tag);

    if (!diagnosisItem || !diagnosisItem.diagnose) {
      const newDiagnosis = {
        diagnose: {
          name: diagnosis.name,
          id: diagnosis.id,
          label: diagnosis.common_name || diagnosis.name
        },
        daily_checkup_id: checkupId,
        diagnosis_id: diagnosis.id,
        diagnose_type: 'clinical',
        note: null,
        tags: [newTag]
      };
      if (!diagnosisItem) {
        change('diagnose-form', 'diagnoses', [...formDiagnoses, newDiagnosis]);
      } else {
        const diagnosisIndex = formDiagnoses.indexOf(diagnosisItem);
        change('diagnose-form', `diagnoses[${diagnosisIndex}]`, newDiagnosis);
      }
    } else {
      // if tag adding to existing diagnosis
      const diagnosisIndex = formDiagnoses.indexOf(diagnosisItem);
      const tags = [...diagnosisItem.tags] || [];
      let emptyDiagnosisIndex = null;
      formDiagnoses.forEach((item, index) => {
        item.tags?.forEach((tag) => {
          if (tag.hash === newTag.hash) emptyDiagnosisIndex = index;
        });
      });
      const diagnoses = [
        ...formDiagnoses.slice(0, emptyDiagnosisIndex),
        ...formDiagnoses.slice(emptyDiagnosisIndex + 1)
      ];
      change('diagnose-form', 'diagnoses', diagnoses);
      change('diagnose-form', `diagnoses[${diagnosisIndex}].tags`, [
        ...tags,
        newTag
      ]);
    }
  };

  deleteTag = (tag) => {
    const { diagnosis } = tag;
    const {
      change,
      formDiagnoses,
      isDiagnosePanelOpen,
      onTagUpdate
    } = this.props;

    if (!isDiagnosePanelOpen) {
      return onTagUpdate([
        {
          id: tag.dc_diagnosis_id,
          image_tags_attributes: [{ id: tag.id, _destroy: true }]
        }
      ]).then(() => {
        this.setState((prevState) => ({
          tags: prevState.tags.filter((stateTag) => (stateTag.id !== tag.id && stateTag.hash !== tag.hash)),
        }));
      });
    }

    const diagnosisItem = formDiagnoses.find(
      (item) => diagnosis.id === item?.diagnose?.id
    );

    const diagnosisIndex = formDiagnoses.indexOf(diagnosisItem);

    const tags = diagnosisItem.tags.map((item) => ({
      ...item,
      _destroy:
        item._destroy || (tag.id ? item.id === tag.id : item.hash === tag.hash)
    }));

    // not needed to send tags with _destroy true if record didn't exists on back-end
    const filteredTags = tags.filter((item) => !(!item.id && item._destroy));

    return change(
      'diagnose-form',
      `diagnoses[${diagnosisIndex}].tags`,
      filteredTags
    );
  };

  render() {
    const { assets, currentIndex, isDiagnosePanelOpen, onDiagnosesPanelOpen, diagnoseFormTags, showPrev, showNext,
      showBadge, isAdmin, isSidebarCollapsed, isFullScreenMode } = this.props;
    const { active, currentSize, tags, isTagsLoading, measure, predictedTags } = this.state;

    const currentAsset = assets[currentIndex];

    if (isEmpty(currentAsset)) {
      return <div className="MediaViewerViewBox" />;
    }

    const targetUrl = currentAsset.url?.large || currentAsset.url?.original || currentAsset.url?.origin;
    const isVideo = isVideoAsset(currentAsset);
    const originalScale = this.getOriginalScale();
    const hideNav = assets.length < 2 || (isMobile && !isTablet);
    const hideZoomPanel = isVideo || isMobile;
    const isPDF = isPDFAsset(currentAsset);

    return (
      <div
        className={cn('MediaViewerViewBox', {
          'is-mobile': isMobile,
          'is-collapsed': isSidebarCollapsed,
          'is-fullscreen': isFullScreenMode,
        })}
        ref={(ref) => { this.viewBox = ref; }}
      >
        <div className={cn('view-box__main', { 'is-video': isVideo })}>
          <div className="view-box-container">
            {isPDF && this.viewBox && (
              <PdfPreview
                deltaX={this.state.deltaX}
                scale={measure}
                asset={currentAsset}
                viewBoxWidth={this.viewBox.clientWidth}
                viewBoxHeight={this.viewBox.clientHeight}
              />
            )}
            {isVideo && (
              <ReactPlayer
                playing
                className="view-box-item"
                url={currentAsset.url.original || currentAsset.url.origin}
                controls
                width="100%"
                height="100%"
                playsinline
              />
            )}
            {!isPDF && (
              <div className={cn('view-box-item', { hidden: isVideo })}>
                <img
                  src={targetUrl}
                  alt=""
                  className="hidden-image"
                  onLoad={this.onImageLoad}
                  ref={(ref) => {
                    this.hiddenImage = ref;
                  }}
                />
                <div
                  ref={(ref) => {
                    this.tagZone = ref;
                  }}
                  className={cn('tags-container', {
                    moveable: this.isDraggable
                  })}
                  style={{
                    width: currentSize.w || 0,
                    height: currentSize.h || 0
                  }}
                >
                  <Preloader isActive={isTagsLoading} />
                  {/* CHANGE WIDTH HEIGHT */}
                  {isDiagnosePanelOpen &&
                    diagnoseFormTags.map((tag, index) => !tag._destroy &&
                      (tag.isSuggestionTag ? (
                        <PredictedImageTagEdit
                          key={tag.id || tag.prediction_image_tag_id}
                          onUpdatePosition={(data) => this.updateTagPosition(data, index)}
                          tag={tag}
                        />
                      ) : (
                        <ImageTagEdit
                          key={tag.id || tag.hash}
                          onAddTag={this.addTag}
                          onDeleteTag={this.deleteTag}
                          onUpdatePosition={(data) => this.updateTagPosition(data, index)}
                          tag={tag}
                        />
                      )))
                  }
                  {!isDiagnosePanelOpen && (
                    <Fragment>
                      {tags.map((tag) => (
                        <ImageTagView
                          key={tag.id}
                          tag={tag}
                          isAdmin={isAdmin}
                          onDeleteTag={this.deleteTag}
                          onEditTag={onDiagnosesPanelOpen}
                        />
                      ))}
                      {predictedTags.map((tag) => (
                        <PredictedImageTagView
                          key={tag.id}
                          tag={tag}
                          onAcceptTag={onDiagnosesPanelOpen}
                          onRejectTag={this.onDeletePredictedTag}
                        />
                      ))}
                    </Fragment>
                  )}
                </div>

                {assets.map((asset, index) => (
                  <div
                    key={index}
                    className={cn('bg-image', {
                      'current-image': index === currentIndex
                    })}
                    style={this.getImageStyle(index, currentIndex)}
                    ref={(ref) => {
                      if (currentIndex === index) this.bgImage = ref;
                    }}
                  />
                ))}
                <div className={cn('current-scale', { visible: active })}>
                  <span className="mr-2">{originalScale}</span>%
                </div>
              </div>
            )}
            {showBadge && !predictedTags.length && (
              <DiagnosisBadge
                diagnosed={!!currentAsset.image_tags_count}
                className="large-size"
              />
            )}
            {showBadge && !!predictedTags.length && (
              <SuggestionBadge className="diagnosis-suggestion-badge">
                <FormattedMessage id="general.imageDiagnosisSuggestion" />
              </SuggestionBadge>
            )}
          </div>
        </div>

        {!hideNav && (
          <MediaViewerNavButtons
            currentIndex={currentIndex}
            lastIndex={assets.length - 1}
            handleShowPrev={showPrev}
            handleShowNext={showNext}
          />
        )}

        <MediaQuery minDeviceWidth={813}>
          {!hideZoomPanel && (
            <MediaViewerZoomPanel
              activeZoom={active}
              onZoomInClick={this.onZoomInClick}
              onZoomOutClick={this.onZoomOutClick}
              onFitToPageClick={this.onFitToPageClick}
            />
          )}
        </MediaQuery>
      </div>
    );
  }
}

MediaViewerViewBox.defaultProps = {
  assets: []
};

MediaViewerViewBox.propTypes = {
  currentIndex: T.number,
  assets: T.array,
  showNext: T.func.isRequired,
  showPrev: T.func.isRequired,
  isDiagnosePanelOpen: T.bool,
  onDiagnosesPanelOpen: T.func,
  onUpdateCounters: T.func,
  onCheckupDiagnosesUpdate: T.func.isRequired,
  onTagUpdate: T.func,
  onSetPredictedTags: T.func.isRequired,
  checkupId: T.number,
  change: T.func,
  formDiagnoses: T.array,
  diagnoseFormTags: T.array,
  showBadge: T.bool,
  isAdmin: T.bool,
  isSidebarCollapsed: T.bool.isRequired,
  isFullScreenMode: T.bool.isRequired,
};

const diagnoseFormTagsSelector = createSelector(
  (state) => state.form['diagnose-form']?.values?.diagnoses || [],
  (state, props) => props.assets[props.currentIndex],
  (diagnosesData, currentAsset) => {
    let result = [];
    for (const item of diagnosesData) {
      const tags = (item.tags || [])
        .filter((tag) => tag.daily_checkup_asset_id === currentAsset.id)
        .map((tag) => ({
          ...tag,
          diagnosis: item.diagnose || null
        }));
      result = result.concat(tags || []);
    }

    return result;
  }
);

export default connect(
  (state, props) => ({
    formDiagnoses: state.form['diagnose-form']?.values?.diagnoses || [],
    diagnoseFormTags: diagnoseFormTagsSelector(state, props),
    isAdmin: state.auth.user.roles_map.admin,
  }), {
    change
  }
)(MediaViewerViewBox);
