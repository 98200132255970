import React, { useState } from 'react';
import T from 'prop-types';
// components
import LabelledInput from 'components/LabelledInput';
import LayoutMinimal from 'components/LayoutMinimal';
import Button from 'components/Button';
import { Link } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import Preloader from 'components/Preloader';
import MessageBox from 'components/MessageBox';
// utils
import { postAuthPassword } from 'endpoints/auth';
import { toastResponseErrors }  from 'utils/responseErrorsHelper';

// eslint-disable-next-line max-len
const regExpr = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const emailInputModel = {
  type: 'email',
  name: 'email',
};

const ResetPassword = (props, { router }) => {
  const [{ isLoading, formErrors, email, registrationStateConflict }, setState] = useState({
    isLoading: false,
    formErrors: null,
    email: '',
    registrationStateConflict: false,
  });
  const { formatMessage } = useIntl();

  const handleChangeEmail = (data, value) => {
    setState((prevState) => ({
      ...prevState,
      email: regExpr.test(value) ? value : '',
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const redirect_url = window.location.origin + '/set-password';
    setState((prevState) => ({ ...prevState, isLoading: true }));

    postAuthPassword({ email: email.toLowerCase(), redirect_url })
      .then(() => router.push('/set-password-message?type=letter'))
      .catch((error) => {
        const registrationStateConflict = Boolean(error.error?.registration_state_conflict);
        setState((prevState) => ({
          ...prevState,
          formErrors: error,
          isLoading: false,
          registrationStateConflict
        }));

        if (!registrationStateConflict) {
          toastResponseErrors({ error: formatMessage({ id: 'container.login.reset.error' }) });
        }
      });
  };

  return (
    <LayoutMinimal containerClass="login">
      <FormattedMessage id="container.login.reset.title" tagName="h1" />
      <div className="text-center mb-20">
        <FormattedMessage id="container.login.reset.hint" />
      </div>
      {registrationStateConflict && (
        <MessageBox type="alert">
          <FormattedMessage id="container.login.reset.registrationStateConflict" />
        </MessageBox>
      )}

      <form noValidate onSubmit={handleSubmit}>
        <LabelledInput
          tabIndex="0"
          model={emailInputModel}
          onChange={handleChangeEmail}
        >
          <FormattedMessage id="component.userProfile.email" />
        </LabelledInput>
        <Preloader isActive={isLoading} />
        <Button
          className="lm-button mb-30"
          primary
          disabled={isLoading || !!formErrors || !email}
        >
          <FormattedMessage id="container.login.reset.instructions" />
        </Button>
        <Link to="/login" className="color-primary align-center block">
          <FormattedMessage id="container.login.returnToLogin" />
        </Link>
      </form>
    </LayoutMinimal>
  );
};

ResetPassword.contextTypes = {
  router: T.object,
};

export default ResetPassword;
