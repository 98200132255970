import { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setNetworkConnect, setProgressSync, setForceSync } from 'reducers/network';
import { fetchFarmGroups } from 'reducers/dailyCheckup/checkupFarmGroups';
import { fetchFarms } from 'reducers/dailyCheckup/checkupFarms';
// offline
import { getLastPreloadData, updateLastPreloadData, clearOfflineCheckups,
  clearOfflineRequests, clearOfflineMediaRequests } from 'utils/offlineHelper';
// utils
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import { getLSRefreshIndexedDB, removeLSRefreshIndexedDB } from 'utils/localStorageHelper';
import { isCheckupPigGroupsLocation, isCheckupFarmsLocation } from 'utils';
// api
import { getSymptoms, getTreatments, getMortalityReasons, getTemperaturePoints, getFarmsForOffline,
  getPigGroupsForOffline } from 'endpoints/common';
import { execDailyCheckupsSync, execMediaSync } from 'endpoints/checkup/checkupEdit';

class NetworkManager extends Component {

  componentDidMount() {
    window.addEventListener('online', this.handleOnline, false);
    window.addEventListener('offline', this.handleOffline, false);
    this.initialPreloadData();
  }

  componentDidUpdate({ current_company_id: prevCompanyId }) {
    const { current_company_id, isForceSync } = this.props;
    if ((prevCompanyId !== current_company_id && current_company_id) || isForceSync) {
      this.sendRequestsForOffline(current_company_id);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleOnline, false);
    window.removeEventListener('offline', this.handleOffline, false);
  }

  isSyncStarted = false;

  syncEntities = new Set();

  startSync = (requestsCount, syncingData = 'syncingDC') => {
    const isRequestsExists = requestsCount > 0;
    if (isRequestsExists) this.syncEntities.add(syncingData);
    if (!this.isSyncStarted && isRequestsExists) {
      this.props.setProgressSync({ progress: 10, syncingData });
      this.isSyncStarted = true;
    }
  };

  finishSync = () => {
    if (this.isSyncStarted) {
      const syncingData = this.syncEntities.size === 1
        ? [...this.syncEntities][0]
        : 'syncingDC';
      this.props.setProgressSync({ progress: 100, syncingData });
      this.isSyncStarted = false;
      this.syncEntities.clear();
    }
  };

  makeReFetchData = (requestsCount) => {
    const { pathname, fetchFarmGroups, fetchFarms, params } = this.props;
    if (requestsCount && isCheckupPigGroupsLocation(pathname)) {
      fetchFarmGroups(params.id);
    }
    if (requestsCount && isCheckupFarmsLocation(pathname)) {
      fetchFarms(params.id);
    }
  };

  initialPreloadData = () => {
    const { current_company_id, setNetworkConnect } = this.props;
    if (navigator.onLine) {
      this.preloadDataForIndexedDB(current_company_id).then(() => {
        if (current_company_id) this.handleOnline();
      });
    } else {
      setNetworkConnect({ isOnline: false });
    }
  };

  handleOnline = () => {
    const { setNetworkConnect } = this.props;
    setNetworkConnect({ isOnline: true });

    execDailyCheckupsSync()
      .then((requests) => {
        this.startSync(requests.length, 'syncingDC');
        Promise.all(requests).then(() => {
          execMediaSync().then((mediaRequests) => {
            this.startSync(mediaRequests.length, 'syncingDC');
            Promise.all(mediaRequests).then(() => {
              this.finishSync();
              this.makeReFetchData(requests.length);
            });
          });
        });
      });
  };

  handleOffline = () => {
    this.props.setNetworkConnect({ isOnline: false });
  };

  sendRequestsForOffline = (companyId) => {
    const { setProgressSync, setForceSync } = this.props;
    setProgressSync({ progress: 10, syncingData: 'preloadData' });
    const requests = [
      getFarmsForOffline(),
      getPigGroupsForOffline(),
      getTreatments(),
      getSymptoms(),
      getMortalityReasons(),
      getTemperaturePoints(),
      updateLastPreloadData(companyId),
      clearOfflineCheckups(),
      clearOfflineRequests(),
      clearOfflineMediaRequests(),
    ];
    return Promise.all(requests).then(() => {
      setProgressSync({ progress: 100, syncingData: 'preloadData' });
      setForceSync(false);
    });
  };

  preloadDataForIndexedDB = (company_id) => {
    return new Promise((resolve, reject) => {

      if (!company_id) {
        resolve();
        return;
      }

      getLastPreloadData(company_id)
        .then((preloadData = {}) => {

          const isNewDay = !moment().startOf('day').isSame(moment(preloadData.loaded_at).startOf('day'));
          const isForceRefresh = getLSRefreshIndexedDB();

          if (isEmpty(preloadData) || isNewDay || isForceRefresh) {
            removeLSRefreshIndexedDB();
            this.sendRequestsForOffline(company_id).then(resolve).catch(reject);
          } else {
            resolve();
          }
        })
        .catch(reject);
    });
  };

  render() {
    return null;
  }
}

NetworkManager.contextTypes = {
  router: T.object.isRequired,
};

NetworkManager.propTypes = {
  setNetworkConnect: T.func.isRequired,
  fetchFarmGroups: T.func.isRequired,
  fetchFarms: T.func.isRequired,
  current_company_id: T.number,
  setProgressSync: T.func.isRequired,
  pathname: T.string.isRequired,
  params: T.object.isRequired,
  setForceSync: T.func.isRequired,
  isForceSync: T.bool.isRequired,
};

export default connect(
  (state) => ({
    current_company_id: state.auth.user?.current_company?.id,
    isForceSync: state.network.isForceSync,
  }), {
    setNetworkConnect,
    fetchFarmGroups,
    fetchFarms,
    setProgressSync,
    setForceSync,
  }
)(NetworkManager);
