import { fetchFromAPI } from 'utils/api';

export function fetchPigGroupDiagnosis(diagnosisId, params) {
  return fetchFromAPI(`/diagnoses/${diagnosisId}/pig_group_diagnoses`, { params }).catch((errors) => {
    throw errors;
  });
}

export function createPigGroupDiagnosis(diagnosisId, params) {
  return fetchFromAPI(`/diagnoses/${diagnosisId}/pig_group_diagnoses`, { method: 'post', body: JSON.stringify(params) })
    .catch((response) => {
      throw response;
    });
}

export function deletePigGroupDiagnosis(diagnosisId, pigGroupDiagnosisId) {
  return fetchFromAPI(`/diagnoses/${diagnosisId}/pig_group_diagnoses/${pigGroupDiagnosisId}`, {
    method: 'delete'
  })
    .catch((response) => {
      throw response;
    });
}
