import { fetchFromAPI } from 'utils/api';

export const getFarmReports = (farmId, templateId, params) => {
  return fetchFromAPI(`/farms/${farmId}/svrs`, { params: { ...params, svr_template_id: templateId } });
};

export const getFarmBarnsheetsReports = (farmId, params) => {
  return fetchFromAPI(`/barnsheets/farms/${farmId}/svrs`, { params });
};

export const createFarmReport = (farmId, templateId, resource) => {
  return fetchFromAPI(`/farms/${farmId}/svrs`, {
    method: 'post',
    params: { svr_template_id: templateId },
    body: JSON.stringify({ resource })
  }).then((response) => response.resource);
};

export const deleteFarmReport = (farmId, reportId) => {
  return fetchFromAPI(`/farms/${farmId}/svrs/${reportId}`, { method: 'delete' });
};
