import React, { useEffect } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Subnavigation from 'components/Subnavigation';
// utils
import { isSchedulingTariffUser } from 'utils/userHelper';
// redux
import { connect } from 'react-redux';

const HealthVariables = ({ children, isLogisticsEnabled, isSchedulingAccessOnly, location, router }) => {

  useEffect(() => {
    const redirectPath = '/admin/health-variables/genetics';

    if (isSchedulingAccessOnly && location.pathname !== redirectPath) router.push(redirectPath);
  }, [isSchedulingAccessOnly]);

  const navLinks = [
    {
      link: '/admin/health-variables/medications',
      label: <FormattedMessage id="general.products" />,
      isHidden: isSchedulingAccessOnly,
    },
    {
      link: '/admin/health-variables/symptoms',
      label: <FormattedMessage id="general.symptoms" />,
      isHidden: isSchedulingAccessOnly,
    },
    {
      link: '/admin/health-variables/diseases',
      label: <FormattedMessage id="general.diseases" />,
      isHidden: isSchedulingAccessOnly,
    },
    {
      link: '/admin/health-variables/death-alerts',
      label: <FormattedMessage id="general.deathLoss" />,
      isHidden: isSchedulingAccessOnly,
    },
    {
      link: '/admin/health-variables/mortality-reasons',
      label: <FormattedMessage id="general.mortalityReasonsBL" />,
      isHidden: isSchedulingAccessOnly,
    },
    {
      link: '/admin/health-variables/setpoints',
      label: <FormattedMessage id="general.setPoints" />,
      isHidden: isSchedulingAccessOnly,
    },
    {
      link: '/admin/health-variables/genetics',
      label: <FormattedMessage id="general.genetics" />,
      isHidden: !isLogisticsEnabled,
    },
  ];

  return (
    <div>
      <Subnavigation links={navLinks} className="small-12 column" />
      <section className="small-12 column">
        {children}
      </section>
    </div>
  );
};

HealthVariables.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
  isLogisticsEnabled: T.bool.isRequired,
  isSchedulingAccessOnly: T.bool,
  location: T.object.isRequired,
  router: T.object.isRequired,
};

export default connect((state) => ({
  isLogisticsEnabled: state.auth.user.current_company.logistics,
  isSchedulingAccessOnly: isSchedulingTariffUser(state.auth.user),
}))(HealthVariables);
