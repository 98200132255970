import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// components
import ReportTemplateForm from './components/ReportTemplateForm';
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
// api
import { getReportTemplate, updateReportTemplate } from 'endpoints/admin/reportTemplates';
// helper
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatReportTemplateResponse, formatReportTemplateUpdatePayload, validateReportTemplate } from './helper';

const ReportTemplateEdit = ({ router, params: { id } }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportTemplate, setReportTemplate] = useState();
  const onCancel = () => router.goBack();

  const fetchReportTemplate = async () => {
    try {
      setIsLoading(true);
      const reportTemplate = await getReportTemplate(id);
      const formattedResponse = formatReportTemplateResponse(reportTemplate);
      setReportTemplate(formattedResponse);
      setIsLoading(false);
    } catch (err) {
      toastResponseErrors(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReportTemplate();
  }, [id]);

  const handleSubmit = async (values) => {
    try {
      const payload = formatReportTemplateUpdatePayload(values, reportTemplate);
      setIsLoading(true);
      await updateReportTemplate(payload.id, payload);
      router.push('/admin/report-builder');
    } catch (err) {
      toastResponseErrors(err);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Preloader isActive={isLoading} />
      <ReportTemplateForm
        submitButtonLabel={<FormattedMessage id="container.checkupIndex.row.update" />}
        initialValues={reportTemplate}
        validate={validateReportTemplate}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        title={<FormattedMessage id="general.editReport" />}
      />
    </>
  );
};

ReportTemplateEdit.propTypes = {
  router: T.object.isRequired,
};

export default ReportTemplateEdit;
