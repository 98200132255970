import React from 'react';
import T from 'prop-types';
// redux
import { setUnreadNotificationsCount } from 'reducers/sidebar';
import { dismissAllNotification } from 'reducers/notifications';
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
// components
import NotificationsList from 'components/Notifications/NotificationsList';
import Dropdown from 'components/Dropdown';
import Link from 'react-router/lib/Link';
import { BasicPlanModal } from 'components/BasicPlan';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatSignInCount } from 'utils';
// translation
import { FormattedMessage } from 'react-intl';
// styles
import './NotificationBadge.scss';

const NotificationBadge = ({
  unreadNotifications,
  dismissAllNotification,
  resources,
  setUnreadNotificationsCount,
  currentUser,
  openModal,
}) => {
  const isEverypigBasic = currentUser.current_company.everypig_basic;
  const last_notification_id = resources[0]?.id || null;
  const dismissAll = () => {
    dismissAllNotification(currentUser.id, last_notification_id)
      .then(() => setUnreadNotificationsCount(0))
      .catch(toastResponseErrors);
  };

  // notifications displaying is disabled for users of tenant with basic plan enabled
  const handleNotificationDropdownOpen = () => {
    openModal(<BasicPlanModal />);
  };

  return (
    <Dropdown
      className="NotificationBadge right-actions-item"
      toggleDropdown={isEverypigBasic && handleNotificationDropdownOpen}
      trigger={(
        <div className="dropdown-header">
          <i className="fa fa-bell" />
          {unreadNotifications > 0 && <span className="count-value">{formatSignInCount(unreadNotifications)}</span>}
        </div>
      )}
    >
      <div className="dropdown-body">
        <div className="header-block">
          <div>
            <FormattedMessage tagName="b" id="general.pageTitle.notifications" />&nbsp;
            {unreadNotifications > 0 && <span className="items-count">{unreadNotifications}</span>}
          </div>
          <div className="action-buttons">
            {resources.length > 0 && (
              <span>
                <span onClick={dismissAll}>
                  <FormattedMessage id="general.dismissAll" />
                </span>&nbsp;|&nbsp;
              </span>
            )}
            <Link to="/profile/messaging"><FormattedMessage id="general.settings" /></Link>
          </div>
        </div>
        <div className="content-block">
          <NotificationsList currentUser={currentUser} key={currentUser.current_company.id} />
        </div>
        <div className="footer-block">
          <Link to="/notifications" className="primary-link"><FormattedMessage id="general.allNotifications" /></Link>
        </div>
      </div>
    </Dropdown>
  );
};

NotificationBadge.propTypes = {
  unreadNotifications: T.number,
  resources: T.array,
  currentUser: T.object,
  setUnreadNotificationsCount: T.func.isRequired,
  dismissAllNotification: T.func.isRequired,
  openModal: T.func.isRequired,
};

export default connect(
  (state) => ({
    unreadNotifications: state.sidebar.counters?.unreadNotifications || 0,
    resources: state.notifications.resources,
    currentUser: state.auth.user,
  }), {
    setUnreadNotificationsCount,
    dismissAllNotification,
    openModal,
  },
)(NotificationBadge);
