import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { checkinUser as checkinUserAction } from 'reducers/auth';
import { setPageOptions } from 'reducers/layout';
// components
import { FormattedMessage } from 'react-intl';
import PigGroupDailyCheckup from './components/PigGroupDailyCheckup';
import GroupInfoCollapse from 'components/Groups/GroupInfoCollapse';
import CollapseWithdrawalWarning from './components/CollapseWithdrawalWarning';
import PigGroupCommitmentsPanel from 'components/PigGroupCommitmentsPanel';
import Button from 'components/Button';
import StickyHeader from 'components/StickyHeader';
import PigsUnderCareLine from 'components/PigsUnderCareLine/PigsUnderCareLine';
import SubNavigation from 'components/Subnavigation';
import CollapsesBox from 'components/CollapsesBox/CollapsesBox';
import AcceptCommitmentModal from 'components/CommitmentModal/AcceptCommitmentModal';
import DeclineCommitmentModal from 'components/CommitmentModal/DeclineCommitmentModal';
import DrBotAvatar from 'components/DrBotAvatar/DrBotAvatar';
import PigGroupTreatmentsPanel from 'components/PigGroupTreatmentsPanel';
import PigsUnderTreatmentLine from 'components/PigsUnderTreatmentLine';
// endpoints
import { acceptCommitment } from 'endpoints/commitments';
// utils
import isEmpty from 'lodash.isempty';
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { toastr } from 'react-redux-toastr';
import { isIOS, isSafari, isMobile } from 'react-device-detect';
import { getCommitmentDirection } from 'utils/commitmentsHelper';
import { tableNames } from 'utils/constants';
// assets
import './DailyCheckupEdit.scss';

class DailyCheckupEdit extends Component {

  state = {
    isCheckinLoading: false,
  };

  componentDidMount() {
    const { checkup, isEditMode, currentUser, location: { pathname } } = this.props;
    this.setPageOptions(checkup, isEditMode);
    const isLogisticsEnabled = currentUser.current_company.logistics;
    if (!isLogisticsEnabled && pathname.includes('schedule')) this.context.router.push('/');
  }

  componentDidUpdate(prevProps) {
    const { checkup, isEditMode } = this.props;
    if (prevProps.checkup?.id !== checkup.id) {
      this.setPageOptions(checkup, isEditMode);
    }
  }

  onSubNavSelect = (selectedItem) => {
    if (selectedItem.isButton) {
      this.checkIn();
      return;
    }
    this.context.router.push(selectedItem.path);
  };

  getActiveTab = () => {
    const { location: { pathname } } = this.props;
    if (pathname.includes('group-info')) return 'group-info';
    if (pathname.includes('schedule')) return 'schedule';
    if (pathname.includes('treatments')) return 'treatments';
    return 'checkup';
  };

  setPageOptions = (checkup, isEditMode) => {
    const { setPageOptions } = this.props;
    if (!isEmpty(checkup)) {
      setPageOptions({
        backLink: '/daily-checkup',
        inEditMode: isEditMode,
      });
    }
  };

  checkIn = () => {
    const { checkinUser, checkup, currentUser } = this.props;
    this.setState({ isCheckinLoading: true });
    const farmId = checkup.pig_group.farm_id;

    checkinUser(farmId)
      .then(() => {
        this.setState({ isCheckinLoading: false });
        toastr.light('', '', {
          icon: <DrBotAvatar />,
          position: 'bottom-center',
          showCloseButton: true,
          className: 'dr-bot',
          width: 400,
          timeOut: 6000,
          component: <FormattedMessage
            id="container.checkupEdit.checkInSuccess"
            values={{
              farm_name: <b>{checkup?.pig_group?.farm?.name}</b>,
              company_name: <b>{currentUser.current_company.name}</b>,
            }}
          />,
        });
      })
      .catch((response) => {
        this.setState({ isCheckinLoading: false });
        toastResponseErrors(response);
      });
  };

  renderCheckinButton = (isCheckedIn, className = '') => {
    const { isCheckinLoading } = this.state;
    const isDisabled = isCheckinLoading || isCheckedIn;
    return (
      <Button light onClick={this.checkIn} className={`checkin-btn ${className}`} disabled={isDisabled}>
        <i className={`fa fa-${isCheckedIn ? 'check' : 'map-marker'} mr-5`} />
        <FormattedMessage id={`container.checkupEdit.${isCheckedIn ? 'checkedIn' : 'checkIn'}`}>
          {(text) => <span className="show-for-large">{text}</span>}
        </FormattedMessage>
        <FormattedMessage id={`container.checkupEdit.${isCheckedIn ? 'checkedIn' : 'checkIn'}ToFarm`}>
          {(text) => <span className="hide-for-large">{text}</span>}
        </FormattedMessage>
      </Button>
    );
  };

  renderCheckInState = (className = '') => (
    <div className={`checked-in-state ${className} animated fadeInDown`}>
      <i className="fa fa-map-marker mr-10" />
      <FormattedMessage id="container.checkupEdit.checkedInState" />
    </div>
  );

  renderWarningTitle = () => {
    const medsCount = this.props.checkup?.withdrawal_warnings?.length || 0;
    return (
      <div className="warning-title">
        <i className="fa fa-warning warning-icon" />
        <FormattedMessage id="container.checkupEdit.withdrawalWarnings" values={{ medsCount }} />
      </div>
    );
  };

  optionsGetter = (commitment, openModal, setDataItemByIndex, index) => {
    const { id, status, loading, acceptable, declinable } = commitment;
    const { ready_for_migration } = loading;
    const isCommitmentAccepted = status === 'accepted';
    const isCommitmentDeclined = status === 'declined';
    const direction = getCommitmentDirection(commitment);
    const handleAcceptCommitment = () => acceptCommitment(id)
      .then((commitment) => {
        setDataItemByIndex(index, commitment);
        openModal(<AcceptCommitmentModal loadName={loading.name} />);
      });

    const declineCommitment = () => {
      openModal(
        <DeclineCommitmentModal
          onSubmit={(resource) => setDataItemByIndex(index, resource)}
          commitment={commitment}
        />
      );
    };


    const onMovementChange = () => {
      const { params } = this.props;
      this.context.router.push(`/daily-checkup/${params.id}/load-movement/${id}`);
    };

    return [
      { onClick: () => this.context.router.push(`/my-schedule/${commitment.id}`),
        label: <FormattedMessage id="general.button.reviewLoad" />,
        showOnMobile: true,
        light: true },
      { onClick: handleAcceptCommitment, label: <FormattedMessage id="general.confirm" />,
        blue: true,
        hide: !acceptable || isCommitmentAccepted },
      { onClick: declineCommitment, label: <FormattedMessage id="general.reportConflict" />,
        red: true,
        hide: !declinable || isCommitmentDeclined },
      {
        label: <FormattedMessage id="general.button.receiveLoad" />, primary: true,
        onClick: onMovementChange, hide: !ready_for_migration || direction === 'out', showOnMobile: true },
      {
        label: <FormattedMessage id="general.button.shipLoad" />, primary: true, showOnMobile: true,
        onClick: onMovementChange, hide: !ready_for_migration || direction === 'in' }
    ];
  };

  render() {
    const { checkup, validateCheckup, currentUser, params, isLoading, isOnline } = this.props;
    const isCheckedIn = currentUser.check_in?.farm_id === +checkup?.pig_group?.farm_id;
    const isLogisticsEnabled = currentUser.current_company.logistics;
    const isTreatmentProtocolsEnabled = !!currentUser.current_company.treatment_protocols;
    const warnings = checkup.withdrawal_warnings || [];
    const activeTab = this.getActiveTab();

    const subNavItems = [
      { tab: 'checkup', name: <FormattedMessage id="general.checkup" />, path: `/daily-checkup/${params.id}` },
      { tab: 'group-info', name: <FormattedMessage id="general.groupInfo" />,
        path: `/daily-checkup/${params.id}/group-info`, isHidden: !isOnline },
      { tab: 'schedule', name: <FormattedMessage id="general.schedule" />, path: `/daily-checkup/${params.id}/schedule`,
        isHidden: !isLogisticsEnabled || !isOnline },
      { tab: 'treatments', name: <FormattedMessage id="general.treatments" />,
        path: `/daily-checkup/${params.id}/treatments`, isHidden: !isTreatmentProtocolsEnabled },
      { isButton: true, name: <FormattedMessage id="general.checkin" />, icon: <i className="fa fa-map-marker" />,
        isHidden: !isMobile || isCheckedIn },
    ];

    return (
      <div className="DailyCheckupEdit">
        {isCheckedIn && this.renderCheckInState('show-for-large')}

        <StickyHeader className="hide-for-large">
          {isCheckedIn && this.renderCheckInState()}
          <PigsUnderCareLine mobile pigsCount={checkup.pigs} />
          {checkup.abf_tracking && <PigsUnderTreatmentLine mobile pigsCount={checkup.treated_pigs} />}
        </StickyHeader>

        <SubNavigation
          className={cn('small-12 column', { 'checkup-subnav': isMobile })}
          isActive={(item) => item.tab === activeTab}
          items={subNavItems}
          onSelect={this.onSubNavSelect}
        >
          {!isMobile && this.renderCheckinButton(isCheckedIn)}
        </SubNavigation>

        {(activeTab === 'checkup') && (
          <PigGroupDailyCheckup
            checkup={checkup}
            isLoading={isLoading}
            checkupId={params.id}
            validateCheckup={validateCheckup}
          />
        )}

        {activeTab === 'group-info' && (
          <div className="group-info">
            <section className={cn('checkup-body', { 'pb-100': isIOS || isSafari })}>
              <div className="row centered">
                <div className="small-12 column">
                  <CollapsesBox>
                    <GroupInfoCollapse group={checkup.pig_group} checkupId={params.id} />
                    {!!warnings.length &&
                      <CollapseWithdrawalWarning title={this.renderWarningTitle()} data={warnings} />}
                  </CollapsesBox>
                </div>
              </div>
            </section>
          </div>
        )}

        {activeTab === 'schedule' && !isEmpty(checkup) && (
          <div>
            <PigGroupCommitmentsPanel
              optionsGetter={this.optionsGetter}
              title={<FormattedMessage id="general.schedule" />}
              pigGroupId={checkup.pig_group_id}
              tableName={tableNames.pigGroupCommitments}
            />
          </div>
        )}

        {activeTab === 'treatments' && !isEmpty(checkup) && (
          <div>
            <PigGroupTreatmentsPanel pigGroup={checkup.pig_group} />
          </div>
        )}
      </div>
    );
  }
}

DailyCheckupEdit.contextTypes = {
  router: T.object,
};

DailyCheckupEdit.propTypes = {
  params: T.object,
  location: T.object,
  isEditMode: T.bool,
  isLoading: T.bool,
  isOnline: T.bool.isRequired,
  checkup: T.object.isRequired,
  setPageOptions: T.func.isRequired,
  validateCheckup: T.func.isRequired,
  currentUser: T.object.isRequired,
  checkinUser: T.func.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
    isOnline: state.network.isOnline,
  }), {
    setPageOptions,
    checkinUser: checkinUserAction,
  }
)(DailyCheckupEdit);
