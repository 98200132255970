import React, { Fragment, useEffect, useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import MessageBox from 'components/MessageBox';
import TreatmentStepCard from 'components/TreatmentProtocols/TreatmentStepCard';
// endpoints
import { getGroupDCTreatmentProtocolProductSteps } from 'endpoints/treatmentProtocols';

const TreatmentProtocolsProductSteps = ({ checkupId, pigGroup, selectedStepIds, onReport }) => {
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (pigGroup?.id) {
      getGroupDCTreatmentProtocolProductSteps(pigGroup.id).then((response) => {
        setSteps(response.resources);
      });
    }
  }, [pigGroup?.id]);

  return (
    <Fragment>
      {steps.length > 0 && (
        <MessageBox type="warning" className="mb-10">
          <FormattedMessage
            id="component.checkupSegment.goToTreatmentsTab"
            values={{
              a: (msg) => <Link className="primary" to={`/daily-checkup/${checkupId}/treatments`}>{msg}</Link>,
            }}
          />
        </MessageBox>
      )}
      {steps.map((step) => !selectedStepIds.includes(step.id) && (
        <TreatmentStepCard
          key={step.id}
          hasNoDetails
          reportSteps
          className="mt-10 mb-20"
          onReport={onReport}
          pigGroup={pigGroup}
          treatmentStepData={step}
        />
      ))}
    </Fragment>
  );
};

TreatmentProtocolsProductSteps.propTypes = {
  onReport: T.func.isRequired,
  checkupId: T.number,
  pigGroup: T.object,
  selectedStepIds: T.array.isRequired,
};

export default TreatmentProtocolsProductSteps;
