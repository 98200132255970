import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ActivityActionsBar from './ActivityActionsBar/ActivityActionsBar';
import Collapse from 'components/Collapse/Collapse';
import ActivityBox from './ActivityBox';
import ActivityBodyCollapse from './ActivityBodyCollapse';
import ActivityHeader from './ActivityHeader';
import ActivityHeaderBadge from './ActivityHeader/ActivityHeaderBadge';
import ActivityMentions from './ActivityMentions';
import FarmTypeLabel from 'components/FarmTypeLabel';
import FarmFeedRowComments from 'components/FarmFeed/FarmFeedRowComments';
import FarmFeedRowAddComment from 'components/FarmFeed/FarmFeedRowAddComment';
import LastCommentInfo from './LastCommentInfo/LastCommentInfo';
import Divider from 'components/Divider';
import Link from 'react-router/lib/Link';
// utils
import classnames from 'classnames/bind';
import moment from 'moment';
// hoc
import withActivityController from './hoc/withActivityController';
// styles
import styles from './GeneralWaterUsageWarning.module.scss';

const cn = classnames.bind(styles);

const affectRerenderKeys = ['id', 'updated_at', 'is_viewed', 'flagged', 'comments', 'additional_data'];

const GeneralWaterUsageWarning = ({
  className,
  activity,
  currentUser,
  isChildActivity,
  markAsSeen,
  openDropdown,
  setActivityFlag,
  isMinimized,
  isTruncated,
  toggleMinimized,
  onViewFullActivity,
  defaultDropdownOptions,
}) => {
  const { id, events, created_at, flagged, is_viewed, mentions } = activity;
  const isAdmin = currentUser.roles_map.admin;

  const renderPercentageLabel = (additional_data) => {
    const { water_usage_diff_percentage } = additional_data;
    return water_usage_diff_percentage > 0 ? `+${water_usage_diff_percentage}%` : `${water_usage_diff_percentage}%`;
  };

  const renderIntegrationBadge = (additional_data) => {
    const { integration } = additional_data;

    return integration ? (<span className={cn('integration-badge')}>{integration}</span>) : null;
  };

  const renderPigGroupName = (pig_group) => {
    if (isAdmin) {
      return (
        <Link to={`/barnsheets/groups/${pig_group.id}/water-usage`} className="color-primary">
          {pig_group.name}
        </Link>
      );
    }
    return pig_group.name;
  };

  const renderFarmName = (farm, pig_group) => {
    if (isAdmin) {
      return (
        <div>
          <Link className="color-primary" to={`/barnsheets/groups/${pig_group.id}/water-usage`}>{farm.name}</Link>
          <FarmTypeLabel className="ml-5" farmType={farm.farm_type} />
        </div>
      );
    }
    return <div>{farm.name}<FarmTypeLabel className="ml-5" farmType={farm.farm_type} /></div>;
  };

  return (
    <ActivityBox
      className={className}
      isFlagged={flagged}
      isTruncated={isTruncated}
      hasMarker={!isChildActivity}
      isUnseen={!is_viewed}
      markAsSeen={markAsSeen}
      onViewFullActivity={onViewFullActivity}
    >
      {!isMinimized &&
        <LastCommentInfo activity={activity} currentUser={currentUser} />
      }
      <ActivityHeader
        activityId={id}
        onOpenDropdown={(e) => openDropdown(e, defaultDropdownOptions)}
        onDoubleClick={toggleMinimized}
      >
        <ActivityHeaderBadge isCentered>
          <FormattedMessage id="general.waterUsageWarning" tagName="b" />
        </ActivityHeaderBadge>
      </ActivityHeader>
      <ActivityBodyCollapse isClosed={isMinimized}>
        <div className={cn('warning-text')}>
          <FormattedMessage
            tagName="div"
            id="component.generalWaterUsageWarning.text"
            values={{
              b: (weight) => <b>{weight}</b>,
              orange: (msg) => <span className={cn('brand-orange')}>{msg}</span>,
              date: moment(activity.created_at).subtract(1, 'day').format('MM/DD/YYYY'),
            }}
          />
          <div className={cn('timestamp')}>
            {moment(created_at).format('MMM DD, YYYY, hh:mm a')}
          </div>
          <ActivityMentions isAdmin={isAdmin} users={mentions} />
        </div>
        {!!events.length && (
          <div className="ph-30 mb-25">
            <Collapse
              className={cn('collapse')}
              title={<FormattedMessage id="general.button.showDetails" />}
            >
              <Divider />
              {events.map(({ farm, pig_group, additional_data }, idx) => (
                <div className={cn('row')} key={idx}>
                  <div className={cn('farm-name')}>
                    {renderFarmName(farm, pig_group)}
                  </div>
                  <div className={cn('group-name')}>
                    {renderPigGroupName(pig_group)}
                  </div>
                  <div className={cn('integration-name')}>
                    {renderIntegrationBadge(additional_data)}
                  </div>
                  <div className={cn('value')}>
                    {renderPercentageLabel(additional_data)}
                  </div>
                </div>
              ))}
            </Collapse>
          </div>
        )}
        {!isChildActivity && (
          <>
            <ActivityActionsBar
              activity={activity}
              currentUser={currentUser}
              openDropdown={openDropdown}
              setActivityFlag={setActivityFlag}
            />
            <FarmFeedRowComments activity={activity} />
            <FarmFeedRowAddComment activity={activity} />
          </>
        )}
      </ActivityBodyCollapse>
    </ActivityBox>
  );
};

GeneralWaterUsageWarning.contextTypes = {
  router: T.object.isRequired
};

GeneralWaterUsageWarning.propTypes = {
  className: T.string,
  activity: T.object.isRequired,
  currentUser: T.object.isRequired,
  markAsSeen: T.func,
  isChildActivity: T.bool,
  openDropdown: T.func.isRequired,
  setActivityFlag: T.func.isRequired,
  // from hoc
  isTruncated: T.bool.isRequired,
  isMinimized: T.bool.isRequired,
  toggleMinimized: T.func.isRequired,
  onViewFullActivity: T.func.isRequired,
  defaultDropdownOptions: T.array.isRequired,
};

GeneralWaterUsageWarning.defaultProps = {
  isChildActivity: false,
};

export default withActivityController(GeneralWaterUsageWarning, { affectRerenderKeys });
