import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { setTableParams } from 'reducers/tableParams';
import { openPortalRight } from 'reducers/rightToLeftPortal';
import { createUserFilterAction, updateUserFilterAction } from 'reducers/userFilters';
// hooks
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
import { useMediaQuery } from 'react-responsive';
import useDidMountEffect from 'hooks/useDidMountEffect';
// endpoints
import { getTreatmentProtocolsFarms, applyTreatmentProtocolFarmsFilter } from 'endpoints/treatmentProtocols';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import TreatmentProtocolsFarmRow from './components/TreatmentProtocolsFarmRow';
import NothingBox from 'components/NothingBox';
import Panel from 'components/Panel';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import PaginationWrapper from 'components/PaginationWrapper';
import UserFilter from 'components/UserFilter';
import UserFilterMobile from 'components/UserFilterMobile/UserFilterMobile';
import UserFilterMobileHeader from 'components/UserFilterMobile/UserFilterMobileHeader';
import SortDropdown from 'components/SortDropdown';
// utils
import { tableNames } from 'utils/constants';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { allOrAnyFarmsOptions, userFilterGroupNames, userFilterSectionTypes } from 'utils/constants/userFilters';
import classnames from 'classnames/bind';
// styles
import styles from './FarmsSetUp.module.scss';

const cn = classnames.bind(styles);

const FarmsSetUp = ({
  isOnline,
  reqParams,
  router,
  setTableParams,
  setPageOptions,
  openPortalRight,
  createUserFilterAction,
  updateUserFilterAction,
  setBreadcrumbs,
}) => {
  const {
    data: {
      resources,
      isLoading,
      meta: { total },
    },
    fetchData,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onSearchChange,
    onUserFilterSectionsChange,
    onUserFilterSegmentSave
  } = useDataTable(getTreatmentProtocolsFarms, {
    tableParams: reqParams,
    filterFetcher: applyTreatmentProtocolFarmsFilter,
    setTableParams: (params) => setTableParams(tableNames.treatmentProtocolFarms, params),
    userFilterGroupName: userFilterGroupNames.treatmentProtocolFarms,
    createUserFilter: createUserFilterAction,
    updateUserFilter: updateUserFilterAction,
  });

  const { page, per_page, search, sort } = reqParams;
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const { formatMessage } = useIntl();

  const sections = [
    { value: userFilterSectionTypes.farmType, label: formatMessage({ id: 'general.farmType' }) },
    { value: userFilterSectionTypes.farm, label: formatMessage({ id: 'general.farmName' }) },
  ];

  useEffect(() => {
    const defaultSortKey = 'active_tp_count';
    const defaultSortDirection = 'desc';
    fetchData({ ...reqParams, page: 1, sort: `${defaultSortKey} ${defaultSortDirection}` })
      .catch(toastResponseErrors);
    setPageOptions({ backLink: '' });
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.treatmentProtocolFarms" />,
        path: '/treatment-protocols-farms',
        useLabelAsMobileTitle: true
      }
    ]);
    return () => {
      setPageOptions({ rightButton: '' });
    };
  }, []);

  const renderTitle = () => (
    <h1 className="lighter">
      <FormattedMessage id="general.farms" />
    </h1>
  );

  const onFarmSelect = (farm) => {
    router.push(`/treatment-protocols-farms/${farm.id}/treatment-protocols`);
  };

  const handleOpenMobileFilters = () => {
    openPortalRight(
      <UserFilterMobile
        allOrAnyConditionLabel={<FormattedMessage id="component.advancedSearchItem.allFarmsFilters" />}
        appliedFilter={reqParams.filters?.[0] || {}}
        filterSections={sections}
        filterGroupName={userFilterGroupNames.treatmentProtocolFarms}
        setFilter={(filter) => fetchData({ ...reqParams, filters: filter ? [filter] : undefined })}
      />,
    );
  };

  const handleSetPageOptions = () => {
    setPageOptions({
      rightButton: !isDesktop && isOnline ? {
        icon: 'fa fa-filter',
        onClick: handleOpenMobileFilters,
      } : '',
    });
  };

  useDidMountEffect(handleSetPageOptions, [reqParams, isDesktop, isOnline]);

  const userFilter = reqParams.filters?.[0];
  const filterSectionsCount = userFilter?.sections?.length;
  const hasMobileFilterHeader = !isDesktop && filterSectionsCount > 0;

  const sortOptions = [
    { label: <FormattedMessage id="general.name" />, key: 'name' },
    { label: <FormattedMessage id="general.activeTreatmentProtocols" />, key: 'active_tp_count' },
    { label: <FormattedMessage id="general.disabledTreatmentProtocols" />, key: 'disabled_tp_count' },
  ];

  return (
    <div
      className={cn('treatment-protocols', {
        'has-mobile-filters': hasMobileFilterHeader,
        'is-desktop': isDesktop,
      })}
    >
      {hasMobileFilterHeader && (
        <UserFilterMobileHeader
          onClick={handleOpenMobileFilters}
          onClose={() => fetchData({ ...reqParams, filters: undefined })}
          sectionsCount={filterSectionsCount}
          segmentName={userFilter?.name}
        />
      )}

      <div className="small-12 column row centered">
        {isDesktop && (
          <UserFilter
            isVisible
            filterGroupName={userFilterGroupNames.treatmentProtocolFarms}
            availFilterSections={sections}
            className={cn('filters')}
            listOptions={allOrAnyFarmsOptions}
            onSectionsChange={onUserFilterSectionsChange}
            initialFilter={reqParams.filters?.[0]}
            onSave={onUserFilterSegmentSave}
          />
        )}
        <Panel className={cn('tp-farms-panel')}>
          <Panel.Heading renderTitle={renderTitle}>
            <Panel.Control>
              <SortDropdown sort={sort} onSortChange={onSortChange} options={sortOptions} />
              <SearchBox onChange={onSearchChange} initialValue={search} disableFocus />
            </Panel.Control>
          </Panel.Heading>
          <Panel.Body>
            <Preloader isActive={isLoading} />
            {resources.map((farm) => (
              <TreatmentProtocolsFarmRow
                key={farm.id}
                onSelect={() => onFarmSelect(farm)}
                farm={farm}
              />
            ))}

            <NothingBox
              itemsName="farms"
              display={!resources.length}
              isLoading={isLoading}
              search={search}
            >
              <FormattedMessage tagName="h1" id="component.nothingBox.noFarmsToDisplay" />
            </NothingBox>

            <PaginationWrapper
              currentPage={page}
              perPage={per_page}
              onPageChange={onPageChange}
              onPerPageChange={onPerPageChange}
              totalItems={total}
              onlyBottom
            />
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
};

FarmsSetUp.propTypes = {
  reqParams: T.shape({
    page: T.number,
    per_page: T.number,
    search: T.string,
  }),
  router: T.object.isRequired,
  setPageOptions: T.func.isRequired,
  setTableParams: T.func.isRequired,
  openPortalRight: T.func.isRequired,
  isOnline: T.bool.isRequired,
  createUserFilterAction: T.func.isRequired,
  updateUserFilterAction: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
};

export default connect(
  (state) => ({
    reqParams: tableParamsSelector(state, tableNames.treatmentProtocolFarms),
    isOnline: state.network.isOnline,
  }),
  {
    setPageOptions,
    setTableParams,
    openPortalRight,
    createUserFilterAction,
    updateUserFilterAction,
    setBreadcrumbs,
  }
)(FarmsSetUp);
