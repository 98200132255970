/* eslint-disable react/prop-types */
import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import { CustomColumn } from 'components/DataTable/Columns/index';
// utils
import cn from 'classnames';

const renderFarmNameColumn = ({ formatted_row, error }) => (
  <CustomColumn
    label={<FormattedMessage id="component.dataTable.headers.farmName" />}
    className={cn({ 'highlighted': error === 'farm' })}
  >
    {formatted_row?.name || '-'}
  </CustomColumn>
);

const renderFarmAddressColumn = ({ formatted_row, error }) => (
  <CustomColumn
    label={<FormattedMessage id="general.address" />}
    className={cn({ 'highlighted': error === 'farm' })}
  >
    {formatted_row?.address || '-'}
  </CustomColumn>
);

const renderPremiseIdColumn = ({ formatted_row, error }) => (
  <CustomColumn
    label={<FormattedMessage id="general.premiseId" />}
    className={cn({ 'highlighted': error === 'farm' })}
  >
    {formatted_row?.federal_premise_id || '-'}
  </CustomColumn>
);

const renderFarmIdColumn = ({ formatted_row, error }) => (
  <CustomColumn
    label={<FormattedMessage id="general.farmId" />}
    className={cn({ 'highlighted': error === 'farm' })}
  >
    {formatted_row?.external_link_id || '-'}
  </CustomColumn>
);

const renderFarmTypeColumn = ({ formatted_row, error }) => (
  <CustomColumn
    label={<FormattedMessage id="general.farmType" />}
    className={cn({ 'highlighted': error === 'farm' })}
  >
    {formatted_row?.farm_type || '-'}
  </CustomColumn>
);

const farmColumns = () => ([
  { label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '1 1 140px',
    renderer: renderFarmNameColumn, sortKey: 'farm_name' },
  { label: <FormattedMessage id="general.address" />, flex: '1 1 140px',
    renderer: renderFarmAddressColumn, sortKey: 'address' },
  { label: <FormattedMessage id="general.farmId" />, flex: '1 1 140px',
    renderer: renderFarmIdColumn, sortKey: 'external_link_id' },
  { label: <FormattedMessage id="general.premiseId" />, flex: '1 1 140px',
    renderer: renderPremiseIdColumn, sortKey: 'premise' },
  { label: <FormattedMessage id="general.farmType" />, flex: '1 1 140px',
    renderer: renderFarmTypeColumn, sortKey: 'farm_type' }
]);

export default farmColumns;
