import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import ActivityBox from './ActivityBox';
import ActivityBodyCollapse from './ActivityBodyCollapse';
import ActivityHeader from './ActivityHeader';
import ActivityActionsBar from './ActivityActionsBar/ActivityActionsBar';
import ActivityMentions from './ActivityMentions';
import ActivityHeaderBadge from './ActivityHeader/ActivityHeaderBadge';
import FarmFeedRowComments from 'components/FarmFeed/FarmFeedRowComments';
import FarmFeedRowAddComment from 'components/FarmFeed/FarmFeedRowAddComment';
// hoc
import withActivityController from './hoc/withActivityController';
// utils
import moment from 'moment';
import classnames from 'classnames/bind';
// styles
import styles from './OutOfComplianceGroupsActivity.module.scss';

const cn = classnames.bind(styles);

const OutOfComplianceGroupsActivity = ({
  className,
  activity,
  currentUser,
  markAsSeen,
  openDropdown,
  setActivityFlag,
  isMinimized,
  isTruncated,
  toggleMinimized,
  onViewFullActivity,
  isChildActivity,
  defaultDropdownOptions,
}) => {
  const { id, created_at, flagged, is_viewed, mentions, additional_data } = activity;
  const isAdmin = currentUser.roles_map.admin;

  return (
    <ActivityBox
      className={className}
      isFlagged={flagged}
      isTruncated={isTruncated}
      hasMarker={!isChildActivity}
      isUnseen={!is_viewed}
      markAsSeen={markAsSeen}
      onViewFullActivity={onViewFullActivity}
    >
      <ActivityHeader
        activityId={id}
        onOpenDropdown={(e) => openDropdown(e, defaultDropdownOptions)}
        onDoubleClick={toggleMinimized}
      >
        <ActivityHeaderBadge isCentered>
          <FormattedMessage id="general.outOfComplianceGroups" />
        </ActivityHeaderBadge>
      </ActivityHeader>
      <ActivityBodyCollapse isClosed={isMinimized}>
        <div className={cn('warning-text')}>
          <FormattedMessage
            tagName="div"
            id="general.outOfComplianceWarning.text"
            values={{
              orange: (msg) => <span className={cn('brand-orange')}>{msg}</span>,
              blue: (msg) => <span className={cn('brand-blue')}>{msg}</span>,
              a: (text) => <Link className="color-primary" to="/treatment-protocols-groups">{text}</Link>,
              count: 100,
              groups_count: additional_data?.groups_count || 0
            }}
          />

          <div className={cn('timestamp')}>
            {moment(created_at).format('MMM DD, YYYY, hh:mm a')}
          </div>
          <ActivityMentions isAdmin={isAdmin} users={mentions} />
        </div>

        {!isChildActivity && (
          <>
            <ActivityActionsBar
              activity={activity}
              currentUser={currentUser}
              openDropdown={openDropdown}
              setActivityFlag={setActivityFlag}
            />
            <FarmFeedRowComments activity={activity} />
            <FarmFeedRowAddComment activity={activity} />
          </>
        )}
      </ActivityBodyCollapse>
    </ActivityBox>
  );
};

OutOfComplianceGroupsActivity.propTypes = {
  className: T.string,
  activity: T.object.isRequired,
  currentUser: T.object.isRequired,
  markAsSeen: T.func,
  isChildActivity: T.bool,
  openDropdown: T.func.isRequired,
  setActivityFlag: T.func.isRequired,
  // from hoc
  isTruncated: T.bool.isRequired,
  isMinimized: T.bool.isRequired,
  toggleMinimized: T.func.isRequired,
  onViewFullActivity: T.func.isRequired,
  defaultDropdownOptions: T.array.isRequired,
};

const affectRerenderKeys = ['id', 'updated_at', 'is_viewed', 'flagged', 'comments'];

export default withActivityController(OutOfComplianceGroupsActivity, { affectRerenderKeys });
