import React, { useEffect } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { ReactComponent as ShareIcon } from './ios-share-icon.svg';
// redux
import { connect } from 'react-redux';
import { closeModal } from 'reducers/modals';
// utils
import { setLSPWAModalSeen } from 'utils/localStorageHelper';
// styles
import styles from './PWAPromptModal.module.scss';

const PWAPromptModal = ({ closeModal }) => {
  useEffect(() => {
    setLSPWAModalSeen('true');
  }, []);

  return (
    <Modal onClose={closeModal} title={<FormattedMessage id="general.installEverypig" />}>
      <section className="modal-body">
        <div className={styles['icon-composition']}>
          <div className={styles['ep-app-icon']}>
            <i className="fa fa-ep-pig" />
          </div>
        </div>
        <FormattedMessage id="general.installEverypig.text" />
        <FormattedMessage
          id="general.installEverypig.instruction"
          values={{ icon: <ShareIcon width={18} className={styles['download-icon']} /> }}
        >{(text) => (
          <div className={styles.instruction}>
            {text}
          </div>
        )}
        </FormattedMessage>
      </section>
      <section className="modal-footer text-right sticky-on-mobile">
        <Button onClick={closeModal}>
          <FormattedMessage id="general.close" />
        </Button>
      </section>
    </Modal>
  );
};

PWAPromptModal.propTypes = {
  closeModal: T.func.isRequired,
};

export default connect(null, { closeModal })(PWAPromptModal);
