import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import ProgressBar from './components/ProgressBar';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import MobileCategoryQuestions from './components/MobileCategoryQuestions';
import CategoryStepper from './components/CategoryStepper';
import SVRHeader from './components/SVRHeader';
import Preloader from 'components/Preloader';
import AdditionalSection from './components/AdditionalSection';
// utils
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import { animateScroll } from 'react-scroll';
import { isSVRCategoriesEmpty } from 'utils/SVRHelper';
// styles
import styles from './Report.module.scss';

const cn = classnames.bind(styles);

const ReportMobileEdit = ({ report, farm, isLoading, location, router, onSave, onClose, onPreview }) => {
  const { push } = router;
  const { query, pathname } = location;
  const { isDraft, createdAt, categories, general_notes_section, action_plan_section, photo_video_uploads } = report;
  const isSVREmpty = isSVRCategoriesEmpty(categories);

  const onStepChange = (step) => push({ pathname, query: { ...query, currentStep: step } });

  const handleStepChange = (step) => () => {
    onStepChange(Math.max(0, step));
    animateScroll.scrollToTop({ containerId: 'svr-content' });
  };

  const onSubmit = () => {
    if (isDraft) return onPreview();
    return onSave();
  };

  const currentStep = query?.currentStep;
  const categoryStep = +(currentStep || 0);
  const currentCategory = categories?.[categoryStep];
  const allQuestionsCount = currentCategory?.svr_questions?.length;
  const answeredQuestionsCount = currentCategory?.svr_questions?.filter((question) => !!question.answer?.value).length;

  const isAdditionalSectionExist = general_notes_section || action_plan_section || photo_video_uploads;
  const isAdditionalSectionVisible = isAdditionalSectionExist && categoryStep === categories.length;
  const isNextStepAvailable = allQuestionsCount === answeredQuestionsCount;

  const isSubmittable = isAdditionalSectionExist ?
    categoryStep === categories.length :
    categoryStep === categories.length - 1;

  return (
    <div className={cn('wrapper')}>
      <Preloader isCentered isActive={isLoading} />
      <SVRHeader
        templateId={report.svr_template_id}
        farmId={farm.id}
        createdAt={createdAt}
        onClose={isSVREmpty ? onClose : onSave}
        farmName={farm.name}
      >
        <ProgressBar questionsData={categories} />
        <Button onClick={isSVREmpty ? onClose : onSave} className={cn('save-close-btn')}>
          <FormattedMessage id="general.button.saveAndClose" />
        </Button>
      </SVRHeader>
      <div className={cn('svr-content')} id="svr-content">
        <div className={cn('content-wrapper')}>
          <div className={cn('content')}>

            {categoryStep < categories.length && (
              <MobileCategoryQuestions
                categoryIndex={categoryStep}
                currentCategory={categories[categoryStep]}
              />
            )}

            {isAdditionalSectionVisible && <AdditionalSection />}

          </div>
        </div>
      </div>
      <div className={cn('svr-footer')}>
        <div className={cn('category-info')}>
          {isAdditionalSectionVisible ? <FormattedMessage id="general.notes" tagName="b" /> : (
            <Fragment>
              <span className="semibold mr-5">{currentCategory.name}</span>
              <span>{answeredQuestionsCount}/{allQuestionsCount}</span>
            </Fragment>
          )}
        </div>
        <div className={cn('actions')}>
          <Button
            onClick={handleStepChange(categoryStep - 1)}
            disabled={categoryStep === 0}
            className={cn('btn', 'back')}
          >
            <i className="mr-10 fa fa-angle-left-btb" />
            <FormattedMessage id="general.button.back" />
          </Button>
          <CategoryStepper categories={categories} />

          {isSubmittable ? (
            <Button className={cn('btn')} primary disabled={!isNextStepAvailable} onClick={onSubmit}>
              {isDraft ?
                <>
                  <FormattedMessage id="general.button.next" />
                  <i className="ml-10 fa fa-angle-right-btb" />
                </> : <FormattedMessage id="general.button.save" />}
            </Button>
          ) : (
            <Button
              className={cn('btn')}
              primary
              disabled={!isNextStepAvailable}
              onClick={handleStepChange(categoryStep + 1)}
            >
              <FormattedMessage id="general.button.next" />
              <i className="ml-10 fa fa-angle-right-btb" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

ReportMobileEdit.propTypes = {
  report: T.object.isRequired,
  isLoading: T.bool.isRequired,
  farm: T.object.isRequired,
  location: T.object.isRequired,
  router: T.object.isRequired,
  onSave: T.func.isRequired,
  onPreview: T.func.isRequired,
  onClose: T.func.isRequired,
};

export default connect((state) => ({
  farm: state.siteVisitReport.farm,
  report: state.siteVisitReport.report,
  isLoading: state.siteVisitReport.isLoading,
}))(ReportMobileEdit);
