import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import SegmentComment from './CheckupSegmentComment/SegmentComment';
import Collapse from 'components/Collapse/Collapse';
// utils
import classnames from 'classnames/bind';
import { getDeathsCount } from 'utils';
import round from 'lodash.round';
// styles
import styles from './DeadPigsBlock.module.scss';
// constants
import { comment_roles, deathTypes } from 'constants.js';
// helpers
import { getCommentByRole } from 'helpers.js';

const cn = classnames.bind(styles);

const { DEATHS_ROLE } = comment_roles;

const renderWithReasons = (data, titleClassName, dailyCheckup) => (
  data.map((item, index) => {
    const deathsCount = getDeathsCount(item);
    return (
      !!deathsCount && (
        <Collapse
          titleClassName={cn(titleClassName, 'death-collapse-title')}
          className={cn('reason-collapse')}
          key={index}
          title={<b>{item.name} • {deathsCount}</b>}
        >
          <div className={cn('reason-body')}>
            {deathTypes
              .filter(({ key }) => (key === 'treated' ? dailyCheckup.abf_tracking : true))
              .map(({ label, key }) => (
                <span key={key} className={cn('item')}>
                  {label} • {item[`${key}_death_count`] || 0}
                </span>
              ))}
          </div>
        </Collapse>
      )
    );
  })
);

const renderWithoutReasons = (data, dailyCheckup, hasAlertOnly = false) => {
  const pigs = Math.max(dailyCheckup.initial_pigs, dailyCheckup.pigs);
  return (
    <div className={cn('reported-deaths')}>
      {data.map(({ type, value }, index) => {
        const hasAlert = (dailyCheckup.death_alerts || [])[type];
        if (hasAlertOnly && !hasAlert) return null;

        const deathValue = hasAlert
          ? `${round(((value || 0) / pigs) * 100, 2)}% (${value || 0})`
          : (value || 0);
        return (
          !!value && (
            <div key={index} className={cn('item', { 'highlighted': hasAlert })}>
              <div className={cn('name')}>
                <FormattedMessage id={`general.deaths.${type}`} />
              </div>
              <div className={cn('value')}>{deathValue}</div>
            </div>
          )
        );
      })}
    </div>
  );
};

const DeadPigsBlock = ({ titleClassName, className, data, dailyCheckup, hasDeathReasons }) => {
  const deathData = [
    { type: 'chronic', value: dailyCheckup.chronic_death_count },
    { type: 'acute', value: dailyCheckup.acute_death_count },
    { type: 'euthanasia', value: dailyCheckup.euthanasia_death_count },
    { type: 'treated', value: dailyCheckup.treated_death_count },
  ];

  return (
    <div className={cn(className, 'DeadPigsBlock')}>
      {hasDeathReasons ? (
        <>
          {renderWithoutReasons(deathData, dailyCheckup, true)}
          {renderWithReasons(data, titleClassName, dailyCheckup)}
        </>
      ) : (
        renderWithoutReasons(deathData, dailyCheckup)
      )}
      <SegmentComment comment={getCommentByRole(dailyCheckup?.comments || [], DEATHS_ROLE)} />
    </div>
  );
};

DeadPigsBlock.propTypes = {
  titleClassName: T.string,
  className: T.string,
  data: T.array,
  dailyCheckup: T.object,
  hasDeathReasons: T.bool,
};

export default DeadPigsBlock;
