export const getLSItem = (key) => localStorage.getItem(key);
export const setLSItem = (key, value) => localStorage.setItem(key, value);
export const removeLSItem = (key) => localStorage.removeItem(key);

export const getLSBuildDate = () => getLSItem('build_date');
export const getLSDeviceToken = () => JSON.parse(getLSItem('deviceToken'));
export const getLSFetchBuildDate = () => getLSItem('fetch_build_date');
export const getLSLanguage = () => getLSItem('LANG');
export const getLSMinimizedActivities = () => JSON.parse(getLSItem('minimized_activities') || '{}');
export const getLSRefreshIndexedDB = () => getLSItem('refreshIndexDb');
export const getLSReportsTableView = () => getLSItem('reports_table_view');
export const getLSPWAModalSeen = () => getLSItem('PWAModalSeen');

export const removeLSRefreshIndexedDB = () => removeLSItem('refreshIndexDb');

export const setLSBuildDate = (buildDate) => setLSItem('build_date', buildDate);
export const setLSDeviceToken = (token) => setLSItem('deviceToken', JSON.stringify(token));
export const setLSFetchBuildDate = (buildDate) => setLSItem('fetch_build_date', buildDate);
export const setLSLanguage = (lang) => setLSItem('LANG', lang);
export const setLSMinimizedActivities = (activities) => setLSItem('minimized_activities', activities);
export const setLSNewContentAvailability = (availability) => setLSItem('is-new-content-available', availability);
export const setLSReportsTableView = (newMode) => setLSItem('reports_table_view', newMode);
export const setLSPWAModalSeen = (value) => setLSItem('PWAModalSeen', value);

export const isNewContentAvailable = () => {
  return getLSItem('is-new-content-available') === 'true';
};
