import React, { Component } from 'react';
import T from 'prop-types';
// recompose
import { compose } from 'recompose';
// redux
import { connect } from 'react-redux';
import { enableEditMode } from 'reducers/barnsheets/barnsheetsGroupCheckups';
import { fetchData } from 'reducers/dataTable';
// hoc
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// components
import Panel from 'components/Panel';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import TableFilter from 'components/TableFilter';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import DataTable from 'components/DataTable/DataTable';
import ClosedIntegration from '../../ClosedIntegration/ClosedIntegration';
import { FormattedMessage } from 'react-intl';
import CustomColumn from 'components/DataTable/Columns/CustomColumn';
import { GroupStatusMetaFarms } from 'components/StatusBadge/StatusBadge';
import TruncateHint from 'components/TruncateHint';
// utils
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { isTablet } from 'react-device-detect';
import moment from 'moment';
import download from 'downloadjs';
// styles
import './PullResults.scss';
import repeat from '../../../../../public/images/repeat.svg';

const tableFilters = [
  { label: <FormattedMessage id="general.allGroups" />, value: '' },
  { label: <FormattedMessage id="general.errors" />, value: 'errors' },
];

const urlDownload = '/pull_results/download?direction=import&api=metafarms';

class PullResults extends Component {
  state = {
    isLoading: false,
  };

  componentDidMount() {
    this.renderRerunArray();
  }

  downloadCSV = (path, fileName) => (e) => {
    e.stopPropagation();
    return fetchFromAPI(path, { blob: true })
      .then((blob) => download(blob, fileName, 'text/csv'))
      .catch(toastResponseErrors);
  };

  renderFarmIdColumn = (farm) => {
    const { data: { pig_group_name } } = farm;
    return (
      <CustomColumn label={<FormattedMessage id="component.dataTable.headers.farmName" />}>
        <TruncateHint name={pig_group_name || 'N/A'} className="groupIdName" />
      </CustomColumn>
    );
  };

  renderFarmNameColumn = ({ data: { farm_name } }) => {
    return (
      <CustomColumn label={<FormattedMessage id="component.dataTable.headers.farmName" />}>
        <TruncateHint name={farm_name || 'N/A'} className="groupIdName" />
      </CustomColumn>
    );
  };

  renderDeathColumn = ({ data: { report_data } }) => (
    <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.deaths" />}>
      {report_data.death  ? report_data.death : 'N/A'}
    </CustomColumn>
  );

  renderEuthanasiaColumn = ({ data: { report_data } }) => (
    <CustomColumn textCenter label={<FormattedMessage id="component.dataTable.headers.deaths" />}>
      <div className="euthanasia">{report_data.euthanize ? report_data.euthanize : 'N/A'}</div>
    </CustomColumn>
  );

  renderRerunArray = () => {
    this.setState({ isLoading: true });
    const { reqParams, fetchData } = this.props;
    fetchData({ ...reqParams, sort: 'status asc' })
      .catch(toastResponseErrors)
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  renderPushResultColumn = ({ success }) => {
    return (
      <CustomColumn
        textCenter
        label={<FormattedMessage id="component.dataTable.headers.status" />}
        noBottomBorder
      >
        <div className="containerResult">
          <div className="itemResult">
            <GroupStatusMetaFarms
              status={success ? 'success' : 'error'}
            />
          </div>
          {!success && (
            <div>
              <button className="resultBtn" onClick={this.renderRerunArray}>
                <img className="resultImg" src={repeat} alt="repeat" />
              </button>
            </div>
          )}
        </div>
      </CustomColumn>
    );
  };

  downloadAll = () => {
    fetchFromAPI(urlDownload, { blob: true })
      .then((blob) => download(blob, 'Download', 'text/csv'))
      .catch(toastResponseErrors);
  };

  getUrlDownload = (success) => {
    return `${urlDownload}&success=${success ? 'true' : 'false'}`;
  };

  getNameDownload = (success) => {
    return success ? 'Successes' : 'Errors';
  };

  render() {
    const {
      reqParams: { page, per_page, search, sort, status },
      meta: { total, stats }, metaFarms, active,
      onSearchChange, onSortChange, onStatusChange, onPageChange, onPerPageChange
    } = this.props;
    const { isLoading } = this.state;
    const title = <FormattedMessage id="general.pageTitle.results" />;
    const datePush =  moment(Date.now()).format('MM/DD/YY HH:MM:ss GTM ZZ');
    const columns = [
      {
        label: <FormattedMessage id="component.dataTable.headers.groupId" />, flex: '1 1 130px',
        renderer: this.renderFarmIdColumn, sortKey: 'pig_group_name'
      },
      {
        label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '1 1 130px',
        renderer: this.renderFarmNameColumn, sortKey: 'farm_name'
      },
      {
        label: <FormattedMessage id="component.dataTable.headers.deaths" />, flex: '1 1 90px',
        renderer: this.renderDeathColumn, sortKey: 'death', textRight: true
      },
      {
        label: <FormattedMessage id="general.deaths.euthanasia" />, flex: '1 1 100px',
        renderer: this.renderEuthanasiaColumn, sortKey: 'euthanize', textCenter: true
      },
      {
        label: <FormattedMessage id="component.dataTable.headers.pullResult" />, flex: '1 1 110px',
        renderer: this.renderPushResultColumn, sortKey: 'status'
      },

    ];
    const labelButton = <FormattedMessage id="general.button.download" />;

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <>
        <Preloader isActive={isLoading} />
        {!isLoading && (
          <div className="small-12 column">
            {active ? (
              <div className="BarnsheetsGroups">
                <Panel className="mv-20">
                  <Panel.Heading renderTitle={() => (
                    <h2 className="lighter show-for-large">
                      {title} {datePush}
                    </h2>
                  )}
                  >
                    <SearchBox initialValue={search} onChange={onSearchChange} />
                  </Panel.Heading>
                  <Panel.Body noPadding>
                    <Preloader isActive={isLoading} />
                    <TableFilter
                      filters={tableFilters}
                      onFilterChange={onStatusChange}
                      activeFilter={status}
                      stats={stats}
                      className="mh-10"
                    >
                      <div className="containerButtons">
                        <DropdownButton
                          idKey="groups-dropdown"
                          ignoreScroll
                          label={labelButton}
                          buttonType="compact"
                          onClick={this.downloadAll}
                          dropDownData={
                            [{ label: <FormattedMessage id="general.download.successes" />,
                              onClick: this.downloadCSV(this.getUrlDownload(true),
                                this.getNameDownload(true)) },
                            { label: <FormattedMessage id="general.download.errors" />,
                              onClick: this.downloadCSV(this.getUrlDownload(false),
                                this.getNameDownload(false)) },
                            ]
                          }
                          customClass="show-for-large"
                          wide
                        />
                      </div>
                    </TableFilter>

                    <DataTable
                      data={metaFarms}
                      getRowClass={({ success }) => ({ 'pink': !success })}
                      columns={columns}
                      sort={sort}
                      onSortChange={onSortChange}
                      paginationProps={paginationProps}
                      isExpandable={isTablet}
                      scrollable
                      tableKey="metaFarms"
                    />
                  </Panel.Body>
                </Panel>
              </div>
            ) : <ClosedIntegration link="meta-farms" />}
          </div>
        )}
      </>
    );
  }
}

PullResults.propTypes = {
  reqParams: T.object.isRequired,
  meta: T.object.isRequired,
  fetchData: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onStatusChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  metaFarms: T.array,
  active: T.bool,
};

const enhance = compose(
  connect(
    (state) => ({
      isLoading: state.dataTable.metaFarms.isLoading,
      meta: state.dataTable.metaFarms.meta,
      reqParams: state.dataTable.metaFarms.params,
      isAdmin: state.auth.user.roles_map.admin,
      metaFarms: state.dataTable.metaFarms.resources,
      active: state.dataTable.metaFarms.meta.active,
    }), (dispatch) => ({
      fetchData: (query) => dispatch(fetchData('/pull_results?api=metafarms', 'metaFarms', query)),
      enableEditMode: (groupId) => dispatch(enableEditMode(groupId)),
    })
  ),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(PullResults);
