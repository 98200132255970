import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchProfile } from 'reducers/profiles';
import { FormattedMessage } from 'react-intl';
// components
import FarmProfileHeader from 'components/ProfileHeader/FarmProfileHeader/FarmProfileHeader';
import PigsUnderCareLine from 'components/PigsUnderCareLine';
import StickyHeader from 'components/StickyHeader';
import SubNavigation from 'components/Subnavigation';
// endpoints
import { getTreatmentProtocolsFarm } from 'endpoints/treatmentProtocols';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';

function isTreatmentProtocolPages(path) {
  const pathParts = path.split('/');
  return ['create', 'edit'].includes(pathParts[pathParts.length - 1]);
}

const TreatmentProtocolsFarm = ({
  farm,
  fetchProfile,
  children,
  isLoading,
  location: {
    pathname,
  },
  params: { farmId },
  router,
}) => {
  const { open_pig_groups_count, pigs_count, federal_premise_id } = farm;
  const isStickyHeaderAvailable = !isTreatmentProtocolPages(pathname);
  const isSubNabAvailable = isStickyHeaderAvailable && farm?.farm_type && farm.farm_type !== 'su';

  useEffect(() => {
    const fetchFarm = () => {
      return getTreatmentProtocolsFarm(farmId).catch((error) => {
        toastResponseErrors(error);
        router.push('/treatment-protocols-farms');
      });
    };
    fetchProfile('farm', fetchFarm);
  }, [farmId]);

  const subNavLinks = [
    {
      label: <FormattedMessage id="general.treatmentProtocols" />,
      link: `/treatment-protocols-farms/${farmId}/treatment-protocols`,
    },
    {
      label: <FormattedMessage id="general.pageTitle.groups" />,
      link: `/treatment-protocols-farms/${farmId}/groups`,
    },
  ];

  return (
    <div>
      <FarmProfileHeader
        className="show-for-large"
        farm={farm}
        isLoading={isLoading}
        actualFarmId={Number(farmId)}
        farmInfo={(
          <div className="color-gray-dark">
            <span>ID&nbsp;&bull;&nbsp;{federal_premise_id || 'N/A'}</span>
          </div>
        )}
        stats={[
          {
            label: <FormattedMessage id="general.openGroups" />,
            value: open_pig_groups_count || 0,
          },
          {
            label: <FormattedMessage id="general.puc" />,
            value: pigs_count || 0,
          },
        ]}
      />
      {isStickyHeaderAvailable && (
        <StickyHeader className="hide-for-large">
          <PigsUnderCareLine mobile pigsCount={farm?.pigs_count || 0} />
        </StickyHeader>
      )}
      {isSubNabAvailable && (
        <SubNavigation isSticky links={subNavLinks} className="small-12 column" />
      )}
      {children}
    </div>
  );
};

TreatmentProtocolsFarm.propTypes = {
  farm: T.object.isRequired,
  fetchProfile: T.func.isRequired,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
  isLoading: T.bool.isRequired,
  params: T.object.isRequired,
  location: T.object.isRequired,
  router: T.object.isRequired,
};

export default connect(
  (state) => ({
    farm: state.profiles.farm.data,
    isLoading: state.profiles.farm.isLoading,
  }), { fetchProfile }
)(TreatmentProtocolsFarm);
