import React from 'react';
import { toastr } from 'react-redux-toastr';
import { FormattedMessage } from 'react-intl';
import { getAuthData } from 'utils/authData';

export const getBaseResumableSettings = (types) => ({
  headers: { 'Accept': '*/*', ...getAuthData() },
  simultaneousUploads: 1,
  testChunks: true,
  chunkRetryInterval: 500,
  maxFileSize: 314572800,
  fileTypeErrorCallback: (file) => {
    toastr.error('', '', {
      icon: <i className="fa fa-ban" />,
      className: 'file-type-error',
      component: (
        <FormattedMessage
          id="component.toastr.availFileTypes.text"
          values={{ filename: file.name, types: types.join(', ') }}
        >
          {(text) => <div className="rrt-text">{text}</div>}
        </FormattedMessage>
      ),
    });
  },
  maxFileSizeErrorCallback: (file) => {
    toastr.error('', '', {
      icon: <i className="fa fa-ban" />,
      component: (
        <FormattedMessage id="general.fileSizeExceeded" values={{ size: '300mb', filename: file.name }}>
          {(text) => (<div className="rrt-text">{text}</div>)}
        </FormattedMessage>
      ),
    });
  },
});
