import { handleActions, createAction } from 'redux-actions';
import { fetchFarmsSVRs, deleteFarmSVR } from 'endpoints/svr';

// ------------------------------------
// Constants
// ------------------------------------

const defaultParams = {
  page: 1,
  per_page: 10,
};

const initialState = {
  draft: {
    isLoading: false,
    resources: [],
    meta: {
      total: 0,
    },
    params: defaultParams,
  },
  active: {
    isLoading: false,
    resources: [],
    meta: {
      total: 0
    },
    params: defaultParams,
  },

};

// ------------------------------------
// Action Types
// ------------------------------------

const FETCH_ACTIVE_SITE_VISIT_REPORTS = 'siteVisitReports/FETCH_ACTIVE_SITE_VISIT_REPORTS';
const [
  FETCH_ACTIVE_SITE_VISIT_REPORTS_PENDING,
  FETCH_ACTIVE_SITE_VISIT_REPORTS_FULFILLED,
  FETCH_ACTIVE_SITE_VISIT_REPORTS_REJECTED
] = [
  `${FETCH_ACTIVE_SITE_VISIT_REPORTS}_PENDING`,
  `${FETCH_ACTIVE_SITE_VISIT_REPORTS}_FULFILLED`,
  `${FETCH_ACTIVE_SITE_VISIT_REPORTS}_REJECTED`
];

const FETCH_DRAFT_SITE_VISIT_REPORTS = 'siteVisitReports/FETCH_DRAFT_SITE_VISIT_REPORTS';
const [
  FETCH_DRAFT_SITE_VISIT_REPORTS_PENDING,
  FETCH_DRAFT_SITE_VISIT_REPORTS_FULFILLED,
  FETCH_DRAFT_SITE_VISIT_REPORTS_REJECTED
] = [
  `${FETCH_DRAFT_SITE_VISIT_REPORTS}_PENDING`,
  `${FETCH_DRAFT_SITE_VISIT_REPORTS}_FULFILLED`,
  `${FETCH_DRAFT_SITE_VISIT_REPORTS}_REJECTED`
];

const DELETE_DRAFT_REPORT = 'siteVisitReports/DELETE_DRAFT_REPORT';
const [DELETE_DRAFT_REPORT_PENDING, DELETE_DRAFT_REPORT_FULFILLED, DELETE_DRAFT_REPORT_REJECTED] = [
  `${DELETE_DRAFT_REPORT}_PENDING`,
  `${DELETE_DRAFT_REPORT}_FULFILLED`,
  `${DELETE_DRAFT_REPORT}_REJECTED`
];

const RESET_DATA = 'siteVisitReports/RESET_DATA';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchActiveSiteVisitReports = (farmId, params = defaultParams) => (dispatch, getState) => {
  dispatch(createAction(FETCH_ACTIVE_SITE_VISIT_REPORTS_PENDING)());
  const currentParams = getState().svr.siteVisitReports.active.params;
  return fetchFarmsSVRs(parseInt(farmId, 10), { ...currentParams, ...params, draft: false })
    .then((resources) => {
      dispatch(
        createAction(FETCH_ACTIVE_SITE_VISIT_REPORTS_FULFILLED)({
          ...resources,
          params: { ...currentParams, ...params }
        })
      );
      return resources;
    })
    .catch((error) => {
      dispatch(createAction(FETCH_ACTIVE_SITE_VISIT_REPORTS_REJECTED)(error));
      throw error;
    });
};

export const fetchDraftSiteVisitReports = (farmId, params = defaultParams) => (dispatch) => {
  dispatch(createAction(FETCH_DRAFT_SITE_VISIT_REPORTS_PENDING)());
  return fetchFarmsSVRs(+farmId, { ...params, draft: true })
    .then((response) => {
      dispatch(createAction(FETCH_DRAFT_SITE_VISIT_REPORTS_FULFILLED)({ ...response, params }));
      return response;
    })
    .catch((error) => {
      dispatch(createAction(FETCH_DRAFT_SITE_VISIT_REPORTS_REJECTED)());
      throw error;
    });
};

export const deleteDraftSiteVisitReport = (farmId, svrId) => (dispatch, getState) => {
  const { draft } = getState().svr.siteVisitReports;
  dispatch(createAction(DELETE_DRAFT_REPORT_PENDING)());
  return deleteFarmSVR(farmId, svrId)
    .then(() => {
      if (draft.resources.length === draft.meta.total) {
        dispatch(createAction(DELETE_DRAFT_REPORT_FULFILLED)({ svrId }));
      } else {
        dispatch(fetchDraftSiteVisitReports(farmId, { ...draft.params, page: 1 }));
      }
    })
    .catch((error) => {
      dispatch(createAction(DELETE_DRAFT_REPORT_REJECTED)());
      throw error;
    });
};

export const resetData = createAction(RESET_DATA);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [FETCH_ACTIVE_SITE_VISIT_REPORTS_PENDING]: (state) => ({
    ...state,
    active: {
      ...state.active,
      isLoading: true
    },
  }),
  [FETCH_ACTIVE_SITE_VISIT_REPORTS_FULFILLED]: (state, action) => ({
    ...state,
    active: {
      isLoading: false,
      ...action.payload,
    },
  }),
  [FETCH_ACTIVE_SITE_VISIT_REPORTS_REJECTED]: (state, action) => ({
    ...state,
    active: {
      isLoading: false,
      ...state.active,
      ...action.payload,
    },
  }),
  [FETCH_DRAFT_SITE_VISIT_REPORTS_PENDING]: (state) => ({
    ...state,
    draft: {
      ...state.draft,
      isLoading: true,
    }
  }),
  [FETCH_DRAFT_SITE_VISIT_REPORTS_FULFILLED]: (state, { payload }) => ({
    ...state,
    draft: {
      ...state.draft,
      isLoading: false,
      meta: payload.meta,
      params: payload.params,
      resources: payload.params.page === 1 ? payload.resources : state.draft.resources.concat(payload.resources),
    },
    isDraftLoading: false,
  }),
  [FETCH_DRAFT_SITE_VISIT_REPORTS_REJECTED]: (state) => ({
    ...state,
    draft: {
      ...state.draft,
      isLoading: false,
    },
  }),
  [DELETE_DRAFT_REPORT_PENDING]: (state) => ({
    ...state,
    draft: {
      ...state.draft,
      isLoading: true,
    }
  }),
  [DELETE_DRAFT_REPORT_REJECTED]: (state) => ({
    ...state,
    draft: {
      ...state.draft,
      isLoading: false,
    }
  }),
  [DELETE_DRAFT_REPORT_FULFILLED]: (state, action) => {
    const { svrId } = action.payload;
    return {
      ...state,
      draft: {
        ...state.draft,
        resources: state.draft.resources.filter(({ id }) => id !== svrId),
        meta: {
          total: state.draft.meta.total - 1,
        },
        isLoading: false,
      }
    };
  },
  [RESET_DATA]: () => ({
    ...initialState,
  }),
  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
