import { handleActions, createAction } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------
const initialState = {
  isOnline: true,
  currentParams: {},
  progress: 0,
  syncingData: '',
  isForceSync: false,
};

const SET_NETWORK_CONNECT = 'network/SET_NETWORK_CONNECT';
const SET_PROGRESS_SYNC = 'network/SET_PROGRESS_SYNC';
const SET_FORCE_SYNC = 'network/SET_FORCE_SYNC';

// ------------------------------------
// Actions
// ------------------------------------
export const setNetworkConnect = createAction(SET_NETWORK_CONNECT);
export const setProgressSync = createAction(SET_PROGRESS_SYNC);
export const setForceSync = createAction(SET_FORCE_SYNC);

// ------------------------------------
// Action Creators
// ------------------------------------

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [SET_NETWORK_CONNECT]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [SET_PROGRESS_SYNC]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [SET_FORCE_SYNC]: (state, action) => ({
    ...state,
    isForceSync: action.payload,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
