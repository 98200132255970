import React, { useState } from 'react';
import T from 'prop-types';
// components
import Modal from 'components/Modal';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import CircleCheckbox from 'components/CircleCheckbox';
import DateRangePicker from 'components/DatePicker/DateRangePicker/DateRangePicker';
import SingleDatePicker from 'components/DatePicker/SingleDatePicker/SingleDatePicker';
// redux
import { connect } from 'react-redux';
import { closeModal } from 'reducers/modals';
// utils
import classnames from 'classnames/bind';
import moment from 'moment';
// styles
import styles from './PigGroupsExportModal.module.scss';

const cn = classnames.bind(styles);

const exportTypes = {
  default: 'default',
  dateRange: 'dateRange',
  oneDay: 'oneDay',
};

const PigGroupsExportModal = ({ closeModal, onSubmit, title }) => {
  const [exportType, setExportType] = useState(exportTypes.default);
  const [dateRange, setDateRange] = useState({ startDate: undefined, endDate: undefined });

  const handleExportTypeChange = (type) => {
    setExportType(type);
  };

  const handleDateChange = (date) => {
    if (exportType === exportTypes.oneDay) {
      setDateRange({ startDate: date, endDate: date });
    }
  };

  const handleSubmit = () => {
    if (exportType === exportTypes.default) {
      onSubmit();
    } else {
      onSubmit({
        date_start: dateRange.startDate.format('YYYY-MM-DD'),
        date_end: dateRange.endDate.format('YYYY-MM-DD'),
      });
    }
    closeModal();
  };

  const isExportButtonDisabled = () => {
    return (exportType === exportTypes.dateRange || exportType === exportTypes.oneDay) &&
      (!dateRange.startDate || !dateRange.endDate);
  };

  return (
    <Modal title={title}>
      <section className="modal-body">
        {/* DEFAULT EXPORT */}
        <div className="mb-10">
          <CircleCheckbox
            label={(
              <span className="semibold">
                <FormattedMessage id="general.defaultExport" />
              </span>
            )}
            checked={exportType === exportTypes.default}
            onChange={({ target: { checked } }) => handleExportTypeChange(exportTypes.default, checked)}
          />
        </div>
        {/* DATE RANGE EXPORT */}
        <div className="mb-10">
          <CircleCheckbox
            label={(
              <span className="semibold">
                <FormattedMessage id="general.exportByPeriod" />
              </span>
            )}
            checked={exportType === exportTypes.dateRange}
            onChange={({ target: { checked } }) => handleExportTypeChange(exportTypes.dateRange, checked)}
          />
          {exportType === exportTypes.dateRange && (
            <DateRangePicker
              className="mt-10"
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              onDatesChange={setDateRange}
              withoutOutsideClickHandler
              isOutsideRange={(day) => !day.isBefore(moment(), 'day')}
            />
          )}
        </div>
        {/* ONE DAY EXPORT */}
        <div className="mb-10">
          <CircleCheckbox
            label={(
              <span className="semibold">
                <FormattedMessage id="general.exportByDay" />
              </span>
            )}
            checked={exportType === exportTypes.oneDay}
            onChange={({ target: { checked } }) => handleExportTypeChange(exportTypes.oneDay, checked)}
          />
          {exportType === exportTypes.oneDay && (
            <div className="mt-10">
              <SingleDatePicker
                className={cn('sd-picker')}
                date={dateRange.startDate}
                onDateChange={handleDateChange}
                withoutOutsideClickHandler
                isOutsideRange={(day) => !day.isBefore(moment(), 'day')}
              />
            </div>
          )}
        </div>
      </section>

      <section className="modal-footer text-right sticky-on-mobile">
        <Button onClick={closeModal}>
          <FormattedMessage id="general.button.cancel" />
        </Button>
        <Button disabled={isExportButtonDisabled()} className="ml-15" primary onClick={handleSubmit}>
          <FormattedMessage id="general.csvImport.results.export" />
        </Button>
      </section>
    </Modal>
  );
};

PigGroupsExportModal.propTypes = {
  closeModal: T.func.isRequired,
  onSubmit: T.func.isRequired,
  title: T.oneOfType([T.string, T.object]).isRequired,
};

export default connect(null, { closeModal })(PigGroupsExportModal);
