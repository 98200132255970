import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './MediaFilesUploader.module.scss';

const cn = classnames.bind(styles);

const UploadPlaceholder = () => (
  <div className={cn('upload-placeholder')}>
    <i className={cn('fa fa-cloud-upload-ios', 'placeholder-icon')} />
    <FormattedMessage
      id="component.mediaUploader.dropFilesOrBrowse"
      values={{
        textLink: (
          <span id="resumable-browse-button" className={cn('upload-placeholder__browse-btn')}>
            <FormattedMessage id="component.mediaUploader.browse" />
          </span>
        ),
      }}
    />
  </div>
);

export default UploadPlaceholder;
