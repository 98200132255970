import React from 'react';
import T from 'prop-types';
// components
import FarmTypeLabel from 'components/FarmTypeLabel';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import DotSeparator from 'components/DotSeparator/DotSeparator';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './TreatmentProtocolsItemRow.scss';

const cn = classnames.bind(styles);

const getStatusIcon = (status) => {
  switch (status) {
    case 'active':
      return <div className={cn('red-marker')} />;
    case 'disabled':
      return <div className={cn('fa fa-lock', 'status-icon')} />;
    default:
      return null;
  }
};

const TreatmentProtocolsFarmRow = ({ farm, onSelect }) =>  (
  <div className={cn('treatment-protocols-farm-row')}>
    <div className={cn('icon-section')}>
      {getStatusIcon(farm.status)}
    </div>
    <div className={cn('name-section')}>
      <span className={cn('farm-name')}>
        {farm.name}
        <FarmTypeLabel className="ml-10" farmType={farm.farm_type} />
      </span>
    </div>
    <div className={cn('stats-section')}>
      <div className={cn('stats-item')}>
        <FormattedMessage id="general.status.active" />
        <DotSeparator />
        <span className={cn('count-badge')}>
          {farm.active_tp_count}
        </span>
      </div>
      <div className={cn('stats-item')}>
        <FormattedMessage id="general.group" />
        <DotSeparator />
        <span className={cn('count-badge')}>
          {farm.active_pg_tp_count}
        </span>
      </div>
      <div className={cn('vertical-divider')} />
      <div className={cn('stats-item')}>
        <FormattedMessage id="general.status.disabled" />
        <DotSeparator />
        <span className={cn('count-badge', 'inactive')}>
          {farm.disabled_tp_count}
        </span>
      </div>
    </div>
    <div className={cn('action-section')}>
      <Button onClick={onSelect} className={cn('select-btn')} primary>
        <FormattedMessage id="general.button.select" />
      </Button>
    </div>
  </div>
);

TreatmentProtocolsFarmRow.propTypes = {
  farm: T.object.isRequired,
  onSelect: T.func.isRequired,
};

export default TreatmentProtocolsFarmRow;
