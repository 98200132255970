import React, { memo } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ActivityAssets from '../ActivityAssets/ActivityAssets';
import ActivityActionsBar from '../ActivityActionsBar/ActivityActionsBar';
import ActivityAuthorLine from '../ActivityAuthorLine/ActivityAuthorLine';
import ActivityBodyCollapse from '../ActivityBodyCollapse';
import ActivityBox from '../ActivityBox';
import ActivityHeader from '../ActivityHeader';
import FarmFeedRowComments from 'components/FarmFeed/FarmFeedRowComments';
import FarmFeedRowAddComment from 'components/FarmFeed/FarmFeedRowAddComment';
import LastCommentInfo from '../LastCommentInfo/LastCommentInfo';
import Line from 'components/Line';
import Translation from 'components/Translation';
import ActivityReportBox from './ActivityReportBox';
import ReportActivityCategoryCollapse from './ReportActivityCategoryCollapse';
import CategoryCounterStatus from 'containers/Report/components/CategoryCounterStatus';
// redux
import { connect } from 'react-redux';
import { openViewer } from 'reducers/mediaViewer';
// hooks
import useActivityController from 'hooks/useActivityController';
// utils
import classnames from 'classnames/bind';
import { isEqualKeys, renderMentionsText } from 'utils';
import { parseReportResponse } from 'utils/SVRHelper';
// styles
import styles from './ReportIssueActivity.module.scss';

const cn = classnames.bind(styles);

const affectRerenderKeys = ['id', 'updated_at', 'is_viewed', 'flagged', 'comments'];

const ReportIssueActivity = (props) => {
  const { defaultDropdownOptions, isMinimized, onToggleMinimized } = useActivityController(props);
  const {
    activity,
    activity: { svr, farm, id, flagged, is_viewed, user },
    className,
    currentUser,
    isChildActivity,
    markAsSeen,
    openDropdown,
    openViewer,
    setActivityFlag,
  } = props;
  const { categories, svr_assets, general_notes, action_plan } = parseReportResponse(svr);

  const handleOpenDropdown = (e) => {
    const options = [
      ...defaultDropdownOptions,
      {
        label: <FormattedMessage id="general.button.viewFarmBarnsheetsHistory" />, url: `/barnsheets/farms/${farm.id}`,
        icon: 'ulist'
      },
      {
        label: <FormattedMessage id="general.viewReport" />,
        url:
          `/report-farms/${svr.farm_id}/report-templates/${svr.svr_template_id}/reports/${svr.id}/review?from=farmfeed`,
        icon: 'merge'
      },
    ];
    openDropdown(e, options);
  };

  const getFormattedReportCategories = () => {
    // categories with failed or commented questions
    const importantCategories = [];
    // categories without failed or commented questions
    const regularCategories = [];

    categories.forEach((category) => {
      const stats = { failedAnswersCounter: 0, passedAnswersCounter: 0, notAvailableAnswersCounter: 0 };
      const importantQuestions = [];
      category.svr_questions.forEach((question) => {
        if (question.answer.value === 'fail') stats.failedAnswersCounter++;
        if (question.answer.value === 'pass') stats.passedAnswersCounter++;
        if (question.answer.value === 'not_available') stats.notAvailableAnswersCounter++;

        if (!!question.answer.comments.length || question.answer.value === 'fail') {
          importantQuestions.push(question);
        }
      });

      if (importantQuestions.length) {
        importantCategories.push({ ...category, svr_questions: importantQuestions, stats });
      } else {
        regularCategories.push({ ...category, stats });
      }

    });

    return { importantCategories, regularCategories };
  };

  const { importantCategories, regularCategories } = getFormattedReportCategories();

  return (
    <ActivityBox
      hasMarker={!isChildActivity}
      className={className}
      isFlagged={flagged}
      isUnseen={!is_viewed}
      markAsSeen={markAsSeen}
    >
      {!isMinimized && <LastCommentInfo activity={activity} currentUser={currentUser} />}
      <ActivityHeader activityId={id} onOpenDropdown={handleOpenDropdown} onDoubleClick={onToggleMinimized}>
        <ActivityAuthorLine
          activity={activity}
          currentUser={currentUser}
          minimized={isMinimized}
        >
          <FormattedMessage
            tagName="div"
            id="container.farmfeed.row.reason.submittedReport"
            values={{
              ownPost: currentUser.id === user.id ? 'yes' : 'no',
              name: user.full_name,
              template_name: svr.svr_template?.name,
              b: (msg) => <b>{msg}</b>,
            }}
          />
        </ActivityAuthorLine>
      </ActivityHeader>
      <ActivityBodyCollapse isClosed={isMinimized}>
        <ActivityReportBox
          isAdmin={currentUser.roles_map.admin}
          className={cn('extended-checkup-box', { 'mb-10': isChildActivity })}
          activity={activity}
        >
          {/* IMPORTANT CATEGORIES (FAILED/COMMENTED QUESTIONS) */}
          <div className="mb-20">
            {importantCategories.map((category, index) => (
              <ReportActivityCategoryCollapse
                questions={category.svr_questions}
                categoryStats={category.stats}
                className={cn('svr-collapse-row')}
                categoryName={category.name}
                key={index}
              />
            ))}
          </div>

          {/* REGULAR QUESTIONS */}
          <div className="mb-20">
            {regularCategories.map(({ name_key, name, stats }, idx) => (
              <div className={cn('svr-category-row')} key={idx}>
                <div className={cn('svr-header')}>
                  <div className={cn('category-name')}>
                    {name_key ? <FormattedMessage id={name_key} /> : name}
                  </div>
                  <div className={cn('counter-badges')}>
                    <CategoryCounterStatus small count={stats.passedAnswersCounter} type="pass" />
                    <CategoryCounterStatus small count={stats.failedAnswersCounter} type="fail" />
                    <CategoryCounterStatus small count={stats.notAvailableAnswersCounter} type="not_available" />
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* NOTES SECTIONS */}
          {!!action_plan && (
            <div className={styles['note-section']}>
              <Line isHeaderLine bolded>
                <FormattedMessage id="general.actionPlan" />
              </Line>
              <Line lightYellow>
                <Translation text={action_plan}>
                  {renderMentionsText(action_plan)}
                </Translation>
              </Line>
            </div>
          )}

          {!!general_notes && (
            <div className={styles['note-section']}>
              <Line isHeaderLine bolded>
                <FormattedMessage id="general.generalNotes" />
              </Line>
              <Line lightYellow>
                <Translation text={general_notes}>
                  {renderMentionsText(general_notes)}
                </Translation>
              </Line>
            </div>
          )}

          {!!svr_assets.length && (
            <ActivityAssets
              assets={svr_assets}
              activity={activity}
              onAssetPreview={(...args) => openViewer(...args, 'svr-issue')}
            />
          )}
        </ActivityReportBox>

        {!isChildActivity && (
          <>
            <ActivityActionsBar
              activity={activity}
              currentUser={currentUser}
              openDropdown={openDropdown}
              setActivityFlag={setActivityFlag}
            />
            <FarmFeedRowComments activity={activity} />
            <FarmFeedRowAddComment activity={activity} />
          </>
        )}
      </ActivityBodyCollapse>
    </ActivityBox>
  );
};

ReportIssueActivity.propTypes = {
  className: T.string,
  activity: T.object.isRequired,
  currentUser: T.object.isRequired,
  markAsSeen: T.func,
  openDropdown: T.func.isRequired,
  setActivityFlag: T.func.isRequired,
  isChildActivity: T.bool.isRequired,
  openViewer: T.func.isRequired,
};

export default memo(connect(null, { openViewer })(ReportIssueActivity), (prevProps, nextProps) => {
  return isEqualKeys(affectRerenderKeys, prevProps.activity, nextProps.activity);
});
