import pickBy from 'lodash.pickby';
import pick from 'lodash.pick';

export const notifiedAboutSettings = [
  { valueKey: 'ep_health_alerts', textKey: 'healthAlerts' },
  { valueKey: 'ep_ff_comments', textKey: 'comments' },
  { valueKey: 'ep_mentions', textKey: 'mentions' },
  { valueKey: 'ep_group_events', textKey: 'groupEvents' },
  { valueKey: 'ep_new_pig_group_alerts', textKey: 'groupCreation' },
  { valueKey: 'ep_diagnosis_alerts', textKey: 'diagnoses' },
  { valueKey: 'ep_farm_compliance', textKey: 'farmCompliance' },
  { valueKey: 'ep_personal_progress', textKey: 'progress' },
  { valueKey: 'ep_birthday_event', textKey: 'birthdayEvent' },
  { valueKey: 'ep_encouragements', textKey: 'userEncouragement' }, // get prop name
  { valueKey: 'ep_tenant_resources', textKey: 'tenantResources' },
  { valueKey: 'ep_lowest_mortality_event', textKey: 'closeoutCongrats' },
  { valueKey: 'ep_loading_events', textKey: 'loadingEvents', type: 'logistics' },
  { valueKey: 'ep_sales_result_events', textKey: 'salesResult', type: 'logistics' },
  { valueKey: 'ep_svr_events', textKey: 'reports', type: 'svr' },
  { valueKey: 'ep_treatment_protocol_events', textKey: 'treatmentProtocols', type: 'treatmentProtocols' },
];

export const emailSettings = [
  { valueKey: 'email_health_alerts', textKey: 'healthAlerts' },
  { valueKey: 'email_mentions', textKey: 'mentions' },
  { valueKey: 'email_ff_comments', textKey: 'comments' },
  { valueKey: 'email_new_pig_group_alerts', textKey: 'groupCreation' },
  { valueKey: 'email_diagnosis_alerts', textKey: 'diagnoses' },
  { valueKey: 'email_farm_compliance', textKey: 'farmCompliance' },
  { valueKey: 'email_personal_progress', textKey: 'progress' },
  { valueKey: 'email_ep_updates', textKey: 'ep_updates' },
  { valueKey: 'email_group_events', textKey: 'groupEvents' },
  { valueKey: 'email_loading_events', textKey: 'emailLoadingEvents', type: 'logistics' },
  { valueKey: 'email_sales_result_events', textKey: 'salesResult', type: 'logistics' },
  { valueKey: 'email_svr_events', textKey: 'reports', type: 'svr' },
  { valueKey: 'email_treatment_protocol_events', textKey: 'treatmentProtocols', type: 'treatmentProtocols' },
];

export const smsSettings = [
  { valueKey: 'sms_health_alerts', textKey: 'healthAlerts' },
  { valueKey: 'sms_ff_comments', textKey: 'comments' },
  { valueKey: 'sms_mentions', textKey: 'mentions' },
  { valueKey: 'sms_group_events', textKey: 'groupEvents' },
  { valueKey: 'sms_new_pig_group_alerts', textKey: 'groupCreation' },
  { valueKey: 'sms_diagnosis_alerts', textKey: 'diagnoses' },
  { valueKey: 'sms_farm_compliance', textKey: 'farmCompliance' },
  { valueKey: 'sms_loading_events', textKey: 'loadingEvents', type: 'logistics' },
  { valueKey: 'sms_sales_result_events', textKey: 'salesResult', type: 'logistics' },
  { valueKey: 'sms_svr_events', textKey: 'reports', type: 'svr' },
  { valueKey: 'sms_treatment_protocol_events', textKey: 'treatmentProtocols', type: 'treatmentProtocols' },
];

export const pushSettings = [
  { valueKey: 'push_health_alerts', textKey: 'healthAlerts' },
  { valueKey: 'push_ff_comments', textKey: 'comments' },
  { valueKey: 'push_mentions', textKey: 'mentions' },
  { valueKey: 'push_group_events', textKey: 'groupEvents' },
  { valueKey: 'push_new_pig_group_alerts', textKey: 'groupCreation' },
  { valueKey: 'push_diagnosis_alerts', textKey: 'diagnoses' },
  { valueKey: 'push_farm_compliance', textKey: 'farmCompliance' },
  { valueKey: 'push_birthday_event', textKey: 'birthdayEvent' },
  { valueKey: 'push_encouragements', textKey: 'userEncouragement' },
  { valueKey: 'push_tenant_resources', textKey: 'tenantResources' },
  { valueKey: 'push_lowest_mortality_event', textKey: 'closeoutCongrats' },
  { valueKey: 'push_loading_events', textKey: 'loadingEvents', type: 'logistics' },
  { valueKey: 'push_sales_result_events', textKey: 'salesResult', type: 'logistics' },
  { valueKey: 'push_svr_events', textKey: 'reports', type: 'svr' },
  { valueKey: 'push_treatment_protocol_events', textKey: 'treatmentProtocols', type: 'treatmentProtocols' },
];

export const messagingSettingsKeys = notifiedAboutSettings.map((item) => item.valueKey);
export const emailSettingsKeys = emailSettings.map((item) => item.valueKey);
export const smsSettingsKeys = smsSettings.map((item) => item.valueKey);
export const pushSettingsKeys = pushSettings.map((item) => item.valueKey);

export const tabsSubtitleKeys = [
  { key: 'messagingTabSubtitleKey', data: messagingSettingsKeys },
  { key: 'emailTabSubtitleKey', data: emailSettingsKeys },
  { key: 'deviceTabSubtitleKey', data: pushSettingsKeys },
  { key: 'smsTabSubtitleKey', data: smsSettingsKeys },
];

export function findSubtitleTitleKey(setting, keys) {
  const dataChecked = pickBy(pick(setting, keys), (item) => (!!item));
  const settingsLength = keys.length;
  const checkedCount = Object.keys(dataChecked).length;

  if (!checkedCount || !settingsLength) return 'notificationsOff';
  if (checkedCount === settingsLength) return 'allNotificationsOn';
  if ((settingsLength / 2) > checkedCount) return 'someNotificationsOn';
  return 'mostNotificationsOn';
}
