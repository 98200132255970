import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Translation from 'components/Translation';
import Collapse from 'components/Collapse/Collapse';
import Line from 'components/Line';
import CollapseTitle from './CollapseTitle';
import FormattedTemperature from 'components/FormattedTemperature';
// utils
import { renderMentionsText } from 'utils';
import classnames from 'classnames/bind';
// styles
import styles from './SectionCollapse.module.scss';

const cn = classnames.bind(styles);

const temperatureLines = [
  { valueKey: 'high_temperature', label: <FormattedMessage id="general.high" /> },
  { valueKey: 'low_temperature', label: <FormattedMessage id="general.low" /> },
];

const TemperaturesCollapse = ({ className, comment, dailyCheckup, isEdited, showCheckup, titleClassName }) => {
  const [isOpened, setIsOpened] = useState(false);
  const hasComment = Boolean(comment);

  if (!hasComment && (dailyCheckup.high_temperature === null) && (dailyCheckup.low_temperature === null)) return null;

  const { edge_temperature, dicam_temperature, barn_tools_temperature, distynct_temperature } = dailyCheckup;

  return ((hasComment || showCheckup) && (
    <Collapse
      className={cn(className, 'section-collapse', { 'highlighted': comment && !isOpened })}
      titleClassName={titleClassName}
      title={(
        <CollapseTitle
          hasEditedBadge={isEdited}
          hasNotesBadge={hasComment}
          hasEdgeBadge={edge_temperature}
          hasDicamBadge={dicam_temperature}
          hasBarnToolBadge={barn_tools_temperature}
          hasDistynctBadge={distynct_temperature}
          hasTriggerIcon={hasComment}
        >
          <FormattedMessage tagName="b" id="general.highLowTemps" />
        </CollapseTitle>
      )}
      onToggle={setIsOpened}
    >
      {temperatureLines.map(({ valueKey, label }, index) => (
        <Line
          key={valueKey}
          boldedValue
          hasTopBorder={!index}
          label={label}
          value={<FormattedTemperature value={dailyCheckup[valueKey]} />}
        />
      ))}
      {comment && (
        <>
          <Line almostWhite bolded>
            <FormattedMessage id="general.notes" />
          </Line>
          <Line lightYellow>
            <Translation text={comment}>{renderMentionsText(comment)}</Translation>
          </Line>
        </>
      )}
    </Collapse>
  ));
};

TemperaturesCollapse.propTypes = {
  className: T.string,
  titleClassName: T.string,
  dailyCheckup: T.object.isRequired,
  comment: T.string,
  isEdited: T.bool,
  showCheckup: T.bool,
};

export default TemperaturesCollapse;
