import React from 'react';
// components
import { FormattedMessage } from 'react-intl';

export const footerItems = [
  {
    id: 'dashboard',
    name: <FormattedMessage id="general.pageTitle.farmfeed" />,
    icon: 'fa fa-newspaper-o',
    link: '/farmfeed',
  },
  {
    id: 'mySchedule',
    name: <FormattedMessage id="general.schedule" />,
    icon: 'fa fa-calendar',
    link: '/my-schedule',
  },
  {
    id: 'virtualVisits',
    name: <FormattedMessage id="general.pageTitle.virtualVisits" />,
    label: <FormattedMessage id="general.pageTitle.virtualVisits" />,
    icon: 'fa fa-camera',
    link: '/virtual-visits',
  },
  {
    id: 'checkin',
    name: <FormattedMessage id="general.checkin" />,
    label: <FormattedMessage id="general.checkinToAFarm" />,
    icon: 'fa fa-map-marker',
    link: '/checkins/new',
  },
  {
    id: 'barnsheets',
    name: <FormattedMessage id="general.pageTitle.barnsheets" />,
    label: <FormattedMessage id="general.pageTitle.barnsheets" />,
    icon: 'fa fa-ulist',
    link: '/barnsheets',
  },
  {
    id: 'svr',
    name: <FormattedMessage id="general.report" />,
    label: <FormattedMessage id="general.reports" />,
    icon: 'fa fa-ep-doc',
    link: '/report-farms',
  },
  {
    id: 'treatmentProtocols',
    name: <FormattedMessage id="general.treatmentProtocols" />,
    label: <FormattedMessage id="general.treatmentProtocols" />,
    icon: 'fa fa-injectable',
    link: '/treatment-protocols',
  },
  {
    id: 'checkup',
    name: <FormattedMessage id="general.checkup" />,
    label: <FormattedMessage id="general.dailyCheckup" />,
    icon: 'fa fa-edit',
    link: '/daily-checkup',
  },
  {
    id: 'notifications',
    name: <FormattedMessage id="general.pageTitle.notifications" />,
    icon: 'fa fa-bell',
    link: '/notifications',
  },
  {
    id: 'more',
    name: <FormattedMessage id="general.pageTitle.more" />,
    icon: 'fa fa-dots-three-horizontal',
    isMore: true,
  },
];
