import React from 'react';
// components
import { Field } from 'redux-form';
import ResponsiveSelect from '../ResponsiveSelect';
import CircleCheckbox from '../CircleCheckbox';
import MessageBox from '../MessageBox';
// styles
import styles from './TenantStatusRow.module.scss';
// constants
import { tenantExpirationOptions } from '../../constants';

const TenantStatusRow = () => (
  <div className={styles['tenant-status-row']}>
    <label className="semibold">Tenant Status</label>
    <ul>
      <li className="mt-15">
        <Field
          name="status"
          type="radio"
          value="trial"
          component={({ input }) => (
            <CircleCheckbox
              type="radio"
              className="trial-period-checkbox"
              label={<span className="semibold">Free Trial</span>}
              {...input}
            />
          )}
        />
        <div className={styles.description}>Tenant has full access to EveryPig for the selected trial Period.</div>
      </li>
      <li className={styles['time-periods']}>
        <div className="semibold">Trial Period</div>
        <Field
          name="expires_at"
          type="text"
          className={styles['expires-days-select']}
          options={tenantExpirationOptions}
          noInputMargin
          grey
          component={ResponsiveSelect}
        />
      </li>
      <li className="mt-10">
        <Field
          name="status"
          type="radio"
          value="only_logistics"
          component={({ input }) => (
            <CircleCheckbox
              type="radio"
              className={styles['trial-period-checkbox']}
              label={<span className="semibold">Scheduling Tariff</span>}
              {...input}
            />
          )}
        />
        <div className={styles.description}>Tenant has access to Scheduling.</div>
        <MessageBox className={styles['info-box']} type="info">
          All features except Logistics will be automatically disabled after saving changes.
        </MessageBox>
      </li>
      <li className="mt-10">
        <Field
          name="status"
          type="radio"
          value="full_access"
          component={({ input }) => (
            <CircleCheckbox
              type="radio"
              className={styles['trial-period-checkbox']}
              label={<span className="semibold">Paying Customer</span>}
              {...input}
            />
          )}
        />
        <div className={styles.description}>Tenant has full access to EveryPig.</div>
      </li>
    </ul>
  </div>
);

export default TenantStatusRow;
