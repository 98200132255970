import React from 'react';
import T from 'prop-types';
// components
import { Field } from 'redux-form';
import ToggleSwitcher from '../ToggleSwitcher';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './TenantCheckboxRow.module.scss';

const cn = classnames.bind(styles);

const TenantCheckboxRow = ({ isLastRow, isFirstRow, fieldData, everypig_basic }) => {
  const disbleCheckboxes = [
    'ai_mortality_prediction',
    'ai_treatment_prediction',
    'ai_img_prediction',
    'ai_diagnosis_prediction',
    'admin_treatment_protocols',
    'admin_svrs',
    'virtual_visits',
    'logistics',
  ];
  const isCheckboxDisbaled = disbleCheckboxes.includes(fieldData.name) && everypig_basic;
  return (
    <div className={cn('toggle-switcher-row', { 'last': isLastRow, 'first': isFirstRow })}>
      <div className={cn('toggle-switcher-column')}>
        <Field type="checkbox" component={ToggleSwitcher} {...fieldData} isDisabled={isCheckboxDisbaled} />
      </div>
    </div>
  );
};

TenantCheckboxRow.propTypes = {
  fieldData: T.object.isRequired,
  isLastRow: T.bool.isRequired,
  isFirstRow: T.bool.isRequired,
};

export default TenantCheckboxRow;
