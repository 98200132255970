import React from 'react';
import T from 'prop-types';
// components
import ActivityMentions from 'components/Activities/ActivityMentions';
import AsyncSelect from 'components/AsyncSelect/AsyncSelect';
import Avatar from 'components/Avatar/Avatar';
import { FormattedMessage } from 'react-intl';
// styles
import './FarmFeedFarmsSelect.scss';

const ValueComponent = (props) => {
  const { value, onRemove, children } = props;

  return (
    <div className="Select-value" title={value.title}>
      <span className="Select-value-label">
        {children}&nbsp;
        <span className="remove-icon" onClick={() => onRemove(value)}>
          <i className="fa fa-times" />
        </span>
      </span>
    </div>
  );
};

const FarmFeedFarmsSelect = ({ onChange, selectedFarms }) => {
  let farmInput = null;
  const optionRenderer = (option) => (
    <div className="farm-suggestion-item">
      <Avatar type="farm" avatarSize={40} iconSize={16} />
      <div className="semibold">{option.label}</div>
    </div>
  );

  return (
    <div className="FarmFeedFarmsSelect">
      <div className="farms-line">
        <ActivityMentions farms={selectedFarms.map(({ item }) => item)} />
      </div>
      <div className="farms-search">
        <div onClick={() => farmInput.focus()} className="select-badge">
          <FormattedMessage id="general.farms" />
        </div>
        <AsyncSelect
          arrowRenderer={null}
          optionRenderer={optionRenderer}
          multi
          selected={selectedFarms}
          placeholder={<FormattedMessage id="general.searchBy.farms" />}
          noInputMargin
          className="farm-select"
          requestParams={{ active: true }}
          optionsPath="/farms/search"
          optionsKeys={{ value: 'id', label: 'name' }}
          onChange={onChange}
          valueComponent={ValueComponent}
          getRef={(input) => { farmInput = input; }}
          openOnFocus
        />
      </div>
    </div>
  );
};

ValueComponent.propTypes = {
  value: T.object,
  onRemove: T.func,
  children: T.any,
};

FarmFeedFarmsSelect.propTypes = {
  onChange: T.func,
  selectedFarms: T.array.isRequired,
};

export default FarmFeedFarmsSelect;
