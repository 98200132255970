import React, { Fragment, useState } from 'react';
import T from 'prop-types';
// components
import Divider from 'components/Divider';
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
// hooks
import useOnclickOutside from 'react-cool-onclickoutside';
// styles
import styles from './SortDropdown.module.scss';

const cn = classnames.bind(styles);

const defaultSortDirection = 'asc';

const directionOptions = [
  { label: <FormattedMessage id="general.ascending" />, key: 'asc' },
  { label: <FormattedMessage id="general.descending" />, key: 'desc' },
];

const SortDropdown = ({ options, onSortChange, sort = '' }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [currentSortKey, currentSortDirection] = sort?.split(' ');

  const getDropdownLabel = () => {
    if (currentSortKey) {
      const { label } = options.find(({ key }) => currentSortKey === key);

      return (
        <Fragment>
          <FormattedMessage id="general.sortBy" />:&nbsp;
          <strong>{label}</strong>
        </Fragment>
      );
    }

    return <FormattedMessage id="general.sortBy" />;
  };

  const toggleSortDirection = () => {
    const newSortDirection = currentSortDirection === 'asc' ? 'desc' : 'asc';
    onSortChange(`${currentSortKey} ${newSortDirection}`);
  };

  const handleSortSelect = (option) => () => {
    if (currentSortKey !== option.key) {
      onSortChange(`${option.key} ${defaultSortDirection}`);
    }
  };

  const toggleIsOpened = () => setIsOpened((isOpened) => !isOpened);

  const wrapperRef = useOnclickOutside(() => {
    if (isOpened) setIsOpened(false);
  });

  return (
    <div ref={wrapperRef} onClick={toggleIsOpened} className={cn('sort-dropdown')}>
      {getDropdownLabel()}
      <i className={cn('fa fa-angle-down-btb', 'angle-icon')} />
      {isOpened && (
        <ul className={cn('sort-dropdown-menu')}>
          {options.map((option) => (
            <li
              key={option.key}
              onClick={handleSortSelect(option)}
              className={cn('menu-item', { active: currentSortKey === option.key })}
            >
              <div className={cn('item-label')}>{option.label}</div>
              {currentSortKey === option.key && <i className={cn('fa fa-check', 'selected-icon')} />}
            </li>
          ))}
          <Divider />
          {directionOptions.map((option) => (
            <li
              key={option.key}
              onClick={toggleSortDirection}
              className={cn('menu-item', { active: currentSortDirection === option.key })}
            >
              <div className={cn('item-label')}>{option.label}</div>
              {currentSortDirection === option.key && <i className={cn('fa fa-check', 'selected-icon')} />}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

SortDropdown.propTypes = {
  options: T.arrayOf(T.shape({
    label: T.node.isRequired,
    key: T.string.isRequired,
  })).isRequired,
  sort: T.string,
  onSortChange: T.func.isRequired,
};

export default SortDropdown;
