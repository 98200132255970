import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage } from 'react-intl';
import TreatmentProtocolProductsTable from './components/TreatmentProtocolProductsTable';
import Preloader from 'components/Preloader';
import TreatmentProtocolProfileHeader from './components/TreatmentProtocolProfileHeader';
// endpoints
import {
  fetchTreatmentProtcool,
  updateTreatmentProtocol,
} from 'endpoints/treatmentProtocols';
// utils
import { connect } from 'react-redux';
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const TreatmentProtocolProfile = ({
  router,
  location: { pathname },
  params: { protocolId },
  setPageOptions,
  setBreadcrumbs,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [treatmentProtocol, setTreatmentProtocol] = useState(null);

  const fetchData = async () => {
    try {
      setTreatmentProtocol(await fetchTreatmentProtcool(protocolId));
    } catch (error) {
      toastResponseErrors(error);
    }
  };

  const onTreatmentProtocolStatusChange = async (isActive) => {
    try {
      setIsLoading(true);
      setTreatmentProtocol(await updateTreatmentProtocol(protocolId, { active: isActive }));
    } catch (error) {
      toastResponseErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setPageOptions({ backLink: '/treatment-protocols' });
    setBreadcrumbs([
      { label: <FormattedMessage id="general.globalProtocols" />, path: '/treatment-protocols' },
      { label: treatmentProtocol?.name || '', useLabelAsMobileTitle: true },
    ]);
  }, [treatmentProtocol?.name]);

  if (!treatmentProtocol) {
    return <Preloader isActive />;
  }

  const options = [
    {
      label: <FormattedMessage id="general.button.edit" />,
      onClick: () => router.push(`${pathname}/edit`),
    },
    {
      label: <FormattedMessage id={`general.button.${treatmentProtocol.active ? 'disable' : 'enable'}`} />,
      onClick: () => onTreatmentProtocolStatusChange(!treatmentProtocol.active), // passing reversed value
    },
  ];

  return (
    <div>
      <Preloader isActive={isLoading} />
      <TreatmentProtocolProfileHeader treatmentProtocol={treatmentProtocol} options={options} />
      <TreatmentProtocolProductsTable treatmentProducts={treatmentProtocol.treatment_protocol_products} />
    </div>
  );
};

TreatmentProtocolProfile.propTypes = {
  router: T.object.isRequired,
  location: T.object.isRequired,
  params: T.object.isRequired,
  setPageOptions: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
};

export default connect(null, {
  setPageOptions,
  setBreadcrumbs,
})(TreatmentProtocolProfile);
