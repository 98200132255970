import React from 'react';
import T from 'prop-types';
// components
import ProgressBar from './components/ProgressBar';
import FarmInformationBar from './components/FarmInformationBar';
import AdditionalSection from './components/AdditionalSection';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import CategoryRow from './components/CategoryRow';
import CategoryStepper from './components/CategoryStepper';
import SVRHeader from './components/SVRHeader';
import Preloader from 'components/Preloader';
// utils
import classnames from 'classnames/bind';
import { connect } from 'react-redux';
import { isSVRCategoriesEmpty, isAdditionalCategoryEmpty, checkSVRConfirmable } from 'utils/SVRHelper';
// styles
import styles from './Report.module.scss';

const cn = classnames.bind(styles);

const ReportEdit = ({ report, farm, isLoading, currentCompany, onSave, onClose, onPreview }) => {
  const { isDraft, createdAt, categories, additionalCategory } = report;
  // TODO: next two functions are expensive, memoize it
  const isSVREmpty = isSVRCategoriesEmpty(categories) && isAdditionalCategoryEmpty(additionalCategory);
  const isSVRConfirmable = checkSVRConfirmable(categories);

  const categoryRenderer = (category, idx) => (
    <CategoryRow categoryIndex={idx} category={category} key={idx} categoryType="generalCategories" />
  );

  return (
    <div className={cn('wrapper')}>
      <Preloader isCentered isActive={isLoading} />
      <SVRHeader
        templateId={report.svr_template_id}
        farmId={farm.id}
        createdAt={createdAt}
        farmName={farm.name}
        onClose={isSVREmpty ? onClose : onSave}
      >
        <Button onClick={isSVREmpty ? onClose : onSave} className={cn('save-close-btn')}>
          <FormattedMessage id={`general.${!isSVREmpty ? 'button.saveAndClose' : 'close'}`} />
        </Button>
      </SVRHeader>
      {isDraft && <CategoryStepper className={cn('stepper')} categories={categories} />}
      <div className={cn('svr-content')}>
        <div className={cn('farm-bar')}>
          <FarmInformationBar farm={farm} currentCompany={currentCompany} />
        </div>
        <div className={cn('content-wrapper')}>
          <div className={cn('content')}>
            {categories.map(categoryRenderer)}
            <AdditionalSection />
          </div>
        </div>
      </div>
      {isDraft && (
        <div className={cn('svr-footer')}>
          <Button disabled className={cn('btn', 'back')}>
            <i className="mr-10 fa fa-angle-left-btb" />
            <FormattedMessage id="general.button.back" />
          </Button>
          <ProgressBar className={cn('progress-footer')} questionsData={categories} />
          <CategoryStepper className={cn('stepper')} categories={categories} />
          <Button disabled={!isSVRConfirmable} className={cn('btn')} primary onClick={onPreview}>
            <FormattedMessage id="general.button.review" />
            <i className="ml-10 fa fa-angle-right-btb" />
          </Button>
        </div>
      )}
    </div>
  );
};

ReportEdit.contextTypes = {
  router: T.object.isRequired,
};

ReportEdit.propTypes = {
  report: T.object.isRequired,
  farm: T.object.isRequired,
  isLoading: T.bool.isRequired,
  currentCompany: T.object.isRequired,
  onSave: T.func.isRequired,
  onPreview: T.func.isRequired,
  onClose: T.func.isRequired,
};

export default connect((state) => ({
  currentCompany: state.auth.user.current_company,
  report: state.siteVisitReport.report,
  farm: state.siteVisitReport.farm,
  isLoading: state.siteVisitReport.isLoading,
}))(ReportEdit);
