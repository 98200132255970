import React from 'react';
import T from 'prop-types';
// components
import FarmTypeLabel from 'components/FarmTypeLabel';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
import { getGroupStatus, getStatusIcon } from 'containers/DailyCheckup/components/helper';
// styles
import styles from './TreatmentProtocolsItemRow.scss';

const cn = classnames.bind(styles);

const TreatmentProtocolsGroupRow = ({ group, onSelect }) =>  {
  const { status } = getGroupStatus(group);

  return (
    <div className={cn('treatment-protocols-farm-row')}>
      <div className={cn('icon-section')}>
        {/* TODO: implement custom logic for icon type (currently based on DC) */}
        {getStatusIcon(status)}
      </div>
      <div className={cn('name-section')}>
        <span className={cn('farm-name-wrapper')}>
          <div className={cn('group-name')}>
            {group.name}
          </div>
          {group.farm && (
            <div className={cn('farm-info')}>
              <div className="vertical-divider" />
              {group.farm.name}
              <FarmTypeLabel className="ml-10" farmType={group.farm.farm_type} />
            </div>
          )}
        </span>
      </div>

      <div className="vertical-divider" />
      <div className={cn('pigs-count-section')}>
        <strong>{group.pigs || 0} </strong>
        &nbsp;
        <FormattedMessage id="general.pigs" />
      </div>

      <div className={cn('action-section')}>
        <Button onClick={onSelect} className={cn('select-btn')} primary>
          <FormattedMessage id="general.button.select" />
        </Button>
      </div>
    </div>
  );
};

TreatmentProtocolsGroupRow.propTypes = {
  group: T.object.isRequired,
  onSelect: T.func.isRequired,
};

export default TreatmentProtocolsGroupRow;
