import { fetchFromAPI } from 'utils/api';

export function fetchBarnSheetsGroups(params) {
  return fetchFromAPI('/barnsheets/pig_groups', { params }).catch((errors) => {
    throw errors;
  });
}

export function downloadBarnSheetsGroupsCSV(params) {
  return fetchFromAPI('/barnsheets/pig_groups.csv', { blob: true, params })
    .catch((errors) => {
      throw errors;
    });
}

export function applyBarnSheetsGroupsFilter(params) {
  return fetchFromAPI('/barnsheets/pig_groups/filter', { method: 'post', body: JSON.stringify(params) })
    .catch((errors) => {
      throw errors;
    });
}

export function downloadBarnSheetsGroupsFilterCSV(params) {
  return fetchFromAPI('/barnsheets/pig_groups/filter.csv', { method: 'post', blob: true, body: JSON.stringify(params) })
    .catch((errors) => {
      throw errors;
    });
}

export function fetchBarnSheetsGroupDiagnoses(pigGroupId, params) {
  return fetchFromAPI(`/barnsheets/pig_groups/${pigGroupId}/diagnoses`, { params }).catch((errors) => {
    throw errors;
  });
}

export function fetchBarnSheetsGroupPigGroupDiagnoses(pigGroupId, params) {
  return fetchFromAPI(`/barnsheets/pig_groups/${pigGroupId}/pig_group_diagnoses`, { params }).catch((errors) => {
    throw errors;
  });
}

export function deleteBarnSheetsGroupPigGroupDiagnosis(pigGroupId, diagnosisId) {
  return fetchFromAPI(`/barnsheets/pig_groups/${pigGroupId}/pig_group_diagnoses/${diagnosisId}`, { method: 'delete' })
    .catch((errors) => {
      throw errors;
    });
}

export function downloadBarnSheetsGroupsMortalitiesCSV(params) {
  return fetchFromAPI('/csv/barnsheets/pig_groups/mortalities_index', { blob: true, params })
    .catch((errors) => {
      throw errors;
    });
}

export function downloadBarnSheetsGroupsMovementsCSV(params) {
  return fetchFromAPI('/csv/barnsheets/pig_groups/pig_migrations_index', { blob: true, params })
    .catch((errors) => {
      throw errors;
    });
}

export function downloadBarnSheetsGroupsTreatmentsCSV(params) {
  return fetchFromAPI('/csv/barnsheets/pig_groups/treatment_products_index', { blob: true, params })
    .catch((errors) => {
      throw errors;
    });
}
