import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { updateCurrentUser } from 'reducers/auth';
// component
import LayoutMinimal from 'components/LayoutMinimal';
import Input from 'components/Input';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import StepDots from '../components/StepDots';
import EXIF from 'exif-js';
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames';
import renderIf from 'utils/renderIf';
import { showToastrMessage } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { isUserHasProductionRole } from 'utils/userHelper';
import { authFrontendV2Url } from 'utils/urlBuilder';

function getRotationClass(orientation) {
  switch (orientation) {
    case 3: return 'rotate180';
    case 6: return 'rotate90';
    case 8: return 'rotate270';
    default: return '';
  }
}

function getUserState(user) {
  return {
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    nickname: user?.nickname || '',
    phone: user?.phone || '',
    country_code: user?.country_code || '',
    profile_photo: user?.profile_photo?.medium || '',
  };
}

const CompleteRegistrationInfo = ({
  isLoading,
  params: { id },
  router,
  updateCurrentUser,
  user,
}) => {
  const [userData, setUserData] = useState(getUserState(user));
  const [{ step, isSubmitting, avatar, isImageLoading, progress, rotateClass }, setState] = useState({
    avatar: '',
    isSubmitting: false,
    isImageLoading: false,
    progress: 0,
    rotateClass: '',
    step: 3,
  });

  useEffect(() => {
    if (!userData.first_name && user?.first_name) {
      setUserData(getUserState(user));
    }
  }, [user]);

  const handleChange = ({ target }) => {
    setUserData((prevState) => ({ ...prevState, [target.name]: target.value }));
  };

  const handleCompleteRegistration = () => {
    const entity = { ...userData };
    if (avatar) entity.profile_photo_data_uri = avatar;

    setState((prevState) => ({ ...prevState, isSubmitting: true }));
    updateCurrentUser(entity, id, { confirm_profile: step === 6 })
      .then((updatedUser) => {
        if (step === 6) {
          showToastrMessage('component.toastr.invitations.success');
          setState((prevState) => ({ ...prevState, isSubmitting: false }));
          const isUserOnlyAdmin = updatedUser?.roles_map?.admin && !user?.roles_map?.super_admin;
          const isSchedulingTariffEnabled = updatedUser?.current_company?.only_logistics;
          const isEverypigBasicEnabled = updatedUser?.current_company?.everypig_basic;
          if (isUserOnlyAdmin && isSchedulingTariffEnabled) {
            window.location.href = authFrontendV2Url('scheduling');
            return;
          }
          if (updatedUser?.has_trucking_roles && !isUserHasProductionRole(updatedUser)) {
            window.location.href = authFrontendV2Url('trucking');
            return;
          }
          if (isEverypigBasicEnabled) {
            router.push('/barnsheets');
            return;
          }

          router.push('/farmfeed');
        } else {
          setState((prevState) => ({ ...prevState, step: prevState.step + 1, isSubmitting: false }));
        }
      })
      .catch((err) => {
        setState((prevState) => ({ ...prevState, isSubmitting: false }));
        return toastResponseErrors(err);
      });
  };

  const setAvatar = (e) => {
    const fileList = e.target.files;
    const reader = new FileReader();

    if (fileList && fileList[0]) {
      setState((prevState) => ({ ...prevState, isImageLoading: true }));
      reader.readAsDataURL(fileList[0]);
      reader.onload = (e) => {
        EXIF.getData(fileList[0], () => {
          const orientation = EXIF.getTag(fileList[0], 'Orientation');
          setState((prevState) => ({ ...prevState, rotateClass: getRotationClass(orientation) }));
        });
        setTimeout(() => {
          setState((prevState) => ({ ...prevState, avatar: e.target.result, isImageLoading: false }));
        }, 500);
      };
      reader.onprogress = (data) => {
        if (data.lengthComputable) {
          setState((prevState) => ({ ...prevState, progress: parseInt(((data.loaded / data.total) * 100), 10) }));
        }
      };
    }
  };

  const handleRemoveAvatar = () => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        avatar: '',
        progress: 0,
      }));
      setUserData((prevState) => ({ ...prevState, profile_photo: '' }));
    }, 0);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleTelInputChange = (value) => {
    setUserData((prevState) => ({
      ...prevState,
      phone: value.phone,
      country_code: value.countryCode,
    }));
  };

  const renderUserCredentials = () => {
    const { first_name, last_name } = userData;
    return (
      <div>
        <FormattedMessage id="container.invitations.firstName">
          {(text) => (
            <label>
              <span className="tag">{text}</span>
              <Input
                type="text"
                value={first_name}
                placeholder={text}
                onChange={handleChange}
                className="input-info"
                name="first_name"
                autoFocus
              />
            </label>
          )}
        </FormattedMessage>
        <FormattedMessage id="container.invitations.lastName">
          {(text) => (
            <label>
              <span className="tag">{text}</span>
              <Input
                type="text"
                value={last_name}
                placeholder={text}
                onChange={handleChange}
                className="input-info"
                name="last_name"
              />
            </label>
          )}
        </FormattedMessage>
      </div>
    );
  };

  const renderUsername = () => {
    const { nickname } = userData;
    return (
      <div>
        <FormattedMessage id="general.username">
          {(text) => (
            <label className="label-username">
              <span className="tag">{text}</span>
              <span className="input-username fa fa-at" />
              <Input
                type="text"
                value={nickname}
                placeholder={text}
                onChange={handleChange}
                className="input-info"
                name="nickname"
                autoFocus
              />
            </label>
          )}
        </FormattedMessage>
        <FormattedMessage id="container.invitations.userName.description">
          {(text) => <i className="description-username">{text}</i>}
        </FormattedMessage>
      </div>
    );
  };

  const renderMobilePhone = () => {
    const value = { phone: userData.phone, countryCode: userData.country_code };
    return (
      <div>
        <PhoneInput
          className="phone-input-info"
          onChange={handleTelInputChange}
          value={value}
          showHeader
        />
        <FormattedMessage id="container.invitations.phone.description">
          {(text) => <i className="description-phone">{text}</i>}
        </FormattedMessage>
      </div>
    );
  };

  const addPhoto = () => {
    const progressBarStyle = { width: `${progress}%` };
    const avatarPresent = !!avatar || !!userData.profile_photo;

    return (
      <div className="upload-label">
        <label className={cn('drop-area', { 'border': avatarPresent })}>
          {!avatarPresent && !isImageLoading && <input type="file" className="file" onChange={setAvatar} />}
          {!avatarPresent && !isImageLoading && (
            <span className="cta">
              <FormattedMessage id="container.invitations.uploadImg.description" />
            </span>
          )}
          {isImageLoading && (
            <div className="upload-progress">
              <div className="progress-value" style={progressBarStyle} />
            </div>
          )}
          {avatarPresent && !isImageLoading &&
          <div onClick={handleRemoveAvatar} className="remove-photo fa fa-times" />}
          <span
            className={`avatar ${rotateClass}`}
            style={{ 'backgroundImage': `url(${(avatar || userData.profile_photo)})` }}
          />
          {!avatarPresent && !isImageLoading && (
            <div className="camera">
              <i className="fa fa-camera-fa" />
            </div>
          )}
        </label>
      </div>
    );
  };

  const getTitleKey = () => {
    if (step === 3) return 'container.invitations.confirmYourName';
    if (step === 4) return 'container.invitations.confirmUserName';
    if (step === 5) return 'container.invitations.phone.label';
    if (step === 6) return 'container.invitations.uploadImg';
    return '';
  };

  const goBack = () => {
    if (step === 3) {
      router.push(`/invitations/${id}/set-language`);
      return;
    }
    setState((prevState) => ({ ...prevState, step: prevState.step - 1 }));
  };

  return (
    <LayoutMinimal containerClass="CompleteRegistration">
      <div onClick={goBack} className="back-icon">
        <i className="fa fa-angle-left-btb" />
      </div>
      <FormattedMessage id={getTitleKey()} tagName="h1" />
      <form onKeyPress={handleEnterKey}>
        {renderIf(step === 3)(renderUserCredentials())}
        {renderIf(step === 4)(renderUsername())}
        {renderIf(step === 5)(renderMobilePhone())}
        {renderIf(step === 6)(addPhoto())}
        <button
          type="button"
          disabled={isSubmitting || isLoading}
          className="button primary lm-button next"
          onClick={handleCompleteRegistration}
        >
          {isSubmitting
            ? <FormattedMessage id="general.button.saving" />
            : <FormattedMessage id={`general.button.${step === 6 ? 'completeRegistration' : 'next'}`} />
          }
        </button>
      </form>
      <StepDots dotsCount={6} activeStep={step} />
    </LayoutMinimal>
  );
};

CompleteRegistrationInfo.propTypes = {
  params: T.object.isRequired,
  user: T.object,
  router: T.object.isRequired,
  isLoading: T.bool.isRequired,
  updateCurrentUser: T.func.isRequired,
};

export default connect(
  (state) => ({
    user: state.invitations.user,
    isLoading: state.invitations.isLoading,
  }), {
    updateCurrentUser,
  }
)(CompleteRegistrationInfo);
