import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import TreatmentScheduleForm from './TreatmentForm';
import Collapse from 'components/Collapse/Collapse';
import PlusButton from 'components/PlusButton';
import TrashButton from 'components/TrashButton';
import { FieldArray } from 'redux-form';
// utils
import classnames from 'classnames/bind';
import { connect } from 'react-redux';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
// constants
import { treatmentProtocolFormSelector } from './helper';
// styles
import styles from './TreatmentForm.module.scss';

const cn = classnames.bind(styles);

const getCollapseTitle = (step) => {
  if (!step) return '';
  const { step_type } = step;
  const { step_unit, first_period, last_period, start_date, end_date } = step[step_type] || {};
  const values = {
    first_period,
    last_period,
    start_date: moment(start_date).format('MM/DD/YYYY'),
    end_date: moment(end_date).format('MM/DD/YYYY'),
  };
  const isBetweenStepTypeValid = step_type === 'between' && (
    (step_unit !== 'date_range' && first_period && last_period) ||
    (step_unit === 'date_range' && start_date && end_date)
  );

  const isStepWithCalendarValid = step_unit === 'date_range' && (end_date || start_date);
  const isOtherStepTypeValid = step_unit !== 'date_range' && step_type !== 'between' && step_type && first_period;

  const isCurrentValueValid = isBetweenStepTypeValid || isOtherStepTypeValid || isStepWithCalendarValid;

  const messageId = step_type === 'on_date' ?
    `general.treatPigs.${step_type}` :
    `general.treatPigs.${step_type}.${step_unit}`;

  return (
    <span className="nowrap-ellipsis">
      {isCurrentValueValid ?
        <FormattedMessage id={messageId} values={{ b: (msg) => <b>{msg}</b>, ...values }} /> :
        <FormattedMessage id="general.treatPigs" />
      }
    </span>
  );
};

const multiStepRenderer = (data) => {
  if (data.fields.length === 1) {
    return (
      <TreatmentScheduleForm
        isDailyTreatment={data.isDailyTreatment}
        fieldName={`${data.fields.name}[0]`}
        product={data.product}
      />
    );
  }

  return (
    <Fragment>
      {data.fields.map((field, fieldIndex) => (
        <div key={fieldIndex} className={cn('treatment-step')}>
          <div className={cn('treatment-counter')}>{fieldIndex + 1}</div>
          <Collapse
            isOpenOnMount={!isMobile}
            className={cn('collapse')}
            title={getCollapseTitle(data.values[fieldIndex])}
          >
            <div className={cn('collapse-content')}>
              <TreatmentScheduleForm
                isDailyTreatment={data.isDailyTreatment}
                fieldName={field}
                product={data.product}
              />
              {data.fields.length > 2 && (
                <TrashButton className={cn('mobile-trash-button')} onClick={() => data.fields.remove(fieldIndex)} />
              )}
            </div>
          </Collapse>
          <div className={cn('action-btn-group')}>
            {data.fields.length > 2 && (
              <TrashButton className={cn('ml-15')} onClick={() => data.fields.remove(fieldIndex)} />
            )}
            {data.fields.length - 1 === fieldIndex && (
              <PlusButton onClick={() => data.fields.push({})} className="mt-2 ml-5" />
            )}
          </div>
        </div>
      ))}
      <PlusButton onClick={() => data.fields.push({})} className={cn('mobile-plus-button')} />
    </Fragment>
  );
};

const TreatmentSteps = ({ fieldName, values, isDailyTreatment, product }) => (
  <Fragment>
    <div className="semibold mb-10">
      <FormattedMessage id="general.treatmentSteps" />
    </div>
    <FieldArray
      name={fieldName}
      isDailyTreatment={isDailyTreatment}
      values={values}
      product={product}
      component={multiStepRenderer}
    />
  </Fragment>
);

TreatmentSteps.propTypes = {
  fieldName: T.string.isRequired,
  values: T.array.isRequired,
  isDailyTreatment: T.bool,
  product: T.oneOfType([T.string, T.object]),
};

export default connect((state, { fieldName }) => ({
  values: treatmentProtocolFormSelector(state, fieldName),
}))(TreatmentSteps);
