import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import FarmTooltip from 'components/EntityFarmfeedTooltip/FarmTooltip';
import CompanyFarmFeedTooltip from 'components/EntityFarmfeedTooltip/CompanyFarmfeedTooltip';
import Popover from 'components/Popover';
import Avatar from 'components/Avatar/Avatar';
import TextLabel from 'components/TextLabel/TextLabel';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './ActivityReportBox.module.scss';

const cn = classnames.bind(styles);

const ActivityReportBox = ({ activity: { id, farm }, className, children, isAdmin = false }) => (
  <div className={cn('svr-box', className)}>
    <div className={cn('svr-box-header')}>
      <Popover
        content={<FarmTooltip activityId={id} farmId={farm.id} isAdmin={isAdmin} />}
        placement="bottomLeft"
      >
        <span>
          <Avatar type="farm" avatarSize={40} iconSize={20} />
        </span>
      </Popover>

      <div className={cn('farm-info')}>
        <div>
          <strong>{farm.name}</strong>
          <TextLabel className={cn('farm-label')}>{farm.farm_type}</TextLabel>
        </div>
        <div className={cn('manager')}>
          {farm.manager
            ? (
              <Popover
                content={<CompanyFarmFeedTooltip activityId={id} companyId={farm.manager.id} isAdmin={isAdmin} />}
                placement="topLeft"
              >
                <div>
                  {farm.manager.name}
                </div>
              </Popover>
            )
            : <FormattedMessage id="container.farmfeed.row.noManageCompany" />}
        </div>
      </div>
    </div>
    {children && (
      <div className={cn('svr-box-body')}>
        {children}
      </div>
    )}
  </div>
);

ActivityReportBox.propTypes = {
  className: T.string,
  activity: T.object.isRequired,
  children: T.oneOfType([T.arrayOf(T.node), T.node]),
  isAdmin: T.bool,
};

export default ActivityReportBox;
