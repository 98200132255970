import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { addNewPostedActivity } from 'reducers/farmfeed';
import { openPortal, closePortal } from 'reducers/portal';
import {
  fetchList,
  setDestroyedData,
  setSelectedData,
  selectItem,
  unselectItem,
  resetData,
  resetResources,
} from 'reducers/mobileListPicker';
// components
import { FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive/src/Component';
import Button from 'components/Button';
import Preloader from 'components/Preloader';
import UserAvatar from 'components/UserAvatar';
import MobileHeader from 'components/MobileHeader/MobileHeader';
import MobilePortalOverlay from 'components/MobilePortalOverlay';
import ResponsivePlayer from 'components/ResponsivePlayer/ResponsivePlayer';
import MobileListPicker from 'components/MobileListPicker/MobileListPicker';
import MobileFarmsRow from 'components/MobileListPicker/Rows/MobileFarmsRow';
import MobileUserRow from 'components/MobileListPicker/Rows/MobileUserRow';
import FileAttachmentProgress from 'components/FileAttachmentProgress/FileAttachmentProgress';
import MentionButton from './MentionButton';
import MobileGroupRow from 'components/MobileListPicker/Rows/MobileGroupRow';
// endpoints
import {
  createDirectPost,
  deleteDirectPost,
  deleteActivityAsset,
  fetchActivityAsset,
  updateDirectPost,
} from 'endpoints/farmfeed';
// utils
import autosize from 'autosize';
import initializeDirectPostResumable from 'utils/resumable/initializeDirectPostResumable';
import classnames from 'classnames/bind';
import isEmpty from 'lodash.isempty';
import { getFarmNickname, getUserNickname, isVideoAsset, isImageAsset, getGroupNickname } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import styles from './FarmFeedPostMobile.module.scss';

const cn = classnames.bind(styles);

const MENTION_SOMEONE = 'MENTION_SOMEONE';
const MENTION_FARM = 'MENTION_FARM';
const MENTION_GROUP = 'MENTION_GROUP';
const POST_COMPANY_MESSAGE = 'POST_COMPANY_MESSAGE';
const UPLOAD_PHOTO_VIDEO = 'UPLOAD_PHOTO_VIDEO';

class FarmFeedPostMobile extends Component {

  state = {
    mentionType: null,
    message: '',
    files: [],
    draftId: null,
    isLoading: false,
    isTextareaFocused: false,
    isFarmSelection: false,
    isUserSelection: false,
    isGroupSelection: false,
    cancelledFileIdentifiers: [],
  };

  componentDidMount() {
    if (this.textarea) autosize(this.textarea);
  }

  componentWillUnmount() {
    const { resetData } = this.props;
    resetData();
  }

  componentDidUpdate(prevProps) {
    const { selected } = this.props;
    if (selected.length !== prevProps.selected.length && !selected.length) {
      this.setState({ mentionType: null });
    }
  }

  initializeFileUploader = () => {
    const { draftId } = this.state;
    if (!draftId) {
      createDirectPost({ visible: false })
        .then(({ id }) => {
          this.setState({ draftId: id });
          this.resumable = initializeDirectPostResumable(id, {
            onFileAdded: this.onFileAdded,
            onFileSuccess: this.onFileSuccess,
            onFileProgress: this.updateFileProgress,
          });

          if (this.resumable.support) {
            const browseButton = document.querySelector('#resumable-mention-browse');
            this.resumable.assignBrowse([browseButton]);
            browseButton.click();
          }
        });
    }
  };

  onFileAdded = (file) => {
    this.setState((prevState) => ({ ...prevState, files: [...prevState.files, file] }));
  };

  onFileSuccess = (file) => {
    const { draftId } = this.state;
    fetchActivityAsset(draftId, { chunk_identifier: file.uniqueIdentifier })
      .then((savedFile) => {
        this.setState((prevState) => ({
          ...prevState,
          files: prevState.files.map((stateFile) => (
            stateFile.uniqueIdentifier === file.uniqueIdentifier
              ? { ...stateFile, savedFile }
              : stateFile
          ))
        }));
      })
      .catch(toastResponseErrors);
  };

  removeFileFromState = (file) => {
    this.setState((prevState) => ({
      ...prevState,
      cancelledFileIdentifiers: [...prevState.cancelledFileIdentifiers, file.uniqueIdentifier],
      files: prevState.files.filter(({ uniqueIdentifier }) => uniqueIdentifier !== file.uniqueIdentifier),
    }));
  }

  removeExistingFileFromState = (file) => {
    this.setState((prevState) => ({
      ...prevState,
      cancelledFileIdentifiers: prevState.cancelledFileIdentifiers
        .filter((identifier) => identifier !== file.uniqueIdentifier),
      files: prevState.files.filter(({ uniqueIdentifier }) => uniqueIdentifier !== file.uniqueIdentifier),
    }));
  }

  removeCancelledAssets = () => {
    const { draftId, cancelledFileIdentifiers } = this.state;
    return Promise.all(cancelledFileIdentifiers.map((identifier) => {
      return fetchActivityAsset(draftId, { chunk_identifier: identifier })
        .then((asset) => deleteActivityAsset(draftId, asset.id))
        .catch(() => ({ error: true }));
    }));
  }

  removeFileFromQueue = (file) => {
    file.cancel();
    this.removeFileFromState(file);
  };

  updateFileProgress = (file) => {
    this.setState((prevState) => ({
      ...prevState,
      files: prevState.files.map((stateFile) => (
        stateFile.uniqueIdentifier === file.uniqueIdentifier
          ? { ...stateFile, uploadProgress: file.progress() }
          : stateFile
      ))
    }));
  };

  removeExistingAsset = (asset) => {
    const { draftId } = this.state;
    deleteActivityAsset(draftId, asset.savedFile.id)
      .then(() => {
        this.removeExistingFileFromState(asset);
      })
      .catch(toastResponseErrors);
  };

  onMentionBtnClick = (type) => {
    const { currentUser: { current_company }, setSelectedData } = this.props;

    if (type === UPLOAD_PHOTO_VIDEO) {
      this.initializeFileUploader();
      return;
    }

    if (type === POST_COMPANY_MESSAGE) {
      setSelectedData([current_company]);
      this.setState({ mentionType: type });
    } else {
      this.openMobilePicker(type);
    }
  };

  isUploading = () => (
    this.state.files.some((file) => !file.isComplete())
  );

  postData = () => {
    const { draftId, message, mentionType } = this.state;
    const { addNewPostedActivity, closePortal, selected } = this.props;
    const data = { message, visible: true };
    if (mentionType === POST_COMPANY_MESSAGE && selected.length) data.company_id = selected[0].id;
    if (mentionType === MENTION_SOMEONE && selected.length) data.mentions = selected.map(({ id }) => ({ user_id: id }));
    if (mentionType === MENTION_GROUP && selected.length) {
      data.pig_group_mentions_attributes = selected.map(({ id }) => ({ pig_group_id: id }));
    }
    if (mentionType === MENTION_FARM && selected.length) {
      data.farm_mentions_attributes = selected.map(({ id }) => ({ farm_id: id }));
    }

    this.setState({ isLoading: true });
    this.removeCancelledAssets().then(() => {
      (draftId ? updateDirectPost(draftId, data) : createDirectPost(data))
        .then((resource) => {
          addNewPostedActivity(resource);
          closePortal();
        })
        .catch((response) => {
          toastResponseErrors(response);
          this.setState({ isLoading: false });
        });
    });
  };

  openMobilePicker = (type) => {
    const { fetchList } = this.props;
    this.setState({
      isFarmSelection: type === MENTION_FARM,
      isUserSelection: type === MENTION_SOMEONE,
      isGroupSelection: type === MENTION_GROUP,
    });
    if (type === MENTION_FARM) {
      fetchList('/farms/search');
    }
    if (type === MENTION_SOMEONE) {
      fetchList('/users/search');
    }
    if (type === MENTION_GROUP) {
      fetchList('/pig_groups/search?exclude_status=closed');
    }
  };

  renderMobileUserRow = (item, mode, { isSelected }) => (
    <MobileUserRow
      key={item.id}
      user={item}
      actionBtnType="checkbox"
      name={item.full_name}
      onRowClick={() => this.onUserAdd(item)}
      selectedRow={isSelected(item)}
    />
  );

  renderMobileFarmsRow = (item, mode, { isSelected }) => (
    <MobileFarmsRow
      key={item.id}
      actionBtnType="checkbox"
      name={item.name}
      onRowClick={() => this.onFarmAdd(item)}
      selectedRow={isSelected(item)}
    />
  );

  renderMobileGroupRow = (item, mode, { isSelected }) => (
    <MobileGroupRow
      key={item.id}
      actionBtnType="checkbox"
      name={item.name}
      onRowClick={() => this.onGroupAdd(item)}
      selectedRow={isSelected(item)}
    />
  );

  onUserAdd = (user) => {
    const { selected, selectItem, unselectItem } = this.props;
    const selectedUser = selected.find((item) => item.id === user.id);
    return selectedUser ? unselectItem(selectedUser) : selectItem(user);
  };

  onGroupAdd = (group) => {
    const { selected, selectItem, unselectItem } = this.props;
    const selectedGroup = selected.find((item) => item.id === group.id);
    return selectedGroup ? unselectItem(selectedGroup) : selectItem(group);
  };

  onFarmAdd = (farm) => {
    const { selected, selectItem, unselectItem } = this.props;
    const selectedFarm = selected.find((item) => item.id === farm.id);
    return selectedFarm ? unselectItem(selectedFarm) : selectItem(farm);
  };

  onHideUserSelection = () => {
    const { resetResources } = this.props;
    this.setState({ mentionType: MENTION_SOMEONE, isUserSelection: false });
    resetResources();
  };

  onHideGroupSelection = () => {
    const { resetResources } = this.props;
    this.setState({ mentionType: MENTION_GROUP, isGroupSelection: false });
    resetResources();
  };

  onHideFarmsSelection = () => {
    const { resetResources } = this.props;
    this.setState({ mentionType: MENTION_FARM, isFarmSelection: false });
    resetResources();
  };

  goBackFromSelection = () => {
    const { resetResources } = this.props;
    this.setState({ isFarmSelection: false, isGroupSelection: false, isUserSelection: false });
    resetResources();
  };

  handleClosePost = () => {
    const { closePortal } = this.props;
    const { draftId } = this.state;
    this.setState({ isLoading: true });
    draftId ? deleteDirectPost(draftId).finally(closePortal) : closePortal();
  };

  render() {
    const { currentUser, selected, setSelectedData } = this.props;
    const { roles_map: { admin }, farm_roles_count } = currentUser;
    const {
      files, isLoading, message, mentionType, isTextareaFocused, isFarmSelection, isUserSelection, isGroupSelection
    } = this.state;
    const buttons = [
      { mentionType: MENTION_SOMEONE, iconClass: 'fa fa-ep-users', btnProps: {},
        name: <FormattedMessage id="container.farmfeed.mentionSomeone" /> },
      { mentionType: MENTION_FARM, iconClass: 'fa fa-ep-farm', btnProps: {},
        name: <FormattedMessage id="container.farmfeed.mentionFarm" />, hide: (!admin && !farm_roles_count) },
      { mentionType: MENTION_GROUP, iconClass: 'fa fa-ep-groups', btnProps: {},
        name: <FormattedMessage id="general.mentionGroup" />, hide: (!admin && !farm_roles_count) },
      { mentionType: POST_COMPANY_MESSAGE, iconClass: 'fa fa-megaphone', btnProps: {},
        name: <FormattedMessage id="container.farmfeed.post" />, hide: !admin },
      { mentionType: UPLOAD_PHOTO_VIDEO, iconClass: 'fa fa-camera', btnProps: { id: 'resumable-mention-browse' },
        name: <FormattedMessage id="container.farmfeed.photoVideo" /> },
    ];
    const isPostDisabled = isEmpty(message.trim()) || !selected.length || this.isUploading() || isLoading;

    return (
      <div className={cn('ff-post-mobile')}>
        <MediaQuery maxDeviceWidth={820}>
          <MobileHeader
            title={<FormattedMessage id="general.pageTitle.postToFarmfeed" />}
            backLink={this.handleClosePost}
            backIcon="fa fa-times"
          >
            <Button disabled={isPostDisabled} primary small onClick={this.postData}>
              <FormattedMessage id="general.button.post" />
            </Button>
          </MobileHeader>
        </MediaQuery>
        <div className={cn('post-content-box')}>
          <div className={cn('post-content')}>
            <UserAvatar user={currentUser} size={40} className="mr-10" />
            <div className={cn('user-name')}>{currentUser.full_name}</div>
            {mentionType && (
              <span className={cn('mention-line')}>
                &mdash;
                {mentionType === POST_COMPANY_MESSAGE && !isEmpty(selected[0]) && (
                  <span className={cn('mention-item')}>
                    @{getFarmNickname(selected[0])}
                    <i className={cn('fa fa-times', 'remove-mention-icon')} onClick={() => setSelectedData([])} />
                  </span>
                )}
                {mentionType === MENTION_FARM && selected.map((farm) => (
                  <span key={farm.id} className={cn('mention-item')}>
                    @{getFarmNickname(farm)}
                    <i
                      className={cn('fa fa-times', 'remove-mention-icon')}
                      onClick={() => setSelectedData(selected.filter((item) => item.id !== farm.id))}
                    /> &nbsp;
                  </span>
                ))}
                {mentionType === MENTION_GROUP && selected.map((group) => (
                  <span key={group.id} className={cn('mention-item')}>
                    @{getGroupNickname(group)}
                    <i
                      className={cn('fa fa-times', 'remove-mention-icon')}
                      onClick={() => setSelectedData(selected.filter((item) => item.id !== group.id))}
                    /> &nbsp;
                  </span>
                ))}
                {mentionType === MENTION_SOMEONE && selected.map((user) => (
                  <span key={user.id} className={cn('mention-item')}>
                    @{getUserNickname(user)}
                    <i
                      className={cn('fa fa-times', 'remove-mention-icon')}
                      onClick={() => setSelectedData(selected.filter((item) => item.id !== user.id))}
                    /> &nbsp;
                  </span>
                ))}
              </span>
            )}
            <FormattedMessage id="container.farmfeed.writeSmth">
              {(placeholder) => (
                <textarea
                  className={cn('text-block', { 'no-margin-top': Boolean(mentionType) })}
                  ref={(ref) => { this.textarea = ref; }}
                  rows={1}
                  value={message}
                  onChange={(e) => this.setState({ message: e.target.value })}
                  onFocus={() => this.setState({ isTextareaFocused: true })}
                  onBlur={() => this.setState({ isTextareaFocused: false })}
                  placeholder={placeholder}
                />
              )}
            </FormattedMessage>
            {!!files.length && (
              <div className={cn('post-assets')}>
                {files.map((file) => {
                  const isUploaded = file.isComplete();

                  if (file.savedFile) {
                    const isImage = isImageAsset(file.savedFile);
                    const isVideo = isVideoAsset(file.savedFile);
                    if (isImage || isVideo) {
                      return (
                        <div key={file.uniqueIdentifier} className={cn('asset-preview')}>
                          {isImage && (
                            <div className={cn('asset')}>
                              <img src={file.savedFile.url.large} alt="" loading="lazy" />
                            </div>
                          )}
                          {isVideo &&
                            <ResponsivePlayer className={cn('asset')} url={file.savedFile.url.original} />
                          }
                          <i
                            className={cn('fa fa-times', 'remove-icon')}
                            onClick={() => this.removeExistingAsset(file)}
                          />
                        </div>
                      );
                    }
                  }

                  return (
                    <FileAttachmentProgress
                      className={cn('progress')}
                      key={file.uniqueIdentifier}
                      name={file.file.name}
                      isUploaded={isUploaded}
                      onRemove={() => {
                        file.savedFile
                          ? this.removeExistingAsset(file)
                          : this.removeFileFromQueue(file);
                      }}
                      progress={file.uploadProgress || 0}
                    />
                  );
                })}
              </div>
            )}
          </div>

          <Preloader isActive={isLoading} />

          <div className={cn('actions')}>
            <hr className={cn('divider')} />
            <div className={cn('action-buttons-box')}>
              {isTextareaFocused && (
                <MentionButton
                  className={cn('mention-to-continue', { 'black': selected.length })}
                  onClick={() => this.textarea.blur()}
                >
                  {!!selected.length && <i className={cn('fa fa-dots-three-horizontal', 'icon')} />}
                  <FormattedMessage id={`general.${!selected.length ? 'mentionToContinue' : 'mentionOrAttach'}`} />
                </MentionButton>
              )}
              {buttons.map((item) => (
                !item.hide && (
                  <MentionButton
                    key={item.mentionType}
                    className={cn('action-btn', { 'hidden': isTextareaFocused })}
                    onClick={() => this.onMentionBtnClick(item.mentionType)}
                    isDisabled={
                      mentionType && (item.mentionType !== UPLOAD_PHOTO_VIDEO) && (mentionType !== item.mentionType)
                    }
                    isActive={mentionType === item.mentionType}
                    {...item.btnProps}
                  >
                    <i className={cn('btn-icon', item.iconClass)} />{item.name}
                  </MentionButton>
                )))}
            </div>
          </div>
        </div>
        <MobilePortalOverlay isHidden={!isFarmSelection && !isUserSelection && !isGroupSelection}>
          {isFarmSelection && (
            <MobileListPicker
              title={<FormattedMessage id="component.groupCreate.selectFarm" />}
              endpoint="/farms/search"
              nameKey="name"
              rightButtonOptions={{
                label: 'next',
                onClick: this.onHideFarmsSelection,
                disableOnEmptyList: true,
              }}
              backLinkOptions={{ onClick: this.goBackFromSelection }}
              rowRenderer={this.renderMobileFarmsRow}
            />
          )}
          {isGroupSelection && (
            <MobileListPicker
              title={<FormattedMessage id="general.pageTitle.selectGroup" />}
              endpoint="/pig_groups/search"
              nameKey="name"
              rightButtonOptions={{
                label: 'next',
                onClick: this.onHideGroupSelection,
                disableOnEmptyList: true,
              }}
              backLinkOptions={{ onClick: this.goBackFromSelection }}
              rowRenderer={this.renderMobileGroupRow}
            />
          )}
          {isUserSelection && (
            <MobileListPicker
              title={<FormattedMessage id="general.pageTitle.selectPeople" />}
              endpoint="/users/search"
              nameKey="full_name"
              rightButtonOptions={{
                label: 'next',
                onClick: this.onHideUserSelection,
                disableOnEmptyList: true,
              }}
              backLinkOptions={{ onClick: this.goBackFromSelection }}
              rowRenderer={this.renderMobileUserRow}
            />
          )}
        </MobilePortalOverlay>
      </div>
    );
  }
}

FarmFeedPostMobile.propTypes = {
  currentUser: T.object,
  addNewPostedActivity: T.func.isRequired,
  openPortal: T.func.isRequired,
  closePortal: T.func.isRequired,
  fetchList: T.func.isRequired,
  selectItem: T.func.isRequired,
  unselectItem: T.func.isRequired,
  resetData: T.func.isRequired,
  setSelectedData: T.func.isRequired,
  resetResources: T.func.isRequired,
  selected: T.array.isRequired,
};

export default connect((state) => ({
  currentUser: state.auth.user,
  dataList: state.mobileListPicker.dataList.resources,
  selected: state.mobileListPicker.selected,
  destroyed: state.mobileListPicker.destroyed,
}), {
  addNewPostedActivity,
  openPortal,
  closePortal,
  fetchList,
  setDestroyedData,
  setSelectedData,
  selectItem,
  unselectItem,
  resetData,
  resetResources,
})(FarmFeedPostMobile);
