import { handleActions, createAction } from 'redux-actions';
import {
  closeAdminPigGroup,
  getAdminPigGroup,
  reopenAdminPigGroup,
  makeAdminPigGroupMarketing,
  makeAdminPigGroupNotMarketing,
  updateAdminPigGroup
} from 'endpoints/admin/pigGroups';
import { forceRequestToCloseGroup } from 'endpoints/pigGroups';

// ------------------------------------
// Constants
// ------------------------------------
const initialState = {
  data: {},
  isLoading: false,
};

// ------------------------------------
// Action Types
// ------------------------------------
const FETCH_PIG_GROUP = 'pigGroupShow/FETCH_PIG_GROUP';
const [FETCH_PIG_GROUP_PENDING, FETCH_PIG_GROUP_FULFILLED, FETCH_PIG_GROUP_REJECTED] = [
  `${FETCH_PIG_GROUP}_PENDING`,
  `${FETCH_PIG_GROUP}_FULFILLED`,
  `${FETCH_PIG_GROUP}_REJECTED`,
];
const UPDATE_PIG_GROUP = 'pigGroupShow/UPDATE_PIG_GROUP';
const [UPDATE_PIG_GROUP_PENDING, UPDATE_PIG_GROUP_FULFILLED, UPDATE_PIG_GROUP_REJECTED] = [
  `${UPDATE_PIG_GROUP}_PENDING`,
  `${UPDATE_PIG_GROUP}_FULFILLED`,
  `${UPDATE_PIG_GROUP}_REJECTED`,
];

// ------------------------------------
// Actions
// ------------------------------------
export const fetchPigGroup = (id) => createAction(FETCH_PIG_GROUP)(getAdminPigGroup(id));

export const updatePigGroup = (data, id) => createAction(UPDATE_PIG_GROUP)(updateAdminPigGroup(id, data));

export const closePigGroup = (id) => createAction(UPDATE_PIG_GROUP)(closeAdminPigGroup(id));

export const forceRequestClosePigGroup = (id) => createAction(UPDATE_PIG_GROUP)(forceRequestToCloseGroup(id));

export const reopenPigGroup = (id) => createAction(UPDATE_PIG_GROUP)(reopenAdminPigGroup(id));

export const reportPigGroupMarketing = (id) => createAction(UPDATE_PIG_GROUP)(makeAdminPigGroupMarketing(id));

export const resetPigGroupMarketing = (id) => createAction(UPDATE_PIG_GROUP)(makeAdminPigGroupNotMarketing(id));

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [FETCH_PIG_GROUP_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_PIG_GROUP_FULFILLED]: (state, action) => ({
    data: action.payload,
    isLoading: false,
  }),
  [FETCH_PIG_GROUP_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [UPDATE_PIG_GROUP_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [UPDATE_PIG_GROUP_FULFILLED]: (state, action) => ({
    data: action.payload,
    isLoading: false,
  }),
  [UPDATE_PIG_GROUP_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
