import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import TreatmentProtocolsStepsPanel from 'components/TreatmentProtocols/TreatmentProtocolsStepsPanel';
// hooks
import { tableParamsSelector } from 'hooks/useDataTable';
// redux
import { connect } from 'react-redux';
import { setTableParams } from 'reducers/tableParams';
// constants
import { tableNames } from 'utils/constants';
// api
import { getPigGroupTreatmentProtocolProductSteps } from 'endpoints/treatmentProtocols';

const PigGroupTreatmentsPanel = ({ pigGroup, setTableParams, tableParams, isOnline }, { router }) => {
  const handleReportTreatmentStep = ({ id }) => {
    const { params: { id: dailyCheckupId } } = router;
    router.push(`/daily-checkup/${dailyCheckupId}/report-treatment/${id}`);
  };

  return (
    <div className="small-12 column row centered pv-20">
      <TreatmentProtocolsStepsPanel
        isOnline={isOnline}
        fetchTreatmentProtocolsSteps={(params) => getPigGroupTreatmentProtocolProductSteps(
          pigGroup.id, { ...params, without_used: true }
        )}
        onStepReport={handleReportTreatmentStep}
        pigGroup={pigGroup}
        setTableParams={(params) => setTableParams(tableNames.checkupGroupTPProductSteps, params)}
        tableParams={tableParams}
        title={<FormattedMessage id="general.treatments" />}
      />
    </div>
  );
};

PigGroupTreatmentsPanel.propTypes = {
  pigGroup: T.object.isRequired,
  setTableParams: T.func.isRequired,
  tableParams: T.object.isRequired,
  isOnline: T.bool.isRequired,
};

PigGroupTreatmentsPanel.contextTypes = {
  router: T.object.isRequired,
};

export default connect(
  (state) => ({
    tableParams: tableParamsSelector(state, tableNames.checkupGroupTPProductSteps),
    isOnline: state.network.isOnline,
  }), {
    setTableParams,
  }
)(PigGroupTreatmentsPanel);
