import { pdfjs } from 'react-pdf';

export const convertPdfToDataUrl = async (pdfUrl) => {
  try {
    const pdfDocument = await pdfjs.getDocument(pdfUrl).promise;
    const firstPage = await pdfDocument.getPage(1);
    const viewport = firstPage.getViewport({ scale: 1.2 });
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const renderContext = { canvasContext: ctx, viewport };
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await firstPage.render(renderContext).promise;

    return await canvas.toDataURL();
  } catch (error) {
    console.error(`Error while converting PDF to DataURL: ${error}`);
    return '';
  }
};
