import React from 'react';
// components
import { BasicPlanModal } from 'components/BasicPlan';
import { openModal } from 'reducers/modals';
// redux
import { validateToken, logout, logoutAction } from 'reducers/auth';
import { getAuthData } from './authData';
// helpers
import { isSchedulingTariffUser } from './userHelper';

export const handleRedirect = (store) => (nextState, replace) => {
  const { user, authenticated } = store.getState().auth;
  const incompleteRegistration = user?.registration_state === 'passworded';
  const isAdmin = user?.roles_map?.admin;
  const userId = user?.id;

  if (authenticated && !isAdmin) {
    // allow logout
    if (/logout/.test(nextState.location.pathname)) return;

    // replace on incorrect parameters
    if (/complete-registration/.test(nextState.location.pathname)) {
      if (userId !== parseInt(nextState.params.id, 10)) {
        return replace(`/invitations/${userId}/complete-registration`); // eslint-disable-line consistent-return
      }
    }

    // incomplete registration
    if (incompleteRegistration) {
      const redirectUrl = `/invitations/${userId}/set-language`;
      const theSameUrl = nextState.location.pathname === redirectUrl;
      if (!/complete-registration/.test(nextState.location.pathname) && !theSameUrl) {
        return replace(redirectUrl); // eslint-disable-line consistent-return
      }
    }
  }
};

export const requireAuth = (store) => (nextState, replace) => {
  const { authenticated, user } = store.getState().auth;
  const isValidCurrentCompany = user && user.root_companies.some((c) => c.id === user.current_company?.id);

  if (!authenticated || !isValidCurrentCompany) {
    sessionStorage.setItem('redirect_location', JSON.stringify(nextState.location));
    replace('/login');
  } else {
    handleRedirect(store)(nextState, replace);
  }
};

export const preloadUser = (store) => (nextState, replace, proceed) => {
  const { authenticated } = store.getState().auth;
  const authData = getAuthData();
  const isInvitationPages = nextState.location.pathname.startsWith('/invitations');

  if (!authenticated && !isInvitationPages) {
    if (authData.uid && navigator.onLine) {
      store.dispatch(validateToken())
        .catch(() => {
          store.dispatch(logoutAction());
        })
        .finally(() => {
          proceed();
        });
      return;
    }
  }
  proceed();
};

export const logoutUser = (store) => (nextState, replace) => {
  store.dispatch(logout);
  replace('/login');
};

const getRedirectPath = (user) => {
  const isSchedulingAccessOnly = isSchedulingTariffUser(user);
  const isEverypigBasic = user?.current_company.everypig_basic;
  if (isEverypigBasic) return '/barnsheets';
  if (isSchedulingAccessOnly) return '/my-schedule';

  return '/farmfeed';
};

export const permissions = (store) => (page) => (nextState, replace, done) => {
  const user = store.getState().auth?.user;
  const isSchedulingAccessOnly = isSchedulingTariffUser(user);
  const isNotEverypigBasic = !user?.current_company.everypig_basic;
  const redirectPath = getRedirectPath(user);
  const { location: { action } } = nextState; // PUSH - link click, POP - browser url change

  const hasPermission = {
    'admin': user?.roles_map.admin,
    'super-admin': user?.roles_map.super_admin,
    svr: !user?.svrs_limited_access &&
    user?.current_company.admin_svrs &&
    isNotEverypigBasic,
    'water-usage': user?.current_company.track_water_usage,
    'logistics': user?.current_company.logistics,
    'virtual_visits': user?.current_company.virtual_visits,
    'treatment_protocols': user?.current_company.treatment_protocols && isNotEverypigBasic,
    'full-access': !isSchedulingAccessOnly,
    'farmfeed': !isSchedulingAccessOnly && isNotEverypigBasic,
    'analytics': user?.roles_map.admin || user?.analytics_extended_access,
    'report-builder': isNotEverypigBasic,
    'my-schedule': user?.current_company.logistics && isNotEverypigBasic,
    'scheduling': isNotEverypigBasic,
    'messaging': isNotEverypigBasic,
    'notifications': isNotEverypigBasic,
    'integrations': isNotEverypigBasic,
  }[page];

  const isLocked =
    [
      'svr',
      'report-builder',
      'treatment_protocols',
      'farmfeed',
      'my-schedule',
      'messaging',
      'virtual_visits',
      'notifications',
      'integrations',
    ].includes(page) && !isNotEverypigBasic;

  if (isLocked) {
    if (action === 'POP' || action === 'REPLACE') {
      replace('/barnsheets');
      done();
      return;
    }

    store.dispatch(openModal(<BasicPlanModal />));

    if (action === 'PUSH') {
      // TODO: find out the way to do it better, without 'window' usage
      window.history.back();
      return;
    }
  }

  if (!hasPermission) replace(redirectPath);
  done();
};

export default (store) => ({
  permissions: permissions(store),
  preloadUser: preloadUser(store),
  requireAuth: requireAuth(store),
  handleRedirect: handleRedirect(store),
  logoutUser: logoutUser(store),
});
