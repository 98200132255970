import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { openModal } from 'reducers/modals';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage } from 'react-intl';
import DeleteTreatmentProtocolModal from '../../components/DeleteTreatmentProtocolModal';
import TreatmentProtocolProductsTable from '../../components/TreatmentProtocolProductsTable';
import Preloader from 'components/Preloader';
import TreatmentProtocolProfileHeader from '../../components/TreatmentProtocolProfileHeader';
// endpoints
import { getFarmTreatmentProtocol, updateFarmTreatmentProtocol } from 'endpoints/treatmentProtocols';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const FarmTreatmentProtocolProfile = ({
  router,
  location: { pathname },
  params: { protocolId, farmId },
  setPageOptions,
  setBreadcrumbs,
  openModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [treatmentProtocol, setTreatmentProtocol] = useState(null);

  const fetchData = async () => {
    try {
      setTreatmentProtocol(await getFarmTreatmentProtocol(farmId, protocolId));
    } catch (error) {
      toastResponseErrors(error);
      router.push(`/treatment-protocols-farms/${farmId}/treatment-protocols`);
    }
  };

  const onTreatmentProtocolStatusChange = async (isActive) => {
    try {
      setIsLoading(true);
      setTreatmentProtocol(await updateFarmTreatmentProtocol(farmId, protocolId, { active: isActive }));
    } catch (error) {
      toastResponseErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  // TODO: update next functions after back-end changes
  const onDeleteTreatmentProtocol = () => undefined;

  useEffect(() => {
    fetchData();
    setPageOptions({ backLink: `/treatment-protocols-farms/${farmId}/treatment-protocols` });
  }, []);

  useEffect(() => {
    setBreadcrumbs([
      { label: <FormattedMessage id="general.treatmentProtocolFarms" />, path: '/treatment-protocols-farms' },
      { label: treatmentProtocol?.farm.name, path: `/treatment-protocols-farms/${farmId}/treatment-protocols` },
      { label: treatmentProtocol?.name, useLabelAsMobileTitle: true },
    ]);
  }, [farmId, treatmentProtocol?.name, treatmentProtocol?.farm.name]);

  if (!treatmentProtocol) {
    return <Preloader isActive />;
  }

  const options = [
    {
      label: <FormattedMessage id="general.button.edit" />,
      onClick: () => router.push(`${pathname}/edit`),
    },
    {
      label: <FormattedMessage id={`general.button.${treatmentProtocol.active ? 'disable' : 'enable'}`} />,
      onClick: () => onTreatmentProtocolStatusChange(!treatmentProtocol.active), // passing reversed value
    },
    {
      label: <FormattedMessage id="general.button.delete" />,
      onClick: () => openModal(
        <DeleteTreatmentProtocolModal
          treatmentProtocol={treatmentProtocol}
          onDeleteTreatmentProtocol={onDeleteTreatmentProtocol}
        />
      ),
    },
  ];

  return (
    <div>
      <Preloader isActive={isLoading} />
      <TreatmentProtocolProfileHeader treatmentProtocol={treatmentProtocol} options={options} />
      <TreatmentProtocolProductsTable treatmentProducts={treatmentProtocol.treatment_protocol_products} />
    </div>
  );
};

FarmTreatmentProtocolProfile.propTypes = {
  router: T.object.isRequired,
  location: T.object.isRequired,
  params: T.object.isRequired,
  setPageOptions: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  openModal: T.func.isRequired,
};

export default connect(null, {
  setPageOptions,
  setBreadcrumbs,
  openModal,
})(FarmTreatmentProtocolProfile);
