import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { TYPE_PANEL } from 'reducers/modals';
// components
import { FormattedMessage } from 'react-intl';
import Waypoint from 'react-waypoint';
import MobileDiseasesSelector from 'components/MobileDiseasesSelector/MobileDiseasesSelector';
import ModalSidebar from 'components/Modal/ModalSidebar';
import DiagnosisSidebar from 'components/DiagnosisSidebar/DiagnosisSidebar';
import Preloader from 'components/Preloader';
import DiagnosisPanel from './DiagnosisPanel';
// requests
import { deleteBarnSheetsGroupPigGroupDiagnosis } from 'endpoints/barnsheets/groups';
// hooks
import useArrayDataState from 'hooks/useArrayDataState';
// utils
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
import { showToastrMessage } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import './BarnSheetsDiagnoses.scss';

const BarnSheetsDiagnoses = ({
  currentUser,
  farm,
  fetchDiagnoses,
  isUserCanDiagnose,
  openModal,
  openPortal,
  pathname,
  pigGroup,
  updateCheckupDiagnoses,
  pgDiagnosis,
}) => {
  const [{ isLoading, meta, resources }, fetchData, fetchNextPage] = useArrayDataState(fetchDiagnoses);
  const hasMoreItems = resources.length < meta.total;
  const isLoadingMore = !!resources.length && isLoading;

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmitSidebarData = (checkupId, data) => {
    return updateCheckupDiagnoses(checkupId, data)
      .then(() => {
        showToastrMessage('component.toastr.diagnoses.successUpdated');
        fetchData({ page: 1 });
      })
      .catch(toastResponseErrors);
  };

  const handleRemoveDiagnosis = ({ id, daily_checkup_id }) => {
    return updateCheckupDiagnoses(daily_checkup_id, [{ id, _destroy: true }])
      .then(() => {
        showToastrMessage('component.toastr.diagnoses.successUpdated');
        fetchData({ page: 1 });
      })
      .catch(toastResponseErrors);
  };

  const handleRemovePigGroupDiagnosis = (diagnosis) => {
    deleteBarnSheetsGroupPigGroupDiagnosis(pigGroup.id, diagnosis.id)
      .then(() => {
        fetchData({ page: 1 });
        showToastrMessage('component.toastr.healthStatuses.successUpdated');
      })
      .catch(toastResponseErrors);
  };

  const handleOpenSidebar = (diagnosis) => {
    const { daily_checkup_id, id, pig_group } = diagnosis;

    if (isMobile) {
      openPortal(
        <MobileDiseasesSelector
          checkupId={daily_checkup_id}
          pigGroup={pig_group || pigGroup}
          onSubmitData={(data) => handleSubmitSidebarData(daily_checkup_id, data)}
          diagnosisId={id}
        />
      );
    } else {
      openModal(
        <ModalSidebar>
          <DiagnosisSidebar
            checkupId={daily_checkup_id}
            pigGroup={pig_group || pigGroup}
            onSubmitData={(data) => handleSubmitSidebarData(daily_checkup_id, data)}
            diagnosisId={id}
          />
        </ModalSidebar>,
        {},
        TYPE_PANEL
      );
    }
  };

  const getDropdownOptions = (diagnosis) => {
    if (isUserCanDiagnose) {
      return pgDiagnosis
        ? [
          {
            label: <FormattedMessage id="general.deleteHealthStatus" />,
            onClick: () => handleRemovePigGroupDiagnosis(diagnosis)
          },
        ]
        : [
          { label: <FormattedMessage id="general.editDiagnosis" />, onClick: () => handleOpenSidebar(diagnosis) },
          { label: <FormattedMessage id="general.deleteDiagnosis" />, onClick: () => handleRemoveDiagnosis(diagnosis) },
          { label: <FormattedMessage id="general.viewCheckup" />,
            url: `/barnsheets/daily-checkup/${diagnosis.daily_checkup_id}?from=${pathname}` },
        ];
    }
    return [];
  };

  return (
    <div className="BarnSheetsDiagnoses">
      <Preloader isActive={isLoading} />
      {resources.length
        ? resources.map((diagnosis, index) => (
          <DiagnosisPanel
            key={index}
            diagnosis={diagnosis}
            currentUser={currentUser}
            onEdit={() => handleOpenSidebar(diagnosis)}
            dropdownOptions={getDropdownOptions(diagnosis)}
            pigGroup={pigGroup}
            farm={farm}
            pgDiagnosis={pgDiagnosis}
          />
        ))
        : <div className="placeholder"><FormattedMessage id="general.no_data" /></div>}

      <div className={cn('waypoint', { hidden: !hasMoreItems })}>
        <Preloader isActive={isLoadingMore} />
        {hasMoreItems &&
          <Waypoint onEnter={fetchNextPage} />
        }
      </div>
    </div>
  );
};

BarnSheetsDiagnoses.defaultProps = {
  pgDiagnosis: false,
};

BarnSheetsDiagnoses.propTypes = {
  currentUser: T.oneOfType([T.node, T.object]),
  farm: T.object,
  fetchDiagnoses: T.func.isRequired,
  isUserCanDiagnose: T.bool,
  openModal: T.func.isRequired,
  openPortal: T.func.isRequired,
  pathname: T.string,
  pigGroup: T.object,
  updateCheckupDiagnoses: T.func.isRequired,
  pgDiagnosis: T.bool,
};

export default BarnSheetsDiagnoses;
