import React, { Fragment, Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import Preloader from 'components/Preloader';
import ReduxToastr from 'react-redux-toastr';
import ModalsContainer from './ModalsContainer/ModalsContainer';
import RootPortal from './RootPortal/RootPortal';
import RootRightToLeftPortal from './RootPortal/RootRightToLeftPortal';
import CurrentUserManager from './CurrentUserManager/CurrentUserManager';
import CurrentUserCompanyManager from './CurrentUserManager/CurrentUserCompanyManager';
import NetworkManager from 'containers/NetworkManager/NetworkManager';
// utils
import moment from 'moment-timezone';
import classnames from 'classnames';
import { isNewContentAvailable, setLSNewContentAvailability } from 'utils/localStorageHelper';
import { checkSafariPermission } from 'utils/pushNotificationsHelper';
import { isCompletedProdUser, isUserHasProductionRole } from 'utils/userHelper';
import { injectIntl } from 'react-intl';
import { isIOS, isTablet, isMobile } from 'react-device-detect';
import isEmpty from 'lodash.isempty';
// initialize
import 'react-dates/initialize';
// styles
import 'react-redux-toastr/src/styles/index.scss';
import './redux-toastr.scss';
import './AppContainer.scss';

// convert using the TZDB identifier for US Central time
moment.tz.setDefault('America/Chicago');

export class AppContainer extends Component {

  state = {
    isHotReload: false,
  }

  componentDidUpdate(prevProps) {
    const { location: { pathname } } = this.props;
    const { location: { pathname: prevPathname } } = prevProps;
    if (pathname !== prevPathname) {
      if (isNewContentAvailable()) {
        setLSNewContentAvailability('false');
        this.setState({ isHotReload: true });
        window.location?.reload(true);
      }
    }
  }

  wasClicked = false;

  getChildContext = () => {
    const { intl: { formatMessage } } = this.props;
    return {
      isMobile,
      isTablet,
      isTouchable: isMobile || isTablet,
      isIOS,
      formatMessage,
    };
  };

  handleContainerClick = () => {
    const { currentUser } = this.props;
    if (this.wasClicked || isEmpty(currentUser)) return;
    if (currentUser.id) {
      checkSafariPermission();
      this.wasClicked = true;
    }
  };

  render() {
    const { children, currentUser, location: { pathname }, params } = this.props;
    const { isHotReload } = this.state;

    if (isHotReload) {
      return <Preloader isActive style={{ top: '50%' }} />;
    }

    return (
      <div
        className={classnames('AppContainer', { isMobile, isIOS })}
        onClick={this.handleContainerClick}
      >
        {children}
        <ModalsContainer />
        <RootPortal pathname={pathname} />
        <RootRightToLeftPortal pathname={pathname} />
        <ReduxToastr
          position="top-center"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          className={classnames('toastr-container', { isMobile })}
          timeOut={4000}
          showCloseButton={false}
          newestOnTop
          confirmOptions={{
            transitionIn: 'bounceIn',
            transitionOut: 'fadeOut',
            okText: 'Confirm',
            cancelText: 'Cancel',
          }}
        />
        {isCompletedProdUser(currentUser) && isUserHasProductionRole(currentUser) && (
          <Fragment>
            <CurrentUserManager user={currentUser} />
            <CurrentUserCompanyManager user={currentUser} />
            <NetworkManager pathname={pathname} params={params} />
          </Fragment>
        )}
      </div>
    );
  }
}

AppContainer.propTypes = {
  currentUser: T.object,
  intl: T.object.isRequired,
  children: T.any,
  location: T.object.isRequired,
  params: T.object.isRequired,
};

AppContainer.childContextTypes = {
  isMobile: T.bool,
  isTablet: T.bool,
  isTouchable: T.bool,
  isIOS: T.bool,
  formatMessage: T.func,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
  })
)(injectIntl(AppContainer));
