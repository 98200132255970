import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import withDropdownActions from 'hoc/withDropdownActions';
// components
import { FormattedMessage } from 'react-intl';
import Panel from 'components/Panel/Panel';
import Preloader from 'components/Preloader';
import NothingBox from 'components/NothingBox';
import DataTable from 'components/DataTable/DataTable';
import Link from 'react-router/lib/Link';
import CustomColumn from 'components/DataTable/Columns/CustomColumn';
import ArrowColumn from 'components/DataTable/Columns/ArrowColumn';
import Button from 'components/Button';
import FormattedNumber from 'components/FormattedNumber';
// utils
import cn from 'classnames/bind';
import moment from 'moment';
import round from 'lodash.round';
import { isTablet } from 'react-device-detect';
// styles
import './BarnsheetsWaterUsageTable.scss';

class BarnsheetsWaterUsageTable extends Component {
  state = {
    currentPage: 1,
    perPage: 10,
  };

  setCurrentPage = (currentPage) => this.setState({ currentPage });

  setPerPage = (perPage) => this.setState({ perPage, currentPage: 1 });

  editData = (dc_id) => {
    const { router } = this.context;
    router.push(`/daily-checkup/${dc_id}/report-water-usage?editMode=true&redirect=water-usage`);
  };

  openDropdown = (e, dc_id) => {
    const { openDropdown } = this.props;
    const options = [
      {
        label: <FormattedMessage id="general.editData" />,
        url: `/daily-checkup/${dc_id}/report-water-usage?editMode=true&redirect=water-usage`,
      },
    ];
    openDropdown(e, options);
  };

  renderDateColumn = ({ created_on, id: dc_id }) => {
    const { currentUser: { roles_map: { admin } } } = this.props;

    const formattedDate = moment(created_on, 'YYYY-MM-DD').format('MM/DD/YYYY');
    return (
      <CustomColumn label={<FormattedMessage id="general.date" />}>
        {admin ? (
          <Link
            to={`/daily-checkup/${dc_id}/report-water-usage?editMode=true&redirect=water-usage`}
            className="color-primary"
          >
            <span>{formattedDate}</span>
          </Link>
        ) : (
          <span className="сolor-primary">{formattedDate}</span>
        )}
      </CustomColumn>
    );
  };

  renderIntegrationColumn = ({ integration }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.integration" />}>
      {integration ? (
        <span className={cn('integration-badge')}>{integration}</span>
      ) : 'N/A'}
    </CustomColumn>
  );

  renderTotalConsumedColumn = ({ water_usage_value }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.totalGallonsConsumed" />}>
      <FormattedNumber value={water_usage_value} format="0,0[.]00" />
    </CustomColumn>
  );

  renderTotalInventColumn = ({ pigs }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.totalInventory" />}>
      <FormattedNumber value={pigs} format="0,0[.]00" />
    </CustomColumn>
  );

  renderGallonsPerHeadColumn = ({ gal_per_head }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.totalGallonsPerHead" />}>
      {gal_per_head ? <FormattedNumber value={gal_per_head} format="0,0[.]00" /> : 'N/A'}
    </CustomColumn>
  )

  renderChangeColumn = ({ change, water_usage_value }) => {
    let changeInner = '--';
    if (!water_usage_value) {
      changeInner = 'N/A';
    } else if (change !== 0) {
      const isNegative = change < 0;
      changeInner = (
        <span className={cn('percent-change', { 'red': isNegative })}>
          {!isNegative && '+'}
          {`${round(change, 2)}%`}
        </span>
      );
    }
    return (
      <CustomColumn label={<FormattedMessage id="component.dataTable.headers.percChanged" />}>
        {changeInner}
      </CustomColumn>
    );
  };

  renderActionsColumn = ({ id }, { rowIndex }) => {
    const { currentUser: { roles_map: { admin } } } = this.props;

    return (
      <div className="collapsible-value button-column actions">
        {admin && (
          <i
            data-name={`${id}-${rowIndex}`}
            className="fa fa-dots-three-horizontal show-for-large"
            onClick={(e) => this.openDropdown(e, id)}
          />
        )}
        {admin && (
          <Button className="hide-for-large" light onClick={() => this.editData(id)}>
            <FormattedMessage id="general.editData" />
          </Button>
        )}
      </div>
    );
  };

  paginateResources = (resources) => {
    const { perPage, currentPage } = this.state;
    return resources.slice((currentPage - 1) * perPage, currentPage * perPage);
  }

  render() {
    const { currentPage, perPage } = this.state;
    const { isLoading, data } = this.props;

    const columns = [
      { label: <FormattedMessage id="general.date" />, flex: '1 1 170px', renderer: this.renderDateColumn },
      { label: <FormattedMessage id="component.dataTable.headers.integration" />,
        flex: '1 1 170px', renderer: this.renderIntegrationColumn },
      { label: <FormattedMessage id="component.dataTable.headers.totalGallonsConsumed" />, flex: '1 1 170px',
        renderer: this.renderTotalConsumedColumn },
      { label: <FormattedMessage id="component.dataTable.headers.inventory" />,
        flex: '1 1 100px', renderer: this.renderTotalInventColumn },
      { label: <FormattedMessage id="component.dataTable.headers.totalGallonsPerHead" />,
        flex: '1 1 100px', renderer: this.renderGallonsPerHeadColumn },
      { label: <FormattedMessage id="component.dataTable.headers.percChanged" />,
        flex: '1 1 100px', renderer: this.renderChangeColumn },
      { label: '', flex: '0 0 50px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange: this.setCurrentPage,
      onPerPageChange: this.setPerPage,
      totalItems: data.length,
      currentPage,
      perPage,
    };

    const paginatedResources = this.paginateResources(data);

    return (
      <Panel className="BarnSheetsWaterUsageTable">
        <Panel.Heading title={<FormattedMessage id="general.waterUsage" />} />
        <Panel.Body noPadding>
          <Preloader isActive={isLoading} />
          <DataTable
            data={paginatedResources}
            columns={columns}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            getRowClass={({ water_usage_value }) => ({
              'pink': !water_usage_value,
            })}
            scrollable
            tableKey="barnsheetsWaterUsage"
          />
          <NothingBox
            display={!paginatedResources.length}
            isLoading={isLoading}
          >
            <h2 className="lighter"><FormattedMessage id="component.nothingBox.noData" /></h2>
          </NothingBox>
        </Panel.Body>
      </Panel>
    );
  }
}

BarnsheetsWaterUsageTable.contextTypes = {
  router: T.object.isRequired,
};

BarnsheetsWaterUsageTable.propTypes = {
  currentUser: T.object.isRequired,
  isLoading: T.bool.isRequired,
  openDropdown: T.func.isRequired,
  data: T.array.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
    isLoading: state.dataTable.barnsheetsWaterUsage.isLoading,
  })
)(withDropdownActions(BarnsheetsWaterUsageTable));
