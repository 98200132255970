import React, { PureComponent } from 'react';
import T from 'prop-types';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import AppVersionLine from 'components/AppVersionLine';
import SidebarGroup from './SidebarGroup';
import CompanySwitcher from '../CompanySwitcher';
import NotificationMessage from 'components/Notifications/NotificationMessage';
import everyPigLogo from 'assets/svg/ep-logo-white.svg';
// redux
import { connect } from 'react-redux';
import {
  fetchUnviewedActivitiesCount,
  fetchInventoriesInfo,
  fetchUnreadNotificationsCount,
  fetchGroupsInfo,
  fetchUnviewedAssetsCount,
  fetchFlaggedActivitiesCount,
} from 'reducers/sidebar';
// helpers
import getSideBarItems from './getSidebarItems';
// utils
import debounce from 'lodash.debounce';
import { isMobile } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { toastr } from 'react-redux-toastr';
import classnames from 'classnames';
import { newActivitiesDefaultSelector as newActivitiesSelector } from 'containers/Farmfeed/selectors';
// styles
import './Sidebar.scss';

// todo: find the other way to detect sidebar items overflow issue
function calculateNeededSidebarHeight(visibleSidebarItemsCount) {
  const headerHeight = 55;
  const footerHeight = 222;
  const betweenItemsDividerHeight = 42;
  const itemHeight = 38;
  return headerHeight + visibleSidebarItemsCount * itemHeight + betweenItemsDividerHeight + footerHeight;
}


class Sidebar extends PureComponent {

  constructor() {
    super();
    this.handleScreenResize = debounce(this.setSidebarView, 300);
    this.state = {
      sidebarCollapsed: window.innerHeight <= 768,
    };
  }

  componentDidMount() {
    if (this.props.isOnline) this.fetchInfo();
    window.addEventListener('resize', this.handleScreenResize);
  }

  componentDidUpdate(prevProps) {
    const { visibleSidebarItemsCount, user, isOnline } = this.props;

    if (visibleSidebarItemsCount !== prevProps.visibleSidebarItemsCount) {
      this.handleScreenResize();
    }

    if (user.current_company?.id !== prevProps.user.current_company?.id && isOnline) {
      this.fetchInfo();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleScreenResize);
  }

  setSidebarView = () => {
    const neededSidebarHeight = calculateNeededSidebarHeight(this.props.visibleSidebarItemsCount);
    const sidebarCollapsed = window.innerHeight <= neededSidebarHeight;
    this.setState((prevState) => {
      if (prevState.sidebarCollapsed === sidebarCollapsed) return null;
      return { ...prevState, sidebarCollapsed };
    });
  };

  fetchInfo = () => {
    const { user, fetchUnviewedActivitiesCount, fetchInventoriesInfo, fetchGroupsInfo, fetchUnreadNotificationsCount,
      fetchUnviewedAssetsCount, fetchFlaggedActivitiesCount, } = this.props;
    Promise.all([
      fetchUnviewedAssetsCount(),
      fetchUnreadNotificationsCount(user.id),
      fetchFlaggedActivitiesCount(),
    ]).catch(toastResponseErrors);

    fetchUnviewedActivitiesCount().then((response) => {
      const predictionCount = response?.value?.prediction_activities_count || 0;
      if (predictionCount > 0) {
        const data = {
          notification_type: 'ai_suggestion',
          options: { suggestionsCount: predictionCount }
        };

        toastr.light('', '', {
          timeOut: 6000,
          position: isMobile ? 'bottom-center' : 'top-right',
          className: 'web-notification',
          component: <NotificationMessage data={data} user={user} volume={0.75} />,
        });
      }
    }).catch(toastResponseErrors);

    if (user.roles_map.admin) {
      fetchGroupsInfo();
      fetchInventoriesInfo();
    }
  };

  toggleSidebar =() => {
    const { collapseSidebar, isCollapsed } = this.props;
    collapseSidebar(!isCollapsed);
  };

  render() {
    const { sidebarCollapsed } = this.state;
    const { user, sidebarItems, companies, isCollapsed, isTabletSidebarOpen, isHidden } = this.props;
    const isExpired = (!user.roles_map.super_admin && user.current_company.is_expired);

    return (
      <div
        className={classnames('sidebar', {
          'is-collapsed': isCollapsed,
          'is-hidden': isHidden,
          'tablet-expanded': isTabletSidebarOpen,
        })}
      >
        <header className="side-header">
          <CompanySwitcher user={user} companies={companies} compact={isCollapsed} />
        </header>

        <div className="side-content">
          <SidebarGroup
            isCollapsed={isCollapsed}
            isExpired={isExpired}
            items={sidebarItems}
            sidebarCollapsed={sidebarCollapsed}
          />
        </div>

        <footer className="side-footer">
          <Link to="/">
            <img className="side-logo" src={everyPigLogo} alt="everypig-trademarked-logo" />
          </Link>
          <AppVersionLine isSidebarView />
          <Link to="/">
            <i className="fa fa-ep-pig side-logo-minimized" />
          </Link>
        </footer>

        <div className="collapser" onClick={this.toggleSidebar}>
          <i className="fa fa-angle-left-btb" />
          <FormattedMessage id={`component.sidebar.${isCollapsed ? 'expandSidebar' : 'collapseSidebar'}`}>
            {(text) => <span className="collapser-tooltip">{text}</span>}
          </FormattedMessage>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  user: T.object.isRequired,
  sidebarItems: T.object.isRequired,
  companies: T.array,
  collapseSidebar: T.func.isRequired,
  fetchUnviewedActivitiesCount: T.func.isRequired,
  fetchInventoriesInfo: T.func.isRequired,
  fetchGroupsInfo: T.func.isRequired,
  fetchUnreadNotificationsCount: T.func.isRequired,
  fetchUnviewedAssetsCount: T.func.isRequired,
  isCollapsed: T.bool.isRequired,
  isTabletSidebarOpen: T.bool.isRequired,
  isHidden: T.bool.isRequired,
  isOnline: T.bool.isRequired,
  visibleSidebarItemsCount: T.number.isRequired,
  fetchFlaggedActivitiesCount: T.func.isRequired,
};

export default connect((state, { user, location, router }) => {
  const sidebarItems = getSideBarItems(user, location, state.sidebar, newActivitiesSelector(state).length, router);
  const visibleSidebarItemsCount = [...sidebarItems.primary, ...sidebarItems.secondary, ...sidebarItems.more]
    .filter(({ hide }) => !hide).length;

  return {
    sidebarItems,
    visibleSidebarItemsCount,
    isOnline: state.network.isOnline,
  };
}, {
  fetchUnviewedActivitiesCount,
  fetchInventoriesInfo,
  fetchGroupsInfo,
  fetchUnreadNotificationsCount,
  fetchUnviewedAssetsCount,
  fetchFlaggedActivitiesCount,
})(Sidebar);
