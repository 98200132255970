import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import PackingCompanyForm from '../components/PackingCompanyForm';
import FormErrorsBox from 'components/FormErrorsBox';
import Panel from 'components/Panel';
// api
import { createPackingPlant } from 'endpoints/admin/packingPlants';
import { createPackingCompany } from 'endpoints/admin/companies';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';

const customErrors = [
  { key: 'name', error: <FormattedMessage id="general.customErrors.company" /> },
];

class PackingCompanyCreate extends Component {

  createNewCompany = (data) => {
    const { router } = this.context;
    const companyData = {
      name: data.name,
      ...data.addressObj,
    };

    return createPackingCompany(companyData)
      .then((company) => {
        createPackingPlant(company.id, companyData)
          .then(() => {
            showToastrMessage('component.toastr.packingCompany.created');
            showToastrMessage('component.toastr.packingPlant.created');
            router.push(`/admin/packing-companies/${company.id}`);
          });
      }).catch(toastResponseErrors);
  };

  render() {
    return (
      <section className="small-12 column">
        <Panel>
          <Panel.Heading title={<FormattedMessage id="general.button.createPackingCompany" />} />
          <Panel.Body noPadding>
            <FormErrorsBox
              formName="address-form"
              customErrors={customErrors}
            />
            <PackingCompanyForm hasLocationDefining onSubmit={this.createNewCompany} />
          </Panel.Body>
        </Panel>
      </section>
    );
  }
}

PackingCompanyCreate.contextTypes = {
  router: T.object.isRequired,
};

export default PackingCompanyCreate;
