import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { postComment } from 'reducers/farmfeed';
// components
import MentionsInput from 'components/MentionsInput';
import FarmFeedAddComment from './FarmFeedAddComment';
import SpeechToText from 'components/SpeechToText';
// hooks
import useLoading from 'hooks/useLoading';
import useCommentWithMentionsState from 'hooks/useCommentWithMentionsState';
// utils
import { isMobile } from 'react-device-detect';
import { getActivityMentionsInputData } from 'utils/activityHelper';
import classnames from 'classnames/bind';
// styles
import styles from './FarmFeedRowAddComment.scss';

const cn = classnames.bind(styles);

export const FarmFeedRowAddComment = ({
  currentUser,
  activity,
  postComment,
}) => {
  const { comment, mentions, setComment, setMentions } = useCommentWithMentionsState();
  const [commentInterim, setCommentInterim] = useState('');

  const [handlePostComment, isSubmitting] = useLoading(() => {
    const fullComment = `${comment || ''}${commentInterim || ''}`;
    if (!fullComment) return null;
    const comment_mentions_attributes = mentions.map((mention) => ({
      user_id: parseInt(mention.id, 10),
    }));
    return postComment(activity.id, { comment: fullComment, comment_mentions_attributes }).then(() => {
      setComment('');
      setCommentInterim('');
      setMentions([]);
    });
  });

  const handlePressEnterKey = (e) => {
    if (e.key === 'Enter' && !isMobile) {
      e.preventDefault();
      handlePostComment();
    }
  };

  return (
    <FarmFeedAddComment
      activityComment
      activity={activity}
      commentOnPost={handlePostComment}
      commentOnEnter={handlePressEnterKey}
      currentUser={currentUser}
      isPostDisabled={isSubmitting || (!comment && !commentInterim)}
    >
      <div className={cn('speech-to-text-container')}>
        <SpeechToText
          setTextMain={setComment}
          setTextInterim={setCommentInterim}
          className="speech-to-text"
          buttonClassName="control-button cb-small main-control primary"
          iconClassName="speech-icon-small"
        />
        <div className={cn('mentions-input-block')}>
          <MentionsInput
            comment={`${comment || ''}${commentInterim || ''}`}
            disabledIds={mentions.map((mention) => mention.id)}
            hasMentionBtn
            onCommentChange={setComment}
            onMentionsChange={setMentions}
            {...getActivityMentionsInputData(activity, currentUser)}
          />
        </div>
      </div>
    </FarmFeedAddComment>
  );
};

FarmFeedRowAddComment.propTypes = {
  activity: T.object.isRequired,
  currentUser: T.object.isRequired,
  postComment: T.func.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
  }),
  { postComment }
)(FarmFeedRowAddComment);
