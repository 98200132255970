import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { reportTemperatures } from 'reducers/dailyCheckup/checkupEdit';
// components
import { FormattedMessage } from 'react-intl';
import CheckupSegment from './CheckupSegment/CheckupSegment';
import SegmentComment from './CheckupSegmentComment/SegmentComment';
import FormattedTemperature from 'components/FormattedTemperature';
// utils
import { getCommentByRole } from 'helpers.js';
import { getCheckupSegmentHeaderData, isSomeSectionReporting, thirdPartyTemperatureDevices } from 'utils/checkupHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import pick from 'lodash.pick';
// styles
import styles from './ReportedSegment.module.scss';
// constants
import { comment_roles } from 'constants.js';

const { TEMPERATURE_ROLE } = comment_roles;

const ReportTempsSegment = ({
  checkup,
  paramsCheckupId,
  isDisabled,
  isSubmitting,
  reportTemperatures,
  validateCheckup,
}) => {
  const [isAllGoodReported, setIsAllGoodReported] = useState(false);
  const { comments, temperature_reported, high_temperature, low_temperature,
    edge_temperature, maximus_temperature, dicam_temperature, barn_tools_temperature,
  } = checkup;
  const isEmptyTemps = (high_temperature === null) && (low_temperature === null);
  const isReported = Boolean(
    temperature_reported ||
    edge_temperature ||
    maximus_temperature ||
    dicam_temperature ||
    barn_tools_temperature
  );

  const handleReportNoTemperatures = () => {
    if (!validateCheckup(checkup)) return;
    setIsAllGoodReported(true);
    reportTemperatures(paramsCheckupId, { all_good: true }).catch(toastResponseErrors);
  };

  // value taken from AP device: edge, maximus, dicam, barntool
  const temperatureDeviceValue = edge_temperature || maximus_temperature || dicam_temperature;

  return (
    <CheckupSegment
      isDisabled={isDisabled}
      isSegmentConditionChanged={isAllGoodReported}
      isSubmitting={isSubmitting}
      isReported={isReported}
      onEmptyDataSubmit={handleReportNoTemperatures}
      isItemsCountHidden={temperature_reported && !isEmptyTemps}
      titleKey="component.checkupSegment.didYouRecordTemps"
      titleKeyWhenReported="general.highLowTemps"
      reportPageUrl={`/daily-checkup/${paramsCheckupId}/report-temps`}
      itemsCountPlaceholder="N/A"
      noData={isReported && isEmptyTemps}
      isButtonsHidden={(temperatureDeviceValue && !isEmptyTemps) || barn_tools_temperature}
      extendedHeaderData={getCheckupSegmentHeaderData(pick(checkup, thirdPartyTemperatureDevices))}
    >
      <div className={styles['reported-segment']}>
        {!isEmptyTemps && (
          <div>
            <div className={styles.line}>
              <FormattedMessage id="general.high" />
              <span className="semibold">
                <FormattedTemperature value={high_temperature} />
              </span>
            </div>
            <div className={styles.line}>
              <FormattedMessage id="general.low" />
              <span className="semibold">
                <FormattedTemperature value={low_temperature} />
              </span>
            </div>
          </div>
        )}
        <SegmentComment comment={getCommentByRole(comments, TEMPERATURE_ROLE)} />
      </div>
    </CheckupSegment>
  );
};

ReportTempsSegment.propTypes = {
  checkup: T.object.isRequired,
  paramsCheckupId: T.oneOfType([T.string, T.number]).isRequired,
  isDisabled: T.bool.isRequired,
  isSubmitting: T.bool.isRequired,
  reportTemperatures: T.func.isRequired,
  validateCheckup: T.func.isRequired,
};

export default connect(
  ({ dailyCheckup }) => ({
    isSubmitting: dailyCheckup.checkupEdit.isReportingTemps,
    isDisabled: isSomeSectionReporting(dailyCheckup.checkupEdit) && !dailyCheckup.checkupEdit.isReportingTemps,
  }), {
    reportTemperatures,
  }
)(ReportTempsSegment);
