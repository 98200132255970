import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
// utils
import moment from 'moment';
import classnames from 'classnames/bind';
// styles
import styles from './SVRHeader.module.scss';

const cn = classnames.bind(styles);

const dateFormat = 'MMMM, D YYYY';

const SVRHeader = ({ createdAt, children, farmName, onClose, farmId, templateId }) => (
  <div className={cn('svr-header')}>
    <i onClick={onClose} className={cn('fa fa-times', 'close-icon')} />
    <div className={cn('farm-info')}>
      <Link
        to={`/report-farms/${farmId}/report-templates/${templateId}/reports`}
        className={cn('farm-name')}
      >
        {farmName}
      </Link>
      <span className={cn('vertical-divider')}>|</span>
      <div className={cn('svr-title')}>
        <FormattedMessage id="general.report" />
        <span className={cn('vertical-divider')}>|</span>
      </div>
      <span className="semibold">{moment(createdAt).format(dateFormat)}</span>
    </div>
    {children}
  </div>
);

SVRHeader.propTypes = {
  createdAt: T.string,
  children: T.oneOfType([T.arrayOf(T.node), T.node]),
  farmName: T.string.isRequired,
  onClose: T.func.isRequired,
  farmId: T.number.isRequired,
  templateId: T.oneOfType([T.string, T.number]).isRequired,
};

export default SVRHeader;
