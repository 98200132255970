import React, { Component } from 'react';
import T from 'prop-types';
// utils
import withOutsideClick from 'react-onclickoutside';
import cn from 'classnames';
import round from 'lodash.round';
// styles
import './Dropdown.scss';

class Dropdown extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      direction: '',
      sideDirection: '',
    };
  }

  componentDidMount() {
    const { container } = this.props;
    this.container = container || document.getElementById('root');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { container } = this.props;
    if (nextProps.container !== container) {
      this.container = nextProps.container;
    }
  }

  closeDropdown = () => {
    this.setState({
      isOpen: false,
      transform: '',
    });
  };

  handleClickOutside = () => {
    const { onToggle } = this.props;
    if (this.state.isOpen) {
      this.closeDropdown();
      if (onToggle) onToggle(false);
    }
  };

  openInDirection = () => {
    const dropdownBounds = this.dropdown.getBoundingClientRect();
    const dropdownBoxBounds = this.dropdownBox.getBoundingClientRect();
    const containerBounds = this.container.getBoundingClientRect();

    const canOpenBottom = (dropdownBoxBounds.bottom + dropdownBounds.height) < containerBounds.bottom;
    const canOpenTop = (dropdownBoxBounds.top - dropdownBounds.height) > containerBounds.top;
    const canOpenLeft = (dropdownBoxBounds.right + dropdownBounds.width) > containerBounds.right;
    const direction = (canOpenBottom && 'bottom') || (canOpenTop && 'above') || 'center';
    const sideDirection = canOpenLeft ? 'left-side' : '';
    const rightOffset = window.screen.width - dropdownBoxBounds.right;

    this.setState({
      isOpen: true,
      direction,
      sideDirection,
      transform: (!canOpenTop && !canOpenBottom) && '',
    });

    if (this.props.onlyDesktop) return;

    const { isMobile, isTablet } = this.context;
    const onlyMobile = isMobile && !isTablet;

    // -10 - because of right margin
    const triangleOffset = ((direction !== 'center') && onlyMobile) ? (round(rightOffset, 0) - 10) + 'px' : 0;

    this.dropdown.style.setProperty('--triangleOffset', triangleOffset);
  };

  toggleDropdown = (e) => {
    const { isOpen } = this.state;
    const { onToggle, stopPropagation } = this.props;
    if (stopPropagation) e.stopPropagation();
    if (onToggle) onToggle(!isOpen);
    if (isOpen) {
      this.closeDropdown();
    } else {
      this.openInDirection();
    }
  };

  render() {
    const { isOpen, direction, transform, sideDirection } = this.state;
    const { isMobile, isTablet } = this.context;
    const { trigger, children, closeOnClick, className, dropdownClassName, withBackdrop, onlyDesktop } = this.props;
    const onlyMobile = isMobile && !isTablet && !onlyDesktop;
    const dropdownClass = cn('dropdown', direction, sideDirection, dropdownClassName, { 'active': isOpen, isMobile });
    const dropdownStyle = onlyMobile ? { transform, right: '-100%' } : { transform };
    const toggleDropdown = this.props.toggleDropdown || this.toggleDropdown;

    return (
      <div ref={(ref) => { this.dropdownBox = ref; }} className={cn('DropdownComponent', className)}>
        <div onClick={toggleDropdown}>{trigger}</div>
        <div
          ref={(ref) => { this.dropdown = ref; }}
          className={dropdownClass}
          style={dropdownStyle}
          onClick={closeOnClick ? toggleDropdown : null}
        >
          {children}
        </div>
        {withBackdrop && (
          <div className={cn('dropdown-backdrop', { 'visible': false })} onClick={toggleDropdown} />
        )}
      </div>
    );
  }
}

Dropdown.contextTypes = {
  isMobile: T.bool,
  isTablet: T.bool,
};

Dropdown.propTypes = {
  trigger: T.node,
  container: T.object,
  children: T.node.isRequired,
  closeOnClick: T.bool,
  className: T.string,
  dropdownClassName: T.string,
  withBackdrop: T.bool,
  onToggle: T.func,
  stopPropagation: T.bool,
  onlyDesktop: T.bool,
  toggleDropdown: T.func,
};

Dropdown.defaultProps = {
  closeOnClick: false,
};

export default withOutsideClick(Dropdown);
