import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
// components
import { FormattedMessage } from 'react-intl';
import Field from 'components/Field';
import Button from 'components/Button';
import ToggleSwitcher from 'components/ToggleSwitcher/index';
// utils
import get from 'lodash.get';
import classnames from 'classnames/bind';
// styles
import styles from './DistynctForm.module.scss';

const cn = classnames.bind(styles);

const labels = {
  barnTemperature: <FormattedMessage id="component.distynct.barnTemperature" />,
  waterUsage: <FormattedMessage id="component.distynct.waterUsage" />,
  mortalityEvents: <FormattedMessage id="component.distynct.mortalityEvents" />,

  dataPushed: <FormattedMessage id="component.distynct.dataPushed" />,
  dataPulled: <FormattedMessage id="component.distynct.dataPulled" />,

};

const dataPulled = [
  {
    key: 'pulled-0',
    text: labels.barnTemperature,
    name: 'import_temperature',
  },
  {
    key: 'pulled-1',
    text: labels.waterUsage,
    name: 'import_water_usage',
  }
];

const Box = ({ title, children, className }) => (
  <div className={cn('box-group', 'mt-20 mb-20', className)}>
    <div className={cn('side-name')}>
      <span className={cn('rotated')}>
        {title}
      </span>
    </div>
    <div className={cn('box-body')}>
      {children}
    </div>
  </div>
);

Box.propTypes = {
  title: T.oneOfType([T.string, T.object]).isRequired,
  children: T.node.isRequired,
  className: T.string,
};

const DistynctForm = ({
  handleForm,
  resetForm,
  handleSubmit,
  formValues,
  connected,
  switchToggle,
  active,
}) => {
  const isSubmitDisabled = !formValues?.client_id?.trim() ||
    !formValues.client_secret?.trim();

  const onSubmit = (data) => handleForm(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cn('distynct-form')}>

      <Field
        placeholderKey="general.typeClientId"
        label={<FormattedMessage id="general.clientId" />}
        name="client_id"
      />

      <Field
        placeholderKey="general.typeClientSecret"
        label={<FormattedMessage id="general.clientSecret" />}
        name="client_secret"
      />

      <Box
        className={cn({ disabled: !connected || !active })}
        title={labels.dataPulled}
      >
        {dataPulled.map(({ text, name, key }) => (
          <ToggleSwitcher
            key={key}
            checked={get(formValues, name, true)}
            text={text}
            onChange={({ target: { checked } }) => switchToggle(name, checked)}
            isDisabled={!connected || !active}
          />
        ))}
      </Box>

      <div className="mt-10">
        <Button type="submit" primary className={cn('wider', 'btn-item')} disabled={isSubmitDisabled}>
          <FormattedMessage id={`general.button.${!connected ? 'connect' : 'save'}`} />
        </Button>
        <Button type="reset" light className={cn('wider', 'btn-item')} onClick={resetForm}>
          <FormattedMessage id="general.button.reset" />
        </Button>
      </div>
    </form>
  );
};

DistynctForm.propTypes = {
  handleForm: T.func.isRequired,
  resetForm: T.func.isRequired,
  handleSubmit: T.func.isRequired,
  formValues: T.object,
  connected: T.bool.isRequired,
  switchToggle: T.func.isRequired,
  active: T.bool.isRequired,
};

const formComponent = reduxForm({
  form: 'distynct-form',
  enableReinitialize: true,
})(DistynctForm);

export default connect(
  (state) => ({
    formValues: getFormValues('distynct-form')(state),
  })
)(formComponent);
