import React from 'react';
import T from 'prop-types';
// redux
import { updateCheckupDiagnoses } from 'reducers/dailyCheckup/checkupDiagnoses';
import { openModal } from 'reducers/modals';
import { openPortal } from 'reducers/portal';
import { connect } from 'react-redux';
// components
import BarnSheetsDiagnoses from '../components/BarnSheetsDiagnoses';
// requests
import { fetchBarnSheetsGroupPigGroupDiagnoses } from 'endpoints/barnsheets/groups';
// utils
import { isUserCanDiagnose } from 'utils';

const BarnSheetsGroupPigGroupDiagnoses = ({
  currentUser,
  location,
  openModal,
  openPortal,
  params: { id },
  pigGroup,
  updateCheckupDiagnoses,
}) => {
  const fetchPigGroupDiagnoses = (reqParams) => {
    return fetchBarnSheetsGroupPigGroupDiagnoses(id, reqParams);
  };
  const canDiagnose = isUserCanDiagnose(currentUser, pigGroup?.farm, pigGroup?.farm?.manager_id);

  return (
    <BarnSheetsDiagnoses
      currentUser={currentUser}
      farm={pigGroup?.farm}
      fetchDiagnoses={fetchPigGroupDiagnoses}
      isUserCanDiagnose={canDiagnose}
      openModal={openModal}
      openPortal={openPortal}
      pathname={location.pathname}
      pigGroup={pigGroup}
      updateCheckupDiagnoses={updateCheckupDiagnoses}
      pgDiagnosis
    />
  );
};

BarnSheetsGroupPigGroupDiagnoses.propTypes = {
  updateCheckupDiagnoses: T.func,
  openModal: T.func,
  openPortal: T.func,
  params: T.object,
  location: T.object,
  currentUser: T.oneOfType([T.node, T.object]),
  pigGroup: T.object,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
    pigGroup: state.barnsheets.groupCheckups.pigGroup,
  }), {
    updateCheckupDiagnoses,
    openModal,
    openPortal,
  },
)(BarnSheetsGroupPigGroupDiagnoses);
