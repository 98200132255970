import React, { useEffect, useMemo } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchDeathsStats, fetchMortalityStats, clearData } from 'reducers/autoReport';
import { fetchUserCompanies } from 'reducers/auth';
import { setTableParams } from 'reducers/tableParams';
import { setPageOptions } from 'reducers/layout';
import { fetchShareholderGroupsList } from 'reducers/staticData';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import TableFilter from 'components/TableFilter';
import FarmTypeLabel from 'components/FarmTypeLabel';
import DataTable from 'components/DataTable/DataTable';
import CustomColumn from 'components/DataTable/Columns/CustomColumn';
import SearchBox from 'components/SearchBox';
import CompanyProfileHeader from 'components/ProfileHeader/CompanyProfileHeader/CompanyProfileHeader';
import Button from 'components/Button';
import NothingBox from 'components/NothingBox';
import SubNavigation from 'components/Subnavigation';
import InlineGraph from 'components/InlineGraph/InlineGraph';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DatePicker from './components/DatePicker';
import AutoReportGraphs from './components/AutoReportGraphs';
import FormattedWeight from 'components/FormattedWeight';
import OfflineScreen from 'components/OfflineScreen';
import ABFBadge from 'components/ABFBadge';
// auto reports api
import { fetchAutoReportsPigGroups, fetchAutoReportsPigGroupsCSV } from 'endpoints/autoReports';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
// utils
import download from 'downloadjs';
import moment from 'moment';
import classnames from 'classnames/bind';
import isEmpty from 'lodash.isempty';
import { isMobile } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatMortality } from 'utils';
import { tableNames } from 'utils/constants';
// styles
import styles from './AutoReport.module.scss';
// constants
import { farmTypes } from 'constants.js';
import { BACKEND_DATE_FORMAT, CURRENT_WEEK_MOMENT, CURRENT_MONTH_MOMENT } from 'constants/dates';

const cn = classnames.bind(styles);

const currentDay = moment().format(BACKEND_DATE_FORMAT);
const prevDay = moment().subtract(1, 'day').format(BACKEND_DATE_FORMAT);
const currentWeekStart = moment().week(CURRENT_WEEK_MOMENT).startOf('week').format(BACKEND_DATE_FORMAT);
const currentWeekEnd = moment().week(CURRENT_WEEK_MOMENT).endOf('week').format(BACKEND_DATE_FORMAT);
const currentMonthStart = moment().month(CURRENT_MONTH_MOMENT).startOf('month').format(BACKEND_DATE_FORMAT);
const currentMonthEnd = moment().month(CURRENT_MONTH_MOMENT).endOf('month').format(BACKEND_DATE_FORMAT);

const prevDeathsColumns = (periodType, startDate) => {
  if (periodType === 'weekly') {
    return Array.from(new Array(5), (value, index) => (moment(startDate).subtract(index, 'week').week()));
  }
  if (periodType === 'monthly') {
    return Array.from(new Array(5), (value, index) => (moment(startDate).subtract(index, 'month').format('MMM YYYY')));
  }
  return Array.from(new Array(5), (value, index) => (moment(startDate).subtract(index, 'day').format('DD/MM/YYYY')));
};

const getSelectedSubNavItem = (items, query) => {
  let selectedSubNavItem = null;
  if (query?.company_id) {
    selectedSubNavItem = items.find(
      ({ id, type }) => (id === Number(query.company_id) && (type === 'company'))
    );
  }
  if (query?.shareholder_group_id) {
    selectedSubNavItem = items.find(
      ({ id, type }) => (id === Number(query.shareholder_group_id) && (type === 'shareholder_group'))
    );
  }
  return selectedSubNavItem;
};

const reportPeriodLabels = { weekly: 'Week', daily: 'Day', monthly: 'Month' };

const tableFilters = [
  { label: <FormattedMessage id="general.allStages" />, value: '' },
  ...farmTypes,
];

const AutoReport = ({
  companies,
  currentUser,
  clearData,
  deathsParams,
  fetchDeathsStats,
  fetchMortalityStats,
  fetchUserCompanies,
  fetchShareholderGroupsList,
  isOnline,
  location,
  mortalityParams,
  reportPeriod,
  setPageOptions,
  setTableParams,
  shareholderGroups,
  tableParams,
}, {
  router,
}) => {
  const {
    data: { resources, isLoading, meta },
    fetchData,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onSearchChange,
  } = useDataTable(fetchAutoReportsPigGroups, {
    setTableParams: (params) => setTableParams(tableNames.autoReports, params),
    tableParams,
  });
  const subNavItems = useMemo(() => [
    ...companies.map((company) => ({ ...company, type: 'company' })),
    ...shareholderGroups.map((group, index) => ({ ...group, type: 'shareholder_group', divider: !index })),
  ], [companies, shareholderGroups]);
  const selectedSubNavItem = getSelectedSubNavItem(subNavItems, location.query);
  const isCurrentUserAdmin = currentUser.roles_map?.admin;
  const { page, per_page, search, sort, farm_type, date_start, date_end } = tableParams;
  const { total, stats } = meta;

  const getTableParams = () => {
    const { query } = location;
    const defaultParams = { ...tableParams, sort: 'avg_mortality desc', per_page: 10 };
    if (query.date_end || query.date_start) {
      return {
        ...defaultParams,
        date_start: moment(query.date_start).format(BACKEND_DATE_FORMAT),
        date_end: moment(query.date_end).format(BACKEND_DATE_FORMAT),
        company_id: query.company_id,
        shareholder_group_id: query.shareholder_group_id,
      };
    }
    if (reportPeriod === 'weekly') {
      return { ...defaultParams, date_start: currentWeekStart, date_end: currentWeekEnd };
    }
    if (reportPeriod === 'monthly') {
      return { ...defaultParams, date_start: currentMonthStart, date_end: currentMonthEnd };
    }
    return { ...defaultParams, date_start: prevDay, date_end: prevDay };
  };

  useEffect(() => {
    if (isOnline) {
      fetchData(getTableParams());
      fetchUserCompanies();
      fetchShareholderGroupsList();
    }
  }, [isOnline]);

  useEffect(() => {
    setPageOptions({ mobileTitle: 'autoReport' });
    return () => {
      setPageOptions({ mobileTitle: '' });
      clearData();
    };
  }, []);

  const handleChangeFilter = (filter) => {
    fetchData({ ...tableParams, page: 1, farm_type: filter });
  };

  const handleChangeSubNav = (item = {}) => {
    const company_id = item.type === 'company' ? item.id : undefined;
    const shareholder_group_id = item.type === 'shareholder_group' ? item.id : undefined;
    if (isOnline) {
      fetchData({ ...tableParams, company_id, shareholder_group_id });
      fetchDeathsStats({ ...deathsParams, company_id, shareholder_group_id });
      fetchMortalityStats({ ...mortalityParams, company_id, shareholder_group_id });
      router.push({ pathname: location.pathname, query: { company_id, shareholder_group_id } });
    }
  };

  const handleChangeDates = ({ startDate, endDate }) => {
    const date_start = moment(startDate).format(BACKEND_DATE_FORMAT);
    const date_end = moment(endDate).format(BACKEND_DATE_FORMAT);
    fetchData({ ...tableParams, farm_type: '', date_start, date_end });
    if (!isEmpty(location.query)) {
      router.push({
        pathname: location.pathname,
        query: { company_id: location.query?.company_id, shareholder_group_id: location.query?.shareholder_group_id },
      });
    }
  };

  const isSubNavItemActive = ({ id, type }) => {
    if (!selectedSubNavItem) return false;
    return selectedSubNavItem.id === id && selectedSubNavItem.type === type;
  };

  const handleDownloadCSV = (e) => {
    e.stopPropagation();
    const { date_start, date_end, company_id, shareholder_group_id } = tableParams;
    const filename = `auto-report-${date_start}-${date_end}.csv`;
    const params = { date_start, date_end, company_id, shareholder_group_id, no_pagination: true };
    return fetchAutoReportsPigGroupsCSV(params)
      .then((blob) => download(blob, filename, 'text/csv'))
      .catch(toastResponseErrors);
  };

  const getSourceName = (source) => {
    return isCurrentUserAdmin
      ? <Link className={cn('first-source')} to={`/sources/${source.id}/`}>{source.name}</Link>
      : <span>{source.name}</span>;
  };

  const renderProducerColumn = (rowData) => (
    <CustomColumn>
      <strong>{rowData.f_owner_name || <FormattedMessage id="general.notAvail" />}</strong>
    </CustomColumn>
  );

  const renderFarmNameColumn = (rowData) => (
    <CustomColumn>
      <strong className="mr-10">{rowData.f_name}</strong>
      <FarmTypeLabel farmType={rowData.f_type} />
    </CustomColumn>
  );

  const renderGroupNameColumn = (rowData) => (
    <CustomColumn>
      {rowData.pg_name}
    </CustomColumn>
  );

  const renderABFStatusColumn = (rowData) => (
    <CustomColumn>
      {rowData.pg_abf_tracking ? <ABFBadge /> : 'N/A'}
    </CustomColumn>
  );

  const renderPigsInColumn = (rowData) => (
    <CustomColumn>
      {Number(rowData.pigs_in)}
    </CustomColumn>
  );

  const renderInventoryColumn = (rowData) => (
    <CustomColumn>
      {Number(rowData.inventory)}
    </CustomColumn>
  );

  const renderGroupStartColumn = (rowData) => (
    <CustomColumn>
      {rowData.first_placement_date || '-'}
    </CustomColumn>
  );

  const renderWeeksOnFeedColumn = (rowData) => (
    <CustomColumn>
      {rowData.days_on_feed ? Math.ceil(rowData.days_on_feed / 7) : '-'}
    </CustomColumn>
  );

  const renderMortalityColumn = (rowData) => (
    <CustomColumn flexRow>
      <span className="mr-5">{formatMortality(rowData.avg_mortality)}</span>
      <span className={cn('mortality-graph')}>
        <InlineGraph preserveAspectRatio="xMaxYMax meet" height={15} width={100} data={rowData.mortality_rates} />
      </span>
    </CustomColumn>
  );

  const renderWeightColumn = (rowData) => (
    <CustomColumn>
      <FormattedWeight value={rowData.est_avg_weight} />
    </CustomColumn>
  );

  const renderCurrentDeadColumn = (rowData) => (
    <CustomColumn className={cn('current-deaths')}>
      {rowData.total_deaths || 0}
    </CustomColumn>
  );

  const renderPrevDeadColumn = (valueKey) => (data) => (
    <CustomColumn>
      {data[valueKey] || 0}
    </CustomColumn>
  );

  const renderDeadPercentColumn = (rowData) => (
    <CustomColumn>
      {rowData.death_percent}%
    </CustomColumn>
  );

  const renderSourcesColumn = (rowData) => (
    <CustomColumn noBottomBorder flexRow>
      <span>
        {!isEmpty(rowData.source)
          ? getSourceName(rowData.source)
          : <FormattedMessage id="general.notAvail" />}
      </span>
    </CustomColumn>
  );

  const getDeathsColumns = () => {
    const { date_start } = tableParams;
    const period = reportPeriodLabels[reportPeriod];
    return prevDeathsColumns(reportPeriod, date_start).map((column, index) => {
      const label = (
        <FormattedMessage id={`component.dataTable.headers.totalDeadFor${period}`} values={{ count: column }} />
      );
      const valueKey = `total_deaths_${index}`;
      return index
        ? { label, flex: '1 0 100px', renderer: renderPrevDeadColumn(valueKey) }
        : {
          label: <FormattedMessage
            id={`component.dataTable.headers.totalDeadForCurrent${period}`}
            values={{ count: column }}
          />, flex: '1 0 140px', renderer: renderCurrentDeadColumn
        };
    });
  };

  const isEmptyTable = !resources.length && !farm_type && !search;

  const columns = [
    {
      label: <FormattedMessage id="component.dataTable.headers.groupId" />,
      flex: '1 0 100px',
      renderer: renderGroupNameColumn,
      sortKey: 'pg_name',
      fixed: true,
    },
    {
      label: <FormattedMessage id="general.abfStatus" />,
      flex: '1 1 90px',
      renderer: renderABFStatusColumn,
      sortKey: 'pg_abf_tracking',
    },
    {
      label: <FormattedMessage id="component.dataTable.headers.producer" />,
      flex: isMobile ? '1 0 80px' : '1 0 120px',
      renderer: renderProducerColumn,
      sortKey: 'f_owner_name',
    },
    {
      label: <FormattedMessage id="component.dataTable.headers.farmName" />,
      flex: '1 0 150px',
      renderer: renderFarmNameColumn,
      sortKey: 'f_name',
    },
    {
      label: <FormattedMessage id="general.pigsIn" />,
      flex: '1 0 70px',
      renderer: renderPigsInColumn,
    },
    {
      label: <FormattedMessage id="component.dataTable.headers.inventory" />,
      flex: '1 0 125px',
      renderer: renderInventoryColumn,
    },
    {
      label: <FormattedMessage id="component.dataTable.headers.groupStartDate" />,
      flex: '1 0 110px',
      renderer: renderGroupStartColumn,
    },
    { label: <FormattedMessage id="general.estAvgWeight" />, flex: '1 0 110px', renderer: renderWeightColumn },
    {
      label: <FormattedMessage id="general.weeksOnFeed" />,
      flex: '1 0 110px',
      renderer: renderWeeksOnFeedColumn,
    },
    {
      label: <FormattedMessage id="component.dataTable.headers.deadPercent" />,
      flex: '1 0 110px',
      renderer: renderDeadPercentColumn,
    },
    {
      label: <FormattedMessage id="component.dataTable.headers.mortRate" />,
      flex: '1 0 120px',
      renderer: renderMortalityColumn,
      sortKey: 'avg_mortality',
    },
    ...getDeathsColumns(),
    {
      label: <FormattedMessage id="general.source" />,
      flex: '1 1 90px',
      renderer: renderSourcesColumn,
    },
  ];

  const paginationProps = {
    onPageChange,
    onPerPageChange,
    totalItems: total,
    currentPage: page,
    perPage: per_page,
  };

  const defaultItem = isCurrentUserAdmin
    ? <FormattedMessage id="general.allGroups" />
    : <FormattedMessage id="general.myGroups" />;

  const noResourcesMsg = date_start === currentDay
    ? <FormattedMessage id="component.nothingBox.noDCSubmitted" />
    : <FormattedMessage id="component.nothingBox.noGroupsToDisplay" />;

  return (
    <div>
      {isOnline && (
        <CompanyProfileHeader company={currentUser.current_company}>
          <DatePicker
            selectionType={reportPeriod}
            onDatesChange={handleChangeDates}
            startDate={moment(date_start)}
            endDate={moment(date_end)}
          />
        </CompanyProfileHeader>
      )}

      <SubNavigation
        items={subNavItems}
        defaultItem={defaultItem}
        isActive={isSubNavItemActive}
        onSelect={handleChangeSubNav}
        className="small-12 columns"
      />

      {isOnline
        ? (
          <div className={cn('autoreport-container')}>
            <div className="small-12 column mt-15">
              <div className={cn('report-label-box')}>
                <span className={cn('label')}>
                  {selectedSubNavItem?.name
                    ? (
                      <FormattedMessage
                        id="container.autoReport.title.company"
                        values={{ companyName: selectedSubNavItem.name }}
                      />)
                    : (
                      <FormattedMessage
                        id={`container.autoReport.title.${isCurrentUserAdmin ? 'allGroups' : 'myGroups'}`}
                      />)
                  }
                </span>
                <Button primary onClick={handleDownloadCSV} className="mobile-full-width">
                  <FormattedMessage id="general.button.downloadCSV" />
                </Button>
              </div>
            </div>

            <AutoReportGraphs reportPeriod={reportPeriod} />

            <section className="small-12 column">
              <Panel>
                <Panel.Heading title={<FormattedMessage id="general.groups" />}>
                  {!isEmptyTable && <SearchBox initialValue={search} onChange={onSearchChange} />}
                </Panel.Heading>

                <Panel.Body noPadding>
                  {!isEmptyTable && (
                    <TableFilter
                      filters={tableFilters}
                      onFilterChange={handleChangeFilter}
                      stats={stats}
                      activeFilter={farm_type}
                      className="ph-10"
                    />
                  )}

                  <Preloader isActive={isLoading} />

                  <DataTable
                    className={cn('autoreport-table', { 'top-indent': !isEmptyTable })}
                    isLoading={isLoading}
                    data={resources}
                    columns={columns}
                    sort={sort}
                    onSortChange={onSortChange}
                    paginationProps={paginationProps}
                    scrollable
                    hasDesktopViewOnly
                    hasScrollHint
                  />

                  <NothingBox
                    itemsName="groups"
                    display={!resources.length}
                    isLoading={isLoading}
                    search={search}
                    filter={farm_type}
                  >
                    <h2 className="lighter">{noResourcesMsg}</h2>
                  </NothingBox>
                </Panel.Body>
              </Panel>
            </section>
          </div>
        ) : <OfflineScreen />}
    </div>
  );
};

AutoReport.propTypes = {
  currentUser: T.object.isRequired,
  reportPeriod: T.string.isRequired,
  location: T.object.isRequired,
  deathsParams: T.object.isRequired,
  mortalityParams: T.object.isRequired,
  companies: T.array.isRequired,
  shareholderGroups: T.array.isRequired,
  setPageOptions: T.func.isRequired,
  setTableParams: T.func.isRequired,
  tableParams: T.object.isRequired,
  clearData: T.func.isRequired,
  fetchUserCompanies: T.func.isRequired,
  fetchShareholderGroupsList: T.func.isRequired,
  fetchMortalityStats: T.func.isRequired,
  fetchDeathsStats: T.func.isRequired,
  isOnline: T.bool.isRequired,
};

AutoReport.contextTypes = {
  router: T.object,
};

export default connect(
  (state) => ({
    tableParams: tableParamsSelector(state, tableNames.autoReports),
    resources: state.autoReport.resources,
    isLoading: state.autoReport.isLoading,
    reqParams: state.autoReport.params,
    meta: state.autoReport.meta,
    deathsParams: state.autoReport.deaths.params,
    mortalityParams: state.autoReport.mortality.params,
    currentUser: state.auth.user,
    companies: state.auth.user_companies,
    reportPeriod: state.auth.user.current_company.auto_report_period,
    shareholderGroups: state.staticData.shareholderGroupsList.resources,
    isOnline: state.network.isOnline,
  }),
  {
    setPageOptions,
    setTableParams,
    fetchUserCompanies,
    fetchShareholderGroupsList,
    fetchMortalityStats,
    fetchDeathsStats,
    clearData,
  }
)(AutoReport);
