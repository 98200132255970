import React, { useCallback, useState } from 'react';
import T from 'prop-types';
// components
import GroupField from './GroupField';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import AsyncSelectLoadMore from 'components/AsyncSelect/AsyncSelectLoadMore';
// api
import { searchAdminFarms } from 'endpoints/admin/farms';
// utils
import classnames from 'classnames/bind';
import isEmpty from 'lodash.isempty';
import { formatFarmOptionLabel } from 'utils';
// styles
import styles from './Section.module.scss';

const cn = classnames.bind(styles);

const FarmSection = ({
  className,
  data,
  isDisabled,
  isOpened,
  onClose,
  onOpen,
  onSave,
  company,
}) => {
  const [currentData, setCurrentData] = useState();
  const selectedOption = currentData || data;

  const getInternalFarms = useCallback((params) => {
    const queryParams = { ...params, only_internal: true, active: true };
    if (company && company.id) {
      queryParams.manager_id = company.id;
    }
    return searchAdminFarms(queryParams);
  }, [company]);

  return (
    <GroupField
      isRequired
      className={className}
      label={(
        <FormattedMessage
          id="component.groupCreate.farmLabel"
          values={{ name: <strong>{data.name}</strong> }}
        />
      )}
      title={<FormattedMessage id="general.farm" />}
      btnLabel={<FormattedMessage id="component.groupCreate.setFarm" />}
      hint={<FormattedMessage id="component.groupCreate.farmHint" />}
      isSaved={!isEmpty(data)}
      onOpen={onOpen}
      isOpened={isOpened}
      isDisabled={isDisabled}
    >
      <div className={cn('field-body')}>
        <div className={cn('field-body-title')}>
          <FormattedMessage id="component.groupCreate.chooseFarm" />
        </div>
        <div className="pt-10">
          <AsyncSelectLoadMore
            selected={selectedOption}
            labelRenderer={formatFarmOptionLabel}
            onChange={setCurrentData}
            optionsGetter={getInternalFarms}
            placeholder={<FormattedMessage id="general.searchBy.farmNameOrId" />}
          />
        </div>
        <div className="pv-10">
          <Button
            primary
            disabled={!currentData || (data.value === currentData?.value)}
            onClick={() => onSave({ farm_id: currentData?.value })}
          >
            <FormattedMessage id="general.button.save" />
          </Button>
          <Button className="btn-link ml-5" onClick={onClose}>
            <FormattedMessage id="general.button.cancel" />
          </Button>
        </div>
      </div>
    </GroupField>
  );
};

FarmSection.propTypes = {
  className: T.string,
  data: T.object,
  onSave: T.func.isRequired,
  onClose: T.func.isRequired,
  onOpen: T.func.isRequired,
  isOpened: T.bool,
  isDisabled: T.bool,
  company: T.object,
};

export default FarmSection;
