import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
// endpoints
import { createFarmReport, getFarmReports, deleteFarmReport } from 'endpoints/reports';
// components
import { FormattedMessage } from 'react-intl';
import DraftReportCard from '../DraftReportCard';
import Preloader from 'components/Preloader';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import Waypoint from 'react-waypoint';
// utils
import classnames from 'classnames/bind';
import moment from 'moment';
import { showToastrMessage } from 'utils';
import { isSomeReportQuestionFlagged } from 'utils/SVRHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import styles from './DraftReportsCarousel.module.scss';

const cn = classnames.bind(styles);

const params = { per_page: 10, page: 1, draft: true };

const DraftReportsCarousel = ({ farmId, templateId, openModal }, { router }) => {
  const [isLoading, setLoading] = useState(false);
  const [isDataLoading, setDataLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [{ resources, meta: { total } }, setData] = useState({ meta: { total: 0 }, resources: [] });

  const fetchData = async () => {
    try {
      setDataLoading(true);
      setData(await getFarmReports(farmId, templateId, params));
      setPage(1);
    } catch (error) {
      toastResponseErrors(error);
    } finally {
      setDataLoading(false);
    }
  };

  const loadMore = async () => {
    try {
      setDataLoading(true);
      const fetchedData = await getFarmReports(farmId, templateId, { ...params, page: page + 1 });
      setData(({ resources }) => ({
        meta: { total: fetchedData.total },
        resources: resources.concat(fetchedData.resources)
      }));
      setPage(page + 1);
    } catch (error) {
      toastResponseErrors(error);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteReport = async (report) => {
    try {
      setLoading(true);
      await deleteFarmReport(farmId, report.id);
      if (total === resources.length) setData(({
        meta, resources }) => ({
        meta: { total: meta.total - 1 },
        resources: resources.filter(({ id }) => id !== report.id),
      }));
      else await fetchData();
      showToastrMessage('component.toastr.svr.draftDeleted');
    } catch (error) {
      toastResponseErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReportDelete = (report) => {
    openModal(
      <ConfirmationModal
        title={<FormattedMessage id="component.modal.deleteReport.title" />}
        actionBtnLabel={<FormattedMessage id="component.modal.deleteReport.confirm" />}
        actionBtnProps={{ red: true }}
        handleConfirm={() => deleteReport(report)}
      >
        <FormattedMessage
          id="component.modal.deleteReport.text"
          tagName="p"
          values={{ date: moment(report.created_at).format('MM/DD/YYYY') }}
        />
      </ConfirmationModal>
    );
  };

  const handleReportCreate = async () => {
    try {
      setLoading(true);
      const { id, svr_template_id } = await createFarmReport(farmId, templateId, { is_draft: true, questions: [] });
      router.push(`/report-farms/${farmId}/report-templates/${svr_template_id}/reports/${id}`);
    } catch (error) {
      toastResponseErrors(error);
      setLoading(false);
    }
  };

  return (
    <div className={cn('reports-carousel')}>
      <Preloader isActive={isLoading || isDataLoading} />
      <div className={cn('add-report-card')}>
        <div onClick={handleReportCreate} className={cn('circle')}>
          <i className={cn('fa fa-plus', 'plus-icon')} />
        </div>
      </div>
      {resources.map((report) => (
        <DraftReportCard
          key={report.id}
          isFlagged={isSomeReportQuestionFlagged(report.svr_categories)}
          onDelete={() => handleReportDelete(report)}
          onContinue={() => router.push(`/report-farms/${farmId}/report-templates/${templateId}/reports/${report.id}`)}
          reportUser={report.user}
        />
      ))}
      {total > resources.length && !isDataLoading && <Waypoint horizontal onEnter={loadMore} />}
    </div>
  );
};

DraftReportsCarousel.contextTypes = {
  router: T.object.isRequired,
};

DraftReportsCarousel.propTypes = {
  farmId: T.string.isRequired,
  templateId: T.string.isRequired,
  openModal: T.func.isRequired,
};

export default connect(null, { openModal })(DraftReportsCarousel);
