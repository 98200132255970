import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import Field from 'components/Field';
import SingleDatePicker from 'components/DatePicker/SingleDatePicker/SingleDatePicker';
import { FormattedMessage } from 'react-intl';
import Input from 'components/Input';
import ReactSelect from 'components/Select/ReactSelect';
import CircleCheckbox from 'components/CircleCheckbox';
import DateRangePicker from 'components/DatePicker/DateRangePicker/DateRangePicker';
import NumberStepInput from 'components/NumberStepInput/NumberStepInput';
// utils
import classnames from 'classnames/bind';
import { connect } from 'react-redux';
import { capitalize, formatDosageType } from 'utils';
import getValue from 'lodash.get';
import moment from 'moment';
// redux
import { change } from 'redux-form';
// constants
import {
  treatmentPeriodUnits,
  treatmentPeriods,
  treatmentProtocolFormSelector,
  treatmentProtocolFormName
} from './helper';
// styles
import styles from './TreatmentForm.module.scss';

const cn = classnames.bind(styles);

const ValueInput = (props) => (
  <Input
    {...props}
    {...props.input}
    placeholder="0"
    className={cn('days-input')}
    hasNoMargin
    type="number"
    min={1}
    max={999}
    onChange={({ target: { value, validity } }) => (validity.valid && props.input.onChange(value ? +value : ''))}
  />
);

const UnitSelectField = (props) => (
  <Field
    {...props}
    component={({ input }) => (
      <ReactSelect
        className={cn('unit-select')}
        noInputMargin
        options={treatmentPeriodUnits}
        labelKey="label"
        valueKey="value"
        arrowRenderer={() => <i className="fa fa-angle-down" />}
        value={input.value || ''}
        onBlur={() => input.onBlur(getValue(input, 'value'))}
        onChange={(option) => (input.onChange(getValue(option, 'value')))}
        clearable={false}
        {...props}
      />
    )}
  />
);

const parseDecimalText = (text, prev) => {
  const pattern = /^[+-]?\d+(\.\d+)?$/; // float number
  if (text === '') return text;
  if (!`0${text}0`.match(pattern)) return prev;
  return text;
};

const TreatmentForm = ({
  fieldName, values, isDailyTreatment, change, isProtocolBaseDate, product, measurementSystem
}) => {
  // Depends on type of base date. Base date can be either group start or treatment protocol created date (arrive date)
  const periodLabel = isProtocolBaseDate ?
    <FormattedMessage id="general.onArrival" /> :
    <FormattedMessage id="general.onFeed" />;

  const onBetweenDatesChange = ({ startDate, endDate }) => {
    const stepType = values?.step_type;
    change(treatmentProtocolFormName, `${fieldName}.${stepType}.start_date`, startDate);
    change(treatmentProtocolFormName, `${fieldName}.${stepType}.end_date`, endDate);
  };

  const getTreatmentLabel = () => {
    const dosageType = formatDosageType(product.dosage_type, measurementSystem);
    const type = <FormattedMessage id={`general.short.${dosageType}`} />;

    if (product.route.includes('oral')) {
      const msgKey = product.dosage_measure === 'head' ? 'oralLabel' : 'unitsAdministered';
      return (
        <FormattedMessage id={`container.checkupEdit.reportMedications.${msgKey}`} values={{ type }} />
      );
    }

    return (
      <FormattedMessage
        id="container.checkupEdit.reportMedications.injectableLabel"
        values={{ type, measure: <FormattedMessage id={`general.${product.dosage_measure}`} /> }}
      />
    );
  };

  const renderTreatmentLabel = (step_type) => {
    const { step_unit, start_date, end_date } = values?.[step_type] || {};
    const fieldKey = `${fieldName}.${step_type}`;

    if (step_type === 'between' && step_unit !== 'date_range') {
      return (
        <Fragment>
          <UnitSelectField name={`${fieldKey}.step_unit`} />
          <div className={cn('label-group')}>
            <Field component={ValueInput} name={`${fieldKey}.first_period`} />
            <div className={cn('label-text')}>
              <FormattedMessage id={`general.and${capitalize(step_unit || 'day')}`} />
            </div>
            <Field component={ValueInput} name={`${fieldKey}.last_period`} />
            <div className={cn('label-text')}>{periodLabel}</div>
          </div>
        </Fragment>
      );
    }

    if (step_type === 'between' && step_unit === 'date_range') {
      return (
        <Fragment>
          <UnitSelectField name={`${fieldKey}.step_unit`} />
          <div className={cn('label-group')}>
            <DateRangePicker
              className={cn('date-picker')}
              startDate={start_date}
              endDate={end_date}
              onDatesChange={onBetweenDatesChange}
              withoutOutsideClickHandler
              isOutsideRange={(day) => (day.isBefore(moment(), 'day'))}
            />
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <UnitSelectField name={`${fieldKey}.step_unit`} />
        <div className={cn('label-group')}>
          {step_unit === 'date_range' ? (
            <Field
              name={step_type === 'before' ? `${fieldKey}.end_date` : `${fieldKey}.start_date`}
              component={({ input, ...other }) => (
                <SingleDatePicker
                  {...input}
                  {...other}
                  className={cn('date-picker')}
                  date={input.value || null}
                  onDateChange={(date) => input.onChange(date)}
                  withoutOutsideClickHandler
                  isOutsideRange={(day) => (day.isBefore(moment(), 'day'))}
                />
              )}
            />
          ) : (
          <>
            <Field min={1} component={ValueInput} name={`${fieldKey}.first_period`} />
            <div className={cn('label-text', 'ml-10')}>{periodLabel}</div>
          </>
          )}
        </div>
      </Fragment>
    );
  };

  const TreatmentDetails = ({ product }) => {
    const step = values?.[values?.step_type] || {};
    const { quantity, gallons_value } = values?.[values?.step_type] || {};

    const setStepValue = (key, value) => {
      const oldValue = step[key];
      const newValue = parseDecimalText(value, step[key]);
      const stepType = values?.step_type;

      if (newValue !== oldValue) {
        change(treatmentProtocolFormName, `${fieldName}.${stepType}.${key}`, value);
      }
    };
    const handleFocus = (e) => e.target.select();
    const handleChange = (key) => (value) => setStepValue(key, value);
    const handleBlur = (key) => ({ target: { value } }) => setStepValue(key, value);

    return (
      <div className="select-values-line">
        <div className="treatment-details">
          {!product.route.includes('topical') && (
            <div className="treatment-details-row">
              <NumberStepInput
                label={getTreatmentLabel()}
                value={quantity || 0}
                onChange={handleChange('quantity')}
                onFocus={handleFocus}
                onBlur={handleBlur('quantity')}
                type="number"
                className="treatment-number-input"
                step={product.quantity_step || 0.25}
                normalizeValue={(value) => value.toString()}
              />
            </div>
          )}

          {product.dosage_measure === 'gal' && !product.route.includes('topical') && (
            <div className="treatment-details-row">
              <NumberStepInput
                className="treatment-number-input"
                label={<FormattedMessage id="general.gallonsWater" />}
                value={gallons_value || ''}
                onChange={handleChange('gallons_value')}
                onFocus={handleFocus}
                onBlur={handleBlur('gallons_value')}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {treatmentPeriods.map((step_type) => (
        <div key={step_type} className={cn('radio-item')}>
          <Field
            name={`${fieldName}.step_type`}
            component={({ input }) => (
              <CircleCheckbox
                {...input}
                disabled={isDailyTreatment && step_type !== 'between'}
                className={cn({ disabled: isDailyTreatment && step_type !== 'between' })}
                type="radio"
                label={(
                  <div className={cn('checkbox-label-text', 'semibold')}>
                    <FormattedMessage id={`general.${step_type}`} />
                  </div>
                )}
                checked={input.value === step_type}
                onChange={() => input.onChange(step_type)}
              />
            )}
          />
          <div className={cn('radio-label', 'semibold', { disabled: step_type !== values.step_type })}>
            {renderTreatmentLabel(step_type)}
          </div>
        </div>
      ))}
      <div className={cn('radio-item')}>
        <TreatmentDetails product={product} />
      </div>
    </Fragment>
  );
};

TreatmentForm.propTypes = {
  change: T.func.isRequired,
  fieldName: T.string.isRequired,
  isDailyTreatment: T.bool,
  isProtocolBaseDate: T.bool,
  product: T.oneOfType([T.string, T.object]),
  measurementSystem: T.string.isRequired,
  values: T.shape({
    step_type: T.string,
    step_unit: T.string,
    first_period: T.number,
    last_period: T.number,
    quantity: T.oneOfType([T.string, T.number]),
    gallonsValue: T.oneOfType([T.string, T.number]),
  }),
};

export default connect((state, { fieldName }) => ({
  values: treatmentProtocolFormSelector(state, fieldName),
  isProtocolBaseDate: treatmentProtocolFormSelector(state, 'tp_base_date'),
  measurementSystem: state.auth.user.current_company.measurement_system,
}), { change })(TreatmentForm);
