import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import FormattedWeight from 'components/FormattedWeight';
// utils
import { getLabelComponent } from '../userFiltersHelper';
import { farmTypes, commitmentStatuses, loadTypes, loadingStatuses } from '../../constants';

export const checkboxProps = ['options', 'isOpened', 'onApply', 'onConditionChange'];

export const sectionFieldsToUpdate = ['id', 'type', '_destroy', 'condition', 'target_id', 'options'];

export const sectionFieldsToCreate = ['type', 'condition', 'target_id', 'options'];

export const sectionOptionsDateFormat = 'DD-MM-YYYY';

export const userFilterSectionTypes = {
  aiImagePrediction: 'AiImagePrediction',
  aiTreatmentPrediction: 'AiTreatmentPrediction',
  aiMortalityPrediction: 'AiMortalityPrediction',
  arriveAt: 'ArriveAt',
  avgMortalityRate: 'AvgMortalityRate',
  avgTreated: 'AvgTreated',
  company: 'RelatedCompany',
  compliance: 'Compliance',
  closeDate: 'CloseDate',
  currentWaterUsage: 'CurrentWaterUsage',
  date: 'Date',
  dcAsset: 'DailyCheckupAsset',
  dcDiagnoses: 'DailyCheckupDiagnoses',
  dcEstimatedWeight: 'DailyCheckupEstimatedWeight',
  dcInventory: 'DailyCheckupInventory',
  dcMortalityRate: 'DailyCheckupMortalityRate',
  farm: 'Farm',
  farmExternalLinkId: 'FarmExternalLinkId',
  farmFederalPremiseId: 'FarmFederalPremiseId',
  farmLocation: 'FarmLocation',
  farmType: 'FarmType',
  flagged: 'Flagged',
  load: 'Loading',
  loadType: 'LoadingLoadType',
  mortalityRate: 'MortalityRate',
  openPigGroupsCount: 'OpenPigGroupsCount',
  pigGroup: 'PigGroup',
  pigGroupCloseDate: 'PigGroupCloseDate',
  pigGroupStartDate: 'PigGroupStartDate',
  pigGroupStatus: 'PigGroupStatus',
  pigsCount: 'PigsCount',
  startDate: 'StartDate',
  commitmentStatus: 'Status',
  svr: 'Svr',
  totalDeaths: 'TotalDeaths',
  user: 'User',
  weight: 'Weight',
  loadingStatus: 'LoadingStatus',
  pigMovements: 'PigMigration',
  managementCompany: 'ManagementCompany',
  latestSvrCreatedAt: 'LatestSvrCreatedAt',
  latestSvrCreatedBy: 'LatestSvrCreatedBy',
  withdrawalWarning: 'WithdrawalWarning',
  waterUsageWarning: 'WaterUsageWarning',
};

export const userFilterGroupNames = {
  activities: 'Activities',
  barnSheetsFarms: 'BarnsheetsFarms',
  barnSheetsPigGroups: 'BarnsheetsPigGroups',
  loadCommitments: 'LoadingCommitments',
  svrsFarms: 'SvrsFarms',
  treatmentProtocolFarms: 'TreatmentProtocolsFarms',
};

export const allOrAnyEventsOptions = [
  { value: 'all', label: getLabelComponent('allFilters') },
  { value: 'any', label: getLabelComponent('anyFilters') },
];

export const allOrAnyGroupsOptions = [
  { value: 'all', label: getLabelComponent('allGroupsFilters') },
  { value: 'any', label: getLabelComponent('anyGroupsFilters') },
];

export const allOrAnyFarmsOptions = [
  { value: 'all', label: getLabelComponent('allFarmsFilters') },
  { value: 'any', label: getLabelComponent('anyFarmsFilters') },
];

export const allOrAnyLoadingsOptions = [
  { value: 'all', label: getLabelComponent('allLoadsFilters') },
  { value: 'any', label: getLabelComponent('anyLoadsFilters') },
];

export const dateSections = [
  userFilterSectionTypes.arriveAt,
  userFilterSectionTypes.closeDate,
  userFilterSectionTypes.date,
  userFilterSectionTypes.pigGroupCloseDate,
  userFilterSectionTypes.pigGroupStartDate,
  userFilterSectionTypes.startDate,
  userFilterSectionTypes.latestSvrCreatedAt,
];

export const weightSections = [userFilterSectionTypes.dcEstimatedWeight, userFilterSectionTypes.weight];

export const entityNameSections = [
  userFilterSectionTypes.company,
  userFilterSectionTypes.farm,
  userFilterSectionTypes.pigGroup,
  userFilterSectionTypes.load,
  userFilterSectionTypes.user,
  userFilterSectionTypes.managementCompany,
  userFilterSectionTypes.latestSvrCreatedBy,
];

export const numberSections = [
  userFilterSectionTypes.avgTreated,
  userFilterSectionTypes.compliance,
  userFilterSectionTypes.currentWaterUsage,
  userFilterSectionTypes.dcEstimatedWeight,
  userFilterSectionTypes.dcInventory,
  userFilterSectionTypes.openPigGroupsCount,
  userFilterSectionTypes.pigsCount,
  userFilterSectionTypes.totalDeaths,
  userFilterSectionTypes.weight,
];

export const multiEntityNameSections = [userFilterSectionTypes.dcDiagnoses];

export const canAddedOnceSections = [
  userFilterSectionTypes.aiImagePrediction,
  userFilterSectionTypes.aiTreatmentPrediction,
  userFilterSectionTypes.aiMortalityPrediction,
  userFilterSectionTypes.arriveAt,
  userFilterSectionTypes.avgTreated,
  userFilterSectionTypes.closeDate,
  userFilterSectionTypes.currentWaterUsage,
  userFilterSectionTypes.dcAsset,
  userFilterSectionTypes.dcDiagnoses,
  userFilterSectionTypes.flagged,
  userFilterSectionTypes.openPigGroupsCount,
  userFilterSectionTypes.pigGroupStatus,
  userFilterSectionTypes.pigsCount,
  userFilterSectionTypes.startDate,
  userFilterSectionTypes.svr,
  userFilterSectionTypes.totalDeaths,
  userFilterSectionTypes.pigMovements,
];

export const availSectionConditions = {
  only: { value: 'only', label: getLabelComponent('is') },
  except: { value: 'except', label: getLabelComponent('isNot') },
  start: { value: 'start', label: getLabelComponent('startsWith') },
  end: { value: 'end', label: getLabelComponent('endsWith') },
  cont: { value: 'cont', label: getLabelComponent('contains') },
  not_cont: { value: 'not_cont', label: getLabelComponent('doesNotContain') },
  lt_days_ago: { value: 'lt_days_ago', label: getLabelComponent('moreThan') },
  eq_days_ago: { value: 'eq_days_ago', label: getLabelComponent('exactly') },
  gt_days_ago: { value: 'gt_days_ago', label: getLabelComponent('lessThan') },
  gt: { value: 'gt', label: getLabelComponent('greaterThan') },
  lt: { value: 'lt', label: getLabelComponent('lessThan') },
  eq: { value: 'eq', label: getLabelComponent('is') },
  not_eq: { value: 'not_eq', label: getLabelComponent('isNot') },
  range: { value: 'range', label: getLabelComponent('hasRange') },
  any: { value: 'any', label: getLabelComponent('hasAnyValue') },
  none: { value: 'none', label: getLabelComponent('isUnknown') },
  no_value: { value: 'none', label: getLabelComponent('hasNoValue') },
  after: { value: 'gt', label: getLabelComponent('after') },
  on: { value: 'eq', label: getLabelComponent('on') },
  before: { value: 'lt', label: getLabelComponent('before') },
  relativeLabel: { label: <FormattedMessage id="general.relative" /> },
  absoluteLabel: { label: <FormattedMessage id="general.absolute" /> },
};

export const numberConditions = [
  availSectionConditions.gt,
  availSectionConditions.lt,
  availSectionConditions.eq,
  availSectionConditions.not_eq,
  availSectionConditions.range,
  availSectionConditions.any
];

const dateConditions = [
  availSectionConditions.relativeLabel,
  availSectionConditions.lt_days_ago,
  availSectionConditions.eq_days_ago,
  availSectionConditions.gt_days_ago,
  availSectionConditions.absoluteLabel,
  availSectionConditions.after,
  availSectionConditions.on,
  availSectionConditions.before,
  availSectionConditions.range,
];

const shortTextConditions = [
  availSectionConditions.start,
  availSectionConditions.end,
  availSectionConditions.cont,
  availSectionConditions.not_cont,
];

const textConditions = [
  availSectionConditions.eq,
  availSectionConditions.not_eq,
  ...shortTextConditions,
  availSectionConditions.any,
];

const nameAndTextConditions = [
  availSectionConditions.only,
  availSectionConditions.except,
  ...shortTextConditions,
];

export const conditionsBySectionType = {
  [userFilterSectionTypes.aiImagePrediction]: [
    { value: 'exist', label: getLabelComponent('hasAiComputerVision') },
    { value: 'not_exist', label: getLabelComponent('doesntHaveAiComputerVision') },
  ],
  [userFilterSectionTypes.aiTreatmentPrediction]: [
    { value: 'exist', label: getLabelComponent('hasAiTreatment') },
    { value: 'not_exist', label: getLabelComponent('doesntHaveAiTreatment') },
  ],
  [userFilterSectionTypes.aiMortalityPrediction]: [
    { value: 'exist', label: getLabelComponent('hasAiMortality') },
    { value: 'not_exist', label: getLabelComponent('doesntHaveAiMortality') },
  ],
  [userFilterSectionTypes.flagged]: [
    { value: 'yes', label: getLabelComponent('isFlagged') },
    { value: 'no', label: getLabelComponent('isNotFlagged') },
  ],
  [userFilterSectionTypes.latestSvrCreatedAt]: dateConditions,
  [userFilterSectionTypes.date]: dateConditions,
  [userFilterSectionTypes.dcAsset]: [
    { value: 'exist', label: getLabelComponent('hasDailyCheckupMedia') },
    { value: 'not_exist', label: getLabelComponent('doesntHaveDailyCheckupMedia') },
  ],
  [userFilterSectionTypes.svr]: [
    { value: 'exist', label: getLabelComponent('hasReport') },
    { value: 'not_exist', label: getLabelComponent('doesntHaveReport') },
    { value: 'failed', label: getLabelComponent('hasFailedReport') },
    { value: 'not_failed', label: getLabelComponent('doesntHaveFailedReport') },
  ],
  [userFilterSectionTypes.pigGroupStatus]: [
    { value: 'opened', label: getLabelComponent('withOpenStatus') },
    { value: 'closed', label: getLabelComponent('withClosedStatus') },
    { value: 'any', label: getLabelComponent('withAnyStatus') },
  ],
  [userFilterSectionTypes.pigGroup]: nameAndTextConditions,
  [userFilterSectionTypes.pigGroupCloseDate]: dateConditions,
  [userFilterSectionTypes.closeDate]: dateConditions,
  [userFilterSectionTypes.pigGroupStartDate]: dateConditions,
  [userFilterSectionTypes.startDate]: dateConditions,
  [userFilterSectionTypes.dcEstimatedWeight]: numberConditions,
  [userFilterSectionTypes.weight]: numberConditions,
  [userFilterSectionTypes.farm]: nameAndTextConditions,
  [userFilterSectionTypes.company]: nameAndTextConditions,
  [userFilterSectionTypes.managementCompany]: nameAndTextConditions,
  [userFilterSectionTypes.user]: [availSectionConditions.only, availSectionConditions.except],
  [userFilterSectionTypes.latestSvrCreatedBy]: [availSectionConditions.only, availSectionConditions.except],
  [userFilterSectionTypes.farmLocation]: textConditions,
  [userFilterSectionTypes.farmExternalLinkId]: textConditions,
  [userFilterSectionTypes.farmFederalPremiseId]: textConditions,
  [userFilterSectionTypes.farmType]: [
    availSectionConditions.eq,
    availSectionConditions.not_eq,
    availSectionConditions.any,
    availSectionConditions.none,
  ],
  [userFilterSectionTypes.compliance]: numberConditions,
  [userFilterSectionTypes.avgTreated]: numberConditions,
  [userFilterSectionTypes.currentWaterUsage]: numberConditions,
  [userFilterSectionTypes.totalDeaths]: numberConditions,
  [userFilterSectionTypes.openPigGroupsCount]: numberConditions,
  [userFilterSectionTypes.dcDiagnoses]: [
    { value: 'cont_all_of', label: getLabelComponent('matchAll') },
    { value: 'cont_any_of', label: getLabelComponent('matchAny') },
    { value: 'cont_none_of', label: getLabelComponent('matchNone') },
    availSectionConditions.any,
    availSectionConditions.no_value,
  ],
  [userFilterSectionTypes.load]: shortTextConditions,
  [userFilterSectionTypes.loadType]: [
    availSectionConditions.eq,
    availSectionConditions.not_eq,
    availSectionConditions.any,
  ],
  [userFilterSectionTypes.commitmentStatus]: [
    availSectionConditions.eq,
    availSectionConditions.not_eq,
    availSectionConditions.any,
  ],
  [userFilterSectionTypes.loadingStatus]: [
    availSectionConditions.eq,
    availSectionConditions.not_eq,
    availSectionConditions.any,
  ],
  [userFilterSectionTypes.arriveAt]: dateConditions,
  [userFilterSectionTypes.pigMovements]: [
    { value: 'exist', label: getLabelComponent('pigsCompleted') },
    { value: 'not_exist', label: getLabelComponent('pigsToBeShipped') },
  ],
  [userFilterSectionTypes.withdrawalWarning]: [
    { value: 'exist', label: getLabelComponent('hasWithdrawalWarning') },
    { value: 'not_exist', label: getLabelComponent('doesntHaveWithdrawalWarning') },
  ],
  [userFilterSectionTypes.waterUsageWarning]: [
    { value: 'exist', label: getLabelComponent('hasWaterUsageWarning') },
    { value: 'not_exist', label: getLabelComponent('doesntHaveWaterUsageWarning') },
  ],
};

export const sectionSpecificProps = {
  [userFilterSectionTypes.dcEstimatedWeight]: {
    inputLabel: <FormattedWeight hasOnlyUnit />,
  },
  [userFilterSectionTypes.weight]: {
    inputLabel: <FormattedWeight hasOnlyUnit />,
    valueKey: 'count',
    valueStartKey: 'count_from',
    valueEndKey: 'count_to',
  },
  [userFilterSectionTypes.compliance]: {
    inputLabel: '%',
    maxValue: 100,
    step: 0.01,
  },
  [userFilterSectionTypes.avgTreated]: {
    inputLabel: '%',
    maxValue: 100,
    step: 0.01,
  },
  [userFilterSectionTypes.totalDeaths]: {
    inputLabel: <FormattedMessage id="general.pigs" />,
    valueKey: 'count',
    valueStartKey: 'count_from',
    valueEndKey: 'count_to',
  },
  [userFilterSectionTypes.openPigGroupsCount]: {
    inputLabel: <FormattedMessage id="general.groups" />,
    valueKey: 'count',
    valueStartKey: 'count_from',
    valueEndKey: 'count_to',
  },
  [userFilterSectionTypes.currentWaterUsage]: {
    inputLabel: <FormattedMessage id="general.gallons" />,
    step: 0.01,
  },
};

export const sectionIcons = {
  [userFilterSectionTypes.aiImagePrediction]: 'fa fa-magic-fas',
  [userFilterSectionTypes.aiTreatmentPrediction]: 'fa fa-magic-fas',
  [userFilterSectionTypes.aiMortalityPrediction]: 'fa fa-magic-fas',
  [userFilterSectionTypes.arriveAt]: 'fa fa-calendar',
  [userFilterSectionTypes.avgMortalityRate]: 'fa fa-pie-chart',
  [userFilterSectionTypes.avgTreated]: 'fa fa-injectable',
  [userFilterSectionTypes.company]: 'fa fa-business-socks',
  [userFilterSectionTypes.managementCompany]: 'fa fa-business-socks',
  [userFilterSectionTypes.compliance]: 'fa fa-trophy',
  [userFilterSectionTypes.currentWaterUsage]: 'fa fa-water-soluble',
  [userFilterSectionTypes.closeDate]: 'fa fa-calendar',
  [userFilterSectionTypes.latestSvrCreatedAt]: 'fa fa-calendar',
  [userFilterSectionTypes.date]: 'fa fa-calendar',
  [userFilterSectionTypes.dcAsset]: 'fa fa-photo',
  [userFilterSectionTypes.dcDiagnoses]: 'fa fa-diagnosis',
  [userFilterSectionTypes.dcEstimatedWeight]: 'fa fa-ep-weight',
  [userFilterSectionTypes.dcInventory]: 'fa fa-ep-piggy',
  [userFilterSectionTypes.dcMortalityRate]: 'fa fa-pie-chart',
  [userFilterSectionTypes.farm]: 'fa fa-ep-farm',
  [userFilterSectionTypes.farmExternalLinkId]: 'fa fa-ep-farm',
  [userFilterSectionTypes.farmFederalPremiseId]: 'fa fa-ep-farm',
  [userFilterSectionTypes.farmType]: 'fa fa-ep-farm',
  [userFilterSectionTypes.farmLocation]: 'fa fa-map-marker',
  [userFilterSectionTypes.flagged]: 'fa fa-bookmark',
  [userFilterSectionTypes.openPigGroupsCount]: 'fa fa-ep-groups',
  [userFilterSectionTypes.pigsCount]: 'fa fa-ep-piggy',
  [userFilterSectionTypes.pigGroup]: 'fa fa-ep-groups',
  [userFilterSectionTypes.pigGroupStatus]: 'fa fa-check-circle',
  [userFilterSectionTypes.pigGroupCloseDate]: 'fa fa-calendar',
  [userFilterSectionTypes.pigGroupStartDate]: 'fa fa-calendar',
  [userFilterSectionTypes.startDate]: 'fa fa-calendar',
  [userFilterSectionTypes.commitmentStatus]: 'fa fa-check-circle',
  [userFilterSectionTypes.loadingStatus]: 'fa fa-check-circle',
  [userFilterSectionTypes.svr]: 'fa fa-site-visit-report',
  [userFilterSectionTypes.totalDeaths]: 'fa fa-ep-piggy',
  [userFilterSectionTypes.load]: 'fa fa-ep-truck',
  [userFilterSectionTypes.loadType]: 'fa fa-ep-truck',
  [userFilterSectionTypes.mortalityRate]: 'fa fa-pie-chart',
  [userFilterSectionTypes.user]: 'fa fa-ep-avatar-male',
  [userFilterSectionTypes.latestSvrCreatedBy]: 'fa fa-ep-avatar-male',
  [userFilterSectionTypes.weight]: 'fa fa-ep-weight',
  [userFilterSectionTypes.pigMovements]: 'fa fa-ep-piggy',
  [userFilterSectionTypes.withdrawalWarning]: 'fa fa-exclamation-triangle',
  [userFilterSectionTypes.waterUsageWarning]: 'fa fa-exclamation-triangle',
};

export const emptySectionPlaceholders = {
  [userFilterSectionTypes.arriveAt]: getLabelComponent('emptyArriveAt'),
  [userFilterSectionTypes.avgTreated]: getLabelComponent('emptyTreated24h'),
  [userFilterSectionTypes.closeDate]: getLabelComponent('emptyCloseDate'),
  [userFilterSectionTypes.company]: getLabelComponent('emptyCompanyName'),
  [userFilterSectionTypes.managementCompany]: getLabelComponent('emptyCompanyName'),
  [userFilterSectionTypes.compliance]: getLabelComponent('emptyCompliance'),
  [userFilterSectionTypes.currentWaterUsage]: getLabelComponent('emptyGallonsConsumed'),
  [userFilterSectionTypes.date]: getLabelComponent('emptyEventDate'),
  [userFilterSectionTypes.dcDiagnoses]: getLabelComponent('emptyDiagnoses'),
  [userFilterSectionTypes.dcEstimatedWeight]: getLabelComponent('emptyWeight'),
  [userFilterSectionTypes.farm]: getLabelComponent('emptyFarmName'),
  [userFilterSectionTypes.farmExternalLinkId]: getLabelComponent('emptyFarmID'),
  [userFilterSectionTypes.farmFederalPremiseId]: getLabelComponent('emptyPremiseID'),
  [userFilterSectionTypes.farmLocation]: getLabelComponent('emptyLocation'),
  [userFilterSectionTypes.farmType]: getLabelComponent('emptyFarmType'),
  [userFilterSectionTypes.load]: getLabelComponent('emptyLoad'),
  [userFilterSectionTypes.loadType]: getLabelComponent('emptyLoadType'),
  [userFilterSectionTypes.openPigGroupsCount]: getLabelComponent('emptyOpenGroupsCount'),
  [userFilterSectionTypes.pigGroup]: getLabelComponent('emptyGroup'),
  [userFilterSectionTypes.pigGroupCloseDate]: getLabelComponent('emptyCloseDate'),
  [userFilterSectionTypes.pigGroupStartDate]: getLabelComponent('emptyStartDate'),
  [userFilterSectionTypes.pigGroupStatus]: getLabelComponent('emptyGroupStatus'),
  [userFilterSectionTypes.startDate]: getLabelComponent('emptyStartDate'),
  [userFilterSectionTypes.commitmentStatus]: getLabelComponent('emptyConfirmationStatus'),
  [userFilterSectionTypes.loadingStatus]: getLabelComponent('emptyLoadStatus'),
  [userFilterSectionTypes.totalDeaths]: getLabelComponent('emptyTotalMortality'),
  [userFilterSectionTypes.user]: getLabelComponent('emptyUserName'),
  [userFilterSectionTypes.latestSvrCreatedBy]: getLabelComponent('emptyLatestReportAuthor'),
  [userFilterSectionTypes.weight]: getLabelComponent('emptyWeight'),
  [userFilterSectionTypes.latestSvrCreatedAt]: getLabelComponent('emptyLatestReportDate'),
};

export const sectionInputPlaceholderIds = {
  [userFilterSectionTypes.farmLocation]: 'general.location',
  [userFilterSectionTypes.farmExternalLinkId]: 'general.farmId',
  [userFilterSectionTypes.farmFederalPremiseId]: 'general.federalPremiseId',
  [userFilterSectionTypes.farm]: 'general.farmName',
  [userFilterSectionTypes.company]: 'general.companyName',
  [userFilterSectionTypes.managementCompany]: 'general.companyName',
  [userFilterSectionTypes.user]: 'general.userName',
  [userFilterSectionTypes.latestSvrCreatedBy]: 'general.userName',
  [userFilterSectionTypes.pigGroup]: 'general.groupId',
  [userFilterSectionTypes.load]: 'general.loadName',
  [userFilterSectionTypes.dcDiagnoses]: 'general.selectDiagnoses',
};

export const sectionEntityKeys = {
  [userFilterSectionTypes.farm]: 'farm',
  [userFilterSectionTypes.user]: 'user',
  [userFilterSectionTypes.latestSvrCreatedBy]: 'user',
  [userFilterSectionTypes.pigGroup]: 'pig_group',
  [userFilterSectionTypes.company]: 'company',
  [userFilterSectionTypes.managementCompany]: 'company',
  [userFilterSectionTypes.dcDiagnoses]: 'diagnoses',
};

export const listSectionsOptions = {
  [userFilterSectionTypes.farmType]: farmTypes,
  [userFilterSectionTypes.loadType]: loadTypes,
  [userFilterSectionTypes.commitmentStatus]: commitmentStatuses,
  [userFilterSectionTypes.loadingStatus]: loadingStatuses,
};
