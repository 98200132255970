import React, { useEffect, useMemo, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { submit, formValueSelector } from 'redux-form';
import { fetchPigGroup } from 'reducers/pigGroups/pigGroupShow';
import { setPageOptions } from 'reducers/layout';
import { openModal } from 'reducers/modals';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Link from 'react-router/lib/Link';
import CenterBox from 'components/CenterBox';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import TransferForm from './components/TransferForm';
import PigsUnderCareLine from 'components/PigsUnderCareLine';
import TruckingRouteCard from 'components/TruckingRouteCard/TruckingRouteCard';
// hooks
import useFetchById from 'hooks/useFetchById';
// api
import { createPigGroupMigration } from 'endpoints/pigGroups';
import { getCommitment } from 'endpoints/commitments';
// utils
import moment from 'moment';
import pick from 'lodash.pick';
import isEmpty from 'lodash.isempty';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
import { formatBackEndWeightToSystem, formatWeightToBackEndFormat } from 'utils/weightHelper';
import { getCommitmentPlacementDate } from 'utils/pigGroupsHelper';
import { getCommitmentDirection, getMigrationTypeBasedOnCommitment } from 'utils/commitmentsHelper';

const TransferCreate = ({
  destination,
  fetchPigGroup,
  location: { query, pathname },
  measurementSystem,
  migrationType,
  params: { id: paramsGroupId },
  pigGroup,
  router,
  setPageOptions,
  submit,
}) => {
  const queryCommitmentId = query?.commitmentId;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [{ data: commitment, isLoading }] = useFetchById(getCommitment, queryCommitmentId, {
    onSuccess: (commitment) => {
      if (commitment.pig_group_id !== +paramsGroupId || commitment.status !== 'accepted') {
        router.push(pathname);
      }
    },
    onError: () => router.push(pathname),
  });

  useEffect(() => {
    if (pigGroup.id !== parseInt(paramsGroupId, 10)) {
      fetchPigGroup(paramsGroupId);
    }
    setPageOptions({ mobileTitle: 'createSaleTransfer' });
    return () => {
      setPageOptions({ mobileTitle: '' });
    };
  }, []);

  const prepareResource = (data) => {
    const resource = pick(data, ['migration_type', 'estimated_weight']);
    if (data?.destination?.type) {
      resource[`${data.destination.type}_destination_ids`] = [data.destination.data.value];
    }

    return {
      ...resource,
      delivery_date: data.delivery_date.format('YYYY-MM-DD'),
      loading_commitment_id: commitment?.id,
      amount: -Math.abs(data.amount),
      estimated_weight: formatWeightToBackEndFormat(resource.estimated_weight, measurementSystem),
    };
  };

  const handleSubmitData = (data) => {
    const resource = prepareResource(data);
    setIsSubmitting(true);
    createPigGroupMigration(paramsGroupId, { resource })
      .then(() => {
        showToastrMessage('component.toastr.transfer.successCreated');
        router.goBack();
      })
      .catch((response) => {
        setIsSubmitting(false);
        toastResponseErrors(response);
      });
  };

  const initialValues = useMemo(() => {
    const initialAvgWeight = formatBackEndWeightToSystem(pigGroup.estimated_weight, measurementSystem, 0);
    if (!queryCommitmentId || !commitment?.id) return {
      delivery_date: moment(),
      estimated_weight: initialAvgWeight,
    };
    return {
      migration_type: getMigrationTypeBasedOnCommitment(commitment),
      delivery_date: getCommitmentPlacementDate(commitment.arrive_at, pigGroup.created_on),
      amount: commitment.head_count,
      estimated_weight: initialAvgWeight,
    };
  }, [pigGroup, commitment, queryCommitmentId]);

  const isCreateDisabled = !queryCommitmentId
    ? !migrationType || !destination?.type || isEmpty(destination?.data)
    : false;
  const cancelLink = queryCommitmentId
    ? `/groups/${paramsGroupId}/scheduled-loads`
    : `/groups/${paramsGroupId}/transfers`;

  return (
    <CenterBox>
      <section className="center-box-header show-for-large">
        <div className="title">
          <FormattedMessage id="general.createMovementWithName" values={{ name: pigGroup.name }} />
        </div>
        <Link to={cancelLink}><i className="fa fa-times close-center-box" /></Link>
      </section>

      <PigsUnderCareLine pigsCount={pigGroup.pigs} mobile />

      <section className="center-box-body">
        {commitment && (
          <TruckingRouteCard
            className="mh-20 mb-20"
            direction={getCommitmentDirection(commitment)}
            commitments={commitment.loading.commitments}
            loadType={commitment.loading.load_type}
          />
        )}
        <TransferForm
          isCommitmentBasedForm={!!queryCommitmentId}
          initialValues={initialValues}
          onSubmit={handleSubmitData}
          minDeliveryDate={moment(pigGroup.created_on)}
        />
      </section>

      <section className="center-box-footer show-for-large">
        <div className="buttons text-right">
          <Link className="button margin-right-1" to={cancelLink}>
            <FormattedMessage id="general.button.cancel" />
          </Link>
          <Button
            primary
            className="submit wider"
            onClick={() => submit('group-movement')}
            disabled={isCreateDisabled || isLoading || isSubmitting}
          >
            <FormattedMessage id="general.button.create" />
          </Button>
        </div>
      </section>

      <StickyFooter className="hide-for-large">
        <Button
          primary
          className="submit"
          onClick={() => submit('group-movement')}
          disabled={isCreateDisabled || isLoading || isSubmitting}
        >
          <FormattedMessage id="general.button.create" />
        </Button>
      </StickyFooter>
    </CenterBox>
  );
};

TransferCreate.propTypes = {
  params: T.object.isRequired,
  location: T.object.isRequired,
  router: T.object.isRequired,
  pigGroup: T.object,
  destination: T.object,
  migrationType: T.string,
  submit: T.func.isRequired,
  fetchPigGroup: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  measurementSystem: T.string.isRequired,
};

const selector = formValueSelector('group-movement');

export default connect(
  (state) => ({
    migrationType: selector(state, 'migration_type'),
    destination: selector(state, 'destination'),
    pigGroup: state.pigGroups.groupShow.data,
    measurementSystem: state.auth.user.current_company.measurement_system,
  }), {
    submit,
    fetchPigGroup,
    setPageOptions,
    openModal,
  }
)(TransferCreate);
