import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// components
import Link from 'react-router/lib/Link';
import { FormattedMessage, useIntl } from 'react-intl';
import LabelledInput from 'components/LabelledInput';
import LayoutMinimal from 'components/LayoutMinimal';
import Button from 'components/Button';
import Preloader from 'components/Preloader';
import PasswordStrength from './components/PasswordStrength';
// auth api
import { updateAuthPassword, checkResetPasswordToken } from 'endpoints/auth';
// utils
import { passwordRules } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import './SetPassword.scss';

const passwordInputModel = {
  type: 'password',
  name: 'password',
};

const passwordConfirmationInputModel = {
  type: 'password',
  name: 'password_confirmation',
};

function validatePassword(value) {
  return /^(?=.{6,})/.test(value) && !['password', '123456', 'abcdef'].includes(value);
}

const ResetPassword = ({ location }, { router }) => {
  const [{ isLoading, isTokenExpired, password, password_confirmation, passwordStrength }, setState] = useState({
    isLoading: false,
    isTokenExpired: false,
    passwordStrength: '',
    password: null,
    password_confirmation: null,
  });
  const { formatMessage } = useIntl();
  const { reset_password_token } = location.query;

  useEffect(() => {
    const redirect_url = window.location.origin + '/set-password';
    checkResetPasswordToken({ reset_password_token, redirect_url }).catch(() => {
      setState((prevState) => ({ ...prevState, isTokenExpired: true }));
    });
  }, [reset_password_token]);

  const handleResetPassword = (e) => {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, isLoading: true }));
    updateAuthPassword({ password, password_confirmation, reset_password_token })
      .then(() => {
        router.push('/set-password-message?type=reset');
      })
      .catch((error) => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
        toastResponseErrors(
          error,
          { reset_password_token: formatMessage({ id: 'container.login.reset.linkExpired' }) }
        );
      });
  };

  const calcPasswordStrength = (value) => {
    return Object.keys(passwordRules)
      .map((rule) => passwordRules[rule].test(value) && rule)
      .filter((rule) => !!rule)
      .join(' ');
  };

  const handlePasswordValidate = (data, value) => {
    const isValidData = validatePassword(value);
    const passwordStrength = calcPasswordStrength(value);
    setState((prevState) => ({ ...prevState, passwordStrength, password: isValidData ? value : null }));
  };

  const handlePasswordConfirmValidate = (data, value) => {
    setState((prevState) => ({ ...prevState, password_confirmation: password === value ? value : null }));
  };

  if (isTokenExpired) {
    return (
      <LayoutMinimal containerClass="expired-token-layout">
        <i className="fa fa-clock-btm clock-icon" />
        <FormattedMessage id="container.login.reset.linkExpired" />
        <Link to="/reset-password" className="color-primary try-again-link">
          <FormattedMessage id="general.tryAgain" />
        </Link>
      </LayoutMinimal>
    );
  }

  return (
    <LayoutMinimal containerClass="set-password-layout">
      <FormattedMessage id="container.login.reset.changePassword">
        {(text) => <h1 className="title">{text}</h1>}
      </FormattedMessage>
      <form noValidate onSubmit={handleResetPassword}>
        <LabelledInput
          model={passwordInputModel}
          onChange={handlePasswordValidate}
        >
          <FormattedMessage id="container.invitations.createPassword" />
        </LabelledInput>
        <LabelledInput
          model={passwordConfirmationInputModel}
          onChange={handlePasswordConfirmValidate}
        >
          <FormattedMessage id="container.login.reset.confirmPassword" />
        </LabelledInput>

        <PasswordStrength
          value={passwordStrength}
          description={<FormattedMessage id="container.login.reset.secureHint" />}
        />
        <Preloader isActive={isLoading} />
        <Button
          className="lm-button margin-bottom-1"
          primary
          disabled={!password || !password_confirmation || password !== password_confirmation || isLoading}
        >
          <FormattedMessage id="general.button.save" />
        </Button>
      </form>
    </LayoutMinimal>
  );
};

ResetPassword.contextTypes = {
  router: T.object,
};

ResetPassword.propTypes = {
  location: T.object,
};

export default ResetPassword;
