import { createAction, handleActions, combineActions } from 'redux-actions';
// utils
import { clearAuthData } from 'utils/authData';
import { isCompletedTruckingUser, isUserDisabled } from 'utils/userHelper';
import { authFrontendV2Url } from 'utils/urlBuilder';
import { getLSDeviceToken, setLSDeviceToken } from 'utils/localStorageHelper';
// endpoints
import { fetchCompanies } from 'endpoints/companies';
import { makeFarmCheckIn } from 'endpoints/farms';
import { getUserSetting, updateUser, putUserSetting } from 'endpoints/users';
import { updateUserByToken } from 'endpoints/userInvites';
import { saveUserDevice } from 'endpoints/userDevices';
import { authValidateToken, authSignOut, authSignIn, postCurrentCompany } from 'endpoints/auth';

// ------------------------------------
// Constants
// ------------------------------------
const initialState = {
  authenticated: false,
  user: null,
  user_companies: [],
  user_setting: {},
  isSettingLoading: false,
  isCurrentCompanyLoading: false,
  isSettingLoaded: false,
  isLoading: false,
  error: null,
};

const LOGIN_PENDING = 'auth/LOGIN_PENDING';
const LOGIN_FULFILLED = 'auth/LOGIN_FULFILLED';
const LOGIN_REJECTED = 'auth/LOGIN_REJECTED';

const SET_CURRENT_COMPANY = 'auth/SET_CURRENT_COMPANY';
const UPDATE_CURRENT_COMPANY = 'auth/UPDATE_CURRENT_COMPANY';
const UPDATE_CURRENT_COMPANY_LOADING = 'auth/UPDATE_CURRENT_COMPANY_LOADING';

const VALIDATE_TOKEN = 'auth/VALIDATE_TOKEN';
const VALIDATE_TOKEN_PENDING = 'auth/VALIDATE_TOKEN_PENDING';
const VALIDATE_TOKEN_FULFILLED = 'auth/VALIDATE_TOKEN_FULFILLED';
const VALIDATE_TOKEN_REJECTED = 'auth/VALIDATE_TOKEN_REJECTED';

const FETCH_USER_SETTING = 'auth/FETCH_USER_SETTING';
const FETCH_USER_SETTING_PENDING = 'auth/FETCH_USER_SETTING_PENDING';
const FETCH_USER_SETTING_FULFILLED = 'auth/FETCH_USER_SETTING_FULFILLED';
const FETCH_USER_SETTING_REJECTED = 'auth/FETCH_USER_SETTING_REJECTED';

const UPDATE_USER_SETTING = 'auth/UPDATE_USER_SETTING';
const UPDATE_USER_SETTING_PENDING = 'auth/UPDATE_USER_SETTING_PENDING';
const UPDATE_USER_SETTING_FULFILLED = 'auth/UPDATE_USER_SETTING_FULFILLED';
const UPDATE_USER_SETTING_REJECTED = 'auth/UPDATE_USER_SETTING_REJECTED';

const FETCH_USER_COMPANIES = 'auth/FETCH_USER_COMPANIES';

const LOGOUT = 'auth/LOGOUT';
const SET_RESOURCE_LOADING = 'auth/SET_RESOURCE_LOADING';
const SET_AUTH_USER_DATA = 'auth/SET_AUTH_USER_DATA';
const SET_RESOURCE_ERRORS = 'auth/SET_RESOURCE_ERRORS';
const UPDATE_AUTH_USER_DATA = 'auth/UPDATE_AUTH_USER_DATA';
const SET_USER_CHECKIN = 'auth/SET_USER_CHECKIN';

const RESET_STORE = 'global/RESET_STORE';

// ------------------------------------
// Actions
// ------------------------------------

export const login = (user) => (dispatch) => {
  dispatch(createAction(LOGIN_PENDING)());
  return authSignIn(user)
    .then((responseUser) => {
      const isRedirectExisted =
        isCompletedTruckingUser(responseUser) &&
        !isUserDisabled(responseUser) &&
        responseUser.root_companies.length === 1;
      if (isRedirectExisted) {
        window.location.href = authFrontendV2Url('trucking');
      } else {
        dispatch(createAction(LOGIN_FULFILLED)(responseUser));
      }
      return responseUser;
    })
    .catch((response) => {
      dispatch(createAction(LOGIN_REJECTED)());
      throw response;
    });
};

export const validateToken = (headers = {}) => createAction(VALIDATE_TOKEN)(
  authValidateToken({ headers }).then((response) => {
    const { data: user } = response;
    const isRedirectExisted =
      isCompletedTruckingUser(user) &&
      !isUserDisabled(user) &&
      user.root_companies.length === 1;
    if (isRedirectExisted) {
      window.location.href = authFrontendV2Url('trucking');
    }

    return response;
  })
);

export const fetchUserCompanies = () => (dispatch) => (
  fetchCompanies()
    .then((resources) => {
      dispatch(createAction(FETCH_USER_COMPANIES)(resources));
    })
);

export const setCurrentCompany = createAction(SET_CURRENT_COMPANY);

export const selectCurrentCompany = (company_id) => (dispatch) => (
  postCurrentCompany(company_id)
    .then((resource) => {
      dispatch(createAction(SET_AUTH_USER_DATA)(resource));
      return resource;
    })
);

export const logoutAction = createAction(LOGOUT);
export const resetStore = createAction(RESET_STORE);
export const setResourceLoading = createAction(SET_RESOURCE_LOADING);
export const setAuthUserData = createAction(SET_AUTH_USER_DATA);
export const setResourceErrors = createAction(SET_RESOURCE_ERRORS);
export const updateAuthUserData = createAction(UPDATE_AUTH_USER_DATA);
export const setUserCheckIn = createAction(SET_USER_CHECKIN);

// ------------------------------------
// Action creators
// ------------------------------------
const signOut = () => (
  authSignOut()
    .then(() => {
      clearAuthData();
      setLSDeviceToken('');
    })
);

export const logout = (dispatch) => {
  dispatch(resetStore());
  dispatch(logoutAction());
  const deviceToken = getLSDeviceToken();
  if (deviceToken) {
    saveUserDevice({ tokens: [{ iid_token: deviceToken, action: 'destroy' }] })
      .then(() => signOut())
      .catch(() => signOut());
  } else {
    signOut();
  }
};

export const createPasswordByToken = (data, token) => (dispatch) => {
  dispatch(setResourceLoading(true));
  return updateUserByToken(data, token)
    .then((user) => {
      dispatch(setAuthUserData(user));
      dispatch(setResourceLoading(false));
      return user;
    })
    .catch((response) => {
      dispatch(setResourceErrors(response));
      dispatch(setResourceLoading(false));
      throw response;
    });
};

export const updateCurrentUser = (data, userId, options = {}) => (dispatch) => {
  dispatch(setResourceLoading(true));
  return updateUser(userId, data, options)
    .then((resource) => {
      dispatch(updateAuthUserData(resource));
      dispatch(setResourceLoading(false));
      return resource;
    })
    .catch((error) => {
      dispatch(setResourceLoading(false));
      throw error;
    });
};

export const checkinUser = (farmId) => (dispatch) => {
  return makeFarmCheckIn(farmId)
    .then((resource) => {
      dispatch(setUserCheckIn(resource));
      return resource;
    });
};

// User setting

export const fetchUserSetting = (id) => createAction(FETCH_USER_SETTING)(getUserSetting(id));

export const updateUserSetting = (resource, id) => createAction(UPDATE_USER_SETTING)(putUserSetting(id, resource));

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  // LOGIN
  [LOGIN_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [LOGIN_FULFILLED]: (state, action) => ({
    ...state,
    isLoading: false,
    user: action.payload,
    authenticated: true,
  }),
  [LOGIN_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
    authenticated: false,
  }),

  // SET_USER_AUTH_DATA
  [SET_RESOURCE_LOADING]: (state, action) => ({
    ...state,
    isLoading: action.payload,
  }),
  [SET_AUTH_USER_DATA]: (state, action) => ({
    ...state,
    user: action.payload,
    authenticated: true,
  }),
  [SET_RESOURCE_ERRORS]: (state, action) => ({
    ...state,
    error: action.payload.errors,
    authenticated: false,
  }),
  [UPDATE_AUTH_USER_DATA]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      ...action.payload,
      language: action.payload.language || state.user.language,
    },
  }),

  // VALIDATE_TOKEN
  [VALIDATE_TOKEN_PENDING]: (state) => ({
    ...state,
    authenticated: false,
    isLoading: true,
  }),
  [VALIDATE_TOKEN_FULFILLED]: (state, action) => ({
    ...state,
    authenticated: true,
    user: action.payload.data,
    isLoading: false,
  }),
  [VALIDATE_TOKEN_REJECTED]: (state, action) => ({
    ...state,
    authenticated: false,
    isLoading: false,
    error: action.payload.errors.join(', '),
  }),

  [combineActions(FETCH_USER_SETTING_PENDING, UPDATE_USER_SETTING_PENDING)]: (state) => ({
    ...state,
    isSettingLoading: true,
  }),
  [FETCH_USER_SETTING_FULFILLED]: (state, action) => ({
    ...state,
    user_setting: action.payload,
    isSettingLoading: false,
    isSettingLoaded: true,
  }),
  [UPDATE_USER_SETTING_FULFILLED]: (state, action) => ({
    ...state,
    user_setting: action.payload,
    user: {
      ...state.user,
      language: action.payload.language,
      sounds_effects: action.payload.sounds_effects,
    },
    isSettingLoading: false,
    isSettingLoaded: true,
  }),
  [combineActions(FETCH_USER_SETTING_REJECTED, UPDATE_USER_SETTING_REJECTED)]: (state) => ({
    ...state,
    isSettingLoading: false,
  }),

  // FETCH_USER_COMPANIES
  [FETCH_USER_COMPANIES]: (state, action) => ({
    ...state,
    user_companies: action.payload,
  }),

  // SET_CURRENT_COMPANY
  [SET_CURRENT_COMPANY]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      current_company: {
        ...state.user.current_company,
        ...action.payload,
      },
    },
  }),
  // UPDATE COMPANY
  [UPDATE_CURRENT_COMPANY_LOADING]: (state, action) => ({
    ...state,
    isCurrentCompanyLoading: action.payload,
  }),
  [UPDATE_CURRENT_COMPANY]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      current_company: {
        ...state.user.current_company,
        ...action.payload,
      },
    },
    isCurrentCompanyLoading: false,
  }),

  // SET_USER_CHECKIN
  [SET_USER_CHECKIN]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      check_in: action.payload,
    },
  }),

  // LOGOUT
  [LOGOUT]: () => ({
    ...initialState,
  }),
}, initialState);
