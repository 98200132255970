import React from 'react';
import T from 'prop-types';
import Select from '../Select';
import { useIntl } from 'react-intl';

const defaultPerPageOptions = [10, 25, 50, 100];

const PaginationSelect = ({ current, onChange, options = defaultPerPageOptions }) => {
  const { formatMessage } = useIntl();

  return (
    <label>
      <div className="table-select">
        <div className="title">{formatMessage({ id: 'general.button.view' })}</div>
        <Select
          value={current}
          onChange={(e) => onChange(parseInt(e.target.value, 10))}
        >
          {options.map((perPageOption) => (
            <option key={perPageOption} value={perPageOption}>{perPageOption}</option>
          ))}
        </Select>
      </div>
    </label>
  );
};

PaginationSelect.propTypes = {
  current: T.number,
  onChange: T.func,
  options: T.arrayOf(T.number),
};

PaginationSelect.defaultProps = {
  current: 25
};

export default PaginationSelect;
