import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Avatar from 'components/Avatar/Avatar';
// utils
import classnames from 'classnames/bind';
import { getFarmNickname } from 'utils';
// styles
import styles from './MobileRow.module.scss';

const cn = classnames.bind(styles);

const MobileFarmsRow = ({ name, actionBtnType, disabledRow, onRowClick, selectedRow }) => (
  <div
    className={cn('MobileRow', { 'no-events': disabledRow, 'select-row': actionBtnType === 'button' })}
    onClick={onRowClick}
  >
    <Avatar type="farm" avatarSize={40} iconSize={18} />
    <div className={cn('name-column')}>
      <div className={cn('name')}>{name}</div>
      <div className={cn('nickname')}>@{getFarmNickname({ name })}</div>
    </div>
    {actionBtnType === 'button' && (
      <Button disabled={selectedRow} className={cn('select-btn')} small primary>
        <FormattedMessage id="general.button.select" />
      </Button>
    )}
    {actionBtnType === 'checkbox' && (
      <div className={cn('icon', { 'selected': selectedRow })}>
        <i className={cn('checked', 'fa fa-check-circle-bts')} />
        <i className={cn('unchecked', 'fa fa-radio-unchecked')} />
      </div>
    )}
  </div>
);

MobileFarmsRow.propTypes = {
  actionBtnType: T.string.isRequired,
  name: T.string,
  disabledRow: T.bool,
  onRowClick: T.func.isRequired,
  selectedRow: T.bool,
};

export default MobileFarmsRow;
