import React from 'react';
import T from 'prop-types';
// components
import VideoPreviewOverlay from 'components/VideoPreviewOverlay';
import PdfAssetPreview from 'components/PdfAssetPreview';
// redux
import { connect } from 'react-redux';
import { openViewer } from 'reducers/mediaViewer';
// utils
import classnames from 'classnames/bind';
import { isVideoAsset, isImageAsset, isPDFAsset } from 'utils';
// styles
import styles from './SVRMediaRow.module.scss';

const cn = classnames.bind(styles);

const SVRMediaRow = ({ media = [], openViewer }) => {
  const visibleAssets = media
    .map((file, fileIndex) => ({ ...file, fileIndex }))
    .filter(({ action }) => action !== 'delete');

  return (
    <div className={cn('media-asset')}>
      {visibleAssets.map((file, index) => (
        <div
          onClick={() => openViewer(visibleAssets, index, {})}
          className={cn('asset-wrapper')}
          key={file.fileIndex}
        >

          {isPDFAsset(file) && <PdfAssetPreview asset={file} />}

          {(isVideoAsset(file) || isImageAsset(file)) && (
            <div
              style={{ backgroundImage: `url(${file.url?.thumb || URL.createObjectURL(file.asset)})` }}
              className={cn('preview-image')}
            />
          )}

          {isVideoAsset(file) && <VideoPreviewOverlay size="30" />}
        </div>
      ))}
    </div>
  );
};


SVRMediaRow.propTypes = {
  media: T.array,
  openViewer: T.func.isRequired,
};

export default connect(null, { openViewer })(SVRMediaRow);
