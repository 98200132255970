import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { updateUserProfile, fetchUserProfile } from 'reducers/profiles';
// components
import { FormattedMessage } from 'react-intl';
import Subnavigation from 'components/Subnavigation';
import ProgressChecklist from 'components/ProgressChecklist/ProgressChecklist';
import UserProfileHeader from 'components/ProfileHeader/UserProfileHeader/UserProfileHeader';
import UserMiniProfileHeader from 'components/ProfileHeader/UserProfileHeader/UserMiniProfileHeader';
import DropdownButton from 'components/DropdownButton';
import ProgressBox from 'components/Admin/ProgressBox';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import Panel from 'components/Panel';
import MessageBox from 'components/MessageBox';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { fetchFromAPI } from 'utils/api';
import { showToastrMessage } from 'utils';
import isEmpty from 'lodash.isempty';
// styles
import './UserProfile.scss';

const labels = {
  showUserCheckIns: <FormattedMessage id="general.showUserCheckIns" />,
  deleteUser: <FormattedMessage id="general.deleteUser" />,
  enableUser: <FormattedMessage id="general.button.enableUser" />,
  sendPasswordReset: <FormattedMessage id="general.button.sendPasswordReset" />,
  resendInvite: <FormattedMessage id="general.button.resendInvite" />,
  userSetupProgress: <FormattedMessage id="component.userProfile.userSetupProgress" />,
  basicUserInformation: <FormattedMessage id="component.userProfile.basicUserInformation" />,
  userSetup: <FormattedMessage id="component.userProfile.userSetup" />,
  assignFarmRoles: <FormattedMessage id="general.assignFarmRoles" />,
  farmRoles: <FormattedMessage id="general.farmRoles" />,
  companyRoles: <FormattedMessage id="general.companyRoles" />,
  truckingRoles: <FormattedMessage id="general.truckingRoles" />,
  userInformation: <FormattedMessage id="general.userInformation" />,
  assignRoles: <FormattedMessage id="general.assignRoles" />,
  commonRole: <FormattedMessage id="general.commonRole" />,
  inviteSent: <FormattedMessage id="general.inviteSent" />,
  registrationComplete: <FormattedMessage id="general.registrationComplete" />,
};

class UserProfile extends Component {

  componentDidMount() {
    const { fetchUserProfile, params: { id } } = this.props;
    fetchUserProfile(`/admin/users/${id}`).catch(toastResponseErrors);
  }

  getActionsList = () => {
    const { user } = this.props;
    if (!user) return [];
    return [
      { label: labels.showUserCheckIns, url: `/checkins/users/${user.id}` },
      { label: labels.deleteUser, onClick: this.deleteUser,
        hide: !['invited', 'bound'].includes(user.registration_state) && !['incomplete'].includes(user.status) },
    ];
  };

  getOptions = () => {
    const { user, params: { id } } = this.props;
    if (isEmpty(user)) return [];
    const { status, registration_state } = user;
    return [
      { label: labels.enableUser, onClick: this.enableUser, hide: status !== 'disabled' },
      { label: labels.sendPasswordReset, onClick: this.sendPassReset, hide: ['pending', 'disabled'].includes(status) },
      { label: labels.resendInvite, onClick: this.resentInvite, hide: status !== 'pending' },
      { label: labels.showUserCheckIns, url: `/checkins/users/${id}` },
      {
        label: labels.deleteUser, onClick: this.deleteUser,
        hide: !['invited', 'bound'].includes(registration_state) && !['incomplete'].includes(user.status)
      },
    ];
  };

  getDropdownAction = () => {
    const { user } = this.props;
    return {
      disabled: this.enableUser,
      incomplete: this.sendPassReset,
      pending: this.resentInvite,
      active: this.sendPassReset,
    }[user.status];
  };

  getDropdownLabel = () => {
    const { user } = this.props;
    return {
      disabled: labels.enableUser,
      incomplete: labels.sendPasswordReset,
      pending: labels.resendInvite,
      active: labels.sendPasswordReset,
    }[user.status];
  };

  deleteUser = () => {
    const { params: { id } } = this.props;
    fetchFromAPI(`/admin/users/${id}`, { method: 'delete' })
      .then(() => {
        this.context.router.push('/admin/users');
        showToastrMessage('component.toastr.user.deleted', 'success', 'fa fa-trash-o');
      })
      .catch(toastResponseErrors);
  };

  progressData = () => {
    const { user: { id, farm_roles_count }, authUser } = this.props;
    const isUserHasRoles = farm_roles_count > 0;
    const userAddedTo = (
      <FormattedMessage
        id="component.userProfile.userAddedTo"
        values={{ companyName: authUser.current_company.name }}
      />
    );
    return {
      closeable: isUserHasRoles,
      headerText: labels.userSetupProgress,
      steps: [
        { text: labels.basicUserInformation, done: true },
        { text: userAddedTo, done: true },
        { text: labels.assignFarmRoles, done: isUserHasRoles, url: `/admin/users/${id}/assign-farm-roles` },
      ],
    };
  };

  enableUser = () => {
    const { user, params: { id }, updateUserProfile } = this.props;
    const isDisabled = !isEmpty(user) && user.disabled;
    const endpoint = `/admin/users/${id}/${isDisabled ? 'enable' : 'activations'}`;
    const method = isDisabled ? 'put' : 'post';

    updateUserProfile(endpoint, { method })
      .then(() => {
        showToastrMessage('component.toastr.user.activated');
      })
      .catch(toastResponseErrors);
  };

  sendPassReset = () => {
    const { user: { email } } = this.props;
    const redirect_url = window.location.origin + '/set-password';
    const reqParams = { method: 'POST', body: JSON.stringify({ email, redirect_url }) };
    return fetchFromAPI('/auth/password', reqParams)
      .then(() => {
        showToastrMessage('component.toastr.passwordReset.sent');
      })
      .catch(toastResponseErrors);
  };

  resentInvite = () => {
    const { updateUserProfile, params: { id } } = this.props;
    updateUserProfile(`/users/${id}/reinvite`)
      .then(() => {
        showToastrMessage('component.toastr.userInvite.resent', 'success', 'fa fa-paper-plane');
      })
      .catch(toastResponseErrors);
  };

  render() {
    const {
      user, isLoading, children, authUser: { current_company: { logistics: isTenantUseLogistics } },
      params: { id }
    } = this.props;
    const isUserDisabled = user.status === 'disabled';
    const navLinks = [
      { link: `/admin/users/${id}/farm-roles`, label: labels.farmRoles },
      { link: `/admin/users/${id}/company-roles`, label: labels.companyRoles },
      { link: `/admin/users/${id}/trucking-roles`, label: labels.truckingRoles, isHidden: !isTenantUseLogistics },
      { link: `/admin/users/${id}/edit`, label: labels.userInformation },
    ];
    const steps = [
      { label: labels.userSetup, icon: 'fa fa-check', completed: true },
      { label: labels.commonRole, icon: 'fa fa-check', completed: true },
      { label: labels.inviteSent, icon: 'fa fa-envelope',
        completed: user.active || user.registration_state === 'bound' },
      { label: labels.assignRoles, icon: 'fa fa-ep-farm',
        completed: (user.trucking_roles_count || user.farm_roles_count) > 0 },
      { label: labels.registrationComplete, icon: 'fa fa-trophy',
        completed: (user.roles_count > 0) && (user.registration_state === 'complete') },
    ];

    return (
      <div className="AdminUserProfile clearfix">
        <UserProfileHeader
          actualUserId={Number(id)}
          user={user}
          isLoading={isLoading}
          className="show-for-large"
          dropdownOptions={this.getOptions()}
        >
          <ProgressChecklist steps={steps} />
        </UserProfileHeader>

        <Subnavigation
          className="small-12 column show-for-large"
          links={navLinks}
          stickyContent={<UserMiniProfileHeader user={user} />}
          isSticky
        />

        <section className="small-12 column">

          <Panel>
            <Panel.Heading title={user.full_name} className="hide-for-large">
              <StatusBadge status={user.status} />
              <DropdownButton
                idKey={`${id}`}
                label={this.getDropdownLabel()}
                onClick={this.getDropdownAction()}
                dropDownData={this.getActionsList()}
                wide
              />
            </Panel.Heading>
            <Panel.Body noPadding className="mobile-body">
              <Subnavigation className="hide-for-large" links={navLinks} inner />

              <div className="mobile-body-box">

                {!isEmpty(user) && !isUserDisabled &&
                  <ProgressBox className="hide-for-large progress-box" {...this.progressData()} />}

                {isUserDisabled && (
                  <div className="small-12 column">
                    <MessageBox type="warning" icon={<i className="fa fa-info-circle" />}>
                      <span>
                        <FormattedMessage id="component.alertBox.userDisabled" tagName="strong" />
                        <FormattedMessage id="component.alertBox.userDisabled.text" />
                      </span>
                      <button className="message-box-btn" onClick={this.enableUser}>
                        <FormattedMessage id="general.button.enableUser" />
                      </button>
                    </MessageBox>
                  </div>
                )}

                {children}
              </div>
            </Panel.Body>
          </Panel>

        </section>
      </div>
    );
  }
}

UserProfile.propTypes = {
  updateUserProfile: T.func.isRequired,
  fetchUserProfile: T.func.isRequired,
  params: T.object.isRequired,
  user: T.object.isRequired,
  authUser: T.object.isRequired,
  children: T.object.isRequired,
  isLoading: T.bool.isRequired,
};

UserProfile.contextTypes = {
  router: T.object,
};

export default connect(
  (state) => ({
    user: state.profiles.user.data,
    isLoading: state.profiles.user.isLoading,
    authUser: state.auth.user,
  }), { updateUserProfile, fetchUserProfile }
)(UserProfile);
