import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import UploadPlaceholder from './UploadPlaceholder';
import DotSeparator from 'components/DotSeparator/DotSeparator';
import Button from 'components/Button';
import UploadingFilePreview from './UploadingFilePreview';
import UploadedFilePreview from './UploadedFilePreview';
// redux
import { connect } from 'react-redux';
import { openViewer } from 'reducers/mediaViewer';
// utils
import classnames from 'classnames/bind';
import Resumable from 'resumablejs';
import { API_URL } from 'constants.js';
import { getBaseResumableSettings } from './helper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import styles from './MediaFilesUploader.module.scss';

const cn = classnames.bind(styles);

const body = document.getElementById('root');
const MediaFilesUploader = ({
  uploadPath,
  isOnline,
  uploadedFiles = [],
  onFileRemove,
  onFileUploaded,
  openViewer,
  types = ['jpg', 'jpeg', 'png', 'avi', 'mp4', 'mov', 'pdf'],
}) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [uploadFilesQueue, setUploadFilesQueue] = useState([]);
  const fileTypes = types.concat(types.map((ext) => ext.toUpperCase()));

  const removeFileFromUploadQueue = (file) => {
    setUploadFilesQueue((uploadFilesQueue) => {
      const fileIndex = uploadFilesQueue.findIndex((queueFile) => queueFile.uniqueIdentifier === file.uniqueIdentifier);
      return [...uploadFilesQueue.slice(0, fileIndex), ...uploadFilesQueue.slice(fileIndex + 1)];
    });
  };

  const onFileAdd = (file) => {
    setIsDraggingOver(false);
    setUploadFilesQueue((uploadFilesQueue) => [...uploadFilesQueue, file]);
    const R = file.resumableObj;

    if (isOnline) {
      R.upload();
    }
  };

  const onFileUploadSuccess = (file) => {
    removeFileFromUploadQueue(file);
    onFileUploaded(file);
  };

  const onFileUploadError = (file, message) => {
    console.log('ResumableJS: onFileError', file.fileName);
    toastResponseErrors(message);
  };

  const onUploadCancel = (file) => {
    if (isOnline) {
      file.cancel();
    }

    removeFileFromUploadQueue(file);
  };

  const updateFileProgress = (file) => {
    setUploadFilesQueue((uploadFilesQueue) => {
      const index = uploadFilesQueue.findIndex((uploadFile) => uploadFile.uniqueIdentifier === file.uniqueIdentifier);
      const updatedFile = {
        ...uploadFilesQueue[index],
        uploadProgress: file.progress(),
        cancelable: file.progress() < 0.7
      };

      return [...uploadFilesQueue.slice(0, index), updatedFile, ...uploadFilesQueue.slice(index + 1)];
    });
  };

  const onDragEnter = () => setIsDraggingOver(true);
  const onDragLeave = () => setIsDraggingOver(false);

  useEffect(() => {
    // event listeners
    if (document) {
      body.addEventListener('dragover', onDragEnter);
      body.addEventListener('dragleave', onDragLeave);
    }

    // initialize resumableJS
    const target = `${API_URL}${uploadPath}`;
    const R = new Resumable({ target, testTarget: target, fileType: fileTypes, ...getBaseResumableSettings(types) });

    if (R.support) {
      const browseButton = document.getElementById('resumable-browse-button');
      const browseButtonMobile = document.getElementById('resumable-browse-button-mobile');
      const dropzoneElement = document.getElementById('resumable-dropzone');
      R.assignDrop(dropzoneElement);
      R.assignBrowse([browseButton, browseButtonMobile]);
      R.on('fileAdded', onFileAdd);
      R.on('fileProgress', updateFileProgress);
      R.on('fileSuccess', onFileUploadSuccess);
      R.on('fileError', onFileUploadError);
    } else {
      console.warn('ResumableJS not supported!');
    }

    return () => {
      body.removeEventListener('dragover', onDragEnter);
      body.removeEventListener('dragleave', onDragLeave);
    };

  }, []);

  const uploadedFilesLength = uploadedFiles.length;

  return (
    <div className={cn('media-files-uploader')}>
      {/* ---- HEADER ---- */}
      <div className={cn('media-files-uploader__header')}>
        <FormattedMessage id="general.media" />
        <DotSeparator />
        {uploadedFilesLength}
      </div>

      {/* ---- BODY ---- */}
      <div id="resumable-dropzone" className={cn('media-files-uploader__body', { dragOver: isDraggingOver })}>
        <UploadPlaceholder />

        {/* ---- UPLOAD FILES QUEUE ---- */}
        {uploadFilesQueue.map((file, idx) => (
          <UploadingFilePreview
            onUploadCancel={onUploadCancel}
            file={file}
            key={`${idx}-${file.uniqueIdentifier}`}
          />
        ))}

        {/* ---- UPLOADED FILES ---- */}
        {uploadedFiles.map((file, idx) => (
          <UploadedFilePreview
            onPreviewClick={() => openViewer(uploadedFiles, idx, {})}
            file={file}
            key={`${idx}-${file.uniqueIdentifier}`}
            onFileRemove={onFileRemove}
          />
        ))}
      </div>

      {/* ---- MOBILE BROWSE BUTTON ---- */}
      <Button wide id="resumable-browse-button-mobile" light className={cn('browse-mobile-btn')}>
        <FormattedMessage id="general.button.browse" />
      </Button>
    </div>
  );
};

MediaFilesUploader.propTypes = {
  isOnline: T.bool.isRequired,
  uploadPath: T.string.isRequired,
  onFileRemove: T.func.isRequired,
  onFileUploaded: T.func.isRequired,
  uploadedFiles: T.array,
  openViewer: T.func.isRequired,
  types: T.arrayOf(T.string),
};

export default connect((state) => ({
  isOnline: state.network.isOnline,
}), { openViewer })(MediaFilesUploader);
