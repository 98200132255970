import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { deleteComment, updateComment } from 'reducers/farmfeed';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import UserFarmFeedTooltip from 'components/EntityFarmfeedTooltip/UserFarmfeedTooltip';
import MentionsInput from 'components/MentionsInput';
import Popover from 'components/Popover';
import TimeAgo from '../TimeAgo/TimeAgo';
import UserAvatar from 'components/UserAvatar';
// hooks
import useCommentWithMentionsState from 'hooks/useCommentWithMentionsState';
// utils
import cn from 'classnames';
import unescape from 'lodash.unescape';
import { renderMentionsAndLinksText } from 'utils';
import { getActivityMentionsInputData } from 'utils/activityHelper';
import { fetchTranslate } from 'utils/fetchTranslation';
import { languageMapping } from 'constants.js';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// assets
import './FarmFeedRowComment.scss';

const FarmFeedRowComment = (props) => {
  const {
    activity,
    authUser,
    comment: { id: commentId, comment, mentions, user, created_at, language },
    deleteComment,
    updateComment,
  } = props;
  const [{
    isEditing,
    isTranslationVisible,
    toTranslate,
    isSubmitting,
    translatedText
  }, setState] = useState({
    isSubmitting: false,
    isEditing: false,
    isTranslationVisible: false,
    translatedText: '',
    toTranslate: '',
  });
  const {
    comment: editedComment,
    mentions: commentMentions,
    setComment,
    setMentions,
  } = useCommentWithMentionsState(comment, mentions);
  const username = user ? (user.full_name || user.email) : 'Unnamed';
  const isAdmin = authUser.roles_map.admin;
  const canEditComment = authUser.roles_map.admin || (user.id === authUser.id);

  const onTranslate = () => {
    fetchTranslate(authUser.language, editedComment, commentId)
      .then((response) => {
        const text = unescape(response?.resource?.data?.translations[0]?.translatedText || '');
        setState((prevState) => ({ ...prevState, translatedText: text, toTranslate: editedComment }));
      })
      .catch((error) => {
        return error.then(({ error }) => toastResponseErrors({ error: error.message || 'Error to translate' }));
      });
  };

  const toggleTranslation = () => {
    if (!translatedText) return onTranslate();
    return setState((prevState) => ({ ...prevState, isTranslationVisible: !prevState.isTranslationVisible }));
  };

  const turnOnEdit = (e) => {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, isEditing: true }));
  };

  const turnOffEdit = () => {
    setState((prevState) => ({ ...prevState, isEditing: false }));
    setComment(comment);
  };

  const commentOnDelete = (e) => {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, isSubmitting: true }));
    deleteComment(activity.id, commentId).finally(() => {
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
    });
  };

  const commentOnUpdate = () => {
    const comment_mentions_attributes = commentMentions.map((mention) => ({
      user_id: parseInt(mention.id, 10),
    }));
    setState((prevState) => ({ ...prevState, isSubmitting: true }));
    updateComment({ ...props.comment, activityId: activity.id, comment: editedComment, comment_mentions_attributes })
      .then(() => {
        setState((prevState) => ({ ...prevState, isSubmitting: false, isEditing: false }));
      })
      .catch((errors) => {
        setState((prevState) => ({ ...prevState, isSubmitting: false }));
        return toastResponseErrors(errors);
      });
    if (translatedText && editedComment !== toTranslate) {
      onTranslate();
    }
  };

  const renderTooltip = (wrappedNode) => (
    <Popover
      content={<UserFarmFeedTooltip activityId={activity.id} userId={user.id} isAdmin={isAdmin} />}
      placement="topLeft"
    >
      {wrappedNode}
    </Popover>
  );

  return (
    <div className={cn('comment animated fadeIn FarmFeedRowComment', { 'edit-mode': isEditing })}>
      {renderTooltip(
        <div className="avatar float-left">
          <UserAvatar user={user} size="25" />
        </div>
      )}
      <div className="comment-body">
        <div className="flex-container">
          {!isEditing && (
            <div className="comment__text">
              {renderTooltip(<span className="username">{username}</span>)}
              <span>{renderMentionsAndLinksText(comment)}</span>
              {translatedText && !isTranslationVisible && (
                <div className="translated-text">
                  {renderMentionsAndLinksText(translatedText)}
                  <span className="google-translate">&nbsp;&mdash;
                    <FormattedMessage id="general.translatedByGoogle" />
                  </span>
                </div>
              )}
            </div>
          )}
          {isEditing && (
            <MentionsInput
              comment={editedComment}
              disabledIds={commentMentions.map((item) => item.id)}
              hasMentionBtn
              isEditCommentMode
              onCommentChange={setComment}
              onMentionsChange={setMentions}
              {...getActivityMentionsInputData(activity, authUser)}
            />
          )}
          <div className="editmode-buttons">
            <Button small className="mr-10" onClick={turnOffEdit} disabled={isSubmitting}>
              <FormattedMessage id="general.button.cancel" />
            </Button>
            <Button small primary onClick={commentOnUpdate} disabled={isSubmitting || comment === editedComment}>
              <FormattedMessage id="general.button.save" />
            </Button>
          </div>
        </div>
      </div>

      <div className="comment-footer">
        {canEditComment
          ? (
            <div className="buttons dot-list float-left">
              <a className="small-button dot-list-item edit primary" onClick={turnOnEdit}>
                <FormattedMessage id="general.button.edit" />
              </a>
              <a className="small-button dot-list-item delete primary" onClick={commentOnDelete}>
                <FormattedMessage id="general.button.delete" />
              </a>
              <span className="dot-list-item time"><TimeAgo date={created_at} /></span>
              {languageMapping[authUser.language] !== language && (
                <span className="small-button dot-list-item" onClick={toggleTranslation}>
                  <span className={cn('translated-button')}>
                    <FormattedMessage
                      id={`general.${(isTranslationVisible || !translatedText) ? 'see' : 'hide'}Translation`}
                    />
                  </span>
                </span>
              )}
            </div>
          )
          : (
            <div className="buttons dot-list float-left">
              <span className="dot-list-item time"><TimeAgo date={created_at} /></span>
              {languageMapping[authUser.language] !== language && (
                <span className="small-button dot-list-item" onClick={toggleTranslation}>
                  <span className={cn('translated-button')}>
                    <FormattedMessage
                      id={`general.${(isTranslationVisible || !translatedText) ? 'see' : 'hide'}Translation`}
                    />
                  </span>
                </span>
              )}
            </div>
          )}
      </div>
    </div>
  );
};

FarmFeedRowComment.contextTypes = {
  router: T.object.isRequired,
};

FarmFeedRowComment.propTypes = {
  comment: T.object.isRequired,
  deleteComment: T.func.isRequired,
  updateComment: T.func.isRequired,
  authUser: T.object.isRequired,
  activity: T.object.isRequired,
};

export default connect(
  (state) => ({
    authUser: state.auth.user,
  }),
  { deleteComment, updateComment },
)(FarmFeedRowComment);
