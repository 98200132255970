
import React, { Component } from 'react';
import T from 'prop-types';
// recompose
import { compose } from 'recompose';
import withCorrection from 'containers/DailyCheckup/utils/withCorrectionHOC';
// redux
import { connect } from 'react-redux';
import { deleteAsset, reportDeath, uploadAssetComplete } from 'reducers/dailyCheckup/checkupEdit';
import { setPageOptions } from 'reducers/layout';
import { openModal, closeModal } from 'reducers/modals';
import { openViewer } from 'reducers/mediaViewer';
// components
import MediaUploader from 'components/MediaUploader';
import { FormattedMessage } from 'react-intl';
import MessageBox from 'components/MessageBox';
import Button from 'components/Button';
import Link from 'react-router/lib/Link';
import PigsUnderCareLine from 'components/PigsUnderCareLine/PigsUnderCareLine';
import CenterBox from 'components/CenterBox';
import CommentBox from 'components/CommentBox';
import StickyHeader from 'components/StickyHeader/StickyHeader';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import NumberStepInput from 'components/NumberStepInput/NumberStepInput';
import CloseGroupModal from './modals/CloseGroupModal';
import MediaUploadProposeModal from 'components/DailyCheckups/modals/MediaUploadProposeModal';
import DailyCheckupEditProtector from './DailyCheckupEditProtector';
import PigsUnderTreatmentLine from 'components/PigsUnderTreatmentLine';
// utils
import isEmpty from 'lodash.isempty';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { animateScroll } from 'react-scroll';
import { sendNotify } from 'utils/airbrakeHelper';
import { getCommentByRole } from 'helpers.js';
import { isMobile } from 'react-device-detect';
// styles
import './DailyCheckupReportDeaths.scss';
// constants
import { comment_roles, deathTypes, API_URL } from 'constants.js';

class DailyCheckupReportDeaths extends Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialValues(props.checkup);
  }

  componentDidMount() {
    const { checkup, params, setPageOptions, isEditMode, backLink } = this.props;
    if (checkup.id === parseInt(params.id, 10)) {
      setPageOptions({
        backLink: { icon: 'fa fa-times fs-18', link: backLink },
        mobileTitle: 'reportDeaths',
        inEditMode: isEditMode,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { checkup } = this.props;
    const { checkup: prevCheckup } = prevProps;
    if (checkup.id !== prevCheckup?.id) {
      this.setState({
        ...this.getInitialValues(checkup)
      });
    }
  }

  componentWillUnmount() {
    this.props.setPageOptions({ mobileTitle: '' });
  }

  getInitialValues = (checkup) => {
    const { DEATHS_ROLE } = comment_roles;
    const { comment, mentioned_user_ids } = getCommentByRole(checkup?.comments || [], DEATHS_ROLE);

    return ({
      chronic: checkup?.chronic_death_count || 0,
      acute: checkup?.acute_death_count || 0,
      euthanasia: checkup?.euthanasia_death_count || 0,
      comment: comment || '',
      mentionsIds: mentioned_user_ids || [],
      treated: checkup?.treated_death_count || 0
    });
  };

  onCommentOpen = () => {
    animateScroll.scrollToBottom({ containerId: 'bodybag' });
  };

  handleInputChange = (type) => (value) => {
    this.setState({ [type]: value });
  };

  submitData = () => {
    const { router } = this.context;
    const { reportDeath, checkup, validateCheckup, backLink, makeCheckupCorrection, isEditMode, currentUser,
      openModal, closeModal, isOnline } = this.props;
    const { chronic, acute, euthanasia, treated, comment, mentionsIds } = this.state;
    if (!validateCheckup(checkup)) return;


    const resource = {
      acute_death_count: acute || 0,
      chronic_death_count: chronic || 0,
      euthanasia_death_count: euthanasia || 0,
      treated_death_count: treated || 0,
      comments_deaths: { text: comment, mentioned_user_ids: mentionsIds },
    };

    const submitRequest = isEditMode
      ? makeCheckupCorrection(checkup.id, resource)
      : reportDeath(checkup.id, { resource });

    submitRequest
      .then((resource = {}) => {
        router.push(backLink);
        if (isOnline && !isEditMode && resource.pigs === 0) {
          openModal(
            <CloseGroupModal pigGroup={resource.pig_group} closeModal={closeModal} />
          );
        }
      })
      .catch((response) => {
        sendNotify(response, currentUser);
        return toastResponseErrors(response);
      });
  };

  handleSubmitData = () => {
    const { openModal, checkup: { dc_assets }, currentUser } = this.props;
    const { media_upload, dc_mortality_media_prompt } = currentUser.current_company;
    const { acute } = this.state;
    const browseButton = document.getElementById('resumable-browse-link');
    const cameraButton = document.getElementById('resumable-camera');
    const isDeathsRelatedMediaFilesUploaded = dc_assets?.some((asset) => asset.category === 'deads');
    const showMediaUploadPropose = browseButton && cameraButton && acute && !isDeathsRelatedMediaFilesUploaded;

    if (showMediaUploadPropose && media_upload && dc_mortality_media_prompt) {
      openModal(
        <MediaUploadProposeModal
          label={<FormattedMessage id="component.modal.deathMediaUploadPropose.text" />}
          onClose={this.submitData}
          onSubmit={() => (isMobile ? cameraButton : browseButton).click()}
        />
      );
    } else {
      this.submitData();
    }
  }

  renderDeathTypeRows = ({ label, key }) => {
    const { pigs, deaths_sum } = this.props.checkup;
    return (
      <NumberStepInput
        key={key}
        isDisabled={!pigs && !deaths_sum}
        label={label}
        value={this.state[key] || ''}
        onChange={this.handleInputChange(key)}
      />
    );
  };

  isContinueDisabled = () => {
    const { chronic, acute, euthanasia, treated, comment } = this.state;
    const { chronic_death_count, acute_death_count, euthanasia_death_count, pigs, deaths_sum, treated_death_count,
      comments } = this.props.checkup;

    const initialChronic = chronic_death_count || 0;
    const initialAcute = acute_death_count || 0;
    const initialEuthanasia = euthanasia_death_count || 0;
    const initialTreated = treated_death_count || 0;
    const totalDeathReported = chronic + acute + euthanasia + treated_death_count;
    const isEqualDeaths =
      initialChronic === chronic &&
      initialAcute === acute &&
      initialEuthanasia === euthanasia &&
      initialTreated === treated;

    const commentData = getCommentByRole(comments, comment_roles.DEATHS_ROLE);
    const savedComment = commentData.comment || '';

    const currentComment = comment || '';
    const isEqualComment = savedComment === currentComment;
    const pigsAmount = (pigs + deaths_sum) - totalDeathReported;

    if (pigsAmount < 0) return true;

    return (isEqualComment && isEqualDeaths);
  };

  deleteAsset = (assetId) => {
    const { deleteAsset, checkup, validateCheckup } = this.props;
    if (!validateCheckup(checkup)) return;
    deleteAsset(checkup.id, assetId).catch(toastResponseErrors);
  };

  render() {
    const {
      isReportingDeaths,
      checkup,
      backLink,
      isEditMode,
      openViewer,
      params,
      validateCheckup,
      uploadAssetComplete,
      currentUser,
    } = this.props;
    const { comment, chronic, acute, euthanasia, mentionsIds, treated } = this.state;
    const { media_upload } = currentUser.current_company;
    const totalDeathReported = [chronic, acute, euthanasia, treated]
      .reduce((sum, val) => sum + (val || 0), 0);
    const pigsWithoutDeaths = checkup.pigs + checkup.deaths_sum;
    const pigsAmount = pigsWithoutDeaths - totalDeathReported;
    const treatedAmountWithoutDeaths = checkup.treated_pigs + checkup.treated_death_count;
    const treatedAmountWithDeaths = treatedAmountWithoutDeaths - treated;
    const treatedAmount = treatedAmountWithDeaths > pigsAmount ?
      treatedAmountWithDeaths - (treatedAmountWithDeaths - pigsAmount) :
      treatedAmountWithDeaths;

    const isContinueDisabled = this.isContinueDisabled();

    return (
      <CenterBox className="DailyCheckupReportDeaths" isLoading={isReportingDeaths} renderIf={Boolean(checkup)}>
        <DailyCheckupEditProtector pigGroup={checkup.pig_group}>
          <StickyHeader className="hide-for-large">
            <PigsUnderCareLine mobile pigsCount={pigsAmount} />
            {checkup.abf_tracking && <PigsUnderTreatmentLine mobile pigsCount={treatedAmount} />}
          </StickyHeader>

          <section className="center-box-header show-for-large">
            <div>
              <div className="subtitle">{checkup.pig_group_name}</div>
              <h1 className="title">
                <FormattedMessage id="general.deaths" />
              </h1>
            </div>
            <Link to={backLink}><i className="fa fa-times close-center-box" /></Link>
          </section>

          <PigsUnderCareLine className="show-for-large" mobile pigsCount={pigsAmount} />
          {checkup.abf_tracking &&
            <PigsUnderTreatmentLine className="show-for-large" mobile pigsCount={treatedAmount} />
          }

          <section className="center-box-body">
            {!isEditMode && !isEmpty(checkup) && (totalDeathReported > pigsWithoutDeaths) && (
              <MessageBox type="alert" isBorderHidden>
                <span className="head-treated-warning">
                  <FormattedMessage id="component.alertBox.warning" tagName="strong" />&nbsp;
                  <FormattedMessage id="container.checkupEdit.wrongNumberHeadTreated" />
                </span>
              </MessageBox>
            )}
            {isEditMode && (
              <MessageBox type="warning">
                <strong><FormattedMessage id="component.alertBox.warning" />&nbsp;</strong>
                <FormattedMessage id="container.checkupEdit.editWarning" />
              </MessageBox>
            )}
            {treatedAmount < 0 && (
              <MessageBox type="warning">
                <strong><FormattedMessage id="component.alertBox.warning" />&nbsp;</strong>
                <FormattedMessage id="container.checkupEdit.wrongNumberDeathTreated" />
              </MessageBox>
            )}
            <div>
              {deathTypes
                .filter(({ key }) => (key === 'treated' ? checkup.abf_tracking : true))
                .map(this.renderDeathTypeRows)
              }
            </div>

            <CommentBox
              hasMentions
              disabledIds={mentionsIds}
              entityId={checkup?.pig_group?.farm_id}
              entityType="farm"
              onOpen={this.onCommentOpen}
              onValueChange={(comment) => this.setState((prevState) => ({ ...prevState, comment }))}
              onMentionsChange={(mentions) => {
                this.setState((prevState) => ({ ...prevState, mentionsIds: mentions.map((i) => i.id) }));
              }}
              value={comment}
              isOpen={!!comment}
              placeholder=""
              useSpeechToText
            />


            {+params.id === checkup?.id && media_upload && (
              <MediaUploader
                checkup={checkup}
                customUploadPath={`${API_URL}/daily_checkups/${params.id}/chunk?category=deads`}
                deleteAsset={this.deleteAsset}
                filterAssets={(asset) => asset.category === 'deads'}
                headerLess
                openViewer={openViewer}
                paramsCheckupId={params.id}
                uploadAssetComplete={uploadAssetComplete}
                validateCheckup={validateCheckup}
              />
            )}
          </section>

          <section className="center-box-footer show-for-large">
            <div className="buttons text-right">
              <Link className="button margin-right-1" to={backLink}>
                <FormattedMessage id="general.button.cancel" />
              </Link>
              <Button
                primary
                disabled={isContinueDisabled || isReportingDeaths}
                className="submit wider"
                onClick={this.handleSubmitData}
              >
                <FormattedMessage id="general.button.continue" />
              </Button>
            </div>
          </section>

          <StickyFooter className="hide-for-large" hidden={isContinueDisabled}>
            <Button
              stickyFooter
              primary
              disabled={isContinueDisabled || isReportingDeaths}
              onClick={this.handleSubmitData}
            >
              <FormattedMessage id="general.button.continue" />
            </Button>
          </StickyFooter>
        </DailyCheckupEditProtector>
      </CenterBox>
    );
  }
}

DailyCheckupReportDeaths.contextTypes = {
  router: T.object.isRequired,
  formatMessage: T.func.isRequired,
};

DailyCheckupReportDeaths.propTypes = {
  checkup: T.object,
  isReportingDeaths: T.bool.isRequired,
  isOnline: T.bool.isRequired,
  setPageOptions: T.func.isRequired,
  reportDeath: T.func.isRequired,
  validateCheckup: T.func.isRequired,
  isEditMode: T.bool,
  params: T.object.isRequired,
  backLink: T.oneOfType([T.string, T.object]),
  makeCheckupCorrection: T.func,
  openModal: T.func.isRequired,
  closeModal: T.func.isRequired,
  currentUser: T.object.isRequired,
  deleteAsset: T.func.isRequired,
  openViewer: T.func.isRequired,
  uploadAssetComplete: T.func.isRequired,
};

const enhance = compose(
  connect(
    (state, props) => ({
      currentUser: state.auth.user,
      isOnline: state.network.isOnline,
      isReportingDeaths: props.isLoading || state.dailyCheckup.checkupEdit.isReportingDeaths,
    }), { reportDeath, setPageOptions, openModal, closeModal, uploadAssetComplete, openViewer, deleteAsset }
  ),
  withCorrection,
);

export default enhance(DailyCheckupReportDeaths);
