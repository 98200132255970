import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { setTableParams } from 'reducers/tableParams';
// components
import VerticalAligner from 'components/VerticalAligner';
import DotSeparator from 'components/DotSeparator/DotSeparator';
import { FormattedMessage } from 'react-intl';
import DataTable from 'components/DataTable/DataTable';
import DeleteTreatmentProtocolModal from './components/DeleteTreatmentProtocolModal';
import { Link } from 'react-router';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import NothingBox from 'components/NothingBox';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import { CustomColumn } from 'components/DataTable/Columns';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import Button from 'components/Button';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
import { useDropdownActions } from 'hooks/useDropdownContext';
// endpoints
import {
  getTreatmentProtocols,
  updateTreatmentProtocol,
  deleteTreatmentProtocol,
} from 'endpoints/treatmentProtocols';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import cn from 'classnames';
// constants
import { tableNames } from 'utils/constants';
// styles
import styles from './TreatmentProtocols.module.scss';

const TreatmentProtocols = ({
  location: {
    pathname,
  },
  openModal,
  router,
  tableParams,
  setBreadcrumbs,
  setPageOptions,
  setTableParams,
}) => {
  const {
    data: { resources, isLoading, meta },
    fetchData,
    onPageChange,
    onPerPageChange,
    onSearchChange,
    onStatusChange,
  } = useDataTable((params) => getTreatmentProtocols(params), {
    setTableParams: (params) => setTableParams(tableNames.treatmentProtocols, params),
    tableParams,
  });
  const { openDropdown } = useDropdownActions();
  const { page, per_page, search, status } = tableParams;
  const { total, stats } = meta;

  const paginationProps = {
    onPageChange,
    onPerPageChange,
    totalItems: total,
    currentPage: page,
    perPage: per_page,
  };

  const treatmentProtocolsTableFilters = [
    { label: <FormattedMessage id="general.allTreatmentProtocols" />, value: '' },
    { label: <FormattedMessage id="general.status.active" />, value: 'active' },
    { label: <FormattedMessage id="general.status.disabled" />, value: 'disabled' },
  ];

  useEffect(() => {
    fetchData();
    setPageOptions({ backLink: '' });
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.globalProtocols" />,
        path: '/treatment-protocols',
        useLabelAsMobileTitle: true
      },
    ]);
  }, []);

  const onTreatmentProtocolStatusChange = async (treatmentProtocolId, rowData) => {
    try {
      const farmIds = rowData.farms.map(({ id }) => id);
      await updateTreatmentProtocol(treatmentProtocolId, { active: !rowData.active }, farmIds);
    } catch (error) {
      toastResponseErrors(error);
    }
  };

  const onDeleteTreatmentProtocol = async (treatmentProtocol) => {
    try {
      await deleteTreatmentProtocol(treatmentProtocol.id);
      await fetchData();
    } catch (err) {
      toastResponseErrors(err);
    }
  };

  const handleDeleteTreatmentProtocol = (treatmentProtocol) => {
    openModal(
      <DeleteTreatmentProtocolModal
        treatmentProtocol={treatmentProtocol}
        onDeleteTreatmentProtocol={() => onDeleteTreatmentProtocol(treatmentProtocol)}
      />
    );
  };

  const handleOpenDropdown = (e, rowData) => {
    const options = [
      {
        label: <FormattedMessage id="general.button.delete" />,
        onClick: () => handleDeleteTreatmentProtocol(rowData),
        hide: rowData.active,
      },
    ];

    openDropdown(e, options, { isScrollIgnored: true });
  };

  const renderNameColumn = (rowData, rowIndex) => (
    <CustomColumn>
      <div className={styles['treatment-protocol-name-column']}>
        <VerticalAligner>
          <span className="hide-for-large semibold">
            <FormattedMessage id="component.dataTable.headers.name" />
            <DotSeparator />
          </span>
          <Link className="primary" to={`${pathname}/${rowData.id}`}>
            {rowData.name}
          </Link>
        </VerticalAligner>
        {!rowData.active && (
          <i
            data-name={`${rowData.name}-${rowData.id}-${rowIndex}`}
            className={cn('fa fa-dots-three-horizontal hide-for-large', styles['dropdown-icon'])}
            onClick={(e) => handleOpenDropdown(e, rowData)}
          />
        )}
      </div>
    </CustomColumn>
  );

  const renderStatusColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <StatusBadge status={rowData.active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  const renderActionsColumn = (rowData, rowIndex) => {
    const options = [
      {
        label: <FormattedMessage id="general.button.edit" />,
        onClick: () => router.push(`${pathname}/${rowData.id}/edit`),
      },
      {
        label: <FormattedMessage id={`general.button.${rowData.active ? 'disable' : 'enable'}`} />,
        onClick: () => onTreatmentProtocolStatusChange(rowData.id, rowData).then(fetchData),
      },
      {
        label: <FormattedMessage id="general.button.delete" />,
        onClick: () => handleDeleteTreatmentProtocol(rowData),
        hide: rowData.active,
      }
    ];

    if (!options.length) return null;

    const firstOption = options[0];
    const restOptions = options.slice(1);

    return (
      <div className="collapsible-value button-column">
        <DropdownButton
          idKey={`${rowData.id}-${rowIndex}`}
          label={firstOption.label}
          buttonType="small light-gray"
          onClick={firstOption.onClick}
          dropDownData={restOptions}
          customClass="show-for-large"
          wide
        />
        <Link onClick={firstOption.onClick} className="button light hide-for-large">
          {firstOption.label}
        </Link>
        <Button
          className="hide-for-large"
          onClick={() => onTreatmentProtocolStatusChange(rowData.id, rowData).then(fetchData)}
        >
          <FormattedMessage id={`general.button.${rowData.active ? 'disable' : 'enable'}`} />
        </Button>
      </div>
    );
  };

  const columns = [
    {
      label: <FormattedMessage id="component.dataTable.headers.name" />,
      flex: '1 1 130px',
      renderer: renderNameColumn,
    },
    {
      label: <FormattedMessage id="component.dataTable.headers.status" />,
      flex: '1 1 100px',
      renderer: renderStatusColumn,
    },
    {
      label: '',
      flex: '0 0 135px',
      renderer: renderActionsColumn,
      fixed: true,
    },
  ];

  return (
    <section className="small-12 column">
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.globalProtocols" />}>
          <SearchBox initialValue={search} onChange={onSearchChange} />
        </Panel.Heading>
        <Panel.Body noPadding>
          <Preloader isActive={isLoading} />
          <TableFilter
            filters={treatmentProtocolsTableFilters}
            activeFilter={status}
            onFilterChange={onStatusChange}
            stats={stats}
            className="ph-10"
          >
            <Link
              to="/treatment-protocols/create"
              className="button small primary wider"
            >
              <FormattedMessage id="general.button.addTreatmentProtocol" />
            </Link>
          </TableFilter>
          <DataTable
            data={resources}
            columns={columns}
            scrollable
            isLoading={isLoading}
            paginationProps={paginationProps}
          />
          <NothingBox
            search={search}
            itemsName="treatmentProtocols"
            display={!resources.length}
            isLoading={isLoading}
          >
            <h2 className="lighter semibold">
              <FormattedMessage id="component.nothingBox.noTreatmentProtocols" />
            </h2>
          </NothingBox>
        </Panel.Body>
      </Panel>
    </section>
  );
};

TreatmentProtocols.propTypes = {
  router: T.object.isRequired,
  openModal: T.func.isRequired,
  location: T.object.isRequired,
  tableParams: T.object.isRequired,
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  setTableParams: T.func.isRequired,
};

export default connect(
  (state) => ({
    tableParams: tableParamsSelector(state, tableNames.treatmentProtocols),
  }), {
  openModal,
  setBreadcrumbs,
  setPageOptions,
  setTableParams,
}
)(TreatmentProtocols);
