import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { FormattedMessage } from 'react-intl';
import SubNavigation from 'components/Subnavigation';
import OfflineScreen from 'components/OfflineScreen';
// utils
import cn from 'classnames';
// styles
import './TreatmentProtocolsContainer.scss';

const subNavLinks = [
  {
    label: <FormattedMessage id="general.outOfComplianceGroups" />,
    link: '/treatment-protocols-groups',
  },
  {
    label: <FormattedMessage id="general.create" />,
    link: '/treatment-protocols-farms',
  },
  {
    label: <FormattedMessage id="general.globalProtocols" />,
    link: '/treatment-protocols',
  },
];

const subnavigationVisiblePaths = [
  '/treatment-protocols-groups',
  '/treatment-protocols-farms',
  '/treatment-protocols',
];

const TreatmentProtocolsContainer = ({ children, isOnline, location: { pathname } }) => {
  const isSubnavigationVisible = subnavigationVisiblePaths.includes(pathname);
  return (
    <div className={cn('TreatmentProtocolsContainer')}>
      {isSubnavigationVisible && <SubNavigation isSticky links={subNavLinks} className="small-12 column" />}
      {isOnline ? children : <OfflineScreen />}
    </div>
  );
};

TreatmentProtocolsContainer.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
  isOnline: T.bool.isRequired,
  location: T.object.isRequired,
};

export default connect(
  (state) => ({
    isOnline: state.network.isOnline,
  })
)(TreatmentProtocolsContainer);
