import React, { Component } from 'react';
import T from 'prop-types';
// recompose
import { compose } from 'recompose';
import withCorrection from 'containers/DailyCheckup/utils/withCorrectionHOC';
// redux
import { connect } from 'react-redux';
import { reportDeath, uploadAssetComplete, deleteAsset } from 'reducers/dailyCheckup/checkupEdit';
import { openPortalRight, closePortalRight } from 'reducers/rightToLeftPortal';
import { openModal, closeModal } from 'reducers/modals';
import { openViewer } from 'reducers/mediaViewer';
import {
  fetchList as fetchMRList,
  setSelectedData,
  selectItem as selectReason,
  unselectItem as unselectReason,
  resetData as resetReasonsData,
} from 'reducers/mobileListPicker';
import { setPageOptions } from 'reducers/layout';
// components
import { FormattedMessage } from 'react-intl';
import { Collapse } from 'react-collapse';
import MessageBox from 'components/MessageBox';
import Button from 'components/Button';
import Link from 'react-router/lib/Link';
import CenterBox from 'components/CenterBox';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import StickyHeader from 'components/StickyHeader/StickyHeader';
import ReactSelect from 'components/Select/ReactSelect';
import CommentBox from 'components/CommentBox';
import MobileListPicker from 'components/MobileListPicker/MobileListPicker';
import MobileMortalityReasonRow from 'components/DailyCheckups/MobileMortalityReasonRow';
import MobileInputLikeRow from 'components/MobileInputLikeRow';
import PigsUnderCareLine from 'components/PigsUnderCareLine/PigsUnderCareLine';
import NumberStepInput from 'components/NumberStepInput/NumberStepInput';
import CloseGroupModal from './modals/CloseGroupModal';
import MediaUploader from 'components/MediaUploader';
import MediaUploadProposeModal from 'components/DailyCheckups/modals/MediaUploadProposeModal';
import PigsUnderTreatmentLine from 'components/PigsUnderTreatmentLine';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getDeathsCount } from 'utils';
import { animateScroll } from 'react-scroll';
import { isMobile } from 'react-device-detect';
import update from 'react-addons-update';
import isEqual from 'lodash.isequal';
import isEmpty from 'lodash.isempty';
import cn from 'classnames';
import { sendNotify } from 'utils/airbrakeHelper';
import { getCommentByRole } from 'helpers.js';
// assets
import { comment_roles, API_URL } from 'constants.js';
import './DailyCheckupReportMortalityReasons.scss';

const deathsTypes = [
  { label: <FormattedMessage id="general.deaths.acute-sudden" />, type: 'acute_death_count' },
  { label: <FormattedMessage id="general.deaths.chronic" />, type: 'chronic_death_count' },
  { label: <FormattedMessage id="general.deaths.euthanasia" />, type: 'euthanasia_death_count' },
  { label: <FormattedMessage id="general.treated" />, type: 'treated_death_count' }
];

class DailyCheckupReportMortalityReasons extends Component {

  state = {
    comment: '',
    reportedDeaths: false,
    mentionsIds: [],
  };

  componentDidMount() {
    const { fetchMRList } = this.props;
    this.setInitialData(this.props);
    fetchMRList('/mortality_reasons');
  }

  componentDidUpdate(prevProps) {
    const { checkup } = this.props;
    const { checkup: prevCheckup } = prevProps;
    if (checkup.id !== prevCheckup?.id) {
      this.setInitialData(this.props);
    }
  }

  componentWillUnmount() {
    this.props.resetReasonsData();
    this.props.setPageOptions({ mobileTitle: '' });
  }

  setInitialData = ({ checkup, backLink, isEditMode }) => {
    const { setSelectedData, setPageOptions } = this.props;
    if (isEmpty(checkup)) return;

    const {
      deaths_sum, chronic_death_count, acute_death_count, euthanasia_death_count, treated_death_count
    } = checkup;
    const checkupReasonsLength = checkup?.mortality_reasons?.length || 0;

    if (!checkupReasonsLength && !deaths_sum) {
      if (isMobile) this.openMobilePicker(checkup, backLink);
      if (!isMobile) setSelectedData([{}]);
    } else {
      const emptyReason = [{ chronic_death_count, acute_death_count, euthanasia_death_count, treated_death_count }];
      setSelectedData(checkupReasonsLength ? checkup.mortality_reasons : emptyReason);
    }

    const {
      comment,
      mentioned_user_ids
    } = getCommentByRole(checkup?.comments || [], comment_roles.DEATHS_ROLE);

    this.setState({
      comment: comment || '',
      mentionsIds: mentioned_user_ids || [],
      reportedDeaths: checkup.deaths_sum > 0,
    });

    setPageOptions({
      mobileTitle: 'mortalities',
      backLink: {
        link: backLink,
        icon: 'fa fa-times fs-18',
      },
      inEditMode: isEditMode,
    });
  };

  optionRenderer = ({ name, code }) => (
    <div className="ReactSelect--option">
      <span className="mr-5">({code})</span>
      <span>{name}</span>
    </div>
  );

  handleInputChange = (index, type) => (value) => {
    const { selectedMR, setSelectedData } = this.props;
    setSelectedData(update(selectedMR, { [index]: { $merge: { [type]: value } } }));
  };

  openMobilePicker = (checkup, backLink) => {
    const { openPortalRight } = this.props;
    const title = <FormattedMessage id="general.pageTitle.selectReasons" />;
    openPortalRight(
      <MobileListPicker
        title={title}
        idKey="mortality_reason_id"
        backLinkOptions={{ url: backLink }}
        rightButtonOptions={{ label: 'next' }}
        rowRenderer={this.renderMobileListRow}
      />
    );
  };

  renderMobileListRow = (reason, mode, { isSelected, isDisabled }) => {
    const onRowClick = {
      select: () => this.onMobileReasonAdd(reason),
      change: () => this.onMobileReasonChange(reason),
      add: () => this.onMobileReasonAdd(reason),
    }[mode];
    const selectedRow = isSelected(reason);
    const disabledRow = isDisabled(reason);
    const actionBtnType = mode === 'change' ? 'button' : 'checkbox';
    return (
      <MobileMortalityReasonRow
        key={reason.id}
        name={reason.name}
        code={reason.code}
        actionBtnType={actionBtnType}
        disabledRow={disabledRow}
        onRowClick={onRowClick}
        selectedRow={selectedRow}
      />
    );
  };

  onCommentOpen = () => {
    animateScroll.scrollToBottom({ containerId: 'bodybag' });
  };

  addRow = () => {
    const { openPortalRight, setSelectedData, selectedMR } = this.props;
    const title = <FormattedMessage id="general.pageTitle.addReasons" />;
    if (isMobile) return openPortalRight(
      <MobileListPicker
        title={title}
        idKey="mortality_reason_id"
        mode="add"
        rightButtonOptions={{ label: 'next' }}
        rowRenderer={this.renderMobileListRow}
      />
    );
    return setSelectedData([...selectedMR, {}]);
  };

  removeRow = (index) => () => {
    const { selectedMR, setSelectedData } = this.props;
    setSelectedData(update(selectedMR, { $splice: [[index, 1]] }));
  };

  onReasonChange = (reason, index) => {
    const id = reason ? reason.id : null;
    const name = reason ? reason.name : null;
    const { selectedMR, setSelectedData } = this.props;
    setSelectedData(update(selectedMR, {
      [index]: { $merge: { mortality_reason_id: id, name } },
    }));
  };

  onMobileReasonAdd = ({ id, name }) => {
    const { selectedMR, selectReason, unselectReason } = this.props;
    const selected = selectedMR.find(({ mortality_reason_id }) => mortality_reason_id === id);
    return selected ? unselectReason(selected) : selectReason({ mortality_reason_id: id, name });
  };

  onMobileReasonChange = ({ id, name }) => {
    const { index } = this.state;
    const { selectedMR, setSelectedData, closePortalRight } = this.props;
    const mortalityReasons = update(selectedMR, {
      [index]: { $set: { mortality_reason_id: id, name } },
    });
    setSelectedData(mortalityReasons);
    closePortalRight();
  };

  onMobileModeChange = (index) => {
    this.setState({ index });
    const { openPortalRight } = this.props;
    const title = <FormattedMessage id="general.pageTitle.changeReason" />;
    openPortalRight(
      <MobileListPicker
        title={title}
        idKey="mortality_reason_id"
        mode="change"
        rowRenderer={this.renderMobileListRow}
      />
    );
  };

  onModalConfirm = () => {
    const { checkup: { id }, resetReasonsData } = this.props;
    const { router } = this.context;
    resetReasonsData();
    router.push(`/daily-checkup/${id}/report-treatments`);
  };

  submitData = () => {
    const { selectedMR, checkup, reportDeath, validateCheckup, backLink, makeCheckupCorrection, isEditMode, openModal,
      closeModal, currentUser, isOnline } = this.props;
    const { router } = this.context;
    const { comment, mentionsIds } = this.state;

    if (!validateCheckup(checkup)) return;

    const data = {
      mortality_reasons: selectedMR.filter((item) => !!item.mortality_reason_id),
      comments_deaths: { text: comment, mentioned_user_ids: mentionsIds },
    };

    const submitRequest = isEditMode
      ? makeCheckupCorrection(checkup.id, data)
      : reportDeath(checkup.id, { resource: data });

    submitRequest
      .then((resource) => {
        router.push(backLink);
        if (isOnline && !isEditMode && resource?.pigs === 0) {
          openModal(
            <CloseGroupModal pigGroup={resource?.pig_group} closeModal={closeModal} />
          );
        }
      })
      .catch((response) => {
        sendNotify(response, currentUser);
        return toastResponseErrors(response);
      });
  };

  isValidReasonData = (reasons) => reasons.some((reason) => !reason.mortality_reason_id || !getDeathsCount(reason));

  isContinueDisabled = (reasons, checkup) => {
    const { reportedDeaths, comment } = this.state;
    const commentData = getCommentByRole(checkup?.comments || [], comment_roles.DEATHS_ROLE);
    const savedComment = commentData.comment || '';
    const currentComment = comment || '';
    const mortalityReasons = checkup.mortality_reasons || [];
    const treatedAmount = this.getTreatedAmount();

    if (treatedAmount < 0) return true;

    if (savedComment !== currentComment) return this.isValidReasonData(reasons);

    if (!reasons.length && reportedDeaths) return false;

    if (!reasons.length) return !mortalityReasons.length;

    if (mortalityReasons.length === reasons.length) {
      if (isEqual(reasons, mortalityReasons)) return true;
      return this.isValidReasonData(reasons);
    }
    return this.isValidReasonData(reasons);
  };

  getUnselectedMortalityReasons = (item) => {
    const { mortalityReasonsList, selectedMR } = this.props;

    return mortalityReasonsList.filter((mr) => (
      mr.id === item.mortality_reason_id ||
      !selectedMR.some((selected) => selected.mortality_reason_id === mr.id)));
  };

  getMortalityReasonName = ({ mortality_reason_id, name }) => {
    const { mortalityReasonsList } = this.props;
    if (name) return name;
    const reasonInList = mortalityReasonsList.find(({ id }) => (id === mortality_reason_id));
    return reasonInList ? reasonInList.name : 'N/A';
  };

  showDeathsRows = ({
    mortality_reason_id, chronic_death_count, acute_death_count, euthanasia_death_count, treated_death_count
  }) => {
    if (mortality_reason_id) return true;
    return Boolean(chronic_death_count || acute_death_count || euthanasia_death_count || treated_death_count);
  };

  deleteAsset = (assetId) => {
    const { deleteAsset, checkup, validateCheckup } = this.props;
    if (!validateCheckup(checkup)) return;
    deleteAsset(checkup.id, assetId).catch(toastResponseErrors);
  };

  handleSubmitData = () => {
    const { openModal, checkup: { dc_assets }, currentUser, selectedMR } = this.props;
    const { media_upload, dc_mortality_media_prompt } = currentUser.current_company;
    const browseButton = document.getElementById('resumable-browse-link');
    const cameraButton = document.getElementById('resumable-camera');
    const isDeathsRelatedMediaFilesUploaded = dc_assets?.some((asset) => asset.category === 'deads');
    const showMediaUploadPropose = browseButton && cameraButton && !isDeathsRelatedMediaFilesUploaded;
    const isAcuteDeathsExist = selectedMR.some(({ acute_death_count }) => acute_death_count);

    if (showMediaUploadPropose && media_upload && dc_mortality_media_prompt && isAcuteDeathsExist) {
      openModal(
        <MediaUploadProposeModal
          label={<FormattedMessage id="component.modal.deathMediaUploadPropose.text" />}
          onClose={this.submitData}
          onSubmit={() => (isMobile ? cameraButton : browseButton).click()}
        />
      );
    } else {
      this.submitData();
    }
  }

  getTreatedAmount() {
    const {
      checkup,
      selectedMR,
    } = this.props;
    const totalDeathReported = selectedMR.reduce((sum, item) => sum + (getDeathsCount(item) || 0), 0);
    const pigsWithoutDeaths = checkup.pigs + checkup.deaths_sum;
    const pigsAmount = pigsWithoutDeaths - totalDeathReported;
    const treatedDeathReported = selectedMR.reduce((sum, item) => sum + parseInt(item.treated_death_count || 0, 10), 0);
    const treatedPigsWithoutDeaths = checkup.treated_pigs + checkup.treated_death_count;
    const treatedPigsWithDeaths = treatedPigsWithoutDeaths - treatedDeathReported;

    return treatedPigsWithDeaths > pigsAmount ?
      treatedPigsWithDeaths - (treatedPigsWithDeaths - pigsAmount) :
      treatedPigsWithDeaths;
  }

  render() {
    const {
      checkup,
      isReportingDeaths,
      backLink,
      isEditMode,
      selectedMR,
      params,
      openViewer,
      validateCheckup,
      uploadAssetComplete,
      currentUser,
    } = this.props;
    const { comment, mentionsIds } = this.state;
    const { media_upload } = currentUser.current_company;
    const totalDeathReported = selectedMR.reduce((sum, item) => sum + (getDeathsCount(item) || 0), 0);
    const pigsWithoutDeaths = checkup.pigs + checkup.deaths_sum;
    const pigsAmount = pigsWithoutDeaths - totalDeathReported;
    const treatedAmount = this.getTreatedAmount();

    const isContinueDisabled = this.isContinueDisabled(selectedMR, checkup);
    // hide trash can if it unsaved last row
    const hideTrash = (selectedMR.length === 1) && !selectedMR[0].mortality_reason_id;
    const isWarningBoxVisible = !isEditMode && !isEmpty(checkup) && (pigsAmount < checkup.total_pigs_treated);

    return (
      <CenterBox isLoading={isReportingDeaths} renderIf={Boolean(checkup)} className="DailyCheckupReportDeathReasons">

        <StickyHeader className="hide-for-large">
          <PigsUnderCareLine mobile pigsCount={pigsAmount} />
          {checkup.abf_tracking && <PigsUnderTreatmentLine mobile pigsCount={treatedAmount} />}
        </StickyHeader>

        <section className="center-box-header show-for-large">
          <div>
            <div className="subtitle">{checkup.pig_group_name}</div>
            <h1 className="title"><FormattedMessage id="general.pageTitle.mortalities" /></h1>
          </div>
          <Link to={backLink}><i className="fa fa-times close-center-box" /></Link>
        </section>

        <PigsUnderCareLine className="show-for-large" mobile pigsCount={pigsAmount} />
        {checkup.abf_tracking && <PigsUnderTreatmentLine className="show-for-large" mobile pigsCount={treatedAmount} />}

        <section className="center-box-body">
          <Collapse isOpened={isWarningBoxVisible} theme={{ collapse: cn({ 'mb-20': isWarningBoxVisible }) }}>
            <MessageBox type="alert" className="mb-0" isBorderHidden>
              <span className="head-treated-warning">
                <FormattedMessage id="component.alertBox.warning" tagName="strong" />&nbsp;
                <FormattedMessage id="container.checkupEdit.wrongNumberHeadTreated" />
              </span>
            </MessageBox>
          </Collapse>
          {isEditMode && (
            <MessageBox type="warning">
              <strong><FormattedMessage id="component.alertBox.warning" />&nbsp;</strong>
              <FormattedMessage id="container.checkupEdit.editWarning" />
            </MessageBox>
          )}
          {treatedAmount < 0 && (
            <MessageBox type="warning">
              <strong><FormattedMessage id="component.alertBox.warning" />&nbsp;</strong>
              <FormattedMessage id="container.checkupEdit.wrongNumberDeathTreated" />
            </MessageBox>
          )}
          <div>
            {selectedMR.map((reason, index) => (
              <div className="mortality-reason-row" key={`mr-${index}-${reason.mortality_reason_id}`}>
                <div className="select-mort-reason-line">
                  <div className="index-cell">
                    <div className="row-index">{index + 1}</div>
                  </div>
                  <div className="select-mort-reason-wrapper">
                    {isMobile
                      ? (
                        <MobileInputLikeRow
                          value={this.getMortalityReasonName(reason)}
                          onClick={() => this.onMobileModeChange(index)}
                        />
                      )
                      : (
                        <ReactSelect
                          placeholder={<FormattedMessage id="general.pageTitle.selectReasons" />}
                          options={this.getUnselectedMortalityReasons(reason)}
                          optionRenderer={this.optionRenderer}
                          valueKey="id"
                          labelKey="name"
                          arrowRenderer={() => <i className="fa fa-angle-down" />}
                          value={reason.mortality_reason_id}
                          onChange={(data) => this.onReasonChange(data, index)}
                          resetValue={{}}
                          clearable={false}
                        />
                      )}
                  </div>
                  <div className={cn('trash-cell', { 'hidden': hideTrash })}>
                    <i className="fa fa-trash-o trash-icon" onClick={this.removeRow(index)} />
                  </div>
                </div>

                {this.showDeathsRows(reason) && (
                  <div>
                    {deathsTypes
                      .filter(({ type }) => (type === 'treated_death_count' ? checkup.abf_tracking : true))
                      .map(({ type, label }, idx) => (
                        <NumberStepInput
                          key={idx}
                          isDisabled={!checkup.pigs && !checkup.deaths_sum}
                          className="death-input"
                          label={label}
                          value={reason[type] || ''}
                          onChange={this.handleInputChange(index, type)}
                        />
                      ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          <button className="add-mortality-reason" onClick={this.addRow}>
            <FormattedMessage id="container.checkupEdit.reportReasons.add" />
          </button>

          <CommentBox
            hasMentions
            disabledIds={mentionsIds}
            entityId={checkup?.pig_group?.farm_id}
            entityType="farm"
            onOpen={this.onCommentOpen}
            onValueChange={(comment) => this.setState((prevState) => ({ ...prevState, comment }))}
            onMentionsChange={(mentions) => {
              this.setState((prevState) => ({ ...prevState, mentionsIds: mentions.map((i) => i.id) }));
            }}
            value={comment}
            isOpen={!!comment}
            placeholder=""
            useSpeechToText
          />

          {+params.id === checkup?.id && media_upload && (
            <MediaUploader
              checkup={checkup}
              customUploadPath={`${API_URL}/daily_checkups/${params.id}/chunk?category=deads`}
              deleteAsset={this.deleteAsset}
              filterAssets={(asset) => asset.category === 'deads'}
              headerLess
              openViewer={openViewer}
              paramsCheckupId={params.id}
              uploadAssetComplete={uploadAssetComplete}
              validateCheckup={validateCheckup}
            />
          )}
        </section>

        <section className="center-box-footer show-for-large">
          <div className="buttons text-right">
            <Link className="button margin-right-1" to={backLink}>
              <FormattedMessage id="general.button.cancel" />
            </Link>
            <Button
              primary
              disabled={isContinueDisabled || isReportingDeaths || pigsAmount < 0}
              className="submit wider"
              onClick={this.handleSubmitData}
            >
              <FormattedMessage id="general.button.continue" />
            </Button>
          </div>
        </section>

        <StickyFooter className="hide-for-large" hidden={isContinueDisabled}>
          <Button
            primary
            stickyFooter
            disabled={isContinueDisabled || isReportingDeaths || pigsAmount < 0}
            onClick={this.handleSubmitData}
          >
            <FormattedMessage id="general.button.continue" />
          </Button>
        </StickyFooter>
      </CenterBox>
    );
  }
}

DailyCheckupReportMortalityReasons.propTypes = {
  checkup: T.object,
  isReportingDeaths: T.bool.isRequired,
  isOnline: T.bool.isRequired,
  reportDeath: T.func.isRequired,
  validateCheckup: T.func.isRequired,
  fetchMRList: T.func.isRequired,
  setSelectedData: T.func.isRequired,
  selectReason: T.func.isRequired,
  unselectReason: T.func.isRequired,
  resetReasonsData: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  selectedMR: T.array,
  openPortalRight: T.func.isRequired,
  openModal: T.func.isRequired,
  closeModal: T.func.isRequired,
  closePortalRight: T.func.isRequired,
  makeCheckupCorrection: T.func.isRequired,
  backLink: T.string,
  isEditMode: T.bool,
  mortalityReasonsList: T.array.isRequired,
  currentUser: T.object.isRequired,
  params: T.object.isRequired,
  openViewer: T.func.isRequired,
  deleteAsset: T.func.isRequired,
  uploadAssetComplete: T.func.isRequired,
};

DailyCheckupReportMortalityReasons.contextTypes = {
  router: T.object.isRequired,
};

const enhance = compose(
  connect(
    (state, props) => ({
      mortalityReasonsList: state.mobileListPicker.dataList.resources,
      selectedMR: state.mobileListPicker.selected,
      currentUser: state.auth.user,
      isOnline: state.network.isOnline,
      isReportingDeaths: props.isLoading || state.dailyCheckup.checkupEdit.isReportingDeaths,
    }), {
      reportDeath,
      setPageOptions,
      fetchMRList,
      setSelectedData,
      selectReason,
      unselectReason,
      resetReasonsData,
      openPortalRight,
      closePortalRight,
      openModal,
      closeModal,
      openViewer,
      deleteAsset,
      uploadAssetComplete,
    }
  ),
  withCorrection,
);

export default enhance(DailyCheckupReportMortalityReasons);
