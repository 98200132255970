import React, { Component } from 'react';
import T from 'prop-types';
// recompose
import { compose } from 'recompose';
import withCorrection from './utils/withCorrectionHOC';
// redux
import { connect } from 'react-redux';
import { deleteAsset, reportSymptoms, uploadAssetComplete } from 'reducers/dailyCheckup/checkupEdit';
import { openPortalRight, closePortalRight } from 'reducers/rightToLeftPortal';
import {
  fetchList as fetchSymptomsList,
  setDestroyedData,
  setSelectedData,
  selectItem as selectSymptom,
  unselectItem as unselectSymptom,
  resetData as resetSymptomsData,
} from 'reducers/mobileListPicker';
import { setPageOptions } from 'reducers/layout';
import { openViewer } from 'reducers/mediaViewer';
import { openModal } from 'reducers/modals';
// components
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import MediaUploader from 'components/MediaUploader';
import MessageBox from 'components/MessageBox';
import Button from 'components/Button';
import CenterBox from 'components/CenterBox';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import ReactSelect from 'components/Select/ReactSelect';
import CommentBox from 'components/CommentBox';
import PercentInput from 'components/PercentInput/PercentInput';
import MobileListPicker from 'components/MobileListPicker/MobileListPicker';
import MobileSymptomRow from 'components/DailyCheckups/MobileSymptomRow';
import MediaUploadProposeModal from 'components/DailyCheckups/modals/MediaUploadProposeModal/MediaUploadProposeModal';
import DailyCheckupEditProtector from './DailyCheckupEditProtector';
// utils
import { animateScroll } from 'react-scroll';
import { isMobile } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import update from 'react-addons-update';
import memoize from 'lodash.memoize';
import isEmpty from 'lodash.isempty';
import classnames from 'classnames';
import { sendNotify } from 'utils/airbrakeHelper';
import { getCommentByRole } from 'helpers.js';
import { isUserExactlyCaregiver } from 'utils/rolesHelper';
// assets
import './DailyCheckupReportSymptoms.scss';
// constants
import { API_URL, comment_roles } from 'constants.js';

class DailyCheckupReportSymptoms extends Component {

  state = {
    comment: '',
    mentionsIds: [],
  };

  componentDidMount() {
    const { fetchSymptomsList } = this.props;
    this.setInitialData(this.props);
    fetchSymptomsList('/symptoms');
  }

  componentDidUpdate(prevProps) {
    const { checkup } = this.props;
    const { checkup: prevCheckup } = prevProps;
    if (checkup.id !== prevCheckup?.id) {
      this.setInitialData(this.props);
    }
  }

  componentWillUnmount() {
    this.props.resetSymptomsData();
    this.props.setPageOptions({ mobileTitle: '' });
  }

  setInitialData = ({ checkup, backLink, isEditMode }) => {
    const { setSelectedData, setPageOptions } = this.props;
    const { SYMPTOMS_ROLE } = comment_roles;

    if (isEmpty(checkup)) return;

    const { comment, mentioned_user_ids } = getCommentByRole(checkup.comments || [], SYMPTOMS_ROLE);

    if (!checkup.dc_symptoms.length) {
      if (isMobile) this.openMobilePicker(checkup, backLink);
      if (!isMobile) setSelectedData([{}]);
    } else {
      setSelectedData(checkup.dc_symptoms);
    }

    this.setState({ comment: comment || '', mentionsIds: mentioned_user_ids || [] });

    setPageOptions({
      mobileTitle: 'reportSymptoms',
      backLink: {
        link: backLink,
        icon: 'fa fa-times fs-18',
      },
      inEditMode: isEditMode,
    });
  };

  openMobilePicker = (checkup, backLink) => {
    const { openPortalRight, isEditingDisabled } = this.props;
    if (isEditingDisabled) return;
    const title = <FormattedMessage id="general.pageTitle.selectSymptoms" />;
    openPortalRight(
      <MobileListPicker
        title={title}
        idKey="symptom_id"
        backLinkOptions={{ url: backLink }}
        rightButtonOptions={{ label: 'next' }}
        rowRenderer={this.renderMobileListRow}
      />
    );
  };

  renderMobileListRow = (symptom, mode, { isSelected, isDisabled }) => {
    const onRowClick = {
      select: this.onMobileSymptomAdd(symptom),
      change: this.onMobileSymptomChange(symptom),
      add: this.onMobileSymptomAdd(symptom),
    }[mode];
    const selectedRow = isSelected(symptom);
    const disabledRow = isDisabled(symptom);
    const actionBtnType = mode === 'change' ? 'button' : 'checkbox';
    return (
      <MobileSymptomRow
        key={symptom.id}
        name={symptom.name}
        actionBtnType={actionBtnType}
        disabledRow={disabledRow}
        onRowClick={onRowClick}
        selectedRow={selectedRow}
      />
    );
  };

  onCommentOpen = () => {
    animateScroll.scrollToBottom({ containerId: 'bodybag' });
  };

  addRow = () => {
    const { openPortalRight, setSelectedData, selectedSymptoms } = this.props;
    const title = <FormattedMessage id="general.pageTitle.addSymptoms" />;
    if (isMobile) {
      return openPortalRight(
        <MobileListPicker
          title={title}
          mode="add"
          idKey="symptom_id"
          rightButtonOptions={{ label: 'next' }}
          rowRenderer={this.renderMobileListRow}
        />
      );
    }
    return setSelectedData([...selectedSymptoms, {}]);
  };

  removeRow = (index) => () => {
    const { selectedSymptoms, destroyedSymptoms, setDestroyedData, setSelectedData } = this.props;
    const data = [...selectedSymptoms];
    const destroyData = [];
    if (data[index].id) {
      destroyData.push({
        ...data[index],
        _destroy: true,
      });
    }
    setSelectedData(update(selectedSymptoms, { $splice: [[index, 1]] }));
    if (destroyData.length) setDestroyedData([...destroyedSymptoms, ...destroyData]);
  };

  onSymptomChange = (index) => ({ id, name }) => {
    const { selectedSymptoms, destroyedSymptoms, setDestroyedData, setSelectedData } = this.props;
    const destroyedSymptom = destroyedSymptoms.find((destroyed) => destroyed.symptom_id === id);
    const newSymptom = destroyedSymptom
      ? { ...destroyedSymptom, frequency: 0, _destroy: undefined }
      : { symptom_id: id, name };
    const newSymptoms = update(selectedSymptoms, {
      [index]: { $merge: newSymptom },
    });

    if (destroyedSymptom) {
      setDestroyedData(destroyedSymptoms.filter((destroyed) => destroyed.symptom_id !== id));
    }
    setSelectedData(newSymptoms);
  };

  onSymptomValueChange = (index) => (newValue) => {
    const { selectedSymptoms, setSelectedData } = this.props;
    const symptoms = update(selectedSymptoms, {
      [index]: { $merge: { frequency: newValue } },
    });
    setSelectedData(symptoms);
  };

  onMobileSymptomAdd = (symptom) => () => {
    const { selectedSymptoms, destroyedSymptoms, selectSymptom, unselectSymptom, setDestroyedData } = this.props;

    const selectedSymptom = selectedSymptoms.find(({ symptom_id }) => symptom_id === symptom.id);
    const destroyedSymptom = destroyedSymptoms.find((destroyed) => destroyed.symptom_id === symptom.id);
    const newSymptom = destroyedSymptom
      ? { ...destroyedSymptom, frequency: 0, _destroy: undefined }
      : { symptom_id: symptom.id, name: symptom.name };

    if (selectedSymptom) unselectSymptom(selectedSymptom);
    if (!selectedSymptom) selectSymptom(newSymptom);

    if (destroyedSymptom) {
      setDestroyedData(destroyedSymptoms.filter(({ symptom_id }) => symptom_id !== symptom.id));
    }
  };

  onMobileSymptomChange = (symptom) => () => {
    const { index } = this.state;
    const { selectedSymptoms, destroyedSymptoms, setDestroyedData, setSelectedData, closePortalRight } = this.props;
    const destroyed = { ...selectedSymptoms[index], _destroy: true };
    const symptoms = update(selectedSymptoms, {
      [index]: { $set: { symptom_id: symptom.id, name: symptom.name } },
    });
    setDestroyedData([...destroyedSymptoms, destroyed]);
    setSelectedData(symptoms);
    closePortalRight();
  };

  onMobileModeChange = (index) => () => {
    this.setState({ index });
    const { openPortalRight } = this.props;
    const title = <FormattedMessage id="general.pageTitle.changeSymptom" />;
    openPortalRight(
      <MobileListPicker
        title={title}
        mode="change"
        idKey="symptom_id"
        rowRenderer={this.renderMobileListRow}
      />
    );
  };

  handleSubmitData = () => {
    const { selectedSymptoms, destroyedSymptoms, openModal, checkup: { dc_assets }, currentUser } = this.props;
    const { comment, mentionsIds } = this.state;
    const { media_upload } = currentUser.current_company;

    const filteredList = selectedSymptoms.filter((item) => !!item.symptom_id);
    const destroyedFilteredList = destroyedSymptoms.filter(({ id, _destroy }) => !!id && _destroy);

    const resource = {
      dc_symptoms: [...filteredList, ...destroyedFilteredList],
      comments_symptoms: { text: comment, mentioned_user_ids: mentionsIds },
    };

    const { dc_symptoms } = resource;
    const mediaRequiredSymptoms = ['Coughing', 'Diarrhea (General)'];
    const isMediaRequiredSymptomsReported = dc_symptoms.some(({ name }) => mediaRequiredSymptoms.includes(name));
    const isSymptomsRelatedMediaFilesUploaded = dc_assets?.some((asset) => asset.category === 'symptoms');
    const browseButton = document.getElementById('resumable-browse-link');
    const cameraButton = document.getElementById('resumable-camera');
    const showMediaUploadPropose =
      isMediaRequiredSymptomsReported &&
      !isSymptomsRelatedMediaFilesUploaded &&
      browseButton &&
      cameraButton;

    if (showMediaUploadPropose && media_upload) {
      openModal(
        <MediaUploadProposeModal
          onClose={this.submitData}
          onSubmit={() => (isMobile ? cameraButton : browseButton).click()}
        />
      );
    } else {
      this.submitData();
    }
  }

  submitData = () => {
    const { selectedSymptoms, destroyedSymptoms, checkup, reportSymptoms, validateCheckup, currentUser,
      backLink, makeCheckupCorrection, isEditMode } = this.props;
    const { comment, mentionsIds } = this.state;
    const { router } = this.context;

    if (!validateCheckup(checkup)) return;

    const filteredList = selectedSymptoms.filter((item) => !!item.symptom_id);
    const destroyedFilteredList = destroyedSymptoms.filter(({ id, _destroy }) => !!id && _destroy);
    const resource = {
      dc_symptoms: [...filteredList, ...destroyedFilteredList],
      comments_symptoms: { text: comment, mentioned_user_ids: mentionsIds },
    };

    const submitRequest = isEditMode
      ? makeCheckupCorrection(checkup.id, resource)
      : reportSymptoms(checkup.id, { resource });

    submitRequest
      .then(() => {
        router.push(backLink);
      })
      .catch((response) => {
        sendNotify(response, currentUser);
        return toastResponseErrors(response);
      });
  };

  deleteAsset = (assetId) => {
    const { deleteAsset, checkup, validateCheckup } = this.props;
    if (!validateCheckup(checkup)) return;
    deleteAsset(checkup.id, assetId).catch(toastResponseErrors);
  };

  isContinueDisabled = memoize((symptoms, checkup) => {
    if (symptoms.length === 0) return !checkup?.dc_symptoms?.length;
    return symptoms.some((symptom) => !symptom.frequency);
  });

  getUnselectedSymptoms = (selectedSymptom) => {
    const { symptomsList, selectedSymptoms } = this.props;

    return symptomsList.filter((symptom) => (
      symptom.id === selectedSymptom.symptom_id ||
      !selectedSymptoms.some(({ symptom_id }) => symptom_id === symptom.id)
    ));
  };

  render() {
    const { checkup, isReportingSymptoms, backLink, isEditMode, selectedSymptoms, openViewer, params,
      validateCheckup, uploadAssetComplete, currentUser } = this.props;
    const { comment, mentionsIds } = this.state;
    const isContinueDisabled = this.isContinueDisabled(selectedSymptoms, checkup);
    const hideTrash = (selectedSymptoms.length) === 1 && !selectedSymptoms[0].id; // hide trash if last row not saved
    const { media_upload } = currentUser.current_company;

    return (
      <CenterBox isLoading={isReportingSymptoms} renderIf={Boolean(checkup)} className="DailyCheckupReportSymptoms">
        <DailyCheckupEditProtector pigGroup={checkup.pig_group}>
          <section className="center-box-header show-for-large">
            <div>
              <div className="subtitle">{checkup.pig_group_name}</div>
              <h1 className="title"><FormattedMessage id="general.symptoms" /></h1>
            </div>
            <Link to={backLink}><i className="fa fa-times close-center-box" /></Link>
          </section>

          <section className="center-box-body">
            {isEditMode && (
            <MessageBox type="warning">
              <strong><FormattedMessage id="component.alertBox.warning" />&nbsp;</strong>
              <FormattedMessage id="container.checkupEdit.editWarning" />
            </MessageBox>
            )}
            <div className="symptom-rows">
              {selectedSymptoms.map((symptom, index) => !symptom._destroy && (
              <div className="symptom-row" key={`symptom-${index}-${symptom.symptom_id}`}>
                <div className="select-symptom-line">
                  <div className="index-cell">
                    <div className="row-index">{index + 1}</div>
                  </div>
                  <div className="select-symptom-wrapper">
                    {isMobile
                      ? (
                        <div className="mobile-symptom-input" onClick={this.onMobileModeChange(index)}>
                          <div className="symptom-name">{symptom.name}</div>
                          <i className="change-symptom-icon fa fa-angle-right-btb" />
                        </div>
                      ) : (
                        <ReactSelect
                          placeholder={<FormattedMessage id="general.pageTitle.selectSymptoms" />}
                          options={this.getUnselectedSymptoms(symptom)}
                          valueKey="id"
                          labelKey="name"
                          arrowRenderer={() => <i className="fa fa-angle-down" />}
                          value={symptom.symptom_id}
                          onChange={this.onSymptomChange(index)}
                          resetValue={{}}
                          clearable={false}
                        />
                      )}
                  </div>
                  <div className={classnames('trash-cell', { 'hidden': hideTrash })}>
                    <i className="fa fa-trash-o trash-icon" onClick={this.removeRow(index)} />
                  </div>
                </div>

                {!!symptom.symptom_id && (
                  <div className="select-value-line">
                    <div className="index-cell" />
                    <div className="select-value-wrapper">
                      <div className="symptomatic">
                        <FormattedMessage id="container.checkupEdit.reportSymptoms.symptomatic" />
                      </div>
                      <PercentInput
                        value={symptom.frequency}
                        onChange={this.onSymptomValueChange(index)}
                      />
                    </div>
                    <div className={classnames('trash-cell', { 'hidden': hideTrash })} />
                  </div>
                )}
              </div>
              ))}
            </div>

            <button className="add-symptom" onClick={this.addRow}>
              <FormattedMessage id="container.checkupEdit.reportSymptoms.add" />
            </button>
            <CommentBox
              hasMentions
              disabledIds={mentionsIds}
              entityId={checkup?.pig_group?.farm_id}
              entityType="farm"
              onOpen={this.onCommentOpen}
              onValueChange={(comment) => this.setState((prevState) => ({ ...prevState, comment }))}
              onMentionsChange={(mentions) => {
                this.setState((prevState) => ({ ...prevState, mentionsIds: mentions.map((i) => i.id) }));
              }}
              value={comment}
              isOpen={!!comment}
              placeholder=""
              useSpeechToText
            />

            {+params.id === checkup?.id && media_upload && (
            <MediaUploader
              checkup={checkup}
              customUploadPath={`${API_URL}/daily_checkups/${params.id}/chunk?category=symptoms`}
              deleteAsset={this.deleteAsset}
              filterAssets={(asset) => asset.category === 'symptoms'}
              headerLess
              openViewer={openViewer}
              paramsCheckupId={params.id}
              uploadAssetComplete={uploadAssetComplete}
              validateCheckup={validateCheckup}
            />
            )}
          </section>

          <section className="center-box-footer show-for-large">
            <div className="buttons text-right">
              <Link className="button margin-right-1" to={backLink}>
                <FormattedMessage id="general.button.cancel" />
              </Link>
              <Button
                primary
                disabled={isContinueDisabled || isReportingSymptoms}
                className="submit wider"
                onClick={this.handleSubmitData}
              >
                <FormattedMessage id="general.button.continue" />
              </Button>
            </div>
          </section>

          <StickyFooter className="hide-for-large" hidden={isContinueDisabled}>
            <Button
              stickyFooter
              primary
              disabled={isContinueDisabled || isReportingSymptoms}
              onClick={this.handleSubmitData}
            >
              <FormattedMessage id="general.button.continue" />
            </Button>
          </StickyFooter>
        </DailyCheckupEditProtector>
      </CenterBox>
    );
  }
}

DailyCheckupReportSymptoms.contextTypes = {
  router: T.object.isRequired,
  formatMessage: T.func.isRequired,
};

DailyCheckupReportSymptoms.propTypes = {
  params: T.object.isRequired,
  checkup: T.object,
  isReportingSymptoms: T.bool.isRequired,
  reportSymptoms: T.func.isRequired,
  validateCheckup: T.func.isRequired,
  fetchSymptomsList: T.func.isRequired,
  setDestroyedData: T.func.isRequired,
  setSelectedData: T.func.isRequired,
  selectSymptom: T.func.isRequired,
  unselectSymptom: T.func.isRequired,
  resetSymptomsData: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  selectedSymptoms: T.array,
  deleteAsset: T.func.isRequired,
  openViewer: T.func.isRequired,
  openPortalRight: T.func.isRequired,
  closePortalRight: T.func.isRequired,
  makeCheckupCorrection: T.func.isRequired,
  uploadAssetComplete: T.func.isRequired,
  backLink: T.string,
  isEditMode: T.bool,
  symptomsList: T.array.isRequired,
  destroyedSymptoms: T.array,
  currentUser: T.object,
  openModal: T.func.isRequired,
  isEditingDisabled: T.bool.isRequired,
};

const enhance = compose(
  connect(
    (state, props) => {
      const currentUser = state?.auth?.user;
      const farmId = props.checkup?.pig_group?.farm_id;
      const isExactlyCaregiver = isUserExactlyCaregiver(currentUser, farmId);
      const isReviewMode = !!props.location?.query?.reviewMode;
      return {
        isEditingDisabled: currentUser.current_company.barnsheets_limited_access && isExactlyCaregiver && isReviewMode,
        symptomsList: state.mobileListPicker.dataList.resources,
        selectedSymptoms: state.mobileListPicker.selected,
        destroyedSymptoms: state.mobileListPicker.destroyed,
        currentUser: state.auth.user,
        isReportingSymptoms: props.isLoading || state.dailyCheckup.checkupEdit.isReportingSymptoms,
      };
    }, {
      deleteAsset,
      reportSymptoms,
      setPageOptions,
      fetchSymptomsList,
      setDestroyedData,
      setSelectedData,
      selectSymptom,
      unselectSymptom,
      resetSymptomsData,
      openViewer,
      openPortalRight,
      closePortalRight,
      uploadAssetComplete,
      openModal,
    }
  ),
  withCorrection,
);

export default enhance(DailyCheckupReportSymptoms);
