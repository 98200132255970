import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// components
import ArrowColumn from 'components/DataTable/Columns/ArrowColumn';
import Button from 'components/Button';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import NothingBox from 'components/NothingBox';
import DropdownButton from 'components/DropdownButton';
import DataTable from 'components/DataTable/DataTable';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import { CustomColumn } from 'components/DataTable/Columns';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
// redux
import { connect } from 'react-redux';
import { setTableParams } from 'reducers/tableParams';
// utils
import { tableNames } from 'utils/constants';
import cn from 'classnames';
import { isTablet } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
// api
import { getReportTemplates, updateReportTemplate } from 'endpoints/admin/reportTemplates';

const tableFilters = [
  { label: <FormattedMessage id="general.allReports" />, value: '' },
  { label: <FormattedMessage id="general.status.active" />, value: 'active' },
  { label: <FormattedMessage id="general.status.disabled" />, value: 'disabled' },
];

const ReportBuilder = ({ isSVREnabled, router, setTableParams, reqParams }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: { resources, isLoading: isResourcesLoading, meta: { stats, total } },
    fetchData,
    onPageChange,
    onPerPageChange,
    onSearchChange,
    onStatusChange,
  } = useDataTable(getReportTemplates, {
    tableParams: reqParams,
    setTableParams: (params) => setTableParams(tableNames.adminReportTemplates, params)
  });

  useEffect(() => {
    if (!isSVREnabled) router.push('/admin');
    else fetchData(reqParams).catch(toastResponseErrors);
  }, []);

  const toggleReportTemplateStatus = async (reportTemplate) => {
    setIsLoading(true);
    try {
      await updateReportTemplate(reportTemplate.id, { active: !reportTemplate.active });
      fetchData(reqParams);
    } catch (err) {
      toastResponseErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  const { page, per_page, status, search } = reqParams;
  const paginationProps = { onPageChange, onPerPageChange, totalItems: total, currentPage: page, perPage: per_page };

  const renderNameColumn = (rowData) => (
    <CustomColumn flexRow>
      <span className="semibold">{rowData.name}</span>
    </CustomColumn>
  );

  const renderStatusColumn = (rowData) => (
    <CustomColumn flexRow>
      <StatusBadge status={rowData.active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  const renderActionsColumn = (rowData, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${rowData.id}-${rowIndex}`}
        wide
        label={<FormattedMessage id="general.button.edit" />}
        buttonType="small light-gray"
        url={`/admin/report-builder/${rowData.id}`}
        dropDownData={[{
          label: rowData.active ?
            <FormattedMessage id="general.disableReport" /> :
            <FormattedMessage id="general.enableReport" />,
          onClick: () => toggleReportTemplateStatus(rowData)
        }]}
        customClass="show-for-large"
      />
      <Link to={`/admin/report-builder/${rowData.id}`} className="button light hide-for-large">
        <FormattedMessage id="general.button.edit" />
      </Link>
      <Button lightGray onClick={() => toggleReportTemplateStatus(rowData)} className="hide-for-large">
        {rowData.active ?
          <FormattedMessage id="general.disableReport" /> :
          <FormattedMessage id="general.enableReport" />
        }
      </Button>
    </div>
  );

  const columns = [
    {
      label: <FormattedMessage id="component.dataTable.headers.name" />,
      flex: '2 1 130px',
      renderer: renderNameColumn,
    },
    {
      label: <FormattedMessage id="component.dataTable.headers.status" />,
      flex: '1 1 100px',
      renderer: renderStatusColumn,
    },
    {
      label: '',
      flex: '0 0 135px',
      renderer: renderActionsColumn,
      fixed: true,
      className: cn({ 'hide-for-large': isTablet })
    },
    {
      label: '',
      flex: '0 0 40px',
      renderer: () => <ArrowColumn />,
      fixed: true,
      hide: !isTablet,
      hasPinnedIcon: true,
    },
  ];

  const renderExpandable = (rowData) => (
    <div>
      <Link to={`/admin/report-builder/${rowData.id}`} className="button light">
        <FormattedMessage id="general.button.edit" />
      </Link>
      <Button light onClick={() => toggleReportTemplateStatus(rowData)}>
        {rowData.active ?
          <FormattedMessage id="general.disableReport" /> :
          <FormattedMessage id="general.enableReport" />
        }
      </Button>
    </div>
  );

  return (
    <section className="small-12 column">
      <Preloader isActive={isLoading || isResourcesLoading} />
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.reports" />}>
          <SearchBox initialValue={search} onChange={onSearchChange} />
        </Panel.Heading>
        <Panel.Body noPadding>
          <TableFilter
            filters={tableFilters}
            activeFilter={status}
            onFilterChange={onStatusChange}
            stats={stats}
            className="ph-10"
          >
            <Link to="/admin/report-builder/create" className="button small primary wider">
              <FormattedMessage id="general.createReport" />
            </Link>
          </TableFilter>
          <DataTable
            data={resources}
            columns={columns}
            isExpandable={isTablet}
            renderExpandable={renderExpandable}
            scrollable
            isLoading={isResourcesLoading}
            paginationProps={paginationProps}
          />
          <NothingBox
            display={!resources.length}
            itemsName="reports"
            search={search}
            filter={status}
            isLoading={isResourcesLoading}
          >
            <FormattedMessage id="component.nothingBox.anyReportsYet"  />
            <FormattedMessage id="component.nothingBox.createReport" tagName="p" />
            <Link to="/admin/report-builder/create" className="button primary mt-10">
              <FormattedMessage id="general.createReport" />
            </Link>
          </NothingBox>
        </Panel.Body>
      </Panel>
    </section>
  );
};

ReportBuilder.propTypes = {
  isSVREnabled: T.bool.isRequired,
  router: T.object.isRequired,
  reqParams: T.object.isRequired,
  setTableParams: T.func.isRequired,
};

export default connect((state) => ({
  isSVREnabled: state.auth.user.current_company.admin_svrs,
  reqParams: tableParamsSelector(state, tableNames.adminReportTemplates),
}), { setTableParams })(ReportBuilder);
