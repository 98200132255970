import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
// utils
import { formValueSelector } from 'redux-form';

export const treatmentPeriods = ['before', 'between', 'after', 'on'];
export const treatmentPeriodUnits = [
  { label: <FormattedMessage id="general.day" />, value: 'day' },
  { label: <FormattedMessage id="general.week" />, value: 'week' },
  { label: <FormattedMessage id="general.calendar" />, value: 'date_range' },
];

export const treatmentProtocolFormName = 'treatment-protocol-form';
export const treatmentProtocolFormSelector = formValueSelector(treatmentProtocolFormName);
