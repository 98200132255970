import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ActivityAssets from '../ActivityAssets/ActivityAssets';
import AttentionBox from 'components/AttentionBox/AttentionBox';
// utils
import { isImageAsset, isVideoAsset, checkVariety, isPDFAsset } from 'utils';
import classnames from 'classnames/bind';
// styles
import styles from './AssetsSection.module.scss';

const cn = classnames.bind(styles);

const AssetsSection = ({ activity, onAssetPreview, suggestionsCount }) => {
  const { daily_checkup } = activity;
  const assets = (daily_checkup?.dc_assets || []).filter((asset) => isImageAsset(asset) ||
    isVideoAsset(asset) ||
    isPDFAsset(asset));
  const logs = daily_checkup?.dc_logs || [];

  const isAllDeleted = checkVariety(logs, 'assets_removed') && !assets.length;
  const renderSegment = isAllDeleted || !!assets.length;

  return (renderSegment && (
    <div className={cn('assets-section')}>
      {isAllDeleted && (
        <AttentionBox className={cn('media-attention')}>
          <FormattedMessage id="container.farmfeed.row.wereRemoved.media" />
        </AttentionBox>
      )}
      {!isAllDeleted && (
        <ActivityAssets
          assets={assets}
          activity={activity}
          suggestionsCount={suggestionsCount}
          onAssetPreview={(...args) => onAssetPreview(...args, 'activity')}
        />
      )}
    </div>
  ));
};

AssetsSection.propTypes = {
  onAssetPreview: T.func.isRequired,
  activity: T.object,
  suggestionsCount: T.number,
};

export default AssetsSection;
