/* eslint-disable */
import React, { Component } from 'react';
import T from 'prop-types';
// components
import { Document, Page } from 'react-pdf';
import Hammer from 'react-hammerjs';
import Preloader from 'components/Preloader';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import debounce from 'lodash.debounce';
// constants
import { MAX_PDF_SCALE, MIN_PDF_SCALE } from 'constants.js';
// styles
import './PdfPreview.scss';

const OVERLAP_PAGES = 1;

const hammerOptions = {
  touchAction: 'auto',
  recognizers: {
    pinch: { enable: true },
  }
};

class PdfPreview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pages: [],
      totalPages: 0,
      pdfFile: null,
      isLoading: false,
    };
    this.prevNumber = 0;
    this.prevScrollTop = 0;
    this.scrollDown = true;
    this.scale = 1;
    this.documentRef = React.createRef();
    this.documentPDFRef = null;
    this.originalSizes = {};
  }

  componentDidMount() {
    const { asset } = this.props;
    const assetUrl = asset?.url?.original || '';
    if (!assetUrl) return;
    this.setPdfData(assetUrl);
  }

  componentDidUpdate(prevProps) {
    const { asset } = this.props;
    const assetUrl = asset?.url?.original || '';
    if (!assetUrl) return;
    const prevAssetUrl = prevProps?.asset?.url?.original || '';
    if (prevAssetUrl !== assetUrl) {
      this.setPdfData(assetUrl);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { viewBoxHeight } = this.props;
    const { scale } = nextProps;

    if (scale !== this.scale && this.documentRef.current) { // handle scale for desktop
      this.stopScrolling = true;
      const { scrollHeight, clientHeight, scrollTop } = this.documentRef.current;
      this.pinchStartScrollHeight = scrollHeight - clientHeight;
      this.pinchScrollStart = scrollTop;
      const k = 1 / this.scale; // factor for calculation origin size

      const scaleRatio = scale / this.scale;
      this.scale = scale;
      const height = this.height * scale;
      this.newScale = scale;
      this.pagesInViewBox = Math.ceil(viewBoxHeight / height);

      const pages = this.documentRef.current.querySelectorAll('.shown-page');

      for (const item of pages) {
        const canvasDiv = item.querySelector('.react-pdf__Page__canvas');
        if (canvasDiv) {
          canvasDiv.style.height = parseFloat(canvasDiv.style.height) * scaleRatio + 'px';
          canvasDiv.style.width = parseFloat(canvasDiv.style.width) * scaleRatio + 'px';
        }
      }

      let paddingBottom;
      let paddingTop;
      if (this.documentPDFRef) {
        paddingBottom = parseInt(this.documentPDFRef.style.paddingBottom || '0');
        paddingTop = parseInt(this.documentPDFRef.style.paddingTop || '0');
      }
      if (paddingTop) {
        this.documentPDFRef.style.paddingTop = (paddingTop * k) + 'px';
      }
      if (paddingBottom) {
        this.documentPDFRef.style.paddingBottom = (paddingBottom * k) + 'px';
      }

      // set scroll on the same place before scaling
      this.documentRef.current.scrollTop = (scrollHeight - clientHeight)
        * (this.pinchScrollStart / this.pinchStartScrollHeight * 100) / 100;
      this.stopScrolling = false;
    }
  }

  componentWillUnmount() {
    this.documentRef.current?.removeEventListener('scroll', this.handleScroll); 
  } 

  setPdfData = (assetUrl) => {
    this.setState({ isLoading: true }); 
    fetch(assetUrl)  
      .then((data) => data.blob())
      .then((resp) => {
        this.setState({ pdfFile: resp, isLoading: false });
        this.documentRef.current?.addEventListener('scroll', this.handleScroll);
      }) 
      .catch((err) => { 
        this.setState({ isLoading: false });
        return toastResponseErrors(err); 
      });
  }

  handleScroll = () => {
    const { totalPages } = this.state;

    const proceed = () => {
      if (this.stopScrolling || !this.documentRef.current) return;
      const { scrollHeight, clientHeight, scrollTop } = this.documentRef.current;
      this.maxScroll = scrollHeight - clientHeight;
      if (this.maxScroll === 0) return;

      this.scrollDown = this.prevScrollTop < scrollTop;
      this.prevScrollTop = scrollTop;

      const scrollPerPos = scrollTop / this.maxScroll * 100;
      const number = Math.round(totalPages * scrollPerPos / 100);

      const shown = this.pagesInViewBox;

      if (this.prevNumber !== number) {
        const startPage = Math.max(number - (shown + OVERLAP_PAGES), 0);
        const endPage = Math.min(number + (shown + OVERLAP_PAGES), totalPages);
        const pagesCountToRender = endPage - startPage;

        if (this.scrollDown) {
          if (startPage) {
            const fakeHeight = this.height * startPage * this.scale;
            this.documentPDFRef.style.paddingTop = fakeHeight + 'px';
          }

          const fakeStartAfter = Math.min(number + (shown + OVERLAP_PAGES * 2), totalPages);
          const fakeCntAfter = totalPages - fakeStartAfter;

          this.documentPDFRef.style.paddingBottom = fakeCntAfter
            ? (totalPages - pagesCountToRender - startPage) * this.height * this.scale + 'px'
            : 0;
        } else {
          this.documentPDFRef.style.paddingTop = startPage ? this.height * startPage * this.scale + 'px' : 0;

          const fakeCntAfter = totalPages - endPage;
          if (fakeCntAfter) {
            const fakeHeight = this.height * fakeCntAfter * this.scale;
            this.documentPDFRef.style.paddingBottom = fakeHeight + 'px';
          }
        }

        if (pagesCountToRender) this.setState({ pages: this.renderPages(startPage, pagesCountToRender) });
        this.prevNumber = number;
      }
    };
    debounce(proceed, 200)();
  };

  handlePinchStart = () => {
    this.newScale = null;
    this.stopScrolling = true;
    const k = 1 / this.scale; // factor for calculation origin size

    const paddingBottom = parseFloat(this.documentPDFRef.style.paddingBottom);
    const paddingTop = parseFloat(this.documentPDFRef.style.paddingTop);
    if (paddingTop) {
      this.onPinchStartPaddingTop = paddingTop * k;
    }
    if (paddingBottom) {
      this.onPinchStartPaddingBottom = paddingBottom * k;
    }

    if (this.documentRef.current) {
      const { scrollHeight, clientHeight, scrollTop } = this.documentRef.current;
      this.pinchStartScrollHeight = scrollHeight - clientHeight;
      this.pinchScrollStart = scrollTop;
    }
  };

  handlePinch = (e) => {
    const { viewBoxHeight } = this.props;

    if (e.deltaX === 0 && e.deltaY === 0) return;

    let scale = this.scale + ((this.scale / 100) * ((e.scale - 1) * 100));

    if (scale > MAX_PDF_SCALE) scale = MAX_PDF_SCALE;
    if (scale < MIN_PDF_SCALE) scale = MIN_PDF_SCALE;

    const currentNumber = parseInt(e.target.parentElement.parentElement.dataset.index, 10);
    if (currentNumber < 0) return;

    const height = this.height * scale;
    const scaleRatio = scale / (this.newScale || this.scale);
    this.newScale = scale;
    this.pagesInViewBox = Math.ceil(viewBoxHeight / height);

    const pages = this.documentRef.current.querySelectorAll('.shown-page');

    for (const item of pages) {
      item.style.height = parseFloat(item.style.height) * scaleRatio + 'px';
      const canvasDiv = item.querySelector('.react-pdf__Page__canvas');
      if (canvasDiv) {
        canvasDiv.style.height = parseFloat(canvasDiv.style.height) * scaleRatio + 'px';
        canvasDiv.style.width = parseFloat(canvasDiv.style.width) * scaleRatio + 'px';
        const ctx = canvasDiv.getContext('2d');
        ctx.scale(scale, scale);
      }
    }

    this.documentPDFRef.style.paddingTop = (this.onPinchStartPaddingTop * scale) + 'px';
    this.documentPDFRef.style.paddingBottom = (this.onPinchStartPaddingBottom * scale) + 'px';

    // set scroll on the same place before scaling
    const { scrollHeight, clientHeight } = this.documentRef.current;
    this.documentRef.current.scrollTop = (scrollHeight - clientHeight)
      * (this.pinchScrollStart / this.pinchStartScrollHeight * 100) / 100;
  };

  handlePinchEnd = () => {
    if (this.newScale) this.scale = this.newScale;
    this.stopScrolling = false;
  };

  renderPages = (number, cnt) => {
    const { viewBoxWidth } = this.props;
    const width = Math.min(viewBoxWidth, 1024);

    const result = [];
    for (let i = number; i < number + cnt; i++) {
      result.push(
        <div key={`render-${i}`} className="shown-page" data-index={i} style={{ width }}>
          <Page
            width={width}
            pageNumber={i + 1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            renderInteractiveForms={false}
            scale={this.scale}
            loading={<Preloader isActive />}
            onLoadSuccess={this.onLoadPageSuccess}
            className="document-page"
          />
        </div>
      );
    }

    return result;
  };

  onLoadPageSuccess = (page) => {
    const { height, width, pageNumber } = page;
    if (pageNumber === 1 && !this.height || !this.width) {
      this.showAfterFakePage(height, width);
    }
  }

  showAfterFakePage = (height, width) => {
    const { viewBoxHeight } = this.props;
    const { totalPages } = this.state;
    this.height = height;
    this.width = width;

    this.pagesInViewBox = Math.ceil(viewBoxHeight / this.height);

    const pageCnt = this.pagesInViewBox + 2 > totalPages ? totalPages : this.pagesInViewBox + 2;
    const cntFakePages = totalPages - (this.pagesInViewBox + 2);
    if (cntFakePages > 0) {
      const paddingBottom = cntFakePages * this.height * this.scale;
      this.documentPDFRef.style.paddingBottom = paddingBottom + 'px';
    }

    this.setState({ pages: this.renderPages(0, pageCnt) });
  }

  onDocumentLoadSuccess(totalPages) {
    this.setState({ totalPages, pages: this.renderPages(0, 1) });
  }

  render() {
    const { pages, pdfFile, isLoading } = this.state;

    const errorScale = () => {
      this.stopScrolling = false;
      if (this.newScale) this.scale = this.newScale;
    };

    return (
      <div className="pdf-wrapper" ref={this.documentRef}>
        <Preloader isActive={isLoading} />
        <Hammer
          onPinchEnd={this.handlePinchEnd}
          onPinchStart={this.handlePinchStart}
          onPinchIn={this.handlePinch}
          onPinchOut={this.handlePinch}
          onPinchCancel={errorScale}
          options={hammerOptions}
        >
          <div>
            {!isLoading && pdfFile && (
              <Document
                inputRef={(ref) => this.documentPDFRef = ref}
                file={pdfFile}
                onLoadError={errorScale}
                onLoadSuccess={(pdf) => this.onDocumentLoadSuccess(pdf.numPages, pdf)}
                className="pdf-preview"
                renderMode="canvas"
                loading={<Preloader isActive />}
              >
                {pages}
              </Document>
            )}
          </div>
        </Hammer>
      </div>
    );
  }
}

PdfPreview.propTypes = {
  asset: T.object,
  viewBoxWidth: T.number,
  scale: T.number,
  viewBoxHeight: T.number,
};

export default PdfPreview;
