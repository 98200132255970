import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import {
  login,
  selectCurrentCompany,
  updateAuthUserData,
} from 'reducers/auth';
// components
import { FormattedMessage } from 'react-intl';
import LayoutMinimal from 'components/LayoutMinimal';
import LoginForm from 'components/Login/LoginForm';
import CompanySelector from 'components/Login/CompanySelector';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { setLSLanguage, setLSPWAModalSeen } from 'utils/localStorageHelper';
import isEmpty from 'lodash.isempty';
import cn from 'classnames';
import { authFrontendV2Url } from 'utils/urlBuilder';
// styles
import './Login.scss';

class Login extends Component {

  state = {
    formErrors: null,
    inputs: {
      email: { type: 'email', name: 'email', value: '', isValid: (val) => /^\S+@\S+$/.test(val) },
      password: { type: 'password', name: 'password', value: '', isValid: (val) => val.length > 0 },
    },
    validInputs: {
      email: null,
      password: null,
      remember_me: true,
    },
    selectCompanyStage: false,
    isLoading: false,
  };

  onLogin = async (data) => {
    const { login } = this.props;

    this.setState({
      isLoading: true,
    });

    try {
      const user = await login({ ...data, email: data.email.toLowerCase() });
      const companies = user?.root_companies || [];
      if (companies.length !== 1) {
        this.setState({ selectCompanyStage: true });
      } else {
        this.selectCurrentCompany(companies[0].id);
      }
      setLSLanguage(user?.language || 'en');
      setLSPWAModalSeen('');
      const redirectLocation = sessionStorage.getItem('redirect_location');
      if (redirectLocation) {
        this.context.router.replace(JSON.parse(redirectLocation));
        sessionStorage.removeItem('redirect_location');
      }
    } catch (response) {
      if (response.errors) {
        this.setState({
          formErrors: <FormattedMessage id="container.login.badCredentials" />,
        });
      }
    } finally {
      this.setState({
        isLoading: false
      });
    }
  };

  handleRedirectToScheduling = () => {
    // admin should be redirected to scheduling after login if scheduling tariff is enabled (according to requirements)
    const { user } = this.props;
    const isUserOnlyAdmin = user?.roles_map?.admin && !user?.roles_map?.super_admin;
    const isSchedulingTariffEnabled = user?.current_company?.only_logistics;
    if (isUserOnlyAdmin && isSchedulingTariffEnabled) {
      window.location.href = authFrontendV2Url('scheduling');
    }
  }

  selectCurrentCompany = (id) => {
    const { selectCurrentCompany, user, updateAuthUserData } = this.props;
    const { router } = this.context;

    if (user?.current_company?.id === id) {
      updateAuthUserData({ logged_in: new Date() }); // update user to trigger notification modals
      this.handleRedirectToScheduling();
      return router.push('/');
    }

    return selectCurrentCompany(id)
      .then(() => {
        this.handleRedirectToScheduling();
        router.push('/');
      })
      .catch(toastResponseErrors);
  };

  goBack = () => {
    this.setState({ selectCompanyStage: false });
  };

  validate = (data, value) => {
    const { validInputs } = this.state;
    const isValidData = data.isValid(value);
    this.setState({
      formErrors: null,
      validInputs: { ...validInputs, [data.name]: isValidData ? value : null },
    });
  };

  render() {
    const { user } = this.props;
    const { formErrors, inputs, validInputs, selectCompanyStage, isLoading } = this.state;
    const userCompanies = user?.root_companies || [];
    const showLoginForm = !selectCompanyStage;
    const allTenantsDisabled = isEmpty(userCompanies) && selectCompanyStage;

    return (
      <LayoutMinimal containerClass={cn('login LoginPage', { titleAbove: allTenantsDisabled })}>
        {showLoginForm
          ? <FormattedMessage id="container.login.logInToAccount" tagName="h1" />
          : <h1><FormattedMessage id="container.login.welcomeBack" />, {user.first_name}!</h1>}

        {!showLoginForm && (allTenantsDisabled
          ? (
            <div className="tenants-disabled-message">
              <p className="text-center">
                <div><i className="fa fa-exclamation-triangle-bts" /></div>
                <FormattedMessage id="container.login.noTenantAvailable" />
              </p>
              <p className="text-center">
                <FormattedMessage id="container.login.contactSupport" tagName="div" />
                <a className="support-link" href="mailto:support@everypig.com">support@everypig.com</a>
              </p>
            </div>
          )
          : <p className="text-center"><FormattedMessage id="container.login.selectAccount" /></p>
        )}

        {showLoginForm
          ? (
            <LoginForm
              errorMessage={formErrors}
              inputs={inputs}
              validInputs={validInputs}
              isError={formErrors !== null}
              onSubmit={this.onLogin}
              onAlertBoxClose={() => this.setState({ formErrors: null })}
              onInputsChange={this.validate}
              isLoading={isLoading}
            />
          )
          : (
            <CompanySelector
              companies={userCompanies}
              onSelect={this.selectCurrentCompany}
              goBack={this.goBack}
            />
          )}
      </LayoutMinimal>
    );
  }
}

Login.contextTypes = {
  router: T.object,
};

Login.propTypes = {
  login: T.func.isRequired,
  selectCurrentCompany: T.func.isRequired,
  updateAuthUserData: T.func.isRequired,
  user: T.object,
};

export default connect(
  (state) => ({
    user: state.auth.user,
  }), {
    login,
    selectCurrentCompany,
    updateAuthUserData,
  }
)(Login);
