import React, { useEffect, useRef, useState } from 'react';
import T from 'prop-types';
// components
import AddressSelect from '../../Farms/components/AddressSelect';
import { FormattedMessage } from 'react-intl';
import GoogleMapSelect from 'components/GoogleMap/GoogleMapSelect';
import ToggleSwitcher from 'components/ToggleSwitcher';
// utils
import cn from 'classnames';
import Geocode from 'utils/geocodeHelper';
import { getFullAddress } from 'utils';
import isEmpty from 'lodash.isempty';
// styles
import styles from './DefaultMapCenterSection.module.scss';

const Marker = () => <div className={cn('fa fa-map-marker-bts primary', styles['map-marker'])} />;

const defaultZoomLevel = 8;

const DefaultMapCenterSection = ({
  settings: {
    map_centralize,
    map_lat,
    map_lng,
    map_zoom,
  },
  onUpdate
}) => {
  const [addressData, setAddressData] = useState({});
  const mapRef = useRef(null);

  useEffect(() => {
    if (map_lat && map_lng) {
      Geocode.fromLatLng([map_lat, map_lng]).then((data) => setAddressData({
        ...data,
        country_code: data.countryKey || '',
        address: [data.strNumber, data.route].filter(Boolean).join(' ').trim() || '',
        state: data.region,
        lat: data?.latlng?.lat || '',
        lng: data?.latlng?.lng || '',
      }));
    }
  }, []);

  const onZoomChange = () => onUpdate({ map_zoom: mapRef?.current?.map_?.zoom || defaultZoomLevel });
  const onFeatureToggle = () => onUpdate({ map_centralize: !map_centralize });
  const onAddressChange = (data) => {
    onUpdate({ map_lat: data.lat, map_lng: data.lng });
    setAddressData(data);
  };
  const onSelectAddressChange = (_, data) => onAddressChange(data);

  const selected = !isEmpty(addressData) ? getFullAddress(addressData) : '';

  return (
    <section className="setting-section">
      <ToggleSwitcher
        name="map_centralize"
        className="small-12 medium-8 large-8"
        text={<FormattedMessage id="general.settings.defaultCenterAddress" />}
        description={<FormattedMessage id="general.settings.defaultCenterAddress.desc" />}
        checked={map_centralize}
        onChange={onFeatureToggle}
      >
        <div className={cn(styles['default-centering-section'], { [styles.disabled]: !map_centralize })}>
          <div className="mt-20">
            <label>
              <AddressSelect
                label={<FormattedMessage id="general.settings.defaultCenterAddress" />}
                hasPoweredByLabel
                countriesData={[]}
                selected={selected}
                changeValue={onSelectAddressChange}
                placeholder={<FormattedMessage id="general.typeAddress" />}
              />
            </label>
            <div className={styles['map-block']}>
              <GoogleMapSelect
                zoom={map_zoom}
                onChangeAdress={onAddressChange}
                hasLocationDefining={false}
                addressData={addressData}
                mapRef={mapRef}
                onZoomAnimationEnd={onZoomChange}
              >
                {(position) => <Marker {...position} />}
              </GoogleMapSelect>
            </div>
          </div>
        </div>
      </ToggleSwitcher>
    </section>
  );
};

DefaultMapCenterSection.propTypes = {
  settings: T.object.isRequired,
  onUpdate: T.func.isRequired,
};


export default DefaultMapCenterSection;
