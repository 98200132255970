import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './MobileDiseasesRow.module.scss';

const cn = classnames.bind(styles);

const MobileDiseasesRow = ({ name, actionBtnType, disabledRow, onRowClick, selectedCount }) => (
  <div
    className={cn('MobileRow', { 'no-events': disabledRow, 'select-row': actionBtnType === 'button' })}
    onClick={onRowClick}
  >
    <div className={cn({ 'selected-disease': disabledRow })}>
      {`${name}${actionBtnType === 'checkbox' && selectedCount > 0 ? ` (${selectedCount})` : ''}`}
    </div>
    {actionBtnType === 'button' && (
      <Button className={cn('select-btn')} small primary>
        <FormattedMessage id="general.button.select" />
      </Button>
    )}
    {actionBtnType === 'checkbox' && (
      <div className={cn('icon')}>
        <i className="fa fa-plus icon" />
      </div>
    )}
  </div>
);

MobileDiseasesRow.propTypes = {
  actionBtnType: T.string.isRequired,
  name: T.string.isRequired,
  disabledRow: T.bool.isRequired,
  onRowClick: T.func.isRequired,
  selectedCount: T.number.isRequired
};

export default MobileDiseasesRow;
