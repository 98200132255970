import { fetchFromAPI } from 'utils/api';

// farms
export const getTreatmentProtocolsFarms = (params) => {
  return fetchFromAPI('/treatment_protocols/farms', { params });
};

export const getTreatmentProtocolsFarm = (farmId) => {
  return fetchFromAPI(`/treatment_protocols/farms/${farmId}`).then((response) => response.resource);
};

export const getFarmTreatmentProtocols = (farmId, params) => {
  return fetchFromAPI(`/farms/${farmId}/treatment_protocols`, { params });
};

export const applyTreatmentProtocolFarmsFilter = (params) => {
  return fetchFromAPI('/treatment_protocols/farms/filter', { method: 'post', body: JSON.stringify(params) })
    .catch((errors) => {
      throw errors;
    });
};

export const getFarmTreatmentProtocol = (farmId, treatmentProtocolId) => {
  return fetchFromAPI(`/farms/${farmId}/treatment_protocols/${treatmentProtocolId}`)
    .then((response) => response.resource);
};

export const createFarmTreatmentProtocol = (farmId, resource) => {
  return fetchFromAPI(`/farms/${farmId}/treatment_protocols`, { method: 'POST', body: JSON.stringify({ resource }) });
};

export const updateFarmTreatmentProtocol = (farmId, treatmentProtocolId, resource, additionalData = {}) => {
  return fetchFromAPI(
    `/farms/${farmId}/treatment_protocols/${treatmentProtocolId}`,
    { method: 'PUT', body: JSON.stringify({ resource, ...additionalData }) }
  ).then((response) => response.resource);
};

export const deleteFarmTreatmentProtocol = (farmId, treatmentProtocolId) => {
  return fetchFromAPI(
    `/farms/${farmId}/treatment_protocols/${treatmentProtocolId}/delete`,
    { method: 'PUT' }
  );
};

export const deletePigGroupTreatmentProtocol = (farmId, treatmentProtocolId) => {
  return fetchFromAPI(
    `/pig_groups/${farmId}/treatment_protocols/${treatmentProtocolId}/delete`,
    { method: 'PUT' }
  );
};

// pig groups
export const getTreatmentProtocolsPigGroup = (groupId) => {
  return fetchFromAPI(`/treatment_protocols/pig_groups/${groupId}`).then((response) => response.resource);
};

export const getGroupExpiredProtocolTreatmentSteps = (pigGroupId, params) => {
  return fetchFromAPI(`/treatment_protocols/pig_groups/${pigGroupId}/treatment_protocol_product_steps`, { params });
};

export const getTreatmentProtocolsPigGroups = (params) => {
  return fetchFromAPI('/treatment_protocols/pig_groups', { params });
};

export const getTreatmentProtocolsFarmPigGroups = (farmId, params) => {
  return fetchFromAPI(`/treatment_protocols/farms/${farmId}/pig_groups`, { params })
    .then((response) => response.resources);
};

export const getTreatmentProtocolsFarmPigGroup = (farmId, pigGroupId) => {
  return fetchFromAPI(`/treatment_protocols/farms/${farmId}/pig_groups/${pigGroupId}`)
    .then((response) => response.resource);
};

export const getPigGroupTreatmentProtocol = (pigGroupId, treatmentProtocolId) => {
  return fetchFromAPI(`/pig_groups/${pigGroupId}/treatment_protocols/${treatmentProtocolId}`)
    .then((response) => response.resource);
};

export const getPigGroupTreatmentProtocols = (pigGroupId, params) => {
  return fetchFromAPI(`/pig_groups/${pigGroupId}/treatment_protocols`, { params });
};

export const createPigGroupTreatmentProtocol = (pigGroupId, resource) => {
  return fetchFromAPI(
    `/pig_groups/${pigGroupId}/treatment_protocols`,
    { method: 'POST', body: JSON.stringify({ resource }) }
  ).then((response) => response.resource);
};

export const updatePigGroupTreatmentProtocol = (pigGroupId, treatmentProtocolId, resource) => {
  return fetchFromAPI(
    `/pig_groups/${pigGroupId}/treatment_protocols/${treatmentProtocolId}`,
    { method: 'PUT', body: JSON.stringify({ resource }) }
  ).then((response) => response.resource);
};

// Barnsheets

export const getPigGroupTreatmentProtocolProductSteps = (pigGroupId, params) => {
  return fetchFromAPI(`/pig_groups/${pigGroupId}/treatment_protocol_product_steps`, { params });
};

// DailyCheckup

export const getGroupDCTreatmentProtocolProductSteps = (pigGroupId, params) => {
  // has no pagination
  return fetchFromAPI(`/daily_checkups/pig_groups/${pigGroupId}/treatment_protocol_product_steps`, { params });
};

export const getPigGroupDailyCheckupTreatmentProtocolProductStep = (pigGroupId, stepId) => {
  return fetchFromAPI(`/daily_checkups/pig_groups/${pigGroupId}/treatment_protocol_product_steps/${stepId}`)
    .then((response) => response.resource);
};

export const fetchFarmTreatmentProtocol = (protocolId) => {
  return fetchFromAPI(`/farm_treatment_protocols/${protocolId}`).then((response) => response.resource);
};

export const fetchPigGroupTreatmentProtocol = (protocolId) => {
  return fetchFromAPI(`/pig_group_treatment_protocols/${protocolId}`).then((response) => response.resource);
};

export const getTreatmentProtocols = (params) => {
  return fetchFromAPI('/company_treatment_protocols', { params });
};

export const fetchTreatmentProtcool = (protocolId) => {
  return fetchFromAPI(`/company_treatment_protocols/${protocolId}`).then((response) => response.resource);
};

export const createTreatmentProtocol = (resource, farmIds) => {
  return fetchFromAPI('/company_treatment_protocols', {
    method: 'POST',
    body: JSON.stringify({ resource, farm_ids: farmIds }),
  });
};

export const updateTreatmentProtocol = (id, resource, farmIds) => {
  return fetchFromAPI(`/company_treatment_protocols/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ resource, farm_ids: farmIds }),
  }).then((response) => response.resource);
};

export const deleteTreatmentProtocol = (id) => {
  return fetchFromAPI(`/company_treatment_protocols/${id}/delete`, { method: 'PUT' });
};

export const getTreatmentProtocol = (id) => {
  return fetchFromAPI(`/company_treatment_protocols/${id}`).then((response) => response.resource);
};
