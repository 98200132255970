export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const BEAMER_APP_ID = process.env.REACT_APP_BEAMER_APP_ID;

export const ESC_KEY = 27;
export const LEFT_KEY = 37;
export const RIGHT_KEY = 39;

export const tableNames = {
  adminGroupShipments: 'adminGroupShipments',
  adminGroupTransfers: 'adminGroupTransfers',
  adminGroupScheduledLoads: 'adminGroupScheduledLoads',
  allCommitments: 'allCommitments',
  autoReports: 'autoReports',
  barnSheetsCompanies: 'barnSheetsCompanies',
  barnSheetsFarms: 'barnSheetsFarms',
  barnSheetsFarmGroups: 'barnSheetsFarmGroups',
  barnSheetsFarmReports: 'barnSheetsFarmReports',
  barnSheetsGroups: 'barnSheetsGroups',
  barnSheetsGroupsTPProductSteps: 'barnSheetsGroupsTPProductSteps',
  barnSheetsGroupSalesResults: 'barnSheetsGroupSalesResults',
  checkupGroupTPProductSteps: 'checkupGroupTPProductSteps',
  farmTreatmentProtocols: 'farmTreatmentProtocols',
  pigGroupCommitments: 'pigGroupCommitments',
  pigGroupTreatmentProtocols: 'pigGroupTreatmentProtocols',
  pigGroupTreatmentProtocolsProductSteps: 'pigGroupTreatmentProtocolsProductSteps',
  startPigGroupCommitments: 'startPigGroupCommitments',
  reportFarms: 'reportFarms',
  truckingCompanies: 'truckingCompanies',
  treatmentProtocolFarms: 'treatmentProtocolFarms',
  treatmentProtocolPigGroups: 'treatmentProtocolPigGroups',
  virtualVisits: 'virtualVisits',
  reportTemplates: 'reportTemplates',
  adminReportTemplates: 'adminReportTemplates',
  farmReports: 'farmReports',
  farmDraftReports: 'farmDraftReports',
  treatmentProtocols: 'treatmentProtocols',
};
