import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import { connect } from 'react-redux';
import { fetchData, setDataItem } from 'reducers/dataTable';
import { openModal } from 'reducers/modals';
import { stopSubmit, setSubmitFailed } from 'redux-form';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import FarmTitleBox from 'components/FarmTitleBox/FarmTitleBox';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import DropdownButton from 'components/DropdownButton';
import Button from 'components/Button';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
// api
import { updateAdminFarm } from 'endpoints/admin/farms';
// utils
import isObject from 'lodash.isobject';
import cn from 'classnames';
import { isTablet } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';

const tableFilters = [
  { label: <FormattedMessage id="general.allFarms" />, value: '' },
  { label: <FormattedMessage id="general.status.active" />, value: 'active' },
  { label: <FormattedMessage id="general.status.disabled" />, value: 'disabled' },
  { label: <FormattedMessage id="general.status.hidden" />, value: 'hidden' },
];

class Farms extends Component {

  componentDidMount() {
    this.fetchFarms();
  }

  fetchFarms = () => {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  };

  updateFarm = (resource, id) => () => {
    const { setDataItem } = this.props;
    updateAdminFarm(id, resource)
      .then((updatedFarm) => {
        setDataItem(updatedFarm);
        let toastrType;
        let msgKey;
        let toastrIcon;
        if (resource.hidden) {
          toastrType = 'error';
          msgKey = 'hidden';
          toastrIcon = 'fa fa-ban';
        } else {
          toastrType = updatedFarm.active ? 'success' : 'error';
          msgKey = updatedFarm.active ? 'enabled' : 'disabled';
          toastrIcon = updatedFarm.active ? 'fa fa-thumbs-up' : 'fa fa-ban';
        }
        showToastrMessage(`component.toastr.farm.${msgKey}`, toastrType, toastrIcon);
      })
      .catch((response) => this.handleValidationErrors(response.errors, id));
  };

  confirmDisableFarm = (id) => () => {
    this.props.openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.button.disableFarm" />}
        actionBtnLabel={<FormattedMessage id="component.modal.disableFarm.confirm" />}
        actionBtnProps={{ red: true }}
        warningMessage={<FormattedMessage id="component.modal.disableFarm.warning" />}
        handleConfirm={this.updateFarm({ active: false }, id)}
      />
    );
  };

  confirmEnableFarm = (id) => () => {
    this.props.openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.button.enableFarm" />}
        actionBtnLabel={<FormattedMessage id="component.modal.enableFarm.confirm" />}
        actionBtnProps={{ blue: true }}
        warningMessage={<FormattedMessage id="component.modal.enableFarm.warning" />}
        handleConfirm={this.updateFarm({ active: true, hidden: false }, id)}
      />
    );
  };

  handleValidationErrors = (errors, id) => {
    const { router } = this.context;
    const { stopSubmit, setSubmitFailed } = this.props;

    if (isObject(errors)) {
      router.push(`/admin/farms/${id}/user-roles`);
      // todo: refactor this without timeout hack
      setTimeout(() => {
        stopSubmit('edit-farm', errors);
        setSubmitFailed.apply(null, ['edit-farm', ...Object.keys(errors)]);
      }, 300);
    } else {
      toastResponseErrors({ errors });
    }
  };

  renderFarmNameColumn = ({ name, farm_type }) => (
    <CustomColumn>
      <FarmTitleBox name={name} type={farm_type} />
    </CustomColumn>
  );

  renderLocationColumn = ({ city, state }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.location" />}>
      {`${city}, ${state}`}
    </CustomColumn>
  );

  renderFarmIdColumn = ({ external_link_id }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.farmId" />}>
      {external_link_id || 'N/A'}
    </CustomColumn>
  );

  renderUserRolesColumn = ({ user_roles_count }) => (
    <CustomColumn label={<FormattedMessage id="general.userRoles" />}>
      {user_roles_count || 0}
    </CustomColumn>
  );

  renderStatusColumn = ({ active }) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.farmStatus" />}>
      <StatusBadge status={active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  renderActionsColumn = ({ id, active, hidden }, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${id}-${rowIndex}`}
        wide
        label={<FormattedMessage id="general.button.edit" />}
        buttonType="small light-gray"
        url={`/admin/farms/${id}/user-roles`}
        dropDownData={[
          { label: <FormattedMessage id="general.button.enableFarm" />, onClick: this.confirmEnableFarm(id),
            hide: active },
          { label: <FormattedMessage id="general.button.disableFarm" />, onClick: this.confirmDisableFarm(id),
            hide: !active },
          { label: <FormattedMessage id="general.button.hideFarm" />, onClick: this.updateFarm({ hidden: true }, id),
            hide: active || hidden },
        ]}
        customClass="show-for-large"
      />
      <Link to={`/admin/farms/${id}/user-roles`} className="button light hide-for-large">
        <FormattedMessage id="general.editFarm" />
      </Link>
      {!active && (
        <Button lightGray onClick={this.confirmEnableFarm(id)} className="hide-for-large">
          <FormattedMessage id="general.button.enableFarm" />
        </Button>
      )}
      {active && (
        <Button lightGray onClick={this.confirmDisableFarm(id)} className="hide-for-large">
          <FormattedMessage id="general.button.disableFarm" />
        </Button>
      )}
    </div>
  );

  renderExpandable = ({ id, active }) => (
    <div>
      <Link to={`/admin/farms/${id}/user-roles`} className="button small light">
        <i className="fa fa-pencil mr-5" />
        <FormattedMessage id="general.editFarm" />
      </Link>
      {!active && (
        <Button small light onClick={this.confirmEnableFarm(id)}>
          <i className="fa fa-check-circle mr-5" />
          <FormattedMessage id="general.button.enableFarm" />
        </Button>
      )}
      {active && (
        <Button small light onClick={this.confirmDisableFarm(id)}>
          <i className="fa fa-times-circle mr-5" />
          <FormattedMessage id="general.button.disableFarm" />
        </Button>
      )}
    </div>
  );

  render() {
    const { farms, isLoading, reqParams: { page, per_page, status, search, sort }, meta: { stats, total },
      onSearchChange, onPageChange, onPerPageChange, onSortChange, onStatusChange } = this.props;

    const isEmptyTable = !farms.length && !status && !search;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '2 1 160px',
        renderer: this.renderFarmNameColumn, sortKey: 'name' },
      { label: <FormattedMessage id="component.dataTable.headers.location" />, flex: '1 1 100px',
        renderer: this.renderLocationColumn, sortKey: 'city' },
      { label: <FormattedMessage id="component.dataTable.headers.farmId" />, flex: '1 1 100px',
        renderer: this.renderFarmIdColumn, sortKey: 'external_link_id' },
      { label: <FormattedMessage id="general.userRoles" />, flex: '1 1 140px', renderer: this.renderUserRolesColumn,
        sortKey: 'user_roles_count' },
      { label: <FormattedMessage id="component.dataTable.headers.farmStatus" />, flex: '1 1 120px',
        renderer: this.renderStatusColumn },
      { label: '', flex: '0 0 120px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <section className="small-12 column">
        <Panel>
          <Panel.Heading title={<FormattedMessage id="general.farms" />}>
            {!isEmptyTable &&
              <SearchBox initialValue={search} onChange={onSearchChange} />}
          </Panel.Heading>

          <Panel.Body noPadding>
            {!isEmptyTable && (
              <TableFilter
                filters={tableFilters}
                activeFilter={status}
                onFilterChange={onStatusChange}
                stats={stats}
                className="ph-10"
              >
                <Link
                  className="importTableBtn"
                  to="/csv-import/select-mode?from=farms"
                >
                  <FormattedMessage id="general.import" />
                </Link>
                <Link to="/admin/farms/create" className="button small primary wider">
                  <FormattedMessage id="general.button.createFarm" />
                </Link>
              </TableFilter>
            )}

            <Preloader isActive={isLoading} />

            <DataTable
              data={farms}
              columns={columns}
              sort={sort}
              onSortChange={onSortChange}
              paginationProps={paginationProps}
              isExpandable={isTablet}
              renderExpandable={this.renderExpandable}
              scrollable
              tableKey="adminFarms"
            />

            <NothingBox
              itemsName="farms"
              display={!farms.length}
              isLoading={isLoading}
              search={search}
              filter={status}
            >
              <FormattedMessage tagName="h1" id="component.nothingBox.anyFarmsYet" />
              <FormattedMessage tagName="p" id="component.nothingBox.createFarm" />
              <Link className="importBtnEmpty" to="/csv-import/select-mode?from=farms">
                <FormattedMessage id="general.import" />
              </Link>
              <Link to="/admin/farms/create" className="button primary mt-10">
                <FormattedMessage id="general.button.createFarm" />
              </Link>
            </NothingBox>
          </Panel.Body>
        </Panel>
      </section>
    );
  }
}

Farms.contextTypes = {
  router: T.object.isRequired,
};

Farms.propTypes = {
  farms: T.array,
  isLoading: T.bool.isRequired,
  reqParams: T.object.isRequired,
  meta: T.object,
  fetchData: T.func.isRequired,
  setDataItem: T.func.isRequired,
  openModal: T.func.isRequired,
  stopSubmit: T.func.isRequired,
  setSubmitFailed: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onStatusChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      farms: state.dataTable.adminFarms.resources,
      meta: state.dataTable.adminFarms.meta,
      isLoading: state.dataTable.adminFarms.isLoading,
      reqParams: state.dataTable.adminFarms.params,
    }), {
      fetchData: (query) => fetchData('/admin/farms', 'adminFarms', query),
      setDataItem: (data) => setDataItem(data, 'adminFarms'),
      openModal,
      stopSubmit,
      setSubmitFailed,
    }
  ),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(Farms);
