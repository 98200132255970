import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// components
import AnalyticsPanel from 'components/Analytics/AnalyticsPanel';
import AnalyticsPanelStats from 'components/Analytics/AnalyticsPanelStats';
import GrowthRate from 'components/GrowthRate';
// api
import { getAnalyticsDeaths } from 'endpoints/admin/analytics';
// utils
import { formatNumber } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import isNull from 'lodash.isnull';
// constants
import { deathTypes } from 'constants.js';

function calcDeathsData(data) {
  const { chronic_death_count_was, acute_death_count_was, euthanasia_death_count_was } = data;
  const all_death_count = data.chronic_death_count + data.acute_death_count + data.euthanasia_death_count;
  const all_death_count_was = chronic_death_count_was + acute_death_count_was + euthanasia_death_count_was;
  const calcDeathsCountChange = () => {
    if (!(all_death_count_was + all_death_count)) return 0;
    if (!all_death_count_was) return 100;
    return ((all_death_count - all_death_count_was) / all_death_count_was) * 100;
  };
  return { ...data, all_death_count, all_death_count_change: calcDeathsCountChange() };
}

const TotalDeathsPanel = ({
  companyId,
}) => {
  const [state, setState] = useState({
    isLoading: false,
    rangeType: '7_days',
    chronic_death_count: 0,
    acute_death_count: 0,
    euthanasia_death_count: 0,
    chronic_death_count_change: 0,
    acute_death_count_change: 0,
    euthanasia_death_count_change: 0,
    all_death_count: 0,
    all_death_count_change: 0,
  });
  const showGrowthRate = state.rangeType !== 'today';
  const filteredDeathTypes = deathTypes.filter(({ key }) => key !== 'treated');
  const stats = filteredDeathTypes.map(({ label, key }) => ({
    label,
    value: formatNumber(state[`${key}_death_count`]),
    growthRate: showGrowthRate && {
      value: state[`${key}_death_count_change`],
      isColorsInverted: true,
    },
  }));

  const getDeathsStats = (type, clickedCompanyId) => {
    const company_id = (isNull(clickedCompanyId) || clickedCompanyId) ? clickedCompanyId : companyId;
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    getAnalyticsDeaths({ type, company_id })
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          ...calcDeathsData(response.resource),
          isLoading: false,
          rangeType: type,
        }));
      })
      .catch((response) => {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        toastResponseErrors(response);
      });
  };

  useEffect(() => {
    getDeathsStats(state.rangeType, companyId);
  }, [companyId]);

  return (
    <AnalyticsPanel titleKey="deaths" panelData={state} getRangeData={getDeathsStats}>
      <div className="total-block">
        <div className="total-number-block">
          <div className="total-number">{formatNumber(state.all_death_count)}</div>
          {showGrowthRate && (
            <GrowthRate className="percent" value={state.all_death_count_change} transparent isColorsInverted />
          )}
        </div>
      </div>
      <div className="stats-block">
        <AnalyticsPanelStats stats={stats} />
      </div>
    </AnalyticsPanel>
  );
};

TotalDeathsPanel.propTypes = {
  companyId: T.number,
};

export default TotalDeathsPanel;
