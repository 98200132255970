import React from 'react';
import T from 'prop-types';
// components
import ReactTooltip from 'rc-tooltip';
// utils
import cn from 'classnames';
// styles
import './InfoTooltip.scss';

const InfoTooltip = ({ className, overlay }, { isMobile }) => (
  <div className={cn('info-tooltip', { [className]: !!className })}>
    <ReactTooltip
      mouseEnterDelay={0.2}
      placement={isMobile ? 'topRight' : 'top'}
      overlayClassName="tooltip-message"
      overlay={overlay}
    >
      <i className="fa fa-info-circle tooltip-icon" />
    </ReactTooltip>
  </div>
);

InfoTooltip.contextTypes = {
  isMobile: T.bool.isRequired,
};

InfoTooltip.propTypes = {
  overlay: T.node.isRequired,
  className: T.string,
};

export default InfoTooltip;
