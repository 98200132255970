import React from 'react';
// utils
import { formatUnviewedAssetsCount } from 'utils';
import { authFrontendV2Url } from 'utils/urlBuilder';
import { isUserHasAnyRole, userRoleKeys, isUserExactlyCaregiver } from 'utils/rolesHelper';
import { isSchedulingTariffUser } from 'utils/userHelper';
// components
import { FormattedMessage } from 'react-intl';
import { ReactComponent as DeviceIcon } from 'assets/svg/video-call-btb.svg';

const svrRolesList = [
  userRoleKeys.super_admin,
  userRoleKeys.admin,
  userRoleKeys.tenant_owner,
  userRoleKeys.caretaker,
  userRoleKeys.farm_owner,
  userRoleKeys.manager,
  userRoleKeys.vet,
];

const isLogisticsHidden = (user) => {
  const isEverypigBasic = user.current_company.everypig_basic;
  const isAdmin = user.roles_map.admin;
  const isTenantUseLogistics = user.current_company.logistics;

  if (isEverypigBasic) return false;
  if (isTenantUseLogistics && isAdmin) return false;

  return true;
};

const getSidebarItems = (user, location, sidebarInfo, newActivitiesCount, router) => {
  const isAdmin = user.roles_map.admin;
  const isEverypigBasic = user.current_company.everypig_basic;
  const showInventoryTab = user.current_company.inventory_used;
  const isTenantUseLogistics = user.current_company.logistics;
  const isTenantUseVirtualVisits = user.current_company.virtual_visits;
  const isTenantUseTreatmentProtocols =
    user.current_company.admin_treatment_protocols && user.current_company.treatment_protocols;
  const hasTruckingRoles = user.has_trucking_roles;
  const isSuperAdmin = user.roles_map.super_admin;
  const { groups, inventories } = sidebarInfo.needAttention;
  const { unviewedActivities, unviewedAssets, flaggedActivities } = sidebarInfo.counters;
  const unviewedAssetsCount = formatUnviewedAssetsCount(unviewedAssets);

  // checking current sidebar item
  const pathFirstCut = location.pathname.split('/')[1];
  const isInsightsActive = ['asset-map', 'analytics', 'treatments', 'auto-report', 'checkins'].includes(pathFirstCut);
  const isGroupsActive = ['groups', 'sources', 'destinations'].includes(pathFirstCut);
  const isBarnSheetsActive = pathFirstCut === 'barnsheets';
  const isVirtualVisitsActive = pathFirstCut === 'virtual-visits';
  const isSchedulingAccessOnly = isSchedulingTariffUser(user);
  const isExtendedAnalyticsAccess = user.analytics_extended_access;
  const isTreatmentProtocolsActive = pathFirstCut === 'treatment-protocols';

  // svr
  const isTenantUseSVR = user.current_company.admin_svrs;
  const isUserHasSVRLimitedAccess = user.svrs_limited_access;
  const isUserCanViewSVR = isUserHasAnyRole(user, svrRolesList);
  const isUserAbleToUseSVR = (isEverypigBasic || isTenantUseSVR) && !isUserHasSVRLimitedAccess;

  const farmFeedItem = {
    link: '/farmfeed',
    icon: 'fa fa-newspaper-o',
    name: <FormattedMessage id="component.sidebar.farmFeed" />,
    counter: unviewedActivities + newActivitiesCount,
    hide: isSchedulingAccessOnly,
  };
  const superAdminItem = {
    link: '/super-admin',
    icon: 'fa fa-key',
    name: <FormattedMessage id="component.sidebar.superAdmin" />,
    hide: !isSuperAdmin,
  };

  return {
    primary: [
      farmFeedItem,
      {
        link: '/farmfeed/flagged',
        icon: 'fa fa-bookmark',
        name: <FormattedMessage id="component.sidebar.flaggedActivities" />,
        counter: flaggedActivities,
        counterTheme: 'yellow',
        hide: isSchedulingAccessOnly,
      },
      {
        link: '/my-schedule',
        icon: 'fa fa-calendar',
        name: <FormattedMessage id="component.sidebar.mySchedule" />,
        hide: !(isEverypigBasic || isTenantUseLogistics),
      },
      {
        link: '/daily-checkup',
        icon: 'fa fa-edit',
        name: <FormattedMessage id="component.sidebar.dailyCheckup" />,
        hide: isSchedulingAccessOnly,
      },
      {
        link: '/treatment-protocols-groups',
        icon: 'fa fa-injectable',
        name: <FormattedMessage id="general.treatmentProtocols" />,
        showTooltip: true,
        isActive: isTreatmentProtocolsActive,
        hide: !isTenantUseTreatmentProtocols || isUserExactlyCaregiver(user),
      },
      {
        link: '/virtual-visits',
        iconComponent: <DeviceIcon width={24} />,
        name: <FormattedMessage id="general.pageTitle.virtualVisits" />,
        isActive: isVirtualVisitsActive,
        hide: !isTenantUseVirtualVisits,
      },
      {
        link: '/report-farms',
        icon: 'fa fa-site-visit-report',
        name: <FormattedMessage id="general.reports" />,
        hide: !isUserAbleToUseSVR || !isUserCanViewSVR || isSchedulingAccessOnly,
      },
    ],

    secondary: [
      {
        link: isAdmin || isExtendedAnalyticsAccess ? '/analytics' : '/auto-report',
        icon: 'fa fa-bar-chart',
        name: <FormattedMessage id="component.sidebar.insights" />,
        isActive: isInsightsActive || isSchedulingAccessOnly,
        hide: isSchedulingAccessOnly,
      }, {
        link: '/barnsheets',
        icon: 'fa fa-ulist',
        name: <FormattedMessage id="component.sidebar.barnSheets" />,
        isActive: isBarnSheetsActive,
      },
    ],

    more: [
      {
        link: '/media-gallery',
        icon: 'fa fa-photo',
        name: <FormattedMessage id="component.sidebar.mediaGallery" />,
        hide: isSchedulingAccessOnly,
      }, {
        link: '/inventory',
        icon: 'fa fa-ep-piggy',
        name: <FormattedMessage id="component.sidebar.myInventory" />,
        hide: isAdmin || !showInventoryTab || isSchedulingAccessOnly,
      }, {
        link: '/admin/inventory',
        icon: 'fa fa-ep-piggy',
        name: <FormattedMessage id="component.sidebar.inventory" />,
        hide: !isAdmin || !showInventoryTab || isSchedulingAccessOnly,
        action_required: inventories,
      }, {
        link: '/tenant-assets',
        icon: 'fa fa-folder',
        name: <FormattedMessage id="general.resources" />,
        counter: unviewedAssetsCount,
        hide: isSchedulingAccessOnly,
      }, {
        link: '/groups',
        icon: 'fa fa-ep-groups',
        name: <FormattedMessage id="component.sidebar.groups" />,
        hide: !isAdmin,
        action_required: groups,
        isActive: isGroupsActive,
      }, {
        link: null,
        onClick: () => {
          // If Basic Plan is enabled we need to redirect to somewhere where 'permissions' handler will work
          if (isEverypigBasic) router.push('/my-schedule');
          else window.location.href = authFrontendV2Url('scheduling');
        },
        icon: 'fa fa-ep-logistics',
        name: <FormattedMessage id="component.sidebar.logistics" />,
        hide: isLogisticsHidden(user),
      }, {
        link: null,
        onClick: () => {
          // If Basic Plan is enabled we need to redirect to somewhere where 'permissions' handler will work
          if (isEverypigBasic) router.push('/my-schedule');
          else window.location.href = authFrontendV2Url('trucking');
        },
        icon: 'fa fa-ep-truck',
        name: <FormattedMessage id="component.sidebar.trucking" />,
        hide: !(isEverypigBasic || isTenantUseLogistics || (hasTruckingRoles && isAdmin)),
      }, {
        link: '/admin',
        icon: 'fa fa-gear',
        name: <FormattedMessage id="general.role.admin" />,
        hide: !isAdmin,
      },
      superAdminItem,
    ],

    expired: [farmFeedItem, superAdminItem],
  };
};

export default getSidebarItems;
