import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './Panel.module.scss';

const cn = classnames.bind(styles);

const Heading = ({ className, title, renderTitle, children }) => (
  <div className={cn('panel-heading', className)}>
    {title && <h1>{title}</h1>}
    {typeof renderTitle === 'function' && renderTitle()}
    {children}
  </div>
);

const Body = ({ className, noPadding, children }) => (
  <div className={cn('panel-body', { 'no-padding': noPadding }, className)}>
    {children}
  </div>
);

const Control = ({ className, children }) => (
  <div className={cn('panel-control', className)}>
    {children}
  </div>
);

const Footer = ({ className, children }) => (
  <div className={className}>
    {children}
  </div>
);

const Panel = ({ className, children }) => (
  <div className={cn('panel', className)}>
    {children}
  </div>
);

const propTypes = {
  className: T.string,
  children: T.node,
};

Heading.propTypes = {
  ...propTypes,
  title: T.oneOfType([T.string, T.node]),
  renderTitle: T.func,
};

Body.propTypes = {
  ...propTypes,
  noPadding: T.bool,
};

Footer.propTypes = propTypes;

Panel.propTypes = propTypes;

Control.propTypes = propTypes;

Object.assign(Panel, { Heading, Body, Footer, Control });

export default Panel;
