import React, { useEffect, useMemo, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { updateUserSetting, fetchUserSetting } from 'reducers/auth';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import PreferenceCollapse from './components/PreferenceCollapse';
import PreferenceItem from './components/PreferenceItem';
import CollapsesBox from 'components/CollapsesBox/CollapsesBox';
import ToggleSwitcher from 'components/ToggleSwitcher';
import Preloader from 'components/Preloader';
import CircleCheckbox from 'components/CircleCheckbox/CircleCheckbox';
import BrowserSection from './components/BrowserSection';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { isMobile } from 'react-device-detect';
import { isSchedulingTariffUser } from 'utils/userHelper';
// helper
import {
  notifiedAboutSettings, emailSettings, smsSettings, pushSettings, smsSettingsKeys, messagingSettingsKeys,
  emailSettingsKeys, pushSettingsKeys, findSubtitleTitleKey
} from './components/helper';
// styles
import './Messaging.scss';
// assets
import everyPigImage from '../../../public/static/icons/icon_32x32.png';

const Messaging = ({
  user,
  fetchUserSetting,
  isLoaded,
  userSetting = {},
  isLogisticsEnabled,
  updateUserSetting,
  isLoading,
  isSVREnabled,
  isTreatmentProtocolsEnabled,
  isSchedulingTariffUser,
}) => {
  const [activeTab, setActiveTab] = useState(null);
  const subtitleKeys = useMemo(() => ({
    messagingTabSounds: (userSetting.sounds_effects ? 'soundsOn' : 'soundsOff'),
    messagingTab: findSubtitleTitleKey(userSetting, messagingSettingsKeys),
    emailTab: findSubtitleTitleKey(userSetting, emailSettingsKeys),
    deviceTab: findSubtitleTitleKey(userSetting, pushSettingsKeys),
    smsTab: findSubtitleTitleKey(userSetting, smsSettingsKeys),
  }), [userSetting]);

  useEffect(() => {
    if (!isLoaded) fetchUserSetting(user.id).catch(toastResponseErrors);
  }, []);

  const onCollapseToggle = (currentTab) => {
    setActiveTab((prevActiveTab) => (currentTab !== prevActiveTab ? currentTab : null));
  };

  const renderMessagingTabSubtitleKey = () =>  (
    <span>
      <FormattedMessage id={`component.messaging.${subtitleKeys.messagingTab}`} />
      {', '}
      <FormattedMessage id={`component.messaging.${subtitleKeys.messagingTabSounds}`} />
    </span>
  );

  const renderSmsTabSubtitleKey = () => (user.phone ?
    `component.messaging.${subtitleKeys.smsTab}` :
    'container.myProfile.settings.smsActivation.subtitle'
  );

  const handleRadioEmailChanged = (newValue) => {
    const newEmailState = {};
    emailSettingsKeys.forEach((valueKey) => { newEmailState[valueKey] = newValue; });
    updateUserSetting(newEmailState, user.id).catch(toastResponseErrors);
  };

  const checkEmailPreferences = () => {
    const checkedItems = emailSettingsKeys.filter((item) => userSetting[item]);
    return checkedItems.length > 0;
  };

  const handleChange = (valueKey) => {
    updateUserSetting({ [valueKey]: !userSetting[valueKey] }, user.id).catch(toastResponseErrors);
  };

  const isNotificationAvailable = (type) => {
    if (isSchedulingTariffUser) {
      return type === 'logistics';
    }

    return (type ?
      { logistics: isLogisticsEnabled, svr: isSVREnabled, treatmentProtocols: isTreatmentProtocolsEnabled }[type] :
      true
    );
  };

  return (
    <CollapsesBox className="profile-messaging">
      <PreferenceCollapse
        isOpened={activeTab === 'messaging'}
        onToggle={() => onCollapseToggle('messaging')}
        icon={<img alt="Every Pig" src={everyPigImage} role="presentation" />}
        title={<FormattedMessage id="container.myProfile.messagingTab" />}
        subtitle={renderMessagingTabSubtitleKey()}
      >
        <Preloader isActive={(activeTab === 'messaging') && isLoading} />
        <PreferenceItem title={<FormattedMessage id="container.myProfile.settings.sounds" tagName="strong" />}>
          <ToggleSwitcher
            checked={userSetting.sounds_effects}
            onChange={() => handleChange('sounds_effects')}
            text={<FormattedMessage id="container.myProfile.settings.soundsEffects" />}
            description={<FormattedMessage id="container.myProfile.settings.soundsEffects.desc" />}
          />
        </PreferenceItem>
        <PreferenceItem title={<FormattedMessage id="container.myProfile.settings.notifiedAbout" tagName="strong" />}>
          {notifiedAboutSettings.map(({ valueKey, textKey, type }, index) => isNotificationAvailable(type) && (
          <ToggleSwitcher
            key={index}
            className="mv-20"
            checked={userSetting[valueKey]}
            onChange={() => handleChange(valueKey)}
            text={<FormattedMessage id={`container.myProfile.settings.notification.${textKey}`} />}
            description={<FormattedMessage id={`container.myProfile.settings.notification.${textKey}.desc`} />}
          />
          ))}
        </PreferenceItem>
      </PreferenceCollapse>

      <PreferenceCollapse
        isOpened={activeTab === 'email'}
        onToggle={() => onCollapseToggle('email')}
        icon={<i className="fa fa-envelope" />}
        title={<FormattedMessage id="general.email" />}
        subtitle={<FormattedMessage id={`component.messaging.${subtitleKeys.emailTab}`} />}
      >
        <Preloader isActive={(activeTab === 'email') && isLoading} />
        <PreferenceItem
          title={<FormattedMessage id="container.myProfile.settings.email.myEmailPreference" tagName="strong" />}
        >
          <div className="email-preference">
            <CircleCheckbox
              className="email-manage mb-15"
              type="radio"
              label={<FormattedMessage id="container.myProfile.settings.email.CheckAllNotifications" />}
              name="email-preference-yes"
              onChange={() => handleRadioEmailChanged(true)}
              checked={checkEmailPreferences()}
            />
            <CircleCheckbox
              className="email-manage"
              type="radio"
              label={<FormattedMessage id="container.myProfile.settings.email.CheckImportantNotifications" />}
              name="email-preference-no"
              onChange={() => handleRadioEmailChanged(false)}
              checked={!checkEmailPreferences()}
            />
          </div>
        </PreferenceItem>
        <PreferenceItem
          title={<FormattedMessage id="container.myProfile.settings.email.allNotifications" tagName="strong" />}
        >
          {emailSettings.map(({ valueKey, textKey, type }, index) => isNotificationAvailable(type) && (
          <ToggleSwitcher
            key={index}
            className="mv-20"
            checked={userSetting[valueKey]}
            onChange={() => handleChange(valueKey)}
            text={<FormattedMessage id={`container.myProfile.settings.email.${textKey}`} />}
            description={<FormattedMessage id={`container.myProfile.settings.email.${textKey}.desc`} />}
          />
          ))}
        </PreferenceItem>
      </PreferenceCollapse>

      <PreferenceCollapse
        isOpened={activeTab === 'device'}
        onToggle={() => onCollapseToggle('device')}
        icon={<i className="fa fa-desktop" />}
        title={<FormattedMessage id="container.myProfile.deviceTab" />}
        subtitle={<FormattedMessage id={`component.messaging.${subtitleKeys.deviceTab}`} />}
      >
        <Preloader isActive={(activeTab === 'device') && isLoading} />
        {!isMobile && (
          <PreferenceItem
            title={<FormattedMessage id="container.myProfile.settings.desktop.title" tagName="strong" />}
          >
            <FormattedMessage id="container.myProfile.settings.desktop.desc" />
            <BrowserSection />
          </PreferenceItem>
        )}
        <PreferenceItem title={<FormattedMessage id="container.myProfile.settings.mobile.title" tagName="strong" />}>
          <FormattedMessage id="container.myProfile.settings.mobile.desc" />
        </PreferenceItem>
        <PreferenceItem title={<FormattedMessage id="container.myProfile.settings.push.all" tagName="strong" />}>
          {pushSettings.map(({ valueKey, textKey, type }, index) => isNotificationAvailable(type) && (
            <ToggleSwitcher
              key={index}
              className="mv-20"
              checked={userSetting[valueKey]}
              onChange={() => handleChange(valueKey)}
              text={<FormattedMessage id={`container.myProfile.settings.notification.${textKey}`} />}
              description={<FormattedMessage id={`container.myProfile.settings.notification.${textKey}.desc`} />}
            />
          ))}
        </PreferenceItem>
      </PreferenceCollapse>

      <PreferenceCollapse
        isOpened={activeTab === 'sms'}
        onToggle={() => onCollapseToggle('sms')}
        icon={<i className="fa fa-cellphone" />}
        title={<FormattedMessage id="container.myProfile.smsTab" />}
        subtitle={<FormattedMessage id={renderSmsTabSubtitleKey()} />}
      >
        <Preloader isActive={(activeTab === 'sms') && isLoading} />
        <PreferenceItem
          title={(
            <FormattedMessage
              id={`container.myProfile.settings.sms${user?.phone ? 'Notifications' : 'Activation'}`}
              tagName="strong"
            />
          )}
        >
          {!user?.phone && (
            <FormattedMessage
              id="container.myProfile.settings.smsActivation.text"
              values={{
                linkedText: (
                  <FormattedMessage id="general.cellphoneNumber">
                    {(text) => (<Link to="/profile/edit#phone" className="link">{text}</Link>)}
                  </FormattedMessage>
                ),
              }}
            />
          )}
          {user?.phone && smsSettings.map(({ valueKey, textKey, type }, index) => isNotificationAvailable(type) && (
            <ToggleSwitcher
              key={index}
              className="mv-20"
              checked={userSetting[valueKey]}
              onChange={() => handleChange(valueKey)}
              text={<FormattedMessage id={`container.myProfile.settings.notification.${textKey}`} />}
              description={<FormattedMessage id={`container.myProfile.settings.notification.${textKey}.desc`} />}
            />
          ))}
        </PreferenceItem>
      </PreferenceCollapse>
    </CollapsesBox>
  );
};

Messaging.propTypes = {
  updateUserSetting: T.func.isRequired,
  fetchUserSetting: T.func.isRequired,
  user: T.object.isRequired,
  userSetting: T.object.isRequired,
  isLoading: T.bool.isRequired,
  isLoaded: T.bool.isRequired,
  isLogisticsEnabled: T.bool.isRequired,
  isSVREnabled: T.bool.isRequired,
  isTreatmentProtocolsEnabled: T.bool.isRequired,
  isSchedulingTariffUser: T.bool.isRequired,
};

export default connect(
  (state) => ({
    user: state.auth.user,
    isLogisticsEnabled: state.auth.user.current_company.logistics,
    userSetting: state.auth.user_setting,
    isLoading: state.auth.isSettingLoading,
    isLoaded: state.auth.isSettingLoaded,
    isSVREnabled: state.auth.user.current_company.admin_svrs && !state.auth.user.svrs_limited_access,
    isSchedulingTariffUser: isSchedulingTariffUser(state.auth.user),
    isTreatmentProtocolsEnabled: state.auth.user.current_company.treatment_protocols &&
      state.auth.user.current_company.admin_treatment_protocols,
  }),
  { updateUserSetting, fetchUserSetting }
)(Messaging);
