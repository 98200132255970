import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openModal, closeModal } from 'reducers/modals';
// components
import { FormattedMessage } from 'react-intl';
import FilterSectionSwitcher from './FilterSectionSwitcher';
import SelectionSection from './Sections/SelectionSection';
import SaveFilterModal from './SaveFilterModal';
import UserFilterSectionDropdown from './UserFilterSectionDropdown';
import FilterDropdownMenu from './FilterDropdownMenu';
import ChooseSegmentDropdown from './ChooseSegmentDropdown';
// utils
import classnames from 'classnames/bind';
import { formatBackEndWeightToSystem, formatWeightToBackEndFormat } from 'utils/weightHelper';
import isEqual from 'lodash.isequal';
import intersection from 'lodash.intersection';
// filters helper
import {
  getTargetData,
  getNecessarySections,
  isInvalidSectionData,
  formatFilterSectionOptions,
} from 'utils/userFiltersHelper';
// filters constants
import { canAddedOnceSections } from 'utils/constants/userFilters';
// styles
import styles from './UserFilter.module.scss';

const cn = classnames.bind(styles);

class UserFilter extends Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialFilter(props.initialFilter);
  }

  componentDidUpdate(prevProps, prevState) {
    const { onSectionsChange, measurementSystem } = this.props;

    if (onSectionsChange && this.isSectionsChanged(prevState)) {
      const { sections, match_filters, id, name } = this.state;
      const validSections = sections.filter(({ isInvalid, _destroy }) => !isInvalid && !_destroy);
      onSectionsChange({
        id,
        name,
        sections: validSections.map(({ isInvalid, isInitialOpened, ...section }) => ({
          ...section,
          options: formatFilterSectionOptions(section, (value) => formatWeightToBackEndFormat(value, measurementSystem))
        })),
        any_condition: match_filters === 'any'
      });
    }

    if (this.props.initialFilter !== prevProps.initialFilter) {
      this.setInitialFilter(this.props.initialFilter);
    }
  }

  isSectionsChanged = (prevState) => {
    const { sections: prevSections, match_filters: prevCondition } = prevState;
    const { sections, match_filters: condition } = this.state;
    const isSectionsChanged = !isEqual(
      prevSections.filter(({ isInvalid }) => !isInvalid),
      sections.filter(({ isInvalid }) => !isInvalid)
    );
    const isMatchingTypeChanged = prevCondition !== condition;
    return isSectionsChanged || isMatchingTypeChanged;
  }

  formatOptionValue = (value, isSaving) => {
    const { measurementSystem } = this.props;
    return isSaving
      ? formatWeightToBackEndFormat(value, measurementSystem)
      : formatBackEndWeightToSystem(value, measurementSystem);
  };

  getInitialFilter = (filter = { any_condition: false, sections: [] }) => ({
    id: filter.id,
    name: filter.name,
    match_filters: filter.any_condition ? 'any' : 'all',
    sections: filter.sections.map((section, index) => ({
      key: index + 1,
      ...section,
      ...getTargetData(section),
      options: formatFilterSectionOptions(section, this.formatOptionValue),
    })),
  });

  setInitialFilter = (filter) => {
    this.setState(this.getInitialFilter(filter));
  };

  onFilterSave = (name, isUpdating) => {
    const { onSave } = this.props;
    const { sections, match_filters, id } = this.state;
    return onSave({
      name,
      sections: getNecessarySections(sections, !!id, !isUpdating, this.formatOptionValue),
      any_condition: match_filters === 'any',
    }, isUpdating).then((filter) => {
      // in the ff filters state was taken from currentFilter
      if (filter) this.setInitialFilter(filter);
    });
  };

  onSectionSelect = (type) => {
    const { sections } = this.state;
    const deletedSection = sections.find((item) => ((item.type === type) && item._destroy));
    if (deletedSection) {
      const index = sections.indexOf(deletedSection);
      const { _destroy, ...data } = sections[index];
      const section = { ...data, condition: '', isInitialOpened: true, isInvalid: true };
      this.setState({
        sections: [
          ...sections.slice(0, index),
          ...sections.slice(index + 1),
          section,
        ],
      });
      return;
    }
    this.setState((prevState) => ({
      sections: [
        ...prevState.sections,
        {
          type,
          condition: '',
          isInitialOpened: true,
          isInvalid: true,
          key: (prevState.sections[prevState.sections.length - 1]?.key || 0) + 1,
        },
      ],
    }));
  };

  onMatchFiltersChange = (type) => {
    this.setState({ match_filters: type });
  };

  onOptionSelect = (item) => (condition, options, other) => {
    const { sections } = this.state;
    const index = sections.indexOf(item);
    const updatedSection = {
      ...sections[index],
      condition,
      options,
      isInitialOpened: false,
      ...other,
    };
    const isInvalid = isInvalidSectionData(updatedSection, condition, options);
    this.setState({
      sections: [
        ...sections.slice(0, index),
        { ...updatedSection, isInvalid },
        ...sections.slice(index + 1),
      ],
    });
  };

  onSectionRemove = (item) => () => {
    const { sections } = this.state;
    const index = sections.indexOf(item);
    if (item.id) {
      this.setState({
        sections: [
          ...sections.slice(0, index),
          { ...sections[index], _destroy: true },
          ...sections.slice(index + 1),
        ],
      });
      return;
    }
    this.setState({
      sections: [
        ...sections.slice(0, index),
        ...sections.slice(index + 1),
      ],
    });
  };

  onOpenSaveModal = () => {
    const { openModal, closeModal } = this.props;
    const { sections, name } = this.state;
    openModal(
      <SaveFilterModal
        initialName={name}
        sections={sections.filter(({ _destroy }) => !_destroy)}
        onClose={closeModal}
        onSave={this.onFilterSave}
      />
    );
  };

  renderAddFilterBtn = () => (
    <>
      <i className={cn('fa fa-plus', 'icon-btn')} />
      <FormattedMessage id="general.addFilter" />
    </>
  );

  renderDropdownMenu = (isOpened) => {
    const { sections } = this.state;
    const { availFilterSections } = this.props;
    const addedSectionTypes = sections.filter(({ _destroy }) => !_destroy).map(({ type }) => type);
    const disabled = intersection(canAddedOnceSections, addedSectionTypes);
    return (
      <FilterDropdownMenu
        isOpened={isOpened}
        currentValue=""
        options={availFilterSections}
        onOptionClick={this.onSectionSelect}
        disabledValues={disabled}
        hasSearch
      />
    );
  };

  render() {
    const { filterGroupName, isVisible, listOptions, children, className, onSave, openModal } = this.props;
    const { sections, match_filters, id } = this.state;
    const selectedSections = sections.filter(({ _destroy }) => !_destroy);

    return (
      <div className={cn('user-filter', { visible: isVisible, [className]: !!className })}>
        <SelectionSection
          condition={match_filters}
          listOptions={listOptions}
          icon="fa fa-filter"
          onOptionSelect={this.onMatchFiltersChange}
          isRemovable={false}
        />
        {selectedSections.map((item) => (
          <FilterSectionSwitcher
            key={item.key}
            uniqueId={item.key}
            {...item}
            onOptionSelect={this.onOptionSelect(item)}
            onSectionRemove={this.onSectionRemove(item)}
          />
        ))}
        <UserFilterSectionDropdown
          className={cn('filter-btn')}
          triggerRenderer={this.renderAddFilterBtn}
          menuRenderer={this.renderDropdownMenu}
        />
        {!!onSave && !!selectedSections.length && !selectedSections.some(({ isInvalid }) => isInvalid) && (
          <div className={cn('filter-btn')} onClick={this.onOpenSaveModal}>
            <i className={cn('fa fa-filter', 'icon-btn')} />
            <FormattedMessage id="general.button.saveSegment" />
          </div>
        )}
        {children}
        <ChooseSegmentDropdown
          filterGroupName={filterGroupName}
          onFilterChoose={this.setInitialFilter}
          currentFilterId={id}
          openModal={openModal}
        />
      </div>
    );
  }
}

UserFilter.propTypes = {
  availFilterSections: T.array.isRequired,
  isVisible: T.bool.isRequired,
  onSave: T.func.isRequired,
  initialFilter: T.object,
  openModal: T.func.isRequired,
  children: T.oneOfType([T.arrayOf(T.node), T.node]),
  closeModal: T.func.isRequired,
  measurementSystem: T.string.isRequired,
  listOptions: T.array.isRequired,
  filterGroupName: T.string.isRequired,
  className: T.string,
};

UserFilter.contextTypes = {
  router: T.object.isRequired,
};

export default connect(
  (state) => ({
    measurementSystem: state.auth.user.current_company.measurement_system,
  }), { openModal, closeModal }
)(UserFilter);
