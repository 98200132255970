import React from 'react';
import T from 'prop-types';
// redux, connect
import { connect } from 'react-redux';
import { setActivityFlag } from 'reducers/farmfeed';
// components
import EncouragementActivity from './EncouragementActivity';
import BirthdayCongratsActivity from './BirthdayCongratsActivity';
import CheckinActivity from './CheckinActivity';
import AISuggestionActivity from './AISuggestionActivity';
import SharedActivity from './SharedActivity';
import DirectPostActivity from './DirectPostActivity';
import LowMortalityActivity from './LowMortalityActivity';
import WithdrawalWarningActivity from './WithdrawalWarningActivity';
import ExtendedCheckupActivity from './ExtendedCheckupActivity';
import WaterUsageWarning from './WaterUsageWarning';
import ReportIssueActivity from './ReportIssueActivity';
import OutOfComplianceGroupsActivity from './OutOfComplianceGroupsActivity';
import GeneralWaterUsageWarning from './GeneralWaterUsageWarning';
import GeneralWithdrawalWarning from './GeneralWithdrawalWarning';
// hooks
import { useDropdownActions } from 'hooks/useDropdownContext';

function getActivityType(variety) {
  if (!variety) return '';
  if (variety.includes('media') || variety.includes('health_issue') || variety.includes('efficacy')) {
    return 'extended_dc';
  }
  if (variety.length === 1) return variety[0];
  return '';
}

const ActivitySwitcher = ({ isChildActivity, ...props }) => {
  const { openDropdown } = useDropdownActions();
  const { variety = [] } = props.activity;

  if (!variety || !variety.length) return null;

  const activityType = getActivityType(variety);

  switch (activityType) {
    case 'diagnosis_encouragement':
    case 'media_encouragement': return <EncouragementActivity {...props} />;
    case 'birthday_congrats': return <BirthdayCongratsActivity {...props} />;
    case 'checked_in':
      return <CheckinActivity {...props} isChildActivity={isChildActivity} openDropdown={openDropdown} />;
    case 'ai_suggestion':
      return <AISuggestionActivity {...props} isChildActivity={isChildActivity} openDropdown={openDropdown} />;
    case 'shared': return <SharedActivity {...props} openDropdown={openDropdown} />;
    case 'direct': return <DirectPostActivity {...props} openDropdown={openDropdown} />;
    case 'low_mortality': return <LowMortalityActivity {...props} />;
    case 'withdrawal_warning':
      return <WithdrawalWarningActivity {...props} isChildActivity={isChildActivity} openDropdown={openDropdown} />;
    case 'extended_dc':
      return <ExtendedCheckupActivity {...props} isChildActivity={isChildActivity} openDropdown={openDropdown} />;
    case 'water_usage_warning':
      return <WaterUsageWarning {...props} isChildActivity={isChildActivity} openDropdown={openDropdown} />;
    case 'general_water_usage_warning':
      return <GeneralWaterUsageWarning {...props} isChildActivity={isChildActivity} openDropdown={openDropdown} />;
    case 'general_withdrawal_warning':
      return <GeneralWithdrawalWarning {...props} isChildActivity={isChildActivity} openDropdown={openDropdown} />;
    case 'svr_issue':
      return <ReportIssueActivity {...props} isChildActivity={isChildActivity} openDropdown={openDropdown} />;
    case 'out_of_compliance_groups':
      return <OutOfComplianceGroupsActivity {...props} isChildActivity={isChildActivity} openDropdown={openDropdown} />;
    default: {
      // eslint-disable-next-line no-console
      console.warn('Invalid JSON for Activity:', JSON.stringify(props.activity)); // eslint-disable-line;
      return null;
    }
  }
};

ActivitySwitcher.defaultProps = {
  isChildActivity: false,
  className: '',
};

ActivitySwitcher.propTypes = {
  activity: T.object.isRequired,
  currentUser: T.object.isRequired,
  setActivityFlag: T.func.isRequired,
  markAsSeen: T.func,
  isChildActivity: T.bool,
  className: T.string,
};

export default connect(null, { setActivityFlag })(ActivitySwitcher);
