import { createSelector } from 'reselect';

export const diagnoseFormInitialValuesSelector = createSelector(
  (state, props) => state.dailyCheckup.checkupDiagnoses.checkups.find(({ checkupId }) => checkupId === props.checkupId),
  (state) => state.dailyCheckup.checkupDiagnoses.predictedTags, // without filter cause of refetch on every asset
  (state, props) => props.diagnosisId,
  (checkup, predictedTags, diagnosisId) => {
    if (!checkup) return undefined;
    if (!checkup.diagnosesList.length && !predictedTags.length) return {
      diagnoses: [{ diagnose_type: 'clinical', note: null, tags: [], notify_all: false }],
    };

    const diagnoses = [
      ...predictedTags.map((item) => ({
        suggested: true,
        suggested_diagnosis_ids: item.diagnosis_ids,
        label: item.label,
        ai_suggestion_percent: item.ai_suggestion_percent,
        diagnose_type: 'clinical',
        note: null,
        tags: [{
          ...item,
          id: null,
          prediction_image_tag_id: item.id,
        }],
        notify_all: false,
      })),
      ...checkup.diagnosesList.map((item) => ({
        diagnose: {
          id: item.diagnosis_id,
          name: item.diagnosis.name,
          label: item.diagnosis.common_name || item.diagnosis.name,
          short_name: item.diagnosis.short_name,
        },
        diagnose_type: item.diagnosis_type,
        note: item.note,
        tags: item.image_tags || [],
        notify_all: item.notify_all,
        id: item.id,
      })),
    ];

    return {
      diagnoses: diagnosisId ? diagnoses.filter(({ id }) => id === diagnosisId) : diagnoses,
    };
  },
);

export const checkupDiagnosesCountSelector = createSelector(
  (state, props) => state.dailyCheckup.checkupDiagnoses.checkups.find(({ checkupId }) => checkupId === props.checkupId),
  (currentCheckup) => {
    if (!currentCheckup) return 0;
    return currentCheckup.diagnosesList.length;
  }
);
