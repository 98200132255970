import React from 'react';
import { FormattedMessage } from 'react-intl';

export const diagnosesTypes = {
  clinical: <FormattedMessage id="general.diagnosesTypes.clinical" />,
  lab_confirmed: <FormattedMessage id="general.diagnosesTypes.labConfirmed" />,
  presumed: <FormattedMessage id="general.diagnosesTypes.presumed" />,
  gross_only: <FormattedMessage id="general.diagnosesTypes.grossOnly" />,
};

export const diagnosesTypeOptions = Object.keys(diagnosesTypes)
  .map((type) => ({ value: type, label: diagnosesTypes[type] }));

export function optionsMapper(list, ids = []) {
  const preffered = [];
  const others = [];
  list.forEach((item) => {
    if (ids.includes(item.id)) {
      preffered.push({ ...item, suggested: true });
      return;
    }
    others.push({ ...item });
  });
  return preffered.concat(others);
}
