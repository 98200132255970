import React from 'react';
import T from 'prop-types';
// components
import ActivityMentions from 'components/Activities/ActivityMentions';
import AsyncSelect from 'components/AsyncSelect/AsyncSelect';
import Avatar from 'components/Avatar/Avatar';
import { FormattedMessage } from 'react-intl';
// styles
import './FarmFeedGroupSelect.scss';

const ValueComponent = (props) => {
  const { value, onRemove, children } = props;

  return (
    <div className="Select-value" title={value.title}>
      <span className="Select-value-label">
        {children}&nbsp;
        <span className="remove-icon" onClick={() => onRemove(value)}>
          <i className="fa fa-times" />
        </span>
      </span>
    </div>
  );
};

const FarmFeedGroupSelect = ({ onChange, selectedGroups }) => {
  let groupInput = null;
  const optionRenderer = (option) => (
    <div className="group-suggestion-item">
      <Avatar type="pig_group" avatarSize={40} iconSize={16} />
      <div className="semibold">{option.label}</div>
    </div>
  );

  return (
    <div className="FarmFeedGroupSelect">
      <div className="groups-line">
        <ActivityMentions groups={selectedGroups.map(({ item }) => item)} />
      </div>
      <div className="groups-search">
        <div onClick={() => groupInput.focus()} className="select-badge">
          <FormattedMessage id="general.groups" />
        </div>
        <AsyncSelect
          arrowRenderer={null}
          optionRenderer={optionRenderer}
          multi
          selected={selectedGroups}
          placeholder={<FormattedMessage id="general.searchBy.groups" />}
          noInputMargin
          className="group-select"
          optionsPath="/pig_groups/search?exclude_status=closed"
          optionsKeys={{ value: 'id', label: 'name' }}
          onChange={onChange}
          valueComponent={ValueComponent}
          getRef={(input) => { groupInput = input; }}
          openOnFocus
        />
      </div>
    </div>
  );
};

ValueComponent.propTypes = {
  value: T.object,
  onRemove: T.func,
  children: T.any,
};

FarmFeedGroupSelect.propTypes = {
  onChange: T.func,
  selectedGroups: T.array.isRequired,
};

export default FarmFeedGroupSelect;
