import React, { Fragment, useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import TreatmentProtocolForm from './components/TreatmentProtocolForm';
// endpoints
import { createTreatmentProtocol } from 'endpoints/treatmentProtocols';
// utils
import { connect } from 'react-redux';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatTreatmentProtocolPayload, validateTreatmentProtocol } from './helper';

const initialTreatment = { product: '', steps: [{}], };
const defaultInitialValues = {
  treatments: [initialTreatment],
  applied_farms: []
};

const CreateTreatmentProtocol = ({
  setBreadcrumbs,
  setPageOptions,
  router: { push },
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPageOptions({ backLink: '/treatment-protocols' });
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.globalProtocols" />,
        path: '/treatment-protocols',
      },
      {
        label: <FormattedMessage id="general.createTreatmentProtocol" />,
        useLabelAsMobileTitle: true,
      },
    ]);
  }, []);

  const onSubmit = async (newProtocolData) => {
    try {
      setIsLoading(true);
      const farmIds = newProtocolData.applied_farms?.map(({ id }) => id);
      const treatmentProtocolPayload = formatTreatmentProtocolPayload(newProtocolData);
      await createTreatmentProtocol(treatmentProtocolPayload, farmIds);
      push('/treatment-protocols');
    } catch (error) {
      toastResponseErrors(error);
      setIsLoading(false);
    }
  };

  const validate = (treatmentProtocolForm) => {
    const isTreatmentProtocolValid = validateTreatmentProtocol(treatmentProtocolForm);
    const isFarmsApplied = !!treatmentProtocolForm?.applied_farms?.length;

    return isTreatmentProtocolValid && isFarmsApplied;
  };

  return (
    <Fragment>
      <Preloader isActive={isLoading} />
      <TreatmentProtocolForm
        validate={validate}
        isCreate
        isGlobal
        onSubmit={onSubmit}
        initialValues={defaultInitialValues}
        title={<FormattedMessage id="general.createTreatmentProtocol" />}
        submitLabel={<FormattedMessage id="general.createTreatmentProtocol" />}
      />
    </Fragment>
  );
};

CreateTreatmentProtocol.propTypes = {
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  router: T.object.isRequired,
};

export default connect(null, {
  setPageOptions,
  setBreadcrumbs,
})(CreateTreatmentProtocol);
