import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './SubmitStepper.module.scss';

const cn = classnames.bind(styles);

const SubmitStepper = ({ className }) => (
  <div className={cn('submit-stepper', className)}>
    <div key="svr" className={cn('step')}>
      <div className={cn('circle-wrapper', 'filled')}>
        <div className={cn('step-circle', 'filled')}>
          <i className={cn('fa fa-check')} />
        </div>
        <div className={cn('name')}>
          <FormattedMessage id="general.report" />
        </div>
      </div>
    </div>
    <div key="review" className={cn('step')}>
      <div className={cn('circle-wrapper')}>
        <div className={cn('step-circle', 'touched')}>
          <div className={cn('name')}>
            <FormattedMessage id="general.reviewAndSubmit" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

SubmitStepper.propTypes = {
  className: T.string,
};

export default SubmitStepper;
