import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// components
import Preloader from 'components/Preloader';
// utils
import classnames from 'classnames/bind';
import { convertPdfToDataUrl } from './helper';
// styles
import styles from './PdfAssetPreview.module.scss';

const cn = classnames.bind(styles);

const PdfAssetPreview = ({ asset, onClick }) => {
  const [pdfDataUrl, setPdfDataUrl] = useState(null);
  const [isConverting, setIsConverting] = useState(false);

  const getImage = async () => {
    setIsConverting(true);
    const pdfDataUrl = await convertPdfToDataUrl(asset?.url?.original);
    setPdfDataUrl(pdfDataUrl);
    setIsConverting(false);
  };

  useEffect(() => {
    getImage();
  }, []);

  return (
    <div onClick={onClick} className={cn('pdf-preview')}>
      {pdfDataUrl && !isConverting && (
        <div className={cn('pdf-image')} style={{ backgroundImage: `url(${pdfDataUrl})` }} />
      )}
      {isConverting && <Preloader />}
      {!isConverting && !pdfDataUrl && <i className={cn('fa fa-page-pdf', 'pdf-asset-icon')} />}
    </div>
  );
};

PdfAssetPreview.propTypes = {
  onClick: T.func,
  asset: T.object.isRequired,
};


export default PdfAssetPreview;
