import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import { Field, FieldArray } from 'redux-form';
import ReactSelect from 'components/Select/ReactSelect';
import CircleCheckbox from 'components/CircleCheckbox';
import CommentBox from 'components/CommentBox';
import TagFields from './TagFields';
import SuggestedDiagnosesCollapse from './SuggestedDiagnosesCollapse';
import PigGroupDiagnosesManager from 'components/PigGroupDiagnosesManager';
// utils
import classnames from 'classnames/bind';
import { optionsMapper, diagnosesTypeOptions } from 'utils/diagnosesHelper';
// assets
import magicLoopSvg from '../../../public/images/magic.svg';
// styles
import styles from './DiagnosisFields.module.scss';

const cn = classnames.bind(styles);
const remapLabels = (diagnosisList) => diagnosisList.map((diagnosis) => ({
  ...diagnosis,
  label: diagnosis.common_name || diagnosis.name,
}));

const renderNotes = ({ input }) => (
  <CommentBox
    {...input}
    onValueChange={input.onChange}
    value={input.value || ''}
    isOpen={!!input.value}
  />
);

renderNotes.propTypes = {
  input: T.shape({
    value: T.any,
    onChange: T.func,
  })
};

const DiagnosisFields = ({
  fields,
  maxFieldsCount,
  changeDiagnosis,
  removeDiagnosis,
  saveSuggestedDiagnosis,
  declinePredictedTag,
  setHoverTag,
  diagnosesList,
  addTag,
  hasTags,
  hasEmptyDiagnosisWithTag,
  editMode,
  isAssetAnImage,
  pigGroupId,
}) => (
  <>
    {fields.map((field, index) => {
      const currentDiagnosis = fields.get(index);
      const suggestedDiagnosisIds = currentDiagnosis.suggested_diagnosis_ids;
      const mapped_options = optionsMapper(diagnosesList, suggestedDiagnosisIds, fields.getAll());
      return (
        <div
          key={index}
          className={cn('diagnosis-field', {
            'first-suggested': currentDiagnosis.suggested && !index,
            'mb-15': currentDiagnosis.suggested && (!fields.get(index + 1) || !fields.get(index + 1).suggested),
          })}
        >
          {!currentDiagnosis.suggested && (
            <>
              <div className={cn('label')}><FormattedMessage id="general.diagnosis" /></div>
              <div className={cn('search-select')}>
                <Field
                  name={`${field}.diagnose`}
                  component={({ input }) => (
                    <ReactSelect
                      noInputMargin
                      value={input.value}
                      options={remapLabels(mapped_options)}
                      labelKey="label"
                      valueKey="id"
                      onChange={changeDiagnosis(input, fields, index)}
                      placeholder={<FormattedMessage id="component.diagnoseForm.chooseDiagnosis" />}
                      optionRenderer={({ label, suggested }) => (
                        <div>
                          {suggested && <img src={magicLoopSvg} alt="" className={cn('magic-icon')} />}
                          {label}
                        </div>
                      )}
                    />
                  )}
                />
                {(fields.get(0).diagnose || fields.length > 1) && (
                  <div className={cn('remove-icon-block')}>
                    <i onClick={() => removeDiagnosis(fields, index)} className={cn('fa fa-trash-o', 'remove-icon')} />
                  </div>
                )}
              </div>
              <div className={cn('diagnosis-type')}>
                <div className={cn('type-label')}>
                  <FormattedMessage id="component.diagnoseForm.diagnosisType" />
                </div>
                <Field
                  name={`${field}.diagnose_type`}
                  component={({ input }) => (
                    <ReactSelect
                      {...input}
                      onChange={({ value }) => input.onChange(value)}
                      noInputMargin
                      value={input.value}
                      options={diagnosesTypeOptions}
                      labelKey="label"
                      valueKey="value"
                      placeholder={<FormattedMessage id="component.diagnoseForm.chooseDiagnosisType" />}
                    />
                  )}
                />
              </div>
              {hasTags && (
                <FieldArray
                  name={`${field}.tags`}
                  component={TagFields}
                  currentDiagnosis={currentDiagnosis}
                  declinePredictedTag={declinePredictedTag}
                  hasEmptyDiagnosisWithTag={hasEmptyDiagnosisWithTag}
                  setHover={setHoverTag}
                  addTag={addTag}
                  isAssetAnImage={isAssetAnImage}
                />
              )}
              <div className={cn('notify-users')}>
                <Field
                  name={`${field}.notify_all`}
                  value={currentDiagnosis.notify_all}
                  parse={(value) => Boolean(value)}
                  component={({ input }) => (
                    <CircleCheckbox
                      label={<FormattedMessage id="component.diagnoseForm.sendAlert" />}
                      checked={Boolean(input.value)}
                      {...input}
                    />
                  )}
                />
              </div>
            </>
          )}
          <PigGroupDiagnosesManager
            currentDiagnosis={currentDiagnosis}
            pigGroupId={pigGroupId}
          />
          {currentDiagnosis.suggested && (
            <>
              <hr className={cn('suggested-divider', 'start')} />
              <SuggestedDiagnosesCollapse
                label={currentDiagnosis.label}
                suggestedDiagnosisIds={suggestedDiagnosisIds}
                percent={currentDiagnosis.ai_suggestion_percent}
                diagnosesList={diagnosesList}
                onSelect={(e, diagnosis_id) => saveSuggestedDiagnosis(e, fields, index, diagnosis_id)}
                onReject={(e) => {
                  e.preventDefault();
                  fields.remove(index);
                  declinePredictedTag(currentDiagnosis.tags[0].prediction_image_tag_id);
                }}
                handleMouseEnter={() => setHoverTag(currentDiagnosis.tags[0].prediction_image_tag_id
                  || currentDiagnosis.tags[0].hash)}
                handleMouseLeave={() => setHoverTag(null)}
              />
              <hr className={cn('suggested-divider', 'end')} />
            </>
          )}
          {!currentDiagnosis.suggested && (
            <>
              <div className={cn('note-field')}>
                <Field
                  name={`${field}.note`}
                  component={renderNotes}
                  type="text"
                />
              </div>
              <div className={cn('divider')}>
                <hr />
              </div>
            </>
          )}
        </div>
      );
    })}
    {(fields.length < maxFieldsCount) && !hasEmptyDiagnosisWithTag && !editMode && (
      <div className={cn('add-diagnosis')}>
        <FormattedMessage id="component.diagnoseForm.addAnother">
          {(text) => (
            <span onClick={() => fields.push({ diagnose_type: 'clinical', note: null, notify_all: false })}>
              {text}
            </span>
          )}
        </FormattedMessage>
      </div>
    )}
  </>
);

DiagnosisFields.propTypes = {
  fields: T.object.isRequired,
  maxFieldsCount: T.number.isRequired,
  changeDiagnosis: T.func.isRequired,
  removeDiagnosis: T.func.isRequired,
  saveSuggestedDiagnosis: T.func.isRequired,
  setHoverTag: T.func.isRequired,
  addTag: T.func.isRequired,
  hasEmptyDiagnosisWithTag: T.bool.isRequired,
  declinePredictedTag: T.func.isRequired,
  diagnosesList: T.array.isRequired,
  isAssetAnImage: T.bool.isRequired,
  hasTags: T.bool,
  editMode: T.bool,
  pigGroupId: T.number,
  input: T.shape({
    onChange: T.func,
    value: T.string
  })
};

export default DiagnosisFields;
