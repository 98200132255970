import React, { Fragment, useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import SVRQuestionStatus from 'components/SVRQuestionStatus';
import SVRCommentView from 'components/SVRComment/SVRCommentView';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './ReportActivityQuestionLine.module.scss';

const cn = classnames.bind(styles);

const ReportActivityQuestionLine = ({ question, preShowCommentsCount = 1 }) => {
  const [isShowAllComments, setIsShowAllComments] = useState(false);
  const { answer: { comments, value }, label } = question;
  const commentsCount = comments.length;
  const commentsToRender = isShowAllComments ? comments : comments.slice(0, preShowCommentsCount);
  return (
    <Fragment>
      <div className={cn('question-line', { failed: value === 'fail' })}>
        <div className="semibold">{label}</div>
        <SVRQuestionStatus question={question} />
      </div>
      {commentsCount > 0 && (
        <div className={cn('comments')}>
          {commentsToRender.map((comment, idx) => (
            <SVRCommentView key={idx} comment={comment} />
          ))}
          {commentsCount > 1 && (
            <div className={cn('show-more-comments')}>
              <span className={cn('toggle-comments')} onClick={() => setIsShowAllComments((prevState) => !prevState)}>
                <FormattedMessage
                  id={`component.comments.${isShowAllComments ? 'hide' : 'show'}Comments`}
                />
              </span>
              <FormattedMessage
                id="component.pagination.pageOfTotal"
                values={{ page: commentsToRender.length, total: commentsCount }}
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

ReportActivityQuestionLine.propTypes = {
  question: T.object.isRequired,
  preShowCommentsCount: T.number,
};

export default ReportActivityQuestionLine;
