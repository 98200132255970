/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import T from 'prop-types';
// recompose
import { compose } from 'recompose';
import withCorrection from 'containers/DailyCheckup/utils/withCorrectionHOC';
// redux
import { connect } from 'react-redux';
import { openViewer } from 'reducers/mediaViewer';
import { updateCheckupDiagnoses } from 'reducers/dailyCheckup/checkupDiagnoses';
import { openModal, TYPE_PANEL } from 'reducers/modals';
import { openPortal } from 'reducers/portal';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import DeadPigsBlock from 'components/DailyCheckups/DeadPigsBlock';
import VideoPreviewOverlay from 'components/VideoPreviewOverlay';
import PigsUnderCareLine from 'components/PigsUnderCareLine/PigsUnderCareLine';
import AudioPreview from 'components/AudioPreview';
import TimeAgo from 'components/TimeAgo/TimeAgo';
import CheckupLogsList from 'components/DailyCheckups/CheckupLogs/CheckupLogsList';
import MovementInfo from 'components/MovementInfo/MovementInfo';
import UserPanel from 'components/UserPanel/UserPanel';
import ModalSidebar from 'components/Modal/ModalSidebar';
import DiagnosisSidebar from 'components/DiagnosisSidebar/DiagnosisSidebar';
import MobileDiseasesSelector from 'components/MobileDiseasesSelector/MobileDiseasesSelector';
import Button from 'components/Button';
import Translation from 'components/Translation';
import OralTreatmentLabel from 'components/DailyCheckups/TreatmentProductLabels/OralTreatmentLabel';
import InjectableTreatmentLabel from 'components/DailyCheckups/TreatmentProductLabels/InjectableTreatmentLabel';
import FormattedTemperature from 'components/FormattedTemperature';
import CheckupNavigation from './CheckupNavigation/CheckupNavigation';
import InfoSection, { Notes, InfoRow } from './Section';
import PdfAssetPreview from 'components/PdfAssetPreview';
import PigsUnderTreatmentLine from 'components/PigsUnderTreatmentLine';
// endpoints
import { getCheckupDiagnoses } from 'endpoints/dailyCheckups';
// utils
import moment from 'moment';
import isEmpty from 'lodash.isempty';
import isNull from 'lodash.isnull';
import cn from 'classnames';
import {
  isImageAsset,
  isVideoAsset,
  isAudioAsset,
  isUserCanDiagnose,
  filterMedsByType,
  calculateMgPerHead,
  showToastrMessage,
  formatWater,
  isPDFAsset,
} from 'utils';
import {
  getCheckupSegmentHeaderData,
  thirdPartyTemperatureDevices,
  thirdPartyWaterUsageDevices,
} from 'utils/checkupHelper';
import { isMobile } from 'react-device-detect';
import { getCommentByRole } from 'helpers.js';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import pick from 'lodash.pick';
import { diagnosesTypes } from 'utils/diagnosesHelper';
import { isUserExactlyCaregiver } from 'utils/rolesHelper';
// styles
import './BarnsheetsDailyCheckup.scss';
// constants
import { comment_roles } from 'constants.js';
import { isSchedulingTariffUser } from '../../../utils/userHelper';

const {
  CHECKUP_ROLE,
  PIG_MIGRATIONS_ROLE,
  TREATMENTS_ROLE,
  SYMPTOMS_ROLE,
  TEMPERATURE_ROLE,
  WATER_USAGE_ROLE,
} = comment_roles;

const labels = {
  edit: <FormattedMessage id="general.button.edit" />,
  notes: <FormattedMessage id="general.notes" />,
  review: <FormattedMessage id="general.button.review" />,

  pigMovements: <FormattedMessage id="general.pigMovements" />,
  deaths: <FormattedMessage id="general.deaths" />,
  medications: <FormattedMessage id="general.medications" />,
  symptoms: <FormattedMessage id="general.symptoms" />,
  highLowTemps: <FormattedMessage id="general.highLowTemps" />,
  waterUsage: <FormattedMessage id="general.waterUsage" />,
  gallonageConsumed: (ms) => <FormattedMessage id={`general.${ms}Consumed`} />,
  gallonagePerHead: (ms) => <FormattedMessage id={`general.${ms}PerHead`} />,

  updated_at: (updated_at, modified_by_full_name) => (
    <FormattedMessage
      id="general.time.updatedBy"
      values={{
        timeago: <TimeAgo date={updated_at} />,
        name: modified_by_full_name || <FormattedMessage id="general.byYou" />,
      }}
    />
  ),

  meds: {
    injectable: <FormattedMessage id="general.meds.injectable" />,
    head: <FormattedMessage id="general.meds.head" />,
    oral: <FormattedMessage id="general.meds.oral" />,
    topical: <FormattedMessage id="general.meds.topical" />,
  },

};

class BarnsheetsDailyCheckup extends Component {
  state = {
    diagnoses: [],
  };

  componentDidMount() {
    const { checkup } = this.props;
    this.getDiagnosesData(checkup.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { checkup } = this.props;
    if (nextProps.checkup.id !== checkup.id) {
      this.getDiagnosesData(nextProps.checkup.id);
    }
  }

  getDiagnosesData(id) {
    getCheckupDiagnoses(id)
      .then((response) => {
        this.setState({ diagnoses: response.resources });
      })
      .catch(toastResponseErrors);
  }

  openSidebar = ({ daily_checkup_id }) => () => {
    const { openModal, openPortal, checkup } = this.props;

    if (isMobile) {
      openPortal(
        <MobileDiseasesSelector
          checkupId={daily_checkup_id}
          pigGroup={checkup.pig_group}
          onSubmitData={(data) => this.onSidebarDataSubmit(daily_checkup_id, data)}
        />,
      );
    } else {
      openModal(
        <ModalSidebar>
          <DiagnosisSidebar
            checkupId={daily_checkup_id}
            pigGroup={checkup.pig_group}
            onSubmitData={(data) => this.onSidebarDataSubmit(daily_checkup_id, data)}
          />
        </ModalSidebar>,
        {},
        TYPE_PANEL,
      );
    }
  };

  onSidebarDataSubmit = (checkupId, data) => {
    const { updateCheckupDiagnoses } = this.props;
    return updateCheckupDiagnoses(checkupId, data)
      .then(() => {
        showToastrMessage('component.toastr.diagnoses.successUpdated');
        this.getDiagnosesData(checkupId);
      })
      .catch(toastResponseErrors);
  };

  removeDiagnosis = ({ id, daily_checkup_id }) => () => {
    const { updateCheckupDiagnoses } = this.props;
    return updateCheckupDiagnoses(daily_checkup_id, [{ id, _destroy: true }])
      .then(() => {
        showToastrMessage('component.toastr.diagnoses.successUpdated');
        this.getDiagnosesData(daily_checkup_id);
      })
      .catch(toastResponseErrors);
  };

  renderDropdownOptions = (diagnosis) => {
    const { currentUser, checkup: { pig_group } } = this.props;
    if (!isUserCanDiagnose(currentUser, pig_group.farm_id, pig_group.farm.manager_id)) return [];
    return [
      { label: <FormattedMessage id="general.editDiagnosis" />, onClick: this.openSidebar(diagnosis) },
      { label: <FormattedMessage id="general.deleteDiagnosis" />, onClick: this.removeDiagnosis(diagnosis) },
    ];
  };

  renderDiagnosis = (diagnose, index) => {
    const { currentUser } = this.props;
    const createdBy = diagnose.created_by;
    const customUserName = (createdBy.id === currentUser.id)
      ? <FormattedMessage id="general.you" />
      : createdBy.name;
    const dropdownOptions = this.renderDropdownOptions(diagnose);

    return (
      <section key={index}>
        <UserPanel
          dropdownOptions={dropdownOptions}
          customUserName={customUserName}
          user={createdBy}
          label={<FormattedMessage id="general.diagnosedPigs" />}
          timestamp={diagnose.created_at}
          className="user-details"
        >
          <div className="panel inner-panel">
            <div className="diagnose-name">
              <span className="name">{diagnose.diagnosis.name}</span>
              <span>{diagnosesTypes[diagnose.diagnosis_type]}</span>
            </div>
            {diagnose.note
              && (
                <div className="diagnose-note">
                  <span className="note"><FormattedMessage id="general.notes" />: </span>&nbsp;
                  <Translation text={diagnose.note}>
                    {diagnose.note}
                  </Translation>
                </div>
              )}
          </div>
        </UserPanel>
      </section>
    );
  };

  handleReportLink = (reportPath) => {
    const { isEditMode, enableEditMode, checkup, isEditingDisabled } = this.props;
    const { router } = this.context;

    if (isEditingDisabled) {
      router.push(`/daily-checkup/${checkup.id}/${reportPath}?reviewMode=true`);
      return;
    }

    if (!isEditMode) {
      enableEditMode()
        .then(() => router.push(`/daily-checkup/${checkup.id}/${reportPath}?editMode=true`));
    } else {
      router.push(`/daily-checkup/${checkup.id}/${reportPath}?editMode=true`);
    }
  };

  render() {
    const { openViewer, checkup, isEditMode, currentUser, fromPageUrl, isSchedulingAccessOnly } = this.props;
    const { diagnoses } = this.state;
    const logs = checkup?.logs || [];
    const comments = checkup?.comments || [];
    const temperatureTracking = currentUser.current_company?.track_temperature;
    const trackWaterUsage = currentUser.current_company?.track_water_usage;
    const measurementSystem = currentUser.current_company?.measurement_system;
    const measure = measurementSystem === 'imperial' ? 'gallonage' : 'liters';
    const { pig_group_id: checkup_pig_group_id, modified_by_full_name, high_temperature, low_temperature,
      water_usage_value, initial_pigs, pigs,
      edge_temperature, edge_water_usage,
      maximus_temperature, maximus_water_usage,
      dicam_temperature, dicam_water_usage,
      barn_tools_temperature, barn_tools_water_usage,
    } = checkup;

    const getAssetPreviewUrl = (asset) => {
      const { url } = asset;
      return url.thumb || url.small || url.medium || url.original || url.origin;
    };

    const treatments = checkup.dc_treatments.filter(({ _destroy }) => !_destroy);
    const { injectable, oral, topical } = filterMedsByType(treatments);

    const pigMigrations = checkup.pig_migrations.filter(({ amount, _destroy }) => (amount && !_destroy));

    const imageAssets = checkup.dc_assets?.filter((asset) => isImageAsset(asset)
      || isVideoAsset(asset)
      || isPDFAsset(asset)) || [];
    const audioAssets = checkup.dc_assets?.filter((asset) => isAudioAsset(asset)) || [];

    const deathsData = checkup.mortality_reasons || [
      { type: 'chronic', value: checkup.chronic_death_count },
      { type: 'acute', value: checkup.acute_death_count },
      { type: 'euthanasia', value: checkup.euthanasia_death_count },
    ];

    const ReportOpenLink = ({ reportPath = '' }) => {
      const { isEditingDisabled } = this.props;
      return (
        <Button className="button light view-report" onClick={() => this.handleReportLink(reportPath)}>
          {isEditingDisabled ? labels.review : labels.edit}
        </Button>
      );
    };

    const backLink = fromPageUrl || `/barnsheets/groups/${checkup_pig_group_id}${isEditMode ? '?editMode=true' : ''}`;

    const isHighlighted = (item) => item?.critical || false;

    const isEmptyTemperatures = (high_temperature === null) && (low_temperature === null);
    const isEmptyWaterUsageVal = (water_usage_value === null);

    const { comment } = getCommentByRole(comments || [], CHECKUP_ROLE);

    return (
      <div className={cn('BarnsheetsDailyCheckup', { isEditMode })} key={checkup.id}>
        <div className="medium-12 xlarge-8 column checkup-column">
          <div className="panel overflow-visible">
            <div className="panel-heading main-panel">
              <span>
                {moment(checkup.created_on).format('MMMM D, YYYY')}
                {!!checkup.dc_assets.length && (
                  <FormattedMessage id="general.media">
                    {(text) => <span className="media-label">{text}</span>}
                  </FormattedMessage>
                )}
              </span>
              <Link to={backLink} className="close-checkup"><i className="fa fa-times" /></Link>
            </div>

            <PigsUnderCareLine pigsCount={checkup.pigs} mobile />
            {checkup.abf_tracking && <PigsUnderTreatmentLine pigsCount={checkup.treated_pigs} mobile />}

            <div className="panel-body">
              {checkup.id && (
                <section>

                  {!!diagnoses.length && diagnoses.map(this.renderDiagnosis)}

                  {/* PIG MOVEMENTS SECTION */}
                  <InfoSection
                    headerLabel={labels.pigMovements}
                    headerValue={pigMigrations.length}
                    notes={getCommentByRole(comments, PIG_MIGRATIONS_ROLE)}
                    actionButton={<ReportOpenLink reportPath="pig-movements" />}
                    highlightEmpty={!pigMigrations.length}
                  >
                    {/* {get(checkup, 'comments', [])} */}
                    <MovementInfo
                      pigMigrations={pigMigrations}
                      className="movements-info"
                      isHighlighted={isHighlighted}
                    />
                  </InfoSection>

                  {/* DEATHS SECTION */}
                  <InfoSection
                    className="deaths-section"
                    headerLabel={labels.deaths}
                    headerValue={checkup.deaths_sum}
                    actionButton={<ReportOpenLink reportPath="report-deaths" />}
                    withoutNotes
                    highlightEmpty={!checkup.deaths_sum}
                  >
                    <DeadPigsBlock
                      data={deathsData}
                      dailyCheckup={checkup}
                      hasDeathReasons={Boolean(checkup.mortality_reasons)}
                    />
                  </InfoSection>

                  {/* MEDICATIONS SECTION */}
                  <InfoSection
                    headerLabel={labels.medications}
                    headerValue={checkup.dc_treatments.length}
                    notes={getCommentByRole(comments, TREATMENTS_ROLE)}
                    actionButton={!isSchedulingAccessOnly ? <ReportOpenLink reportPath="report-treatments" /> : null}
                    highlightEmpty={!injectable.length && !oral.length}
                  >
                    {!!injectable.length && (
                      <div>
                        <InfoRow
                          leftValue={labels.meds.injectable}
                          rightValue={labels.meds.head}
                          gray
                          semibold
                        />

                        {injectable.map((item) => (
                          <InfoRow
                            key={item.id}
                            rightSemibold
                            leftValue={(
                              <InjectableTreatmentLabel quantity={item.quantity} product={item.treatment_product} />
                            )}
                            rightValue={item.head_count}
                            className={cn({ 'highlighted': isHighlighted(item.treatment_product) })}
                          />
                        ))}
                      </div>
                    )}

                    {!!oral.length && (
                      <div>
                        <InfoRow
                          leftValue={labels.meds.oral}
                          rightValue={labels.meds.head}
                          gray
                          semibold
                        />
                        {oral.map((item) => (
                          <InfoRow
                            key={item.id}
                            rightSemibold
                            leftValue={(
                              <OralTreatmentLabel
                                product={item.treatment_product}
                                quantity={item.quantity}
                                gallonsValue={item.gallons_value}
                              />
                            )}
                            rightValue={item.head_count}
                            className={cn({ 'highlighted': isHighlighted(item.treatment_product) })}
                          />
                        ))}
                      </div>
                    )}

                    {!!topical.length && (
                      <div>
                        <InfoRow
                          leftValue={labels.meds.topical}
                          rightValue={labels.meds.head}
                          gray
                          semibold
                        />
                        {topical.map((item) => (
                          <InfoRow
                            key={item.id}
                            rightSemibold
                            leftValue={item.treatment_product.name}
                            rightValue={item.head_count}
                            className={cn({ 'highlighted': isHighlighted(item.treatment_product) })}
                          />
                        ))}
                      </div>
                    )}
                  </InfoSection>

                  {/* SYMPTOM SECTION */}
                  <InfoSection
                    headerLabel={labels.symptoms}
                    headerValue={checkup.dc_symptoms.length}
                    notes={getCommentByRole(comments, SYMPTOMS_ROLE)}
                    actionButton={!isSchedulingAccessOnly ? <ReportOpenLink reportPath="report-symptoms" /> : null}
                    highlightEmpty={!checkup.dc_symptoms.length}
                  >
                    {checkup.dc_symptoms.map((symptom) => !symptom._destroy && (
                      <InfoRow
                        key={symptom.id}
                        rightSemibold
                        leftValue={symptom.name}
                        rightValue={symptom.frequency + '%'}
                        className={cn({ 'highlighted': isHighlighted(symptom) })}
                      />
                    ))}
                  </InfoSection>

                  {/* HIGH/LOW TEMPS SECTION */}
                  {(temperatureTracking || !isEmptyTemperatures) && (
                    <InfoSection
                      extendedHeaderData={getCheckupSegmentHeaderData(pick(checkup, thirdPartyTemperatureDevices))}
                      headerLabel={labels.highLowTemps}
                      headerValue={isEmptyTemperatures ? 'N/A' : null}
                      notes={getCommentByRole(comments, TEMPERATURE_ROLE)}
                      actionButton={(
                        !edge_temperature &&
                        !maximus_temperature &&
                        !dicam_temperature &&
                        !barn_tools_temperature &&
                        temperatureTracking
                      ) || isEmptyTemperatures || !isSchedulingAccessOnly ?
                        <ReportOpenLink reportPath="report-temps" /> :
                        null
                      }
                      highlightEmpty={isEmptyTemperatures}
                    >
                      {!isEmptyTemperatures && ['high', 'low'].map((item) => (
                        <InfoRow
                          key={item}
                          rightSemibold
                          leftValue={<FormattedMessage id={`general.${item}`} />}
                          rightValue={<FormattedTemperature value={checkup[`${item}_temperature`]} />}
                        />
                      ))}
                    </InfoSection>
                  )}

                  {/* SURVEYS SECTION */}
                  {!isSchedulingAccessOnly && checkup.daily_checkup_surveys.map(({ question, id, value }) => (
                    !isNull(value) ? (
                      <InfoSection
                        key={id}
                        headerLabel={question}
                        headerValue={value ?
                          <FormattedMessage id="general.button.yes" /> :
                          <FormattedMessage id="general.button.no" />}
                        withoutNotes
                        highlightEmpty
                      />
                    ) : null))}

                  {/* WATER USAGE SECTION */}
                  {(trackWaterUsage || !isEmptyWaterUsageVal) && !isSchedulingAccessOnly && (
                    <InfoSection
                      extendedHeaderData={getCheckupSegmentHeaderData(pick(checkup, thirdPartyWaterUsageDevices))}
                      headerLabel={labels.waterUsage}
                      headerValue={isEmptyWaterUsageVal ? 'N/A' : null}
                      notes={getCommentByRole(comments, WATER_USAGE_ROLE)}
                      actionButton={
                        (!edge_water_usage && !maximus_water_usage && !dicam_water_usage && !barn_tools_water_usage
                          && trackWaterUsage) || isEmptyWaterUsageVal || water_usage_value === '0.0' ? (
                            <ReportOpenLink reportPath="report-water-usage" />
                          ) : null}
                      highlightEmpty={isEmptyWaterUsageVal}
                    >
                      {!isEmptyWaterUsageVal && (
                        <InfoRow
                          rightSemibold
                          leftValue={labels.gallonageConsumed(measure)}
                          rightValue={formatWater(water_usage_value, measurementSystem)}
                        />
                      )}
                      {!isEmptyWaterUsageVal && (
                        <InfoRow
                          rightSemibold
                          leftValue={labels.gallonagePerHead(measure)}
                          rightValue={calculateMgPerHead(
                            formatWater(water_usage_value, measurementSystem),
                            initial_pigs || pigs
                          )}
                        />
                      )}
                    </InfoSection>
                  )}

                  {/* NOTES SECTION */}

                  <InfoSection
                    headerLabel={labels.notes}
                    withoutNotes
                    actionButton={!isSchedulingAccessOnly ? <ReportOpenLink reportPath="note" /> : null}
                    highlightEmpty={!comment}
                  >
                    {comment && (
                      <Notes value={{ comment }} withoutLabel />
                    )}
                  </InfoSection>

                  {/* AUDIO ASSETS */}
                  {!!audioAssets.length && !isSchedulingAccessOnly && (
                    <InfoSection
                      headerLabel={<FormattedMessage id="container.checkupEdit.recordedAudio" />}
                      headerValue={audioAssets.length}
                      actionButton={!isSchedulingAccessOnly ? <ReportOpenLink reportPath="media" /> : null}
                      highlightEmpty={!audioAssets.length}
                    >
                      {audioAssets.map((asset) => (
                        <div className="audio-asset-wrapper" key={asset.id}>
                          <AudioPreview
                            asset={asset}
                            className="audio-asset"
                            controlSize="40"
                            hideAction
                          />
                          {asset.description
                            && (
                              <div className="asset-note">
                                <FormattedMessage id="general.note">
                                  {(text) => <div className="title">{text}</div>}
                                </FormattedMessage>
                                <div className="description">
                                  {asset.description}
                                </div>
                              </div>
                            )}
                        </div>
                      ))}
                    </InfoSection>
                  )}

                  {/* MEDIA SECTION */}
                  <InfoSection
                    headerLabel={<FormattedMessage id="general.media" />}
                    headerValue={imageAssets.length}
                    withoutNotes
                    actionButton={!isSchedulingAccessOnly ? <ReportOpenLink reportPath="media" /> : null}
                    highlightEmpty={!imageAssets.length}
                  >
                    {!!imageAssets.length && (
                      <div className="panel-body content-row assets">
                        {imageAssets.map((asset, index) => (
                          <div className="asset-preview" key={asset.id}>
                            {(isVideoAsset(asset) || isImageAsset(asset)) && (
                              <>
                                <div
                                  className="image"
                                  style={{ backgroundImage: 'url(' + getAssetPreviewUrl(asset) + ')' }}
                                  title={asset.filename}
                                  onClick={() => openViewer(imageAssets, index, checkup, 'checkup')}
                                />
                                {isVideoAsset(asset) && <VideoPreviewOverlay size="30" simple />}
                              </>
                            )}
                            {isPDFAsset(asset) && (
                              <PdfAssetPreview
                                asset={asset}
                                onClick={() => openViewer(imageAssets, index, checkup, 'checkup')}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </InfoSection>

                  {checkup.updated_at
                    && (
                      <div className="updated-at">
                        {labels.updated_at(checkup.updated_at, modified_by_full_name)}
                      </div>
                    )}
                </section>
              )}
            </div>
          </div>
        </div>


        <div className="medium-12 xlarge-4 column right-column">
          {!isEmpty(logs) && (
            <div className="panel">
              <div className="panel-heading">
                <FormattedMessage id="component.checkupActivity.checkupActivity" />
              </div>
              <div className="panel-body no-padding">
                <CheckupLogsList logs={logs} checkup={checkup} />
              </div>
            </div>
          )}
        </div>

        <CheckupNavigation checkup={checkup} isEditMode={isEditMode} />
      </div>
    );
  }
}

BarnsheetsDailyCheckup.contextTypes = {
  router: T.object,
};

BarnsheetsDailyCheckup.propTypes = {
  checkup: T.object,
  currentUser: T.object,
  openViewer: T.func,
  isEditMode: T.bool,
  updateCheckupDiagnoses: T.func,
  openModal: T.func,
  openPortal: T.func,
  enableEditMode: T.func,
  fromPageUrl: T.string,
  isEditingDisabled: T.bool.isRequired,
  isSchedulingAccessOnly: T.bool.isRequired,
};

const enhance = compose(
  connect((state) => {
    const checkup = state.barnsheets.checkup.resource;
    const isExactlyCaregiver = isUserExactlyCaregiver(state.auth.user, checkup.pig_group.farm_id);
    return {
      checkup,
      currentUser: state.auth.user,
      isEditingDisabled: state.auth.user.current_company.barnsheets_limited_access && isExactlyCaregiver,
      isSchedulingAccessOnly: isSchedulingTariffUser(state.auth.user),
    };
  }, {
    openViewer,
    updateCheckupDiagnoses,
    openModal,
    openPortal,
  }),
  withCorrection,
);

export default enhance(BarnsheetsDailyCheckup);
