import React from 'react';
import T from 'prop-types';
// components
import Input from 'components/Input';
// utils
import cn from 'classnames';
// styles
import './NumberStepInput.scss';

const defaultMaxValue = 99999;
const defaultMinValue = 0;
const defaultStep = 1;
const defaultMask = /^[0-9]{0,5}.?[0-9]{0,2}$/;

const parseValue = (value, step) => {
  const isFloatMode = Number(step) === step && step % 1 !== 0;
  if (!isFloatMode) return parseInt(value, 10);
  if ((value || '').substr(-1) === '.') return value;
  return parseFloat(value);
};

const NumberStepInput = ({
  hasWarning = false,
  step = defaultStep,
  label,
  isDisabled = false,
  className = '',
  onChange,
  normalizeValue,
  min = defaultMinValue,
  max = defaultMaxValue,
  pattern = defaultMask,
  value = '',
  valueParser = parseValue,
  ...props
}) => {
  const onInputChange = (e) => {
    const targetValue = e.target.value;
    if (targetValue === '') {
      onChange(0);
      return;
    }

    const inputValue = valueParser(targetValue, step);
    // if (inputValue === value || !pattern.test(inputValue.toString())) return;
    if (!pattern.test(inputValue.toString())) return;
    if ((+inputValue > max || +inputValue < min) && inputValue !== 0) return;
    onChange(inputValue || 0);
  };

  const onCounterChange = (e, delta) => {
    e.preventDefault();
    let newValue = +value + delta;
    // 0.1 + 0.2 = 0.30000000000000004 according to IEEE 754
    if (Math.abs(delta) === 0.1) {
      newValue = ((+value * 10) + (delta * 10)) / 10;
    }
    if (newValue > max || newValue < min) return;
    onChange(Number.isInteger(newValue) ? newValue : newValue.toFixed(2));
  };

  const formattedValue = normalizeValue ? normalizeValue(value) : value;
  return (
    <div
      className={cn('NumberStepInput numeric', { [className]: !!className, disabled: isDisabled, warning: hasWarning })}
    >
      <label>
        <div className="label-wrapper">
          {Boolean(label) && <span>{label}</span>}
        </div>
        <div className="step-buttons">
          <span className="minus" onClick={(e) => onCounterChange(e, -step)} />
          <Input
            disabled={isDisabled}
            step={step}
            min={min}
            max={max}
            value={formattedValue}
            inputMode="numeric"
            type="text"
            placeholder="0"
            onChange={onInputChange}
            {...props}
          />
          <span className="plus" onClick={(e) => onCounterChange(e, step)} />
        </div>
      </label>
    </div>
  );
};

NumberStepInput.propTypes = {
  value: T.oneOfType([T.number, T.string]),
  min: T.number,
  max: T.number,
  onChange: T.func.isRequired,
  onBlur: T.func,
  onFocus: T.func,
  step: T.number,
  label: T.node,
  isDisabled: T.bool,
  hasWarning: T.bool,
  className: T.string,
  pattern: T.string,
  type: T.string,
  id: T.string,
  normalizeValue: T.func,
  valueParser: T.func,
};

export default NumberStepInput;
