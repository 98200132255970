/* eslint-disable max-len */
import React from 'react';
import { Route, IndexRoute, IndexRedirect } from 'react-router';
import routesHelper from 'utils/routesHelper';
import { withProps } from 'recompose';
// redux
import { closeViewer } from 'reducers/mediaViewer';
// csv import
import CSVImportContainer from 'containers/CSVImport/CSVImportContainer';
import CSVImportSelectMode from 'containers/CSVImport/CSVImportSelectMode';
import CSVImportUpload from 'containers/CSVImport/CSVImportUpload';
import CSVImportMapColumns from 'containers/CSVImport/CSVImportMapColumns';
import CSVImportResults from 'containers/CSVImport/CSVImportResults';
// layouts
import Container from 'containers/AppContainer/AppContainer';
import MainLayout from 'containers/Layouts/MainLayout';
import DefaultLayout from 'containers/Layouts/DefaultLayout';
// auth
import Login from 'containers/Auth/Login';
import InvitationsContainer from 'containers/Auth/Invitations/InvitationsContainer';
import AcceptInvite from 'containers/Auth/Invitations/AcceptInvite';
import CompleteRegistrationPassword from 'containers/Auth/Invitations/CompleteRegistrationPassword';
import CompleteRegistrationLanguage from 'containers/Auth/Invitations/CompleteRegistrationLanguage';
import CompleteRegistrationInfo from 'containers/Auth/Invitations/CompleteRegistrationInfo';
import ResetPassword from 'containers/Auth/ResetPassword';
import SetPassword from 'containers/Auth/SetPassword';
import CompanySwitcher from 'containers/Auth/CompanySwitcher';
import SwitchUserAuth from 'containers/Auth/SwitchUserAuth';
import SetPasswordMessage from 'containers/Auth/SetPasswordMessage';
// barnsheets
import BarnSheetsCompanies from 'containers/Barnsheets/BarnSheetsCompanies/BarnSheetsCompanies';
import BarnSheetsGroupProfile from 'containers/Barnsheets/BarnsheetsGroupProfile/BarnSheetsGroupProfile';
import BarnSheetsGroups from 'containers/Barnsheets/BarnSheetsGroups';
import BarnSheetsFarms from 'containers/Barnsheets/BarnSheetsFarms';
import BarnSheetsFarmProfile from 'containers/Barnsheets/BarnSheetsFarmProfile/BarnSheetsFarmProfile';
import BarnSheetsFarmGroups from 'containers/Barnsheets/BarnSheetsFarmProfile/BarnSheetsFarmGroups';
import BarnSheetsFarmPigMovements from 'containers/Barnsheets/BarnSheetsFarmProfile/BarnSheetsFarmPigMovements';
import BarnsheetsFarmDiagnoses from 'containers/Barnsheets/BarnSheetsFarmProfile/BarnsheetsFarmDiagnoses';
import BarnsheetsMedia from 'containers/Barnsheets/BarnsheetsMedia';
import BarnsheetsGroupCheckups from 'containers/Barnsheets/BarnsheetsGroupProfile/BarnsheetsGroupCheckups';
import BarnSheetsGroupTreatmentsHistory from
  'containers/Barnsheets/BarnsheetsGroupProfile/BarnSheetsGroupTreatmentsHistory';
import BarnsheetsGroupDiagnoses from 'containers/Barnsheets/BarnsheetsGroupProfile/BarnsheetsGroupDiagnoses';
import BarnsheetsWaterUsage from 'containers/Barnsheets/BarnsheetsGroupProfile/BarnsheetsWaterUsage';
import BarnSheetsGroupPigMovements from 'containers/Barnsheets/BarnsheetsGroupProfile/BarnSheetsGroupPigMovements';
import BarnsheetsDailyCheckup from 'containers/Barnsheets/BarnsheetsDailyCheckup';
import BarnsheetsCompanyFarms from 'containers/Barnsheets/BarnSheetsCompanies/BarnsheetsCompanyFarms';
import BarnsheetsFarmTreatments from 'containers/Barnsheets/BarnSheetsFarmProfile/BarnsheetsFarmTreatmentsHistory';
import BarnsheetsFarmTreatmentsAntibiotic from
  'containers/Barnsheets/BarnSheetsFarmProfile/BarnsheetsFarmTreatmentsAntibiotic';
import BarnSheetsGroupTreatmentsAntibiotic
  from 'containers/Barnsheets/BarnsheetsGroupProfile/BarnSheetsGroupTreatmentsAntibiotic';
import BarnSheetsGroupSalesResults from 'containers/Barnsheets/BarnsheetsGroupProfile/BarnSheetsGroupSalesResults';
import BarnSheetsReports from 'containers/Barnsheets/BarnSheetsReports';
import BarnSheetsTreatmentProtocols
  from './containers/Barnsheets/BarnsheetsGroupProfile/BarnSheetsGroupTreatmentProtocols';
import BarnsheetsGroupPigGroupDiagnoses
  from './containers/Barnsheets/BarnsheetsGroupProfile/BarnsheetsGroupPigGroupDiagnoses';
// my-schedule
import MySchedule from './containers/MySchedule/MySchedule';
import CommitmentPageContainer from './containers/MySchedule/CommitmentPage/CommitmentPageContainer';
import CommitmentProfile from './containers/MySchedule/CommitmentPage/CommitmentProfile/CommitmentProfile';
import CommitmentHealthInfo from './components/CommitmentHealthInfo/CommitmentHealthInfo';
import SalesResults from './components/SalesResults/SalesResults';
// daily-checkup
import DailyCheckupContainer from 'containers/DailyCheckup/DailyCheckupContainer';
import DailyCheckupHome from 'containers/DailyCheckup/DailyCheckupHome';
import DailyCheckupFarmGroups from 'containers/DailyCheckup/DailyCheckupFarmGroups';
import DailyCheckupEdit from 'containers/DailyCheckup/DailyCheckupEdit';
import DailyCheckupReportMovements from 'containers/DailyCheckup/DailyCheckupReportMovements';
import DailyCheckupReportTemps from 'containers/DailyCheckup/DailyCheckupReportTemps';
import DailyCheckupReportTreatments from 'containers/DailyCheckup/DailyCheckupReportTreatments';
import DailyCheckupReportProtocolTreatment from 'containers/DailyCheckup/DailyCheckupReportProtocolTreatment';
import DailyCheckupReportSymptoms from 'containers/DailyCheckup/DailyCheckupReportSymptoms';
import DailyCheckupReportWaterUsage from 'containers/DailyCheckup/DailyCheckupReportWaterUsage';
import AudioRecorder from 'containers/DailyCheckup/AudioRecorder';
import DailyCheckupStartGroupSwitcher from './containers/DailyCheckup/DailyCheckupStartGroupSwitcher';
import DailyCheckupNote from 'containers/DailyCheckup/DailyCheckupNote';
import DailyCheckupMedia from 'containers/DailyCheckup/DailyCheckupMedia';
import DailyCheckupLoadMovement from './containers/DailyCheckup/DailyCheckupLoadMovement';
// treatment-protocols
import TreatmentProtocolsContainer from 'containers/TreatmentProtocols/TreatmentProtocolsContainer';
import TreatmentProtocolProfile from 'containers/TreatmentProtocols/TreatmentProtocolProfile';
import OutOfComplianceGroups from 'containers/TreatmentProtocols/OutOfComplianceGroups';
import GroupTreatmentProtocolsSteps from 'containers/TreatmentProtocols/Groups/GroupTreatmentProtocolsSteps';
import FarmsSetUp from 'containers/TreatmentProtocols/FarmsSetUp';
import TreatmentProtocolsFarm from 'containers/TreatmentProtocols/Farms/TreatmentProtocolsFarm';
import FarmTreatmentProtocols from 'containers/TreatmentProtocols/Farms/TreatmentProtocols/FarmTreatmentProtocols';
import CreateFarmTreatmentProtocol from 'containers/TreatmentProtocols/Farms/TreatmentProtocols/CreateFarmTreatmentProtocol';
import EditFarmTreatmentProtocol from 'containers/TreatmentProtocols/Farms/TreatmentProtocols/EditFarmTreatmentProtocol';
import TreatmentProtocolFarmGroups from 'containers/TreatmentProtocols/Farms/TreatmentProtocols/TreatmentProtocolsFarmGroups';
import FarmTreatmentProtocolProfile from 'containers/TreatmentProtocols/Farms/TreatmentProtocols/FarmTreatmentProtocolProfile';
import CreateGroupTreatmentProtocol from './containers/TreatmentProtocols/Farms/Groups/CreateGroupTreatmentProtocol';
import GroupTreatmentProtocols from 'containers/TreatmentProtocols/Farms/Groups/GroupTreatmentProtocols';
import TreatmentProtocolsGroup from 'containers/TreatmentProtocols/Farms/Groups/TreatmentProtocolsGroup';
import EditGroupTreatmentProtocol from 'containers/TreatmentProtocols/Farms/Groups/EditGroupTreatmentProtocol';
import GroupTreatmentProtocolProfile from 'containers/TreatmentProtocols/Farms/Groups/GroupTreatmentProtocolProfile';
import TreatmentProtocols from 'containers/TreatmentProtocols/TreatmentProtocols';
import CreateTreatmentProtocol from 'containers/TreatmentProtocols/CreateTreatmentProtocol';
import EditTreatmentProtocol from 'containers/TreatmentProtocols/EditTreatmentProtocol';
// reports
import FarmReportTemplates from 'containers/ReportFarms/FarmReportTemplates';
import ReportFarms from 'containers/ReportFarms';
import FarmReports from 'containers/ReportFarms/FarmReports';
import Report from 'containers/Report';
import ReportReview from 'containers/Report/ReportReview';
// my-profile
import MyProfile from 'containers/MyProfile/MyProfile';
import MyProfileEdit from 'containers/MyProfile/MyProfileEdit';
import MyProfileMessaging from 'containers/MyProfile/Messaging';
import MyProfilePreferences from 'containers/MyProfile/MyProfilePreferences';
import MyProfileFarmRoles from 'containers/MyProfile/MyProfileFarmRoles';
import MyProfileCompanyRoles from 'containers/MyProfile/MyProfileCompanyRoles';
import MyProfileAdminRoles from 'containers/MyProfile/MyProfileAdminRoles';
// admin imports
import  AdminImports  from './containers/Admin/Imports';
import  Templates from './containers/Admin/Imports/Templates';
import  ChoseImport from './containers/Admin/Imports/ChooseImport';
// admin users
import AdminUsers from 'containers/Admin/Users/Users';
import CreateUser from 'containers/Admin/Users/CreateUser';
import AdminUserProfile from 'containers/Admin/Users/UserProfile';
import AdminUserProfileEdit from 'containers/Admin/Users/UserProfileEdit';
import AdminUserFarmRoles from 'containers/Admin/Users/UserFarmRoles';
import AdminUserCompanyRoles from 'containers/Admin/Users/UserCompanyRoles';
import AdminUserTruckingRoles from 'containers/Admin/Users/UserTruckingRoles';
import UserFarmsRolesManage from 'containers/Admin/Users/UserFarmsRolesManage';
import UserCompanyRolesManage from 'containers/Admin/Users/UserCompanyRolesManage';
import UserTruckingRolesManage from 'containers/Admin/Users/UserTruckingRolesManage';
// admin farms
import AdminFarms from 'containers/Admin/Farms/Farms';
import CreateFarm from 'containers/Admin/Farms/FarmCreate';
import FarmProfile from 'containers/Admin/Farms/FarmProfile';
import FarmEdit from 'containers/Admin/Farms/FarmEdit';
import FarmBarns from 'containers/Admin/Farms/FarmBarns';
import FarmBarnCreate from 'containers/Admin/Farms/FarmBarnCreate';
import FarmBarnEdit from 'containers/Admin/Farms/FarmBarnEdit';
import FarmUserRoles from 'containers/Admin/Farms/FarmUserRoles';
import FarmHealthVariables from 'containers/Admin/Farms/FarmHealthVariables';
import FarmScheduling from 'containers/Admin/Farms/FarmScheduling';
import FarmUserRolesManage from 'containers/Admin/Farms/FarmUserRolesManage';
// admin companies
import CompaniesContainer from 'containers/Admin/Companies/CompaniesContainer';
import PackingPlant from './containers/Admin/Companies/PackingCompanies/PackingPlants/PackingPlant';
import PackingPlantEdit from './containers/Admin/Companies/PackingCompanies/PackingPlants/PackingPlantEdit';
import AdminCompanies from 'containers/Admin/Companies/Companies';
import PackingCompanyCreate from 'containers/Admin/Companies/PackingCompanies/PackingCompanyCreate';
import PackingCompanyEdit from './containers/Admin/Companies/PackingCompanies/PackingCompanyEdit';
import PackingPlantCreate from './containers/Admin/Companies/PackingCompanies/PackingPlants/PackingPlantCreate';
import TruckingCompanies from 'containers/Admin/Companies/TruckingCompanies/TruckingCompanies';
import TruckingCompanyCreate from 'containers/Admin/Companies/TruckingCompanies/TruckingCompanyCreate';
import TruckingCompanyProfile from 'containers/Admin/Companies/TruckingCompanies/TruckingCompanyProfile';
import TruckingCompanyEdit from 'containers/Admin/Companies/TruckingCompanies/TruckingCompanyEdit';
import TruckingCompanyUserRoles from 'containers/Admin/Companies/TruckingCompanies/TruckingCompanyUserRoles';
import CreateCompany from 'containers/Admin/Companies/CreateCompany';
import CompanyProfile from 'containers/Admin/Companies/CompanyProfile';
import CompanyEdit from 'containers/Admin/Companies/CompanyEdit';
import CompanyUsersRoles from 'containers/Admin/Companies/CompanyUsersRoles';
import CompanyFarmRoles from 'containers/Admin/Companies/CompanyManagedFarms';
import CompanyFarmsManage from 'containers/Admin/Companies/CompanyFarmsManage';
import CompanyUsersRolesManage from 'containers/Admin/Companies/CompanyUsersRolesManage';
import TruckingUsersRolesManage from 'containers/Admin/Companies/TruckingCompanies/TruckingUsersRolesManage';
import CompanyGroups from 'containers/Admin/Companies/CompanyGroups';
import PackingCompanies from 'containers/Admin/Companies/PackingCompanies/PackingCompanies';
import PackingCompanyProfile from 'containers/Admin/Companies/PackingCompanies/PackingCompanyProfile';
import PackingPlants from 'containers/Admin/Companies/PackingCompanies/PackingPlants/PackingPlants';
// admin ShareholderGroups
import AdminShareholderGroups from 'containers/Admin/ShareholderGroups/ShareholderGroups';
import ShareholderGroupsCreate from 'containers/Admin/ShareholderGroups/ShareholderGroupCreate';
import ShareholderGroupsEdit from 'containers/Admin/ShareholderGroups/ShareholderGroupEdit';
import ShareholderGroupProfile from 'containers/Admin/ShareholderGroups/ShareholderGroupProfile';
import ShareholderGroupsFarmsManage from 'containers/Admin/ShareholderGroups/ShareholderGroupsFarmsManage';
import ShareholderGroupFarmRoles from 'containers/Admin/ShareholderGroups/ShareholderGroupFarmRoles';
// admin site-visit-report-templates
import ReportBuilder from 'containers/Admin/ReportBuilder';
import ReportTemplateCreate from 'containers/Admin/ReportBuilder/ReportTemplateCreate';
import ReportTemplateEdit from 'containers/Admin/ReportBuilder/ReportTemplateEdit';
// admin my-company
import MyCompanyProfile from 'containers/Admin/MyCompany/MyCompanyProfile';
import MyCompanyEdit from 'containers/Admin/MyCompany/MyCompanyEdit';
import MyCompanyAdminRoles from 'containers/Admin/MyCompany/MyCompanyAdminRoles';
import MyCompanyAdminCreate from 'containers/Admin/MyCompany/MyCompanyAdminCreate';
import MyCompanyAdminEdit from 'containers/Admin/MyCompany/MyCompanyAdminEdit';
// admin health variables
import HealthVariables from 'containers/Admin/HealthVariables/HealthVariables';
import Medications from 'containers/Admin/HealthVariables/Mediactions/Medications';
import MedicationProfile from 'containers/Admin/HealthVariables/Mediactions/MedicationProfile';
import MedicationInfo from 'containers/Admin/HealthVariables/Mediactions/MedicationInfo';
import MedicationSettings from 'containers/Admin/HealthVariables/Mediactions/MedicationSettings';
import Diseases from 'containers/Admin/HealthVariables/Diseases/Diseases';
import UpdateDisease from 'containers/Admin/HealthVariables/Diseases/UpdateDisease';
import Symptoms from 'containers/Admin/HealthVariables/Symptoms/Symptoms';
import SymptomUpdate from 'containers/Admin/HealthVariables/Symptoms/UpdateSymptom';
import DeathAlerts from 'containers/Admin/HealthVariables/DeathAlerts/DeathAlerts';
import MortalityReasons from 'containers/Admin/HealthVariables/MortalityReasons/MortalityReasons';
import SetPoints from 'containers/Admin/HealthVariables/SetPoints/SetPoints';
import Genetics from './containers/Admin/HealthVariables/Genetics/Genetics';
import GeneticProfile from './containers/Admin/HealthVariables/Genetics/GeneticProfile';
import GeneticInfo from './containers/Admin/HealthVariables/Genetics/GeneticInfo';
import GeneticGroups from './containers/Admin/HealthVariables/Genetics/GeneticGroups';
import CreateMortalityReason from 'containers/Admin/HealthVariables/MortalityReasons/CreateMortalityReason';
import UpdateMortalityReason from 'containers/Admin/HealthVariables/MortalityReasons/UpdateMortalityReason';
// admin preferences
import Preferences from 'containers/Admin/Preferences/Preferences';
import Settings from 'containers/Admin/Preferences/Settings';
import DailyCheckup from 'containers/Admin/Preferences/DailyCheckup';
import Integrations from 'containers/Admin/Preferences/Integrations';
import MetaFarmsIntegration from 'containers/Admin/Preferences/MetaFarmsIntegration';
import PrairieSystemsIntegration from 'containers/Admin/Preferences/PrairieSystemsIntegration';
import DistynctIntegration from './containers/Admin/Preferences/DistynctIntegration';
// sources
import Sources from 'containers/Sources/Sources';
import SourceEdit from 'containers/Sources/SourceEdit';
import SourceCreate from 'containers/Sources/SourceCreate';
import SourceProfileContainer from 'containers/Sources/SourceProfileContainer';
import SourceOverview from 'containers/Sources/SourceOverview/SourceOverview';
import SourceTreatmentFeedback from 'containers/Sources/SourceTreatmentFeedback';
import SourceGroups from 'containers/Sources/SourceGroups';
// destinations
import Destinations from 'containers/Destinations/Destinations';
import DestinationEdit from 'containers/Destinations/DestinationEdit';
import DestinationCreate from 'containers/Destinations/DestinationCreate';
// farmfeed
import FarmFeedContainer from 'containers/Farmfeed/FarmFeedContainer';
import FlaggedFarmFeed from 'containers/Farmfeed/FlaggedFarmFeed';
import ActivityShow from 'containers/Farmfeed/ActivityShow/ActivityShow';
// virtual visit
import VirtualVisitCreate from 'containers/VirtualVisits/VirtualVisitCreate';
import VirtualVisitUpdate from 'containers/VirtualVisits/VirtualVisitUpdate';
import VirtualVisitView from 'containers/VirtualVisits/VirtualVisitView';
import VirtualVisits from 'containers/VirtualVisits/VirtualVisits';
// insights
import Insights from 'containers/Insights/Insights';
import Analytics from 'containers/Insights/Analytics/Analytics';
import AssetMap from 'containers/Insights/AssetMap/AssetMap';
import AutoReport from 'containers/Insights/AutoReport/AutoReport';
import TreatmentFeedbackHome from 'containers/Insights/Treatments/TreatmentFeedbackHome';
import TreatmentFeedbackMedication from 'containers/Insights/Treatments/TreatmentFeedbackMedication';
import TreatmentFeedbackGroup from 'containers/Insights/Treatments/TreatmentFeedbackGroup';
import EfficacyFeed from 'containers/Insights/Treatments/EfficacyFeed';
import Treatments from 'containers/Insights/Treatments/Treatments';
import TreatmentAntibiotic from 'containers/Insights/Treatments/TreatmentAntibiotic';
// check-ins
import CheckInMap from 'containers/Checkins/CheckInMap';
import FarmsCheckins from 'containers/Checkins/FarmsCheckins/FarmsCheckins';
import FarmCheckins from 'containers/Checkins/FarmCheckins/FarmCheckins';
import UsersCheckins from 'containers/Checkins/UsersCheckins/UsersCheckins';
import Checkins from 'containers/Checkins/Checkins';
import UserCheckins from 'containers/Checkins/UserCheckins/UserCheckins';
import MobileCheckin from 'containers/Checkins/MobileCheckin';
// inventory
import Inventory from 'containers/Inventory/Inventory';
// groups
import GroupsMenuContainer from 'containers/Groups/GroupsMenuContainer';
import Groups from 'containers/Groups/Groups';
import GroupCreate from 'containers/Groups/GroupCreate/GroupCreate';
import GroupProfileContainer from 'containers/Groups/GroupProfile/GroupProfileContainer';
import GroupFeed from 'containers/Groups/GroupProfile/GroupFeed';
import GroupShipments from 'containers/Groups/GroupProfile/GroupShipments';
import GroupShipmentCreate from 'containers/Groups/GroupShipments/ShipmentCreate';
import GroupShipmentEdit from 'containers/Groups/GroupShipments/ShipmentEdit';
import GroupTransferCreate from 'containers/Groups/GroupTransfers/TransferCreate';
import GroupTransferEdit from 'containers/Groups/GroupTransfers/TransferEdit';
import GroupTransfers from 'containers/Groups/GroupProfile/GroupTransfers';
import GroupInfo from 'containers/Groups/GroupProfile/GroupInfo';
import GroupLinkLoads from 'containers/Groups/GroupProfile/GroupLinkLoads';
import GroupScheduledLoads from 'containers/Groups/GroupProfile/GroupScheduledLoads';
import GroupFlow from 'containers/Groups/GroupProfile/GroupFlow';
// media-gallery
import TenantResources from 'containers/TenantResources/TenantResources';
// tenant resources
import MediaGallery from 'containers/MediaGallery/MediaGallery';
// admin
import Admin from 'containers/Admin/Admin';
// super admin
import SuperAdminContainer from 'containers/SuperAdmin/SuperAdminContainer';
import Tenants from 'containers/SuperAdmin/Tenants/Tenants';
import SuperAdmins from 'containers/SuperAdmin/SuperAdmins/SuperAdmins';
import SuperAdminCreate from 'containers/SuperAdmin/SuperAdmins/SuperAdminCreate';
import SuperAdminEdit from 'containers/SuperAdmin/SuperAdmins/SuperAdminEdit';
import TenantCreate from 'containers/SuperAdmin/Tenants/TenantCreate';
import TenantEdit from 'containers/SuperAdmin/Tenants/TenantEdit';
import DailyCheckupReportDeaths from './containers/DailyCheckup/DailyCheckupReportDeaths';
import DailyCheckupReportMortalityReasons from './containers/DailyCheckup/DailyCheckupReportMortalityReasons';
// notifications
import Notifications from 'containers/Notifications/Notifications';
// import
import GroupsImport from 'containers/ImportGroups/GroupsImport';
import GroupsRenameImport from 'containers/ImportGroups/GroupsRenameImport';
import ApiMonitoring from './components/ApiMonitoring/ApiMonitoring';
import PrairieSystemsHome from './components/ApiMonitoring/PrairieSystems/PrairiesSystemsHome';
import PullResultsMetaFarms from './components/ApiMonitoring/MetaFarms/PullResults/PullResults';
import PushResultsMetaFarms  from './components/ApiMonitoring/MetaFarms/PushResults/PushResults';
import PullResultsPrairiesSystem from './components/ApiMonitoring/PrairieSystems/PullResults/PullResults';
import PushResultsPrairiesSystem  from './components/ApiMonitoring/PrairieSystems/PushResults/PushResults';
import MetaHome from './components/ApiMonitoring/MetaFarms/MetaHome';
import ResultsImport from './containers/Admin/Imports/ImportTable/ResultsImport/ResultsImport';
import ResultsAllImports from './containers/Admin/Imports/ResultsAllImports/ResultsAllImports';
// Not Found page
import NotFoundRedirect from './containers/NotFoundRedirect';

const withAdmin = withProps({ isAdminRoute: true });

export const getRoutes = (store) => {
  const {
    preloadUser,
    requireAuth,
    handleRedirect,
    logoutUser,
    permissions,
  } = routesHelper(store);

  // ивент тригерится на каждом чихе роута
  // https://github.com/ReactTraining/react-router/issues/3554
  const onRouteChange = () => {
    if (store.getState().mediaViewer.isOpen) {
      store.dispatch(closeViewer());
    }
  };

  return (
    <Route path="/" component={Container} onEnter={preloadUser} onChange={onRouteChange}>
      <IndexRedirect to="/farmfeed" />

      <Route onEnter={permissions('admin')}>
        <Route path="import-groups" component={GroupsImport} />
        <Route path="rename-groups" component={GroupsRenameImport} />
      </Route>

      <Route path="switch-user-auth" component={SwitchUserAuth} />

      {/* MAIN LAYOUT */}
      <Route component={MainLayout} onEnter={requireAuth}>

        {/* MY SCHEDULE (AVAILABLE ONLY AT LOGISTICS MODE) */}
        <Route path="my-schedule" onEnter={permissions('my-schedule')}>
          <IndexRoute component={MySchedule} />
          <Route path=":id" component={CommitmentPageContainer}>
            <IndexRedirect to="load-info" />
            <Route path="load-info" component={CommitmentProfile} />
            <Route path="health" component={CommitmentHealthInfo} />
            <Route path="sales-results" component={SalesResults} />
          </Route>
        </Route>

        {/* DAILY CHECKUPS */}
        <Route path="daily-checkup">
          <IndexRoute onEnter={permissions('full-access')} component={DailyCheckupHome} />
          <Route onEnter={permissions('full-access')} path="farms/:id" component={DailyCheckupFarmGroups} />
          <Route
            onEnter={permissions('full-access')}
            path="groups/:id/create-shipment"
            component={DailyCheckupStartGroupSwitcher}
          />
          <Route path=":id(/schedule)(/group-info)(/treatments)" component={DailyCheckupContainer}>
            <IndexRoute component={DailyCheckupEdit} />
            <Route
              path="report-deaths"
              component={(props) => (
                store.getState().auth.user.current_company.track_mortality_reasons
                  ? <DailyCheckupReportMortalityReasons {...props} />
                  : <DailyCheckupReportDeaths {...props} />
              )}
            />

            <Route path="report-treatments" component={DailyCheckupReportTreatments} />
            <Route path="report-treatment/:treatmentStepId" component={DailyCheckupReportProtocolTreatment} />
            <Route path="report-symptoms" component={DailyCheckupReportSymptoms} />
            <Route path="pig-movements" component={DailyCheckupReportMovements} />
            <Route path="load-movement/:commitmentId" component={DailyCheckupLoadMovement} />
            <Route path="report-temps" component={DailyCheckupReportTemps} />
            <Route path="report-water-usage" component={DailyCheckupReportWaterUsage} />
            <Route path="record-audio" component={AudioRecorder} />
            <Route path="note" component={DailyCheckupNote} />
            <Route path="media" component={DailyCheckupMedia} />
          </Route>
        </Route>

        {/* TREATMENT PROTOCOLS */}
        <Route onEnter={permissions('treatment_protocols')} component={TreatmentProtocolsContainer}>
          {/* GROUPS */}
          <Route path="treatment-protocols-groups">
            <IndexRoute component={OutOfComplianceGroups} />
            <Route path=":groupId" component={GroupTreatmentProtocolsSteps} />
          </Route>

          {/* FARMS */}
          <Route path="treatment-protocols-farms">
            <IndexRoute component={FarmsSetUp} />

            {/* FARM PROTOCOLS */}
            <Route path=":farmId" component={TreatmentProtocolsFarm}>
              <Route path="treatment-protocols">
                <IndexRoute component={FarmTreatmentProtocols} />
                <Route path="create" component={CreateFarmTreatmentProtocol} />
                <Route path=":protocolId" component={FarmTreatmentProtocolProfile} />
                <Route path=":protocolId/edit" component={EditFarmTreatmentProtocol} />
              </Route>
              <Route path="groups" component={TreatmentProtocolFarmGroups} />
              <IndexRedirect to="/treatment-protocols" />
            </Route>

            {/* GROUP PROTOCOLS */}
            <Route path=":farmId/groups/:groupId" component={TreatmentProtocolsGroup}>
              <Route path="treatment-protocols">
                <IndexRoute component={GroupTreatmentProtocols} />
                <Route path="create" component={CreateGroupTreatmentProtocol} />
                <Route path=":protocolId" component={GroupTreatmentProtocolProfile} />
                <Route path=":protocolId/edit" component={EditGroupTreatmentProtocol} />
              </Route>
              <IndexRedirect to="/treatment-protocols" />
            </Route>
          </Route>

          {/* COMPANY PROTOCOLS */}
          <Route path="treatment-protocols">
            <IndexRoute component={TreatmentProtocols} />
            <Route path="create" component={CreateTreatmentProtocol} />
            <Route path=":protocolId" component={TreatmentProtocolProfile} />
            <Route path=":protocolId/edit" component={EditTreatmentProtocol} />
          </Route>
        </Route>

        {/* VIRTUAL VISIT */}
        <Route path="virtual-visits" onEnter={permissions('virtual_visits')}>
          <IndexRoute component={VirtualVisits} />
          <Route path="create" component={VirtualVisitCreate} />
          <Route path=":id" component={VirtualVisitView} />
          <Route path=":id/edit" component={VirtualVisitUpdate} />
        </Route>

        {/* SITE VISIT REPORTS */}
        <Route path="/report-farms" onEnter={permissions('svr')}>
          <IndexRoute component={ReportFarms} />
          <Route path=":farmId">
            <Route path="report-templates">
              <IndexRoute component={FarmReportTemplates} />
              <Route path=":templateId/reports" component={FarmReports} />
              <Route path=":templateId/reports/:svrId" component={Report} />
              <Route path=":templateId/reports/:svrId/review" component={ReportReview} />
            </Route>
          </Route>
        </Route>

        {/* FARM FEED */}
        <Route onEnter={permissions('farmfeed')} path="farmfeed/flagged" component={FlaggedFarmFeed} />
        <Route onEnter={permissions('farmfeed')} path="farmfeed">
          <IndexRoute component={FarmFeedContainer} />
          <Route path="activities/:id" component={ActivityShow} />
        </Route>

        {/* INSIGHTS */}
        <Route onEnter={permissions('full-access')} component={Insights}>
          <Route path="analytics" component={Analytics} onEnter={permissions('analytics')} />
          <Route path="auto-report" component={AutoReport} />
          <Route path="asset-map" component={AssetMap} />
          <Route path="treatments" component={Treatments} onEnter={permissions('admin')}>
            <IndexRedirect to="antibiotic" />
            <Route path="antibiotic" component={TreatmentAntibiotic} />
            <Route path="feedback">
              <IndexRoute component={TreatmentFeedbackHome} />
              <Route path=":id" component={TreatmentFeedbackMedication} />
              <Route path="groups/:id" component={TreatmentFeedbackGroup} />
              <Route path="efficacy/:id" component={EfficacyFeed} />
            </Route>
          </Route>
          <Route path="checkins" component={Checkins}>
            <IndexRedirect to="users" />
            <Route path="users">
              <IndexRoute component={UsersCheckins} />
              <Route path="map" component={UsersCheckins} />
              <Route path=":userId(/map)" component={UserCheckins} />
            </Route>
            <Route path="farms">
              <IndexRoute component={FarmsCheckins} />
              <Route path=":farmId" component={FarmCheckins} />
            </Route>
            <Route path="new" component={MobileCheckin} />
            <Route path=":id" component={CheckInMap} />
          </Route>
        </Route>
        {/* API MONITORING */}
        <Route path="monitoring" component={ApiMonitoring}>
          <IndexRedirect to="metafarms/pushresults" />
          <Route path="metafarms" component={MetaHome}>
            <IndexRedirect to="pushresults" />
            <Route path="pushresults" component={PushResultsMetaFarms}  />
            <Route path="pullresults" component={PullResultsMetaFarms}  />
          </Route>
          <Route path="prairiesystems" component={PrairieSystemsHome}>
            <IndexRedirect to="pushresults" />
            <Route path="pushresults" component={PushResultsPrairiesSystem}  />
            <Route path="pullresults" component={PullResultsPrairiesSystem}  />
          </Route>
          <Route path="integration" component={PushResultsMetaFarms} />
        </Route>

        {/* BARN SHEETS */}
        <Route path="barnsheets">
          <IndexRedirect to="groups" />
          <Route path="groups" component={BarnSheetsGroups} />
          <Route path="farms" component={BarnSheetsFarms} />
          <Route path="companies" component={BarnSheetsCompanies} />
        </Route>
        <Route path="barnsheets/daily-checkup/:id" component={BarnsheetsDailyCheckup} />
        <Route path="barnsheets/companies/:companyId">
          <IndexRedirect to="farms" />
          <Route path="farms" component={BarnsheetsCompanyFarms} />
          <Route path="farms/:farmId" component={BarnSheetsFarmProfile}>
            <IndexRedirect to="groups" />
            <Route path="groups" component={BarnSheetsFarmGroups} />
            <Route path="treatments">
              <IndexRedirect to="history" />
              <Route path="history" component={BarnsheetsFarmTreatments} />
              <Route path="antibiotic" component={BarnsheetsFarmTreatmentsAntibiotic} onEnter={permissions('admin')} />
            </Route>
            <Route path="diagnoses" component={BarnsheetsFarmDiagnoses} />
            <Route path="pig-movements" component={BarnSheetsFarmPigMovements} />
            <Route path="media" component={BarnsheetsMedia} />
            <Route path="reports" component={BarnSheetsReports} />
          </Route>
        </Route>
        <Route path="barnsheets/farms/:farmId" component={BarnSheetsFarmProfile}>
          <IndexRedirect to="groups" />
          <Route path="groups" component={BarnSheetsFarmGroups} />
          <Route path="treatments">
            <IndexRedirect to="history" />
            <Route path="history" component={BarnsheetsFarmTreatments} />
            <Route path="antibiotic" component={BarnsheetsFarmTreatmentsAntibiotic} onEnter={permissions('admin')} />
          </Route>
          <Route path="diagnoses" component={BarnsheetsFarmDiagnoses} />
          <Route path="pig-movements" component={BarnSheetsFarmPigMovements} />
          <Route path="media" component={BarnsheetsMedia} />
          <Route path="reports" component={BarnSheetsReports} />
        </Route>
        <Route path="barnsheets/groups/:id" component={BarnSheetsGroupProfile}>
          <IndexRoute component={BarnsheetsGroupCheckups} />
          <Route path="treatments">
            <IndexRedirect to="history" />
            <Route path="history" component={BarnSheetsGroupTreatmentsHistory} />
            <Route path="antibiotic" component={BarnSheetsGroupTreatmentsAntibiotic} onEnter={permissions('admin')} />
          </Route>
          <Route path="water-usage" component={BarnsheetsWaterUsage} onEnter={permissions('water-usage')} />
          <Route path="diagnoses" component={BarnsheetsGroupDiagnoses} />
          <Route path="pig-movements" component={BarnSheetsGroupPigMovements} />
          <Route path="sales-results" component={BarnSheetsGroupSalesResults} />
          <Route path="pig_group_diagnoses" component={BarnsheetsGroupPigGroupDiagnoses} />
          <Route path="media" component={BarnsheetsMedia} />
          <Route path="flow" component={(props) => <GroupFlow {...props} isBarnSheets />} />
          <Route
            path="treatment-protocols"
            component={(props) => <BarnSheetsTreatmentProtocols {...props} />}
            onEnter={permissions('treatment_protocols')}
          />
        </Route>

        {/* INVENTORY ADMIN */}
        <Route onEnter={permissions('full-access')} path="admin/inventory">
          <IndexRoute component={withAdmin(Inventory)} />
        </Route>

        {/* INVENTORY CAREGIVER */}
        <Route onEnter={permissions('full-access')} path="inventory">
          <IndexRoute component={Inventory} />
        </Route>

        <Route component={GroupsMenuContainer} onEnter={permissions('admin')}>

          {/* GROUPS */}
          <Route path="groups">
            <IndexRoute component={Groups} />
            <Route path="create" component={GroupCreate} />
            <Route path=":id" component={GroupProfileContainer}>
              <IndexRedirect to="groupfeed" />
              <Route path="groupfeed" component={GroupFeed} />
              <Route path="shipments" component={GroupShipments} />
              <Route path="transfers" component={GroupTransfers} />
              <Route path="edit" component={GroupInfo} />
              <Route path="link-loads" component={GroupLinkLoads} />
              <Route path="scheduled-loads" component={GroupScheduledLoads} />
              <Route path="flow" component={GroupFlow} />
            </Route>
            <Route path=":id/shipments">
              <Route path="create" component={GroupShipmentCreate} />
              <Route path=":migrationId/edit" component={GroupShipmentEdit} />
            </Route>
            <Route path=":id/transfers">
              <Route path="create" component={GroupTransferCreate} />
              <Route path=":migrationId/edit" component={GroupTransferEdit} />
            </Route>
          </Route>

          {/* SOURCES */}
          <Route path="sources">
            <IndexRoute component={Sources} />
            <Route path="create" component={SourceCreate} />
            <Route path=":id" component={SourceProfileContainer}>
              <IndexRedirect to="overview" />
              <Route path="overview" component={SourceOverview} />
              <Route path="groups" component={SourceGroups} />
              <Route path="treatment-feedback" component={SourceTreatmentFeedback} />
              <Route path="edit" component={SourceEdit} />
            </Route>
          </Route>

          {/* DESTINATIONS */}
          <Route path="destinations">
            <IndexRoute component={Destinations} />
            <Route path=":id/edit" component={DestinationEdit} />
            <Route path="create" component={DestinationCreate} />
          </Route>
        </Route>

        {/* MEDIA GALLERY */}
        <Route onEnter={permissions('full-access')} path="media-gallery" component={MediaGallery} />

        {/* MY PROFILE */}
        <Route path="profile" component={MyProfile}>
          <IndexRedirect to="edit" />
          <Route path="edit" component={MyProfileEdit} />
          <Route path="farm-roles" component={MyProfileFarmRoles} />
          <Route path="company-roles" component={MyProfileCompanyRoles} />
          <Route path="admin-roles" component={MyProfileAdminRoles} />
          <Route onEnter={permissions('messaging')} path="messaging" component={MyProfileMessaging} />
          <Route path="preferences" component={MyProfilePreferences} />
        </Route>

        {/* SUPER ADMIN ROUTES */}
        <Route path="super-admin" component={SuperAdminContainer} onEnter={permissions('super-admin')}>
          <IndexRedirect to="tenants" />
          <Route path="tenants">
            <IndexRoute component={Tenants} />
            <Route path="create" component={TenantCreate} />
            <Route path=":id/edit" component={TenantEdit} />
          </Route>
          <Route path="admin-users">
            <IndexRoute component={SuperAdmins} />
            <Route path="create" component={SuperAdminCreate} />
            <Route path=":id/edit" component={SuperAdminEdit} />
          </Route>
        </Route>

        {/* TENANT RESOURCES */}
        <Route onEnter={permissions('full-access')} path="tenant-assets" component={TenantResources} />

        {/* IMPORTS ROUTES */}
        <Route onEnter={permissions('full-access')} path="imports" components={AdminImports} />
        <Route onEnter={permissions('full-access')} components={ResultsImport} path="/imports/:id/:nameType" />
        <Route onEnter={permissions('full-access')} components={ResultsAllImports} path="/imports/choose/:name/:type" />

        {/* ADMIN ROUTES */}
        <Route path="admin" onEnter={permissions('admin')}>
          <IndexRedirect to="users" />
          <Route component={Admin}>
            <Route path="users">
              <IndexRoute component={AdminUsers} />
              <Route path="create" component={CreateUser} />
              <Route path=":id" component={AdminUserProfile}>
                <IndexRedirect to="farm-roles" />
                <Route path="edit" component={AdminUserProfileEdit} />
                <Route path="farm-roles" component={AdminUserFarmRoles} />
                <Route path="company-roles" component={AdminUserCompanyRoles} />
                <Route path="trucking-roles" component={AdminUserTruckingRoles} />
              </Route>
              <Route path=":id/assign-farm-roles" component={UserFarmsRolesManage} />
              <Route path=":id/assign-company-roles" component={UserCompanyRolesManage} />
              <Route path=":id/assign-trucking-roles" component={UserTruckingRolesManage} />
            </Route>
            <Route path="farms">
              <IndexRoute component={AdminFarms} />
              <Route path="create" component={CreateFarm} />
              <Route path=":id" component={FarmProfile}>
                <IndexRedirect to="user-roles" />
                <Route path="user-roles" component={FarmUserRoles} />
                <Route path="health-variables" component={FarmHealthVariables} />
                <Route onEnter={permissions('scheduling')} path="scheduling" component={FarmScheduling} />

                <Route path="edit" component={FarmEdit} />
                <Route path="barns">
                  <IndexRoute component={FarmBarns} />
                  <Route path="create" component={FarmBarnCreate} />
                  <Route path=":barnId" component={FarmBarnEdit} />
                </Route>
              </Route>
              <Route path=":id/assign-user-roles" component={FarmUserRolesManage} />
            </Route>

            <Route component={CompaniesContainer}>
              <Route path="companies" component={AdminCompanies} />
              <Route path="trucking-companies" component={TruckingCompanies} onEnter={permissions('logistics')} />
              <Route path="packing-companies" component={PackingCompanies} onEnter={permissions('logistics')} />
            </Route>

            <Route path="companies/create" component={CreateCompany} />
            <Route path="companies/:id" component={CompanyProfile}>
              <IndexRedirect to="user-roles" />
              <Route path="user-roles" component={CompanyUsersRoles} type="companyUserRoles" />
              <Route path="managed-farms" component={CompanyFarmRoles} type="companyFarms" />
              <Route path="owned-groups" component={CompanyGroups} type="companyGroups" />
              <Route path="edit" component={CompanyEdit} />
            </Route>
            <Route
              path="companies/:id/assign-user-roles"
              component={CompanyUsersRolesManage}
              type="companyUserRolesManage"
            />
            <Route path="companies/:id/assign-farms" component={CompanyFarmsManage} type="companyFarmsManage" />

            <Route
              path="trucking-companies/create"
              component={TruckingCompanyCreate}
              onEnter={permissions('logistics')}
            />
            <Route
              path="trucking-companies/:id/assign-user-roles"
              component={TruckingUsersRolesManage}
            />
            <Route path="trucking-companies/:id" onEnter={permissions('logistics')} component={TruckingCompanyProfile}>
              <IndexRedirect to="user-roles" />
              <Route path="user-roles" component={TruckingCompanyUserRoles} />
              <Route path="edit" component={TruckingCompanyEdit} />
            </Route>

            <Route
              path="packing-companies/create"
              component={PackingCompanyCreate}
              onEnter={permissions('logistics')}
            />
            <Route path="packing-companies/:id" component={PackingCompanyProfile} onEnter={permissions('logistics')}>
              <IndexRedirect to="packing-plants" />
              <Route path="packing-plants" component={PackingPlants} />
              <Route path="edit" component={PackingCompanyEdit} />
            </Route>
            <Route
              path="packing-companies/:id/packing-plants/create"
              component={PackingPlantCreate}
              onEnter={permissions('logistics')}
            />
            <Route path="packing-companies/:id/packing-plants/:plantId" component={PackingPlant}>
              <IndexRedirect to="edit" />
              <Route path="edit" component={PackingPlantEdit} />
              {/* possible routes: loads,activity,contracts */}
            </Route>
            <Route path="shareholder-groups">
              <IndexRoute component={AdminShareholderGroups} />
              <Route path="create" component={ShareholderGroupsCreate} />
              <Route path=":id" component={ShareholderGroupProfile}>
                <IndexRedirect to="farm-roles" />
                <Route path="farm-roles" component={ShareholderGroupFarmRoles} />
                <Route path="edit" component={ShareholderGroupsEdit} />
              </Route>
              <Route path=":id/assign-farms" component={ShareholderGroupsFarmsManage} />
            </Route>
            <Route path="my-company">
              <IndexRedirect to="admins" />
              <Route component={MyCompanyProfile}>
                <Route path="user-roles" component={CompanyUsersRoles} type="myCompanyUserRoles" />
                <Route path="managed-farms" component={CompanyFarmRoles} type="myCompanyFarms" />
                <Route path="edit" component={MyCompanyEdit} />
                <Route path="admins" component={MyCompanyAdminRoles} />
                <Route path="owned-groups" component={CompanyGroups} type="myCompanyGroups" />
              </Route>
              <Route path="assign-user-roles" component={CompanyUsersRolesManage} type="myCompanyUserRolesManage" />
              <Route path="assign-farms" component={CompanyFarmsManage} type="myCompanyFarmsManage" />
              <Route path="admins/create" component={MyCompanyAdminCreate} />
              <Route path="admins/:id/edit" component={MyCompanyAdminEdit} />
            </Route>
            <Route path="health-variables">
              <IndexRedirect to="medications" />
              <Route component={HealthVariables}>
                <Route path="medications" component={Medications} />
                <Route path="diseases" component={Diseases} />
                <Route path="symptoms" component={Symptoms} />
                <Route path="death-alerts" component={DeathAlerts} />
                <Route path="mortality-reasons" component={MortalityReasons} />
                <Route path="setpoints" component={SetPoints} />
                <Route path="genetics" component={Genetics} onEnter={permissions('logistics')} />
              </Route>
              <Route path="medications/:id" component={MedicationProfile}>
                <IndexRedirect to="information" />
                <Route path="information" component={MedicationInfo} />
                <Route path="settings" component={MedicationSettings} />
              </Route>
              <Route path="genetics/:id" component={GeneticProfile} onEnter={permissions('logistics')}>
                <IndexRedirect to="sourced-groups" />
                <Route path="sourced-groups" component={GeneticGroups} />
                <Route path="info" component={GeneticInfo} />
              </Route>
              <Route path="diseases/:id/edit" component={UpdateDisease} />
              <Route path="symptoms/:id/edit" component={SymptomUpdate} />
              <Route path="mortality-reasons/:id/edit" component={UpdateMortalityReason} />
              <Route path="mortality-reasons/create" component={CreateMortalityReason} />
            </Route>
            <Route onEnter={permissions('report-builder')} path="report-builder">
              <IndexRoute component={ReportBuilder} />
              <Route path="create" component={ReportTemplateCreate} />
              <Route path=":id" component={ReportTemplateEdit} />
            </Route>
            <Route path="preferences">
              <IndexRedirect to="settings" />
              <Route component={Preferences}>
                <Route path="settings" component={(props) => <Settings {...props} />} />
                <Route path="daily-checkup" component={(props) => <DailyCheckup {...props} />} />
                <Route path="integrations" onEnter={permissions('integrations')} component={Integrations}>
                  <IndexRedirect to="meta-farms" />
                  <Route path="meta-farms" component={MetaFarmsIntegration} />
                  <Route path="prairie-systems" component={PrairieSystemsIntegration} />
                  <Route path="distynct" component={DistynctIntegration} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="switch-company/:id" component={CompanySwitcher} />

        <Route path="notifications" onEnter={permissions('notifications')} component={Notifications} />
      </Route>

      {/* CSV Import */}
      <Route component={CSVImportContainer} path="csv-import">
        <IndexRedirect to="select-mode" />
        <Route path="select-mode" component={CSVImportSelectMode} />
        <Route path="upload" component={CSVImportUpload} />
        <Route path="match-columns" component={CSVImportMapColumns} />
        <Route path="results" component={CSVImportResults} />
      </Route>
      <Route path="/imports/templates" components={Templates} />
      <Route path="/imports/choose-import" components={ChoseImport} />

      {/* DEFAULT LAYOUT */}
      <Route component={DefaultLayout} onEnter={handleRedirect}>
        {/* AUTHENTICATION */}
        <Route path="login" component={Login} />
        <Route path="logout" onEnter={logoutUser} />
        <Route path="reset-password" component={ResetPassword} />
        <Route path="set-password" component={SetPassword} />
        <Route path="set-password-message" component={SetPasswordMessage} />
        <Route path="invitations">
          <Route path=":token" component={InvitationsContainer}>
            <IndexRoute component={AcceptInvite} />
            <Route path="set-password" component={CompleteRegistrationPassword} />
          </Route>
          <Route path=":id" component={InvitationsContainer}>
            <Route path="set-language" component={CompleteRegistrationLanguage} />
            <Route path="complete-registration" component={CompleteRegistrationInfo} />
          </Route>
        </Route>
      </Route>

      <Route path="*" component={NotFoundRedirect} />
    </Route>
  );
};
