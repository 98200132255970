import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import {
  updateCheckupNote as updateCheckupNoteAction,
  uploadAssetComplete as uploadAssetCompleteAction,
  deleteAsset as deleteAssetAction,
  submitCheckup as submitCheckupAction,
  fetchCheckupLogs,
} from 'reducers/dailyCheckup/checkupEdit';
import { openViewer } from 'reducers/mediaViewer';
import { openModal } from 'reducers/modals';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Spacer from 'components/Spacer';
import StickyFooter from 'components/StickyFooter';
import MediaUploader from 'components/MediaUploader';
import PigsUnderCareLine from 'components/PigsUnderCareLine/PigsUnderCareLine';
import ReportMovementsSegment from 'components/DailyCheckups/ReportMovementsSegment';
import ReportDeathSegment from 'components/DailyCheckups/ReportDeathSegment';
import ReportTreatmentsSegment from 'components/DailyCheckups/ReportTreatmentsSegment';
import ReportSymptomsSegment from 'components/DailyCheckups/ReportSymptomsSegment';
import ReportSurveySegment from 'components/DailyCheckups/ReportSurveySegment';
import ReportTempsSegment from 'components/DailyCheckups/ReportTempsSegment';
import ReportWaterUsageSegment from 'components/DailyCheckups/ReportWaterUsageSegment';
import ReportTreatmentsEfficacySegment from 'components/DailyCheckups/ReportTreatmentsEfficacySegment';
import ReportSVRQuestionAnswerSegment from 'components/DailyCheckups/ReportSVRQuestionAnswerSegment';
import VetBotAlertModal from 'components/DailyCheckups/modals/VetBotAlert/VetBotAlertModal';
import VetBotPhotoUpload from 'components/DailyCheckups/modals/VetBotPhotoUpload/VetBotPhotoUpload';
import SubmitCheckupModal from 'components/DailyCheckups/modals/SubmitCheckupModal/SubmitCheckupModal';
import OfflineSubmitAlert from 'components/DailyCheckups/modals/OfflineSubmitAlert/OfflineSubmitAlert';
import CommentBox from 'components/CommentBox';
import CheckupLogs from 'components/DailyCheckups/CheckupLogs/CheckupLogs';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import PlusMoreHint from 'components/PlusMoreHint';
import PigsUnderTreatmentLine from 'components/PigsUnderTreatmentLine';
// utils
import isEmpty from 'lodash.isempty';
import isNull from 'lodash.isnull';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { isAudioRecordSupported } from 'utils';
import { isIOS, isSafari } from 'react-device-detect';
import cn from 'classnames';
import { formatCheckupDate } from 'utils/checkupHelper';
import { getFarmOffline, getPigGroupByCheckup } from 'utils/offlineHelper';
import { isUserExactlyCaregiver } from 'utils/rolesHelper';
// assets
import './PigGroupDailyCheckup.scss';
// constants
import { comment_roles } from 'constants.js';
import { mediaExtensions } from 'components/MediaUploader/MediaUploader';

const { CHECKUP_ROLE } = comment_roles;

class PigGroupDailyCheckup extends Component {

  state = {
    comment: '',
    offlinePigGroup: {},
    mentionsIds: [],
  };

  componentDidMount() {
    const { checkup, fetchCheckupLogs, isEditMode, isOnline, checkupId, setBreadcrumbs } = this.props;
    this.setCheckupData(checkup, isEditMode);
    fetchCheckupLogs();
    if (!isOnline) {
      getPigGroupByCheckup(checkupId)
        .then((groupList) => {
          const currentGroup = groupList[0];
          this.setState({ offlinePigGroup: currentGroup });
          getFarmOffline(currentGroup.farm_id).then((farm) => {
            setBreadcrumbs([
              { label: <FormattedMessage id="general.pageTitle.checkups" />, path: '/daily-checkup' },
              { label: farm.name, path: `/daily-checkup/farms/${farm.id}` },
              { label: currentGroup.name, useLabelAsMobileTitle: true },
            ]);
          });
        });
    }
  }

  componentDidUpdate({ checkup: prevCheckup }) {
    const { checkup, isEditMode } = this.props;
    if (prevCheckup.id !== checkup.id) this.setCheckupData(checkup, isEditMode);
  }

  setCheckupData = (checkup, isEditMode) => {
    const { comment, mentioned_user_ids } = checkup?.comments?.find(({ role }) => role === CHECKUP_ROLE) || {};
    this.setState({ comment: comment || '', mentionsIds: mentioned_user_ids || [] });
    this.checkVetBotAlerts(checkup);
    this.setPageOptions(checkup, isEditMode);
  };

  setPageOptions = (checkup, isEditMode) => {
    const { setPageOptions } = this.props;
    if (!isEmpty(checkup)) {
      setPageOptions({
        backLink: '/daily-checkup',
        inEditMode: isEditMode,
      });
    }
  };

  checkVetBotAlerts = (checkup) => {
    const alerts = checkup?.alerts || [];
    if (alerts.length === 0) return;

    const poorConditionAlerts = alerts.find(({ active, reason }) => (active && reason === 'poor_condition'));
    if (poorConditionAlerts) {
      this.processPoorConditionAlerts(checkup, poorConditionAlerts);
    }

    if (!isAudioRecordSupported()) return;
    const coughAlerts = alerts.find(({ active, reason }) => (active && reason === 'cough'));

    if (coughAlerts) {
      this.processCoughAlerts(checkup, coughAlerts);
    }
  };

  processPoorConditionAlerts(checkup, alert) {
    // TODO pig_migrations [...] condition == poor  && alert = true && no image yet
    const { openModal } = this.props;
    const poorMigration = checkup.pig_migrations?.find(({ condition }) => (condition === 'poor'));
    if (!isEmpty(poorMigration)) {
      openModal(
        <VetBotPhotoUpload
          checkup={checkup}
          alert={alert}
          onClose={this.processAlerts}
        />,
      );
    }
  }

  processCoughAlerts = (checkup, alert) => {
    const { openModal } = this.props;
    openModal(
      <VetBotAlertModal
        checkup={checkup}
        alert={alert}
        onClose={this.processAlerts}
      />,
    );
  };

  deleteAsset = (assetId) => {
    const { deleteAsset, checkup, validateCheckup } = this.props;
    if (!validateCheckup(checkup)) return;
    deleteAsset(assetId).catch(toastResponseErrors);
  };

  submitCheckup = async () => {
    const { router } = this.context;
    const { comment, mentionsIds } = this.state;
    const { openModal, checkup, validateCheckup, submitCheckup, updateCheckupNote, farm, isOnline } = this.props;
    if (!validateCheckup(checkup)) return;
    await updateCheckupNote({ resource: { comments_checkup: { text: comment, mentioned_user_ids: mentionsIds } } });
    submitCheckup()
      .then(() => {
        const groupsCount = (farm?.open_pig_groups_count || 0) + (farm?.pending_pig_groups_count || 0);
        openModal(isOnline ? <SubmitCheckupModal /> : <OfflineSubmitAlert />);
        if (groupsCount === 1) {
          router.push('/daily-checkup');
        } else {
          router.push(`/daily-checkup/farms/${checkup.pig_group.farm_id || checkup.farm_id}`);
        }
      })
      .catch(toastResponseErrors);
  };

  updateCheckup = () => {
    const { router } = this.context;
    const { comment, mentionsIds } = this.state;
    const { updateCheckupNote, checkup, validateCheckup, openModal } = this.props;
    if (!validateCheckup(checkup)) return;

    const { comment: oldComment } = checkup?.comments?.find(({ role }) => role === CHECKUP_ROLE) || {};
    const isCommentEdited = (oldComment || '') !== (comment || '');

    if (isCommentEdited) {
      updateCheckupNote({ resource: { comments_checkup: { text: comment, mentioned_user_ids: mentionsIds } } });
    }
    openModal(<SubmitCheckupModal isUpdate />);
    router.push(`/daily-checkup/farms/${checkup.pig_group.farm_id}`);
  };

  isAllReported = () => {
    const { checkup, trackTemperature, trackWaterUsage, hideMovements } = this.props;
    if (!checkup) return false;
    const { acute_death_count, chronic_death_count, euthanasia_death_count, symptom_count, treatment_count,
      pig_migration_count, temperature_reported, water_usage_reported, edge_water_usage,
      edge_temperature, maximus_temperature, maximus_water_usage, dicam_temperature, dicam_water_usage,
      barn_tools_water_usage, barn_tools_temperature } = checkup;
    const isDeathReported = !isNull(acute_death_count) || !isNull(chronic_death_count)
      || !isNull(euthanasia_death_count);
    const isMovementsReported = hideMovements || (pig_migration_count !== null);
    const isTemperatureReported = Boolean(
      !trackTemperature ||
      temperature_reported ||
      edge_temperature ||
      maximus_temperature ||
      dicam_temperature ||
      barn_tools_temperature
    );
    const isWaterUsageReported = Boolean(
      !trackWaterUsage ||
      water_usage_reported ||
      edge_water_usage ||
      maximus_water_usage ||
      dicam_water_usage ||
      barn_tools_water_usage
    );

    return isDeathReported         // deaths reported
      && !isNull(symptom_count)    // symptoms reported
      && !isNull(treatment_count)  // treatments reported
      && isMovementsReported       // migrations reported
      && isTemperatureReported     // temperatures reported
      && isWaterUsageReported;     // water usage reported
  };

  render() {
    const { checkup, isLoading, uploadAssetComplete, openMediaViewer, checkupId, validateCheckup, logs, isOnline,
      trackTemperature, trackWaterUsage, measurementSystem, farm, current_company, hideMovements
    } = this.props;
    const groupBarns = checkup?.pig_group?.barns || [];
    const checkupLogs = isOnline ? logs : [];
    const isLogsExisted = !!checkupLogs.length;
    const { comment, offlinePigGroup, mentionsIds } = this.state;
    const isReadyToSubmit = this.isAllReported();
    const isSubmitted = !isEmpty(checkup) && checkup.submitted;
    const farmUtcOffset = checkup?.pig_group?.farm?.utc_offset || farm?.utc_offset;
    const { media_upload } = current_company;

    return (
      <div className="PigGroupDailyCheckup">
        <section className={cn('checkup-body', { 'pb-100': isIOS || isSafari })}>
          <div className="row centered">
            <div className="small-12 column">
              <Panel>
                <Panel.Heading>
                  <div className="checkup-date">
                    <h2 className="lighter">{formatCheckupDate(checkup.created_on, farmUtcOffset)}</h2>
                  </div>
                </Panel.Heading>

                {!!groupBarns.length && (
                  <div className="group-location">
                    <FormattedMessage
                      id="general.groupLocationWithBarns"
                      values={{
                        barns: <b><PlusMoreHint items={groupBarns} /></b>
                      }}
                    />
                  </div>
                )}
                <PigsUnderCareLine className="show-for-large" pigsCount={checkup.pigs || offlinePigGroup.pigs} mobile />
                {checkup.abf_tracking && (
                  <PigsUnderTreatmentLine className="show-for-large" pigsCount={checkup.treated_pigs || 0} mobile />
                )}

                <Panel.Body noPadding className={cn('ph-20 pt-20', { 'pb-20': !isLogsExisted })}>
                  <Preloader isActive={isLoading} />
                  {isEmpty(checkup) && !isOnline && (
                    <div className="offline-no-checkup-warning">
                      <i className="fa fa-info-circle attention-icon" />
                      <h1 className="message">
                        <FormattedMessage id="container.checkupEdit.wasSubmitted" />
                      </h1>
                    </div>
                  )}
                  {!isEmpty(checkup) && (
                    <div className="row">
                      <div className="medium-12 xlarge-8 column">
                        {checkup.water_usage_diff_overflow && (
                          <div className="alert-container">
                            <FormattedMessage
                              id="component.waterUsageWarning.text"
                              values={{
                                value: checkup.water_usage_diff_percentage > 0 ?
                                  `+${checkup.water_usage_diff_percentage}` :
                                  checkup.water_usage_diff_percentage,
                                b: (msg) => <b>{msg}</b>,
                                orange: (msg) => <span style={{ color: '#ffb200' }}>{msg}</span>
                              }}
                            />
                          </div>
                        )}
                        {!hideMovements && (
                          <ReportMovementsSegment
                            validateCheckup={validateCheckup}
                            isOnline={isOnline}
                            checkup={checkup}
                            paramsCheckupId={checkupId}
                          />
                        )}
                        <ReportDeathSegment
                          validateCheckup={validateCheckup}
                          checkup={checkup}
                          paramsCheckupId={checkupId}
                        />

                        <ReportTreatmentsSegment
                          validateCheckup={validateCheckup}
                          checkup={checkup}
                          paramsCheckupId={checkupId}
                        />

                        <ReportSymptomsSegment
                          validateCheckup={validateCheckup}
                          checkup={checkup}
                          paramsCheckupId={checkupId}
                        />

                        {trackTemperature && (
                          <ReportTempsSegment
                            validateCheckup={validateCheckup}
                            checkup={checkup}
                            paramsCheckupId={checkupId}
                          />
                        )}

                        {trackWaterUsage && (
                          <ReportWaterUsageSegment
                            validateCheckup={validateCheckup}
                            checkup={checkup}
                            paramsCheckupId={checkupId}
                            measurementSystem={measurementSystem}
                          />
                        )}

                        {/* other sections */}
                        <ReportTreatmentsEfficacySegment checkup={checkup} />

                        {/* custom surveys */}
                        {isOnline && checkup.daily_checkup_surveys?.map((survey) => (
                          <ReportSurveySegment
                            key={survey.id}
                            checkup={checkup}
                            validateCheckup={validateCheckup}
                            survey={survey}
                            paramsCheckupId={checkupId}
                          />
                        ))}

                        {/* site-visit-reports */}
                        {isOnline && checkup.svrs?.map((svr) => [
                          svr.questions.map((question) => (
                            <ReportSVRQuestionAnswerSegment
                              key={svr.id + '-' + question.id}
                              checkup={checkup}
                              paramsCheckupId={checkupId}
                              svrId={svr.id}
                              question={question}
                              validateCheckup={validateCheckup}
                            />
                          ))
                        ])}

                        <CommentBox
                          hasMentions
                          value={comment || ''}
                          isOpen={!!comment}
                          className="no-margin"
                          disabledIds={mentionsIds}
                          entityId={checkup.pig_group.farm_id}
                          entityType="farm"
                          onValueChange={(comment) => this.setState((prevState) => ({ ...prevState, comment }))}
                          onMentionsChange={(mentions) => {
                            this.setState((prevState) => ({ ...prevState, mentionsIds: mentions.map((i) => i.id) }));
                          }}
                          placeholder=""
                          useSpeechToText
                        />
                      </div>
                      <div className="position-relative medium-12 xlarge-4 column">
                        <Spacer className="hide-for-xlarge" />
                        {media_upload && (
                        <MediaUploader
                          paramsCheckupId={checkupId}
                          checkup={checkup}
                          uploadAssetComplete={uploadAssetComplete}
                          deleteAsset={this.deleteAsset}
                          openViewer={openMediaViewer}
                          validateCheckup={validateCheckup}
                          extensions={[...mediaExtensions, 'pdf']}
                        />
                        )}
                      </div>
                    </div>
                  )}
                  {isLogsExisted && <CheckupLogs logs={checkupLogs} checkup={checkup} />}
                </Panel.Body>
              </Panel>
            </div>
          </div>
        </section>

        <StickyFooter hidden={!isReadyToSubmit}>
          {isSubmitted && (
            <Button primary onClick={this.updateCheckup} className="big-button">
              <FormattedMessage id="container.checkupIndex.row.update" />
            </Button>
          )}
          {!isSubmitted && (
            <Button
              primary
              onClick={this.submitCheckup}
              disabled={!isReadyToSubmit || isLoading}
              className="big-button"
            >
              <i className="fa fa-rocket mr-5" />
              <FormattedMessage id="container.checkupEdit.submitCheckup" />
            </Button>
          )}
        </StickyFooter>
      </div>
    );
  }
}

PigGroupDailyCheckup.contextTypes = {
  router: T.object.isRequired,
};

PigGroupDailyCheckup.propTypes = {
  isLoading: T.bool.isRequired,
  isEditMode: T.bool,
  checkup: T.object.isRequired,
  checkupId: T.string.isRequired,
  farm: T.object.isRequired,
  logs: T.array,
  setPageOptions: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  updateCheckupNote: T.func.isRequired,
  uploadAssetComplete: T.func.isRequired,
  deleteAsset: T.func.isRequired,
  openMediaViewer: T.func.isRequired,
  openModal: T.func.isRequired,
  validateCheckup: T.func.isRequired,
  submitCheckup: T.func.isRequired,
  fetchCheckupLogs: T.func.isRequired,
  isOnline: T.bool.isRequired,
  trackWaterUsage: T.bool.isRequired,
  trackTemperature: T.bool.isRequired,
  hideMovements: T.bool.isRequired,
  measurementSystem: T.string.isRequired,
  current_company: T.object,
};

export default connect(
  (state) => {
    const isExactlyCaregiver = isUserExactlyCaregiver(state.auth.user, state.dailyCheckup.farmGroups.farm.id);
    return {
      logs: state.dailyCheckup.checkupEdit.logs,
      farm: state.dailyCheckup.farmGroups.farm,
      isOnline: state.network.isOnline,
      trackTemperature: state.auth.user.current_company.track_temperature,
      trackWaterUsage: state.auth.user.current_company.track_water_usage,
      measurementSystem: state.auth.user.current_company.measurement_system,
      current_company: state.auth.user.current_company,
      hideMovements: state.auth.user.current_company.dc_migrations_limited_access && isExactlyCaregiver,
    };
  }, (dispatch, { checkupId }) => ({
    updateCheckupNote: (resource) => dispatch(updateCheckupNoteAction(checkupId, resource)),
    uploadAssetComplete: (asset) => dispatch(uploadAssetCompleteAction(asset)),
    deleteAsset: (assetId) => dispatch(deleteAssetAction(checkupId, assetId)),
    openMediaViewer: (assets, index, data, type) => dispatch(openViewer(assets, index, data, type)),
    openModal: (component) => dispatch(openModal(component)),
    setPageOptions: (options) => dispatch(setPageOptions(options)),
    setBreadcrumbs: (data) => dispatch(setBreadcrumbs(data)),
    submitCheckup: () => dispatch(submitCheckupAction(checkupId)),
    fetchCheckupLogs: () => dispatch(fetchCheckupLogs(checkupId)),
  }),
)(PigGroupDailyCheckup);
