import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchProfile } from 'reducers/profiles';
// components
import GroupProfileHeader from 'components/ProfileHeader/GroupProfileHeader/GroupProfileHeader';
// endpoints
import { getTreatmentProtocolsFarmPigGroup } from 'endpoints/treatmentProtocols';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const TreatmentProtocolsGroup = ({
  fetchProfile,
  children,
  isLoading,
  params: {
    groupId,
    farmId,
  },
  pigGroup,
  router,
}) => {
  useEffect(() => {
    fetchProfile('pigGroup', () => {
      return getTreatmentProtocolsFarmPigGroup(farmId, groupId).catch((error) => {
        toastResponseErrors(error);
        router.push('/treatment-protocols-farms');
      });
    });
  }, [farmId]);

  return (
    <div>
      <GroupProfileHeader group={pigGroup} isLoading={isLoading} />
      {children}
    </div>
  );
};

TreatmentProtocolsGroup.propTypes = {
  pigGroup: T.object.isRequired,
  fetchProfile: T.func.isRequired,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
  isLoading: T.bool.isRequired,
  params: T.object.isRequired,
  router: T.object.isRequired,
};

export default connect(
  (state) => ({
    pigGroup: state.profiles.pigGroup.data,
    isLoading: state.profiles.pigGroup.isLoading,
  }), { fetchProfile }
)(TreatmentProtocolsGroup);
