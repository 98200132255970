import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import Input from 'components/Input';
import Button from 'components/Button';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './MultiInput.module.scss';

const cn = classnames.bind(styles);

const MultiInput = ({
  title,
  label,
  onSelect,
  onClick,
  onRemove,
  values = [],
  className,
  placeholderKey,
  extractLabel,
  labelClassName,
  selectClassName,
  scrollable,
  inputValue,
  ...props
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={cn('multi-select', className)}>
      {title && <div className={cn('select-title')}>{title}</div>}
      {label && <div className={cn('select-label')}>{label}</div>}
      <div className={cn('input-button-container')}>
        <Input
          type="text"
          className={selectClassName}
          placeholder={placeholderKey && formatMessage({ id: placeholderKey })}
          value={inputValue}
          onChange={onSelect}
          {...props}
        />

        <Button
          primary
          className={cn('add-btn')}
          onClick={onClick}
        >
          <FormattedMessage id="general.button.add" />
        </Button>
      </div>
      <div className={cn('selected-box', { scrollable })}>
        {values?.map((value) => (
          <div key={value.id} className={cn('selected-item')}>
            <div className={cn(labelClassName)}>
              {extractLabel(value)}
            </div>
            <i className={cn('remove-btn', 'fa fa-times')} onClick={() => onRemove(value)} />
          </div>
        ))}
      </div>
    </div>
  );
};

MultiInput.propTypes = {
  title: T.oneOfType([T.node, T.string]),
  label: T.oneOfType([T.node, T.string]),
  values: T.array,
  onSelect: T.func.isRequired,
  onClick: T.func.isRequired,
  onRemove: T.func.isRequired,
  selectClassName: T.string,
  className: T.string,
  labelClassName: T.string,
  optionKeys: T.shape({ value: T.string, label: T.string }).isRequired,
  placeholderKey: T.string,
  extractLabel: T.func.isRequired,
  inputValue: T.string
};

export default MultiInput;
