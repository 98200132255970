import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import MediaFilesUploader from 'components/MediaFilesUploader';
import Button from 'components/Button';
import MentionsInput from 'components/MentionsInput';
import SpeechToText from 'components/SpeechToText';
// redux
import { connect } from 'react-redux';
import { updateActionPlan, uploadAsset, deleteAsset, updateGeneralNotes } from 'reducers/siteVisitReport';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './AdditionalSection.module.scss';

const cn = classnames.bind(styles);

const svrQuestionMentionRoles = ['owner', 'caretaker', 'manager', 'vet'];

const AdditionalSection = (
  { uploadAsset, deleteAsset, report, updateGeneralNotes, updateActionPlan },
  { router: { params } }
) => {
  const [generalNotesMain, setGeneralNotesMain] = useState(report.general_notes || '');
  const [generalNotesInterim, setGeneralNotesInterim] = useState('');

  const [actionPlanMain, setActionPlanMain] = useState(report.action_plan || '');
  const [actionPlanInterim, setActionPlanInterim] = useState('');

  const [mentions, setMentions] = useState([]);

  const onMentionsChange = (mentions) => setMentions(mentions.map(({ id: user_id }) => ({ user_id })));

  const onGeneralNotesSave = () => {
    updateGeneralNotes(generalNotesMain, mentions);
  };

  const onActionPlanSave = () => {
    updateActionPlan(actionPlanMain, mentions);
  };

  const onFileRemove = (file) => deleteAsset(file);
  const onFileUploaded = (asset) => uploadAsset(asset);

  return (
    <div className={cn('general-section')}>
      {/* GENERAL NOTES */}
      {report.general_notes_section && (
        <div className={cn('notes-section')}>
          <div className={cn('title')}>
            <FormattedMessage id="general.generalNotes" />
          </div>
          <FormattedMessage id="general.enterNotesHere">
            {(placeholder) => (
              <MentionsInput
                comment={`${generalNotesMain || ''}${generalNotesInterim || ''}`}
                disabledIds={mentions.map((mention) => mention.user_id)}
                entityType="farm"
                entityId={params.farmId}
                inputType="square"
                mentionRoles={svrQuestionMentionRoles}
                onCommentChange={setGeneralNotesMain}
                onMentionsChange={onMentionsChange}
                placeholder={placeholder}
              />
            )}
          </FormattedMessage>
          <div className={cn('action-buttons')}>
            <SpeechToText
              setTextMain={setGeneralNotesMain}
              setTextInterim={setGeneralNotesInterim}
              className={cn('speech-to-text')}
              buttonClassName={cn('control-button', 'main-control', 'primary')}
              iconClassName={cn('speech-icon')}
            />
            <Button
              className={cn('save-btn')}
              onClick={onGeneralNotesSave}
              disabled={generalNotesMain === report.general_notes}
              primary
            >
              <FormattedMessage id="general.button.save" />
            </Button>
          </div>
        </div>
      )}
      {/* ACTION PLAN */}
      {report.action_plan_section && (
        <div className={cn('notes-section')}>
          <div className={cn('title')}>
            <FormattedMessage id="general.actionPlan" />
          </div>
          <FormattedMessage id="general.enterActionPlanHere">
            {(placeholder) => (
              <MentionsInput
                comment={`${actionPlanMain || ''}${actionPlanInterim || ''}`}
                disabledIds={mentions.map((mention) => mention.user_id)}
                entityType="farm"
                entityId={params.farmId}
                inputType="square"
                mentionRoles={svrQuestionMentionRoles}
                onCommentChange={setActionPlanMain}
                onMentionsChange={onMentionsChange}
                placeholder={placeholder}
              />
            )}
          </FormattedMessage>
          <div className={cn('action-buttons')}>
            <SpeechToText
              setTextMain={setActionPlanMain}
              setTextInterim={setActionPlanInterim}
              className={cn('speech-to-text')}
              buttonClassName={cn('control-button', 'main-control', 'primary')}
              iconClassName={cn('speech-icon')}
            />
            <Button
              className={cn('save-btn')}
              onClick={onActionPlanSave}
              disabled={actionPlanMain === report.action_plan}
              primary
            >
              <FormattedMessage id="general.button.save" />
            </Button>
          </div>
        </div>
      )}
      {/* MEDIA UPLOAD */}
      {report.photo_video_uploads && (
        <div className="mb-30">
          <div className={cn('title')}>
            <FormattedMessage id="general.media" />
          </div>
          <MediaFilesUploader
            onFileRemove={onFileRemove}
            onFileUploaded={onFileUploaded}
            uploadedFiles={report.svr_assets}
            uploadPath={`/svrs/${report.id}/chunk`}
          />
        </div>
      )}
    </div>
  );
};

AdditionalSection.propTypes = {
  report: T.object,
  uploadAsset: T.func.isRequired,
  deleteAsset: T.func.isRequired,
  updateActionPlan: T.func.isRequired,
  updateGeneralNotes: T.func.isRequired,
};

AdditionalSection.contextTypes = {
  router: T.object.isRequired,
};

export default connect(
  (state) => ({
    report: state.siteVisitReport.report,
  }),
  { uploadAsset, deleteAsset, updateGeneralNotes, updateActionPlan }
)(AdditionalSection);
