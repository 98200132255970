import React, { useEffect } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import GroupSourceBlock from 'containers/Groups/components/GroupSourceBlock';
import GroupFeedPanel from 'components/Groups/GroupFeedPanel/GroupFeedPanel';
import GroupActivityBox from 'components/Groups/GroupActivity/GroupActivityBox';
import Preloader from 'components/Preloader';
import Waypoint from 'react-waypoint';
import UserPanel from 'components/UserPanel/UserPanel';
// hooks
import useArrayDataState from 'hooks/useArrayDataState';
// utils
import moment from 'moment';
import classnames from 'classnames/bind';
import { formatFarmOptionLabel } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import styles from './PigGroupMovementsContainer.module.scss';

const cn = classnames.bind(styles);

const PigGroupMovementsContainer = ({ children, className = '', currentUser, getPigGroupMovements, pigGroup }) => {
  const [state, fetchData, fetchNextPage] = useArrayDataState(getPigGroupMovements);
  const { resources, isLoading, meta, isFirstFetchFinished } = state;
  const hasMoreItems = resources.length < meta.total;
  const isLoadingMore = !!resources.length && isLoading;
  const isCurrentUserAdmin = currentUser.roles_map.admin;

  useEffect(() => {
    fetchData().catch(toastResponseErrors);
  }, []);

  const renderDeliveryDate = () => {
    if (!pigGroup?.delivery_start || !pigGroup?.delivery_end) {
      return <FormattedMessage id="general.notAvail" />;
    }
    return (
      <div>
        {moment(pigGroup.delivery_start).format('MM/DD/YYYY')}
        <i className={cn('fa fa-arrow-right', 'delivery-icon')} />
        {moment(pigGroup.delivery_end).format('MM/DD/YYYY')}
      </div>
    );
  };

  const createGroupData = [
    { label: <FormattedMessage id="component.groupCreate.groupOwner" />,
      value: pigGroup?.company_name,
    },
    { label: <FormattedMessage id="general.farm" />,
      value: formatFarmOptionLabel(pigGroup?.farm || {}),
    },
    { label: <FormattedMessage id="component.groupCreate.customGroupId" />,
      value: pigGroup?.name,
    },
    { label: <FormattedMessage id="component.groupCreate.estimateDelivery" />,
      value: renderDeliveryDate(),
    },
  ];

  return (
    <div className={cn('pig-group-movements', { [className]: !!className })}>
      <Preloader isActive={isLoading} />

      {children(state, cn('group-activity'))}

      {pigGroup?.id && resources.length === meta.total && isFirstFetchFinished && (
        <GroupFeedPanel className={cn('group-activity')}>
          <UserPanel
            className="no-margin no-border"
            user={pigGroup.created_by || { full_name: currentUser.current_company.name }}
            timestamp={pigGroup.created_on}
            label={<FormattedMessage id="component.groupCreate.createdGroup" values={{ name: pigGroup.name }} />}
            dropdownOptions={[
              {
                label: <FormattedMessage id="general.editGroupInfo" />,
                url: `/groups/${pigGroup.id}/edit`,
                hide: !isCurrentUserAdmin,
              },
            ]}
          >
            <GroupActivityBox resources={createGroupData} />
            <GroupSourceBlock isAdmin={isCurrentUserAdmin} source={pigGroup.external_source} className="mt-10" />
            {pigGroup.pig_group_diagnoses_names && pigGroup.pig_group_diagnoses_names.length > 0 && (
              <div className={cn('group-info-box')}>
                <div className={cn('group-info-line')}>
                  <div><FormattedMessage id="general.healthStatus" /></div>
                  <div className="semibold"><strong>{pigGroup.pig_group_diagnoses_names.join(', ')}</strong></div>
                </div>
              </div>
            )}
          </UserPanel>
        </GroupFeedPanel>
      )}

      {resources.length < meta.total && (
        <div className={cn('waypoint', { hidden: !hasMoreItems })}>
          <Preloader isActive={isLoadingMore} />
          {hasMoreItems && <Waypoint onEnter={fetchNextPage} />}
        </div>
      )}
    </div>
  );
};

PigGroupMovementsContainer.propTypes = {
  className: T.string,
  children: T.func.isRequired,
  currentUser: T.object.isRequired,
  getPigGroupMovements: T.func.isRequired,
  pigGroup: T.object,
};

export default PigGroupMovementsContainer;
