import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setTableParams } from 'reducers/tableParams';
// components
import DataTable from 'components/DataTable/DataTable';
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import Button from 'components/Button';
import NothingBox from 'components/NothingBox';
import Link from 'react-router/lib/Link';
import { CustomColumn } from 'components/DataTable/Columns';
import Panel from 'components/Panel';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
import { useDropdownActions } from 'hooks/useDropdownContext';
// api
import { getFarmBarnsheetsReports } from 'endpoints/reports';
import { getSiteVisitReportPDF } from 'endpoints/svr';
// utils, constants
import classnames from 'classnames/bind';
import { getAnswersCountByAnswerType, getSVRAssetName, isSomeReportQuestionFlagged } from 'utils/SVRHelper';
import { tableNames } from 'utils/constants';
import moment from 'moment';
import download from 'downloadjs';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import styles from './BarnSheetsReports.module.scss';

const cn = classnames.bind(styles);

const BarnSheetsReports = ({
  isSVREnabled,
  params: { farmId },
  router,
  setTableParams,
  tableParams,
}) => {
  const { openDropdown } = useDropdownActions();
  const [isDownloading, setDownloading] = useState(false);
  const {
    data: { resources, isLoading, meta },
    fetchData,
    onPageChange,
    onPerPageChange,
  } = useDataTable((params) => getFarmBarnsheetsReports(farmId, params), {
    setTableParams: (params) => setTableParams(tableNames.barnSheetsFarmReports, params),
    tableParams,
  });
  const { page, per_page } = tableParams;
  const { total } = meta;

  useEffect(() => {
    fetchData({ ...tableParams, draft: false, page: 1 });
  }, []);

  useEffect(() => {
    if (!isSVREnabled) {
      router.push('/barnsheets/farms');
    }
  }, [isSVREnabled]);

  const paginationProps = {
    onPageChange,
    onPerPageChange,
    totalItems: total,
    currentPage: page,
    perPage: per_page,
  };

  const backLocationQuery = { from: 'barnsheets' };

  const buildReportPath = (reportData, isReview) => {
    const { farm_id, id, svr_template_id } = reportData;
    const pathname = isReview
      ? `/report-farms/${farm_id}/report-templates/${svr_template_id}/reports/${id}/review`
      : `/report-farms/${farm_id}/report-templates/${svr_template_id}/reports/${id}`;
    return { pathname, query: backLocationQuery };
  };

  const renderDateColumn = (rowData) => {
    const { svr_categories, created_at } = rowData;

    return (
      <CustomColumn
        className={cn('date-column', { flagged: isSomeReportQuestionFlagged(svr_categories) })}
        label={<FormattedMessage id="general.date" />}
      >
        <Link className="color-primary" to={buildReportPath(rowData, true)}>
          {created_at ? moment(created_at, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'N/A'}
        </Link>
      </CustomColumn>
    );
  };

  const renderReporterColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.reporter" />}>
      {rowData.user.full_name}
    </CustomColumn>
  );

  const renderTemplateNameColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.report" />}>
      {rowData.svr_template.name || <FormattedMessage id="general.notAvail" />}
    </CustomColumn>
  );

  const renderPassesColumn = (rowData) => {
    const passesCount = getAnswersCountByAnswerType(rowData, 'pass');
    return (
      <CustomColumn label={<FormattedMessage id="general.passes" />}>
        {passesCount}
      </CustomColumn>
    );
  };

  const renderFailsColumn = (rowData) => {
    const failsCount = getAnswersCountByAnswerType(rowData, 'fail');
    return (
      <CustomColumn label={<FormattedMessage id="general.fails" />}>
        {failsCount}
      </CustomColumn>
    );
  };

  const renderNAColumn = (rowData) => {
    const NACount = getAnswersCountByAnswerType(rowData, 'not_available');
    return (
      <CustomColumn noBottomBorder label={<FormattedMessage id="general.notAvail" />}>
        {NACount}
      </CustomColumn>
    );
  };

  const onReportDownload = (report) => () => {
    setDownloading(true);
    getSiteVisitReportPDF(report.farm_id, report.id)
      .then((blob) => download(blob, getSVRAssetName(report), 'application/pdf'))
      .catch(toastResponseErrors)
      .finally(() => setDownloading(false));
  };

  const handleDropdownOpen = (e, report) => {
    const options = [
      { label: <FormattedMessage id="general.button.download" />, onClick: onReportDownload(report) },
    ];
    openDropdown(e, options);
  };

  const renderActionsColumn = (report, { rowIndex }) => (
    <div className={cn('collapsible-value button-column', 'actions-column')}>
      <i
        data-name={`${report.name}-${report.id}-${rowIndex}`}
        className={cn('fa fa-dots-three-horizontal show-for-large', 'dropdown-icon')}
        onClick={(e) => handleDropdownOpen(e, report)}
      />
      <Button
        darkGrey
        onClick={onReportDownload(report)}
        className="hide-for-large"
      >
        <FormattedMessage id="general.button.download" />
      </Button>
    </div>
  );

  const columns = [
    { label: <FormattedMessage id="general.date" />, flex: '1 1 140px', renderer: renderDateColumn },
    { label: <FormattedMessage id="general.report" />, flex: '1 1 140px', renderer: renderTemplateNameColumn },
    { label: <FormattedMessage id="general.reporter" />, flex: '1 1 140px', renderer: renderReporterColumn },
    { label: <FormattedMessage id="general.passes" />, flex: '1 1 140px', renderer: renderPassesColumn },
    { label: <FormattedMessage id="general.fails" />, flex: '1 1 140px', renderer: renderFailsColumn },
    { label: <FormattedMessage id="general.notAvail" />, flex: '1 1 140px', renderer: renderNAColumn },
    { label: '', flex: '0 0 45px', fixed: true, renderer: renderActionsColumn },
  ];


  return (
    <Panel>
      <Panel.Heading title={<FormattedMessage id="general.reports" />} />
      <Panel.Body noPadding>
        <Preloader isActive={isDownloading} />
        <DataTable
          data={resources}
          columns={columns}
          paginationProps={paginationProps}
          isLoading={isLoading}
          scrollable
        />

        <NothingBox itemsName="reports" display={!resources.length} isLoading={isLoading}>
          <h2 className="lighter"><FormattedMessage id="component.nothingBox.noReports" /></h2>
        </NothingBox>
      </Panel.Body>
    </Panel>
  );
};

BarnSheetsReports.propTypes = {
  setTableParams: T.func.isRequired,
  tableParams: T.object.isRequired,
  isSVREnabled: T.bool.isRequired,
  router: T.object.isRequired,
  params: T.object.isRequired,
};

export default connect(
  (state) => ({
    tableParams: tableParamsSelector(state, tableNames.barnSheetsFarmReports),
    isSVREnabled: state.auth.user.current_company.admin_svrs,
  }), { setTableParams }
)(BarnSheetsReports);
