import { handleActions, createAction } from 'redux-actions';
import { fetchFromAPI } from 'utils/api';
import pickBy from 'lodash.pickby';

// ------------------------------------
// Constants
// ------------------------------------
const defaultParams = {
  page: 1,
  per_page: 200,
  search: '',
};

const initialState = {
  resources: [],
  isLoading: false,
  pigGroup: {},
  isGroupLoading: false,
  selectedCheckupId: undefined, // for auto scroll
  params: defaultParams,
  meta: {
    total: 0,
    stats: {},
  },
};

const FETCH_GROUP_CHECKUPS = 'barnsheets/FETCH_GROUP_CHECKUPS';
const [FETCH_CHECKUPS_PENDING, FETCH_CHECKUPS_FULFILED, FETCH_CHECKUPS_REJECTED] = [
  `${FETCH_GROUP_CHECKUPS}_PENDING`,
  `${FETCH_GROUP_CHECKUPS}_FULFILLED`,
  `${FETCH_GROUP_CHECKUPS}_REJECTED`,
];
const [CLEAR_GROUP_CHECKUPS] = 'barnsheets/CLEAR_GROUP_CHECKUPS';

const FETCH_CURRENT_GROUP = 'barnsheets/FETCH_CURRENT_GROUP';
const [FETCH_CURRENT_GROUP_PENDING, FETCH_CURRENT_GROUP_FULFILLED, FETCH_CURRENT_GROUP_REJECTED] = [
  `${FETCH_CURRENT_GROUP}_PENDING`,
  `${FETCH_CURRENT_GROUP}_FULFILLED`,
  `${FETCH_CURRENT_GROUP}_REJECTED`,
];

const UPDATE_CURRENT_GROUP = 'barnsheets/UPDATE_CURRENT_GROUP';
const [UPDATE_CURRENT_GROUP_PENDING, UPDATE_CURRENT_GROUP_FULFILLED, UPDATE_CURRENT_GROUP_REJECTED] = [
  `${UPDATE_CURRENT_GROUP}_PENDING`,
  `${UPDATE_CURRENT_GROUP}_FULFILLED`,
  `${UPDATE_CURRENT_GROUP}_REJECTED`,
];

const SET_CURRENT_PIG_GROUP = 'barnsheets/SET_CURRENT_PIG_GROUP';
const SET_PIG_GROUP_LOADING = 'barnsheets/SET_PIG_GROUP_LOADING';

const EDIT_MODE_ON = 'barnsheets/EDIT_MODE_ON';
const EDIT_MODE_OFF = 'barnsheets/EDIT_MODE_OFF';
const SUBMIT_CORRECTIONS = 'barnsheets/SUBMIT_CORRECTIONS';

// for auto scroll
const SET_SELECTED_CHECKUP_ID = 'barnsheets/SET_SELECTED_CHECKUP_ID';
const RESET_SELECTED_CHECKUP_ID = 'barnsheets/RESET_SELECTED_CHECKUP_ID';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchGroupCheckups = (id, params = defaultParams, isEditMode, fromDate) => (dispatch) => {
  dispatch(createAction(FETCH_CHECKUPS_PENDING)());
  const endpoint = isEditMode
    ? `/pig_groups/${id}/daily_checkups/edit_mode_index`
    : `/pig_groups/${id}/daily_checkups`;

  return fetchFromAPI(endpoint, { params: pickBy({ ...params, from_date: fromDate }) })
    .then((response) => {
      dispatch(createAction(FETCH_CHECKUPS_FULFILED)({
        resources: response.resources,
        meta: response.meta,
        params,
      }));
      return response;
    })
    .catch((response) => {
      dispatch(createAction(FETCH_CHECKUPS_REJECTED)(response));
      throw response;
    });
};

export const fetchCurrentGroup = (id) => createAction(FETCH_CURRENT_GROUP)(
  fetchFromAPI(`/barnsheets/pig_groups/${id}`)
);
export const setCurrentGroup = createAction(SET_CURRENT_PIG_GROUP);
export const setGroupLoading = createAction(SET_PIG_GROUP_LOADING);

export const enableEditMode = (id, params = { type: 'edit' }) => (dispatch) => {
  dispatch(setGroupLoading(true));
  const reqParams = { method: 'PUT', body: JSON.stringify(params) };
  return fetchFromAPI(`/barnsheets/pig_groups/${id}/edit_mode_on`, reqParams)
    .then(({ resource }) => {
      dispatch(createAction(EDIT_MODE_ON)(resource));
      dispatch(setGroupLoading(false));
    })
    .catch((response) => {
      dispatch(setGroupLoading(false));
      throw response;
    });
};

export const disableEditMode = (id) => (dispatch) => {
  dispatch(setGroupLoading(true));
  return fetchFromAPI(`/barnsheets/pig_groups/${id}/edit_mode_off`, { method: 'PUT' })
    .then(({ resource }) => {
      dispatch(createAction(EDIT_MODE_OFF)(resource));
      dispatch(setGroupLoading(false));
    })
    .catch((response) => {
      dispatch(setGroupLoading(false));
      throw response;
    });
};

export const submitCorrections = (id) => (dispatch) => {
  dispatch(setGroupLoading(true));
  return fetchFromAPI(`/barnsheets/pig_groups/${id}/submit_corrections`, { method: 'PUT' })
    .then(({ resource }) => {
      dispatch(setGroupLoading(false));
      dispatch(createAction(SUBMIT_CORRECTIONS)(resource));
    })
    .catch((response) => {
      dispatch(setGroupLoading(false));
      throw response;
    });
};


// for auto scroll
export const setSelectedCheckupId = createAction(SET_SELECTED_CHECKUP_ID);
export const resetSelectedCheckupId = createAction(RESET_SELECTED_CHECKUP_ID);
export const clearGroupCheckups = createAction(CLEAR_GROUP_CHECKUPS);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  // FETCH_CURRENT_GROUP
  [FETCH_CURRENT_GROUP_PENDING]: (state) => ({
    ...state,
    isGroupLoading: true,
  }),
  [FETCH_CURRENT_GROUP_FULFILLED]: (state, action) => ({
    ...state,
    isGroupLoading: false,
    pigGroup: action?.payload?.resource,
  }),
  [FETCH_CURRENT_GROUP_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isGroupLoading: false,
  }),

  // FETCH_GROUP_CHECKUPS
  [FETCH_CHECKUPS_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_CHECKUPS_FULFILED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),
  [FETCH_CHECKUPS_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),

  // UPDATE_CURRENT_GROUP
  [UPDATE_CURRENT_GROUP_PENDING]: (state) => ({
    ...state,
    isGroupLoading: true,
  }),
  [UPDATE_CURRENT_GROUP_FULFILLED]: (state, action) => ({
    ...state,
    isGroupLoading: false,
    pigGroup: action.payload.resource,
  }),
  [UPDATE_CURRENT_GROUP_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isGroupLoading: false,
  }),
  // EDIT_MODE
  [EDIT_MODE_ON]: (state, action) => ({
    ...state,
    pigGroup: {
      ...state.pigGroup,
      ...action.payload,
    },
  }),
  [EDIT_MODE_OFF]: (state, action) => ({
    ...state,
    pigGroup: {
      ...state.pigGroup,
      ...action.payload,
    },
  }),
  [SUBMIT_CORRECTIONS]: (state, action) => ({
    ...state,
    pigGroup: {
      ...state.pigGroup,
      ...action.payload,
    },
  }),
  [SET_PIG_GROUP_LOADING]: (state, action) => ({
    ...state,
    isGroupLoading: action.payload,
  }),

  // SET_CURRENT_PIG_GROUP
  [SET_CURRENT_PIG_GROUP]: (state, action) => ({
    ...state,
    pigGroup: {
      ...action.payload,
    },
  }),

  // for auto scroll
  [SET_SELECTED_CHECKUP_ID]: (state, action) => ({
    ...state,
    selectedCheckupId: action.payload,
  }),
  [RESET_SELECTED_CHECKUP_ID]: (state) => ({
    ...state,
    selectedCheckupId: undefined,
  }),
  [CLEAR_GROUP_CHECKUPS]: (state) => ({
    ...state,
    resources: [],
    meta: {
      total: 0,
      stats: {},
    }
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
