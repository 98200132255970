import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
// components
import SVRComment from 'components/SVRComment';
import SVRMediaRow from './components/SVRMediaRow';
import { ReactComponent as DownloadIcon } from './download.svg';
import SVRStatBlocks from './components/SVRStatBlocks';
import SVRHeader from './components/SVRHeader';
import FarmInformationBar from './components/FarmInformationBar';
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import CategoryViewRow from './components/CategoryViewRow';
// api
import { fetchSVRFarm, getFarmSVR, getSiteVisitReportPDF } from 'endpoints/svr';
// utils
import download from 'downloadjs';
import classnames from 'classnames/bind';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import {
  getSVRNotesComment,
  getUserAccessToFarmSVR,
  getSVRBackLink,
  getSVRAssetName,
  parseReportResponse,
} from 'utils/SVRHelper';
// styles
import styles from './Report.module.scss';

const cn = classnames.bind(styles);

const ReportReview = ({
  currentUser,
  isOnline,
  location,
  params: { farmId, svrId, templateId },
  router,
  setPageOptions,
}) => {
  const [{ isLoading, isDownloading, isEditable, svr, farm }, setData] = useState({
    isLoading: false,
    isDownloading: false,
    isEditable: false,
    svr: {},
    farm: null,
  });
  const { categories = [], createdAt, general_notes, action_plan, svr_assets, user } = svr;

  const fetchData = async () => {
    setData((prevState) => ({ ...prevState, isLoading: true }));

    try {
      const svr = await getFarmSVR(farmId, svrId);

      if (svr.is_draft) {
        return router.goBack();
      }

      const farm = await fetchSVRFarm(+farmId);
      const { canEdit } = getUserAccessToFarmSVR(currentUser, farm?.id, farm?.manager_id);

      return setData((prevState) => ({
        ...prevState,
        farm,
        isLoading: false,
        isEditable: canEdit,
        svr: parseReportResponse(svr),
      }));

    } catch (errors) {
      toastResponseErrors(errors);
      return router.push('/report-farms');
    }
  };

  useEffect(() => {
    fetchData();
  }, [isOnline, farmId, svrId]);

  useEffect(() => {
    setPageOptions({ isFullScreenMode: true });
    return () => setPageOptions({ isFullScreenMode: false });
  }, []);

  const onClose = () => {
    const backLink = getSVRBackLink(farmId, templateId, location.query);
    router.push(backLink);
  };

  const onPDFDownload = () => {
    setData((prevState) => ({ ...prevState, isDownloading: true }));
    return getSiteVisitReportPDF(farmId, svrId)
      .then((blob) => download(blob, getSVRAssetName(svr), 'application/pdf'))
      .catch(toastResponseErrors)
      .finally(() => {
        setData((prevState) => ({ ...prevState, isDownloading: false }));
      });
  };

  const onEdit = () => {
    if (isEditable) {
      router.push({ ...location, pathname: `/report-farms/${farmId}/report-templates/${templateId}/reports/${svrId}` });
    }
  };

  if (!farm) return <Preloader isActive isCentered />;

  return (
    <div className={cn('wrapper', { loading: isLoading || isDownloading })}>
      <Preloader isCentered isActive={isLoading || isDownloading} />

      <SVRHeader templateId={templateId} farmId={farm.id} createdAt={createdAt} farmName={farm.name} onClose={onClose}>
        <div className={cn('buttons-section')}>
          {isEditable && <i onClick={onEdit} className={cn('fa fa-pencil-mdc', 'icon', 'edit-icon')} />}
          <DownloadIcon onClick={onPDFDownload} className={cn('icon', 'download-icon')} />
          <i onClick={onClose} className={cn('fa fa-times', 'icon', 'close-icon')} />
        </div>
      </SVRHeader>

      <div className={cn('svr-content')}>
        <div className={cn('farm-bar')}>
          <FarmInformationBar farm={farm} currentCompany={currentUser.current_company} />
        </div>
        <div id="svr-content-wrapper" className={cn('content-wrapper')}>
          <div className={cn('content')}>
            <SVRStatBlocks categories={categories} />

            <div className="mt-30">
              {categories.map((category, idx) => <CategoryViewRow categoryIndex={idx} category={category} key={idx} />)}
            </div>

            {general_notes && (
              <div className={cn('general-section', 'mt-30')}>
                <div className={cn('section-title')}>
                  <FormattedMessage id="general.generalNotes" />
                </div>
                <div>
                  <SVRComment comment={getSVRNotesComment({ value: general_notes }, user)} />
                </div>
              </div>
            )}

            {action_plan && (
              <div className={cn('general-section')}>
                <div className={cn('section-title')}>
                  <FormattedMessage id="general.actionPlan" />
                </div>
                <div>
                  <SVRComment comment={getSVRNotesComment({ value: action_plan }, user)} />
                </div>
              </div>
            )}

            {!!svr_assets.length && (
              <div className={cn('general-section')}>
                <div className={cn('section-title', 'mb-15')}>
                  <FormattedMessage id="general.media" />
                </div>
                <SVRMediaRow media={svr_assets} />
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

ReportReview.propTypes = {
  params: T.object.isRequired,
  router: T.object.isRequired,
  location: T.object.isRequired,
  isOnline: T.bool.isRequired,
  currentUser: T.object.isRequired,
  setPageOptions: T.func.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
    isOnline: state.network.isOnline,
  }), {
    setPageOptions,
  }
)(ReportReview);
