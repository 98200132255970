import T from 'prop-types';
import numeral from 'numeral';

const FormattedNumber = ({ value, format, placeholder = 'N/A' }) => {
  if (value === undefined || value === null) return placeholder;
  return numeral(+value).format(format);
};

FormattedNumber.propTypes = {
  number: T.number,
  format: T.string,
  placeholder: T.string,
};

export default FormattedNumber;
