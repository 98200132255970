import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// styles
import './Avatar.scss';

const icons = {
  farm: <i className="fa fa-ep-farm" />,
  pig_group: <i className="fa fa-ep-groups" />,
  shareholder_group: <i className="fa fa-sitemap" />,
  company: <i className="fa fa-business-socks" />,
  everypig: <i className="fa fa-ep-pig" />,
  source: <i className="fa fa-merge" />,
  injectable: <i className="fa fa-injectable" />,
  topical: <i className="fa fa-topical" />,
  'packing-company': <i className="fa fa-business-socks" />,
  'water-soluble': <i className="fa fa-water-soluble" />,
  'trucking-company': <i className="fa fa-business-socks" />,
  'packing-plant': <i className="fa fa-industry" />,
};

const classes = {
  farm: 'farm-icon',
  pig_group: 'group-icon',
  shareholder_group: 'shareholder-group-icon',
  company: 'company-icon',
  everypig: 'everypig-icon',
  source: 'source-icon',
  injectable: 'injectable-icon',
  topical: 'topical-icon',
  'packing-plant': 'packing-plant-icon',
  'packing-company': 'packing-company-icon',
  'water-soluble': 'water-soluble-icon',
  'trucking-company': 'trucking-company-icon'
};

const Avatar = ({ avatarSize, iconSize, type, icon, className }) => (
  <div
    className={cn('Avatar', className, classes[type])}
    style={{
      'width': `${avatarSize}px`,
      'height': `${avatarSize}px`,
      'fontSize': `${iconSize}px`,
    }}
  >
    {icon || icons[type]}
  </div>
);


Avatar.defaultProps = {
  avatarSize: 30,
  iconSize: 12,
  type: 'everypig',
  className: '',
};

Avatar.propTypes = {
  type: T.string,
  icon: T.node,
  className: T.string,
  avatarSize: T.oneOfType([T.string, T.number]),
  iconSize: T.oneOfType([T.string, T.number]),
};

export default Avatar;
