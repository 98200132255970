import React, { useEffect } from 'react';
import T from 'prop-types';
// components
import UserAvatar from 'components/UserAvatar';
import LogisticsNotificationIcon from './LogisticsNotificationIcon';
// utils
import classnames from 'classnames/bind';
import {
  getNotificationCaption,
  getRespondUrl,
  getAudioPath,
} from 'utils/notificationsHelper';
import { renderEncourageIcon } from 'utils';
// styles
import styles from './NotificationMessage.module.scss';
// assets
import congratIcon from '../../../public/images/congrat-icon.png';
import magicLoopSvg from '../../../public/images/magic_2_looped.svg';
import trophyIcon from '../../../public/images/trophy-icon.png';
// redux
import { connect } from 'react-redux';
import { setActivitiesFilter } from 'reducers/farmfeed';
import { setAdvancedSearchVisibility } from 'reducers/layout';

const cn = classnames.bind(styles);

const logisticsNotifications = [
  'loading_created_production',
  'loading_edited_production',
  'loading_edited_confirmation_production',
  'sales_result_created_production',
  'loading_canceled_production',
];

const AISuggestionSections = {
  sections: [
    {
      type: 'AiTreatmentPrediction',
      condition: 'exist',
      key: 1,
      options: []
    },
    {
      type: 'AiMortalityPrediction',
      condition: 'exist',
      key: 2,
      options: []
    },
    {
      type: 'AiImagePrediction',
      condition: 'exist',
      key: 3,
      options: []
    }
  ],
  any_condition: true
};

function renderToastrIcon(type, initiator, target, user) {
  if (['open_group', 'request_close_group'].includes(type)) return (
    <UserAvatar size={50} user={initiator || user} notificationType="group" />
  );

  if (['dc_mention'].includes(type)) return (
    <div className={cn('toastr-dc-mention', 'fa fa-ep-groups')}>
      <i className={cn('mention-icon', 'fa fa-at')} />
    </div>
  );

  if (['group_import', 'change_pig_group_name'].includes(type)) return (
    <i className={cn('fa fa-ep-groups', 'groups-icon')} />
  );

  if (['farm_compliance'].includes(type)) return (
    <i className={cn('fa fa-clock-btm', 'clock-icon')} />
  );

  if ([
    'health_alert', 'water_usage_warning_event', 'general_water_usage_warning_event', 'general_withdrawal_warning_event'
  ].includes(type)) return (
    <i className={cn('fa fa-warning', 'warning-icon')} />
  );

  if (['withdrawal_warning_event'].includes(type)) return (
    <i className={cn('fa fa-exclamation-triangle', 'warning-icon')} />
  );

  if (['ai_suggestion'].includes(type)) return (
    <img src={magicLoopSvg} className={cn('magic-loop-image')} alt="" loading="lazy" />
  );

  if (['birthday_event'].includes(type) && (initiator.id === user.id)) return (
    <img src={congratIcon} className={cn('congrats-icon')} alt="birthday-icon" loading="lazy" />
  );

  if (['lowest_mortality_event'].includes(type)) return (
    <img src={trophyIcon} className={cn('trophy-icon')} alt="lowest-mortality-icon" loading="lazy" />
  );

  if (['birthday_event'].includes(type) && (initiator.id !== user.id)) return (
    <UserAvatar size={50} user={initiator} />
  );

  if (['diagnosis_encouragement', 'media_encouragement'].includes(type)) return (
    renderEncourageIcon(target.additional_data.milestone, type === 'diagnosis_encouragement')
  );

  if (logisticsNotifications.includes(type)) return (<LogisticsNotificationIcon type={type} target={target} />);

  return <UserAvatar size={50} user={initiator || user} notificationType={type} />;
}

const NotificationMessage = ({
  className,
  data,
  user,
  volume,
  showAdvancedSearch,
  setAdvancedSearchVisibility,
  setActivitiesFilter,
}, {
  router,
}) => {

  useEffect(() => {
    const { sounds_effects } = user;
    if (sounds_effects) {
      const audioPath = getAudioPath(data.notification_type);
      const audio = new Audio(audioPath);
      if (volume) {
        audio.volume = volume;
      }
      const promise = audio.play();
      promise?.catch((error) => console.warn(error));
    }
  }, []);

  const handleClick = () => {
    const { notification_type, target } = data;
    const url = getRespondUrl(notification_type, target, user);
    if (url) router.push(url);

    // TODO: move it to separate function
    if (notification_type === 'ai_suggestion') {
      setAdvancedSearchVisibility(!showAdvancedSearch);
      setActivitiesFilter(AISuggestionSections);

      if (router.location.pathname !== 'farmfeed') {
        router.push('/farmfeed');
      }

    }
  };


  return (
    <div
      onClick={handleClick}
      className={
        cn('notification-msg',
          { [className]: !!className, 'ai-suggestion-msg': data.notification_type === 'ai_suggestion' })}
    >
      <div className={cn('nots-avatar')}>
        {renderToastrIcon(data.notification_type, data.initiator, data.target, user)}
      </div>
      {getNotificationCaption(data.notification_type, data.target, data.initiator, data.options, user, true)}
    </div>
  );
};

NotificationMessage.contextTypes = {
  router: T.object.isRequired,
};

NotificationMessage.propTypes = {
  className: T.string,
  data: T.object.isRequired,
  user: T.object.isRequired,
  volume: T.number,
  showAdvancedSearch: T.bool,
  setAdvancedSearchVisibility: T.func.isRequired,
  setActivitiesFilter: T.func.isRequired,
};


export default connect(
  (state) => ({
    showAdvancedSearch: state.layout.showAdvancedSearch,
  }), {
    setActivitiesFilter,
    setAdvancedSearchVisibility,
  }
)(NotificationMessage);
