import isEqual from 'lodash.isequal';
import moment from 'moment';

export const formatTreatmentProtocolResponse = (fetchedTreatmentProtocol) => ({
  id: fetchedTreatmentProtocol.id,
  active: fetchedTreatmentProtocol.active,
  name: fetchedTreatmentProtocol.name,
  comment: fetchedTreatmentProtocol.comment,
  farm_treatment_protocol: fetchedTreatmentProtocol.farm_treatment_protocol,
  applied_farms: fetchedTreatmentProtocol.farms?.filter((farm) => farm.active),
  tp_base_date: fetchedTreatmentProtocol.tp_base_date, // boolean
  treatments: fetchedTreatmentProtocol.treatment_protocol_products.map((product) => ({
    id: product.id,
    product: product.treatment_product,
    repeat: product.treatment_protocol_product_steps.some(({ repeat }) => repeat),
    comment: product.comment,
    dosage_comment: product.dosage_comment,
    steps: product.treatment_protocol_product_steps.map((step) => {
      const {
        step_type, step_unit, first_period, last_period, id, start_date, end_date, quantity, gallons_value
      } = step;

      return {
        [step_type]: {
          step_unit,
          first_period,
          last_period,
          start_date: moment(start_date, 'YYYY-MM-DD'),
          end_date: moment(end_date, 'YYYY-MM-DD'),
          quantity,
          gallons_value,
        },
        step_type,
        id
      };
    }),
  }))
});

export const formatTreatmentProtocolPayload = (treatmentProtocolPayload) => ({
  id: treatmentProtocolPayload.id,
  active: treatmentProtocolPayload.active,
  name: treatmentProtocolPayload.name,
  comment: treatmentProtocolPayload.comment,
  tp_base_date: treatmentProtocolPayload.tp_base_date, // boolean
  treatment_protocol_products_attributes: treatmentProtocolPayload.treatments.map((treatment) => ({
    id: treatment.id,
    treatment_product_id: treatment.product.id,
    repeat: treatment.repeat,
    comment: treatment.comment,
    dosage_comment: treatment.dosage_comment,
    _destroy: treatment._destroy,
    treatment_protocol_product_steps_attributes: treatment.steps.map((step) => {
      const { step_type, id, _destroy } = step;
      const {
        step_unit, first_period, last_period, start_date, end_date, quantity, gallons_value
      } = step[step_type];
      const repeat = step_type === 'between' && treatment.repeat;

      return {
        step_unit,
        first_period,
        last_period,
        step_type,
        id,
        _destroy,
        repeat,
        start_date: step_type === 'before' ? undefined : start_date,
        // eslint-disable-next-line no-nested-ternary
        end_date: step_type === 'after' ? undefined : (step_type === 'on' ? start_date : end_date),
        quantity,
        gallons_value,
      };
    }),
  }))
});

// TODO: replace it with adding _destroy key dynamically, on every remove call
export const markDestroyedItems = (updatedTreatmentProtocol, initialTreatmentProtocol) => {
  const formattedTreatmentProtocol = { ...updatedTreatmentProtocol };
  initialTreatmentProtocol.treatments.forEach((treatment) => {
    const treatmentItem = formattedTreatmentProtocol.treatments.find(({ id }) => id === treatment.id);
    if (treatmentItem) {
      treatment.steps.forEach((step) => {
        const stepItem = treatmentItem.steps.find(({ id }) => id === step.id);
        if (!stepItem) {
          treatmentItem.steps.push({ ...step, _destroy: true });
        }
      });
    } else {
      formattedTreatmentProtocol.treatments.push({ ...treatment, _destroy: true });
    }
  });

  return formattedTreatmentProtocol;
};

const isTreatmentStepFilled = (treatmentStepData = {}, product = {}) => {
  const { step_type } = treatmentStepData;
  const {
    step_unit, first_period, last_period, start_date, end_date, quantity, gallons_value
  } = treatmentStepData[step_type] || {};

  if (!product.route.includes('topical') && (quantity == null || quantity === 0)) return false;
  if (
    product.dosage_measure === 'gal' && !product.route.includes('topical') &&
    (gallons_value == null || gallons_value === 0)
  ) return false;

  if (step_unit === 'date_range' && step_type === 'between') return start_date && end_date;
  if (step_unit === 'date_range' && ['after', 'on', 'before'].includes(step_type)) return start_date || end_date;
  if (step_type === 'between') return first_period && last_period && step_unit;

  return step_type && first_period && step_unit;
};

export const validateTreatmentProtocol = (treatmentProtocol = {}) => {
  const isProtocolNamed = !!treatmentProtocol.name;
  if (!isProtocolNamed) return false;
  return treatmentProtocol.treatments?.every((treatment) => {
    const isProductSelected = !!treatment.product;
    if (!isProductSelected) return false;
    return treatment.steps?.every((step) => isTreatmentStepFilled(step, treatment.product));
  });
};

export const validateUpdatedTreatmentProtocol = (updatedTreatmentProtocol, initialTreatmentProtocol) => {
  return !isEqual(updatedTreatmentProtocol, initialTreatmentProtocol) &&
    validateTreatmentProtocol(updatedTreatmentProtocol);
};
