import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import MessageBox from 'components/MessageBox';
// utils
import classnames from 'classnames/bind';
// redux
import { connect } from 'react-redux';
// styles
import styles from './BasicPlan.module.scss';

const cn = classnames.bind(styles);

const BasicPlanCustomizationBlocker = ({ children, currentUser }) => {
  const isEverypigBasicEnabled = currentUser?.current_company?.everypig_basic;
  if (!isEverypigBasicEnabled) return children;
  return (
    <div className={cn('basic-plan-content-wrapper')}>
      <div className={cn('content-blocker')} />
      {children}
      <div className={cn('content-blocker-label')}>
        <MessageBox className={cn('content-blocker-message-box')} type="info">
          <div>
            <div className="semibold">
              <FormattedMessage id="general.everypigBasicEnabled" />
            </div>
            <span>
              <FormattedMessage id="general.customizationOfThisSettingIsDisabled" /><br />
              <FormattedMessage
                id="general.contactEverypig"
                values={{
                  helloLink: <a href="mailto:hello@everypig.com">hello@everypig.com</a>,
                  b: (text) => <b>{text}</b>,
                }}
              />
            </span>
          </div>
        </MessageBox>
      </div>
    </div>
  );
};

BasicPlanCustomizationBlocker.propTypes = {
  children: T.node.isRequired,
  currentUser: T.object.isRequired,
};

export default connect((state) => ({
  currentUser: state.auth.user,
}))(BasicPlanCustomizationBlocker);
