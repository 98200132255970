import React, { Fragment, useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import TreatmentProtocolForm from '../../components/TreatmentProtocolForm';
// endpoints
import { getFarmTreatmentProtocol, updateFarmTreatmentProtocol } from 'endpoints/treatmentProtocols';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import {
  formatTreatmentProtocolPayload,
  formatTreatmentProtocolResponse,
  markDestroyedItems,
  validateUpdatedTreatmentProtocol,
} from '../../helper';

const EditFarmTreatmentProtocol = ({
  farm,
  setBreadcrumbs,
  setPageOptions,
  isABFEnabled,
  router: { params: { farmId, protocolId }, push },
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [treatmentProtocol, setTreatmentProtocol] = useState(undefined);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const fetchedTreatmentProtocol = await getFarmTreatmentProtocol(farmId, protocolId);
      const formattedTreatmentProtocol = formatTreatmentProtocolResponse(fetchedTreatmentProtocol);
      setTreatmentProtocol(formattedTreatmentProtocol);
      setIsLoading(false);
    } catch (error) {
      toastResponseErrors(error);
      push(`/treatment-protocols-farms/${farmId}/treatment-protocols`);
    }
  };

  useEffect(() => {
    fetchData();
    setPageOptions({ backLink: `/treatment-protocols-farms/${farmId}/treatment-protocols` });
  }, []);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.treatmentProtocolFarms" />,
        path: '/treatment-protocols-farms',
      },
      {
        label: farm?.name || '',
        path: `/treatment-protocols-farms/${farmId}/treatment-protocols`,
      },
      {
        label: treatmentProtocol?.name || '',
        useLabelAsMobileTitle: true,
      },
    ]);
  }, [farm?.name, treatmentProtocol?.name, farmId]);

  const onSubmit = async (updatedTreatmentProtocol) => {
    try {
      setIsLoading(true);
      const markedTreatmentProtocolPayload = markDestroyedItems(updatedTreatmentProtocol, treatmentProtocol);
      const treatmentProtocolPayload = formatTreatmentProtocolPayload(markedTreatmentProtocolPayload);
      const isUpdateWithGroups = !!updatedTreatmentProtocol.update_with_groups;
      const additionalData = isUpdateWithGroups ? { with_pg_protocols: isUpdateWithGroups } : {};
      await updateFarmTreatmentProtocol(farmId, protocolId, treatmentProtocolPayload, additionalData);
      push(`/treatment-protocols-farms/${farmId}/treatment-protocols`);
    } catch (error) {
      toastResponseErrors(error);
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Preloader isActive={isLoading} />
      <TreatmentProtocolForm
        isApplicable
        onSubmit={onSubmit}
        validate={validateUpdatedTreatmentProtocol}
        initialValues={treatmentProtocol}
        title={<FormattedMessage id="general.editTreatmentProtocol" />}
        submitLabel={<FormattedMessage id="general.updateTreatmentProtocol" />}
        ABFEnabled={isABFEnabled}
      />
    </Fragment>
  );
};

EditFarmTreatmentProtocol.propTypes = {
  farm: T.object.isRequired,
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  router: T.object.isRequired,
  isABFEnabled: T.bool.isRequired,
};

export default connect(
  (state) => ({
    farm: state.profiles.farm.data,
    isABFEnabled: state.auth.user.current_company.abf_tracking,
  }), {
    setPageOptions,
    setBreadcrumbs,
  }
)(EditFarmTreatmentProtocol);
