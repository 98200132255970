import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// redux, recompose
import { connect } from 'react-redux';
// components
import { formValueSelector, change, Field, reduxForm } from 'redux-form';
import { FormattedMessage, useIntl } from 'react-intl';
import CompositeAddressField from 'components/ReduxFormFields/CompositeAddressField/CompositeAddressField';
import PhoneInputField from 'components/ReduxFormFields/PhoneInputField/PhoneInputField';
import FormField from 'components/ReduxFormFields/FormField/FormField';
import SelectField from 'components/ReduxFormFields/SelectField/SelectField';
import { Link } from 'react-router';
import Button from 'components/Button';
import TenantCheckboxRow from '../TenantCheckboxRow';
import TenantStatusRow from '../TenantStatusRow';
// styles
import './CreateTenantForm.scss';
// constants
import { langOptions } from 'utils/localizationHelper';
import { tenantSettingsCheckboxes } from 'constants.js';

const CreateTenantForm = ({
  change,
  first_name,
  last_name,
  nickname,
  handleSubmit,
  pristine,
  submitting,
  everypig_basic,
  countryCode,
}) => {
  const [nickNameChanged, setNickNameChanged] = useState(false);
  const { formatMessage } = useIntl();

  const changeNickName = (name, value) => {
    const nickname = (name === 'user[first_name]') ? value + `${last_name || ''}` : `${first_name || ''}` + value;
    change('user[nickname]', nickname);
  };

  const changeLanguage = (name, value) => change('user[language]', value);

  const handleNamesChange = ({ target: { name, value } }) => {
    if (!nickname) {
      setNickNameChanged(false);
      changeNickName(name, value);
      return;
    }
    if (!nickNameChanged) {
      changeNickName(name, value);
    }
  };

  const handleNicknameChange = () => {
    if (!nickNameChanged) setNickNameChanged(true);
  };

  useEffect(() => {
    if (everypig_basic) {
      change('ai_mortality_prediction', false);
      change('ai_treatment_prediction', false);
      change('ai_img_prediction', false);
      change('ai_diagnosis_prediction', false);
      change('admin_treatment_protocols', false);
      change('admin_svrs', false);
      change('logistics', false);
      change('virtual_visits', false);
    }
  }, [everypig_basic, change]);

  const namePlaceholder = formatMessage({ id: 'general.companyName' });
  const emailPlaceholder = formatMessage({ id: 'general.typeEmail' });
  const firstNamePlaceholder = formatMessage({ id: 'component.userProfile.firstName' });
  const lastNamePlaceholder = formatMessage({ id: 'component.userProfile.lastName' });
  const userNamePlaceholder = formatMessage({ id: 'component.userProfile.userName' });
  return (
    <form onSubmit={handleSubmit} className="CreateTenantForm">
      <div className="row">
        <div className="small-12 medium-8 column">
          <div className="row">
            <div className="small-12 column">
              <label>
                <Field
                  label={namePlaceholder}
                  name="name"
                  component={FormField}
                  type="text"
                  placeholder={namePlaceholder}
                />
              </label>
            </div>
          </div>

          {countryCode &&
          <CompositeAddressField countryCode={countryCode} />}

          <div className="row">
            <div className="small-12 medium-6 column">
              <label>
                <Field
                  label={<FormattedMessage id="general.pageTitle.language" />}
                  name="language"
                  component={SelectField}
                  onChange={changeLanguage}
                  options={langOptions}
                  valueKey="value"
                  labelKey="label"
                />
              </label>
            </div>
          </div>

          <TenantStatusRow />

          {tenantSettingsCheckboxes.map((item, index) => (
            <TenantCheckboxRow
              key={item.name}
              fieldData={item}
              isFirstRow={index === 0}
              isLastRow={tenantSettingsCheckboxes.length === (index + 1)}
              everypig_basic={everypig_basic}
            />
          ))}

          <div className="row margin-bottom-1">
            <div className="small-12 column">
              <div className="company-owner">
                <FormattedMessage id="general.companyOwner" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="small-12 column">
              <label>
                <Field
                  label={<FormattedMessage id="general.emailAddress" />}
                  name="user[email]"
                  component={FormField}
                  type="email"
                  placeholder={emailPlaceholder}
                />
              </label>
            </div>
          </div>
          <div className="row">
            <div className="small-12 medium-6 column">
              <label>
                <Field
                  name="user[first_name]"
                  component={FormField}
                  onChange={handleNamesChange}
                  type="text"
                  placeholder={firstNamePlaceholder}
                  label={firstNamePlaceholder}
                />
              </label>
            </div>
            <div className="small-12 medium-6 column">
              <label>
                <Field
                  name="user[last_name]"
                  component={FormField}
                  onChange={handleNamesChange}
                  type="text"
                  placeholder={lastNamePlaceholder}
                  label={lastNamePlaceholder}
                />
              </label>
            </div>
          </div>

          <div className="row">
            <div className="small-12 column">
              <label>
                <Field
                  label={userNamePlaceholder}
                  name="user[nickname]"
                  component={FormField}
                  onChange={handleNicknameChange}
                  icon={<i className="nickname-icon fa fa-at" />}
                  iconLeft
                  type="text"
                  placeholder={userNamePlaceholder}
                />
                <FormattedMessage id="component.userProfile.userName.description">
                  {(text) => (<i className="fs-12 field-description">{text}</i>)}
                </FormattedMessage>
              </label>
            </div>
          </div>

          <div className="row position-relative z-index-2">
            <div className="small-12 medium-6 column">
              <label>
                <Field
                  label={<FormattedMessage id="general.pageTitle.language" />}
                  name="user[language]"
                  component={SelectField}
                  options={langOptions}
                  valueKey="value"
                  labelKey="label"
                />
              </label>
            </div>
          </div>

          <div className="row">
            <div className="small-12 medium-6 column">
              <Field
                label={<FormattedMessage id="component.userProfile.phone" />}
                name="user[phone_attributes]"
                component={PhoneInputField}
              />
              <FormattedMessage id="component.userProfile.phoneDescription">
                {(text) => <i className="phone-description">{text}</i>}
              </FormattedMessage>
            </div>
          </div>

          {/* Button */}
          <div className="row mt-30">
            <div className="small-12 column">
              <Button type="submit" className="button primary" disabled={pristine || submitting}>
                <FormattedMessage id="general.createTenant" />
              </Button>
              <Link to="/super-admin/tenants" className="button btn-link margin-left-1">
                <FormattedMessage id="general.button.cancel" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

CreateTenantForm.propTypes = {
  handleSubmit: T.func.isRequired,
  pristine: T.bool,
  submitting: T.bool,
  first_name: T.string,
  last_name: T.string,
  nickname: T.string,
  countryCode: T.string,
  change: T.func.isRequired,
  everypig_basic: T.bool,
};

const formName = 'create-tenant';
const selector = formValueSelector(formName);

const formComponent = reduxForm({
  form: formName,
})(CreateTenantForm);

export default connect((state) => ({
  first_name: selector(state, 'user[first_name]'),
  last_name: selector(state, 'user[last_name]'),
  nickname: selector(state, 'user[nickname]'),
  countryCode: selector(state, 'country_code'),
  everypig_basic: selector(state, 'everypig_basic'),
}), { change })(formComponent);
