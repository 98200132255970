import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import UserAvatar from 'components/UserAvatar';
// hooks
import { useDropdownActions } from 'hooks/useDropdownContext';
// utils
import moment from 'moment';
import classnames from 'classnames/bind';
import { isSomeReportQuestionFlagged } from 'utils/SVRHelper';
// styles
import styles from './ReportCard.module.scss';

const cn = classnames.bind(styles);

const ReportCard = ({ report, onClick, onDelete, onDownload, onEdit, isReportEditable }) => {
  const { openDropdown } = useDropdownActions();

  const handleOpenDropdown = (e) => {
    const options = [
      {
        label: <FormattedMessage id="general.button.edit" />,
        onClick: onEdit(report),
        hide: !isReportEditable,
      },
      {
        label: <FormattedMessage id="general.button.download" />,
        onClick: onDownload(report),
      },
      {
        label: <FormattedMessage id="general.button.delete" />,
        onClick: onDelete(report),
      },
    ];

    openDropdown(e, options);
  };

  const lastUpdateDate = report.created_at
    ? moment(report.created_at, 'YYYY-MM-DD').format('MM/DD/YYYY')
    : 'N/A';

  const isReportFlagged = isSomeReportQuestionFlagged(report.svr_categories);

  return (
    <div onClick={onClick(report)} className={cn('report-card', { flagged: isReportFlagged })}>
      <div className={cn('top-content', { flagged: isReportFlagged })}>
        <UserAvatar user={report.user} size={25} />
        <i onClick={handleOpenDropdown} className={cn('fa fa-dots-three-horizontal', 'action-button')} />
      </div>
      <div className={cn('info-block')}>
        <div className={cn('title')}>
          <FormattedMessage id="general.report" />
        </div>
        {lastUpdateDate}
      </div>
      <div />
    </div>
  );
};

ReportCard.propTypes = {
  report: T.object.isRequired,
  onClick: T.func.isRequired,
  onDelete: T.func.isRequired,
  onDownload: T.func.isRequired,
  onEdit: T.func.isRequired,
  isReportEditable: T.bool.isRequired,
};

export default ReportCard;
