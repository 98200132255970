import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setTableParams } from 'reducers/tableParams';
import { openModal } from 'reducers/modals';
// components
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import { ShipmentBadge } from 'components/StatusBadge/StatusBadge';
import NothingBox from 'components/NothingBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import FormattedWeight from 'components/FormattedWeight';
import Button from 'components/Button';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
// endpoints
import { getPigGroupMigrations, getPigGroupCommitments } from 'endpoints/pigGroups';
// utils
import moment from 'moment';
import classnames from 'classnames/bind';
import { isTablet } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { tableNames } from 'utils/constants';
// styles
import styles from './GroupShipments.module.scss';

const cn = classnames.bind(styles);

const GroupShipments = ({
  params,
  pigGroup,
  setTableParams,
  tableParams,
  openModal,
  isLogisticsEnabled,
}, { router }) => {
  const [isCommitmentsFetching, setIsCommitmentsFetching] = useState(false);
  const {
    data: { resources, isLoading, meta },
    fetchData,
    onPageChange,
    onPerPageChange,
    onSortChange,
  } = useDataTable((regParams) => getPigGroupMigrations(params.id, regParams), {
    setTableParams: (regParams) => setTableParams(tableNames.adminGroupShipments, regParams),
    tableParams,
  });
  const { page, per_page, search, sort } = tableParams;
  const { total } = meta;

  useEffect(() => {
    fetchData({ ...tableParams, type: 'positive' }).catch(toastResponseErrors);
  }, []);

  const renderDateColumn = (rowData) => (
    <CustomColumn>
      <div className={cn('shipment-date-box')}>
        <div className={cn('shipment-icon')}>
          <i className="fa fa-arrow-right" />
        </div>
        <div className={cn('shipment-date')}>{moment(rowData.delivery_date).format('MM/DD/YYYY')}</div>
      </div>
    </CustomColumn>
  );

  const renderHeadColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.head" />}>
      {rowData.amount}
    </CustomColumn>
  );

  const renderWeightColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.estAvgWeight" />}>
      <FormattedWeight value={rowData.estimated_weight}  />
    </CustomColumn>
  );

  const renderStatusColumn = () => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <ShipmentBadge status="received" />
    </CustomColumn>
  );

  const renderActionsColumn = (rowData) => (
    <div className="collapsible-value button-column">
      <Link
        to={`/groups/${params.id}/shipments/${rowData.id}/edit`}
        className="button small wide light-gray"
      >
        <FormattedMessage id="general.button.edit" />
      </Link>
    </div>
  );

  const renderExpandable = (rowData) => (
    <div>
      <Link to={`/groups/${params.id}/shipments/${rowData.id}/edit`} className="button small light wide">
        <i className="fa fa-pencil mr-5" />
        <FormattedMessage id="general.button.edit" />
      </Link>
    </div>
  );

  const columns = [
    { label: <FormattedMessage id="general.date" />, flex: '1 1 140px', renderer: renderDateColumn,
      sortKey: 'delivery_date' },
    { label: <FormattedMessage id="general.head" />, flex: '1 1 160px', renderer: renderHeadColumn,
      sortKey: 'amount' },
    { label: <FormattedMessage id="general.estAvgWeight" />, flex: '1 1 135px', renderer: renderWeightColumn,
      sortKey: 'estimated_weight' },
    { label: <FormattedMessage id="component.dataTable.headers.status" />, flex: '1 1 125px',
      renderer: renderStatusColumn },
    { label: '', flex: '0 0 120px', renderer: renderActionsColumn, fixed: true,
      className: isTablet ? 'hide-for-large' : '' },
    { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
      hasPinnedIcon: true },
  ];

  const paginationProps = {
    totalItems: total,
    currentPage: page,
    perPage: per_page,
    onPageChange,
    onPerPageChange,
  };

  const handleCreateShipments = async () => {
    if (!isLogisticsEnabled) {
      return router.push(`/groups/${params.id}/shipments/create`);
    }

    try {
      setIsCommitmentsFetching(true);
      const { meta } = await getPigGroupCommitments(params.id, { commitment_type: 'destination' });
      setIsCommitmentsFetching(false);
      const commitmentsExist = !!meta?.total;
      if (commitmentsExist) {
        openModal(
          <ConfirmationModal
            title={<FormattedMessage id="general.pageTitle.createShipment" />}
            actionBtnLabel={<FormattedMessage id="general.button.continue" />}
            actionBtnProps={{ primary: true }}
            handleConfirm={() => router.push(`/groups/${params.id}/shipments/create`)}
          >
            <FormattedMessage id="component.modal.confirmShipments.text" tagName="p" />
          </ConfirmationModal>
        );
      } else {
        router.push(`/groups/${params.id}/shipments/create`);
      }
    } catch (e) {
      router.push(`/groups/${params.id}/shipments/create`);
      console.error(e);
    }

    return undefined;
  };

  return (
    <section className="small-12 column">
      <Panel className={cn('group-shipments')}>
        <Panel.Heading title={<FormattedMessage id="general.shipments" />}>
          <Button onClick={handleCreateShipments} className="small primary wider show-for-large">
            <FormattedMessage id="general.button.createShipment" />
          </Button>
        </Panel.Heading>
        <Panel.Body noPadding>
          <div className={cn('create-shipment-box', 'hide-for-large')}>
            <Link to={`/groups/${params.id}/shipments/create`} className="button primary wide">
              <FormattedMessage id="general.button.createShipment" />
            </Link>
          </div>

          <Preloader isActive={isLoading || isCommitmentsFetching} />

          <DataTable
            data={resources}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            isLoading={isLoading}
            renderExpandable={renderExpandable}
            scrollable
          />

          <NothingBox
            display={!resources.length}
            isLoading={isLoading}
            search={search}
          >
            <h2 className="lighter semibold">
              <FormattedMessage id="component.nothingBox.noShipments" />
            </h2>
            {['awaiting_delivery', 'scheduled_delivery'].includes(pigGroup.status) && (
              <FormattedMessage tagName="p" id="component.groupShipments.groupNotStarted" />
            )}
            {['awaiting_delivery', 'scheduled_delivery'].includes(pigGroup.status) && (
              <Button onClick={handleCreateShipments} className="button primary">
                <FormattedMessage id="general.button.createShipment" />
              </Button>
            )}
          </NothingBox>
        </Panel.Body>
      </Panel>
    </section>
  );
};

GroupShipments.propTypes = {
  params: T.object.isRequired,
  pigGroup: T.object,
  setTableParams: T.func.isRequired,
  tableParams: T.object.isRequired,
  openModal: T.func.isRequired,
  isLogisticsEnabled: T.bool.isRequired,
};

GroupShipments.contextTypes = {
  router: T.object.isRequired,
};

export default connect(
  (state) => ({
    pigGroup: state.pigGroups.groupShow.data,
    tableParams: tableParamsSelector(state, tableNames.adminGroupShipments),
    isLogisticsEnabled: state.auth.user.current_company.logistics,
  }), {
    setTableParams,
    openModal,
  },
)(GroupShipments);
