import React from 'react';
import T from 'prop-types';
// components
import TableFilter from 'components/TableFilter';

const tableFilters = [
  { label: 'MetaFarms', value: '/admin/preferences/integrations/meta-farms' },
  { label: 'Prairie Systems', value: '/admin/preferences/integrations/prairie-systems' },
  { label: 'Distynct', value: '/admin/preferences/integrations/distynct' },
];

const Integrations = ({ children, location }, { router }) => (
  <div>
    <TableFilter
      className="mb-15"
      filters={tableFilters}
      onFilterChange={router.push}
      activeFilter={location.pathname}
    />
    {children}
  </div>
);

Integrations.contextTypes = {
  router: T.object.isRequired,
};

Integrations.propTypes = {
  location: T.object.isRequired,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default Integrations;
