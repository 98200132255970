import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Modal from 'components/Modal';
import Button from 'components/Button';
// redux
import { connect } from 'react-redux';
import { closeModal } from 'reducers/modals';

const MediaUploadProposeModal = ({
  closeModal,
  onSubmit,
  onClose,
  label = <FormattedMessage id="component.modal.mediaUploadPropose.text" />,
}) => {

  const handleCloseModal = () => {
    closeModal();
    onClose();
  };

  const handleSubmitModal = () => {
    closeModal();
    onSubmit();
  };

  return (
    <Modal onClose={handleCloseModal} title={<FormattedMessage id="general.mediaUpload" />}>
      <section className="modal-body">
        {label}
      </section>
      <section className="modal-footer text-right sticky-on-mobile">
        <Button className="margin-right-1" onClick={handleCloseModal}>
          <FormattedMessage id="general.close" />
        </Button>
        <Button primary onClick={handleSubmitModal}><FormattedMessage id="general.upload" /></Button>
      </section>
    </Modal>
  );
};

MediaUploadProposeModal.propTypes = {
  closeModal: T.func.isRequired,
  onSubmit: T.func.isRequired,
  label: T.node,
  onClose: T.func.isRequired,
};

export default connect(null, { closeModal })(MediaUploadProposeModal);
