import React from 'react';
import T from 'prop-types';
// styles
import styles from './ABFBadge.module.scss';
import cn from 'classnames';

const ABFBadge = ({ className }) => (
  <span className={cn(styles['abf-status-badge'], className)}>
    ABF
  </span>
);

ABFBadge.propTypes = {
  className: T.string,
};

export default ABFBadge;
