import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import ReportTemplateRow from '../components/ReportTemplateRow';
import PaginationWrapper from 'components/PaginationWrapper';
import NothingBox from 'components/NothingBox';
// redux
import { connect } from 'react-redux';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { setPageOptions } from 'reducers/layout';
import { setTableParams } from 'reducers/tableParams';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { tableNames } from 'utils/constants';
import cn from 'classnames';
// endpoints
import { fetchFarm } from 'endpoints/farms';
import { getFarmReportTemplates } from 'endpoints/reportTemplates';
// styles
import styles from './FarmReportTemplates.module.scss';

const FarmReportTemplates = ({
  params: { farmId },
  reqParams,
  router,
  setBreadcrumbs,
  setPageOptions,
  setTableParams,
}) => {
  const [farm, setFarm] = useState(undefined);
  const [isFarmLoading, setIsFarmLoading] = useState(false);

  const {
    data: { resources, isLoading, meta: { total } },
    fetchData,
    onPageChange,
    onPerPageChange,
  } = useDataTable((params) => getFarmReportTemplates(farmId, params), {
    tableParams: reqParams,
    setTableParams: (params) => setTableParams(tableNames.reportTemplates, params)
  });

  const getFarm = async () => {
    try {
      setIsFarmLoading(true);
      setFarm(await fetchFarm(farmId));
    } catch (error) {
      toastResponseErrors(error);
    } finally {
      setIsFarmLoading(false);
    }
  };

  useEffect(() => {
    getFarm();
    fetchData();
  }, [farmId]);

  useEffect(() => {
    if (farm) {
      const breadcrumbs = [
        { label: <FormattedMessage id="general.reports" />, path: '/report-farms' },
        { label: farm.name, useLabelAsMobileTitle: true },
      ];
      setPageOptions({ backLink: '' });
      setBreadcrumbs(breadcrumbs);
    }
  }, [farm]);

  const onTemplateSelect = (templateId) => {
    return router.push(`/report-farms/${farm.id}/report-templates/${templateId}/reports`);
  };

  const { page, per_page } = reqParams;

  return (
    <div className={cn('row centered', styles.templates)}>
      <section className="small-12 column">
        <Panel className={styles['templates-panel']}>
          <Panel.Heading
            className={styles['templates-panel-title']}
            title={<FormattedMessage id="general.reports" />}
          />
          <Preloader isActive={isLoading || isFarmLoading} />
          <Panel.Body className={styles['templates-panel-body']}>
            <NothingBox itemsName="templates" display={!resources.length} isLoading={isLoading}>
              <FormattedMessage tagName="h1" id="component.nothingBox.noReportsToDisplay" />
            </NothingBox>
            {resources.map((template) => (
              <ReportTemplateRow key={template.id} onSelect={() => onTemplateSelect(template.id)} template={template} />
            ))}
            <PaginationWrapper
              currentPage={page}
              perPage={per_page}
              onPageChange={onPageChange}
              onPerPageChange={onPerPageChange}
              totalItems={total}
              onlyBottom
            />
          </Panel.Body>
        </Panel>
      </section>
    </div>
  );
};

FarmReportTemplates.propTypes = {
  params: T.object.isRequired,
  reqParams: T.object.isRequired,
  router: T.object.isRequired,
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  setTableParams: T.func.isRequired,
};

export default connect((state) => ({
  reqParams: tableParamsSelector(state, tableNames.reportTemplates),
}), { setBreadcrumbs, setPageOptions, setTableParams })(FarmReportTemplates);
