import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
// utils
import Resumable from 'resumablejs';
import { getResumableHeaders } from './index';
import { toastResponseErrors } from '../responseErrorsHelper';
import { toastr } from 'react-redux-toastr';
// constants
import { API_URL } from '../../constants';

export default function initializeDirectPostResumable(activityId, options) {
  const uploadPath = `${API_URL}/activities/${activityId}/chunk`;
  const resumable = new Resumable({
    target: uploadPath,
    testTarget: uploadPath,
    headers: getResumableHeaders(),
    simultaneousUploads: 1,
    testChunks: true,
    chunkRetryInterval: 500,
    maxFileSize: 314572800,
    maxFileSizeErrorCallback: (file) => {
      toastr.error('', '', {
        icon: <i className="fa fa-ban" />,
        component: (
          <FormattedMessage id="general.fileSizeExceeded" values={{ size: '300mb', filename: file.name }}>
            {(text) => (<div className="rrt-text">{text}</div>)}
          </FormattedMessage>
        ),
      });
    },
  });

  const onFileAdded = (file) => {
    file.resumableObj.upload();
    options?.onFileAdded?.(file);
  };

  const onFileError = (file, message) => {
    console.warn('ResumableJS: onFileError', file.fileName);
    toastResponseErrors(message);
    options?.onFileError?.(file, message);
  };

  if (resumable.support) {
    resumable.on('fileAdded', onFileAdded);
    resumable.on('fileError', onFileError);
    resumable.on('fileSuccess', options?.onFileSuccess);
    resumable.on('fileProgress', options?.onFileProgress);
  } else {
    console.warn('ResumableJS not supported!');
  }

  return resumable;
}
