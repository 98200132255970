import React from 'react';
import T from 'prop-types';
// components
import ButtonRadioGroup from 'components/ButtonRadioGroup/ButtonRadioGroup';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './GroupField.module.scss';
import { FormattedMessage } from 'react-intl';

const cn = classnames.bind(styles);

const options = [
  { label: <FormattedMessage id="general.button.no" />, value: false },
  { label: <FormattedMessage id="general.button.yes" />, value: true },
];

const ABFSection = ({
  isRequired,
  isOpened,
  isSaved,
  className,
  data,
  onSave,
}) => {
  const getLabel = () => (data ?
    <FormattedMessage id="general.activeAbfGroup" /> :
    <FormattedMessage id="general.groupIsNotABF" />);

  const onChange = (value) => onSave({ abf_tracking: value });

  return (
    <div className={cn('group-create-field', className, { 'blackout': !isRequired && !isSaved && !isOpened })}>
      <div className={cn('field-row')}>
        <i className={cn(`fa fa-check-circle${isSaved ? '-bts' : ''}`, 'icon', { 'saved': isSaved })} />
        <div className={cn('title-box')}>
          <div className={cn('title')}>
            <FormattedMessage id="general.abfGroup" />
          </div>
          {isSaved
            ? <span className={cn('label')}>{getLabel()}</span>
            : <div className={cn('hint')}><FormattedMessage id="general.isAnAbfGroup" /></div>}
        </div>
        <div className={cn('buttons')}>
          <ButtonRadioGroup
            name="abf_tracking"
            className={styles['radio-button-group']}
            options={options}
            value={data}
            onChange={({ target: { value } }) => onChange(value)}
          />
        </div>
      </div>
    </div>
  );
};

ABFSection.propTypes = {
  className: T.string,
  isRequired: T.bool,
  isOpened: T.bool,
  isSaved: T.bool,
  onSave: T.func.isRequired,
  data: T.bool,
};

export default ABFSection;
