import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import { bytesToSize } from 'utils';
// styles
import styles from './MediaFilesUploader.module.scss';

const UploadingFilePreview = ({ file, onUploadCancel }) => {
  const progress = Math.ceil((file.uploadProgress || 0) * 99);
  const progressBarStyle = { width: `${progress}%` };

  return (
    <div className={styles['file-preview']}>
      <div className={styles['file-data']}>
        <div className={styles['file-info']}>
          <div className={styles['file-name']}>{file.fileName}</div>
          <div className={styles['file-size']}>{bytesToSize(file.size)}</div>
        </div>
        <div className={styles['file-upload-progress']}>
          <div className={styles['file-progress-value']} style={progressBarStyle} />
        </div>
        <div className={styles['file-uploading-label']}>
          <FormattedMessage id="general.uploadingProgress" values={{ progress }} />
        </div>
      </div>
      {file.cancelable && (
        <div className={styles['cancel-file-upload']}>
          <i className="fa fa-times" onClick={() => onUploadCancel(file)} />
        </div>
      )}
    </div>
  );
};

UploadingFilePreview.propTypes = {
  file: T.object.isRequired,
  onUploadCancel: T.func.isRequired,
};

export default UploadingFilePreview;
