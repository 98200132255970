import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { removeAttentionDot } from 'reducers/sidebar';
import { compose, branch, renderComponent } from 'recompose';
import { openModal } from 'reducers/modals';
// components
import ABFBadge from 'components/ABFBadge';
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import Dropdown from 'components/Dropdown/Dropdown2';
import SubNavigation from 'components/Subnavigation';
import OfflineScreen from 'components/OfflineScreen';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import SearchBox from 'components/SearchBox';
import DownloadLink from 'components/DownloadLink';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import Button from 'components/Button';
import NothingBox from 'components/NothingBox';
import PigGroupMarketingBadge from 'components/PigGroupMarketingBadge';
import { GroupStatusBadge } from 'components/StatusBadge/StatusBadge';
import FarmTypeLabel from 'components/FarmTypeLabel/FarmTypeLabel';
import InlineGraph from 'components/InlineGraph/InlineGraph';
import FormattedWeight from 'components/FormattedWeight';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import SynchroniseModal from './components/SynchroniseModal';
// utils
import cn from 'classnames';
import download from 'downloadjs';
import moment from 'moment';
import isEmpty from 'lodash.isempty';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { fetchData } from 'reducers/dataTable';
import kebabCase from 'lodash.kebabcase';
import { isTablet } from 'react-device-detect';
import { formatMortality, showToastrMessage } from 'utils';
import { getGroupsStatusForRequest, parseFasLinkGroups } from 'utils/pigGroupsHelper';
// groups api
import { downloadPigGroupCSV, downloadAllPigGroupsCSV } from 'endpoints/pigGroups';
import {
  deleteAdminPigGroup,
  closeAdminPigGroup,
  makeFasLinkAdminGroupsImport,
  checkFasLinkAdminGroupsOption,
  updateAdminPigGroup,
} from 'endpoints/admin/pigGroups';
// assets
import downloadFile from 'containers/ImportGroups/EveryPig_Group_Upload_Template.csv';
// styles
import './Groups.scss';

const mobileDropdownData = [
  { label: <FormattedMessage id="general.button.createGroup" />, url: '/groups/create' },
  { label: <FormattedMessage id="general.importGroups" />, url: '/import-groups' },
  { label: <FormattedMessage id="general.replaceIds" />, url: '/rename-groups' },
];

class Groups extends Component {

  state = {
    canForceSync: false,
    forceUpdating: false,
  };

  componentDidMount() {
    const { setPageOptions, setBreadcrumbs } = this.props;

    this.fetchTableData();
    checkFasLinkAdminGroupsOption().then((import_pig_groups) => this.setState({ canForceSync: import_pig_groups }));
    setPageOptions({ mobileTitle: 'groups' });
    setBreadcrumbs();
  }

  componentDidUpdate(prevProps) {
    const { location: { query }, groupParams, fetchData } = this.props;
    const prevPropsStatus = prevProps.location.query?.status;
    if (prevPropsStatus !== query.status) {
      fetchData({ ...groupParams, status: getGroupsStatusForRequest(query.status), page: 1 });
    }
  }

  componentWillUnmount() {
    const { setPageOptions, removeAttentionDot } = this.props;
    setPageOptions({ mobileTitle: '' });
    removeAttentionDot('groups');
  }

  fetchTableData = () => {
    const { fetchData, groupParams, location: { query } } = this.props;
    fetchData({ ...groupParams, status: getGroupsStatusForRequest(query.status) });
  };

  downloadCSV = (id, name) => (e) => {
    e.stopPropagation();
    const filename = `pig-group-${kebabCase(name)}-${moment().format('MM-DD-YYYY')}.csv`;
    return downloadPigGroupCSV(id)
      .then((blob) => download(blob, filename, 'text/csv'))
      .catch(toastResponseErrors);
  };

  downloadCSVExample = () => {
    return download(downloadFile, 'EveryPig_Group_Upload_Template.csv', 'text/csv');
  };

  downloadGroupData = () => {
    const filename = `pig-groups-${moment().format('MM-DD-YYYY')}.csv`;
    return downloadAllPigGroupsCSV()
      .then((blob) => download(blob, filename, 'text/csv'))
      .catch(toastResponseErrors);
  };

  deletePigGroupDraft = (group) => {
    const { fetchData, groupParams, openModal } = this.props;

    const handleDeleteDraft = () => {
      return deleteAdminPigGroup(group.id)
        .then(() => {
          showToastrMessage('component.toastr.groupDraft.hasBeenDeleted');
          fetchData(groupParams);
        })
        .catch(toastResponseErrors);
    };

    openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.deleteDraft" />}
        actionBtnLabel={<FormattedMessage id="component.modal.deleteDraft.confirm" />}
        actionBtnProps={{ red: true }}
        handleConfirm={handleDeleteDraft}
      >
        <FormattedMessage
          id="component.modal.deleteDraftGroup.text"
          tagName="p"
          values={{ name: group.name, b: (text) => <b>{text}</b> }}
        />
      </ConfirmationModal>
    );
  };

  hideGroup = (id) => () => {
    const { fetchData, groupParams } = this.props;
    return updateAdminPigGroup(id, { hidden: true })
      .then(() => {
        fetchData(groupParams);
        showToastrMessage('component.toastr.group.hidden');
      })
      .catch(toastResponseErrors);
  };

  unhideGroup = (id) => () => {
    const { fetchData, groupParams } = this.props;
    return updateAdminPigGroup(id, { hidden: false })
      .then(() => {
        fetchData(groupParams);
        showToastrMessage('component.toastr.group.unhidden');
      })
      .catch(toastResponseErrors);
  };

  renderGroupIDColumn = (pigGroup) => {
    const { id, name, status } = pigGroup;
    const url = status === 'draft' ? `/groups/create?draftId=${id}` : `/groups/${id}`;
    return (
      <CustomColumn>
        <div className="group-id-column">
          <div className="flex align-center">
            <Link to={url} className="color-primary">{name}</Link>&nbsp;
            <PigGroupMarketingBadge pigGroup={pigGroup} size="small" isMinimized isHintAttachedToBody />
          </div>
          <DownloadLink
            endpoint={`/csv/pig_groups/${id}`}
            title={<FormattedMessage id="general.button.downloadData" />}
            className="button download-btn hide-for-large"
          >
            <i className="fa fa-download-btn" />
          </DownloadLink>
        </div>
      </CustomColumn>
    );
  };

  renderFarmColumn = (group) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.farmName" />}>
      {group.farm_name || '-'}
    </CustomColumn>
  );

  renderFarmTypeColumn = ({ farm_type }) => (
    <CustomColumn label={<FormattedMessage id="general.type" />}>
      <FarmTypeLabel farmType={farm_type} />
    </CustomColumn>
  );

  renderABFStatusColumn = ({ abf_tracking }) => (
    <CustomColumn label={<FormattedMessage id="general.type" />}>
      {abf_tracking ? <ABFBadge /> : 'N/A'}
    </CustomColumn>
  )

  renderStartDateColumn = ({ started_on }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.startDate" />}>
      {started_on ? moment(started_on).format('MM/DD/YYYY') : '-'}
    </CustomColumn>
  );

  renderPigsColumn = ({ pigs }) => (
    <CustomColumn textRight label={<FormattedMessage id="component.sidebar.inventory" />}>
      {pigs || '-'}
    </CustomColumn>
  );

  renderEstWeightColumn = ({ estimated_weight }) => (
    <CustomColumn textRight label={<FormattedMessage id="general.estWeight" />}>
      <FormattedWeight value={estimated_weight}  />
    </CustomColumn>
  );

  renderMortalityColumn = ({ mortality_rate, mortality_to_date }) => (
    <CustomColumn flexRow label={<FormattedMessage id="component.dataTable.headers.currentMort" />}>
      <span className="mr-5">{formatMortality(mortality_rate)}</span>
      {!!mortality_rate && (
        <span className={cn('mortality-graph')}>
          <InlineGraph preserveAspectRatio="xMaxYMax meet" height={15} width={100} data={mortality_to_date} />
        </span>
      )}
    </CustomColumn>
  );

  renderStatusColumn = ({ status, closed_on }) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <GroupStatusBadge
        status={status}
        textValues={{
          date: closed_on ? moment(closed_on).format('MM/DD/YY') : null,
        }}
      />
    </CustomColumn>
  );

  renderActionsColumn = (group, { rowIndex }) => {
    const { id, status, name } = group;
    const btnClasses = cn('compact', (status === 'pending_close') ? 'primary' : 'light-gray');
    let url = null;
    let labelText = '';
    let onClick = null;
    if (status === 'pending_close') {
      labelText = <FormattedMessage id="general.close" />;
      onClick = this.closeGroup(id);
    } else {
      labelText = <FormattedMessage id="general.manage" />;
      url = status === 'draft' ? `/groups/create?draftId=${id}` : `/groups/${id}`;
    }

    return (
      <div className="collapsible-value button-column">
        <DropdownButton
          idKey={`${id}-${rowIndex}`}
          label={labelText}
          buttonType={btnClasses}
          url={url}
          onClick={onClick}
          dropDownData={[
            { label: <FormattedMessage id="general.manage" />, url: `/groups/${id}`, hide: status !== 'pending_close' },
            {
              label: <FormattedMessage id="general.button.downloadData" />,
              onClick: this.downloadCSV(id, name),
            },
            {
              label: <FormattedMessage id="general.button.hideGroup" />,
              onClick: this.hideGroup(id),
              hide: status !== 'closed' || group.hidden,
            },
            {
              label: <FormattedMessage id="general.button.unhideGroup" />,
              onClick: this.unhideGroup(id),
              hide: !group.hidden,
            },
            {
              label: <FormattedMessage id="general.deleteDraft" />,
              onClick: () => this.deletePigGroupDraft(group), hide: status !== 'draft',
            },
          ]}
          customClass="show-for-large"
          wide
        />
        <Link
          to={status === 'draft' ? `/groups/create?draftId=${id}` : `/groups/${id}`}
          className="button light hide-for-large"
        >
          <FormattedMessage id="general.manage" />
        </Link>
        {status === 'pending_close' && (
          <Button lightGrey className="hide-for-large" onClick={this.closeGroup(id)}>
            <FormattedMessage id="general.close" />
          </Button>
        )}
        {status === 'draft' && (
          <Button lightGrey onClick={() => this.deletePigGroupDraft(group)} className="hide-for-large">
            <FormattedMessage id="general.deleteDraft" />
          </Button>
        )}
      </div>
    );
  };

  renderExpandable = (group) => {
    const { id, status } = group;
    const url = status === 'draft' ? `/groups/create?draftId=${id}` : `/groups/${id}`;
    return (
      <div>
        <Link className="button light small" to={url}>
          <i className="fa fa-gear mr-5" />
          <FormattedMessage id="general.manage" />
        </Link>
        <Button small light onClick={this.downloadCSV(id)}>
          <i className="fa fa-download mr-5" />
          <FormattedMessage id="general.button.downloadData" />
        </Button>
        {status === 'draft' && (
          <Button small light onClick={() => this.deletePigGroupDraft(group)}>
            <i className="fa fa-trash-o mr-5" />
            <FormattedMessage id="general.deleteDraft" />
          </Button>
        )}
        {status === 'pending_close' && (
          <Button small light onClick={this.closeGroup(id)}>
            <FormattedMessage id="general.close" />
          </Button>
        )}
      </div>
    );
  };

  closeGroup = (id) => () => {
    const { fetchData, groupParams } = this.props;
    closeAdminPigGroup(id).then(() => {
      fetchData(groupParams);
      showToastrMessage('component.toastr.group.wasClosed');
    }).catch(toastResponseErrors);
  };

  getFilter = (status) => {
    if (!status) return '';
    if (Array.isArray(status)) {
      return status.includes('opened') ? 'opened' : 'pending';
    }
    return status;
  };

  onGroupStatusChange = (item = {}) => {
    const { location: { pathname } } = this.props;
    const { router } = this.context;
    router.push({ pathname, query: (!isEmpty(item) && item.value ? { status: item.value } : {}) });
  };

  renderTable = () => {
    const { resources, isLoading, meta: { total }, groupParams: { page, per_page, search, sort, status },
      onSearchChange, onSortChange, onPageChange, onPerPageChange } = this.props;
    const { forceUpdating } = this.state;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.groupId" />, flex: '1 1 140px',
        renderer: this.renderGroupIDColumn, sortKey: 'name' },
      { label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '1 1 140px',
        renderer: this.renderFarmColumn, sortKey: 'farm_name' },
      { label: <FormattedMessage id="general.type" />, flex: '1 1 90px', renderer: this.renderFarmTypeColumn,
        sortKey: 'farm_type' },
      { label: <FormattedMessage id="general.abfStatus" />, renderer: this.renderABFStatusColumn,
        flex: '1 1 90px', sortKey: 'abf_tracking' },
      { label: <FormattedMessage id="component.dataTable.headers.startDate" />, flex: '1 1 90px',
        renderer: this.renderStartDateColumn, sortKey: 'started_on' },
      { label: <FormattedMessage id="component.sidebar.inventory" />, flex: '1 1 75px',
        renderer: this.renderPigsColumn, textRight: true },
      { label: <FormattedMessage id="general.estWeight" />, flex: '1 1 90px',
        renderer: this.renderEstWeightColumn, textRight: true },
      { label: <FormattedMessage id="component.dataTable.headers.currentMort" />, flex: '1 1 140px',
        renderer: this.renderMortalityColumn },
      { label: <FormattedMessage id="component.dataTable.headers.status" />, flex: '1 1 120px',
        renderer: this.renderStatusColumn, sortKey: 'status' },
      { label: '', flex: '0 0 175px', renderer: this.renderActionsColumn, fixed: true,
        className: isTablet ? 'hide-for-large' : '' },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      totalItems: total,
      currentPage: page,
      perPage: per_page,
      onPageChange,
      onPerPageChange,
    };

    const filter = this.getFilter(status);
    const noGroupsTextValues = {
      importGroupsLink: (
        <Link className="underline color-primary" to="/import-groups">
          <FormattedMessage id="general.textParts.importGroups" />
        </Link>
      ),
      createGroupLink: (
        <Link className="underline color-primary" to="/groups/create">
          <FormattedMessage id="general.textParts.createGroupNow" />
        </Link>
      ),
      downloadLink: (
        <span className="download-link" onClick={this.downloadCSVExample}>
          <FormattedMessage id="general.here" />
        </span>
      ),
    };

    return (
      <section className="GroupsIndexTable">
        <Panel>
          <Panel.Heading title={<FormattedMessage id="general.allGroups" />}>
            <SearchBox initialValue={search} onChange={onSearchChange} />
          </Panel.Heading>
          <Panel.Body noPadding>
            <Preloader isActive={isLoading || forceUpdating} />

            <div className="hide-for-large mobile-download-btn mh-10 pt-10">
              <DropdownButton
                idKey={`groups-${resources.length}`}
                label={<FormattedMessage id="general.downloadAllGroupData" />}
                onClick={this.downloadGroupData}
                dropDownData={mobileDropdownData}
                wide
              />
            </div>

            <DataTable
              data={resources}
              columns={columns}
              sort={sort}
              onSortChange={onSortChange}
              paginationProps={paginationProps}
              isExpandable={isTablet}
              renderExpandable={this.renderExpandable}
              scrollable
              tableKey="adminGroups"
            />

            <NothingBox
              itemsName="groups"
              display={!resources.length}
              isLoading={isLoading}
              search={search}
              filter={filter}
            >
              <FormattedMessage tagName="div" id="component.nothingBox.noGroupsYet" values={noGroupsTextValues} />
            </NothingBox>
          </Panel.Body>
        </Panel>
      </section>
    );
  };

  forceSynchronise = () => {
    const { openModal } = this.props;

    this.setState({ forceUpdating: true });
    makeFasLinkAdminGroupsImport()
      .then((resources) => {
        this.fetchTableData();
        this.setState({ forceUpdating: false });
        const parsedGroups = parseFasLinkGroups(resources);
        openModal(<SynchroniseModal groups={parsedGroups} />);
      })
      .catch((err, msg) => {
        toastResponseErrors(err, msg);
        this.setState({ forceUpdating: false });
      });
  };

  render() {
    const { stats: {
      pending_close, scheduled_delivery, awaiting_delivery, opened, request_close, closed, draft, hidden
    },
    location: { query: { status } } } = this.props;
    const { canForceSync } = this.state;

    const pendingStats = (scheduled_delivery || 0) + (awaiting_delivery || 0);
    const items = [
      { value: 'action_required', label: <FormattedMessage id="general.status.action_required" />,
        counter: pending_close || 0 },
      { value: 'opened', label: <FormattedMessage id="general.status.opened" />, stats: (opened + request_close) || 0 },
      { value: 'pending', label: <FormattedMessage id="general.status.pending" />, stats: pendingStats },
      { value: 'closed', label: <FormattedMessage id="general.status.closed" values={{ date: null }} />,
        stats: closed || 0 },
      { value: 'draft', label: <FormattedMessage id="general.status.draft" />, stats: draft || 0 },
      { value: 'hidden', label: <FormattedMessage id="general.status.hidden" />, stats: hidden || 0 },
    ];

    const options = [
      { label: <FormattedMessage id="general.replaceIds" />, url: '/rename-groups' },
      { label: <FormattedMessage id="general.downloadGroupData" />, onClick: this.downloadGroupData },
      ...(canForceSync
        ? [{ label: <FormattedMessage id="general.synchronise" />, onClick: this.forceSynchronise }]
        : [])
    ];

    return (
      <div className="Groups clearfix">
        <SubNavigation
          defaultItem={<FormattedMessage id="general.allGroups" />}
          items={items}
          idKey="value"
          labelKey="label"
          currentId={status}
          onSelect={this.onGroupStatusChange}
          className="small-12 column"
          isSticky
        >
          <Link className="importTableBtn" to="/csv-import/select-mode?from=groups">
            <FormattedMessage id="general.import" />
          </Link>

          <Link className="createGroupBtn" to="/groups/create">
            <FormattedMessage id="general.button.createGroup" />
          </Link>
          <Dropdown
            isButton
            ignoreScroll
            options={options}
            className="ml-10 show-for-large"
          />
        </SubNavigation>
        <div className="small-12 column">
          {this.renderTable()}
        </div>
      </div>
    );
  }
}

Groups.propTypes = {
  location: T.object.isRequired,
  stats: T.object,
  setPageOptions: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  removeAttentionDot: T.func.isRequired,
  groupParams: T.object.isRequired,
  fetchData: T.func,
  resources: T.array.isRequired,
  meta: T.object.isRequired,
  isLoading: T.bool,
  onSearchChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  openModal: T.func.isRequired,
};

Groups.contextTypes = {
  router: T.object.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      stats: state.dataTable.adminGroups.meta.stats || {},
      groupParams: state.dataTable.adminGroups.params,
      resources: state.dataTable.adminGroups.resources,
      meta: state.dataTable.adminGroups.meta,
      isLoading: state.dataTable.adminGroups.isLoading,
      isOnline: state.network.isOnline,
    }),
    (dispatch) => ({
      fetchData: (query) => dispatch(fetchData('/admin/pig_groups', 'adminGroups', query)),
      openModal: (component) => dispatch(openModal(component)),
      setPageOptions,
      setBreadcrumbs,
      removeAttentionDot,
    })
  ),
  withDataTableController('fetchData', 'groupParams'),
  branch(
    ({ isOnline }) => !isOnline,
    renderComponent(OfflineScreen),
  )
);

export default enhance(Groups);
