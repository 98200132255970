import { useEffect } from 'react';
import { isProduction } from 'utils';

export default function useHubspot(pathname) {
  useEffect(() => {
    if (isProduction) {
      const script = document.createElement('script');
      script.src = '//js.hs-scripts.com/6703101.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => {
    window?.HubSpotConversations?.widget?.refresh();
  }, [pathname]);
}
